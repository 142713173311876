// Options Actions
export const FETCH_DEVICE_PROPSETID_REQUEST = "FETCH_DEVICE_PROPSETID_REQUEST";
export const FETCH_DEVICE_PROPSETID_SUCCESS = "FETCH_DEVICE_PROPSETID_SUCCESS";

export const FETCH_PATIENT_SENSOR_REQUEST = "FETCH_PATIENT_SENSOR_REQUEST";
export const FETCH_PATIENT_SENSOR_SUCCESS = "FETCH_PATIENT_SENSOR_SUCCESS";
export const FETCH_PATIENT_SENSOR_ERROR = "FETCH_PATIENT_SENSOR_ERROR";

export const UPDATE_GUESTMAPPING_REQUEST = "UPDATE_GUESTMAPPING_REQUEST";
export const UPDATE_GUESTMAPPING_SUCCESS = "UPDATE_GUESTMAPPING_SUCCESS";
export const UPDATE_GUESTMAPPING_ERROR = "UPDATE_GUESTMAPPING_ERROR";

export const FETCH_GUESTS_REQUEST = "FETCH_GUESTS_REQUEST";
export const FETCH_GUESTS_SUCCESS = "FETCH_GUESTS_SUCCESS";
export const FETCH_GUESTS_ERROR = "FETCH_GUESTS_ERROR";

export const SEND_INVITE_REQUEST = "SEND_INVITE_REQUEST";
export const SEND_INVITE_SUCCESS = "SEND_INVITE_SUCCESS";
export const SEND_INVITE_ERROR = "SEND_INVITE_ERROR";

export const ADD_GUESTMAP_REQUEST = "ADD_GUESTMAP_REQUEST";
export const ADD_GUESTMAP_SUCCESS = "ADD_GUESTMAP_SUCCESS";
export const ADD_GUESTMAP_ERROR = "ADD_GUESTMAP_ERROR";

export const RESET_GUESTMAP = "RESET_GUESTMAP";