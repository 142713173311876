import {
  FETCH_CUSTOM_FIELD_REQUEST,
  FETCH_CUSTOM_FIELD_SUCCESS,
  FETCH_CUSTOM_FIELD_ERROR,
  FETCH_SUPPLIER_CUSTOM_FIELD_REQUEST,
  FETCH_SUPPLIER_CUSTOM_FIELD_SUCCESS,
  FETCH_SUPPLIER_CUSTOM_FIELD_ERROR
} from "./actions";
import { REDUCERS } from "../../common/constants";
import { createReducer } from "../../common/helpers";

const schema = {
  loading: false,
  error: false,
  list: [],
  companyCustomList: []
};

const reducer = {
  // Program Options
  [FETCH_CUSTOM_FIELD_REQUEST]: (state) => {
    return {
      ...state,
      ...REDUCERS.LOADING,
    };
  },
  [FETCH_CUSTOM_FIELD_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      list: payload.content.sort((a,b) => a.displayOrder - b.displayOrder),
    };
  },
  [FETCH_CUSTOM_FIELD_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [FETCH_SUPPLIER_CUSTOM_FIELD_REQUEST]: (state) => {
    return {
      ...state,
      ...REDUCERS.LOADING,
    };
  },
  [FETCH_SUPPLIER_CUSTOM_FIELD_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      companyCustomList: payload.content.sort((a,b) => a.displayOrder - b.displayOrder),
    };
  },
  [FETCH_SUPPLIER_CUSTOM_FIELD_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
};

export default createReducer(schema, reducer);
