import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import AddAlert from "@material-ui/icons/AddAlert";

import countryList from "react-select-country-list";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import Snackbar from "components/Snackbar/Snackbar.js";
import Loader from "components/Loader/Loader.js";

import formStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { FETCH_PRACTICE_REQUEST } from "../../redux/practice/actions";
import {
  USER_DETAIL_REQUEST,
  CREATE_PRACTICE_USER_REQUEST,
  CREATE_USER_REQUEST,
  UPDATE_PRACTICE_USER_REQUEST,
  UPDATE_USER_REQUEST,
} from "../../redux/users/actions";
import { FETCH_COMPANY_REQUEST } from "../../redux/company/actions";
import { FETCH_CUSTOM_FIELD_REQUEST } from "../../redux/customFields/actions";
import { useComposeDispatcher } from "../../common/hooks";

import { USER_ROLES, CUSTOM_FIELD_DEFAULT_VALUE } from "../../common/constants";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const tenantId = "ATS-DEV";

const styles = () => ({
  labelFocused: {
    color: "#E73F3B !important",
  },
  inputFocused: {
    "&:before": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "&:after": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "& input": {
      borderBottom: "#E73F3B !important",
    },
  },
  inputRoot: {
    color: "red",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
});

const InputBox = withStyles(styles)((props) => {
  const {
    labelText,
    helperText,
    classes,
    value,
    id,
    info,
    updateInfo,
    type,
    required,
    disabled
  } = props;
  return (
    <TextField
      label={labelText}
      helperText={helperText}
      fullWidth
      disabled={disabled}
      required={required ? required : false}
      type={type ? type : "text"}
      margin="normal"
      color="primary"
      value={value}
      onChange={(e) => {
        let obj = {};
        obj[id] = e.target.value;
        updateInfo({ ...info, ...obj });
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          focused: classes.labelFocused,
        },
      }}
      InputProps={{
        classes: {
          root: classes.root,
          focused: classes.inputFocused,
        },
      }}
    />
  );
});

const SelectBox = withStyles(styles)((props) => {
  const {
    disabled,
    labelText,
    classes,
    value,
    id,
    info,
    updateInfo,
    options,
    required,
    customField,
  } = props;
  return (
    <TextField
      select
      fullWidth
      disabled={disabled || false}
      label={labelText}
      value={value}
      required={required ? required : false}
      margin="normal"
      color="primary"
      onChange={(e) => {
        if (id === "role") {
          const guestAccountDetail = customField.find(
            (val) => val.name === "Guest Account"
          );
          let obj = {};
          obj[id] = e.target.value;
          obj[guestAccountDetail.fieldId] =
            e.target.value === "Guest" ? "Yes" : "No";
          updateInfo({ ...info, ...obj });
        } else {
          let obj = {};
          obj[id] = e.target.value;
          updateInfo({ ...info, ...obj });
        }
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          focused: classes.labelFocused,
        },
      }}
      InputProps={{
        classes: {
          root: classes.root,
          focused: classes.inputFocused,
        },
      }}
    >
      {options}
    </TextField>
  );
});

const useStyles = makeStyles(formStyle);

export default function AddUser() {
  const history = useHistory();
  const { id } = useParams();
  const { practice, users, company, customFields } = useSelector((state) => ({
    practice: state.practice,
    users: state.users,
    company: state.company,
    customFields: state.customFields,
  }));

  let user = users.userDetail.user;
  const [userInfo, setUserInfo] = React.useState({});
  const [alertMessage, setAlertMessage] = React.useState("");
  const [toastStatus, setToastStatus] = React.useState(false);
  const [refreshData, setRefreshData] = React.useState(Date.now());

  const [getCompanyList] = useComposeDispatcher(FETCH_COMPANY_REQUEST);
  const [getPracticeList] = useComposeDispatcher(FETCH_PRACTICE_REQUEST);
  const [getUserDetailsById] = useComposeDispatcher(USER_DETAIL_REQUEST);
  const [getCustomFieldList] = useComposeDispatcher(FETCH_CUSTOM_FIELD_REQUEST);
  const [createPracticeAndUser] = useComposeDispatcher(
    CREATE_PRACTICE_USER_REQUEST
  );
  const [createUser] = useComposeDispatcher(CREATE_USER_REQUEST);

  const [updatePracticeAndUser] = useComposeDispatcher(
    UPDATE_PRACTICE_USER_REQUEST
  );
  const [updateUser] = useComposeDispatcher(UPDATE_USER_REQUEST);

  const classes = useStyles();

  const userDetails = JSON.parse(localStorage.getItem("user-details"));
  const loggedUserRole = userDetails !== null ? userDetails.roles[0].role : "";
  
  useEffect(() => {
    getCompanyList();
    getCustomFieldList("UserProfile");
    id && getUserDetailsById(id);
  }, []);

  if (customFields.list.length > 0 && Object.keys(userInfo).length === 0) {
    const updatedData = customFields.list.reduce((acc, val) => {
      const defaultValue = CUSTOM_FIELD_DEFAULT_VALUE.find(
        (data) => data.key === val.name
      );
      if (defaultValue) {
        let obj = [];
        obj[val.fieldId] = defaultValue.value;
        return { ...acc, ...obj };
      }
      return acc;
    }, {});
    setUserInfo({ ...userInfo, ...updatedData });
  }

  if (id && user.firstName && !userInfo.firstName) {
    user.company = user.roles[0].supplier?.supplierId;
    setUserInfo(user);
    if(loggedUserRole !== 'Patient') {
      getPracticeList({ name: user.fullName });
    }
    setRefreshData(Date.now());
  }

  let validateForm = () => {
    setToastStatus(false);
    if (
      !userInfo?.firstName ||
      !userInfo?.lastName ||
      !userInfo?.emailAddress ||
      !userInfo?.password ||
      !userInfo?.confirmPassword ||
      !userInfo?.role ||
      !userInfo?.streetAddress1 ||
      !userInfo?.city ||
      !userInfo?.state ||
      !userInfo?.country ||
      !userInfo?.zipcode ||
      !userInfo?.primaryPhone ||
      !userInfo?.company ||
      customFields.list.filter((val) => !userInfo[val.fieldId]).length > 3
    ) {
      setAlertMessage("Fields mark with asterisk are required");
      setToastStatus(true);
    } else {
      const emailReg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      const passwordReq = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      if (!emailReg.test(userInfo?.emailAddress)) {
        setAlertMessage("Please enter valid email");
        setToastStatus(true);
      } else if (!passwordReq.test(userInfo?.password)) {
        setAlertMessage("Please enter valid password");
        setToastStatus(true);
      } else if (userInfo?.password !== userInfo?.confirmPassword) {
        setAlertMessage("Entered Password is not matching");
        setToastStatus(true);
      } else {
        const customFieldData = customFields.list.reduce((acc, val) => {
          if (userInfo[val.fieldId]) {
            return [...acc, {
              fieldData: userInfo[val.fieldId],
              field: val,
            }];
          }
          return acc;
        }, []);
        let name = `${userInfo.firstName} ${userInfo.lastName}`;
        if (userInfo.middleName) {
          name = `${userInfo.firstName} ${userInfo.middleName} ${userInfo.lastName}`;
        }
        const contactInfo = {
          streetAddress1: userInfo.streetAddress1,
          streetAddress2: userInfo.streetAddress2,
          city: userInfo.city,
          state: userInfo.state,
          country: userInfo.country,
          zipcode: userInfo.zipcode,
          primaryPhone: userInfo.primaryPhone,
          secondaryPhone: userInfo.secondaryPhone,
        };
        const praticeObject = {
          name,
          emailAddress: userInfo.emailAddress,
          supplierId: userInfo.company,
          tenantId,
          contactInfo,
        };
        const userObject = {
          password: userInfo.password,
          user: {
            firstName: userInfo.firstName,
            middleName: userInfo.middleName,
            lastName: userInfo.lastName,
            emailAddress: userInfo.emailAddress,
            password: userInfo.password,
            contactInfo,
            roles: [
              {
                role:
                  userInfo.role === "Guest" ? "PracticeUser" : userInfo.role,
                supplier: {
                  supplierId: userInfo.company,
                },
              },
            ],
            tenant: { tenantId },
          },
          customData: customFieldData,
        };
        if (userInfo?.role === "PracticeUser" || userInfo?.role === "Guest") {
          createPracticeAndUser({
            userObject,
            praticeObject,
            supplierId: userInfo.company,
          });
        } else {
          createUser({
            userObject,
          });
        }
      }
    }
  };

  let validateUpdateForm = () => {
    setToastStatus(false);
    if (
      !userInfo?.firstName ||
      !userInfo?.lastName ||
      !userInfo?.emailAddress ||
      !userInfo?.role ||
      !userInfo?.streetAddress1 ||
      !userInfo?.city ||
      !userInfo?.state ||
      !userInfo?.country ||
      !userInfo?.zipcode ||
      !userInfo?.primaryPhone ||
      !userInfo?.company ||
      customFields.list.filter((val) => !userInfo[val.fieldId]).length > 3
    ) {
      setAlertMessage("Fields mark with asterisk are required");
      setToastStatus(true);
    } else {
      const emailReg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      if (!emailReg.test(userInfo?.emailAddress)) {
        setAlertMessage("Please enter valid email");
        setToastStatus(true);
      } else {
        const customFieldData = customFields.list.reduce((acc, val) => {
          if (userInfo[val.fieldId]) {
            return [...acc, {
              fieldData: userInfo[val.fieldId],
              field: val,
            }];
          }
          return acc;
        }, []);
        let name = `${userInfo.firstName} ${userInfo.lastName}`;
        if (userInfo.middleName) {
          name = `${userInfo.firstName} ${userInfo.middleName} ${userInfo.lastName}`;
        }
        const contactInfo = {
          streetAddress1: userInfo.streetAddress1,
          streetAddress2: userInfo.streetAddress2,
          city: userInfo.city,
          state: userInfo.state,
          country: userInfo.country,
          zipcode: userInfo.zipcode,
          primaryPhone: userInfo.primaryPhone,
          secondaryPhone: userInfo.secondaryPhone,
        };
        const praticeObject = {
          practiceId: practice?.practiceList[0]?.practiceId,
          name,
          emailAddress: userInfo.emailAddress,
          supplierId: userInfo.company,
          tenantId,
          contactInfo,
        };
        const userObject = {
          user: {
            userId: userInfo.userId,
            firstName: userInfo.firstName,
            middleName: userInfo.middleName,
            lastName: userInfo.lastName,
            emailAddress: userInfo.emailAddress,
            contactInfo,
            roles: [
              {
                role:
                  userInfo.role === "Guest" ? "PracticeUser" : userInfo.role,
                supplier: {
                  supplierId: userInfo.company,
                },
              },
            ],
            tenant: { tenantId },
          },
          customData: customFieldData,
        };
        if (userInfo?.role === "PracticeUser" || userInfo?.role === "Guest") {
          updatePracticeAndUser({
            userObject,
            praticeObject,
            supplierId: userInfo.company,
          });
        } else {
          updateUser({
            userObject,
          });
        }
      }
    }
  };

  if (users.success) {
    if(loggedUserRole !== 'Patient') {
      history.push(`/admin/user`);
    } else {
      history.push(`/admin/dashboard`);
    }
  }

  return (
    <GridContainer key={refreshData}>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="danger" icon>
            <CardIcon color="danger">
              <Person />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>General Information</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <InputBox
                  labelText="First name"
                  value={userInfo.firstName}
                  id="firstName"
                  required={true}
                  info={userInfo}
                  disabled={loggedUserRole === 'Patient'}
                  updateInfo={setUserInfo}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <InputBox
                  labelText="Middle name"
                  id="middleName"
                  value={userInfo.middleName}
                  info={userInfo}
                  disabled={loggedUserRole === 'Patient'}
                  updateInfo={setUserInfo}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <InputBox
                  labelText="Last name"
                  id="lastName"
                  value={userInfo.lastName}
                  required={true}
                  info={userInfo}
                  disabled={loggedUserRole === 'Patient'}
                  updateInfo={setUserInfo}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <InputBox
                  labelText="Email"
                  id="emailAddress"
                  type="email"
                  value={userInfo.emailAddress}
                  required={true}
                  info={userInfo}
                  disabled={loggedUserRole === 'Patient'}
                  updateInfo={setUserInfo}
                />
              </GridItem>
              {!id && (
                <>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputBox
                      labelText="Password"
                      helperText="Please enter password which contain min 8 letter, at least a symbol, upper and lower case letters and a number"
                      id="password"
                      type="password"
                      value={userInfo.password}
                      required={true}
                      info={userInfo}
                      updateInfo={setUserInfo}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputBox
                      labelText="Confirm password"
                      id="confirmPassword"
                      type="password"
                      value={userInfo.confirmPassword}
                      required={true}
                      info={userInfo}
                      updateInfo={setUserInfo}
                    />
                  </GridItem>
                </>
              )}
              {
                loggedUserRole !== 'Patient' &&
                <>
                  <GridItem xs={12} sm={12} md={4}>
                    <SelectBox
                      id="role"
                      labelText="Select Role"
                      required={true}
                      value={userInfo?.role}
                      info={userInfo}
                      customField={customFields.list}
                      updateInfo={setUserInfo}
                      options={USER_ROLES.map((val) => (
                        <MenuItem
                          disabled={val === userInfo?.currentRole?.role}
                          classes={{
                            root: classes.selectMenuItem,
                          }}
                          key={val.key}
                          value={val.key}
                        >
                          {val.value}
                        </MenuItem>
                      ))}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <SelectBox
                      id="company"
                      labelText="Company"
                      value={userInfo?.company}
                      info={userInfo}
                      required={true}
                      updateInfo={setUserInfo}
                      options={company.companyList.map((val) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                          }}
                          key={val.supplierId}
                          value={val.supplierId}
                        >
                          {val.name}
                        </MenuItem>
                      ))}
                    />
                  </GridItem>
                </>
              }
              
            </GridContainer>
          </CardBody>
        </Card>
        <Card>
          <CardHeader color="danger" icon>
            <CardIcon color="danger">
              <ContactPhoneIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Contact Information</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <InputBox
                  required={true}
                  labelText="Street Address 1"
                  id="streetAddress1"
                  disabled={loggedUserRole === 'Patient'}
                  value={userInfo?.streetAddress1}
                  info={userInfo}
                  updateInfo={setUserInfo}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <InputBox
                  labelText="Street Address 2"
                  id="streetAddress2"
                  value={userInfo?.streetAddress2}
                  info={userInfo}
                  disabled={loggedUserRole === 'Patient'}
                  updateInfo={setUserInfo}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <InputBox
                  required={true}
                  labelText="City"
                  id="city"
                  value={userInfo?.city}
                  info={userInfo}
                  disabled={loggedUserRole === 'Patient'}
                  updateInfo={setUserInfo}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <InputBox
                  required={true}
                  labelText="State"
                  id="state"
                  value={userInfo?.state}
                  info={userInfo}
                  disabled={loggedUserRole === 'Patient'}
                  updateInfo={setUserInfo}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Box paddingTop={2}>
                  <Autocomplete
                    fullWidth
                    disabled={loggedUserRole === 'Patient'}
                    options={countryList().getData()}
                    className={classes.inputColor}
                    disableCloseOnSelect
                    color="primary"
                    getOptionLabel={(option) =>
                      option.label ? option.label : option
                    }
                    value={
                      userInfo?.country
                        ? countryList()
                          .getData()
                          .find((data) => data.value === userInfo?.country)
                        : null
                    }
                    onChange={(event, val) => {
                      const field = { ...userInfo };
                      if (val) {
                        field.country = val.value;
                      } else {
                        delete field.country;
                      }
                      setUserInfo(field);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country"
                        required={true}
                        InputLabelProps={{
                          shrink: true,
                          classes: {
                            focused: classes.labelFocused,
                          },
                        }}
                        SelectProps={{
                          classes: {
                            focused: classes.labelFocused,
                          },
                        }}
                      />
                    )}
                  />
                </Box>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <InputBox
                  required={true}
                  disabled={loggedUserRole === 'Patient'}
                  labelText="Zipcode"
                  id="zipcode"
                  value={userInfo?.zipcode}
                  info={userInfo}
                  updateInfo={setUserInfo}
                />
              </GridItem>
            </GridContainer>
            <GridContainer style={{ marginTop: "25px" }}>
              <GridItem xs={12} sm={12} md={4}>
                <InputLabel
                  style={{
                    fontSize: "12px",
                    color: "rgba(0, 0, 0, 0.54)",
                  }}
                >
                  Primary Phone *
                </InputLabel>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <PhoneInput
                    country={"au"}
                    containerStyle={{ zIndex: 4 }}
                    inputStyle={{
                      border: "0px",
                      borderBottom: "1px solid #0000008a",
                      borderRadius: "0px",
                    }}
                    disabled={loggedUserRole === 'Patient'}
                    buttonStyle={{
                      border: "0px",
                      background: "none",
                    }}
                    enableSearch={true}
                    placeholder="212345678"
                    value={userInfo?.primaryPhone}
                    onChange={(val) => {
                      setUserInfo({ ...userInfo, primaryPhone: val });
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={4} mt={2}>
                <InputLabel
                  style={{
                    fontSize: "12px",
                    color: "rgba(0, 0, 0, 0.54)",
                  }}
                >
                  Secondary Phone
                </InputLabel>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <PhoneInput
                    country={"au"}
                    disabled={loggedUserRole === 'Patient'}
                    containerStyle={{ zIndex: 4 }}
                    inputStyle={{
                      border: "0px",
                      borderBottom: "1px solid #0000008a",
                      borderRadius: "0px",
                    }}
                    buttonStyle={{
                      border: "0px",
                      background: "none",
                    }}
                    enableSearch={true}
                    placeholder="212345678"
                    value={userInfo?.secondaryPhone}
                    onChange={(val) => {
                      setUserInfo({ ...userInfo, secondaryPhone: val });
                    }}
                  />
                </FormControl>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <Card>
          <CardHeader zeroIndex={true} color="danger" icon>
            <CardIcon color="danger">
              <FilterNoneIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>User Profile</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              {customFields.list.map((field) => {
                if(loggedUserRole !== 'Patient') {
                  if (
                    field.name !== "TimeZone" &&
                    field.name !== "Location" &&
                    field.name !== "DayLight_Savings" &&
                    field.name !== "Plot_range_default"
                  ) {
                    let html;
                    if (field.type === "SingleSelect") {
                      html = (
                        <GridItem xs={12} sm={12} md={4}>
                          <SelectBox
                            id={field.fieldId}
                            // disabled={
                            //   field.name === "Guest Account" ? true : false
                            // }
                            labelText={field.name}
                            required={true}
                            value={userInfo[field.fieldId]}
                            info={userInfo}
                            updateInfo={setUserInfo}
                            options={field.optionValues.map((val) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                }}
                                key={val}
                                value={val}
                              >
                                {val}
                              </MenuItem>
                            ))}
                          />
                        </GridItem>
                      );
                    } else if (field.type === "MultiSelect") {
                      html = (
                        <GridItem xs={12} sm={12} md={4}>
                          <Box paddingTop={2}>
                            <Autocomplete
                              multiple
                              fullWidth
                              options={field.optionValues}
                              className={classes.inputColor}
                              disableCloseOnSelect
                              color="primary"
                              renderOption={(option, { selected }) => (
                                <React.Fragment>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option}
                                </React.Fragment>
                              )}
                              onChange={(event, val) => {
                                if (val.length === 0) {
                                  delete userInfo[field.fieldId];
                                } else {
                                  setUserInfo({
                                    ...userInfo,
                                    [field.fieldId]: val,
                                  });
                                }
                              }}
                              value={userInfo[field.fieldId]}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={field.name}
                                  required={true}
                                  InputLabelProps={{
                                    shrink: true,
                                    classes: {
                                      focused: classes.labelFocused,
                                    },
                                  }}
                                  SelectProps={{
                                    classes: {
                                      focused: classes.labelFocused,
                                    },
                                  }}
                                />
                              )}
                            />
                          </Box>
                        </GridItem>
                      );
                    } else if (field.type === "MultiLineText") {
                      html = (
                        <GridItem xs={12} sm={12} md={4}>
                          <InputLabel className={classes.datePickerLabel}>
                            {field.name}
                          </InputLabel>
                          <TextareaAutosize
                            value={userInfo[field.fieldId]}
                            rowsMax={5}
                            rowsMin={3}
                            id={field.fieldId}
                            style={{
                              width: "100%",
                              marginTop: "5px",
                            }}
                            onChange={(e) => {
                              setUserInfo({
                                ...userInfo,
                                [field.fieldId]: e.target.value,
                              });
                            }}
                          />
                        </GridItem>
                      );
                    } else {
                      html = (
                        <GridItem xs={12} sm={12} md={4}>
                          <InputBox
                            labelText={field.name}
                            id={field.fieldId}
                            required={true}
                            value={userInfo[field.fieldId]}
                            info={userInfo}
                            updateInfo={setUserInfo}
                          />
                        </GridItem>
                      );
                    }
                    return html;
                  }
                } else {
                  if (
                    field.name == "T&C accepted" ||
                    field.name == "Privacy Policy Accepted"
                  ) {
                    let html;
                    if (field.type === "SingleSelect") {
                      html = (
                        <GridItem xs={12} sm={12} md={4}>
                          <SelectBox
                            id={field.fieldId}
                            // disabled={
                            //   field.name === "Guest Account" ? true : false
                            // }
                            labelText={field.name}
                            required={true}
                            disabled={loggedUserRole === 'Patient'}
                            value={userInfo[field.fieldId]}
                            info={userInfo}
                            updateInfo={setUserInfo}
                            options={field.optionValues.map((val) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                }}
                                key={val}
                                value={val}
                              >
                                {val}
                              </MenuItem>
                            ))}
                          />
                        </GridItem>
                      );
                    }
                    return html;
                  }
                }
              })}
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Box textAlign="right">
          {(loggedUserRole === "TenantAdmin" ||
            loggedUserRole === "SupplierAdmin") && (
              <>
                <Button
                  onClick={() => {
                    id ? validateUpdateForm() : validateForm();
                  }}
                  color="danger"
                >
                  {id ? "Save" : "Submit"}
                </Button>
                <Button
                  onClick={() => {
                    history.push(`/admin/user`);
                  }}
                >
                  Cancel
                </Button>
              </>
            )}
        </Box>
      </GridItem>
      <Snackbar
        place="tr"
        color="danger"
        icon={AddAlert}
        message={alertMessage}
        open={toastStatus}
        closeNotification={() => setToastStatus(false)}
        close
      />
      <Loader status={users.loading || users.userDetail.loading} />
    </GridContainer>
  );
}
