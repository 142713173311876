import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import AddAlert from "@material-ui/icons/AddAlert";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Table from "@material-ui/core/Table";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import List from "@material-ui/icons/List";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Checkbox from "@material-ui/core/Checkbox";
// core components
import Snackbar from "components/Snackbar/Snackbar.js";
import GridContainer from "components/Grid/GridContainer.js";
import Loader from "components/Loader/Loader.js";
import Button from "components/CustomButtons/Button.js";
import { PAGINATION_DETAILS, PAGE_SIZES } from "../../common/constants";
import Filter from "./Filter.js";
import {
  FETCH_DEVICE_REQUEST,
  FETCH_FRIENDLY_NAME_REQUEST,
} from "../../redux/device/actions";
import { FETCH_USER_LIST_REQUEST } from "../../redux/users/actions";
import { FETCH_COMPANY_REQUEST } from "../../redux/company/actions";
import {
  FETCH_ERROR_LOG_DATA_REQUEST,
  DELETE_ERROR_LOG_DATA_REQUEST,
} from "../../redux/device/actions";
import { useComposeDispatcher } from "../../common/hooks";
import styles from "assets/jss/material-dashboard-pro-react/views/userStyle.js";

const useStyles = makeStyles(styles);

const columns = [
  {
    id: "residentName",
    label: "Resident Name",
    minWidth: 130,
    sort: false,
  },
  {
    id: "FriendlyNameD",
    label: "Friendly Name",
    minWidth: 150,
    sort: true,
  },
  {
    id: "DevidDevhealth",
    label: "Dev Id",
    minWidth: 130,
    sort: true,
  },
  {
    id: "ErrorType",
    label: "Error Type",
    minWidth: 130,
    sort: true,
  },
  {
    id: "ErrorLog",
    label: "Error Text",
    minWidth: 130,
    sort: true,
  },
  {
    id: "Fwvers",
    label: "Fwvers",
    minWidth: 130,
    sort: true,
  },
  {
    id: "TimestampD",
    label: "Error Time",
    minWidth: 130,
    type: "date",
    sort: true,
  },
];

export default function ErrorLogs() {
  const classes = useStyles();

  const timerToClearSomewhere = useRef(null);

  const { device, company, users } = useSelector((state) => ({
    device: state.device,
    company: state.company,
    users: state.users,
  }));

  const [userInfo, setUserInfo] = useState({
    interval: "1 Hr",
    deviceId: "d36a4373-fbdc-44a3-8c96-4bb920041e40",
    macAddress: "all",
    intervalStartTime: new Date(),
    supplierId: "cd5dc314-6e25-48a8-9d47-9e574b8fe3a0",
  });

  const [displayContainer, setDisplayContainer] = useState(false);

  const [toastStatus, setToastStatus] = useState(false);

  const [order, setOrder] = useState("maxValueProvidedOn");

  const [orderBy, setOrderBy] = useState("DESC");

  const [filterData, setFilterData] = useState({});

  const [page, setPage] = useState(PAGINATION_DETAILS.pageNumber);

  const [selected, setSelected] = React.useState([]);

  const [selectedAll, setSelectedAll] = React.useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(PAGINATION_DETAILS.pageSize);

  const [getDeviceList] = useComposeDispatcher(FETCH_DEVICE_REQUEST);

  const [getCompanyList] = useComposeDispatcher(FETCH_COMPANY_REQUEST);

  const [getUserList] = useComposeDispatcher(FETCH_USER_LIST_REQUEST);

  const [checked, setChecked] = React.useState(false);

  const [fetchFriendlyNameData] = useComposeDispatcher(
    FETCH_FRIENDLY_NAME_REQUEST
  );

  const [deleteErrorLogData] = useComposeDispatcher(
    DELETE_ERROR_LOG_DATA_REQUEST
  );

  const [getErrorLogData] = useComposeDispatcher(FETCH_ERROR_LOG_DATA_REQUEST);

  useEffect(() => {
    getCompanyList();
    getUserList({
      role: "Patient",
      pageNumber: 0,
      pageSize: 100,
      sortBy: "firstName",
      sortOrder: "ASC",
    });
    getDeviceList({ status: "Active" });
    getUserList({ role: "Patient" });
    return () => {
      clearInterval(timerToClearSomewhere.current);
    };
  }, []);

  const getDeviceData = () => {
    let dataObj = {
      deviceCriteria: [{ key: "ErrorLog", operator: "IsSet", value: null}],
      deviceDataModelId: `${userInfo.deviceId}-dh`,
      devicePropertyCodes: null
    };
    if (userInfo.macAddress !== "all") {
      dataObj.deviceCriteria = [
        { key: "ErrorLog", operator: "IsSet", value: null },
        { key: "DevidDevhealth", operator: "Equal", value: userInfo.macAddress }
      ];
    }
    const userList = users.userList.users.map((val) => val.userId);
    dataObj.ownerFilter = {users: userInfo.userId !== "all" ? [userInfo.userId] : userList};
    
    let identityDataFrame = []
    if (userInfo.userId !== "all") {
      device.friendlyNameData.forEach(val => {
        if (val.macId != 'all'){
          let identityObj = {
            "deviceDataModelId": "d36a4373-fbdc-44a3-8c96-4bb920041e40-id",
            "ownerFilter": {
              "users": [userInfo.userId]
            },
            "devicePropertyCodes": [
              "FriendlyName", "Devid", "Timezone"
            ],
            "deviceCriteria": [
              {"key": "Devid", "operator": "Equal", "value": val.macId}          
            ]
          }
          identityDataFrame = [...identityDataFrame, identityObj];
        }
      })
    }
    setFilterData(dataObj);
    setSelectedAll(false);
    setPage(0);
    getErrorLogData({
      identityDataFrame,
      payload: {
        pageNumber: 0,
        pageSize: rowsPerPage,
        sortBy: order,
        sortOrder: orderBy,
      },
      data: dataObj,
    });
    setDisplayContainer(true);
    setChecked(false);
    setSelected([]);
  };

  const createSortHandler = (property) => () => {
    const isAsc = order === `data.${property}.value` && orderBy === "ASC";
    setOrderBy(isAsc ? "DESC" : "ASC");
    setOrder(`data.${property}.value`);
    getErrorLogData({
      payload: {
        pageNumber: 0,
        pageSize: rowsPerPage,
        sortBy: `data.${property}.value`,
        sortOrder: isAsc ? "DESC" : "ASC",
      },
      data: filterData,
    });
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setChecked(false);
    setSelected([]);
    getErrorLogData({
      payload: {
        pageNumber: newPage,
        pageSize: rowsPerPage,
        sortBy: order,
        sortOrder: orderBy,
      },
      data: filterData,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setChecked(false);
    setSelected([]);
    getErrorLogData({
      payload: {
        pageNumber: 0,
        pageSize: +event.target.value,
        sortBy: order,
        sortOrder: orderBy,
      },
      data: filterData,
    });
  };

  const onSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = device.errorLog.data.map((n) => n.deviceDataId);
      setSelected(newSelecteds);
      setChecked(event.target.checked);
      return;
    }
    setSelected([]);
    setChecked(false);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    newSelected.length === 0 && setChecked(false);
    setSelected(newSelected);
  };

  const isSelected = (deviceDataId) => selected.indexOf(deviceDataId) !== -1;

  return (
    <>
      <GridContainer>
        <Filter
          device={device}
          company={company}
          userInfo={userInfo}
          userList={users.userList}
          setUserInfo={setUserInfo}
          getUserList={getUserList}
          getDeviceData={getDeviceData}
          timerToClearSomewhere={timerToClearSomewhere}
          fetchFriendlyNameData={fetchFriendlyNameData}
        />
        {displayContainer && (
          <GridItem xs={12}>
            <Card>
              <CardHeader color="danger" icon>
                <CardIcon color="danger">
                  <List />
                </CardIcon>
              </CardHeader>
              <CardBody>
                {selected.length > 0 && (
                  <Toolbar
                    style={{
                      color: "#fd0808",
                      fontSize: "16px",
                      backgroundColor: lighten("#fd0808", 0.85),
                    }}
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={10}
                        style={{
                          margin: "auto",
                        }}
                      >
                        <Typography
                          className={classes.title}
                          color="#fd0808"
                          variant="subtitle1"
                          component="div"
                        >
                          You have selected all {selectedAll ? device.errorLog.count : selected.length} records.{" "}
                          {device.errorLog.count > selected.length && (
                            <Link color="#fd0808" onClick={
                              () => {
                                const currentStatus = selectedAll ? false : true;
                                setSelectedAll(currentStatus);
                              }
                            }>
                              {selectedAll ? `Unselect all` : `Select all`} {device.errorLog.count} records
                            </Link>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          variant="outlined"
                          color="danger"
                          onClick={() => {
                            if(selectedAll){
                              deleteErrorLogData({
                                deviceDataModelId:
                                  "d36a4373-fbdc-44a3-8c96-4bb920041e40-dh",
                                userIds: [userInfo.userId],
                                filter: {
                                  payload: {
                                    pageNumber: page,
                                    pageSize: rowsPerPage,
                                    sortBy: "maxValueProvidedOn",
                                    sortOrder: "DESC",
                                  },
                                  data: filterData,
                                },
                              });
                            } else {
                              deleteErrorLogData({
                                deviceDataModelId:
                                  "d36a4373-fbdc-44a3-8c96-4bb920041e40-dh",
                                deviceDataIds: selected,
                                filter: {
                                  payload: {
                                    pageNumber: page,
                                    pageSize: rowsPerPage,
                                    sortBy: "maxValueProvidedOn",
                                    sortOrder: "DESC",
                                  },
                                  data: filterData,
                                },
                              });
                            }
                            setSelected([]);
                          }}
                        >
                          Confirm Delete
                        </Button>
                      </Grid>
                    </Grid>
                  </Toolbar>
                )}
                <TableContainer className={classes.container}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            inputProps={{ "aria-label": "select all" }}
                            indeterminate={
                              selected.length > 0 &&
                              selected.length < device.errorLog.count
                            }
                            checked={checked}
                            onChange={onSelectAllClick}
                          />
                        </TableCell>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              maxWidth: column.minWidth,
                              minWidth: column.minWidth,
                            }}
                          >
                            {column.sort ? (
                              <TableSortLabel
                                style={{
                                  maxWidth: column.minWidth,
                                  minWidth: column.minWidth,
                                }}
                                active={orderBy === column.id}
                                direction={
                                  orderBy === column.id
                                    ? order.toLowerCase()
                                    : "asc"
                                }
                                onClick={createSortHandler(column.id)}
                              >
                                <Typography>{column.label}</Typography>
                              </TableSortLabel>
                            ) : (
                              <Typography>{column.label}</Typography>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {device.errorLog.count === 0 && (
                        <TableRow>
                          <TableCell align="center" colSpan="7">
                            No records found
                          </TableCell>
                        </TableRow>
                      )}
                      {device.errorLog.data.map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index + "" + row.userId}
                          >
                            {columns.map((column, cIndex) => {
                              let value = row[column.id];
                              if (column.type === "date") {
                                value = moment(row[column.id]).format(
                                  "MM/DD/YYYY hh:mm a"
                                );
                              }
                              let errorStyle = "#000000";
                              if (column.id === "errorType") {
                                if (value.toLowerCase().trim() === "w") {
                                  errorStyle = "orange";
                                  value = "Warning";
                                } else if (value.toLowerCase().trim() === "e") {
                                  errorStyle = "red";
                                  value = "Error";
                                } else if (value.toLowerCase().trim() === "l") {
                                  value = "Log";
                                }
                              }
                              if (column.id === "errorText") {
                                if (
                                  row["errorType"].toLowerCase().trim() === "w"
                                ) {
                                  errorStyle = "orange";
                                } else if (
                                  row["errorType"].toLowerCase().trim() === "e"
                                ) {
                                  errorStyle = "red";
                                }
                              }
                              return (
                                <>
                                  {cIndex === 0 && (
                                    <TableCell padding="checkbox">
                                      <Checkbox
                                        onChange={(event) =>
                                          handleClick(event, row.deviceDataId)
                                        }
                                        checked={isSelected(row.deviceDataId)}
                                        inputProps={{
                                          "aria-labelledby": labelId,
                                        }}
                                      />
                                    </TableCell>
                                  )}
                                  <TableCell
                                    style={{
                                      color: errorStyle,
                                    }}
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.id !== "action" &&
                                    column.format &&
                                    typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                </>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={PAGE_SIZES}
                  component="div"
                  count={device.errorLog.count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                {alert}
              </CardBody>
            </Card>
          </GridItem>
        )}
      </GridContainer>
      <Loader status={device.loading || device.loadingData} />
      <Snackbar
        place="tr"
        color="danger"
        icon={AddAlert}
        message={''}
        open={toastStatus}
        closeNotification={() => setToastStatus(false)}
        close
      />
    </>
  );
}
