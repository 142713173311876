// Options Actions
export const FETCH_COMPANY_REQUEST = "FETCH_COMPANY_REQUEST";
export const FETCH_COMPANY_SUCCESS = "FETCH_COMPANY_SUCCESS";
export const FETCH_COMPANY_ERROR = "FETCH_COMPANY_ERROR";

export const COMPANY_DETAIL_REQUEST = "COMPANY_DETAIL_REQUEST";
export const COMPANY_DETAIL_SUCCESS = "COMPANY_DETAIL_SUCCESS";
export const COMPANY_DETAIL_ERROR = "COMPANY_DETAIL_ERROR";

export const CREATE_COMPANY_REQUEST = "CREATE_COMPANY_REQUEST";
export const CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS";
export const CREATE_COMPANY_ERROR = "CREATE_COMPANY_ERROR";

export const UPDATE_COMPANY_REQUEST = "UPDATE_COMPANY_REQUEST";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_ERROR = "UPDATE_COMPANY_ERROR";

export const DELETE_COMPANY_REQUEST = "DELETE_COMPANY_REQUEST";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_ERROR = "DELETE_COMPANY_ERROR";

export const UPDATE_DEVICE_COMPANY_REQUEST = "UPDATE_DEVICE_COMPANY_REQUEST";
export const UPDATE_DEVICE_COMPANY_ERROR = "UPDATE_DEVICE_COMPANY_ERROR";
