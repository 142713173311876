import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import Loader from "components/Loader/Loader.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";

import formStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import {
  CREATE_PROPERTY_SET_REQUEST,
  FETCH_PROPERTY_SET_DETAIL_REQUEST,
  UPDATE_PROPERTY_SET_REQUEST,
} from "../../redux/device/actions";
import { useComposeDispatcher } from "../../common/hooks";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const userRoles = [
  { key: "SupplierAdmin", title: "Admin" },
  { key: "SupplierUser", title: "Manager" },
  { key: "PracticeUser", title: "Caregiver" },
  { key: "Patient", title: "Patient" },
];

const styles = () => ({
  labelFocused: {
    color: "#E73F3B !important",
  },
  inputFocused: {
    "&:before": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "&:after": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "& input": {
      borderBottom: "#E73F3B !important",
    },
  },
  inputRoot: {
    color: "red",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
});

const InputBox = withStyles(styles)((props) => {
  const {
    labelText,
    helperText,
    classes,
    value,
    id,
    info,
    updateInfo,
    type,
    required,
  } = props;
  return (
    <TextField
      label={labelText}
      helperText={helperText}
      fullWidth
      required={required ? required : false}
      type={type ? type : "text"}
      margin="normal"
      color="primary"
      value={value}
      onChange={(e) => {
        let obj = {};
        obj[id] = e.target.value;
        updateInfo({ ...info, ...obj });
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          focused: classes.labelFocused,
        },
      }}
      InputProps={{
        classes: {
          root: classes.root,
          focused: classes.inputFocused,
        },
      }}
    />
  );
});

const SelectBox = withStyles(styles)((props) => {
  const {
    disabled,
    labelText,
    classes,
    value,
    id,
    info,
    updateInfo,
    options,
    required,
    customField,
  } = props;
  return (
    <TextField
      select
      fullWidth
      disabled={disabled || false}
      label={labelText}
      value={value}
      required={required ? required : false}
      margin="normal"
      color="primary"
      onChange={(e) => {
        if (id === "role") {
          const guestAccountDetail = customField.find(
            (val) => val.name === "Guest Account"
          );
          let obj = {};
          obj[id] = e.target.value;
          obj[guestAccountDetail.fieldId] =
            e.target.value === "Guest" ? "Yes" : "No";
          updateInfo({ ...info, ...obj });
        } else {
          let obj = {};
          obj[id] = e.target.value;
          updateInfo({ ...info, ...obj });
        }
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          focused: classes.labelFocused,
        },
      }}
      InputProps={{
        classes: {
          root: classes.root,
          focused: classes.inputFocused,
        },
      }}
    >
      {options}
    </TextField>
  );
});

const useStyles = makeStyles(formStyle);

export default function CreatePropertySet() {
  const history = useHistory();
  const { id, propertyId } = useParams();
  const { device } = useSelector((state) => ({
    device: state.device,
  }));
  const [userInfo, setUserInfo] = React.useState({});
  const [alertMessage, setAlertMessage] = React.useState("");
  const [toastStatus, setToastStatus] = React.useState(false);
  const [refreshData, setRefreshData] = React.useState(Date.now());

  const [createPropertySet] = useComposeDispatcher(CREATE_PROPERTY_SET_REQUEST);
  const [updatePropertySet] = useComposeDispatcher(UPDATE_PROPERTY_SET_REQUEST);

  const [getPropertySetDetailsById] = useComposeDispatcher(
    FETCH_PROPERTY_SET_DETAIL_REQUEST
  );

  const classes = useStyles();

  useEffect(() => {
    propertyId && getPropertySetDetailsById({ id, propertyId });
  }, [id]);

  // if (id && company.companyDetail.data.name && !userInfo.name) {
  //   setUserInfo(company.companyDetail.data);
  //   setRefreshData(Date.now());
  // }

  let validateForm = () => {
    setToastStatus(false);
    if (!userInfo?.name || !userInfo?.collectionMethod) {
      setAlertMessage("Fields mark with asterisk are required");
      setToastStatus(true);
    } else {
      if (
        userInfo?.collectionMethod === "Specified" &&
        (!userInfo?.rolesAllowedToSpecify ||
          userInfo?.rolesAllowedToSpecify.length === 0)
      ) {
        setAlertMessage("Please select atleast one role");
        setToastStatus(true);
      } else {
        const propertyInfo = {
          collectionMethod:
            userInfo?.collectionMethod === "Collected From Device"
              ? "CollectedFromDevice"
              : userInfo?.collectionMethod,
          description: userInfo?.description,
          deviceId: id,
          deviceProperties: [],
          name: userInfo?.name,
          rolesAllowedToSpecify: userInfo?.rolesAllowedToSpecify,
        };

        if (!propertyId) {
          createPropertySet({ propertyInfo });
        } else {
          propertyInfo.devicePropertySetId = propertyId;
          updatePropertySet({ propertyInfo });
        }
      }
    }
  };

  if (propertyId && device?.propertySetDetails?.name && !userInfo?.name) {
    if (
      device?.propertySetDetails?.collectionMethod === "CollectedFromDevice"
    ) {
      device.propertySetDetails.collectionMethod = "Collected From Device";
    }
    setUserInfo(device?.propertySetDetails);
    setRefreshData(Date.now());
  }

  if (device.success) {
    history.push(`/admin/edit-device/${id}`);
  }

  return (
    <GridContainer key={refreshData}>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="danger" icon>
            <CardIcon color="danger">
              <Person />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Device Property Set</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <InputBox
                  labelText="Name"
                  value={userInfo.name}
                  id="name"
                  required={true}
                  info={userInfo}
                  updateInfo={setUserInfo}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <InputBox
                  labelText="Description"
                  id="description"
                  value={userInfo.description}
                  info={userInfo}
                  updateInfo={setUserInfo}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <SelectBox
                  id="collectionMethod"
                  labelText="Collection Method"
                  disabled={propertyId ? true : false}
                  required={true}
                  value={userInfo?.collectionMethod}
                  info={userInfo}
                  updateInfo={setUserInfo}
                  options={["Specified", "Collected From Device"].map((val) => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      key={val}
                      value={val}
                    >
                      {val}
                    </MenuItem>
                  ))}
                />
              </GridItem>
              {userInfo?.collectionMethod === "Specified" && (
                <GridItem xs={12} sm={12} md={4}>
                  <Autocomplete
                    multiple
                    fullWidth
                    options={userRoles}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.title}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.title}
                      </React.Fragment>
                    )}
                    value={
                      userInfo?.rolesAllowedToSpecify
                        ? userInfo?.rolesAllowedToSpecify.reduce(
                            (acc, data) => {
                              const linkedData = userRoles.find(
                                (val) => val.key === data
                              );
                              if (linkedData) {
                                return [...acc, linkedData];
                              }
                              return acc;
                            },
                            []
                          )
                        : []
                    }
                    onChange={(event, val) => {
                      const field = { ...userInfo };
                      if (val.length > 0) {
                        field.rolesAllowedToSpecify = val.map(
                          (data) => data.key
                        );
                      } else {
                        delete field.rolesAllowedToSpecify;
                      }
                      setUserInfo(field);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        color="primary"
                        required={true}
                        label="Select Role(s) That Can Specify"
                        InputLabelProps={{
                          shrink: true,
                          classes: {
                            focused: classes.labelFocused,
                          },
                        }}
                      />
                    )}
                  />
                </GridItem>
              )}
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Box textAlign="right">
          <Button onClick={() => validateForm()} color="danger">
            {propertyId ? "Save" : "Submit"}
          </Button>
          <Button
            onClick={() => {
              history.push(`/admin/edit-device/${id}`);
            }}
          >
            Cancel
          </Button>
        </Box>
      </GridItem>
      <Snackbar
        place="tr"
        color="danger"
        icon={AddAlert}
        message={alertMessage}
        open={toastStatus}
        closeNotification={() => setToastStatus(false)}
        close
      />
      <Loader status={device.loading} />
    </GridContainer>
  );
}
