import * as service from "../common/service";
import { API_BASE_URL } from "../common/constants";

const userApi = `${API_BASE_URL}/user/patientdevice`;
const token =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJlbWFpbEFkZHJlc3MiOiJ3aG9obWFuQGNhcmRpby5pbyIsInJvbGUiOiJUZW5hbnRBZG1pbiIsImFwcFR5cGUiOiJERVZJQ0UiLCJpc3MiOiJHYWxlbkNsb3VkIiwidGVuYW50SWQiOiJBVFMtREVWIiwicHJpbmNpcGFsSWQiOiJhOTIzZWQyMS05OWU4LTRiMjktOWRiOS0yYTQ5NjdmNzQ1MTciLCJ0b2tlblR5cGUiOiJBdXRoVG9rZW4iLCJleHAiOjE2MjgxMDAwMzF9.Uy6W7tlOFJ73KEbepeR3XeaWhia0jTkMQiwLPsSj_xW_GlSTBoi33sKW2N0ia48s2dqNWaM4p3KOjo2eFvu10g";

const patientDeviceService = {
  getPatientDeviceList: (payload) => {
    const headers = { Authorization: "Bearer " + token };
    let filter = "";
    if (payload && Object.keys(payload).length > 0) {
      filter = Object.keys(payload).reduce(
        (acc, val, index) =>
          Object.keys(payload).length - 1 === index
            ? `${acc}${val}=${payload[val]}`
            : `${acc}${val}=${payload[val]}&`,
        "?"
      );
    }
    return service.get(`${userApi}${filter}`, {}, headers);
  },
};

export default patientDeviceService;
