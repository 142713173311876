import React, { useEffect, useState, useRef } from "react";
import AddAlert from "@material-ui/icons/AddAlert";
import { useSelector } from "react-redux";
import html2canvas from "html2canvas";
import moment from "moment-timezone";
import { jsPDF } from "jspdf";

// core components
import { SUCCESS_NOTIFICATION_REQUEST } from "../../redux/notifications/actions";
import { GRAPH_DATA_REQUEST, UTC_TIME_ZONE } from "../../common/constants";
import { FETCH_USER_LIST_REQUEST } from "../../redux/users/actions";
import { FETCH_COMPANY_REQUEST } from "../../redux/company/actions";
import GridContainer from "components/Grid/GridContainer.js";
import { useComposeDispatcher } from "../../common/hooks";
import Snackbar from "components/Snackbar/Snackbar.js";
import Loader from "components/Loader/Loader.js";
import GraphDetail from "./GraphDetail.js";
import Devices from "./Devices.js";
import Filter from "./Filter.js";
import {
  FETCH_NEW_USER_MIN_DATA_UPDATE_REQUEST,
  UPDATE_SENSOR_CONTROL_TABLE_REQUEST,
  FETCH_NEW_USER_MIN_DATA_REQUEST,
  FETCH_UPDATE_USER_DATA_REQUEST,
  UPDATE_SENSOR_CONTROL_REQUEST,
  FETCH_USER_ORDER_DATA_REQUEST,
  FETCH_DEVICE_DETAILS_REQUEST,
  FETCH_SENSOR_CONTROL_REQUEST,
  FETCH_NEW_USER_DATA_REQUEST,
  FETCH_FRIENDLY_NAME_REQUEST,
  FETCH_MIN_CSV_DATA_REQUEST,
  FETCH_NEW_DEV_DATA_REQUEST,
  FETCH_SVITTAL_DATA_REQUEST,
  FETCH_NEW_SV_DATA_REQUEST,
  FETCH_DEVICE_DATA_REQUEST,
  FETCH_PARAMS_DATA_REQUEST,
  SAVE_USER_ORDER_REQUEST,
  FETCH_CSV_DATA_REQUEST,
  FETCH_NEW_DATA_REQUEST,
  FETCH_DEVICE_REQUEST,
  RESET_GRAPH_REQUEST,
  START_GRAPH_LOADER
} from "../../redux/device/actions";

import {
  FETCH_GUESTS_REQUEST
} from "../../redux/guestMap/actions";

export default function MonitorDashboard() {
  const userDetails = JSON.parse(localStorage.getItem("user-details"));
  const loggedUserId = userDetails !== null ? userDetails.userId : "";
  const loggedUserRole = userDetails !== null ? userDetails.roles[0].role : "";
  const loggedUserSupplier = userDetails !== null ? userDetails.roles[0].supplier : "";
  const selectedDevice = [];

  const svittalDashboardDrawerRef = useRef(null);
  const devTimerToClearSomewhere = useRef(null);
  const timerToClearSomewhere = useRef(null);
  const svittalDrawerRef = useRef(null);
  const drawerRef = useRef(null);

  const { device, company, users, guestMapData } = useSelector((state) => ({
    device: state.device,
    company: state.company,
    users: state.users,
    guestMapData: state.guestMap
  }));

  const [userInfo, setUserInfo] = useState({
    interval: "1 Hr",
    deviceDataModelId: "d36a4373-fbdc-44a3-8c96-4bb920041e40",
    macAddress: "all",
    intervalStartTime: new Date(),
    supplierId: "cd5dc314-6e25-48a8-9d47-9e574b8fe3a0",
  });

  const [isSensorPositionSaved, setIsSensorPositionSaved] = useState(false);
  const [selectedStartDate, handleStartDateChange] = useState(new Date());
  const [selectedEndDate, handleEndDateChange] = useState(new Date());
  const [fetchGraphDataCall, setFetchGraphDataCall] = useState(false);
  const [showSensorControl, setShowSensorControl] = useState(false);
  const [selectedReadingParams, setReadingParams] = useState(null);
  const [userTimeZone, setUserTimeZone] = useState(UTC_TIME_ZONE);
  const [disablePdfBtn, setDisablePdfBtn] = useState(false);
  const [apiRequestCount, setApiRequestCount] = useState(0);
  const [dayLightSaving, setDayLightSaving] = useState(0);
  const [startTime, setStartTime] = useState(Date.now());
  const [userWithOrder, setUserWithOrder] = useState({});
  const [userMacAddress, setUserMacAddress] = useState();
  const [realTimeType, setRealTimeType] = useState(true);
  const [toastStatus, setToastStatus] = useState(false);
  const [showSensors, setShowSensors] = useState(false);
  const [autoFetchData, setAutoFetchData] = useState(1);
  const [alertMessage, setAlertMessage] = useState("");
  const [guestSensors, setGuestSensors] = useState([]);
  const [endTime, setEndTime] = useState(Date.now());
  const [friendlyName, setFriendlyName] = useState();
  const [lastFilter, setLastFilter] = useState({});
  const [nextCall, setNextCall] = useState(false);
  const [devDataId, setDevDataId] = useState();
  const [drawer, setDrawer] = useState(false);
  const [sensor, setSensor] = useState({});
  const [offset, setOffset] = useState(0);
  const [abbr, setAbbr] = useState("GMT");

  const [updateSensorControlTableRequest] = useComposeDispatcher(UPDATE_SENSOR_CONTROL_TABLE_REQUEST);
  const [fetchUserMinDeviceUpdateData] = useComposeDispatcher(FETCH_NEW_USER_MIN_DATA_UPDATE_REQUEST);
  const [updateSensorControlRequest] = useComposeDispatcher(UPDATE_SENSOR_CONTROL_REQUEST);
  const [fetchUserDeviceUpdateData] = useComposeDispatcher(FETCH_UPDATE_USER_DATA_REQUEST);
  const [successNotificationRequest] = useComposeDispatcher(SUCCESS_NOTIFICATION_REQUEST);
  const [fetchUserMinDeviceData] = useComposeDispatcher(FETCH_NEW_USER_MIN_DATA_REQUEST);
  const [fetchFriendlyNameData] = useComposeDispatcher(FETCH_FRIENDLY_NAME_REQUEST);
  const [sensorControlRequest] = useComposeDispatcher(FETCH_SENSOR_CONTROL_REQUEST);
  const [getUserProfileData] = useComposeDispatcher(FETCH_USER_ORDER_DATA_REQUEST);
  const [fetchUserDeviceData] = useComposeDispatcher(FETCH_NEW_USER_DATA_REQUEST);
  const [getDeviceDataById] = useComposeDispatcher(FETCH_DEVICE_DETAILS_REQUEST);
  const [svittalDataRequest] = useComposeDispatcher(FETCH_SVITTAL_DATA_REQUEST);
  const [newDevDataRequest] = useComposeDispatcher(FETCH_NEW_DEV_DATA_REQUEST);
  const [paramsDataRequest] = useComposeDispatcher(FETCH_PARAMS_DATA_REQUEST);
  const [getDeviceDataList] = useComposeDispatcher(FETCH_DEVICE_DATA_REQUEST);
  const [saveUserDeviceOrder] = useComposeDispatcher(SAVE_USER_ORDER_REQUEST);
  const [newSvDataRequest] = useComposeDispatcher(FETCH_NEW_SV_DATA_REQUEST);
  const [fetchMinCsvData] = useComposeDispatcher(FETCH_MIN_CSV_DATA_REQUEST);
  const [newDataRequest] = useComposeDispatcher(FETCH_NEW_DATA_REQUEST);
  const [getCompanyList] = useComposeDispatcher(FETCH_COMPANY_REQUEST);
  const [startGraphLoader] = useComposeDispatcher(START_GRAPH_LOADER);
  const [getUserList] = useComposeDispatcher(FETCH_USER_LIST_REQUEST);
  const [getGuestsList] = useComposeDispatcher(FETCH_GUESTS_REQUEST);
  const [getDeviceList] = useComposeDispatcher(FETCH_DEVICE_REQUEST);
  const [resetGraphData] = useComposeDispatcher(RESET_GRAPH_REQUEST);
  const [fetchCsvData] = useComposeDispatcher(FETCH_CSV_DATA_REQUEST);

  useEffect(() => {
    if (loggedUserRole !== "Guest") {
      getUserProfileData({deviceDataModelId: "6df2d80f-be28-47ac-a509-8f0b6aa3bbb8"});
      getCompanyList();
    }
    getDeviceDataById('6df2d80f-be28-47ac-a509-8f0b6aa3bbb8');
    getDeviceList({status: "Active"});
    getUserList({
      role: "Patient",
      pageNumber: 0,
      pageSize: 100,
      sortBy: "firstName",
      sortOrder: "ASC",
    });
 if (loggedUserRole === "Patient") {
      setUserInfo({ ...userInfo, userId: loggedUserId });
      fetchFriendlyNameData({userId: loggedUserId,deviceDataModelId: userInfo.deviceDataModelId});
    }

    if (loggedUserRole !== "TenantAdmin" && loggedUserRole !== "Patient") {
      getUserList({role: "Patient", supplierId: loggedUserSupplier?.supplierId});
    }
    if (loggedUserRole === "Guest") {
      getGuestsList({"deviceDataModelId": "299992ad-6d4a-4020-8501-8a004d45fffc"});
      fetchFriendlyNameData({deviceDataModelId: userInfo.deviceDataModelId});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener("visibilitychange", onTabChange);
    return () => {
      window.removeEventListener('visibilitychange', onTabChange);
    };
  }, [drawer])

  let tempTimeout = '';
  const onTabChange = () => {
    clearTimeout(tempTimeout);
    tempTimeout = setTimeout(() => {
      console.log(drawer, 'drawerdrawerdrawerdrawer 1');
      if(drawer) {
        if (document.visibilityState == "visible") {
          console.log("tab is active");
          fetchButtonTapped();
        } else {
          console.log("tab is changed/inactive");
          clearInterval(drawerRef.current);
          clearInterval(svittalDrawerRef.current);
        }
      }
    }, 500);

    return () => {
      clearTimeout(tempTimeout);
    };
  }

  
  const fetchButtonTapped = () => {
    const isValidDate = moment(userInfo.intervalStartTime,"MM/DD/YYYY h:mm a",true).isValid();
    if (isValidDate) {
      clearInterval(drawerRef.current);
      clearInterval(svittalDrawerRef.current);
      setTimeout(() => {
        resetGraphData();
      }, 3000);
      startGraphLoader();
      setTimeout(() => {
        intervalChange( userTimeZone, true, userInfo.interval, userInfo.devID);
      }, 5000);
    } else {
      setToastStatus(true);
      setAlertMessage("Please enter Start Time in MM/DD/YYYY h:mm a format (Example: 04/27/2022 1:14 pm)");
    }
  }

  const changeLocalDevicePosition = (flag = false) => {
    let userGraphDataNew = {};
    let userGraphData = device?.graphData[0]?.linkedGraphData;
    const savedSensorPositionStr = userWithOrder?.data?.Sensorposition?.value;
    if (userGraphData && savedSensorPositionStr) {
      const sensorsArr = savedSensorPositionStr.split(';');
      let savedSensorPositionArr = sensorsArr.sort((a,b) => {return a.split('/')[1] - b.split('/')[1]});

      /* console.log(savedSensorPositionStr, savedSensorPositionArr) */;

      for(var i=0; i<savedSensorPositionArr.length; i++) {
          for(let ele in userGraphData) {
              if(savedSensorPositionArr[i].split('/')[0] === ele) {
                  userGraphDataNew[ele] = userGraphData[ele];
              }
          }
      }

      /* || This loop to excecute in case new sensor added after order set into DB || */
      for(let ele in userGraphData) {
          if (!savedSensorPositionStr.includes(ele)){            
            userGraphDataNew[ele] = userGraphData[ele];
          }
      }

      /* console.log(userGraphDataNew, device?.graphData[0].linkedGraphData, '----LIST DATA------'); */
      device.graphData[0].linkedGraphData = userGraphDataNew;
      device.loadingData = false;
    }

    if (!savedSensorPositionStr) {
      device.loadingData = false;
    }

    if (flag && userGraphData && !device.loadingData) {
      setShowSensors(true);
    }

    if (loggedUserRole === "Guest") {
      filterGuestSensors();
    }
  }

  /* This function will be called for only user having GUEST Role, to filter out sensors which he has access */
  const filterGuestSensors = () => {
    let tempGraphData = {};
    if(guestSensors.length && device?.graphData?.length && Object.keys(device?.graphData[0]?.linkedGraphData).length) {
      for(let i=0; i < guestSensors.length; i++) {
          for(let key in device?.graphData[0].linkedGraphData) {
              if(guestSensors[i]?.macId !== "all" && key === guestSensors[i]?.macId) {
                tempGraphData[key] = device?.graphData[0].linkedGraphData[key];
              }
          }
      }
      device.graphData[0].linkedGraphData = tempGraphData;
      device.loadingData = false;
    }
  }

  const getDeviceData = () => {
    setLastFilter({});
    setShowSensors(false);
    getUserProfileDataFun();
    let userObj = userInfo;
    if (!userObj?.supplierId) {
      let obj = { supplierId: loggedUserSupplier ? loggedUserSupplier?.supplierId : company.companyList[0].supplierId};
      userObj.supplierId = loggedUserSupplier ? loggedUserSupplier?.supplierId : company.companyList[0].supplierId;
      setUserInfo({ ...userInfo, ...obj });
    }

    if (!userObj.deviceId) {
      let obj = {deviceId: device.deviceList[0].deviceId};
      userObj.deviceId = device.deviceList[0].deviceId;
      setUserInfo({ ...userInfo, ...obj });
    }

    // Clear intervals before proceeding
    clearInterval(timerToClearSomewhere.current);
    clearInterval(devTimerToClearSomewhere.current);
    clearInterval(svittalDashboardDrawerRef.current);

    if (!userObj?.deviceId || !userObj?.supplierId) {
      setAlertMessage("Device and company both are mandatory");
      setToastStatus(true);
    } else {
      let m = moment();
      const rangeStartDateTime = m.utc().subtract(60, "minutes").format("YYYY-MM-DDTHH:mm:ss");
      const rangeEndDateTime = moment().utc().format("YYYY-MM-DDTHH:mm:ss");
      let ownerFilter = {};
      if (loggedUserRole === "Patient") {
        ownerFilter = {users: [loggedUserId]};
      } else {
        if (userInfo.userId === "all" && loggedUserRole !== "Guest") {
          ownerFilter = {
            users: users.userList.users.reduce((acc, val) => {
              if (val.defaultRole.supplier.supplierId === userInfo.supplierId) {
                return [...acc, val.userId];
              }
              return acc;
          }, [])};
        } else {
          ownerFilter = {users: userInfo?.userId && userInfo?.userId !== "all" ? [userInfo?.userId] : []};
        }
      }
      setStartTime(rangeStartDateTime);
      let selectedTime = moment.utc(rangeStartDateTime).tz(userTimeZone).format("MM/DD/YYYY h:mm a");
      
      handleStartDateChange(selectedTime);
     
      setEndTime(rangeEndDateTime);

      const deviceName = device.deviceList.find((val) => val.deviceId === userObj.deviceId)?.name.split("-")[0];

      let finalObj = {
        deviceDataModelId: userObj.deviceId,
        deviceName,
        supplierId: userObj.supplierId,
        devicePropertyCodes: ["Hr","Rr","Stress","Motion","DevidPvital","FriendlyNameP","LocationP","Offline","RssRrMv","RssHrMv","Sdnn","Rmssd","Occupied"],
        ownerFilter,
        deviceCriteria: [{key: "Timestamp", operator: "Between", valueFrom: rangeStartDateTime, valueTo: rangeEndDateTime}],
      };

      if (userInfo.macAddress !== "all" && userInfo.macAddress) {
        finalObj.macId = userInfo.macAddress;
        finalObj.deviceCriteria = [
          ...finalObj.deviceCriteria,
          { key: "DevidPvital", operator: "Equal", value: userInfo.macAddress},
        ];
      }
      let dhObj1 = {
        deviceDataModelId: `${userInfo.deviceId}-dh`,
        aggregationPropertyOptions: [
          {propertyCode: "Hwvers", aggregationType: "Last"},
          {propertyCode: "Fwvers", aggregationType: "Last"},
          {propertyCode: "WifiRss", aggregationType: "Last"},
          {propertyCode: "DevidDevhealth", aggregationType: "First"},
          {propertyCode: "RouterMac1", aggregationType: "Last"},
          {propertyCode: "RouterMac2", aggregationType: "Last"}
        ],

        deviceCriteriaGroup: {
          isOr: false,
          groupOwner: null,
          groupElements: [{
            isOr: false,
            groupOwner: null,
            groupElements: [{
                key: "TimestampD",
                operator: "Between",
                valueFrom: moment(rangeEndDateTime).subtract(1, "year").format("YYYY-MM-DDTHH:mm:ss"),
                valueTo: rangeEndDateTime
            }]
          }],
        },
        groupingPropertyOptions: [{propertyCode: "DevidDevhealth"}],
        firstOrLastAggregationTypeSortBy: "TimestampD",
        ownerFilter: ownerFilter,
      };
      let dhObj = { ...finalObj };
      delete dhObj.deviceCriteria;
      dhObj.deviceCriteria = [{
        key: "TimestampD",
        operator: "Between",
        valueFrom: moment(rangeEndDateTime).subtract(1, "year").format("YYYY-MM-DDTHH:mm:ss"),
        valueTo: rangeEndDateTime,
      }];
      dhObj.deviceId = `${userInfo.deviceId}-dh`;
      dhObj.devicePropertyCodes = ["DevidDevhealth","Hwvers","Fwvers","WifiRss","RouterMac1","RouterMac2"];

      if (userInfo.macAddress !== "all" && userInfo.macAddress) {
        dhObj1.deviceCriteriaGroup.groupElements[0].groupElements = [
          ...dhObj1.deviceCriteriaGroup.groupElements[0].groupElements,
          {key: "DevidDevhealth",operator: "Equal",value: userInfo.macAddress},
        ];
        dhObj.deviceCriteria = [
          ...dhObj.deviceCriteria,
          {key: "DevidDevhealth", operator: "Equal", value: userInfo.macAddress},
        ];
      }
      let svObj = { ...finalObj };
      delete svObj.deviceCriteria;
      svObj.deviceCriteria = [
        { key: "TimestampSv", operator: "Between",valueFrom: rangeStartDateTime, valueTo: rangeEndDateTime},
        { key: "Apneaperminute", operator: "IsSet",value: null, isDateValue: false},
        { key: "Occupied", operator: "IsSet", value: null, isDateValue: false },
      ];
      svObj.deviceDataModelId = `${userInfo.deviceId}-sv`;
      svObj.devicePropertyCodes = ["DevidSvital", "Occupied", "Apneaperminute"];
      if (userInfo.macAddress !== "all" && userInfo.macAddress) {
        svObj.deviceCriteria = [
          ...svObj.deviceCriteria,
          {key: "DevidSvital", operator: "Equal", value: userInfo.macAddress},
        ];
      }
      getDeviceDataList({
        pVittal: finalObj,
        devHealth: dhObj1,
        sVittal: svObj,
      });
      setLastFilter({ pVittal: finalObj, devHealth: dhObj1 });
    }
  };

  useEffect(() => {
    if (device.updateSensorStatus) {
      getDeviceData();
      setShowSensorControl(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device.updateSensorStatus]);

  if (nextCall && device?.graphData?.length) {
    if (sensor?.userId) {
      const currentUser = device?.graphData.find((val) => val.userId === sensor.userId);
      const HRValues = [],
        RRValues = [],
        Timings = [];

      if (currentUser?.graphData?.length) {
        currentUser.graphData.forEach((val) => {
          Timings.push(val.data.Hr.valueProvidedOn);
          HRValues.push([new Date(val.data.Hr.valueProvidedOn),val.data.Hr.value]);
          RRValues.push([new Date(val.data.Hr.valueProvidedOn),val.data.Rr.value]);
        });
      } else {
        const currentTime = moment().utc().format("YYYY-MM-DDTHH:mm:ss");
        HRValues.push([new Date(currentTime), 0]);
        RRValues.push([new Date(currentTime), 0]);
      }
    }
    setNextCall(false);
  }

  useEffect(() => {
    console.log('Called 1', realTimeType);
    if (realTimeType && device?.graphData?.length) {
      let apiRequest = apiRequestCount;

      timerToClearSomewhere.current = setInterval(() => {
        console.log('Called 2', timerToClearSomewhere.current);
        let ownerFilter = {};
        if (loggedUserRole === "Patient") {
          ownerFilter = {users: [loggedUserId]};
        } else {
          if (userInfo.userId === "all" && loggedUserRole !== "Guest") {
            ownerFilter = {
              users: users.userList.users.reduce((acc, val) => {
                if (val.defaultRole.supplier.supplierId === userInfo.supplierId) {
                  return [...acc, val.userId];
                }
                return acc;
              }, []),
            };
          } else {
            ownerFilter = {users: userInfo.userId && userInfo.userId !== "all" ? [userInfo.userId] : []};
          }
        }

        let currentTime = moment().utc().format("YYYY-MM-DDTHH:mm:ss");
        let m = moment();
        const rangeStartDateTime = m.utc().subtract(2, "minutes").format("YYYY-MM-DDTHH:mm:ss");
        setNextCall(true);
        let finalObj = {
          deviceDataModelId: userInfo.deviceId,
          supplierId: userInfo.supplierId,
          devicePropertyCodes: ["Hr", "Rr", "Stress", "Motion", "DevidPvital", "FriendlyNameP", "LocationP", "Offline", "RssRrMv", "RssHrMv", "Sdnn", "Rmssd", "Occupied"],
          ownerFilter,
        };
        let dhObj = { ...finalObj };
        dhObj.deviceId = `${userInfo.deviceId}-dh`;
        dhObj.devicePropertyCodes = [ "DevidDevhealth", "Hwvers", "Fwvers", "WifiRss", "RouterMac1", "RouterMac2"];
        dhObj.deviceCriteria = [{key: "TimestampD", operator: "Between", valueFrom: rangeStartDateTime, valueTo: currentTime}];
        finalObj.deviceCriteria = [{key: "Timestamp", operator: "Between", valueFrom: rangeStartDateTime, valueTo: currentTime}];
        if (userInfo.macAddress !== "all" && userInfo.macAddress) {
          finalObj.deviceCriteria = [
            ...finalObj.deviceCriteria,
            {
              key: "DevidPvital",
              operator: "Equal",
              value: userInfo.macAddress,
            },
          ];
          dhObj.deviceCriteria = [
            ...dhObj.deviceCriteria,
            {
              key: "DevidDevhealth",
              operator: "Equal",
              value: userInfo.macAddress,
            },
          ];
        }
        const filterRequest = { pVittal: finalObj };
        newDataRequest(filterRequest);
        if (drawer) {
          if (apiRequest > 8) {
            apiRequest = 0 + 1;
            setApiRequestCount(apiRequest);
          } else {
            apiRequest = apiRequest + 1;
            setApiRequestCount(apiRequest);
          }
        }
      }, 4000);

      devTimerToClearSomewhere.current = setInterval(() => {
          let ownerFilter = {};
          if (loggedUserRole === "Patient") {
            ownerFilter = {users: [loggedUserId]};
          } else {
            if (userInfo.userId === "all") {
              ownerFilter = {
                users: users.userList.users.reduce((acc, val) => {
                  if (val.defaultRole?.supplier?.supplierId === userInfo.supplierId) {
                    return [...acc, val.userId];
                  }
                  return acc;
                }, []),
              };
            } else {
              ownerFilter = { users: userInfo.userId && userInfo.userId !== "all" ? [userInfo.userId] : [] };
            }
          }
          let currentTime = moment().utc().format("YYYY-MM-DDTHH:mm:ss");
          var m = moment();
          const rangeStartDateTime = m.utc().subtract(2, "minutes").format("YYYY-MM-DDTHH:mm:ss");
          setNextCall(true);
          let dhObj1 = {
            deviceDataModelId: `${userInfo.deviceId}-dh`,
            aggregationPropertyOptions: [
              {propertyCode: "Hwvers", aggregationType: "Last"},
              {propertyCode: "Fwvers", aggregationType: "Last"},
              {propertyCode: "WifiRss", aggregationType: "Last"},
              {propertyCode: "DevidDevhealth", aggregationType: "First"},
              {propertyCode: "RouterMac1", aggregationType: "Last"},
              {propertyCode: "RouterMac2", aggregationType: "Last"}
            ],
            deviceCriteriaGroup: {
              isOr: false,
              groupOwner: null,
              groupElements: [
                {
                  isOr: false,
                  groupOwner: null,
                  groupElements: [
                    {
                      key: "TimestampD",
                      operator: "Between",
                      valueFrom: moment(currentTime)
                        .subtract(1, "year")
                        .format("YYYY-MM-DDTHH:mm:ss"),
                      valueTo: currentTime,
                    },
                  ],
                },
              ],
            },
            groupingPropertyOptions: [
              {
                propertyCode: "DevidDevhealth",
              },
            ],
            firstOrLastAggregationTypeSortBy: "TimestampD",
            ownerFilter: ownerFilter,
          };
          let dhObj = {
            deviceDataModelId: `${userInfo.deviceId}-dh`,
            devicePropertyCodes: ["DevidDevhealth", "Hwvers", "Fwvers", "WifiRss", "RouterMac1", "RouterMac2"],
            supplierId: userInfo.supplierId,
            deviceCriteria: [
              {
                key: "TimestampD",
                operator: "Between",
                valueFrom: rangeStartDateTime,
                valueTo: currentTime,
              },
            ],
            ownerFilter,
          };
  
          if (userInfo.macAddress !== "all" && userInfo.macAddress) {
            dhObj1.deviceCriteriaGroup.groupElements[0].groupElements = [
              ...dhObj1.deviceCriteriaGroup.groupElements[0].groupElements,
              {
                key: "DevidDevhealth",
                operator: "Equal",
                value: userInfo.macAddress,
              },
            ];
            dhObj.deviceCriteria = [
              ...dhObj.deviceCriteria,
              {
                key: "DevidDevhealth",
                operator: "Equal",
                value: userInfo.macAddress,
              },
            ];
          }
          const filterRequest = { devHealth: dhObj1 };
          newDevDataRequest(filterRequest);
      }, 30000);

      svittalDashboardDrawerRef.current = setInterval(() => {
          let ownerFilter = {};
          if (loggedUserRole === "Patient") {
            ownerFilter = {users: [loggedUserId]};
          } else {
            if (userInfo.userId === "all") {
              ownerFilter = {
                users: users.userList.users.reduce((acc, val) => {
                  if (val.defaultRole?.supplier?.supplierId === userInfo.supplierId) {
                    return [...acc, val.userId];
                  }
                  return acc;
                }, []),
              };
            } else {
              ownerFilter = {
                users:
                  userInfo.userId && userInfo.userId !== "all"
                    ? [userInfo.userId]
                    : [],
              };
            }
          }
          let currentTime = moment().utc().format("YYYY-MM-DDTHH:mm:ss");
          var m = moment();
          const rangeStartDateTime = m.utc().subtract(2, "minutes").format("YYYY-MM-DDTHH:mm:ss");
          setNextCall(true);
          let svObj = {
            deviceDataModelId: `${userInfo.deviceId}-sv`,
            devicePropertyCodes: ["DevidSvital", "Occupied", "Apneaperminute"],
            supplierId: userInfo.supplierId,
            deviceCriteria: [
              { key: "TimestampSv", operator: "Between", valueFrom: rangeStartDateTime, valueTo: currentTime },
              { key: "Apneaperminute", operator: "IsSet",value: null, isDateValue: false},
              { key: "Occupied", operator: "IsSet", value: null, isDateValue: false},
            ],
            ownerFilter,
          };
  
          if (userInfo.macAddress !== "all" && userInfo.macAddress) {
            svObj.deviceCriteria = [
              ...svObj.deviceCriteria,
              {key: "DevidSvital", operator: "Equal", value: userInfo.macAddress},
            ];
          }
          newSvDataRequest(svObj);
      }, 30000);
    }
    changeLocalDevicePosition();

    return () => {
      console.log("called 123");
      clearInterval(timerToClearSomewhere.current);
      clearInterval(devTimerToClearSomewhere.current);
      clearInterval(svittalDashboardDrawerRef.current);
    };
  }, [realTimeType, device?.graphData?.length]);

  if (
    device.backDateData &&
    device.rangeStartDateTime &&
    device.rangeEndDateTime &&
    fetchGraphDataCall &&
    device.graphArray.hrValue.length > 4
  ) {
    let startTime = moment.utc(device.rangeStartDateTime).tz(userTimeZone).format("MM/DD/YYYY h:mm:ss a");
    
    handleStartDateChange(startTime);
    setStartTime(device.rangeStartDateTime);

    let newEndTime = moment.utc(device.rangeEndDateTime).tz(userTimeZone).format("MM/DD/YYYY h:mm:ss a");
    handleEndDateChange(newEndTime);
    setEndTime(device.rangeEndDateTime);

    setFetchGraphDataCall(false);
  }

  useEffect(() => {
    console.log('Called 4', realTimeType)
    if (drawer && realTimeType && !device.loadingData) {
      let timeRate = 20000;
      console.log('fetchGraphDataCall ', fetchGraphDataCall)
      const uI = userInfo?.interval;
      let smallInterval = false;
      if (uI === "1 min" || uI === "2 mins" || uI === "5 mins" || uI === "10 mins" || uI === "30 mins") {
        timeRate = 6000;
        smallInterval = true;
      }
      
      let counter = 0;
      drawerRef.current = setInterval(() => {
          counter++;
          let newStartTime = moment.utc(startTime).add((timeRate/1000) * counter, "seconds").format("YYYY-MM-DDTHH:mm:ss");
   
          let modifiedTime = moment.utc(endTime).add((timeRate/1000) * counter, "seconds").format("YYYY-MM-DDTHH:mm:ss");
          let newEndTime = moment.utc(modifiedTime).tz(userTimeZone).format("MM/DD/YYYY h:mm:ss a");
          setEndTime(modifiedTime);
          let selectedTime = moment.utc(newStartTime).tz(userTimeZone).format("MM/DD/YYYY h:mm a");
          
          handleStartDateChange(selectedTime);
          
          setStartTime(newStartTime);
          handleEndDateChange(newEndTime);

          console.log('modifiedTime ', modifiedTime)
  
          let graphRequest = GRAPH_DATA_REQUEST;
          graphRequest.deviceDataModelId = userInfo.deviceId;
          graphRequest.deviceCriteria[1].valueFrom = newStartTime;
          graphRequest.deviceCriteria[1].valueTo = modifiedTime;
          graphRequest.ownerFilter.users = [sensor.userId];
          graphRequest.currentUserTimeZone = userTimeZone;
          graphRequest.offset = userInfo.startTime ? 0 : offset;
          graphRequest.dayLightSaving = dayLightSaving;
          if (devDataId) {
            graphRequest.deviceCriteria[0].value = devDataId;
          }
          if ( userInfo.interval.split(" ")[1] === "secs" || userInfo.interval.split(" ")[1] === "mins") {
            graphRequest.options.groupType = "Second";
          }
          graphRequest.interval = userInfo.interval;
  
          if (
            userInfo.interval === "1 min" ||
            userInfo.interval === "2 mins" ||
            userInfo.interval === "5 mins" ||
            userInfo.interval === "10 mins" ||
            userInfo.interval === "30 mins"
          ) {
            let obj = {
              abbr,
              deviceDataModelId: userInfo.deviceId,
              supplierId: userInfo.supplierId,
              ownerFilter: {
                users: [sensor.userId],
              },
              offset: userInfo.startTime ? 0 : offset,
              dayLightSaving: dayLightSaving,
              currentUserTimeZone: userTimeZone,
              devicePropertyCodes: ["Hr","Rr","Stress","Motion","DevidPvital","FriendlyNameP","LocationP","RssHrMv","Occupied"],
              deviceCriteria: graphRequest.deviceCriteria,
            };
            if (devDataId) {
              obj["devId"] = devDataId;
            }
            fetchUserMinDeviceUpdateData(obj);
          } else {
            graphRequest.options.groupType = "Minute";
            fetchUserDeviceUpdateData({...graphRequest, dataPropertyCode: "Hr"});
            fetchUserDeviceUpdateData({...graphRequest, dataPropertyCode: "Rr"});
            fetchUserDeviceUpdateData({...graphRequest, dataPropertyCode: "Stress"});
            fetchUserDeviceUpdateData({...graphRequest, dataPropertyCode: "Motion"});
          }
        },
        smallInterval  ? 6000 : 20000, selectedEndDate
      );
      let newCounter = 0;
  
      svittalDrawerRef.current = setInterval(() => {
          newCounter++;
          var newStartTime = moment.utc(startTime).format("YYYY-MM-DDTHH:mm:ss");
           
          var modifiedTime = moment
            .utc(endTime)
            .add(20 * newCounter, "seconds")
            .format("YYYY-MM-DDTHH:mm:ss");
  
          let svittalObj = {
            deviceDataModelId: `${userInfo.deviceId}-sv`,
            devicePropertyCodes: ["DevidSvital", "Apneaperminute", "Occupied"],
            supplierId: userInfo.supplierId,
            deviceCriteria: [
              { key: "TimestampSv", operator: "Between", valueFrom: newStartTime, valueTo: modifiedTime},
              { key: "DevidSvital", operator: "Equal", value: devDataId },
              { key: "Apneaperminute", operator: "IsSet", value: null, isDateValue: false },
              { key: "Occupied", operator: "IsSet", value: null, isDateValue: false },
            ],
            ownerFilter: {
              users: [sensor.userId],
            },
          };
          svittalDataRequest(svittalObj);
        },
        30000, selectedEndDate
      );
      setFetchGraphDataCall(false);
    }

    return () => {
      console.log("called 456");
      clearInterval(drawerRef.current);
      clearInterval(svittalDrawerRef.current);
    };
  }, [fetchGraphDataCall, realTimeType]);
  
  const intervalChange = (
    currentUserTimeZone,
    time,
    interval,
    devId,
    userId,
    userOffset,
    dlSaving
  ) => {
    devId && setDevDataId(devId);
    let m = moment.utc().tz(UTC_TIME_ZONE);
    let currentEndDate = moment().utc().format("YYYY-MM-DDTHH:mm:ss");
    if (userInfo.startTime && time) {
      const selectedTimeZone = moment(selectedStartDate).format("YYYY-MM-DDTHH:mm:ss");
      m = moment.tz(selectedTimeZone, currentUserTimeZone).utc();
    } else if (device.backDateData && time) {
      const selectedTimeZone = moment(selectedStartDate).format("YYYY-MM-DDTHH:mm:ss");
      m = moment.tz(selectedTimeZone, currentUserTimeZone).utc();
      // currentEndDate = moment.tz(endTime, UTC_TIME_ZONE).utc().format("YYYY-MM-DDTHH:mm:ss");
    }
    let rangeStartDateTime = m.format("YYYY-MM-DDTHH:mm:ss");
    let rangeEndDateTime = moment().utc().format("YYYY-MM-DDTHH:mm:ss");
    const intervalArray = interval.split(" ");
     
    if (interval === "Weekly") {
      m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      rangeStartDateTime =
        (userInfo.startTime && time) || (device.backDateData && time)
          ? m.format("YYYY-MM-DDTHH:mm:ss")
          : m.subtract(7, "day").utc().format("YYYY-MM-DDTHH:mm:ss");

      rangeEndDateTime =
        (userInfo.startTime && time) || (device.backDateData && time)
          ? m.add(7, "day").format("YYYY-MM-DDTHH:mm:ss")
          : currentEndDate;
    } else if (interval === "Monthly") {
      m.set({ date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
      rangeStartDateTime =
        (userInfo.startTime && time) || (device.backDateData && time)
          ? m.format("YYYY-MM-DDTHH:mm:ss")
          : m.subtract(30, "day").utc().format("YYYY-MM-DDTHH:mm:ss");
      rangeEndDateTime =
        (userInfo.startTime && time) || (device.backDateData && time)
          ? m.add(30, "day").format("YYYY-MM-DDTHH:mm:ss")
          : currentEndDate;
    } else if (intervalArray[1] === "Hr") {
      rangeStartDateTime =
        (userInfo.startTime && time) || (device.backDateData && time)
          ? m.format("YYYY-MM-DDTHH:mm:ss")
          : m
              .subtract(intervalArray[0], "h")
              .utc()
              .format("YYYY-MM-DDTHH:mm:ss");
      rangeEndDateTime =
        (userInfo.startTime && time) || (device.backDateData && time)
          ? m.add(intervalArray[0], "h").format("YYYY-MM-DDTHH:mm:ss")
          : currentEndDate;
    } else if (intervalArray[1] === "secs") {
      rangeStartDateTime =
        (userInfo.startTime && time) || (device.backDateData && time)
          ? moment(userInfo.intervalStartTime).format("YYYY-MM-DDTHH:mm:ss")
          : m.subtract(intervalArray[0], "seconds").utc().format("YYYY-MM-DDTHH:mm:ss");
      rangeEndDateTime =
        (userInfo.startTime && time) || (device.backDateData && time)
          ? m.add(intervalArray[0], "seconds").format("YYYY-MM-DDTHH:mm:ss")
          : currentEndDate;
    } else {
      rangeStartDateTime =
        (userInfo.startTime && time) || (device.backDateData && time)
          ? m.format("YYYY-MM-DDTHH:mm:ss")
          : m.subtract(intervalArray[0], "minutes").utc().format("YYYY-MM-DDTHH:mm:ss");
      rangeEndDateTime = (userInfo.startTime && time) || (device.backDateData && time)
          ? m.add(intervalArray[0], "minutes").format("YYYY-MM-DDTHH:mm:ss") : currentEndDate;
    }
    setStartTime(rangeStartDateTime);
    let selectedTime = moment.utc(rangeStartDateTime).tz(currentUserTimeZone).format("MM/DD/YYYY h:mm a");
    
    handleStartDateChange(selectedTime);
    setEndTime(rangeEndDateTime);

    /* setTimeout(() => {
      let stTime = moment.utc(rangeStartDateTime).tz(currentUserTimeZone).format("MM/DD/YYYY h:mm a");
      let endTime = moment.utc(rangeEndDateTime).tz(currentUserTimeZone).format("MM/DD/YYYY h:mm a");
      handleStartDateChange(stTime);
      handleEndDateChange(endTime);
    }, 500); */

    let graphRequest = GRAPH_DATA_REQUEST;
    graphRequest.deviceDataModelId = userInfo.deviceId;
    graphRequest.deviceCriteria[1].valueFrom = rangeStartDateTime;
    graphRequest.deviceCriteria[1].valueTo = rangeEndDateTime;
    graphRequest.ownerFilter.users = [userId || sensor.userId];
    graphRequest.offset = userOffset || offset;
    graphRequest.currentUserTimeZone = currentUserTimeZone;
    graphRequest.dayLightSaving = dlSaving || dayLightSaving;
    graphRequest.backDateData = device.backDateData || false;
    if (devId) {
      graphRequest.deviceCriteria[0].value = devId;
    }
    if (
      interval.split(" ")[1] === "secs" ||
      interval.split(" ")[1] === "mins"
    ) {
      graphRequest.options.groupType = "Second";
    }
    graphRequest.interval = userInfo.interval;
    let obj = {
      deviceDataModelId: userInfo.deviceId,
      // rangeStartDateTime: rangeStartDateTime,
      // rangeEndDateTime: rangeEndDateTime,
      supplierId: userInfo.supplierId,
      ownerFilter: {
        users: [userId || sensor.userId],
      },
      offset: userOffset || offset,
      abbr,
      dayLightSaving: dlSaving || dayLightSaving,
      currentUserTimeZone: currentUserTimeZone,
      backDateData: device.backDateData || false,
      devicePropertyCodes: ["Hr","Rr","Stress","Motion","DevidPvital","FriendlyNameP","LocationP","RssHrMv","Occupied"],
      deviceCriteria: graphRequest.deviceCriteria,
    };
    if (devId) {
      obj["devId"] = devId;
    }
    if (interval === "1 min" || interval === "2 mins" || interval === "5 mins" || interval === "10 mins" || interval === "30 mins") {
      fetchUserMinDeviceData(obj);
    } else {
      graphRequest.options.groupType = "Minute";
      delete obj.rangeStartDateTime;
      delete obj.rangeEndDateTime;
      fetchUserDeviceData({...graphRequest, dataPropertyCode: "Hr", deviceData: obj});
      fetchUserDeviceData({...graphRequest, dataPropertyCode: "Rr", deviceData: obj});
      fetchUserDeviceData({...graphRequest, dataPropertyCode: "Stress", deviceData: obj});
      fetchUserDeviceData({...graphRequest, dataPropertyCode: "Motion", deviceData: obj});
    }
    let svittalObj = {
      deviceDataModelId: "d36a4373-fbdc-44a3-8c96-4bb920041e40-sv",
      devicePropertyCodes: ["DevidSvital", "Apneaperminute", "Occupied"],
      supplierId: userInfo.supplierId,
      deviceCriteria: [
        {key: "TimestampSv",operator: "Between",valueFrom: rangeStartDateTime,valueTo: rangeEndDateTime},
        {key: "DevidSvital",operator: "Equal",value: devId},
        {key: "Apneaperminute",operator: "IsSet",value: null,isDateValue: false},
        {key: "Occupied",operator: "IsSet",value: null,isDateValue: false},
      ],
      ownerFilter: {users: [userId || sensor.userId]},
    };
    if (loggedUserRole === "Patient") {
      let paramObj = {
        deviceDataModelId: "d36a4373-fbdc-44a3-8c96-4bb920041e40-pa",
        devicePropertyCodes: ["DevidParams", "Csv1sec"],
        supplierId: userInfo.supplierId,
        deviceCriteria: [{
            key: "DevidParams",
            operator: "Equal",
            value: devId,
        }],
        ownerFilter: {
          users: [userId || sensor.userId],
        },
      };
      paramsDataRequest(paramObj);
    }
    svittalDataRequest(svittalObj);
    setFetchGraphDataCall(true);
    setApiRequestCount(1);
  };

  const exportPdf = (type, name) => {
    const data = document.querySelector(`#capture`);
    html2canvas(data).then((canvas) => {
      let dataURL = canvas.toDataURL("image/jpeg");
      if (type === "pdf") {
        const pdf = new jsPDF("l", "mm", "a4");
        pdf.addImage(dataURL, "JPEG", 12, 12, 200, 170);
        pdf.save(`${name}.pdf`);
        setDisablePdfBtn(false);
      } else if (type === "png") {
        var link = document.createElement("a");
        link.download = `${name}.png`;
        link.href = dataURL;
        link.click();
      }
    });
  };

  const onIntervalChange = (interval) => {
    let m = moment(selectedEndDate);
    let rangeStartDateTime = m.format("YYYY-MM-DDTHH:mm:ss");
    if (interval === "Weekly") {
      m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      rangeStartDateTime = m.subtract(7, "day").format("MM/DD/YYYY h:mm a");
    } else if (interval === "Monthly") {
      m.set({ date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
      rangeStartDateTime = m.subtract(30, "day").format("MM/DD/YYYY h:mm a");
    } else if (interval === "1 Hr") {
      rangeStartDateTime = m.subtract(1, "h").format("MM/DD/YYYY h:mm a");
    } else if (interval === "2 Hr") {
      rangeStartDateTime = m.subtract(2, "h").format("MM/DD/YYYY h:mm a");
    } else if (interval === "4 Hr") {
      rangeStartDateTime = m.subtract(4, "h").format("MM/DD/YYYY h:mm a");
    } else if (interval === "6 Hr") {
      rangeStartDateTime = m.subtract(6, "h").format("MM/DD/YYYY h:mm a");
    } else if (interval === "8 Hr") {
      rangeStartDateTime = m.subtract(8, "h").format("MM/DD/YYYY h:mm a");
    } else if (interval === "10 Hr") {
      rangeStartDateTime = m.subtract(10, "h").format("MM/DD/YYYY h:mm a");
    } else if (interval === "18 Hr") {
      rangeStartDateTime = m.subtract(18, "h").format("MM/DD/YYYY h:mm a");
    } else if (interval === "12 Hr") {
      rangeStartDateTime = m.subtract(12, "h").format("MM/DD/YYYY h:mm a");
    } else if (interval === "24 Hr") {
      rangeStartDateTime = m.subtract(24, "h").format("MM/DD/YYYY h:mm a");
    } else {
      const intervalArray = interval.split(" ");
      rangeStartDateTime = m
        .subtract(intervalArray[0], "minutes")
        .format("MM/DD/YYYY h:mm a");
    }
    
    handleStartDateChange(rangeStartDateTime);
  };

  const selectedGroupType = (interval) => {
    var currentGroupType = "Second";
    switch (interval) {
      case "1 min":
      case "2 mins":
      case "5 mins":
      case "10 mins":
      case "30 mins":
        currentGroupType = "Second";
        break;
      case "1 Hr":
      case "2 Hr":
      case "4 Hr":
      case "6 Hr":
      case "8 Hr":
      case "10 Hr":
      case "12 Hr":
      case "18 Hr":
      case "24 Hr":
        currentGroupType = "Minute";
        break;
     case "Daily":
      case "Weekly":
      case "Monthly":
        currentGroupType = "Hour";
        break;
      default:
        currentGroupType = "Second";
        break;
    }
    return currentGroupType;
  };

  const exportCSV = (data, group) => {
    var currentGroupType = group ? group : selectedGroupType(userInfo.interval);
    let obj = {
      deviceCriteriaGroup: {
        groupElements: [{
            key: "Timestamp",
            operator: "Between",
            valueFrom: startTime,
            valueTo: endTime,
          }, {
            key: "DevidPvital",
            operator: "Equal",
            value: devDataId,
        }],
      },
      deviceDataModelId: "d36a4373-fbdc-44a3-8c96-4bb920041e40",
      aggregationPropertyOptions: [
        {propertyCode: "Hr", aggregationType: "Average"},
        {propertyCode: "Rr", aggregationType: "Average"},
        {propertyCode: "Stress", aggregationType: "Average"},
        {propertyCode: "Motion", aggregationType: "Average"},
        {propertyCode: "RssRrMv", aggregationType: "Average"},
        {propertyCode: "RssHrMv", aggregationType: "Average"},
        {propertyCode: "HrTd", aggregationType: "Average"},
        {propertyCode: "HrFd", aggregationType: "Average"},
        {propertyCode: "HrSel",aggregationType: "Average"},
        {propertyCode: "HrEst",aggregationType: "Average"},
        {propertyCode: "WinFd",aggregationType: "Average"},
        {propertyCode: "WinSel",aggregationType: "Average"},
        {propertyCode: "WinEst",aggregationType: "Average"},
        {propertyCode: "WinTd",aggregationType: "Average"},
      ],
      groupingPropertyOptions: [{
        propertyCode: "Timestamp",
        groupType: currentGroupType,
      }],
      ownerFilter: {users: [sensor.userId]},
      offset: offset,
      abbr,
      DevidPvital: devDataId,
      friendlyName,
      swVersion: data.fwVersion,
      hwVersion: data.hwVersion,
      dayLightSaving: dayLightSaving,
      fullName: sensor.fullName,
      userRole: loggedUserRole,
      currentUserTimeZone: userTimeZone,
      type: "csv",
      group: group || userInfo.interval === "1 min" ? true : false,
      groupBy: `${currentGroupType}-Timestamp`,
      groupBySv: `${currentGroupType}-TimestampSv`,
      interval: userInfo.interval,
    };
    let sVittalObj = {
      deviceCriteriaGroup: {
        groupElements: [
          {
            key: "TimestampSv",
            operator: "Between",
            valueFrom: startTime,
            valueTo: endTime,
          },
          {
            key: "DevidSvital",
            operator: "Equal",
            value: devDataId,
          },
        ],
      },
      deviceDataModelId: `${userInfo.deviceId}-sv`,
      aggregationPropertyOptions: [
        {propertyCode: "Sdnn",aggregationType: "Average"},
        {propertyCode: "Rmssd",aggregationType: "Average"},
        {propertyCode: "Occupied",aggregationType: "Average"},
        {propertyCode: "Apneaperminute",aggregationType: "Average"},
      ],
      groupingPropertyOptions: [{propertyCode: "TimestampSv",groupType: "Minute"}],
      ownerFilter: {users: [sensor.userId]},
      offset: offset,
      abbr,
      DevidPvital: devDataId,
      friendlyName,
      swVersion: data.fwVersion,
      hwVersion: data.hwVersion,
      dayLightSaving: dayLightSaving,
      fullName: sensor.fullName,
      userRole: loggedUserRole,
      currentUserTimeZone: userTimeZone,
      type: "csv",
    };
    obj.sVittal = sVittalObj;
    fetchCsvData(obj);
    obj.rangeStartDateTime = startTime;
    group && fetchMinCsvData(obj);
  };

  const onDrawerClose = () => {
    setDrawer(false);
    setReadingParams(null);
    clearInterval(drawerRef.current);
    clearInterval(svittalDrawerRef.current);
    resetGraphData();
    setUserTimeZone(UTC_TIME_ZONE);
    setUserMacAddress();
    let obj = {startTime: false,};
    setUserInfo({ ...userInfo, ...obj });
    setSensor({});
  };

  /* Fetch User Profile data on User Selection change */
  const getUserProfileDataFun = () => {
    if (userInfo?.userId) {
      getUserProfileData({
        deviceDataModelId: "6df2d80f-be28-47ac-a509-8f0b6aa3bbb8",
        deviceCriteria: [{
          "key": "Userid",
          "operator": "Equal",
          "value": userInfo?.userId
        }]
      });
    }
  }

  useEffect(() => {
    getUserProfileDataFun();
    if (loggedUserRole !== "Guest") {
      getUserProfileData({deviceDataModelId: "6df2d80f-be28-47ac-a509-8f0b6aa3bbb8"});
    }
  }, [userInfo.userId]);

  useEffect(() => {
    if(device?.userSensorPosSaved) {
      getUserProfileDataFun();
      setIsSensorPositionSaved(true);
    }
  }, [device?.userSensorPosSaved]);

  /* To check wheather data is saved for current user or not */
  useEffect(() => {
    if (device?.userOrderData?.length && userInfo?.userId) {
      const matchedUserData = device.userOrderData.find(ele => ele?.data?.Userid?.value === userInfo?.userId);
      console.log(matchedUserData, 'matchedUserData', userInfo?.userId, device.userOrderData);
      if (matchedUserData && Object.keys(matchedUserData).length) {
        setUserWithOrder(matchedUserData);
        getDeviceDataById(matchedUserData?.deviceDataModelId);
        if (isSensorPositionSaved) {
          setTimeout(() => {
            changeLocalDevicePosition();
          }, 500);
          setIsSensorPositionSaved(false);
        }
      } else {
        setUserWithOrder({});
      }
    }
  }, [device.userOrderData, userInfo.userId]);

  /* Updating Device Data in the List as per Sensor Position data Saved from DB */
  useEffect(() => {
    changeLocalDevicePosition(true);
  }, [device.graphData]);
  
  /* To update the position of Sensor into DB */
  const onIndexUpdate = (val, obj, currEle) => {
    let Sensorposition = "";
    let cloneLinkedGD = JSON.parse(JSON.stringify(obj.linkedGraphData));
    /* Spare task */
    for(let i in cloneLinkedGD) {
      delete cloneLinkedGD[i].data
    }
    /* Spare task */
    
    delete cloneLinkedGD[currEle];

    let counter = 0;
    for (let i=0; i<Object.keys(cloneLinkedGD).length; i++) {
      counter = counter+1;
      if (parseInt(val) === counter) {counter = counter+1}
      
      let aa = Object.keys(cloneLinkedGD)[i] + '/'+ counter + ";";
      Sensorposition += aa;
    }
    Sensorposition+= currEle + "/" + parseInt(val);

    const objToUpdate = {
      deviceDataModelId: "6df2d80f-be28-47ac-a509-8f0b6aa3bbb8",
      data: {
          Fullname: userWithOrder?.data?.Fullname?.value || obj?.fullName,
          Userid : userWithOrder?.data?.Userid?.value || obj?.userId,
          Sensorposition: Sensorposition,
          TimestampUP: moment().utc().format("YYYY-MM-DDTHH:mm:ss")
      },
      deviceDataId : userWithOrder?.deviceDataId,
      devicePropertySetId: device?.deviceDataById?.devicePropertySetId
    }

    if (!Object.keys(userWithOrder).length) {
      delete objToUpdate["deviceDataId"];
    }
    

    if(device.userOrderData) {
      const matchedUserData = device?.userOrderData.find(ele => ele?.data?.Userid?.value === userInfo?.userId);
      if(matchedUserData?.data?.Sensorposition?.value) {
        matchedUserData.data.Sensorposition.value = Sensorposition;
        /* console.log(matchedUserData?.data?.Sensorposition?.value, '-------'); */
        setUserWithOrder(matchedUserData);
        changeLocalDevicePosition();
      }
    }

    saveUserDeviceOrder(objToUpdate);
    console.log(val, obj,currEle, 'Index Selected', objToUpdate);
  }

  /* In case Guest Update, Guest data also should update on Local */
  useEffect(() => {
    const tempGuestSensors = [];
    if (guestMapData.guestsList?.length) {
      const guestSensorsMapped = guestMapData?.guestsList[0]?.data?.Macaddreess?.value;
      if(guestSensorsMapped?.length) {
        if (guestSensorsMapped?.length > 1) {
          tempGuestSensors.push(device?.friendlyNameData[0]);
        }
        for (let j=0; j < device?.friendlyNameData?.length; j++) {
          if (guestSensorsMapped.indexOf(device?.friendlyNameData[j]?.macId) !== -1) {
            tempGuestSensors.push(device?.friendlyNameData[j]);
          }
        }
      }
      setGuestSensors(tempGuestSensors);

      if(autoFetchData && loggedUserRole === "Guest") {
        setAutoFetchData(0);
        getDeviceData();
      }
    }

    if(autoFetchData && loggedUserRole === "Patient" && device?.friendlyNameData?.length) {
      setAutoFetchData(0);
      getDeviceData();
    }
    /* console.log(guestMapData.guestsList, tempGuestSensors, 'guestSensors', device?.friendlyNameData); */
  }, [guestMapData.guestsList, device?.friendlyNameData]);

  return (
    <>
      <GridContainer>
        {loggedUserRole !== "Guest" && loggedUserRole !== "Patient" &&
          <Filter
            fetchFriendlyNameData={fetchFriendlyNameData}
            getDeviceData={getDeviceData}
            guestSensors={guestSensors}
            userList={users.userList}
            setUserInfo={setUserInfo}
            getUserList={getUserList}
            userInfo={userInfo}
            company={company}
            device={device}
          />
        }
        {showSensors && 
        <Devices
          updateSensorControlTableRequest={updateSensorControlTableRequest}
          updateSensorControlRequest={updateSensorControlRequest}
          sensorControlRequest={sensorControlRequest}
          setShowSensorControl={setShowSensorControl}
          showSensorControl={showSensorControl}
          setUserMacAddress={setUserMacAddress}
          setDayLightSaving={setDayLightSaving}
          startGraphLoader={startGraphLoader}
          setReadingParams={setReadingParams}
          setUserTimeZone={setUserTimeZone}
          setAlertMessage={setAlertMessage}
          setFriendlyName={setFriendlyName}
          setRealTimeType={setRealTimeType}
          resetGraphData={resetGraphData}
          intervalChange={intervalChange}
          userData={device.sensorControl}
          loggedUserRole={loggedUserRole}
          userMacAddress={userMacAddress}
          setToastStatus={setToastStatus}
          selectedDevice={selectedDevice}
          onIndexUpdate={onIndexUpdate}
          userWithOrder={userWithOrder}
          userTimeZone={userTimeZone}
          friendlyName={friendlyName}
          setUserInfo={setUserInfo}
          lastFilter={lastFilter}
          setSensor={setSensor}
          setOffset={setOffset}
          setDrawer={setDrawer}
          userInfo={userInfo}
          setAbbr={setAbbr}
          device={device}
          offset={offset}
          sensor={sensor}
          abbr={abbr}
        />
      }
      {!device?.graphData?.length && !device.loadingData && loggedUserRole === "Guest" &&
        <p className='noContentFound'>No Sensor Found</p>
      }
      </GridContainer>
      <GraphDetail
        successNotificationRequest={successNotificationRequest}
        selectedReadingParams={selectedReadingParams}
        handleStartDateChange={handleStartDateChange}
        apiRequestCount={device.graphReqApiCount}
        apiResponseCount={device.graphApiCount}
        setDayLightSaving={setDayLightSaving}
        selectedStartDate={selectedStartDate}
        fetchButtonTapped={fetchButtonTapped}
        setReadingParams={setReadingParams}
        svittalDrawerRef={svittalDrawerRef}
        setDisablePdfBtn={setDisablePdfBtn}
        onIntervalChange={onIntervalChange}
        setUserTimeZone={setUserTimeZone}
        setFriendlyName={setFriendlyName}
        setRealTimeType={setRealTimeType}
        loggedUserRole={loggedUserRole}
        resetGraphData={resetGraphData}
        dayLightSaving={dayLightSaving}
        disablePdfBtn={disablePdfBtn}
        userTimeZone={userTimeZone}
        friendlyName={friendlyName}
        realTimeType={realTimeType}
        setDevDataId={setDevDataId}
        setUserInfo={setUserInfo}
        exportPdf={exportPdf}
        startTime={startTime}
        exportCSV={exportCSV}
        drawerRef={drawerRef}
        setOffset={setOffset}
        setDrawer={setDrawer}
        userInfo={userInfo}
        setAbbr={setAbbr}
        endTime={endTime}
        device={device}
        drawer={drawer}
        offset={offset}
        sensor={sensor}
        abbr={abbr}
      />
      <Snackbar
        closeNotification={() => setToastStatus(false)}
        message={alertMessage}
        open={toastStatus}
        icon={AddAlert}
        color="danger"
        place="tr"
        close
      />
      <Loader
        status={device.loading || (drawer ? device.loadingGraph : false) || device.loadingCsv || device.loadingData}
        crossIcon={drawer ? true : false}
        onDrawerClose={onDrawerClose}
      />
    </>
  );
}
