import { ofType } from "redux-observable";
import { mergeMap, map, catchError } from "rxjs/operators";
import { forkJoin } from "rxjs";

import { dispatch } from "../../common/helpers";
import { companyService } from "../../services";

import {
  FETCH_COMPANY_REQUEST,
  FETCH_COMPANY_SUCCESS,
  FETCH_COMPANY_ERROR,
  COMPANY_DETAIL_REQUEST,
  COMPANY_DETAIL_SUCCESS,
  COMPANY_DETAIL_ERROR,
  CREATE_COMPANY_REQUEST,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_ERROR,
  UPDATE_COMPANY_REQUEST,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_ERROR,
  DELETE_COMPANY_REQUEST,
  DELETE_COMPANY_ERROR,
  UPDATE_DEVICE_COMPANY_REQUEST,
  UPDATE_DEVICE_COMPANY_ERROR
} from "./actions";

import { ERROR_NOTIFICATION_REQUEST } from "../notifications/actions";

export const getCompanyList = (action$) => {
  return action$.pipe(
    ofType(FETCH_COMPANY_REQUEST),
    mergeMap(({ payload }) => {
      return companyService.getCompanyList(payload).pipe(
        mergeMap((response) => {
          const companyData = JSON.parse(response);
          const customDataReq = companyData.content.reduce((acc, val) => {
            return [
              ...acc,
              companyService.getCompanyCustomDataById(val.supplierId),
            ];
          }, []);
          return forkJoin(customDataReq).pipe(
            mergeMap((customResp) => {
              companyData.content = companyData.content.map((val, index) => {
                val.customData = JSON.parse(customResp[index]);
                return val;
              })
              return [dispatch(FETCH_COMPANY_SUCCESS, companyData)];
            }),
            catchError((error) => {
              return [dispatch(FETCH_COMPANY_ERROR, error)];
            })
          )
        }),
        catchError((error) => {
          return [dispatch(FETCH_COMPANY_ERROR, error)];
        })
      );
    })
  );
};

export const getCompanyDetailsById = (action$) => {
  return action$.pipe(
    ofType(COMPANY_DETAIL_REQUEST),
    mergeMap(({ payload }) => {
      return companyService.getCompanyDetailsById(payload).pipe(
        mergeMap((response) => {
          return companyService.getCompanyCustomDataById(payload).pipe(
            mergeMap((cresponse) => {
              let company = JSON.parse(response);
              company.customData = JSON.parse(cresponse);
              return [dispatch(COMPANY_DETAIL_SUCCESS, company)];
            }),
            catchError((error) => [
              dispatch(COMPANY_DETAIL_ERROR, error),
              dispatch(ERROR_NOTIFICATION_REQUEST, {
                response: {
                  message: error.response,
                },
              }),
            ])
          );
        }),
        catchError((error) => [
          dispatch(COMPANY_DETAIL_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, {
            response: {
              message: error.response,
            },
          }),
        ])
      );
    })
  );
};

export const createCompany = (action$) => {
  return action$.pipe(
    ofType(CREATE_COMPANY_REQUEST),
    mergeMap(({ payload }) =>
      companyService.createCompany(payload.companyObject).pipe(
        map(() => dispatch(CREATE_COMPANY_SUCCESS)),
        catchError((error) => [
          dispatch(CREATE_COMPANY_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, {
            response: {
              message: error.response,
            },
          }),
        ])
      )
    )
  );
};

export const updateCompany = (action$) => {
  return action$.pipe(
    ofType(UPDATE_COMPANY_REQUEST),
    mergeMap(({ payload }) =>
      companyService.updateCompany(payload.companyObject).pipe(
        map(() => dispatch(UPDATE_COMPANY_SUCCESS)),
        catchError((error) => [
          dispatch(UPDATE_COMPANY_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, {
            response: {
              message: error.response,
            },
          }),
        ])
      )
    )
  );
};

export const deleteCompany = (action$) => {
  return action$.pipe(
    ofType(DELETE_COMPANY_REQUEST),
    mergeMap(({ payload }) =>
      companyService.deleteCompany(payload).pipe(
        map(() => {
          delete payload.supplierId;
          return dispatch(FETCH_COMPANY_REQUEST, payload);
        }),
        catchError((error) => [
          dispatch(DELETE_COMPANY_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, {
            response: {
              message: error.response,
            },
          }),
        ])
      )
    )
  );
};

export const updateDeviceCompany = (action$) => {
  return action$.pipe(
    ofType(UPDATE_DEVICE_COMPANY_REQUEST),
    mergeMap(({ payload }) =>
      companyService.updateCompany(payload.companyObject).pipe(
        mergeMap(() => {
          return [dispatch(FETCH_COMPANY_REQUEST, payload.pagination)]
        }),
        catchError((error) => [
          dispatch(UPDATE_DEVICE_COMPANY_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, {
            response: {
              message: error.response,
            },
          }),
        ])
      )
    )
  );
};
