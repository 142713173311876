import * as service from "../common/service";
import { API_BASE_URL } from "../common/constants";

const userApi = `${API_BASE_URL}/user/practice`;
const updatePractice = `${API_BASE_URL}/user/practice-custom`;

const practiceService = {
  getPracticeList: (payload) => {
    if (payload && payload.name) {
      return service.get(`${userApi}?name=${payload.name}`, {});
    }
    return service.get(`${userApi}`, {});
  },
  getPracticeDetailsById: (id) => {
    return service.get(`${userApi}/${id}`, {});
  },
  deletePractice: (payload) => {
    return service.deleteJson(`${userApi}?practiceId=${payload.practiceId}`);
  },
  updatePractice: (payload) => {
    return service.putJson(`${updatePractice}`, payload);
  },
};

export default practiceService;
