import {
  FETCH_PRACTICE_REQUEST,
  FETCH_PRACTICE_SUCCESS,
  FETCH_PRACTICE_ERROR,
  PRACTICE_DETAIL_REQUEST,
  PRACTICE_DETAIL_SUCCESS,
  PRACTICE_DETAIL_ERROR,
} from "./actions";
import { REDUCERS } from "../../common/constants";
import { createReducer } from "../../common/helpers";

const schema = {
  loading: false,
  error: false,
  totalElements: 0,
  practiceList: [],
  practiceDetail: {
    loading: false,
    error: false,
  },
};

const reducer = {
  // Program Options
  [FETCH_PRACTICE_REQUEST]: (state) => {
    return {
      ...state,
      ...REDUCERS.LOADING,
    };
  },
  [FETCH_PRACTICE_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      totalElements: payload.totalElements,
      practiceList: payload.content,
    };
  },
  [FETCH_PRACTICE_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
    totalElements: 0,
    practiceList: []
  }),
  [PRACTICE_DETAIL_REQUEST]: (state) => {
    return {
      ...state,
      practiceDetail: {
        ...state.practiceDetail,
        ...REDUCERS.LOADING,
      },
    };
  },
  [PRACTICE_DETAIL_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      practiceDetail: {
        ...state.practiceDetail,
        ...REDUCERS.SUCCESS,
        data: payload,
      },
    };
  },
  [PRACTICE_DETAIL_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
};

export default createReducer(schema, reducer);
