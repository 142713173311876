import { combineReducers } from "redux";
import users from "./users/reducer";
import practice from "./practice/reducer";
import company from "./company/reducer";
import patientDevice from "./patientDevice/reducer";
import guestMap from "./guestMap/reducer";
import auditLogs from "./auditLogs/reducer";
import dashboard from "./dashboard/reducer";
import device from "./device/reducer";
import notifications from "./notifications/reducer";
import customFields from "./customFields/reducer";
import observe from "./observe/reducer";

export default combineReducers({
  users,
  practice,
  company,
  patientDevice,
  guestMap,
  auditLogs,
  dashboard,
  device,
  notifications,
  customFields,
  observe,
});
