import { ofType } from "redux-observable";
import { mergeMap, map, catchError } from "rxjs/operators";

import { dispatch } from "../../common/helpers";
import {
  observeService,
} from "../../services";

import {
  FETCH_OBSERVE_LIST_REQUEST,
  FETCH_OBSERVE_LIST_SUCCESS,
  FETCH_OBSERVE_LIST_ERROR,
  FETCH_INVITES_REQUEST,
  FETCH_INVITES_SUCCESS,
  FETCH_INVITES_ERROR,
  ACTION_INVITES_REQUEST,
  ACTION_INVITES_SUCCESS,
  ACTION_INVITES_ERROR
} from "./actions";

export const getObserveList = (action$) => {
  return action$.pipe(
    ofType(FETCH_OBSERVE_LIST_REQUEST),
    mergeMap(({ payload }) => {
      return observeService.getObserveList(payload).pipe(
        map((response) => {
          return dispatch(FETCH_OBSERVE_LIST_SUCCESS, JSON.parse(response));
        }),
        catchError((error) => [dispatch(FETCH_OBSERVE_LIST_ERROR, error)])
      );
    })
  );
};

export const getInvitesList = (action$) => {
  return action$.pipe(
    ofType(FETCH_INVITES_REQUEST),
    mergeMap(({ payload }) => {
      return observeService.getInvitesList(payload).pipe(
        map((response) => {
          return dispatch(FETCH_INVITES_SUCCESS, JSON.parse(response));
        }),
        catchError((error) => {
          return dispatch(FETCH_INVITES_ERROR, error);
        })
      );
    })
  );
};

export const actionInvite = (action$) => {
  return action$.pipe(
    ofType(ACTION_INVITES_REQUEST),
    mergeMap(({ payload }) => {
      return observeService.actionInvite(payload).pipe(
        map((response) => {
          console.log(response, 'Accept Invites');
          return dispatch(ACTION_INVITES_SUCCESS, {});
        }),
        catchError((error) => {
          return dispatch(ACTION_INVITES_ERROR, error);
        })
      );
    })
  );
};
