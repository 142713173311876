/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import styles from "assets/jss/material-dashboard-pro-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { fluid, white, rtlActive } = props;
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white
  });
  var anchor =
    classes.a +
    cx({
      [" " + classes.whiteColor]: white
    });
  var block = cx({
    [classes.block]: true,
    [classes.whiteColor]: white
  });
  return (
    <footer className={classes.footer}>
      <div className={container}>
        <p>
          Copyright &copy; {1900 + new Date().getYear()}{" "} All rights reserved.
          <a
            href="https://cardio.io/"
            className={anchor}
            target="_blank"
          >
            {"  "}CARDI/O&reg;{"  "}
          </a>
          {"is registered trademark of Advanced TeleSensors Inc. "}
          <a
            href="https://cardio.io/pages/privacy-policy"
            className={anchor}
            target="_blank"
          >Privacy Policy</a> | {"  "}
          <a
            href="https://cardio.io/pages/terms-and-conditions-of-service"
            className={anchor}
            target="_blank"
          >Terms and Conditions of Service</a>
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool
};
