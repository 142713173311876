import { ajax } from "rxjs/ajax";
import { catchError, mergeMap } from "rxjs/operators";
import { throwError, empty, of } from "rxjs";
import { LOGIN } from "./routes";
import { TENANT_DOMAIN, APP_TYPE, APP_VERSION } from "./constants";

const userDetails = localStorage.getItem("user-details") && JSON.parse(localStorage.getItem("user-details")),
  loggedUserRole = userDetails !== null ? userDetails.roles[0].role : "";
  
const getHeaders = (appVersion, refreshToken = "") => {
  const headers = {
    "X-APP-TYPE": APP_TYPE,
    "X-TENANT-DOMAIN": TENANT_DOMAIN,
    "X-API-VERSION": appVersion || APP_VERSION,
  };

  let token = localStorage.getItem("user-details") && JSON.parse(localStorage.getItem("user-details")).token;
  if (refreshToken && loggedUserRole === "Guest") {
    token = refreshToken;
  }

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  return headers;
};

const unathorized = (err) => {
  if (err.status === 401) {
    localStorage.removeItem("user-details");
    window.location.replace(`${window.location.origin}${LOGIN}`);
    return empty();
  }
  return throwError(err);
};

export const get = (url, headers = {}) => {
  return ajax({
    url: url,
    method: "GET",
    responseType: "text",
    headers: {
      ...getHeaders(),
      ...headers,
    },
    crossDomain: true,
  }).pipe(
    mergeMap(({ response }) => {
      return of(response);
    }),
    catchError(unathorized)
  );
};

export const remove = (url, headers = {}) => {
  return ajax({
    url: url,
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      ...getHeaders(),
      ...headers,
    },
    responseType: "json",
    crossDomain: true,
  }).pipe(
    mergeMap(({ response: data }) => of(data.response)),
    catchError(unathorized)
  );
};

export const post = (url, body = {}, responseType = "text") => {
  return ajax({
    url: url,
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      ...getHeaders(),
    },
    body,
    responseType,
    crossDomain: true,
  }).pipe(catchError(unathorized));
};

export const postJson = (url, body = {}, responseType = "text") => {
  return ajax({
    url: url,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...getHeaders(),
    },
    body,
    async: true,
    responseType: responseType,
    crossDomain: true,
  }).pipe(catchError(unathorized));
};

export const postVersionJson = (url, body = {}, responseType = "text", version = 3) => {
  return ajax({
    url: url,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...getHeaders(version),
    },
    body,
    async: true,
    responseType: responseType,
    crossDomain: true,
  }).pipe(catchError(unathorized));
};

export const putJson = (url, body = {}, responseType = "text") => {
  const  updatedHeaders = body?.refreshToken ? getHeaders(APP_VERSION, body?.refreshToken) : getHeaders();
  return ajax({
    url: url,
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...updatedHeaders,
    },
    body,
    responseType: responseType,
    crossDomain: true,
  }).pipe(catchError(unathorized));
};

export const deleteJson = (url, body = {}, responseType = "text") => {
  return ajax({
    url: url,
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      ...getHeaders(),
    },
    body,
    responseType: responseType,
    crossDomain: true,
  }).pipe(catchError(unathorized));
};

export const postMultipart = (url, body = {}, responseType = "text") => {
  return ajax({
    url: url,
    method: "POST",
    headers: {
      // "Content-Type": "multipart/form-data",
      // "type": "formData",
      ...getHeaders(),
    },
    body,
    responseType: responseType,
    crossDomain: true,
  }).pipe(catchError(unathorized));
};

export const get3Version = (url, headers = {}) => {
  return ajax({
    url: url,
    method: "GET",
    responseType: "text",
    headers: {
      ...getHeaders(3),
      ...headers,
    },
    crossDomain: true,
  }).pipe(
    mergeMap(({ response }) => {
      return of(response);
    }),
    catchError(unathorized)
  );
};

export const downloadFirmware = (url, headers = {}) => {
  return ajax({
    url: url,
    method: "GET",
    responseType: "arraybuffer",
    headers: {
      ...getHeaders(3),
      ...headers,
    },
    crossDomain: true,
  }).pipe(catchError(unathorized));
};

export const postRegisterForm = (url, body = {}, responseType = "text") => {
  return ajax({
    url: url,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-APP-TYPE": "DEVICE",
      "X-TENANT-DOMAIN": TENANT_DOMAIN,
      "X-API-VERSION": 3,
    },
    body,
    async: true,
    responseType: responseType,
    crossDomain: true,
  }).pipe(catchError(unathorized));
};

export const refreshToken = (url) => {
  return ajax({
    url: url,
    method: "POST",
    headers: {
      "Content-Type": "text/plain",
      "X-APP-TYPE": "EUWP",
      "X-TENANT-DOMAIN": TENANT_DOMAIN,
      "X-API-VERSION": 3,
    },
    body: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJlbWFpbEFkZHJlc3MiOiJhZTZkMDFjNC1kOGY5LTRhYzctYTc3Mi1mNmRkYTIyNmU0ZTZAZ2FsZW5jbG91ZC5jb20iLCJyb2xlIjoiVGVuYW50VXNlciIsImFwcFR5cGUiOiJERVZJQ0UiLCJpc3MiOiJHYWxlbkNsb3VkIiwidGVuYW50SWQiOiJBVFMtREVWIiwicHJpbmNpcGFsSWQiOiJhZTZkMDFjNC1kOGY5LTRhYzctYTc3Mi1mNmRkYTIyNmU0ZTYiLCJ0b2tlblR5cGUiOiJNYXN0ZXJUb2tlbiIsImV4cCI6MTg2MTg5NzUzMH0.kMGiKSzkiw76ajEbUvszcNcU4omVc68ywy9QsX6b7AGmoNk9sz7EFpODDXrK_2M0e3Exu0C4dbJ-8Nko_a_Kaw",
    async: true,
    responseType: "text",
    crossDomain: true,
  }).pipe(catchError(unathorized));
};