import {
  FETCH_COMPANY_REQUEST,
  FETCH_COMPANY_SUCCESS,
  FETCH_COMPANY_ERROR,
  COMPANY_DETAIL_REQUEST,
  COMPANY_DETAIL_SUCCESS,
  COMPANY_DETAIL_ERROR,
  CREATE_COMPANY_REQUEST,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_ERROR,
  UPDATE_COMPANY_REQUEST,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_ERROR,
  DELETE_COMPANY_REQUEST,
  DELETE_COMPANY_ERROR,
  UPDATE_DEVICE_COMPANY_REQUEST,
  UPDATE_DEVICE_COMPANY_ERROR,
} from "./actions";
import { REDUCERS } from "../../common/constants";
import { createReducer } from "../../common/helpers";

const schema = {
  loading: false,
  error: false,
  success: false,
  totalElements: 0,
  companyList: [],
  companyDetail: {
    loading: false,
    error: false,
    data: {},
  },
};

const reducer = {
  [FETCH_COMPANY_REQUEST]: (state) => {
    return {
      ...state,
      ...REDUCERS.LOADING,
      success: false,
      companyDetail: {
        loading: false,
        error: false,
        data: {},
      },
    };
  },
  [FETCH_COMPANY_SUCCESS]: (state, { payload }) => {
    const userDetails = JSON.parse(localStorage.getItem("user-details"));
    const notTenent = userDetails.roles.find(
      (val) =>
        val.role === "SupplierAdmin" ||
        val.role === "SupplierUser" ||
        val.role === "PracticeUser"
    );
    let count = payload.totalElements;
    let content = payload.content;
    if (notTenent) {
      count = 1;
      content = payload.content.filter(
        (val) => val.supplierId === userDetails.roles[0].supplier.supplierId
      );
    }
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      success: false,
      totalElements: count,
      companyList: content.sort((a, b) => (a.name > b.name ? 1 : -1)),
    };
  },
  [FETCH_COMPANY_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [COMPANY_DETAIL_REQUEST]: (state) => {
    return {
      ...state,
      success: false,
      companyDetail: {
        ...state.companyDetail,
        ...REDUCERS.LOADING,
      },
    };
  },
  [COMPANY_DETAIL_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      companyDetail: {
        ...state.companyDetail,
        ...REDUCERS.SUCCESS,
        data: { ...payload, ...payload.contactInfo },
      },
    };
  },
  [COMPANY_DETAIL_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
    companyDetail: {
      ...state.companyDetail,
      ...REDUCERS.ERROR,
    },
  }),
  [CREATE_COMPANY_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
    success: false,
  }),
  [CREATE_COMPANY_SUCCESS]: (state) => {
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      success: true,
    };
  },
  [CREATE_COMPANY_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
    success: false,
  }),
  [UPDATE_COMPANY_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
    success: false,
  }),
  [UPDATE_COMPANY_SUCCESS]: (state) => {
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      success: true,
    };
  },
  [UPDATE_COMPANY_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
    success: false,
  }),
  [DELETE_COMPANY_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [DELETE_COMPANY_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [UPDATE_DEVICE_COMPANY_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [UPDATE_DEVICE_COMPANY_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
};

export default createReducer(schema, reducer);
