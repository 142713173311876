// Options Actions
export const FETCH_LOGIN_REQUEST = "FETCH_LOGIN_REQUEST";
export const FETCH_LOGIN_SUCCESS = "FETCH_LOGIN_SUCCESS";
export const FETCH_LOGIN_ERROR = "FETCH_LOGIN_ERROR";

export const FETCH_USER_LIST_REQUEST = "FETCH_USER_LIST_REQUEST";
export const FETCH_USER_LIST_SUCCESS = "FETCH_USER_LIST_SUCCESS";
export const FETCH_USER_LIST_ERROR = "FETCH_USER_LIST_ERROR";

export const USER_DETAIL_REQUEST = "USER_DETAIL_REQUEST";
export const USER_DETAIL_SUCCESS = "USER_DETAIL_SUCCESS";
export const USER_DETAIL_ERROR = "USER_DETAIL_ERROR";

export const CREATE_PRACTICE_USER_REQUEST = "CREATE_PRACTICE_USER_REQUEST";
export const CREATE_PRACTICE_USER_SUCCESS = "CREATE_PRACTICE_USER_SUCCESS";
export const CREATE_PRACTICE_USER_ERROR = "CREATE_PRACTICE_USER_ERROR";

export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_ERROR = "CREATE_USER_ERROR";

export const UPDATING_PRACTICE_DETAILS_SUCCESS =
  "UPDATING_PRACTICE_DETAILS_SUCCESS";

export const START_PRACTICE_REQUEST = "START_PRACTICE_REQUEST";
export const START_PRACTICE_SUCCESS = "START_PRACTICE_SUCCESS";
export const START_PRACTICE_ERROR = "START_PRACTICE_ERROR";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";

export const DELETE_USER_PRACTICE_REQUEST = "DELETE_USER_PRACTICE_REQUEST";
export const DELETE_USER_PRACTICE_SUCCESS = "DELETE_USER_PRACTICE_SUCCESS";
export const DELETE_USER_PRACTICE_ERROR = "DELETE_USER_PRACTICE_ERROR";

export const CREATE_DEVICE_USER_REQUEST = "CREATE_DEVICE_USER_REQUEST";
export const CREATE_DEVICE_USER_SUCCESS = "CREATE_DEVICE_USER_SUCCESS";
export const CREATE_DEVICE_USER_ERROR = "CREATE_DEVICE_USER_ERROR";

export const PATIENT_DETAIL_REQUEST = "PATIENT_DETAIL_REQUEST";
export const PATIENT_DETAIL_SUCCESS = "PATIENT_DETAIL_SUCCESS";
export const PATIENT_DETAIL_ERROR = "PATIENT_DETAIL_ERROR";

export const DELETE_USER_DEVICE_REQUEST = "DELETE_USER_DEVICE_REQUEST";
export const DELETE_USER_DEVICE_SUCCESS = "DELETE_USER_DEVICE_SUCCESS";
export const DELETE_USER_DEVICE_ERROR = "DELETE_USER_DEVICE_ERROR";

export const FETCH_CAREGIVER_LIST_REQUEST = "FETCH_CAREGIVER_LIST_REQUEST";
export const FETCH_CAREGIVER_LIST_SUCCESS = "FETCH_CAREGIVER_LIST_SUCCESS";
export const FETCH_CAREGIVER_LIST_ERROR = "FETCH_CAREGIVER_LIST_ERROR";

export const FETCH_GUEST_LIST_REQUEST = "FETCH_GUEST_LIST_REQUEST";
export const FETCH_GUEST_LIST_SUCCESS = "FETCH_GUEST_LIST_SUCCESS";
export const FETCH_GUEST_LIST_ERROR = "FETCH_GUEST_LIST_ERROR";

export const UPDATE_ROLE_REQUEST = "UPDATE_ROLE_REQUEST";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_ERROR = "UPDATE_ROLE_ERROR";

export const UPDATE_GUEST_REQUEST = "UPDATE_GUEST_REQUEST";
export const UPDATE_GUEST_SUCCESS = "UPDATE_GUEST_SUCCESS";
export const UPDATE_GUEST_ERROR = "UPDATE_GUEST_ERROR";

export const UPDATE_DEVICE_USER_REQUEST = "UPDATE_DEVICE_USER_REQUEST";
export const UPDATE_DEVICE_USER_SUCCESS = "UPDATE_DEVICE_USER_SUCCESS";
export const UPDATE_DEVICE_USER_ERROR = "UPDATE_DEVICE_USER_ERROR";

export const UPDATE_PRACTICE_USER_REQUEST = "UPDATE_PRACTICE_USER_REQUEST";
export const UPDATE_PRACTICE_USER_SUCCESS = "UPDATE_PRACTICE_USER_SUCCESS";
export const UPDATE_PRACTICE_USER_ERROR = "UPDATE_PRACTICE_USER_ERROR";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

export const UPDATE_TERM_AND_CONDITION_REQUEST =
  "UPDATE_TERM_AND_CONDITION_REQUEST";
export const UPDATE_TERM_AND_CONDITION_SUCCESS =
  "UPDATE_TERM_AND_CONDITION_SUCCESS";
export const UPDATE_TERM_AND_CONDITION_ERROR =
  "UPDATE_TERM_AND_CONDITION_ERROR";

export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR";

export const FETCH_USER_CUSTOM_REQUEST = "FETCH_USER_CUSTOM_REQUEST";
export const FETCH_USER_CUSTOM_SUCCESS = "FETCH_USER_CUSTOM_SUCCESS";
export const FETCH_USER_CUSTOM_ERROR = "FETCH_USER_CUSTOM_ERROR";

export const RESET_PASSWORD_UPDATE_REQUEST = "RESET_PASSWORD_UPDATE_REQUEST";
export const RESET_PASSWORD_UPDATE_SUCCESS = "RESET_PASSWORD_UPDATE_SUCCESS";
export const RESET_PASSWORD_UPDATE_ERROR = "RESET_PASSWORD_UPDATE_ERROR";

export const BACK_TO_OTP_REQUEST = "BACK_TO_OTP_REQUEST";
export const BACK_TO_OTP_SUCCESS = "BACK_TO_OTP_SUCCESS";

export const UPDATE_RESIDENT_USER_REQUEST = 'UPDATE_RESIDENT_USER_REQUEST';
export const UPDATE_RESIDENT_USER_SUCCESS = 'UPDATE_RESIDENT_USER_SUCCESS';
export const UPDATE_RESIDENT_USER_ERROR = 'UPDATE_RESIDENT_USER_ERROR';

export const DELETE_RESIDENT_DEVICE_REQUEST = 'DELETE_RESIDENT_DEVICE_REQUEST';
export const DELETE_RESIDENT_DEVICE_SUCCESS = 'DELETE_RESIDENT_DEVICE_SUCCESS';
export const DELETE_RESIDENT_DEVICE_ERROR = 'DELETE_RESIDENT_DEVICE_ERROR';

export const DELETE_RESIDENT_DEVICE_ONLY_REQUEST = 'DELETE_RESIDENT_DEVICE_ONLY_REQUEST';
export const DELETE_RESIDENT_DEVICE_ONLY_SUCCESS = 'DELETE_RESIDENT_DEVICE_ONLY_SUCCESS';
export const DELETE_RESIDENT_DEVICE_ONLY_ERROR = 'DELETE_RESIDENT_DEVICE_ONLY_ERROR';

export const GET_INVITE_EMAIL_REQUEST = 'GET_INVITE_EMAIL_REQUEST';
export const GET_INVITE_EMAIL_SUCCESS = 'GET_INVITE_EMAIL_SUCCESS';
export const GET_INVITE_EMAIL_ERROR = 'GET_INVITE_EMAIL_ERROR';

export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';

export const ACTIVATE_USER_REQUEST = 'ACTIVATE_USER_REQUEST';
export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS';
export const ACTIVATE_USER_ERROR = 'ACTIVATE_USER_ERROR';

export const RESEND_OTP_REQUEST = 'RESEND_OTP_REQUEST';
export const RESEND_OTP_SUCCESS = 'RESEND_OTP_SUCCESS';
export const RESEND_OTP_ERROR = 'RESEND_OTP_ERROR';

export const ACCEPT_TERMS_REQUEST = 'ACCEPT_TERMS_REQUEST';
export const ACCEPT_TERMS_SUCCESS = 'ACCEPT_TERMS_SUCCESS';
export const ACCEPT_TERMS_ERROR = 'ACCEPT_TERMS_ERROR';

export const ACCEPT_TERMS_USER_REQUEST = 'ACCEPT_TERMS_USER_REQUEST';
export const ACCEPT_TERMS_USER_SUCCESS = 'ACCEPT_TERMS_USER_SUCCESS';
export const ACCEPT_TERMS_USER_ERROR = 'ACCEPT_TERMS_USER_ERROR';

export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_ERROR = 'REFRESH_TOKEN_ERROR';