import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
// @material-ui/core components
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import MenuItem from "@material-ui/core/MenuItem";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { PAGINATION_DETAILS, PAGE_SIZES } from "../../common/constants";
import { FETCH_AUDIT_LOGS_REQUEST } from "../../redux/auditLogs/actions";
import { FILTER_USER_ROLES } from "../../common/constants";
import { useComposeDispatcher } from "../../common/hooks";
import styles from "assets/jss/material-dashboard-pro-react/views/userStyle.js";

const useStyles = makeStyles(styles);

const filterstyles = () => ({
  labelFocused: {
    color: "#E73F3B !important",
  },
  inputFocused: {
    "&:before": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "&:after": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "& input": {
      borderBottom: "#E73F3B !important",
    },
  },
  inputRoot: {
    color: "red",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
});

const columns = [
  {
    id: "auditTime",
    label: "Audit Time",
    minWidth: 130,
    type: "date",
    sort: true,
  },
  { id: "objectType", label: "Resource", minWidth: 100, sort: true },
  { id: "auditType", label: "Operation", minWidth: 100, sort: true },
  { id: "auditResult", label: "Result", minWidth: 100, sort: true },
  { id: "userEmailAddress", label: "User Email", minWidth: 130, sort: true },
  {
    id: "auditData",
    label: "Audit Data",
    minWidth: 130,
    sort: false,
  },
];

const SelectBox = withStyles(filterstyles)((props) => {
  const { labelText, classes, value, onChange, options, required } = props;
  return (
    <TextField
      select
      fullWidth
      label={labelText}
      value={value}
      required={required ? required : false}
      margin="normal"
      color="primary"
      onChange={onChange}
      InputLabelProps={{
        shrink: true,
        classes: {
          focused: classes.labelFocused,
        },
      }}
      InputProps={{
        classes: {
          root: classes.root,
          focused: classes.inputFocused,
        },
      }}
    >
      {options}
    </TextField>
  );
});

export default function AuditLogs() {
  const { auditLogs } = useSelector((state) => ({
    auditLogs: state.auditLogs,
  }));
  const [alert, setAlert] = React.useState(null);
  const [order, setOrder] = React.useState("auditTime");
  const [orderBy, setOrderBy] = React.useState("DESC");
  const [filter, setFilter] = React.useState({
    fromDateTime: null,
    toDateTime: null,
    userRole: FILTER_USER_ROLES[0].key,
  });

  const [getAuditLogsList] = useComposeDispatcher(FETCH_AUDIT_LOGS_REQUEST);

  const classes = useStyles();

  const [page, setPage] = React.useState(PAGINATION_DETAILS.pageNumber);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    PAGINATION_DETAILS.pageSize
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAuditLogsList({
      pageNumber: newPage,
      pageSize: rowsPerPage,
      sortBy: order,
      sortOrder: orderBy,
      filter,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("user-details"));
    const userRole = userDetails !== null ? userDetails.roles[0].role : "";
    let dropdownRoles = [
      { key: "SupplierAdmin", value: "Admin" },
      { key: "SupplierUser", value: "Manager" },
      { key: "PracticeUser", value: "Caregiver & Guest" },
    ];

    if (userRole === "Patient") {
      dropdownRoles = [{ key: "Patient", value: "Patient" }];
    }
    setFilter({ ...filter, userRole: dropdownRoles[0].key });
    getAuditLogsList({
      pageNumber: page,
      pageSize: rowsPerPage,
      sortBy: order,
      sortOrder: orderBy,
      filter: {
        ...filter,
        userRole: dropdownRoles[0].key,
      },
    });
  }, []);

  const showLogsDetail = (data) => {
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px", width: "400px" }}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        cancelBtnStyle={{
          display: "none",
        }}
        confirmBtnCssClass={classes.button + " " + classes.success}
        confirmBtnText="Close"
        showCancel
      >
        {data}
      </SweetAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const createSortHandler = (property) => () => {
    const isAsc = orderBy === property && order === "ASC";
    setOrder(isAsc ? "DESC" : "ASC");
    setOrderBy(property);
    getAuditLogsList({
      pageNumber: 0,
      pageSize: rowsPerPage,
      sortBy: property,
      sortOrder: isAsc ? "DESC" : "ASC",
      filter,
    });
    setPage(0);
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box
          bgcolor="#ffffff"
          display="flex"
          padding={1}
          borderRadius="5px"
          marginBottom={2}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <b>Filter By</b>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <SelectBox
                id="role"
                labelText="Select Role"
                required={true}
                value={filter.userRole}
                onChange={(val) => {
                  setFilter({
                    ...filter,
                    userRole: val.target.value,
                  });
                  getAuditLogsList({
                    pageNumber: 0,
                    pageSize: rowsPerPage,
                    sortBy: order,
                    sortOrder: orderBy,
                    filter: {
                      ...filter,
                      userRole: val.target.value,
                    },
                  });
                }}
                options={FILTER_USER_ROLES.map((val) => (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                    key={val.key}
                    value={val.key}
                  >
                    {val.value}
                  </MenuItem>
                ))}
              />
            </GridItem>
          </GridContainer>
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="danger" icon>
            <CardIcon color="danger">
              <Person />
            </CardIcon>
          </CardHeader>
          <CardBody>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          maxWidth: column.minWidth,
                          minWidth: column.minWidth,
                        }}
                      >
                        {column.sort ? (
                          <TableSortLabel
                            style={{
                              maxWidth: column.minWidth,
                              minWidth: column.minWidth,
                            }}
                            active={orderBy === column.id}
                            direction={
                              orderBy === column.id
                                ? order.toLowerCase()
                                : "asc"
                            }
                            onClick={createSortHandler(column.id)}
                          >
                            <Typography>{column.label}</Typography>
                          </TableSortLabel>
                        ) : (
                          <Typography>{column.label}</Typography>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {auditLogs.totalElements === 0 && (
                    <TableRow>
                      <TableCell align="center" colSpan="7">
                        No records found
                      </TableCell>
                    </TableRow>
                  )}
                  {auditLogs.logList.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index + "" + row.userId}
                      >
                        {columns.map((column) => {
                          let value = row[column.id];
                          if (column.type === "date") {
                            value = moment(row[column.id]).format(
                              "DD MMM, YYYY hh:mm"
                            );
                          }
                          if (column.id === "auditData") {
                            value = row[column.id] ? (
                              <Button
                                color="success"
                                simple
                                onClick={() => {
                                  showLogsDetail(row[column.id]);
                                }}
                                className={classes.actionButton}
                              >
                                View Data
                              </Button>
                            ) : (
                              ""
                            );
                          }
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.id !== "action" &&
                              column.id !== "role" &&
                              column.format &&
                              typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={PAGE_SIZES}
              component="div"
              count={auditLogs.totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            {alert}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
