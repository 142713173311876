import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import React, { useEffect } from "react";
import moment from "moment";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Loader from "components/Loader/Loader.js";
import Snackbar from "components/Snackbar/Snackbar.js";

import {
  FETCH_DEVICE_REQUEST,
  DELETE_DEVICE_REQUEST,
  DUPLICATE_DEVICE_REQUEST,
} from "../../redux/device/actions";

import { useComposeDispatcher } from "../../common/hooks";
import { PAGINATION_DETAILS, PAGE_SIZES } from "../../common/constants";
import styles from "assets/jss/material-dashboard-pro-react/views/userStyle.js";

const useStyles = makeStyles(styles);

const columns = [
  { id: "name", label: "Name", minWidth: 150, sort: false },
  { id: "description", label: "Description", minWidth: 250, sort: false },
  { id: "status", label: "Status", minWidth: 170, sort: false },
  {
    id: "createdOn",
    label: "Date Created",
    minWidth: 130,
    sort: false,
    type: "date",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 170,
    align: "center",
    sort: false,
    format: (value) => value.toFixed(2),
  },
];

const inputStyles = () => ({
  labelFocused: {
    color: "#E73F3B !important",
  },
  inputFocused: {
    "&:before": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "&:after": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "& input": {
      borderBottom: "#E73F3B !important",
    },
  },
  inputRoot: {
    color: "red",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
});

const InputBox = withStyles(inputStyles)((props) => {
  const {
    labelText,
    helperText,
    classes,
    value,
    id,
    info,
    updateInfo,
    type,
    required,
  } = props;
  return (
    <TextField
      label={labelText}
      helperText={helperText}
      fullWidth
      required={required ? required : false}
      type={type ? type : "text"}
      margin="normal"
      color="primary"
      value={value}
      onChange={(e) => {
        let obj = {};
        obj[id] = e.target.value;
        updateInfo({ ...info, ...obj });
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          focused: classes.labelFocused,
        },
      }}
      InputProps={{
        classes: {
          root: classes.root,
          focused: classes.inputFocused,
        },
      }}
    />
  );
});

export default function Device() {
  const history = useHistory();
  const { device } = useSelector((state) => ({
    device: state.device,
  }));
  const [alert, setAlert] = React.useState(null);
  const [order, setOrder] = React.useState("DESC");
  const [orderBy, setOrderBy] = React.useState("createdOn");
  const userDetails = JSON.parse(localStorage.getItem("user-details"));
  const userRole = userDetails !== null ? userDetails.roles[0].role : "";
  const [duplicateBox, setDuplicateBox] = React.useState(false);
  const [duplicateBoxId, setDuplicateBoxId] = React.useState({});
  const [userInfo, setUserInfo] = React.useState({});
  const [alertMessage, setAlertMessage] = React.useState("");
  const [toastStatus, setToastStatus] = React.useState(false);

  const [getDeviceList] = useComposeDispatcher(FETCH_DEVICE_REQUEST);

  const [deleteDevice] = useComposeDispatcher(DELETE_DEVICE_REQUEST);

  const [duplicateDevice] = useComposeDispatcher(DUPLICATE_DEVICE_REQUEST);

  const classes = useStyles();
  const [page, setPage] = React.useState(PAGINATION_DETAILS.pageNumber);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    PAGINATION_DETAILS.pageSize
  );

  useEffect(() => {
    getDeviceList({
      pageNumber: page,
      pageSize: rowsPerPage,
      sortBy: orderBy,
      sortOrder: order,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const warningWithConfirmMessage = (user) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => {
          deleteDevice({
            deviceId: user.deviceId,
            pageNumber: page,
            pageSize: rowsPerPage,
            sortBy: orderBy,
            sortOrder: order,
          });
          hideAlert();
        }}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        You will not be able to recover <b>{user.name}</b> device data!
      </SweetAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const onEditButtonClick = ({ deviceId }) => {
    history.push(`/admin/edit-device/${deviceId}`);
  };

  const onFirmwareButtonClick = ({ deviceId, name }) => {
    history.push(`/admin/device-firmware/${deviceId}?name=${name}`);
  };

  const createSortHandler = (property) => () => {
    const isAsc = orderBy === property && order === "ASC";
    setOrder(isAsc ? "DESC" : "ASC");
    setOrderBy(property);
    getDeviceList({
      role: "Patient",
      pageNumber: 0,
      pageSize: rowsPerPage,
      sortBy: property,
      sortOrder: isAsc ? "DESC" : "ASC",
    });
    setPage(0);
  };

  if (device.success && duplicateBox) {
    setDuplicateBox(false);
    setDuplicateBoxId({});
    setUserInfo({});
  }
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="danger" icon>
            <CardIcon color="danger">
              <Person />
            </CardIcon>
            <Box className={classes.cardIconTitle} style={{ float: "right" }}>
              <Button
                color="primary"
                onClick={() => {
                  history.push(`/admin/add-device`);
                }}
              >
                Create Device
              </Button>
            </Box>
          </CardHeader>
          <CardBody>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => {
                      if (
                        userRole === "PracticeUser" &&
                        column.id === "action"
                      ) {
                        return false;
                      }
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ maxWidth: column.minWidth }}
                        >
                          {column.sort ? (
                            <TableSortLabel
                              style={{
                                maxWidth: column.minWidth,
                                minWidth: column.minWidth,
                              }}
                              active={orderBy === column.id}
                              direction={
                                orderBy === column.id
                                  ? order.toLowerCase()
                                  : "asc"
                              }
                              onClick={createSortHandler(column.id)}
                            >
                              <Typography>{column.label}</Typography>
                            </TableSortLabel>
                          ) : (
                              <Typography>{column.label}</Typography>
                            )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {device.deviceList.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.userId}
                      >
                        {columns.map((column) => {
                          let value = row[column.id];
                          if (column.type === "date") {
                            value = moment(row[column.id]).format(
                              "DD MMM, YYYY hh:mm"
                            );
                          }
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.id === "role" && row?.currentRole.role}
                              {column.id !== "action" &&
                                column.id !== "role" &&
                                column.format &&
                                typeof value === "number"
                                ? column.format(value)
                                : value}
                              {column.id === "action" &&
                                userRole !== "PracticeUser" && (
                                  <>
                                    <Button
                                      color="success"
                                      simple
                                      onClick={() => {
                                        onEditButtonClick(row);
                                      }}
                                      className={classes.actionButton}
                                    >
                                      Edit
                                    </Button>{" "}
                                    |
                                    <Button
                                      color="danger"
                                      simple
                                      onClick={() => {
                                        warningWithConfirmMessage(row);
                                      }}
                                      className={classes.actionButton}
                                    >
                                      Delete
                                    </Button>{" "}
                                    |
                                    <Button
                                      color="info"
                                      simple
                                      onClick={() => {
                                        onFirmwareButtonClick(row);
                                      }}
                                      className={classes.actionButton}
                                    >
                                      Firmware
                                    </Button>{" "}
                                    |
                                    <Button
                                      color="success"
                                      simple
                                      onClick={() => {
                                        setDuplicateBox(true);
                                        setDuplicateBoxId(row);
                                      }}
                                      className={classes.actionButton}
                                    >
                                      Duplicate
                                    </Button>
                                  </>
                                )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={PAGE_SIZES}
              component="div"
              count={device.totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={(e, page) => {
                setPage(page);
                getDeviceList({
      pageNumber: page,
      pageSize: rowsPerPage,
      sortBy: orderBy,
      sortOrder: order,
    });

                      }}
                      onChangeRowsPerPage={(e) => {
                       
                        setRowsPerPage(+e.target.value);
                        setPage(0);

                        getDeviceList({
      pageNumber: page,
      pageSize: e.target.value,
      sortBy: orderBy,
      sortOrder: order,
    });
                      }}
            />
            {alert}
            <Loader status={device.loading} />
            <Dialog
              open={duplicateBox}
              onClose={() => {
                setDuplicateBox(false);
                setDuplicateBoxId({});
                setUserInfo({});
              }}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Duplicate Device</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Click CONFIRM, if you are sure you want to duplicate{" "}
                  <b> {duplicateBoxId.name}</b> device
                </DialogContentText>
                <InputBox
                  labelText="New Device Name"
                  id="newDeviceName"
                  value={userInfo?.newDeviceName}
                  required={true}
                  info={userInfo}
                  updateInfo={setUserInfo}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setDuplicateBox(false);
                    setDuplicateBoxId({});
                    setUserInfo({});
                  }}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    if (!userInfo?.newDeviceName) {
                      setAlertMessage("Please enter new device name");
                      setToastStatus(true);
                    } else {
                      duplicateDevice({
                        deviceId: duplicateBoxId.deviceId,
                        newDeviceName: userInfo.newDeviceName,
                        currentPage: {
                          pageNumber: page,
                          pageSize: rowsPerPage,
                          sortBy: orderBy,
                          sortOrder: order,
                        },
                      });
                    }
                    // setDuplicateBox(false);
                    // setDuplicateBoxId({});
                  }}
                  disabled={device.loading}
                  color="primary"
                >
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
            <Snackbar
              place="tr"
              color="danger"
              icon={AddAlert}
              message={alertMessage}
              open={toastStatus}
              closeNotification={() => setToastStatus(false)}
              close
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
