export const DEMO_BASE_URL = "https://demo.galencloud.com";
export const API_BASE_URL = "https://api.galencloud.com";
export const TENANT_DOMAIN = "ats.galencloud.com";
export const APP_TYPE = "DEVICE";
export const APP_VERSION = "3";

export const REDUCERS = {
  LOADING: { loading: true, error: false },
  SUCCESS: { loading: false, error: false },
  ERROR: { loading: false, error: true },
};

export const MESSAGES = {
  FORM_SUBMITTED_SUCCESSFULLY: "Form submitted successfully.",
  FORM_UPDATED_SUCCESSFULLY: "Form updated successfully.",
  DATA_DELETED_SUCCESSFULLY: "Data deleted successfully.",
  SOMETHING_WENT_WRONG: "Something went wrong.",
  FILE_UPLOADED_SUCCESSFULLY: "File uploaded successfully.",
  LOGIN_SUCCESSFUL: "Login successfully",
  RESET_MAIL_SUCCESSFUL: "We have sent OTP code to your email address.",
  INCORRECT_PASSWORD: "Invalid login credentials.",
  PASSWORD_UPDATED_SUCCESSFUL: "Password updated successfully."
};

export const USER_ROLES = [
  { key: "SupplierAdmin", value: "Admin" },
  { key: "PracticeUser", value: "Caregiver" },
  { key: "Guest", value: "Guest" },
  { key: "SupplierUser", value: "Manager" },
];

export const FILTER_USER_ROLES = [
  { key: "all", value: "All" },
  { key: "SupplierAdmin", value: "Admin" },
  { key: "PracticeUser", value: "Caregiver & Guest" },
  { key: "SupplierUser", value: "Manager" },
  { key: "Patient", value: "Patient" }
];

export const PATIENT_USER_ROLE = [{ key: "Patient", value: "Patient" }];

export const PRACTICE_USER_ROLES = ["PracticeAdmin", "PracticeUser", "Patient"];

export const GENDER = ["male", "female"];

export const PAGINATION_DETAILS = {
  pageNumber: 0,
  pageSize: 5,
};

export const PAGE_SIZES = [5, 10, 25, 100];

export const PATIENT_PASSWORD = "Md@995353";

export const CUSTOM_FIELD_DEFAULT_VALUE = [
  { key: "T&C accepted", value: "No" },
  { key: "Privacy Policy Accepted", value: "Yes" },
  {
    key: "Enable Alerts",
    value: ["None"],
  },
  { key: "APP_view", value: "GW" },
  { key: "Enable Reports", value: ["None"] },
  { key: "Guest Account", value: "No" },
  { key: "Change Password", value: "Yes" },
  { key: "Location", value: "Location" },
  { key: "Alexa_name", value: "Alexa name" },
  { key: "DayLight_Savings", value: "Yes" },
  { key: "TimeZone", value: "Central Standard Time" },
  { key: "Plot_range_default", value: "1 Hr" },
];

export const DEFAULT_TIMEZONE = 'CDT';
export const DEFAULT_TIMEOFFSET = '-5';
export const DEFAULT_TIME = 'Central Standard Time';
export const DEFAULT_UTC_TIME = 'America/Chicago';
export const UTC_TIME_ZONE = 'America/Scoresbysund';

export const THRESHOLD_DEFAULT_VALUES = {
  AlertMinhr: 45,
  AlertMaxhr: 100,
  AlertMinrr: 9,
  AlertMaxrr: 30,
  // AlertMinst: 0,
  AlertMaxst: 5,
  // AlertMinmt: ,
  AlertMaxmt: 5,
}

export const PARAMS_DEFAULT_VALUES = {
  BpfSelect: 2,
  GainSelect: "M",
  // HrSqlSigC: 9,
  // RrSqlSigC: 30,
  // AlertMinst: 0,
  // HrGainC: 5,
  // RrGainC: ,
  // ParamsLastupdate: 5,
}

export const GRAPH_DATA_REQUEST = {
  "deviceDataModelId": "",
  "dataPropertyCode": "",
  "rangeStartDateTime": null,
  "rangeEndDateTime": null,
  "buckets": null,
  "options": {
    "aggregationType": null,
    "groupingPropertyCode": "Timestamp",
    "groupType": "Minute",
    "defaultSelect": "Last"
  },
  "deviceCriteria": [
    {
      "key": "DevidPvital", 
      "operator": "Equal", 
      "value": "", 
      "valueFrom": null, 
      "valueTo": null
    },
    {
      "key": "Timestamp",
      "operator": "Between",
      "valueFrom": "",
      "valueTo": "",
    },
  ],
  "ownerFilter": {
    "users": [],
    "practices": [],
    "suppliers": [],
    "tenantId": null,
    "empty": false
  },
  "offset": "",
  "dayLightSaving": "",
};

