import {
  FETCH_OBSERVE_LIST_REQUEST,
  FETCH_OBSERVE_LIST_SUCCESS,
  FETCH_OBSERVE_LIST_ERROR,
  FETCH_INVITES_REQUEST,
  FETCH_INVITES_SUCCESS,
  FETCH_INVITES_ERROR,
  ACTION_INVITES_REQUEST,
  ACTION_INVITES_SUCCESS,
  ACTION_INVITES_ERROR
} from "./actions";
import { REDUCERS } from "../../common/constants";
import { createReducer } from "../../common/helpers";

const schema = {
  loading: false,
  error: false,
  observeList: {
    loading: false,
    error: false,
    totalPages: 0,
    totalElements: 0,
    users: [],
  },
  invitesList: [],
  inviteUpdated: false,
};

const reducer = {
  [FETCH_OBSERVE_LIST_REQUEST]: (state) => {
    return {
      ...state,
      success: false,
      observeList: {
        ...state.observeList,
        ...REDUCERS.LOADING,
      },
    };
  },
  [FETCH_OBSERVE_LIST_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      observeList: {
        ...state.observeList,
        ...REDUCERS.SUCCESS,
        totalPages: payload.totalPages,
        totalElements: payload.totalElements,
        users: payload.content,
      },
    };
  },
  [FETCH_OBSERVE_LIST_ERROR]: (state) => {
    return {
      ...state,
      observeList: {
        ...state.observeList,
        ...REDUCERS.ERROR,
        totalPages: 0,
        totalElements: 0,
        users: [],
      },
    };
  },
  [FETCH_INVITES_REQUEST]: (state) => {
    return {
      ...state,
      ...REDUCERS.LOADING,
      loading: true
    };
  },
  [FETCH_INVITES_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      invitesList: payload.content,
      loading: false
    };
  },
  [FETCH_INVITES_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
    loading: true
  }),
  [ACTION_INVITES_REQUEST]: (state) => {
    return {
      ...state,
      ...REDUCERS.LOADING,
      loading: true,
      inviteUpdated: false
    };
  },
  [ACTION_INVITES_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      loading: false,
      inviteUpdated: true
    };
  },
  [ACTION_INVITES_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
    loading: false,
    inviteUpdated: false
  })
};
  
export default createReducer(schema, reducer);
