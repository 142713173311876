import React from "react";
import { Spring } from "react-spring";
import Grid from "@material-ui/core/Grid";
import "../assets/css/style.css";

const VerticalProgress = ({ progress, min=0, max=10 }) => {
  progress = progress === '-' ? min : progress;
  return (
    <Spring from={{ percent: min }} to={{ percent: progress * max }}>
      {() => {
        return (
          <Grid container>
            <Grid item xs={9}>
              <div className="progress vertical">
                <div style={{ height: `${progress}%` }} className="progress-bar"></div>
              </div>
            </Grid>
            <Grid item xs={3}>
              <Grid
                style={{ height: "100%" }}
                container
                direction="column"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid
                  item
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "#fff",
                  }}
                >
                  {max}
                </Grid>
                <Grid
                  item
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "#fff",
                  }}
                >
                  {min}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    </Spring>
  );
};

export default VerticalProgress;
