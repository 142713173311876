import {
  FETCH_PATIENT_DEVICE_REQUEST,
  FETCH_PATIENT_DEVICE_SUCCESS,
  FETCH_PATIENT_DEVICE_ERROR,
} from "./actions";
import { REDUCERS } from "../../common/constants";
import { createReducer } from "../../common/helpers";

const schema = {
  loading: false,
  error: false,
  patientDeviceList: [],
};

const reducer = {
  [FETCH_PATIENT_DEVICE_REQUEST]: (state) => {
    return {
      ...state,
      ...REDUCERS.LOADING,
    };
  },
  [FETCH_PATIENT_DEVICE_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      patientDeviceList: payload.content,
    };
  },
  [FETCH_PATIENT_DEVICE_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
};

export default createReducer(schema, reducer);
