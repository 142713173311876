import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import moment from "moment-timezone";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import MuiButton from '@material-ui/core/Button';
import Button from "components/CustomButtons/Button.js";
import MenuItem from "@material-ui/core/MenuItem";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import timezones from "../../common/timezones.json";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from "components/Grid/GridItem.js";
import { UTC_TIME_ZONE } from 'common/constants';
import Avatar from "@material-ui/core/Avatar";

import heart_rate from "assets/img/heart_rate-2-512.png";
import lungs from "assets/img/resp.png";
import stress from "assets/img/stress.png";
import motion from "assets/img/motion.png";

const styles = (theme) => ({
  root: {
    margin: 0,
    // padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  labelFocused: {
    color: "#E73F3B !important",
  },
  inputFocused: {
    "&:before": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "&:after": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "& input": {
      borderBottom: "#E73F3B !important",
    },
  },
  inputRoot: {
    color: "red",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
});

const useStyles = makeStyles({
  noPadding: {
    padding: '0px !important',
  },
  noPaddingRight: {
    paddingLeft: '0px !important',
  },
  noPaddingWithBorder: {
    padding: '0px !important',
    borderLeft: '1px solid #0000008a'
  },
  searchBox: {
    width: '100%',
    borderRadius: "5px",
    "& .MuiInputBase-fullWidth": {
      padding: "3px",
    },
    "& .Mui-focused:after": {
      borderColor: "red !important",
    },
    "&.Mui-focused fieldset": {
      borderColor: "red !important",
    },
  },
  drawerRoot: {
    "& .MuiPaper-root": {
      width: "100%",
    },
  },
  labelFocused: {
    color: "#E73F3B !important",
  },
  inputFocused: {
    "&:before": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "&:after": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "& input": {
      borderBottom: "#E73F3B !important",
    },
  },
  inputRoot: {
    color: "red",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
  popover: {
    zIndex: 2,
  },
  fab: {
    margin: "0px",
    top: "auto",
    right: "20px",
    bottom: "20px",
    left: "auto",
    position: "fixed",
    zIndex: 3,
  },
  tableSection: {
    border: '1px solid #0000008a',
    borderRadius: '3px'
  },
  tableDivider: {
    borderBottom: '1px solid #0000008a',
    padding: '16px'
  },
  tableDividerLast: {
    padding: '16px'
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: "space-between",
    alignItems: "center"
  },
}))(MuiDialogActions);

let defaultObj = {
  userTimeZone: 'Central Standard Time',
  currentUserOffset: "America/Chicago",
  userNightMode: '',
  userNightModeStart: '',
  userNightModeEnd: '',
  userMinHr: '',
  userMaxHr: '',
  userMinRr: '',
  userMaxRr: '',
  userMinSt: '',
  userMaxSt: '',
  userMinMo: '',
  userMaxMo: '',
  enableAlerts: '',
  friendlyName: '',
  location: ''
};

export default function Setting({
  showSensorControl,
  setShowSensorControl,
  friendlyName,
  setFriendlyName,
  userData,
  updateSensorControlRequest,
  setUserTimeZone,
  setOffset,
  setAbbr,
  abbr,
  offset,
  userTimeZone: newUserTimeZone,
  userMacAddress,
  setUserMacAddress,
  sensor,
  device,
  updateSensorControlTableRequest,
  loggedUserRole,
  setAlertMessage,
  setToastStatus
}) {
  const classes = useStyles();
  const [sensorData, setSensorData] = useState({ ...defaultObj, friendlyName });
  const handleClose = () => {
    setShowSensorControl(false);
    setFriendlyName();
    setSensorData(defaultObj);
    setUserTimeZone(UTC_TIME_ZONE);
    setOffset(0);
    setUserMacAddress();
    setAbbr('GMT');
  };
  const [keySection, setKeySection] = useState(Date.now());

  const currentDevice = device.graphData.find((val) => val.userId === sensor.userId);
  const currentDeviceData = currentDevice?.linkedGraphData[userMacAddress];

  let fwVersion = "--";
  let hwVersion = "--";
  let activeStatus = "No";
  let wifiRss = '--';
  let latestHrReading = '--';
  let latestRrReading = '--';
  let latestStressReading = '--';
  let latestMotionReading = '--';
  let isOnline = false;
  let isOccupied = false;
  let apneaperminute = "--";
  let routerMac1 = "";
  let routerMac2 = "";
  isOccupied = currentDeviceData?.occupied;
  if(currentDeviceData?.data.length) {
    isOnline = currentDeviceData?.data[0]?.data?.Offline?.value.toLowerCase() === "no" ? true : false;
    isOccupied = currentDeviceData?.data[0]?.data?.Occupied?.value === 1 ? true : false;
  }
 
  apneaperminute = currentDeviceData?.apneaperminute;
  routerMac1 = currentDeviceData?.RouterMac1;
  routerMac2 = currentDeviceData?.RouterMac2;

  if (
    currentDeviceData?.Hwvers ||
    currentDeviceData?.Fwvers ||
    currentDeviceData?.active ||
    currentDeviceData?.WifiRss
  ) {
    hwVersion = currentDeviceData?.Hwvers || "--";
    fwVersion = currentDeviceData?.Fwvers || "--";
    activeStatus = currentDeviceData?.active;
    wifiRss = currentDeviceData?.WifiRss;
  }

  if (
    currentDeviceData &&
    currentDeviceData?.data?.length > 0 &&
    currentDeviceData?.data[0].data
  ) {
    let currentUtcFormatTime = moment().utc().format("YYYY-MM-DDTHH:mm:ss");
    let currentUtcTime = moment(currentUtcFormatTime);
    const firstEntry = currentDevice.linkedGraphData[userMacAddress].data[0];
    const heartLastTime = moment(firstEntry.data.Hr.valueProvidedOn);
    const respiratoryLastTime = moment(firstEntry.data.Rr.valueProvidedOn);
    const stressLastTime = moment(firstEntry.data.Stress.valueProvidedOn);
    const motionLastTime = moment(firstEntry.data.Motion.valueProvidedOn);
    if (currentUtcTime.diff(heartLastTime, "seconds") < 60) {
      latestHrReading = Math.round(firstEntry.data.Hr.value);
    }
    if (
      currentUtcTime.diff(respiratoryLastTime, "seconds") < 60
    ) {
      latestRrReading = Math.round(firstEntry.data.Rr.value);
    }
    if (currentUtcTime.diff(stressLastTime, "seconds") < 60) {
      latestStressReading = firstEntry.data.Stress
        ? Math.round(firstEntry.data.Stress.value)
        : "-";
    }
    if (currentUtcTime.diff(motionLastTime, "seconds") < 60) {
      latestMotionReading = firstEntry.data.Motion
        ? Math.round(firstEntry.data.Motion.value)
        : "-";
    }
  }

  useEffect(() => {
    let userTimeZone = 'Central Standard Time';
    let currentUserOffset = "America/Chicago";
    let userNightMode = '';
    let userNightModeStart = '';
    let userNightModeEnd = '';
    let enableAlerts = '';
    let userMinHr = '';
    let userMaxHr = '';
    let userMinRr = '';
    let userMaxRr = '';
    let userMinSt = '';
    let userMaxSt = '';
    let userMinMo = '';
    let userMaxMo = '';
    let email = 'No';
    let push = 'No';
    let sms = 'No';
    let xMode = '';
    let developerMode = '';
    let forceFw = '';
    let hrLimitHi = '';
    let hrLimitLo = '';
    let bpfSelect = "";
    let gainSelect = "";
    let lteMode = 'No';
    let lteinterval = 0;
    // let userMinHr = '30';
    // let userMaxHr = '150';
    // let userMinRr = '8';
    // let userMaxRr = '60';
    // let userMinSt = '1';
    // let userMaxSt = '9';
    // let userMinMo = '1';
    // let userMaxMo = '9';
    let userDataObj = userData && userData?.identityData && userData?.identityData?.length > 0 && userData?.identityData[0];
    if (userDataObj) {
      userTimeZone = userDataObj.data?.Timezone?.value || userTimeZone;
      enableAlerts = userDataObj.data?.EnableAlerts?.value || '';
      developerMode = userDataObj.data?.DeveloperMode?.value || '';
      xMode = userDataObj.data?.XMode?.value || '';
      forceFw = userDataObj.data?.ForceFw?.value || '';
      const selectedZone = timezones.find((time) => time.value === userTimeZone) || {};
      if (selectedZone && selectedZone.utc && selectedZone.utc.length > 0) {
        currentUserOffset = selectedZone.utc[0]
      }
      const userNotificationType = userDataObj.data?.NotificationType?.value || '';
      const convertTypeToArray = userNotificationType.split(',');
      if (convertTypeToArray.find(val => val === 'Email')) {
        email = 'Yes';
      }
      if (convertTypeToArray.find(val => val === 'Push')) {
        push = 'Yes';
      }
      if (convertTypeToArray.find(val => val === 'SMS')) {
        sms = 'Yes';
      }
    }

    let userParamsDataObj = userData && userData?.paramsData && userData?.paramsData?.length > 0 && userData?.paramsData[0];
    if (userParamsDataObj && !sensorData?.userNightMode) {
      userNightMode = userParamsDataObj.data?.NightMode?.value;
      if (newUserTimeZone && userParamsDataObj.data?.NightModeStart?.value) {
        userNightModeStart = moment
          .utc(
            userParamsDataObj.data?.NightModeStart?.value, 'HH:mm:ss'
          )
          .tz(newUserTimeZone)
          .format("HH:mm:ss");
      }
      if (newUserTimeZone && userParamsDataObj.data?.NightModeEnd?.value) {
        userNightModeEnd = moment
          .utc(
            userParamsDataObj.data?.NightModeEnd?.value, 'HH:mm:ss'
          )
          .tz(newUserTimeZone)
          .format("HH:mm:ss");
      }
      if (newUserTimeZone && userParamsDataObj.data?.HrLimitHi?.value) {
        hrLimitHi = userParamsDataObj.data?.HrLimitHi?.value;
      }
      if (newUserTimeZone && userParamsDataObj.data?.HrLimitLo?.value) {
        hrLimitLo = userParamsDataObj.data?.HrLimitLo?.value;
      }

      bpfSelect = userParamsDataObj.data?.BpfSelect?.value;
      gainSelect = userParamsDataObj.data?.GainSelect?.value;
      lteMode = userParamsDataObj.data?.Ltemode?.value;
      lteinterval = userParamsDataObj.data?.Lteinterval?.value;
      // userNightModeStart = userParamsDataObj.data?.NightModeStart?.value ? moment(userParamsDataObj.data?.NightModeStart?.value, 'h:mm a').format('HH:mm:ss') : '';
      // userNightModeEnd = userParamsDataObj.data?.NightModeEnd?.value ? moment(userParamsDataObj.data?.NightModeEnd?.value, 'h:mm a').format('HH:mm:ss') : '';
    }
    if (userData && userData.thresholdData && userData.thresholdData.length > 0 && !sensorData?.userMaxHr) {
      userMinHr = userData.thresholdData[0].data?.AlertMinhr?.value || '';
      userMaxHr = userData.thresholdData[0].data?.AlertMaxhr?.value || '';
      userMinRr = userData.thresholdData[0].data?.AlertMinrr?.value || '';
      userMaxRr = userData.thresholdData[0].data?.AlertMaxrr?.value || '';
      userMinSt = userData.thresholdData[0].data?.AlertMinst?.value || '';
      userMaxSt = userData.thresholdData[0].data?.AlertMaxst?.value || '';
      userMinMo = userData.thresholdData[0].data?.AlertMinmt?.value || '';
      userMaxMo = userData.thresholdData[0].data?.AlertMaxmt?.value || '';
    }
    setSensorData({
      userTimeZone,
      currentUserOffset,
      userNightMode,
      userNightModeStart,
      userNightModeEnd,
      userMinHr,
      userMaxHr,
      userMinRr,
      userMaxRr,
      userMinSt,
      userMaxSt,
      userMinMo,
      userMaxMo,
      enableAlerts,
      email,
      push,
      sms,
      developerMode,
      xMode,
      forceFw,
      friendlyName: friendlyName,
      location: userData.identityData?.length && userData.identityData[0]?.data?.Location.value,
      hrLimitLo,
      hrLimitHi,
      bpfSelect,
      gainSelect,
      lteMode,
      lteinterval
    });
    setKeySection(Date.now());
  }, [userData, userData.identityData]);

  return (
    <div>
      <Dialog fullWidth="true" maxWidth="sm" onClose={handleClose} aria-labelledby="customized-dialog-title" open={showSensorControl}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <GridContainer style={{"display": "flex", "align-items": "center", "justify-content": "space-around", "padding": "10px 80px 10px 0", "font-size": "12px"}}>
            {<span style={{"font-size": "20px"}}>Sensor Control</span>}
            {
              <Box textAlign="center">
                <div style={{width: "10px",height: "10px",background: `${isOnline ? "green" : "red"}`,borderRadius: "50%",display: "inline-block"}}></div>{" "}
                <b>{isOnline ? 'Online' : 'Offline' }</b>
              </Box>
            }
            {
              <Box textAlign="center">
                <div style={{width: "10px",height: "10px",background: `${isOccupied ? "green" : "red"}`,borderRadius: "50%",display: "inline-block"}}></div>{" "}
                <b>{isOccupied ? 'Occupied' : 'Unoccupied' }</b>
              </Box>
            }
            {loggedUserRole !== "Patient" && (
              <Box textAlign="center">
                <b>Apnea: {apneaperminute || 0}</b>
              </Box>
            )}
          </GridContainer>
          <GridContainer
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <GridItem xs={3}>
              <Box display="flex" flexDirection="row">
                <Box justifyContent="center">
                  <Avatar
                    src={heart_rate}
                    style={{
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  />
                </Box>
                <Box justifyContent="center" style={{ padding: "3px" }}>
                  <Typography
                    style={{
                      verticalAlign: "middle",
                      margin: "auto",
                      fontSize: "22px",
                      fontWeight: "700",
                    }}
                  >
                    {latestHrReading}
                  </Typography>
                </Box>
              </Box>
            </GridItem>
            <GridItem xs={3}>
              <Box display="flex" flexDirection="row">
                <Box justifyContent="center">
                  <Avatar
                    src={lungs}
                    style={{
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  />
                </Box>
                <Box justifyContent="center" style={{ padding: "3px" }}>
                  <Typography
                    style={{
                      verticalAlign: "middle",
                      margin: "auto",
                      fontSize: "22px",
                      fontWeight: "700",
                    }}
                  >
                    {latestRrReading}
                  </Typography>
                </Box>
              </Box>
            </GridItem>
            <GridItem xs={3}>
              <Box display="flex" flexDirection="row">
                <Box justifyContent="center">
                  <Avatar
                    src={stress}
                    style={{
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  />
                </Box>
                <Box justifyContent="center" style={{ padding: "3px" }}>
                  <Typography
                    style={{
                      verticalAlign: "middle",
                      margin: "auto",
                      fontSize: "22px",
                      fontWeight: "700",
                    }}
                  >
                    {latestStressReading}
                  </Typography>
                </Box>
              </Box>
            </GridItem>
            <GridItem xs={3}>
              <Box display="flex" flexDirection="row">
                <Box justifyContent="center">
                  <Avatar
                    src={motion}
                    style={{
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  />
                </Box>
                <Box justifyContent="center" style={{ padding: "3px" }}>
                  <Typography
                    style={{
                      verticalAlign: "middle",
                      margin: "auto",
                      fontSize: "22px",
                      fontWeight: "700",
                    }}
                  >
                    {latestMotionReading}
                  </Typography>
                </Box>
              </Box>
            </GridItem>
          </GridContainer>
        </DialogTitle>
        <DialogContent dividers key={keySection}>
          <GridContainer>
            <GridItem xs={4}>
              <Box>
                <TextField
                  disabled={false}
                  className={classes.searchBox}
                  color="secondary"
                  label="Friendly Name"
                  value={sensorData?.friendlyName}
                  onChange={(val) => {
                    let obj = {
                      friendlyName: val.target.value,
                    };
                    setSensorData({ ...sensorData, ...obj });
                  }}
                  InputLabelProps={{
                    shrink: true,
                    classes: {
                      focused: classes.labelFocused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.root,
                      focused: classes.inputFocused,
                    },
                  }}
                />
              </Box>
            </GridItem>
            <GridItem xs={4}>
              <Box>
                <TextField
                  disabled={false}
                  className={classes.searchBox}
                  color="secondary"
                  label="Location"
                  value={sensorData?.location}
                  onChange={(val) => {
                    let obj = {
                      location: val.target.value,
                    };
                    setSensorData({ ...sensorData, ...obj });
                  }}
                  InputLabelProps={{
                    shrink: true,
                    classes: {
                      focused: classes.labelFocused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.root,
                      focused: classes.inputFocused,
                    },
                  }}
                />
              </Box>
            </GridItem>
            <GridItem xs={4}>
              <Box>
                <TextField
                  disabled={true}
                  className={classes.searchBox}
                  color="secondary"
                  label="Mac Address"
                  value={userMacAddress}
                  InputLabelProps={{
                    shrink: true,
                    classes: {
                      focused: classes.labelFocused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.root,
                      focused: classes.inputFocused,
                    },
                  }}
                />
              </Box>
            </GridItem>
            <GridItem xs={12}>
              <Box pt={2}>
                <TextField
                  className={classes.searchBox}
                  select
                  disabled={false}
                  color="secondary"
                  label="Timezone"
                  value={sensorData?.userTimeZone}
                  onChange={(val) => {
                    let obj = {};
                    const selectedZone = timezones.find((time) => time.value === val.target.value) || {};
                    if (selectedZone && selectedZone.utc[0]) {
                      const selectedStartTimeZone = moment(sensorData.userNightModeStart, 'HH:mm:ss').format(
                        "YYYY-MM-DDTHH:mm:ss"
                      );
                      const selectedEndTimeZone = moment(sensorData.userNightModeEnd, 'HH:mm:ss').format(
                        "YYYY-MM-DDTHH:mm:ss"
                      );
                      let startTime = moment.tz(selectedStartTimeZone, sensorData.currentUserOffset).utc().format('HH:mm:ss');
                      let endTime = moment.tz(selectedEndTimeZone, sensorData.currentUserOffset).utc().format('HH:mm:ss');
                      let userNightModeStart = moment
                        .utc(
                          startTime, 'HH:mm:ss'
                        )
                        .tz(selectedZone.utc[0])
                        .format("HH:mm:ss");
                      let userNightModeEnd = moment
                        .utc(
                          endTime, 'HH:mm:ss'
                        )
                        .tz(selectedZone.utc[0])
                        .format("HH:mm:ss");
                      obj = {
                        userNightModeStart,
                        userNightModeEnd
                      }
                    }
                    setSensorData({ ...sensorData, ...obj, userTimeZone: val.target.value, currentUserOffset: selectedZone.utc[0] });
                  }}
                  InputLabelProps={{
                    shrink: true,
                    classes: {
                      focused: classes.labelFocused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.root,
                      focused: classes.inputFocused,
                    },
                  }}
                >
                  {timezones.map((val, index) => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      key={`${val}-${index}`}
                      value={val.value}
                    >
                      {`${val.text} (${val.abbr})`}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </GridItem>
            <GridItem xs={12}>
              <Box pt={3}>
                <TextField
                  className={classes.searchBox}
                  select
                  color="secondary"
                  label="NightMode"
                  disabled={false}
                  value={sensorData?.userNightMode}
                  onChange={(val) => {
                    let obj = {
                      userNightMode: val.target.value,
                    };
                    setSensorData({ ...sensorData, ...obj });
                  }}
                  InputLabelProps={{
                    shrink: true,
                    classes: {
                      focused: classes.labelFocused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.root,
                      focused: classes.inputFocused,
                    },
                  }}
                >
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                    value='Yes'>
                    ON
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                    value='No'>
                    OFF
                  </MenuItem>
                </TextField>
              </Box>
            </GridItem>
            {sensorData?.userNightMode === 'Yes' && <><GridItem xs={12}>
              <Box pt={3}>
                <Grid container>
                  <GridItem xs={6} className={classes.noPaddingRight}>
                    <TextField
                      disabled={ false}
                      type="time"
                      label="Night Mode Start"
                      value={sensorData?.userNightModeStart}
                      className={classes.searchBox}
                      onChange={(val) => {
                        let obj = {
                          userNightModeStart: val.target.value,
                        };
                        setSensorData({ ...sensorData, ...obj });
                      }}
                      InputLabelProps={{
                        shrink: true,
                        classes: {
                          focused: classes.labelFocused,
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.root,
                          focused: classes.inputFocused,
                        },
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} className={classes.noPaddingRight}>
                    <TextField
                      type="time"
                      disabled={ false}
                      label="Night Mode End"
                      value={sensorData?.userNightModeEnd}
                      className={classes.searchBox}
                      onChange={(val) => {
                        let obj = {
                          userNightModeEnd: val.target.value,
                        };
                        setSensorData({ ...sensorData, ...obj });
                      }}
                      InputLabelProps={{
                        shrink: true,
                        classes: {
                          focused: classes.labelFocused,
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.root,
                          focused: classes.inputFocused,
                        },
                      }}
                    />
                  </GridItem>
                </Grid>
              </Box>
            </GridItem></>}
            <GridItem xs={12}>
              <Box pt={3}>
                <TextField
                  className={classes.searchBox}
                  select
                  color="secondary"
                  label="Enable Alerts"
                  disabled={false}
                  value={sensorData?.enableAlerts}
                  onChange={(val) => {
                    let obj = {
                      enableAlerts: val.target.value,
                    };
                    setSensorData({ ...sensorData, ...obj });
                  }}
                  InputLabelProps={{
                    shrink: true,
                    classes: {
                      focused: classes.labelFocused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.root,
                      focused: classes.inputFocused,
                    },
                  }}
                >
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                    value='Yes'>
                    Yes
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                    value='No'>
                    No
                  </MenuItem>
                </TextField>
              </Box>
            </GridItem>
            {sensorData?.enableAlerts === 'Yes' && <><GridItem xs={4}>
              <Box pt={3}>
                <TextField
                  className={classes.searchBox}
                  select
                  color="secondary"
                  label="Email"
                  disabled={false}
                  value={sensorData?.email}
                  onChange={(val) => {
                    let obj = {
                      email: val.target.value,
                    };
                    setSensorData({ ...sensorData, ...obj });
                  }}
                  InputLabelProps={{
                    shrink: true,
                    classes: {
                      focused: classes.labelFocused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.root,
                      focused: classes.inputFocused,
                    },
                  }}
                >
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                    value='Yes'>
                    ON
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                    value='No'>
                    OFF
                  </MenuItem>
                </TextField>
              </Box>
            </GridItem>
              <GridItem xs={4}>
                <Box pt={3}>
                  <TextField
                    className={classes.searchBox}
                    select
                    color="secondary"
                    label="Push"
                    value={sensorData?.push}
                    disabled={false}
                    onChange={(val) => {
                      let obj = {
                        push: val.target.value,
                      };
                      setSensorData({ ...sensorData, ...obj });
                    }}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        focused: classes.labelFocused,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.root,
                        focused: classes.inputFocused,
                      },
                    }}
                  >
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      value='Yes'>
                      ON
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      value='No'>
                      OFF
                    </MenuItem>
                  </TextField>
                </Box>
              </GridItem>
              <GridItem xs={4}>
                <Box pt={3}>
                  <TextField
                    disabled={true}
                    className={classes.searchBox}
                    select
                    color="secondary"
                    label="SMS"
                    value={sensorData?.sms}
                    onChange={(val) => {
                      let obj = {
                        sms: val.target.value,
                      };
                      setSensorData({ ...sensorData, ...obj });
                    }}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        focused: classes.labelFocused,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.root,
                        focused: classes.inputFocused,
                      },
                    }}
                  >
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      value='Yes'>
                      ON
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      value='No'>
                      OFF
                    </MenuItem>
                  </TextField>
                </Box>
              </GridItem></>
            }
            <GridItem xs={12}>
              <Box mt={3}><b>Alert Threshold Settings</b></Box>
              <Box mt={1} className={classes.tableSection}>
                <Grid container>
                  <GridItem xs={6} className={classes.noPadding}>
                    <Grid container justifyContent="center" alignItems="center" className={classes.tableDivider}>
                      <GridItem xs={4} className={classes.noPadding}>
                        <Box textAlign="center">Heart Rate</Box>
                      </GridItem>
                      <GridItem xs={4} className={classes.noPadding}>
                        <Grid
                          container
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <GridItem xs={12}>
                            <TextField
                              className={classes.searchBox}
                              value={sensorData?.userMaxHr}
                              id="standard-start-adornment"
                              disabled={sensorData?.enableAlerts === 'No'}
                              onChange={(val) => {
                                let obj = {
                                  userMaxHr: val.target.value,
                                };
                                setSensorData({ ...sensorData, ...obj });
                              }}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">H</InputAdornment>,
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12}>
                            <TextField
                              className={classes.searchBox}
                              id="standard-start-adornment"
                              value={sensorData?.userMinHr}
                              disabled={sensorData?.enableAlerts === 'No'}
                              onChange={(val) => {
                                let obj = {
                                  userMinHr: val.target.value,
                                };
                                setSensorData({ ...sensorData, ...obj });
                              }}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">L</InputAdornment>,
                              }}
                            />
                          </GridItem>
                        </Grid>
                      </GridItem>
                      <GridItem xs={4} className={classes.noPadding}>30 to 150</GridItem>
                    </Grid>
                    <Grid container justifyContent="center" alignItems="center" className={classes.tableDividerLast}>
                      <GridItem xs={4} className={classes.noPadding}>
                        <Box textAlign="center">Stress</Box>
                      </GridItem>
                      <GridItem xs={4} className={classes.noPadding}>
                        <Grid
                          container
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <GridItem xs={12}>
                            <TextField
                              className={classes.searchBox}
                              id="standard-start-adornment"
                              value={sensorData?.userMaxSt}
                              disabled={sensorData?.enableAlerts === 'No' }
                              onChange={(val) => {
                                let obj = {
                                  userMaxSt: val.target.value,
                                };
                                setSensorData({ ...sensorData, ...obj });
                              }}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">H</InputAdornment>,
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12}>
                            <TextField
                              className={classes.searchBox}
                              id="standard-start-adornment"
                              value={sensorData?.userMinSt}
                              disabled={sensorData?.enableAlerts === 'No' }
                              onChange={(val) => {
                                let obj = {
                                  userMinSt: val.target.value,
                                };
                                setSensorData({ ...sensorData, ...obj });
                              }}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">L</InputAdornment>,
                              }}
                            />
                          </GridItem>
                        </Grid>
                      </GridItem>
                      <GridItem xs={4} className={classes.noPadding}>0 to 10</GridItem>
                    </Grid>
                  </GridItem>
                  <GridItem xs={6} className={classes.noPaddingWithBorder}>
                    <Grid container justifyContent="center" alignItems="center" className={classes.tableDivider}>
                      <GridItem xs={4} className={classes.noPadding}>
                        <Box textAlign="center">Breath Rate</Box>
                      </GridItem>
                      <GridItem xs={4} className={classes.noPadding}>
                        <Grid
                          container
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <GridItem xs={12}>
                            <TextField
                              className={classes.searchBox}
                              id="standard-start-adornment"
                              value={sensorData?.userMaxRr}
                              disabled={sensorData?.enableAlerts === 'No'}
                              onChange={(val) => {
                                let obj = {
                                  userMaxRr: val.target.value,
                                };
                                setSensorData({ ...sensorData, ...obj });
                              }}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">H</InputAdornment>,
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12}>
                            <TextField
                              className={classes.searchBox}
                              id="standard-start-adornment"
                              value={sensorData?.userMinRr}
                              onChange={(val) => {
                                let obj = {
                                  userMinRr: val.target.value,
                                };
                                setSensorData({ ...sensorData, ...obj });
                              }}
                              disabled={sensorData?.enableAlerts === 'No'}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">L</InputAdornment>,
                              }}
                            />
                          </GridItem>
                        </Grid>
                      </GridItem>
                      <GridItem xs={4} className={classes.noPadding}>8 to 60</GridItem>
                    </Grid>
                    <Grid container justifyContent="center" alignItems="center" className={classes.tableDividerLast}>
                      <GridItem xs={4} className={classes.noPadding}>
                        <Box textAlign="center">Motion</Box>
                      </GridItem>
                      <GridItem xs={4} className={classes.noPadding}>
                        <Grid
                          container
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <GridItem xs={12}>
                            <TextField
                              className={classes.searchBox}
                              id="standard-start-adornment"
                              value={sensorData?.userMaxMo}
                              onChange={(val) => {
                                let obj = {
                                  userMaxMo: val.target.value,
                                };
                                setSensorData({ ...sensorData, ...obj });
                              }}
                              disabled={sensorData?.enableAlerts === 'No' }
                              InputProps={{
                                startAdornment: <InputAdornment position="start">H</InputAdornment>,
                              }}

                            />
                          </GridItem>
                          <GridItem xs={12}>
                            <TextField
                              className={classes.searchBox}
                              id="standard-start-adornment"
                              value={sensorData?.userMinMo}
                              onChange={(val) => {
                                let obj = {
                                  userMinMo: val.target.value,
                                };
                                setSensorData({ ...sensorData, ...obj });
                              }}
                              disabled={sensorData?.enableAlerts === 'No' }
                              InputProps={{
                                startAdornment: <InputAdornment position="start">L</InputAdornment>,
                              }}
                            />
                          </GridItem>
                        </Grid>
                      </GridItem>
                      <GridItem xs={4} className={classes.noPadding}>0 to 10</GridItem>
                    </Grid>
                  </GridItem>
                </Grid>
              </Box>
            </GridItem>
            {
              loggedUserRole !== "Patient" && 
              <>
               <GridItem xs={3}>
                    <Box pt={2}>
                      <TextField
                        className={classes.searchBox}
                        color="secondary"
                        label="BPF Select"
                        value={sensorData.bpfSelect}
                        onChange={(val) => {
                          let obj = {
                            bpfSelect: val.target.value,
                          };
                          setSensorData({ ...sensorData, ...obj });
                        }}
                        InputLabelProps={{
                          shrink: true,
                          classes: {
                            focused: classes.labelFocused,
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.root,
                            focused: classes.inputFocused,
                          },
                        }}
                      />
                    </Box>
                  </GridItem>
                 

                  <GridItem xs={3}>
                    <Box pt={2}>
                      <TextField
                        className={classes.searchBox}
                        color="secondary"
                        label="GAIN Select"
                        value={sensorData.gainSelect}
                        onChange={(val) => {
                          let obj = {
                            gainSelect: val.target.value,
                          };
                          setSensorData({ ...sensorData, ...obj });
                        }}
                        InputLabelProps={{
                          shrink: true,
                          classes: {
                            focused: classes.labelFocused,
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.root,
                            focused: classes.inputFocused,
                          },
                        }}
                      />
                    </Box>
                  </GridItem>
                
                  <GridItem xs={3}>
                    <Box pt={2}>
                    <TextField
                    className={classes.searchBox}
                    select
                    color="secondary"
                    label="LTE Mode"
                    disabled={ false}
                    value={sensorData?.lteMode}
                    onChange={(val) => {
                      if(val.target.value === 'Yes'){
                        let obj = {
                          lteMode: val.target.value,
                        };
                        setSensorData({ ...sensorData, ...obj });
                      } else {
                        let obj = {
                          lteMode: 'No',
                        };
                        setSensorData({ ...sensorData, ...obj });
                      }                    
                    }}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        focused: classes.labelFocused,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.root,
                        focused: classes.inputFocused,
                      },
                    }}
                  >
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      value='Yes'>
                      Yes
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      value='No'>
                      No
                    </MenuItem>
                  </TextField>
                    </Box>
                  </GridItem>

                  <GridItem xs={3}>
                    <Box pt={2}>
                      <TextField
                        className={classes.searchBox}
                        color="secondary"
                        label="LTE Interval"
                        value={sensorData.lteinterval}
                        onChange={(val) => {
                          let obj = {
                            lteinterval: val.target.value,
                          };
                          setSensorData({ ...sensorData, ...obj });
                        }}
                        InputLabelProps={{
                          shrink: true,
                          classes: {
                            focused: classes.labelFocused,
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.root,
                            focused: classes.inputFocused,
                          },
                        }}
                      />
                    </Box>
                  </GridItem>
                  
              <GridItem xs={6}>
                <Box pt={2}>
                  <TextField
                    disabled={ true }
                    className={classes.searchBox}
                    color="secondary"
                    label="Router MAC 1"
                    value={routerMac1}
                    onChange={(val) => {
                      let obj = {
                        routerMac1: val.target.value,
                      };
                      setSensorData({ ...sensorData, ...obj });
                    }}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        focused: classes.labelFocused,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.root,
                        focused: classes.inputFocused,
                      },
                    }}
                  />
                </Box>
              </GridItem>
              <GridItem xs={6}>
                <Box pt={2}>
                  <TextField
                    disabled={true }
                    className={classes.searchBox}
                    color="secondary"
                    label="Router MAC 2"
                    value={routerMac2}
                    onChange={(val) => {
                      let obj = {
                        routerMac2: val.target.value,
                      };
                      setSensorData({ ...sensorData, ...obj });
                    }}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        focused: classes.labelFocused,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.root,
                        focused: classes.inputFocused,
                      },
                    }}
                  />
                </Box>
              </GridItem>
              <GridItem xs={6}>
                <Box pt={3}>
                  <TextField
                    type='number'
                    className={classes.searchBox}
                    value={sensorData?.hrLimitHi}
                    inputProps={{
                      max: 999,
                      min: 0
                    }}
                    disabled={sensorData?.enableAlerts === 'No' || loggedUserRole === "Patient" ? true : false}
                    onChange={(val) => {
                      const value = +val.target.value;
                      let obj = {
                        hrLimitHi: value,
                      };
                      if (val.target.value === '') {
                        obj.hrLimitHi = '';
                      } else if (value > 999) {
                        obj.hrLimitHi = 999;
                        setAlertMessage("HR_LIMIT_HI must be between 0 to 999");
                        setToastStatus(true);
                      } else if (value < 0) {
                        obj.hrLimitHi = 0;
                        setAlertMessage("HR_LIMIT_HI must be between 0 to 999");
                        setToastStatus(true);
                      }
                      setSensorData({ ...sensorData, ...obj });
                    }}
                    label="HR_LIMIT_HI"
                  />
                </Box>
              </GridItem>
              <GridItem xs={6}>
                <Box pt={3}>
                  <TextField
                    type='number'
                    className={classes.searchBox}
                    value={sensorData?.hrLimitLo}
                    inputProps={{
                      max: 999,
                      min: 0
                    }}
                    disabled={sensorData?.enableAlerts === 'No' || loggedUserRole === "Patient" ? true : false}
                    onChange={(val) => {
                      const value = +val.target.value;
                      let obj = {
                        hrLimitLo: value,
                      };
                      if (val.target.value === '') {
                        obj.hrLimitLo = '';
                      } else if (value > 999) {
                        obj.hrLimitLo = 999;
                        setAlertMessage("HR_LIMIT_LO must be between 0 to 999");
                        setToastStatus(true);
                      } else if (value < 0) {
                        obj.hrLimitLo = 0;
                        setAlertMessage("HR_LIMIT_LO must be between 0 to 999");
                        setToastStatus(true);
                      }
                      setSensorData({ ...sensorData, ...obj });
                    }}
                    label="HR_LIMIT_LO"
                  />
                </Box>
              </GridItem>
              <GridItem xs={4}>
                <Box pt={3}>
                  <TextField
                    className={classes.searchBox}
                    select
                    color="secondary"
                    label="Developer Mode"
                    disabled={loggedUserRole === "Patient" ? true : false}
                    value={sensorData?.developerMode}
                    onChange={(val) => {
                      if(val.target.value === 'Yes'){
                        let obj = {
                          developerMode: val.target.value,
                          xMode: 'No',
                        };
                        setSensorData({ ...sensorData, ...obj });
                      } else {
                        let obj = {
                          developerMode: 'No',
                        };
                        setSensorData({ ...sensorData, ...obj });
                      }                    
                    }}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        focused: classes.labelFocused,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.root,
                        focused: classes.inputFocused,
                      },
                    }}
                  >
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      value='Yes'>
                      Yes
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      value='No'>
                      No
                    </MenuItem>
                  </TextField>
                </Box>
              </GridItem>
              <GridItem xs={4}>
                <Box pt={3}>
                  <TextField
                    className={classes.searchBox}
                    select
                    color="secondary"
                    label="XMode"
                    value={sensorData?.xMode}
                    disabled={loggedUserRole === "Patient" ? true : false}
                    onChange={(val) => {
                      if(val.target.value === 'Yes'){
                        let obj = {
                          xMode: val.target.value,
                          developerMode: 'No',
                        };
                        setSensorData({ ...sensorData, ...obj });
                      } else {
                        let obj = {
                          xMode: 'No',
                        };
                        setSensorData({ ...sensorData, ...obj });
                      }    
                    }}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        focused: classes.labelFocused,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.root,
                        focused: classes.inputFocused,
                      },
                    }}
                  >
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      value='Yes'>
                      Yes
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      value='No'>
                      No
                    </MenuItem>
                  </TextField>
                </Box>
              </GridItem>
              <GridItem xs={4}>
                <Box pt={3}>
                <TextField
                  disabled={loggedUserRole === "Patient" ? true : false}
                  className={classes.searchBox}
                  color="secondary"
                  label="ForceFw"
                  value={sensorData?.forceFw}
                  onChange={(val) => {
                    let obj = {
                      forceFw: val.target.value,
                    };
                    setSensorData({ ...sensorData, ...obj });
                  }}
                  InputLabelProps={{
                    shrink: true,
                    classes: {
                      focused: classes.labelFocused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.root,
                      focused: classes.inputFocused,
                    },
                  }}
                >
                </TextField>
                </Box>
              </GridItem>
              </>
            }
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <GridContainer
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {loggedUserRole !== "" && <><GridItem>
              <MuiButton
                color="secondary"
                variant="outlined"
                onClick={() => {
                  let paramObj = {
                    "devicePropertySetId": userData.paramsPropertySet[0].devicePropertySetId,
                    "deviceDataModelId": userData.paramsData[0].deviceDataModelId,
                    "deviceDataId": userData.paramsData[0].deviceDataId,
                    "data": Object.keys(userData.paramsData[0].data).reduce((acc, val) => {
                      let obj = {};
                      obj[val] = userData.paramsData[0].data[val].value;
                      return { ...acc, ...obj };
                    }, {})
                  }
                  paramObj.data.Restart = true;
                  updateSensorControlTableRequest(paramObj);
                }}
              >
                Restart Sensor
              </MuiButton>
              {"  "}
              <MuiButton
                color="secondary"
                variant="outlined"
                onClick={() => {
                  let identityObj = {
                    "devicePropertySetId": userData.identityPropertySet[0].devicePropertySetId,
                    "deviceDataModelId": userData.identityData[0].deviceDataModelId,
                    "deviceDataId": userData.identityData[0].deviceDataId,
                    "data": Object.keys(userData.identityData[0].data).reduce((acc, val) => {
                      let obj = {};
                      obj[val] = userData.identityData[0].data[val].value;
                      return { ...acc, ...obj };
                    }, {})
                  }
                  identityObj.data.FindMySensor = 'Yes';
                  updateSensorControlTableRequest(identityObj);
                }}
              >
                Find My Sensor
              </MuiButton>
              {"  "}
              <MuiButton
                color="secondary"
                variant="outlined"
                onClick={() => {
                  let paramObj = {
                    "devicePropertySetId": userData.paramsPropertySet[0].devicePropertySetId,
                    "deviceDataModelId": userData.paramsData[0].deviceDataModelId,
                    "deviceDataId": userData.paramsData[0].deviceDataId,
                    "data": Object.keys(userData.paramsData[0].data).reduce((acc, val) => {
                      let obj = {};
                      obj[val] = userData.paramsData[0].data[val].value;
                      return { ...acc, ...obj };
                    }, {})
                  }
                  paramObj.data.PushAlert = 'Yes';
                  updateSensorControlTableRequest(paramObj);
                }}
              >
                Push Alert
              </MuiButton>
            </GridItem>
              <GridItem>
                <Button
                  color="danger"
                  onClick={() => {
                    if (
                      sensorData.developerMode.toLowerCase() === 'yes' &&
                      sensorData.xMode.toLowerCase() === 'yes'
                    ) {
                      setAlertMessage("Developer Mode and XMode both could not be yes at same time");
                      setToastStatus(true);
                    } else if ( sensorData.hrLimitHi && sensorData.hrLimitLo && sensorData.hrLimitHi <= sensorData.hrLimitLo) {
                      setAlertMessage("HR_LIMIT_HI value must be greater then HR_LIMIT_LO");
                      setToastStatus(true);
                    } else {
                      let identityObj = {
                        "devicePropertySetId": userData && userData.identityPropertySet ? userData.identityPropertySet[0].devicePropertySetId : 'fdab73ae-28df-45f5-92c5-b24460daf682',
                        "deviceDataModelId": userData && userData.identityData && userData.identityData.length > 0 ? userData.identityData[0].deviceDataModelId : "d36a4373-fbdc-44a3-8c96-4bb920041e40-id",
                        "data": userData.identityData && userData.identityData.length > 0 ? Object.keys(userData.identityData[0].data).reduce((acc, val) => {
                          let obj = {};
                          obj[val] = userData.identityData[0].data[val].value;
                          return { ...acc, ...obj };
                        }, {}) : {}
                      }
                      if (userData && userData.identityData && userData.identityData.length === 0) {
                        identityObj.data.Devid = userMacAddress;
                        identityObj.data.FriendlyName = friendlyName;
                      }
                      if (userData && userData.identityData && userData.identityData.length > 0) {
                        identityObj.deviceDataId = userData.identityData[0].deviceDataId;
                      }
                      identityObj.data.Timezone = sensorData.userTimeZone;
                      identityObj.data.EnableAlerts = sensorData.enableAlerts;
                      identityObj.data.DeveloperMode = sensorData.developerMode;
                      identityObj.data.XMode = sensorData.xMode;
                      identityObj.data.ForceFw = sensorData.forceFw;
                      identityObj.data.FriendlyName = sensorData.friendlyName;
                      identityObj.data.Location = sensorData.location;
                      if (sensorData.enableAlerts === 'Yes') {
                        let notificationType = [];
                        if (sensorData.email === 'Yes') {
                          notificationType = [...notificationType, 'Email'];
                        }
                        if (sensorData.push === 'Yes') {
                          notificationType = [...notificationType, 'Push'];
                        }
                        identityObj.data.NotificationType = notificationType.toString();
                      }
                      let paramObj = {
                        "devicePropertySetId": userData && userData.paramsPropertySet ? userData.paramsPropertySet[0].devicePropertySetId : 'cda7b88f-3f07-43cf-bba9-0512010874fc',
                        "deviceDataModelId": userData && userData.paramsData && userData.paramsData.length > 0 ? userData.paramsData[0].deviceDataModelId : "d36a4373-fbdc-44a3-8c96-4bb920041e40-pa",
                        "data": userData.paramsData && userData.paramsData.length > 0 ? Object.keys(userData.paramsData[0].data).reduce((acc, val) => {
                          let obj = {};
                          obj[val] = userData.paramsData[0].data[val].value;
                          return { ...acc, ...obj };
                        }, {}) : {}
                      }
                      if (userData && userData.paramsData && userData.paramsData.length === 0) {
                        paramObj.data.DevidParams = userMacAddress;
                        paramObj.data.FriendlyNamePa = friendlyName;
                      }
                      if (userData && userData.paramsData && userData.paramsData.length > 0) {
                        paramObj.deviceDataId = userData.paramsData[0].deviceDataId;
                      }
                      paramObj.data.NightMode = sensorData.userNightMode;
                      paramObj.data.HrLimitHi = sensorData.hrLimitHi;
                      paramObj.data.HrLimitLo = sensorData.hrLimitLo;
                      paramObj.data.BpfSelect = sensorData.bpfSelect;
                      paramObj.data.GainSelect = sensorData.gainSelect;
                      paramObj.data.Ltemode = sensorData.lteMode;
                      paramObj.data.Lteinterval = sensorData.lteinterval;
                      

                      paramObj.data.FriendlyNamePa = sensorData.friendlyName;
                      if (sensorData.userNightMode && sensorData.userNightMode.toLowerCase() === 'yes') {
                        const selectedStartTimeZone = moment(sensorData.userNightModeStart, 'HH:mm:ss').format(
                          "YYYY-MM-DDTHH:mm:ss"
                        );
                        const selectedEndTimeZone = moment(sensorData.userNightModeEnd, 'HH:mm:ss').format(
                          "YYYY-MM-DDTHH:mm:ss"
                        );
                        paramObj.data.NightModeStart = moment.tz(selectedStartTimeZone, sensorData.currentUserOffset).utc().format('HH:mm:ss');
                        paramObj.data.NightModeEnd = moment.tz(selectedEndTimeZone, sensorData.currentUserOffset).utc().format('HH:mm:ss');
                      }
                      let thresholdObj = {
                        "devicePropertySetId": userData && userData.thresholdPropertySet ? userData.thresholdPropertySet[0].devicePropertySetId : 'b7bbec21-ae10-4a2d-bb11-6910245985ec',
                        "deviceDataModelId": userData && userData.thresholdData && userData.thresholdData.length > 0 ? userData.thresholdData[0].deviceDataModelId : "d36a4373-fbdc-44a3-8c96-4bb920041e40-th",
                        "data": userData.thresholdData && userData.thresholdData.length > 0 ? Object.keys(userData.thresholdData[0].data).reduce((acc, val) => {
                          let obj = {};
                          obj[val] = userData.thresholdData[0].data[val].value;
                          return { ...acc, ...obj };
                        }, {}) : {}
                      }
                      if (userData && userData.thresholdData && userData.thresholdData.length === 0) {
                        thresholdObj.data.DevidThresholds = userMacAddress;
                        thresholdObj.data.FriendlyNameT = friendlyName;
                      }
                      if (userData && userData.thresholdData && userData.thresholdData.length > 0) {
                        thresholdObj.deviceDataId = userData.thresholdData[0].deviceDataId;
                      }
                      thresholdObj.data.AlertMinhr = sensorData.userMinHr;
                      thresholdObj.data.AlertMaxhr = sensorData.userMaxHr;
                      thresholdObj.data.AlertMinrr = sensorData.userMinRr;
                      thresholdObj.data.AlertMaxrr = sensorData.userMaxRr;
                      thresholdObj.data.AlertMinst = sensorData.userMinSt;
                      thresholdObj.data.AlertMaxst = sensorData.userMaxSt;
                      thresholdObj.data.AlertMinmt = sensorData.userMinMo;
                      thresholdObj.data.AlertMaxmt = sensorData.userMaxMo;
                      thresholdObj.data.FriendlyNameT = sensorData.friendlyName;
                      updateSensorControlRequest([identityObj, paramObj, thresholdObj]);
                    }
                  }}
                >
                  Save
                </Button>
              </GridItem></>}
            <GridItem xs={12}>
              <GridContainer
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <GridItem xs={3}>
                  <Box textAlign="center">Hwvers: <strong>{hwVersion}</strong></Box>
                </GridItem>
                <GridItem xs={3}>
                  <Box textAlign="center">Fwvers: <strong>{fwVersion}</strong></Box>
                </GridItem>
                <GridItem xs={3}>
                  <Box textAlign="center">WiFi Rss: <strong>{wifiRss}</strong></Box>
                </GridItem>
                <GridItem xs={3}>
                  <Box textAlign="center">Active: <strong>{activeStatus}</strong></Box>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogActions>
      </Dialog>
    </div>
  );
}