/* eslint-disable no-unused-vars */

import { combineEpics } from "redux-observable";
import * as users from "./users/epics";
import * as practice from "./practice/epics";
import * as company from "./company/epics";
import * as patientDevice from "./patientDevice/epics";
import * as auditLogs from "./auditLogs/epics";
import * as dashboard from "./dashboard/epics";
import * as device from "./device/epics";
import * as notifications from "./notifications/epics";
import * as customFields from "./customFields/epics";
import * as observe from "./observe/epics";
import * as guestMap from "./guestMap/epics";

export default combineEpics(
  ...Object.entries({
    ...users,
    ...practice,
    ...company,
    ...patientDevice,
    ...auditLogs,
    ...device,
    ...dashboard,
    ...notifications,
    ...customFields,
    ...observe,
    ...guestMap,
  }).map(([key, value]) => value)
);
