import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from "react-redux";
import Datetime from "react-datetime";
import moment from "moment";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Dialog from '@material-ui/core/Dialog';
import Box from "@material-ui/core/Box";

import {SEND_INVITE_REQUEST} from "../../redux/guestMap/actions";
import GridContainer from "components/Grid/GridContainer.js";
import { useComposeDispatcher } from "../../common/hooks";
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";

const today = moment();
const disablePastDate = (current) => current.isAfter(today);
export default function InviteGuest({
    showInviteDialog,
    setShowInviteDialog
}) {

    const { guestMapData } = useSelector((state) => ({ guestMapData: state.guestMap}));

    const [onInviteGuest] = useComposeDispatcher(SEND_INVITE_REQUEST);

    const [validateEmail, setValidateEmail] = useState({isValid: true, msg: ''});
    const [selectedStartDate, handleStartDateChange] = useState(new Date());
    const [usedExistanceMsg, setUsedExistanceMsg] = useState(false);
    const emailRef = useRef('');

    const onSubmit = () => {
        let emailVal = emailRef.current.value;
          emailVal = emailVal && emailVal.trim();
        let dateSelected = moment(selectedStartDate).format("YYYY-MM-DDTHH:mm:ss");
        
        const emailReg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
        if (!emailReg.test(emailVal)) {
          setValidateEmail({isValid: false, msg: 'Invalid Email'});
        } else {
          setValidateEmail({isValid: true, msg: ''})
          const payload = {
              "emailAddress": emailVal,
              "validUntil" : dateSelected
          }
          if(guestMapData?.isNewAccount && usedExistanceMsg) {
            payload.guestInvite = true;
          }
          onInviteGuest(payload);
        }
    }

    useEffect(() => {
      if(guestMapData?.inviteSent) {
        setShowInviteDialog(false);
      }
    }, [guestMapData?.inviteSent]);

    useEffect(() => {
      if (guestMapData?.isNewAccount) {
        setUsedExistanceMsg(true);
      }
    }, [guestMapData?.isNewAccount]);

    const onCloseDialog = () => {
      setUsedExistanceMsg(false);
      setShowInviteDialog(false);
      emailRef.current.value = "";
    }

    useEffect(() => {
      return () => {
        onCloseDialog()
      }
    }, []);

    return(
        <Dialog open={showInviteDialog}  keepMounted onClose={onCloseDialog} aria-describedby="alert-dialog-slide-description">
            <DialogTitle className="tac">{"Invite Guest"}</DialogTitle>
            <DialogContent style={{"minHeight":"550px"}}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          error={!validateEmail.isValid && emailRef.current.value}
                          fullWidth
                          id="standard-error-helper-text"
                          label="Email"
                          helperText={validateEmail.msg}
                          inputRef={emailRef}
                          variant="standard"
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Box paddingTop={2}>
                        <InputLabel style={{ fontSize: "12px" }}> Start Time </InputLabel>
                  
                        <Datetime
                            isValidDate={disablePastDate}
                            closeOnSelect={true}
                            value={selectedStartDate}
                            onChange={(val) => {handleStartDateChange(val);}}
                        />
                        </Box>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <p className="dateDisclaimer"> * If you do not specify a validity, then your observer will have access until you manually revoke it.</p>
                    </GridItem>
                </GridContainer>
                {usedExistanceMsg && 
                  <p className="guestDisclaimer">
                    User with email <strong>{emailRef?.current?.value}</strong> does not exists in the system. Would you like to send an invitation to <strong>{emailRef?.current?.value}</strong> to join ATS Dev cloud platform?
                    Once they accept your invitation and sign up, they will be able to view your personal health data and gain insights.
                  </p>
                }
            </DialogContent>
            <DialogActions>
                <Button color="danger" onClick={onSubmit}>Send</Button>
                <Button onClick={onCloseDialog}>Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}