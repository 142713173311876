import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import AddAlert from "@material-ui/icons/AddAlert";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import MenuItem from "@material-ui/core/MenuItem";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Loader from "components/Loader/Loader.js";
import Snackbar from "components/Snackbar/Snackbar.js";

import {
  FETCH_USER_LIST_REQUEST,
  DELETE_USER_PRACTICE_REQUEST,
  DELETE_USER_REQUEST,
  CHANGE_PASSWORD_REQUEST,
} from "../../redux/users/actions";
import { FETCH_COMPANY_REQUEST } from "../../redux/company/actions";

import { FILTER_USER_ROLES, USER_ROLES } from "../../common/constants";

import { useComposeDispatcher } from "../../common/hooks";
import { PAGINATION_DETAILS, PAGE_SIZES } from "../../common/constants";
import styles from "assets/jss/material-dashboard-pro-react/views/userStyle.js";

const useStyles = makeStyles(styles);

const filterstyles = () => ({
  labelFocused: {
    color: "#E73F3B !important",
  },
  inputFocused: {
    "&:before": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "&:after": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "& input": {
      borderBottom: "#E73F3B !important",
    },
  },
  inputRoot: {
    color: "red",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
});

const columns = [
  { id: "firstName", label: "First Name", minWidth: 150, sort: true },
  { id: "lastName", label: "Last Name", minWidth: 150, sort: true },
  { id: "emailAddress", label: "Email", minWidth: 170, sort: true },
  { id: "role", label: "Role", minWidth: 100, sort: true },
  { id: "status", label: "Status", minWidth: 100, sort: true },
  {
    id: "createdOn",
    label: "Date Created",
    minWidth: 130,
    sort: true,
    type: "date",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 170,
    align: "center",
    sort: false,
    format: (value) => value.toFixed(2),
  },
];

const inputStyles = () => ({
  labelFocused: {
    color: "#E73F3B !important",
  },
  inputFocused: {
    "&:before": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "&:after": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "& input": {
      borderBottom: "#E73F3B !important",
    },
  },
  inputRoot: {
    color: "red",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
});

const InputBox = withStyles(inputStyles)((props) => {
  const {
    labelText,
    helperText,
    classes,
    value,
    id,
    info,
    updateInfo,
    type,
    required,
  } = props;
  return (
    <TextField
      label={labelText}
      helperText={helperText}
      fullWidth
      required={required ? required : false}
      type={type ? type : "text"}
      margin="normal"
      color="primary"
      value={value}
      onChange={(e) => {
        let obj = {};
        obj[id] = e.target.value;
        updateInfo({ ...info, ...obj });
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          focused: classes.labelFocused,
        },
      }}
      InputProps={{
        classes: {
          root: classes.root,
          focused: classes.inputFocused,
        },
      }}
    />
  );
});

const SelectBox = withStyles(filterstyles)((props) => {
  const { labelText, classes, value, onChange, options, required } = props;
  return (
    <TextField
      select
      fullWidth
      label={labelText}
      value={value}
      required={required ? required : false}
      margin="normal"
      color="primary"
      onChange={onChange}
      InputLabelProps={{
        shrink: true,
        classes: {
          focused: classes.labelFocused,
        },
      }}
      InputProps={{
        classes: {
          root: classes.root,
          focused: classes.inputFocused,
        },
      }}
    >
      {options}
    </TextField>
  );
});

const FilterInputBox = withStyles(inputStyles)((props) => {
  const {
    labelText,
    helperText,
    classes,
    value,
    id,
    type,
    onChange,
    required,
  } = props;
  return (
    <TextField
      label={labelText}
      helperText={helperText}
      fullWidth
      required={required ? required : false}
      type={type ? type : "text"}
      margin="normal"
      color="primary"
      value={value}
      onChange={onChange}
      InputLabelProps={{
        shrink: true,
        classes: {
          focused: classes.labelFocused,
        },
      }}
      InputProps={{
        classes: {
          root: classes.root,
          focused: classes.inputFocused,
        },
      }}
    />
  );
});

export default function Users() {
  const history = useHistory();
  const { users: userData, company } = useSelector((state) => ({
    users: state.users,
    company: state.company,
  }));

  const userDetails = JSON.parse(localStorage.getItem("user-details"));
  const loggedUserRole = userDetails !== null ? userDetails.roles[0].role : "";
  let rolesDropdown = FILTER_USER_ROLES;
  if (loggedUserRole === "SupplierUser") {
    rolesDropdown = [
      { key: "PracticeUser", value: "Caregiver & Guest" },
      { key: "SupplierUser", value: "Manager" },
      { key: "Patient", value: "Patient" },
    ];
  }
  const [alert, setAlert] = React.useState(null);
  const [userRole, setUserRole] = React.useState(rolesDropdown[0].key);
  const [userCompany, setUserCompany] = React.useState("");
  const [ownerFilterName, setownerFilterName] = React.useState("");
  const [ownerFilterEmail, setownerFilterEmail] = React.useState("");

  const [order, setOrder] = React.useState("DESC");
  const [orderBy, setOrderBy] = React.useState("createdOn");
  const [openChangePassword, setOpenChangePassword] = React.useState(false);
  const [selectedUserPassword, setSelectedUserPassword] = React.useState({});
  const [userInfo, setUserInfo] = React.useState({});
  const [alertMessage, setAlertMessage] = React.useState("");
  const [toastStatus, setToastStatus] = React.useState(false);

  const [getUserList] = useComposeDispatcher(FETCH_USER_LIST_REQUEST);
  const [deleteUser] = useComposeDispatcher(DELETE_USER_REQUEST);
  const [deleteUserAndPractice] = useComposeDispatcher(
    DELETE_USER_PRACTICE_REQUEST
  );
  const [getCompanyList] = useComposeDispatcher(FETCH_COMPANY_REQUEST);
  const [updateUserPassword] = useComposeDispatcher(CHANGE_PASSWORD_REQUEST);

  const classes = useStyles();
  const [page, setPage] = React.useState(PAGINATION_DETAILS.pageNumber);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    PAGINATION_DETAILS.pageSize
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getUserList({
      role: userRole === 'all' ? "" : userRole,
      pageNumber: newPage,
      pageSize: rowsPerPage,
      sortBy: orderBy,
      sortOrder: order,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    getUserList({
      role: userRole === 'all' ? "" : userRole,
      pageNumber: 0,
      pageSize: +event.target.value,
      sortBy: orderBy,
      sortOrder: order,
    });
  };

  useEffect(() => {
    getCompanyList();
    getUserList({
      role: userRole === 'all' ? "" : userRole,
      pageNumber: page,
      pageSize: rowsPerPage,
      sortBy: orderBy,
      sortOrder: order,
    });
  }, []);

  const warningWithConfirmMessage = (user) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => {
          if (user.roles.find((val) => val.role === "PracticeUser")) {
            deleteUserAndPractice({
              userId: user.userId,
              role: userRole,
              fullName: user.fullName,
              pageNumber: page,
              pageSize: rowsPerPage,
              sortBy: orderBy,
              sortOrder: order,
            });
            hideAlert();
          } else {
            deleteUser({
              userId: user.userId,
              role: userRole,
              pageNumber: page,
              pageSize: rowsPerPage,
              sortBy: orderBy,
              sortOrder: order,
            });
            hideAlert();
          }
        }}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        You will not be able to recover <b>{user.fullName}</b> user data!
      </SweetAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const createSortHandler = (property) => () => {
    const isAsc = orderBy === property && order === "ASC";
    setOrder(isAsc ? "DESC" : "ASC");
    setOrderBy(property);
    getUserList({
      role: userRole === 'all' ? "" : userRole,
      pageNumber: 0,
      pageSize: rowsPerPage,
      sortBy: property,
      sortOrder: isAsc ? "DESC" : "ASC",
    });
    setPage(0);
  };

  const onEditButtonClick = (row) => {
    if(row.roles.find(v => v.role === 'TenantAdmin')) {
      history.push(`/admin/edit-manufacturer/${row.userId}`);
    } else if(row.roles.find(v => v.role === 'Patient')) {
      history.push(`/admin/edit-patient/${row.userId}`);
    } else {
      history.push(`/admin/edit-user/${row.userId}`);
    }
  };

  const changePasswordModal = () => {
    return (
      <Dialog
        open={openChangePassword}
        onClose={() => {
          setOpenChangePassword(false);
          setSelectedUserPassword({});
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter new password for <b>{selectedUserPassword.fullName}</b> user
          </DialogContentText>
          <InputBox
            labelText="New Password"
            id="newPassword"
            type="password"
            value={userInfo?.newPassword}
            required={true}
            info={userInfo}
            helperText="Please enter password which contain min 8 letter, at least a symbol, upper and lower case letters and a number"
            updateInfo={setUserInfo}
          />
          <InputBox
            labelText="Confirm Password"
            id="confirmPassword"
            type="password"
            value={userInfo?.confirmPassword}
            required={true}
            info={userInfo}
            updateInfo={setUserInfo}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenChangePassword(false);
              setSelectedUserPassword({});
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            disabled={!userInfo?.newPassword || !userInfo?.confirmPassword}
            onClick={() => {
              const passwordReq = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
              if (!passwordReq.test(userInfo?.newPassword)) {
                setAlertMessage("Please enter valid password");
                setToastStatus(true);
              } else if (userInfo?.newPassword !== userInfo?.confirmPassword) {
                setAlertMessage("Entered Password is not matching");
                setToastStatus(true);
              } else {
                updateUserPassword({
                  userId: selectedUserPassword.userId,
                  newPassword: userInfo?.newPassword,
                  newConfirmPassword: userInfo?.confirmPassword,
                });
                setOpenChangePassword(false);
                setSelectedUserPassword({});
              }
            }}
            color="primary"
          >
            Change Password
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box
          bgcolor="#ffffff"
          display="flex"
          padding={1}
          borderRadius="5px"
          marginBottom={2}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <b>Filter By</b>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <FilterInputBox
                labelText="Name"
                value={userInfo.name}
                id="name"
                onChange={val => {
                  setownerFilterName(val.target.value)
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <FilterInputBox
                labelText="Email"
                value={userInfo.email}
                id="email"
                onChange={val => {
                  setownerFilterEmail(val.target.value)
                }}
              />
            </GridItem>
            {loggedUserRole === "TenantAdmin" && (
              <GridItem xs={12} sm={12} md={3}>
                <SelectBox
                  id="company"
                  labelText="Company"
                  value={userCompany}
                  required={true}
                  onChange={(val) => {
                    setUserCompany(val.target.value);
                  }}
                  options={company.companyList.map((val) => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      key={val.supplierId}
                      value={val.supplierId}
                    >
                      {val.name}
                    </MenuItem>
                  ))}
                />
              </GridItem>
            )}
            <GridItem xs={12} sm={12} md={3}>
              <SelectBox
                id="role"
                labelText="Select Role"
                required={true}
                value={userRole}
                onChange={(val) => {
                  setUserRole(val.target.value);
                }}
                options={rolesDropdown.map((val) => (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                    key={val.key}
                    value={val.key}
                  >
                    {val.value}
                  </MenuItem>
                ))}
              />
            </GridItem>
            <GridItem xs={3}>
              <Box pt={2}>
                <Button
                  onClick={() => {
                    let obj = {
                      pageNumber: 0,
                      pageSize: rowsPerPage,
                      sortBy: orderBy,
                      sortOrder: order,
                    };
                    if (userRole) {
                      obj.role = userRole === 'all' ? '' : userRole;
                    }
                    if (userCompany) {
                      obj.supplierId = userCompany;
                    }
                    if (ownerFilterName) {
                      obj.nameLike = ownerFilterName;
                    }
                    if (ownerFilterEmail) {
                      obj.emailAddress = ownerFilterEmail;
                    }
                    setPage(0);
                    getUserList(obj);
                  }}
                  color="danger"
                >
                  Apply
                  </Button>
              </Box>
            </GridItem>
          </GridContainer>
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="danger" icon>
            <CardIcon color="danger">
              <Person />
            </CardIcon>
            {
              (
                loggedUserRole === "TenantAdmin" ||
                loggedUserRole === "SupplierAdmin"
              ) && (
                <Box className={classes.cardIconTitle} style={{ float: "right" }}>
                  <Button
                    color="primary"
                    onClick={() => {
                      history.push(`/admin/add-user`);
                    }}
                  >
                    Create User
                  </Button>
                </Box>
              )
            }
          </CardHeader>
          <CardBody>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ maxWidth: column.minWidth }}
                      >
                        {column.sort ? (
                          <TableSortLabel
                            style={{
                              maxWidth: column.minWidth,
                              minWidth: column.minWidth,
                            }}
                            active={orderBy === column.id}
                            direction={
                              orderBy === column.id
                                ? order.toLowerCase()
                                : "asc"
                            }
                            onClick={createSortHandler(column.id)}
                          >
                            <Typography>{column.label}</Typography>
                          </TableSortLabel>
                        ) : (
                            <Typography>{column.label}</Typography>
                          )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userData.userList.totalElements === 0 && (
                    <TableRow>
                      <TableCell align="center" colSpan="6">
                        No records found
                      </TableCell>
                    </TableRow>
                  )}
                  {userData.userList.users.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.userId}
                      >
                        {columns.map((column) => {
                          let value = row[column.id];
                          if (column.type === "date") {
                            value = moment(row[column.id]).format(
                              "DD MMM, YYYY hh:mm"
                            );
                          }
                          return (
                            <TableCell
                              style={{ maxWidth: column.minWidth }}
                              key={column.id}
                              align={column.align}
                            >
                              {column.id === "role" &&
                                row?.roles.map((val, index) => {
                                  const newRoleName = USER_ROLES.find(
                                    (userVal) => userVal.key === val.role
                                  );
                                  return (
                                    <p key={index}>
                                      {newRoleName
                                        ? newRoleName.value
                                        : val.role}
                                    </p>
                                  );
                                })}
                              {column.id !== "action" &&
                                column.id !== "role" &&
                                column.format &&
                                typeof value === "number"
                                ? column.format(value)
                                : value}
                              {column.id === "action" && (
                                <>
                                  <Button
                                    color="success"
                                    simple
                                    onClick={() => {
                                      onEditButtonClick(row);
                                    }}
                                    className={classes.actionButton}
                                  >
                                    <Edit />
                                  </Button>
                                  {(loggedUserRole === "TenantAdmin" ||
                                    loggedUserRole === "SupplierAdmin" ||
                                    userRole === "PracticeUser") && (
                                      <>
                                        {userDetails.userId !== row.userId && (
                                          <Button
                                            color="danger"
                                            simple
                                            onClick={() => {
                                              warningWithConfirmMessage(row);
                                            }}
                                            className={classes.actionButton}
                                          >
                                            <Close />
                                          </Button>
                                        )}
                                        <Button
                                          className={classes.actionButton}
                                          color="info"
                                          simple
                                          onClick={() => {
                                            setOpenChangePassword(true);
                                            setSelectedUserPassword(row);
                                          }}
                                        >
                                          <VpnKeyIcon />
                                        </Button>
                                      </>
                                    )}
                                </>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={PAGE_SIZES}
              component="div"
              count={userData.userList.totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            {alert}
            {changePasswordModal()}
            <Snackbar
              place="tr"
              color="danger"
              icon={AddAlert}
              message={alertMessage}
              open={toastStatus}
              closeNotification={() => setToastStatus(false)}
              close
            />
            <Loader status={userData.userList.loading} />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
