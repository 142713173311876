import React from "react";
import moment from "moment";
import Datetime from "react-datetime";
import { Box, InputLabel, FormControl } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import timezones from "../../common/timezones.json";

const useStyles = makeStyles({
  searchBox: {
    borderRadius: "5px",
    marginTop: "10px",
    "& .MuiInputBase-fullWidth": {
      padding: "3px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "red !important",
    },
  },
  drawerRoot: {
    "& .MuiPaper-root": {
      width: "100%",
    },
  },
  labelFocused: {
    color: "#E73F3B !important",
  },
  inputFocused: {
    "&:before": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "&:after": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "& input": {
      borderBottom: "#E73F3B !important",
    },
  },
  inputRoot: {
    color: "red",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
  popover: {
    zIndex: 2,
  },
  fab: {
    margin: "0px",
    top: "auto",
    right: "20px",
    bottom: "20px",
    left: "auto",
    position: "fixed",
    zIndex: 3,
  },
  buttonSection: {
    marginTop: "10px",
  },
  resetButtonSection: {
    marginTop: "10px",
    marginLeft: "10px"
  },
  datePicker: {
    "& input": {
      fontSize: '15px !important',
      color: '#212121 !important'
    }
  }
});

const today = moment();

const disableFutureDt = (current) => current.isBefore(today);

const GRAPH_LIST = ["Hr", "Rr", "Stress", "Motion", "RssHrMv", "RssRrMv", "HrTd", "HrFd", "HrSel", "HrEst", "WinTd", "WinFd", "WinEst", "WinSel", "Offline", "Sdnn", "Rmssd", "Occupied"];

const timeformat = ['sec', 'mins'];

const timeIntervals = [
  "1 min",
  "2 mins",
  "5 mins",
  "10 mins",
  "30 mins",
  "1 Hr",
  "2 Hr",
  "4 Hr",
  "6 Hr",
  "8 Hr",
  "10 Hr",
  "12 Hr",
  "18 Hr",
  "24 Hr",
  "Daily",
  "Weekly",
  "Monthly",
];

export default function Filter({
  setUserInfo,
  userInfo,
  device,
  getDeviceData,
  defaultKey,
  setDefaultKey,
  userList,
  fetchFriendlyNameData,
  userAbbr,
  userTime,
  setUserAbbr,
  setUserTime,
  userUtcTime,
  setUserUtcTime,
  resetGraphDeviceData,
  saveGraphData,
  loggedUserId,
  loggedUserName
}) {

  const classes = useStyles();
  let userData = [...userList.users];
  return (
    <GridItem xs={12}>
      <Box
        bgcolor="#ffffff"
        display="flex"
        padding={2}
        borderRadius="5px"
        marginBottom={2}
      >
        <GridContainer key={defaultKey}>
          <GridItem xs={12} sm={12} md={3}>
            <TextField
              className={classes.searchBox}
              style={{
                width: "100%",
              }}
              select
              color="secondary"
              label="User 1 *"
              onChange={(val) => {
                let obj = {
                  userId1: val.target.value,
                };
                fetchFriendlyNameData({
                  userId: val.target.value,
                  deviceDataModelId: userInfo.deviceId,
                  user: 'user1'
                });
                setUserInfo({ ...userInfo, ...obj });
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  focused: classes.labelFocused,
                },
              }}
              value={userInfo?.userId1}
              InputProps={{
                classes: {
                  root: classes.root,
                  focused: classes.inputFocused,
                },
              }}
            >
              {[...userData.sort((a, b) =>
                a.firstName > b.firstName ? 1 : -1
              )].map((option) => (
                <MenuItem key={option.userId} value={option.userId}>
                  {option.fullName}
                </MenuItem>
              ))}
            </TextField>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <TextField
              className={classes.searchBox}
              style={{
                width: "100%",
              }}
              select
              color="secondary"
              label="Friendly Name 1 *"
              onChange={(val) => {
                let obj = {
                  macAddress1: val.target.value,
                };
                const seletedMacAddress = device.friendlyNameUser1Data.find(value => value.macId === val.target.value);
                let userTimeZone = timezones.find(
                  (val) => val.value === seletedMacAddress.timeZone
                );
                setUserTime(userTimeZone.value);
                setUserAbbr(userTimeZone.abbr);
                 
                if (userTimeZone.utc.length > 0) {
                  setUserUtcTime(userTimeZone.utc[0]);
                }
                setUserInfo({ ...userInfo, ...obj });
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  focused: classes.labelFocused,
                },
              }}
              value={userInfo?.macAddress1}
              InputProps={{
                classes: {
                  root: classes.root,
                  focused: classes.inputFocused,
                },
              }}
            >
              {device.friendlyNameUser1Data.map(
                (option) =>
                  option.friendlyName && (
                    <MenuItem key={option.macId} value={option.macId}>
                      {option.friendlyName}
                    </MenuItem>
                  )
              )}
            </TextField>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <TextField
              className={classes.searchBox}
              style={{
                width: "100%",
              }}
              select
              color="secondary"
              label="User 2"
              onChange={(val) => {
                let obj = {
                  userId2: val.target.value,
                };
                fetchFriendlyNameData({
                  userId: val.target.value,
                  deviceDataModelId: userInfo.deviceId,
                  user: 'user2'
                });
                setUserInfo({ ...userInfo, ...obj });
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  focused: classes.labelFocused,
                },
              }}
              value={userInfo?.userId2}
              InputProps={{
                classes: {
                  root: classes.root,
                  focused: classes.inputFocused,
                },
              }}
            >
              {[...userData.sort((a, b) =>
                a.firstName > b.firstName ? 1 : -1
              )].map((option) => (
                <MenuItem key={option.userId} value={option.userId}>
                  {option.fullName}
                </MenuItem>
              ))}
            </TextField>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <TextField
              className={classes.searchBox}
              style={{
                width: "100%",
              }}
              select
              color="secondary"
              label="Friendly Name 2"
              onChange={(val) => {
                let obj = {
                  macAddress2: val.target.value,
                };
                setUserInfo({ ...userInfo, ...obj });
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  focused: classes.labelFocused,
                },
              }}
              value={userInfo?.macAddress2}
              InputProps={{
                classes: {
                  root: classes.root,
                  focused: classes.inputFocused,
                },
              }}
            >
              {device.friendlyNameUser2Data.map(
                (option) =>
                  option.friendlyName && (
                    <MenuItem key={option.macId} value={option.macId}>
                      {option.friendlyName}
                    </MenuItem>
                  )
              )}
            </TextField>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <TextField
              className={classes.searchBox}
              style={{
                width: "100%",
              }}
              select
              color="secondary"
              label="User 3"
              onChange={(val) => {
                let obj = {
                  userId3: val.target.value,
                };
                fetchFriendlyNameData({
                  userId: val.target.value,
                  deviceDataModelId: userInfo.deviceId,
                  user: 'user3'
                });
                setUserInfo({ ...userInfo, ...obj });
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  focused: classes.labelFocused,
                },
              }}
              value={userInfo?.userId3}
              InputProps={{
                classes: {
                  root: classes.root,
                  focused: classes.inputFocused,
                },
              }}
            >
              {[...userData.sort((a, b) =>
                a.firstName > b.firstName ? 1 : -1
              )].map((option) => (
                <MenuItem key={option.userId} value={option.userId}>
                  {option.fullName}
                </MenuItem>
              ))}
            </TextField>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <TextField
              className={classes.searchBox}
              style={{
                width: "100%",
              }}
              select
              color="secondary"
              label="Friendly Name 3"
              onChange={(val) => {
                let obj = {
                  macAddress3: val.target.value,
                };
                setUserInfo({ ...userInfo, ...obj });
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  focused: classes.labelFocused,
                },
              }}
              value={userInfo?.macAddress3}
              InputProps={{
                classes: {
                  root: classes.root,
                  focused: classes.inputFocused,
                },
              }}
            >
              {device.friendlyNameUser3Data.map(
                (option) =>
                  option.friendlyName && (
                    <MenuItem key={option.macId} value={option.macId}>
                      {option.friendlyName}
                    </MenuItem>
                  )
              )}
            </TextField>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <TextField
              className={classes.searchBox}
              style={{
                width: "100%",
              }}
              select
              color="secondary"
              value={userInfo?.graphProperty1}
              label="First Graph Property *"
              onChange={(val) => {
                let obj = {
                  graphProperty1: val.target.value,
                };
                setUserInfo({ ...userInfo, ...obj });
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  focused: classes.labelFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.root,
                  focused: classes.inputFocused,
                },
              }}
            >
              {GRAPH_LIST.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <TextField
              className={classes.searchBox}
              style={{
                width: "100%",
              }}
              select
              color="secondary"
              label="Second Graph Property *"
              value={userInfo?.graphProperty2}
              onChange={(val) => {
                let obj = {
                  graphProperty2: val.target.value,
                };
                setUserInfo({ ...userInfo, ...obj });
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  focused: classes.labelFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.root,
                  focused: classes.inputFocused,
                },
              }}
            >
              {GRAPH_LIST.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <TextField
              className={classes.searchBox}
              style={{
                width: "100%",
              }}
              select
              label="Time Format *"
              color="secondary"
              value={userInfo.timeformat}
              onChange={(val) => {
                setUserInfo({ ...userInfo, timeformat: val.target.value });
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  focused: classes.labelFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.root,
                  focused: classes.inputFocused,
                },
              }}
            >
              {timeformat.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <TextField
              className={classes.searchBox}
              style={{
                width: "100%",
              }}
              select
              label="Interval *"
              color="secondary"
              value={userInfo.interval}
              onChange={(val) => {
                let newTime = userInfo.intervalStartTime;
                const intervalArray = val.target.value.split(" ");
                if (intervalArray[0] === "Weekly") {
                  newTime = moment.tz(userUtcTime).subtract('7', 'day').format("MM/DD/YYYY h:mm a")
                } else if (intervalArray[0] === "Monthly") {
                  newTime = moment.tz(userUtcTime).subtract('30', 'day').format("MM/DD/YYYY h:mm a")
                } else if (intervalArray[0] === "Daily") {
                  newTime = moment.tz(userUtcTime).subtract('24', 'h').format("MM/DD/YYYY h:mm a")
                } else if (intervalArray[1] === "Hr") {
                  newTime = moment.tz(userUtcTime).subtract(intervalArray[0], 'h').format("MM/DD/YYYY h:mm a")
                } else if (intervalArray[1] === "secs") {
                  newTime = moment.tz(userUtcTime).subtract(intervalArray[0], 'seconds').format("MM/DD/YYYY h:mm a")
                } else {
                  newTime = moment.tz(userUtcTime).subtract(intervalArray[0], 'minutes').format("MM/DD/YYYY h:mm a")
                }
                setUserInfo({ ...userInfo, interval: val.target.value, intervalStartTime: newTime });
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  focused: classes.labelFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.root,
                  focused: classes.inputFocused,
                },
              }}
            >
              {timeIntervals.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <InputLabel
              style={{ fontSize: "12px" }}
              className={classes.searchBox}
            >
              Start Time ({userAbbr}) *
            </InputLabel>
            <FormControl fullWidth>
              <Datetime
                // dateFormat="MM/DD/YYYY h:mm a"
                isValidDate={disableFutureDt}
                className={classes.datePicker}
                value={userInfo.intervalStartTime}
                onChange={(val) => {
                  let obj = {
                    intervalStartTime: val,
                  };
                  setUserInfo({ ...userInfo, ...obj });
                }}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={5}>
            <Button
              className={classes.buttonSection}
              onClick={() => {
                getDeviceData();
              }}
              color="danger"
            >
              Fetch Data
            </Button>
            <Button
              className={classes.resetButtonSection}
              onClick={() => {
                setDefaultKey(Date.now());
                resetGraphDeviceData();
              }}
              color="danger"
            >
              Reset Filter
            </Button>
            <Button
              className={classes.resetButtonSection}
              onClick={() => {
                let createdByUserName = loggedUserName;
                let userName1 = '';
                let userName2 = '';
                let userName3 = '';
                let friendlyName1 = '';
                let friendlyName2 = '';
                let friendlyName3 = '';
                if (userInfo.userId1) {
                  const user1 = userData.find(val => val.userId === userInfo.userId1);
                  userName1 = user1?.fullName || '';
                }
                if (userInfo.userId2) {
                  const user2 = userData.find(val => val.userId === userInfo.userId2);
                  userName2 = user2?.fullName || '';
                }
                if (userInfo.userId3) {
                  const user3 = userData.find(val => val.userId === userInfo.userId3);
                  userName3 = user3?.fullName || '';
                }
                 
                if (userInfo.macAddress1 && device.friendlyNameUser1Data.length > 0) {
                  const device1 = device.friendlyNameUser1Data.find(val => val.macId === userInfo.macAddress1);
                  friendlyName1 = device1?.friendlyName || '';
                }
                if (userInfo.macAddress2 && device.friendlyNameUser2Data.length > 0) {
                  const device2 = device.friendlyNameUser2Data.find(val => val.macId === userInfo.macAddress2);
                  friendlyName2 = device2?.friendlyName || '';
                }
                if (userInfo.macAddress3 && device.friendlyNameUser3Data.length > 0) {
                  const device3 = device.friendlyNameUser3Data.find(val => val.macId === userInfo.macAddress3);
                  friendlyName3 = device3?.friendlyName || '';
                }
                saveGraphData({ ...userInfo, timezone: userTime, loggedUserId: loggedUserId, createdByUserName, userName1, userName2, userName3, friendlyName1, friendlyName2, friendlyName3 });
              }}
              color="danger"
            >
              Save Filter
            </Button>
          </GridItem>
        </GridContainer>
      </Box>
    </GridItem>
  );
}
