import { ofType } from "redux-observable";
import { mergeMap, map, catchError } from "rxjs/operators";

import { dispatch } from "../../common/helpers";
import { auditLogService } from "../../services";

import {
  FETCH_AUDIT_LOGS_REQUEST,
  FETCH_AUDIT_LOGS_SUCCESS,
  FETCH_AUDIT_LOGS_ERROR,
} from "./actions";

export const getAuditLogsList = (action$) => {
  return action$.pipe(
    ofType(FETCH_AUDIT_LOGS_REQUEST),
    mergeMap(({ payload }) => {
      return auditLogService.getAuditLogsList(payload).pipe(
        map(({ response }) => {
          return dispatch(FETCH_AUDIT_LOGS_SUCCESS, response);
        }),
        catchError((error) => {
          return dispatch(FETCH_AUDIT_LOGS_ERROR, error);
        })
      );
    })
  );
};
