import * as service from "../common/service";
import { API_BASE_URL } from "../common/constants";

const deviceDataApi = `${API_BASE_URL}/data/devicedata-advanced`;
const usersApi = `${deviceDataApi}/owner`;
const devicePropSetIdApi = `${API_BASE_URL}/user/deviceproperty`
const updateGuestMappingApi = `${API_BASE_URL}/data/devicedata`;
const guestInvite = `${API_BASE_URL}/observer/invite`;

const guestMapService = {
    getPatientSensorList: (payload) => {
        let url = usersApi + "?sortBy=maxValueProvidedOn&sortOrder=DESC";
        return service.postVersionJson(url, payload, 'text', 3);
    },
    getGuestsList: (payload) => {
        return service.postVersionJson(deviceDataApi, payload, 'text', 3);
    },
    getDevicePropSetId: (payload) => {
        return service.get(devicePropSetIdApi + '?deviceDataModelId=' + payload);
    },
    updateGuest: (payload) => {
        return service.postVersionJson(updateGuestMappingApi, payload, 'text', 3);
    },
    onInviteGuest: (payload) => {
        return service.postVersionJson(guestInvite, payload, 'text', 3);
    }
};

export default guestMapService;
