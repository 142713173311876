import { ofType } from "redux-observable";
import { mergeMap, map } from "rxjs/operators";

import {
  SUCCESS_NOTIFICATION_REQUEST,
  SHOW_SUCCESS_MESSAGE,
  ERROR_NOTIFICATION_REQUEST,
  SHOW_ERROR_MESSAGE,
  RESET_NOTIFICATION_DATA,
  RESET_NOTIFICATION_SUCCESS,
} from "./actions";
import { dispatch } from "../../common/helpers";

export const successNotificationRequest = (action$) =>
  action$.pipe(
    ofType(SUCCESS_NOTIFICATION_REQUEST),
    map(({ payload }) => dispatch(SHOW_SUCCESS_MESSAGE, payload))
  );

export const errorNotificationRequest = (action$) =>
  action$.pipe(
    ofType(ERROR_NOTIFICATION_REQUEST),
    map(({ payload }) => dispatch(SHOW_ERROR_MESSAGE, payload))
  );

export const resetNotificationData = (action$) =>
  action$.pipe(
    ofType(RESET_NOTIFICATION_DATA),
    mergeMap(() => [dispatch(RESET_NOTIFICATION_SUCCESS)])
  );
