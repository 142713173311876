import React, { useEffect, useState, createRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment-timezone";

import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import DialpadIcon from '@material-ui/icons/Dialpad';
import AddAlert from "@material-ui/icons/AddAlert";
import PersonIcon from '@material-ui/icons/Person';
import Checkbox from "@material-ui/core/Checkbox";
import Email from "@material-ui/icons/Email";
import Input from "@material-ui/core/Input";
import Icon from "@material-ui/core/Icon";

import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";

import {
    ACCEPT_TERMS_USER_REQUEST,
    GET_INVITE_EMAIL_REQUEST,
    REGISTER_USER_REQUEST,
    ACTIVATE_USER_REQUEST,
    ACCEPT_TERMS_REQUEST,
    RESEND_OTP_REQUEST,
} from "../../redux/users/actions";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { useComposeDispatcher } from "../../common/hooks";
import loginLogo from "assets/img/login-logo.png";

const TEST_SITE_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";
const useStyles = makeStyles(styles);

export default function Signup() {
    const userData = useSelector(({ users }) => users);
    const _reCaptchaRef = createRef();
    const history = useHistory();
    const classes = useStyles();

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const inviteId = params.get('inviteId');

    const [acceptTermsUser] = useComposeDispatcher(ACCEPT_TERMS_USER_REQUEST);
    const [getInviteEmail] = useComposeDispatcher(GET_INVITE_EMAIL_REQUEST);
    const [registerUser] = useComposeDispatcher(REGISTER_USER_REQUEST);
    const [activateUser] = useComposeDispatcher(ACTIVATE_USER_REQUEST);
    const [acceptTerms] = useComposeDispatcher(ACCEPT_TERMS_REQUEST);
    const [resendCode] = useComposeDispatcher(RESEND_OTP_REQUEST);

    const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
    const [privacyPolicy, setPrivacyPolicy] = useState(false);
    const [toastStatus, setToastStatus] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [otpEnabled, setOtpEnabled] = useState(false);
    const [captchaHash, setCaptchaHash] = useState('');
    const [loginInfo, setLoginInfo] = useState({});
    const [tnc, setTnC] = useState(false);

    useEffect(() => {
        getInviteEmail(inviteId);
        setCardAnimation("");
    }, []);

    useEffect(() => {
        // console.log(userData?.inviteData, '------');
        if (userData?.inviteData?.emailAddress) {
            setTimeout(() => {
                setLoginInfo({...loginInfo, emailAddress: userData?.inviteData?.emailAddress});
            }, 3000);
        }
    }, [userData?.inviteData]);

    useEffect(() => {
        if (userData?.userRegisteredObj?.userRegistered) {
            setOtpEnabled(true);
        } else if(userData?.userRegisteredObj?.msg) {
            setToastStatus(true);
            setAlertMessage(userData?.userRegisteredObj?.msg);
        }
    }, [userData?.userRegisteredObj]);

    useEffect(() => {
        if (userData?.acceptTermsUser && userData.acceptTerms) {
            console.log('Signup Registration done, navigation started');
            history.push("/admin/dashboard");
        }
    }, [userData?.acceptTermsUser, userData?.acceptTerms]);

    useEffect(() => {
        if (userData?.userActivate) {
            acceptTermsUser();
            acceptTerms();
        }
    }, [userData?.userActivate]);

    useEffect(() => {
        if (userData?.codeResent) {
            setAlertMessage('Confirmation code successfully sent');
        }
    }, [userData?.codeResent]);

    const handleChange = (value) => {
        setCaptchaHash(value);
        /* // if value is null recaptcha expired
        if (value === null) this.setState({ expired: "true" }); */
    };

    const onAccept = (event) => {
        const val = event.target.value,
            isChecked = event.target.checked;

        val === "tnc" ?  setTnC(isChecked) : setPrivacyPolicy(isChecked);
    }

    const formValidation = () => {
        const passwordReq = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        const emailRegx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        let isFormValid = false;
        
        if(!loginInfo?.firstName && !loginInfo?.lastName) {
            setToastStatus(true);
            setAlertMessage('Please enter First and Last Name');
        } else if(!loginInfo?.emailAddress || !emailRegx.test(loginInfo?.emailAddress)) {
            setToastStatus(true);
            setAlertMessage('Please enter valid Email ID');
        } else if(!loginInfo?.password || !passwordReq.test(loginInfo?.password)) {
            setToastStatus(true);
            setAlertMessage('Please enter valid Password');
        } else if(loginInfo?.password !== loginInfo?.confirmPassword) {
            setToastStatus(true);
            setAlertMessage('Password and Confirm Password are not same');
        } else if(!captchaHash) {
            setToastStatus(true);
            setAlertMessage('Confirm Captcha First');
        } else if(!tnc) {
            setToastStatus(true);
            setAlertMessage('Please accept Terms & Conditions First');
        } else if(!privacyPolicy) {
            setToastStatus(true);
            setAlertMessage('Please accept Privacy Policy First');
        } else {
            isFormValid = true;
        }
        return isFormValid;
    }

    const onSignup = () => {
        const isFormValid = formValidation();

        if(isFormValid) {
            const signupPayload = {
                captchaToken: captchaHash,
                userInviteId: inviteId,
                confirmPassword: loginInfo.confirmPassword,
                password: loginInfo.password,
                timezone: moment?.tz?.guess(),
                user: {
                    emailAddress: userData?.inviteData?.emailAddress,
                    firstName: loginInfo.firstName,
                    lastName: loginInfo.lastName,
                    contactInfo: {},
                    canViewSensitiveData: userData?.inviteData?.referrerUser?.canViewSensitiveData,
                    lastLoggedInVia: "Galen",
                    localPasswordSet: userData?.inviteData?.referrerUser?.localPasswordSet,
                    loggableAccount: userData?.inviteData?.referrerUser?.loggableAccount,
                    roles: [],
                    tenant: {}
                }
            }
            console.log(signupPayload, 'loginInfo Validated');
            registerUser(signupPayload);
        }
    }

    const onOTPSubmit = () => {
        console.log(loginInfo.otpCode, 'OTP Code');
        const otpPayload = {
            "otpCode": loginInfo.otpCode,
            "emailAddress": loginInfo.emailAddress,
            "userInviteId": inviteId
        }
        activateUser(otpPayload)
    }

    const onResendCode = () => {
        const form_data = new FormData();
        form_data.append("email", loginInfo.emailAddress);
        resendCode(form_data);
    }

    return(
        <>
        <div className={classes.container}>
            {!otpEnabled && 
                <GridContainer justifyContent="center">
                    <GridItem xs={12} sm={6} md={7} className={classes.signupTextBox}>
                        <div>
                            <div>
                                <h1 className={classes.suHeading}>CARDIO</h1>
                                <h5 className={classes.poweredBy}>Powered by CardI/O</h5>
                            </div>
                            <div>
                                <h3 className={classes.message}> Sajol2 Ghoshal has invited you to Cardio's cloud platform. </h3>
                                <p className={classes.disclaimer}> Once you sign up, you will be able to view Sajol2's personal health data and gain insights. </p>
                            </div>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={5}>
                        <form>
                        <Card login className={classes[cardAnimaton]}>
                            <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color="danger">
                                <img alt="logo" src={loginLogo} style={{ width: "120px", borderRadius: "5px" }} />
                            </CardHeader>
                            <CardBody>
                                <CustomInput
                                    labelText="First Name"
                                    id="firstName"
                                    formControlProps={{fullWidth: true,}}
                                    data={loginInfo}
                                    required={true}
                                    onChange={setLoginInfo}
                                    inputProps={{
                                        endAdornment: (
                                        <InputAdornment position="end">
                                            <PersonIcon className={classes.inputAdornmentIcon} />
                                        </InputAdornment>
                                        ),
                                        autoComplete: "off",
                                    }}
                                />
                                <CustomInput
                                    labelText="Last Name"
                                    id="lastName"
                                    formControlProps={{fullWidth: true,}}
                                    data={loginInfo}
                                    onChange={setLoginInfo}
                                    inputProps={{
                                        endAdornment: (
                                        <InputAdornment position="end">
                                            <PersonIcon className={classes.inputAdornmentIcon} />
                                        </InputAdornment>
                                        ),
                                        autoComplete: "off",
                                    }}
                                />
                                <div className="singupEmailField">
                                    <InputLabel style={{ fontSize: "12px" }}> Email </InputLabel>
                                    <Input
                                        fullWidth
                                        id="standard-error-helper-text"
                                        variant="standard"
                                        autoComplete='chrome-off'
                                        value={userData?.inviteData?.emailAddress}
                                        disabled={true}
                                        endAdornment =
                                            {<InputAdornment position="end">
                                                <Email className={classes.inputAdornmentIcon} />
                                            </InputAdornment>}
                                    />
                                </div>
                                <CustomInput
                                    labelText="New Password"
                                    id="password"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    data={loginInfo}
                                    onChange={setLoginInfo}
                                    inputProps={{
                                        endAdornment: (
                                        <InputAdornment position="end">
                                            <Icon className={classes.inputAdornmentIcon}>
                                            lock_outline
                                        </Icon>
                                        </InputAdornment>
                                        ),
                                        type: "password",
                                        autoComplete: "off",
                                    }}
                                />
                                <CustomInput
                                    labelText="Confirm Password"
                                    id="confirmPassword"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    data={loginInfo}
                                    helperText="Note: Please enter password which contain min 8 letter, at least a symbol, upper and lower case letters and a number"
                                    onChange={setLoginInfo}
                                    inputProps={{
                                        endAdornment: (
                                        <InputAdornment position="end">
                                            <Icon className={classes.inputAdornmentIcon}>
                                            lock_outline
                                        </Icon>
                                        </InputAdornment>
                                        ),
                                        type: "password",
                                        autoComplete: "off",
                                    }}
                                />
                                <GridContainer justifyContent="center">
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Checkbox
                                            inputProps={{ "aria-label": "Privacy Policy" }}
                                            onChange={onAccept}
                                            value={"pp"}
                                        />
                                        <a href="https://cardio.io/pages/privacy-policy" className={classes.navLink} target="_blank">
                                            I accept Privacy Policy
                                        </a>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer justifyContent="center">
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Checkbox
                                            inputProps={{ "aria-label": "Terms & Conditions" }}
                                            onChange={onAccept}
                                            value={"tnc"}
                                        />
                                        <a href="https://cardio.io/pages/terms-and-conditions-of-service" className={classes.navLink} target="_blank">
                                            I accept Terms and Conditions of Service
                                        </a>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer justifyContent="center">
                                    <GridItem xs={12} sm={12} md={12}>
                                        <ReCAPTCHA
                                            style={{ display: "inline-block" }}
                                            theme="dark"
                                            ref={_reCaptchaRef}
                                            sitekey={TEST_SITE_KEY}
                                            onChange={handleChange}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <CardFooter className={classes.justifyContentCenter}>
                                    <GridContainer justifyContent="center">
                                        <GridItem xs={12} sm={12} md={12}>
                                            <Button color="danger" block onClick={onSignup}>
                                                Signup
                                            </Button>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12} className={classes.textCenter}>
                                            Already a user? <NavLink to={"/auth/login"} className={classes.navLink}>Sign In</NavLink>
                                        </GridItem>
                                    </GridContainer>
                                </CardFooter>
                            </CardBody>
                        </Card>
                        </form>
                    </GridItem>
                </GridContainer>
            }
            {otpEnabled && 
                <GridContainer justifyContent="center">
                <GridItem xs={12} sm={6} md={6} className={classes.signupTextBox}>
                    <form>
                        <Card login className={classes[cardAnimaton]}>
                            <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color="danger">
                                <img alt="logo" src={loginLogo} style={{ width: "120px", borderRadius: "5px" }} />
                            </CardHeader>
                            <CardBody>
                                <p>Please enter the confirmation code sent to your email address</p>
                                <p><strong>{loginInfo?.emailAddress}</strong></p>
                                <CustomInput
                                    labelText="Enter OTP"
                                    id="otpCode"
                                    formControlProps={{fullWidth: true}}
                                    data={loginInfo}
                                    onChange={setLoginInfo}
                                    inputProps={{
                                        endAdornment: (
                                        <InputAdornment position="end">
                                            <DialpadIcon className={classes.inputAdornmentIcon} />
                                        </InputAdornment>
                                        )
                                    }}
                                />
                            </CardBody>
                            <CardFooter className={classes.justifyContentCenter}>
                                <GridContainer justifyContent="center">
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Button color="danger" block onClick={onOTPSubmit}>Confirm</Button>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12} className={classes.textCenter}>
                                        Didn't receive the code? <span onClick={onResendCode} className={classes.navLink}>Resend Code</span>
                                    </GridItem>
                                </GridContainer>
                            </CardFooter>
                        </Card>
                    </form>
                </GridItem>
            </GridContainer>
            }
            <Snackbar
                closeNotification={() => setToastStatus(false)}
                message={alertMessage}
                open={toastStatus}
                icon={AddAlert}
                color="danger"
                place="tr"
                close
            />
        </div>
        </>
    )
}
// signup?inviteId=22ecc865-faff-4c37-96ba-be19ba3aac40