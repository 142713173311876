import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CancelIcon from '@material-ui/icons/Cancel';
import Checkbox from "@material-ui/core/Checkbox";
// @material-ui/core components
import Grid from '@material-ui/core/Grid';
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import Loader from "components/Loader/Loader.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";

import formStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import {
  GET_DEVICE_PROPERTY_REQUEST,
  FETCH_DEVICE_PROPERTY_REQUEST,
  FETCH_DEVICE_PROPERTY_SET_REQUEST,
  UPDATE_DEVICE_PROPERTY_REQUEST,
  CREATE_DEVICE_PROPERTY_REQUEST,
} from "../../redux/device/actions";
import { useComposeDispatcher } from "../../common/hooks";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const PROPERTY_TYPE = [
  { key: "DateAndTime", title: "Date and Time" },
  { key: "File", title: "File" },
  { key: "GeoCoordinates", title: "GeoCoordinates" },
  { key: "Json", title: "Json" },
  { key: "Number", title: "Number" },
  { key: "NumberArray", title: "NumberArray" },
  { key: "Temperature", title: "Temperature" },
  { key: "Text", title: "Text" },
  { key: "Xml", title: "Xml" },
  { key: "Boolean", title: "Boolean" },
  { key: "Enumeration", title: "Dropdown List" },
];

const styles = () => ({
  labelFocused: {
    color: "#E73F3B !important",
  },
  inputFocused: {
    "&:before": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "&:after": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "& input": {
      borderBottom: "#E73F3B !important",
    },
  },
  inputRoot: {
    color: "red",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
});

const InputBox = withStyles(styles)((props) => {
  const {
    disabled,
    labelText,
    helperText,
    classes,
    value,
    id,
    info,
    updateInfo,
    type,
    required,
  } = props;
  return (
    <TextField
      disabled={disabled || false}
      label={labelText}
      helperText={helperText}
      fullWidth
      required={required ? required : false}
      type={type ? type : "text"}
      margin="normal"
      color="primary"
      value={value}
      onChange={(e) => {
        let obj = {};
        obj[id] = e.target.value;
        updateInfo({ ...info, ...obj });
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          focused: classes.labelFocused,
        },
      }}
      InputProps={{
        classes: {
          root: classes.root,
          focused: classes.inputFocused,
        },
      }}
    />
  );
});

const DropDownInputBox = withStyles(styles)((props) => {
  const {
    disabled,
    labelText,
    classes,
    value,
    onChange
  } = props;
  return (
    <TextField
      disabled={disabled || false}
      label={labelText}
      fullWidth
      type="text"
      margin="normal"
      color="primary"
      value={value}
      onChange={onChange}
      InputLabelProps={{
        shrink: true,
        classes: {
          focused: classes.labelFocused,
        },
      }}
      InputProps={{
        classes: {
          root: classes.root,
          focused: classes.inputFocused,
        },
      }}
    />
  );
});

const SelectBox = withStyles(styles)((props) => {
  const {
    disabled,
    labelText,
    classes,
    value,
    id,
    info,
    updateInfo,
    options,
    required,
    customField,
  } = props;
  return (
    <TextField
      select
      fullWidth
      disabled={disabled || false}
      label={labelText}
      value={value}
      required={required ? required : false}
      margin="normal"
      color="primary"
      onChange={(e) => {
        if (id === "role") {
          const guestAccountDetail = customField.find(
            (val) => val.name === "Guest Account"
          );
          let obj = {};
          obj[id] = e.target.value;
          obj[guestAccountDetail.fieldId] =
            e.target.value === "Guest" ? "Yes" : "No";
          updateInfo({ ...info, ...obj });
        } else {
          let obj = {};
          obj[id] = e.target.value;
          updateInfo({ ...info, ...obj });
        }
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          focused: classes.labelFocused,
        },
      }}
      InputProps={{
        classes: {
          root: classes.root,
          focused: classes.inputFocused,
        },
      }}
    >
      {options}
    </TextField>
  );
});

const useStyles = makeStyles(formStyle);

export default function CreateProperty() {
  const history = useHistory();
  const { id, propertyId } = useParams();
  const { device } = useSelector((state) => ({
    device: state.device,
  }));

  const [userInfo, setUserInfo] = React.useState({
    visibleToPatient: true,
    visibleToPractice: true,
    visibleToSupplier: true,
    required: false,
    indexProperty: false,
    dropdownList: []
  });
  const [alertMessage, setAlertMessage] = React.useState("");
  const [toastStatus, setToastStatus] = React.useState(false);
  const [refreshData, setRefreshData] = React.useState(Date.now());

  const [getDevicePropertyList] = useComposeDispatcher(
    FETCH_DEVICE_PROPERTY_REQUEST
  );
  const [getDevicePropertySetList] = useComposeDispatcher(
    FETCH_DEVICE_PROPERTY_SET_REQUEST
  );
  const [getPropertyDetailsById] = useComposeDispatcher(
    GET_DEVICE_PROPERTY_REQUEST
  );
  const [updateProperty] = useComposeDispatcher(UPDATE_DEVICE_PROPERTY_REQUEST);
  const [createProperty] = useComposeDispatcher(CREATE_DEVICE_PROPERTY_REQUEST);

  const classes = useStyles();

  useEffect(() => {
    getDevicePropertyList(id);
    getDevicePropertySetList(id);
    propertyId && getPropertyDetailsById(propertyId);
  }, [id]);

  if (
    propertyId &&
    device.propertyDetails.devicePropertyId &&
    !userInfo.devicePropertyId
  ) {
    device.propertyDetails.devicePropertySet = device.propertyDetails.devicePropertySet.map(
      (val) => val.devicePropertySetId
    );
    setUserInfo({ ...userInfo, ...device.propertyDetails });
    setRefreshData(Date.now());
  }

  let validateForm = () => {
    setToastStatus(false);
    if (!userInfo?.name || !userInfo?.devicePropertySet) {
      setAlertMessage("Fields mark with asterisk are required");
      setToastStatus(true);
    } else {
      userInfo.devicePropertySet = userInfo.devicePropertySet.map((val) => ({
        devicePropertySetId: val,
      }));
      if (propertyId) {
        updateProperty(userInfo);
      } else {
        userInfo.displayOrder = 0;
        userInfo.deviceDataModelId = id;
        userInfo.groupingProperty = false;
        userInfo.namevalidationRules = [];
        createProperty(userInfo);
      }
    }
  };

  if (device.success) {
    history.push(`/admin/edit-device/${id}`);
  }

  return (
    <GridContainer key={refreshData}>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="danger" icon>
            <CardIcon color="danger">
              <Person />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Property</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <InputBox
                  labelText="Name"
                  value={userInfo.name}
                  disabled={propertyId ? true : false}
                  id="name"
                  required={true}
                  info={userInfo}
                  updateInfo={setUserInfo}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <InputBox
                  labelText="Description"
                  id="description"
                  value={userInfo.description}
                  info={userInfo}
                  updateInfo={setUserInfo}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4} style={{ marginTop: "15px" }}>
                <Autocomplete
                  multiple
                  fullWidth
                  options={device.propertySet}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </React.Fragment>
                  )}
                  value={
                    userInfo?.devicePropertySet
                      ? userInfo.devicePropertySet.reduce((acc, data) => {
                        const linkedData = device.propertySet.find(
                          (val) => val.devicePropertySetId === data
                        );
                        if (linkedData) {
                          return [...acc, linkedData];
                        }
                        return acc;
                      }, [])
                      : []
                  }
                  onChange={(event, val) => {
                    const field = { ...userInfo };
                    if (val.length > 0) {
                      field.devicePropertySet = val.map(
                        (data) => data.devicePropertySetId
                      );
                    } else {
                      delete field.devicePropertySet;
                    }
                    setUserInfo(field);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      color="primary"
                      required={true}
                      label="Device Property Set(s)"
                      InputLabelProps={{
                        shrink: true,
                        classes: {
                          focused: classes.labelFocused,
                        },
                      }}
                    />
                  )}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <SelectBox
                  id="dataType"
                  labelText="Property Type"
                  value={userInfo?.dataType}
                  info={userInfo}
                  updateInfo={setUserInfo}
                  options={PROPERTY_TYPE.map((val) => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      key={val.key}
                      value={val.key}
                    >
                      {val.title}
                    </MenuItem>
                  ))}
                />
              </GridItem>
            </GridContainer>
            {userInfo.dataType === 'Enumeration' && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Button
                    color="danger"
                    onClick={() => {
                      let previousData = userInfo?.additionalData || [];
                      let obj = {};
                      obj['additionalData'] = [...previousData, ''];
                      setUserInfo({ ...userInfo, ...obj });
                    }}
                  >
                    Add Dropdown Values
                  </Button>
                </GridItem>
                {userInfo?.additionalData?.map((val, index) => (
                  <GridItem xs={12} sm={12} md={4}>
                    <Grid container spacing={1} alignItems="flex-end">
                      <Grid item>
                        <DropDownInputBox
                          onChange={val => {
                            let previousData = userInfo?.additionalData;
                            previousData[index] = val.target.value
                            let obj = {};
                            obj['additionalData'] = previousData;
                            setUserInfo({ ...userInfo, ...obj });
                          }}
                          labelText={`Dropdown ${index + 1}`}
                          value={val}
                        />
                      </Grid>
                      <Grid item>
                        <Box p={1}>
                          <CancelIcon
                            onClick={() => {
                              let previousData = userInfo?.additionalData;
                              previousData.splice(index, 1);
                              let obj = {};
                              obj['additionalData'] = previousData;
                              setUserInfo({ ...userInfo, ...obj });
                            }}
                            style={{cursor:"pointer"}}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </GridItem>
                ))}
              </GridContainer>
            )}
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <SelectBox
                  id="indexProperty"
                  labelText="Index Property? (OPTIONS)"
                  value={userInfo?.indexProperty}
                  info={userInfo}
                  updateInfo={setUserInfo}
                  options={[
                    { key: true, title: "Yes" },
                    { key: false, title: "No" },
                  ].map((val) => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      value={val.key}
                      key={val.key}
                    >
                      {val.title}
                    </MenuItem>
                  ))}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <SelectBox
                  id="required"
                  labelText="Required? (OPTIONS)"
                  value={userInfo?.required}
                  info={userInfo}
                  updateInfo={setUserInfo}
                  options={[
                    { key: true, title: "Yes" },
                    { key: false, title: "No" },
                  ].map((val) => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      key={val.key}
                      value={val.key}
                    >
                      {val.title}
                    </MenuItem>
                  ))}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <SelectBox
                  id="visibleToPatient"
                  labelText="Visible to Patient? (VISIBILITY)"
                  value={userInfo?.visibleToPatient}
                  info={userInfo}
                  updateInfo={setUserInfo}
                  options={[
                    { key: true, title: "Yes" },
                    { key: false, title: "No" },
                  ].map((val) => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      key={val.key}
                      value={val.key}
                    >
                      {val.title}
                    </MenuItem>
                  ))}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <SelectBox
                  id="visibleToSupplier"
                  labelText="Visible to Partner? (VISIBILITY)"
                  value={userInfo?.visibleToSupplier}
                  info={userInfo}
                  updateInfo={setUserInfo}
                  options={[
                    { key: true, title: "Yes" },
                    { key: false, title: "No" },
                  ].map((val) => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      key={val.key}
                      value={val.key}
                    >
                      {val.title}
                    </MenuItem>
                  ))}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <SelectBox
                  id="visibleToPractice"
                  labelText="Visible to Practice? (VISIBILITY)"
                  value={userInfo?.visibleToPractice}
                  info={userInfo}
                  updateInfo={setUserInfo}
                  options={[
                    { key: true, title: "Yes" },
                    { key: false, title: "No" },
                  ].map((val) => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      key={val.key}
                      value={val.key}
                    >
                      {val.title}
                    </MenuItem>
                  ))}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Box textAlign="right">
          <Button onClick={() => validateForm()} color="danger">
            {propertyId ? "Save" : "Submit"}
          </Button>
          <Button
            onClick={() => {
              history.push(`/admin/edit-device/${id}`);
            }}
          >
            Cancel
          </Button>
        </Box>
      </GridItem>
      <Snackbar
        place="tr"
        color="danger"
        icon={AddAlert}
        message={alertMessage}
        open={toastStatus}
        closeNotification={() => setToastStatus(false)}
        close
      />
      <Loader status={device.loading} />
    </GridContainer>
  );
}
