import { ofType } from "redux-observable";
import { mergeMap, map, catchError } from "rxjs/operators";

import { dispatch } from "../../common/helpers";
import { practiceService } from "../../services";

import {
  FETCH_PRACTICE_REQUEST,
  FETCH_PRACTICE_SUCCESS,
  FETCH_PRACTICE_ERROR,
  PRACTICE_DETAIL_REQUEST,
  PRACTICE_DETAIL_SUCCESS,
  PRACTICE_DETAIL_ERROR,
} from "./actions";

export const getPracticeList = (action$) => {
  return action$.pipe(
    ofType(FETCH_PRACTICE_REQUEST),
    mergeMap(({ payload }) => {
      return practiceService.getPracticeList(payload).pipe(
        map((response) => {
          return dispatch(FETCH_PRACTICE_SUCCESS, JSON.parse(response));
        }),
        catchError((error) => {
          return dispatch(FETCH_PRACTICE_ERROR, error);
        })
      );
    })
  );
};

export const getPracticeDetailsById = (action$) => {
  return action$.pipe(
    ofType(PRACTICE_DETAIL_REQUEST),
    mergeMap(({ payload }) => {
      return practiceService.getPracticeDetailsById(payload).pipe(
        map((response) => {
          return dispatch(PRACTICE_DETAIL_SUCCESS, JSON.parse(response));
        }),
        catchError((error) => {
          return dispatch(PRACTICE_DETAIL_ERROR, error);
        })
      );
    })
  );
};
