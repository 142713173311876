import * as service from "../common/service";
import { API_BASE_URL } from "../common/constants";

const customFieldApi = `${API_BASE_URL}/user/custom-field`;

const customFieldService = {
  getCustomFieldList: (payload) => {
    return service.get(`${customFieldApi}?fieldArea=${payload}`, {});
  },
};

export default customFieldService;
