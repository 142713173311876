import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AddAlert from "@material-ui/icons/AddAlert";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Loader from "components/Loader/Loader.js";
import Snackbar from "components/Snackbar/Snackbar.js";

import { FETCH_OBSERVE_LIST_REQUEST } from "../../redux/observe/actions";

import { FETCH_USER_LIST_REQUEST } from "../../redux/users/actions";

import { useComposeDispatcher } from "../../common/hooks";
import { PAGINATION_DETAILS, PAGE_SIZES } from "../../common/constants";
import styles from "assets/jss/material-dashboard-pro-react/views/userStyle.js";

const useStyles = makeStyles(styles);

const columns = [
  { id: "firstName", label: "First Name", minWidth: 100, sort: false },
  { id: "lastName", label: "Last Name", minWidth: 100, sort: false },
  { id: "emailAddress", label: "Email", minWidth: 170, sort: false },
  { id: "role", label: "Role", minWidth: 100, sort: false },
  { id: "status", label: "Status", minWidth: 100, sort: false },
  {
    id: "createdOn",
    label: "Date Created",
    minWidth: 130,
    sort: false,
    type: "date",
  },
];

const observeColumns = [
  { id: "name", label: "Name", minWidth: 100, sort: true },
  {
    id: "validUntil",
    label: "Valid Until",
    minWidth: 100,
    sort: true,
    type: "date",
  },
  { id: "observerEmailAddress", label: "Email", minWidth: 150, sort: true },
  { id: "role", label: "Role", minWidth: 100, sort: true },
  { id: "requestStatus", label: "Status", minWidth: 100, sort: true },
  {
    id: "createdOn",
    label: "Date Created",
    minWidth: 130,
    sort: true,
    type: "date",
  },
];

export default function Observe() {
  const { observe: observeData, users: userData } = useSelector((state) => ({
    observe: state.observe,
    users: state.users,
  }));

  const [order, setOrder] = React.useState("DESC");
  const [orderBy, setOrderBy] = React.useState("createdOn");
  const [alertMessage, setAlertMessage] = React.useState("");
  const [toastStatus, setToastStatus] = React.useState(false);
  const [open, setOpen] = React.useState(true);

  const [getObserveList] = useComposeDispatcher(FETCH_OBSERVE_LIST_REQUEST);
  const [getUserList] = useComposeDispatcher(FETCH_USER_LIST_REQUEST);

  const classes = useStyles();
  const [page, setPage] = React.useState(PAGINATION_DETAILS.pageNumber);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    PAGINATION_DETAILS.pageSize
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getObserveList({
      pageNumber: newPage,
      pageSize: rowsPerPage,
      sortBy: orderBy,
      sortOrder: order,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    getObserveList({
      pageNumber: 0,
      pageSize: +event.target.value,
      sortBy: orderBy,
      sortOrder: order,
    });
  };

  useEffect(() => {
    getUserList({
      pageNumber: page,
      pageSize: rowsPerPage,
    });
    getObserveList({
      pageNumber: page,
      pageSize: rowsPerPage,
      sortBy: orderBy,
      sortOrder: order,
    });
  }, []);

  const createSortHandler = (property) => () => {
    const isAsc = orderBy === property && order === "ASC";
    setOrder(isAsc ? "DESC" : "ASC");
    setOrderBy(property);
    getObserveList({
      pageNumber: 0,
      pageSize: rowsPerPage,
      sortBy: property,
      sortOrder: isAsc ? "DESC" : "ASC",
    });
    setPage(0);
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="danger" icon>
            <CardIcon color="danger">
              <Person />
            </CardIcon>
          </CardHeader>
          <CardBody>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          maxWidth: column.minWidth,
                        }}
                      >
                        {column.sort ? (
                          <TableSortLabel
                            style={{
                              maxWidth: column.minWidth,
                              minWidth: column.minWidth,
                            }}
                            active={orderBy === column.id}
                            direction={
                              orderBy === column.id
                                ? order.toLowerCase()
                                : "asc"
                            }
                            onClick={createSortHandler(column.id)}
                          >
                            <Typography>{column.label}</Typography>
                          </TableSortLabel>
                        ) : (
                          <Box
                            style={{
                              maxWidth: column.minWidth,
                              minWidth: column.minWidth,
                            }}
                          >
                            <Typography>{column.label}</Typography>
                          </Box>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userData.userList.users.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.userId}
                      >
                        {columns.map((column) => {
                          let value = row[column.id];
                          if (column.type === "date") {
                            value = moment(row[column.id]).format(
                              "DD MMM, YYYY hh:mm"
                            );
                          }
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.id === "role" && "Resident"}
                              {column.id === "expand" && (
                                <TableCell>
                                  <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => setOpen(!open)}
                                  >
                                    {open ? (
                                      <KeyboardArrowUpIcon />
                                    ) : (
                                      <KeyboardArrowDownIcon />
                                    )}
                                  </IconButton>
                                </TableCell>
                              )}
                              {column.id !== "action" &&
                              column.id !== "role" &&
                              column.format &&
                              typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box marginTop={1}>
              <Typography
                variant="h6"
                style={{
                  marginLeft: "10px",
                }}
                gutterBottom
                component="div"
              >
                Guest
              </Typography>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {observeColumns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            maxWidth: column.minWidth,
                          }}
                        >
                          {column.sort ? (
                            <TableSortLabel
                              style={{
                                maxWidth: column.minWidth,
                                minWidth: column.minWidth,
                              }}
                              active={orderBy === column.id}
                              direction={
                                orderBy === column.id
                                  ? order.toLowerCase()
                                  : "asc"
                              }
                              onClick={createSortHandler(column.id)}
                            >
                              <Typography>{column.label}</Typography>
                            </TableSortLabel>
                          ) : (
                            <Typography>{column.label}</Typography>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {observeData.observeList.totalElements === 0 && (
                      <TableRow>
                        <TableCell align="center" colSpan="6">
                          No records found
                        </TableCell>
                      </TableRow>
                    )}
                    {observeData.observeList.users.map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.userObserverId}
                        >
                          {observeColumns.map((column) => {
                            let value = row[column.id];
                            if (column.type === "date") {
                              value = moment(row[column.id]).format(
                                "DD MMM, YYYY hh:mm"
                              );
                            }
                            return (
                              <TableCell
                                style={{
                                  maxWidth: column.minWidth,
                                }}
                                key={column.id}
                                align={column.align}
                              >
                                {column.id === "role" && "Guest"}
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={PAGE_SIZES}
                component="div"
                count={observeData.observeList.totalElements}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Box>
            {alert}
            <Snackbar
              place="tr"
              color="danger"
              icon={AddAlert}
              message={alertMessage}
              open={toastStatus}
              closeNotification={() => setToastStatus(false)}
              close
            />
            <Loader status={observeData.observeList.loading} />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
