import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#e53935",
  },
  cancelBtn: {
    position: "absolute",
    top: "0",
    right: "0",
    color: "#000000",
    padding: "15px",
    marginTop: "16px",
    cursor: "pointer"
  }
}));

export default function Loader(props) {
  const { status, crossIcon = false, onDrawerClose } = props;
  const classes = useStyles();
  return (
    <div>
      <Backdrop className={classes.backdrop} style={{ zIndex: "1400" }} open={status}>
        {crossIcon && <div className={classes.cancelBtn} onClick={onDrawerClose}><CloseIcon /></div>}
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

Loader.propTypes = {
  status: PropTypes.bool,
  crossIcon: PropTypes.bool,
  onDrawerClose: PropTypes.func
};
