import React, { useEffect } from "react";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import TextField from "@material-ui/core/TextField";
import AddAlert from "@material-ui/icons/AddAlert";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Button from "components/CustomButtons/Button.js";
import { useComposeDispatcher } from "../common/hooks";
import { RESET_NOTIFICATION_DATA } from "../redux/notifications/actions";
import AddUser from "views/Users/AddUser.js";
import CreatePatient from "views/Patient/CreatePatient.js";
import AddCompany from "views/Company/AddCompany.js";
import CreateDevice from "views/Devices/CreateDevice.js";
import CreateProperty from "views/Forms/CreateProperty.js";
import CreatePropertySet from "views/Forms/CreatePropertySet.js";
import Firmware from "views/Forms/Firmware.js";
import CreateFirmware from "views/Forms/CreateFirmware.js";
import AddManufacturer from "views/Manufacturer/AddManufacturer.js";
import PdfLoader from "components/PdfLoader/PdfLoader.js";

import routes from "routes.js";
import {
  UPDATE_TERM_AND_CONDITION_REQUEST,
  UPDATE_PASSWORD_REQUEST,
} from "../redux/users/actions";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";

var ps;

const useStyles = makeStyles(styles);

const inputStyles = () => ({
  labelFocused: {
    color: "#E73F3B !important",
  },
  inputFocused: {
    "&:before": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "&:after": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "& input": {
      borderBottom: "#E73F3B !important",
    },
  },
  inputRoot: {
    color: "red",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
});

const InputBox = withStyles(inputStyles)((props) => {
  const {
    labelText,
    helperText,
    classes,
    value,
    id,
    info,
    updateInfo,
    type,
    required,
  } = props;
  return (
    <TextField
      label={labelText}
      helperText={helperText}
      fullWidth
      required={required ? required : false}
      type={type ? type : "text"}
      margin="normal"
      color="primary"
      value={value}
      onChange={(e) => {
        let obj = {};
        obj[id] = e.target.value;
        updateInfo({ ...info, ...obj });
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          focused: classes.labelFocused,
        },
      }}
      InputProps={{
        classes: {
          root: classes.root,
          focused: classes.inputFocused,
        },
      }}
    />
  );
});

export default function Dashboard(props) {
  const history = useHistory();
  const { ...rest } = props;
  const image = require("assets/img/sidebar-2.jpg");
  const color = "red";
  const bgColor = "black";
  const logo = require("assets/img/logo.png");
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  // const [hasImage, setHasImage] = React.useState(true);
  const [termAndConditionBox, setTermAndConditionBox] = React.useState(false);
  const [changePasswordBox, setChangePasswordBox] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [toastStatus, setToastStatus] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState({});
  const [loginUser, setLoginUser] = React.useState({});

  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (!localStorage.getItem("user-details")) {
      history.push("/auth/login");
    }
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });

  const { success, message, error: alertError, align: notificationAlign } = useSelector(
    ({ notifications }) => notifications
  );
  const userData = useSelector(({ users }) => users);

  useEffect(() => {
    const userDetails = localStorage.getItem("user-details");
    !loginUser?.userId && setLoginUser(JSON.parse(userDetails));
    const loggedUserRole = JSON.parse(userDetails) !== null ? JSON.parse(userDetails).roles[0].role : "";

    const termAndCondtionsObj = localStorage.getItem("termAndCondtions");
    const changePasswordObj = localStorage.getItem("changePassword");
    if (
      (loggedUserRole === "TenantAdmin" ||
        loggedUserRole === "SupplierAdmin") &&
      termAndCondtionsObj &&
      termAndCondtionsObj !== 'undefined'
    ) {
      if (
        JSON.parse(termAndCondtionsObj).fieldData === "No" &&
        !termAndConditionBox
      ) {
        setTermAndConditionBox(true);
      } else if (
        JSON.parse(termAndCondtionsObj).fieldData === "Yes" &&
        termAndConditionBox
      ) {
        setTermAndConditionBox(false);
      }
    }
    if (
      (loggedUserRole === "TenantAdmin" ||
        loggedUserRole === "SupplierAdmin") &&
      changePasswordObj &&
      changePasswordObj !== 'undefined'
    ) {
      if (
        JSON.parse(changePasswordObj).fieldData === "Yes" &&
        !changePasswordBox
      ) {
        setChangePasswordBox(true);
      }
    }
  });

  const [resetNotificationData] = useComposeDispatcher(RESET_NOTIFICATION_DATA);
  const [updateTermAndConditions] = useComposeDispatcher(
    UPDATE_TERM_AND_CONDITION_REQUEST
  );
  const [changeUserPassword] = useComposeDispatcher(UPDATE_PASSWORD_REQUEST);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const changePasswordModal = () => {
    return (
      <Dialog
        open={changePasswordBox}
        onClose={() => {
          setChangePasswordBox(false);
        }}
        disableEscapeKeyDown={true}
        // disableBackdropClick={true}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
        <DialogContent>
          <InputBox
            labelText="Current Password"
            id="currentPassword"
            type="password"
            value={userInfo?.currentPassword}
            required={true}
            info={userInfo}
            updateInfo={setUserInfo}
          />
          <InputBox
            labelText="New Password"
            id="newPassword"
            type="password"
            value={userInfo?.newPassword}
            required={true}
            info={userInfo}
            helperText="Please enter password which contain min 8 letter, at least a symbol, upper and lower case letters and a number"
            updateInfo={setUserInfo}
          />
          <InputBox
            labelText="Confirm Password"
            id="confirmPassword"
            type="password"
            value={userInfo?.confirmPassword}
            required={true}
            info={userInfo}
            updateInfo={setUserInfo}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              localStorage.removeItem("user-details");
              localStorage.removeItem("termAndCondtions");
              localStorage.removeItem("changePassword");
              localStorage.removeItem("user-custom-details");
              history.push("/auth/login");
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            disabled={
              !userInfo?.newPassword ||
              !userInfo?.confirmPassword ||
              !userInfo?.currentPassword
            }
            onClick={() => {
              const userDetails = JSON.parse(
                localStorage.getItem("user-details")
              );
              const userCustomDetails = JSON.parse(
                localStorage.getItem("user-custom-details")
              );

              const passwordReq = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
              if (!passwordReq.test(userInfo?.newPassword)) {
                setAlertMessage("Please enter valid password");
                setToastStatus(true);
              } else if (userInfo?.newPassword !== userInfo?.confirmPassword) {
                setAlertMessage("Entered Password is not matching");
                setToastStatus(true);
              } else {
                const finalObj = {
                  user: userDetails,
                  customData: userCustomDetails.map((val) => {
                    if (val.field.name === "Change Password") {
                      val.fieldData = "No";
                    }
                    return val;
                  }),
                };
                changeUserPassword({
                  password: {
                    userId: loginUser.userId,
                    newPassword: userInfo?.newPassword,
                    currentPassword: userInfo?.currentPassword,
                    newConfirmPassword: userInfo?.confirmPassword,
                  },
                  user: finalObj,
                });
              }
            }}
            color="primary"
          >
            Update Password
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={"Cardi/o"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={getActiveRoute(routes)}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>
              <Switch>
                {getRoutes(routes)}
                <Route 
                  path="/admin/add-device"
                  component={CreateDevice}
                />
                <Route
                  path="/admin/add-resident"
                  component={CreatePatient}
                />
                <Route
                  path="/admin/edit-patient/:id"
                  component={CreatePatient}
                />
                <Route path="/admin/add-user" component={AddUser} />
                <Route path="/admin/edit-user/:id" component={AddUser} />
                <Route
                  path="/admin/add-manufacturer"
                  component={AddManufacturer}
                />
                <Route
                  path="/admin/edit-manufacturer/:id"
                  component={AddManufacturer}
                />
                <Route path="/admin/add-company" component={AddCompany} />
                <Route path="/admin/edit-company/:id" component={AddCompany} />
                <Route path="/admin/edit-device/:id" component={CreateDevice} />
                <Route
                  path="/admin/device-property/create/:id"
                  component={CreateProperty}
                />
                <Route
                  path="/admin/device-property-set/create/:id"
                  component={CreatePropertySet}
                />
                <Route
                  path="/admin/device-property-set/edit/:id/:propertyId"
                  component={CreatePropertySet}
                />
                <Route
                  path="/admin/device-property/edit/:id/:propertyId"
                  component={CreateProperty}
                />
                <Route path="/admin/device-firmware/:id" component={Firmware} />
                <Route
                  path="/admin/create-device-firmware/:id"
                  component={CreateFirmware}
                />
                <Route
                  path="/admin/edit-device-firmware/:id/:firmwareId"
                  component={CreateFirmware}
                />
                <Redirect from="/admin" to="/admin/dashboard" />
              </Switch>
            </div>
          </div>
        ) : (
          <div className={classes.map}>
            <Switch>
              {getRoutes(routes)}
              <Route path="/admin/edit-user/:id" component={AddUser} />
              <Route path="/admin/edit-company/:id" component={AddCompany} />
              <Redirect from="/admin" to="/admin/dashboard" />
            </Switch>
          </div>
        )}
        {getRoute() ? <Footer fluid /> : null}
        <Snackbar
          place={notificationAlign || 'tr'}
          color={success ? "success" : "danger"}
          icon={AddAlert}
          message={message}
          open={success || alertError}
          closeNotification={() => resetNotificationData()}
          close
        />
        <Snackbar
          place="tr"
          color="danger"
          icon={AddAlert}
          message={alertMessage}
          open={toastStatus}
          closeNotification={() => setToastStatus(false)}
          close
        />
        {changePasswordBox && changePasswordModal()}
        <Dialog
          open={termAndConditionBox}
          maxWidth="lg"
          fullWidth={true}
          disableEscapeKeyDown={true}
          // disableBackdropClick={true}
          onClose={() => {
            setTermAndConditionBox(false);
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Privacy policy</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <p>
                <b>
                  This privacy policy discloses the privacy practices for
                  Advanced Telesensors, Inc.
                </b>
              </p>
              <p>
                This privacy policy applies solely to information collected by
                this web site. It will notify you of the following:
              </p>
              <ul>
                <li>
                  What personally identifiable information is collected from you
                  through the web site, how it is used and with whom it may be
                  shared.
                </li>
                <li>
                  What choices are available to you regarding the use of your
                  data.
                </li>
                <li>
                  The security procedures in place to protect the misuse of your
                  information.
                </li>
                <li>
                  How you can correct any inaccuracies in the information.
                </li>
              </ul>
              <p>
                <b>Information Collection, Use, and Sharing</b>
              </p>
              <p>
                We are the sole owners of the information collected on this
                site. We only have access to/collect information that you
                voluntarily give us via email or other direct contact from you.
                We will not sell or rent this information to anyone.
              </p>
              <p>
                We will use your information to respond to you regarding the
                reason you contacted us. We will not share your information with
                any third party outside of our organization, other than as
                necessary to fulfill your request, e.g. to ship an order.
              </p>
              <p>
                Unless you ask us not to, we may contact you via email in the
                future to tell you about specials, new products or services, or
                changes to this privacy policy.
              </p>
              <p>
                <b>Your Access to and Control Over Information</b>
              </p>
              <p>You may opt out of any future contacts from us at any time.</p>
              <p>
                You can do the following at any time by contacting us via the
                email address or phone number given on our website:
              </p>
              <ul>
                <li>See what data we have about you, if any.</li>
                <li>Change/correct any data we have about you.</li>
                <li>Have us delete any data we have about you.</li>
                <li>
                  Express any concern you have about our use of your data.
                </li>
              </ul>
              <p>
                <b>Security</b>
              </p>
              <p>
                We take precautions to protect your information. When you submit
                sensitive information via the website, your information is
                protected both online and offline.
              </p>
              <p>
                Wherever we collect sensitive information (such as credit card
                data), that information is encrypted and transmitted to us in a
                secure way. You can verify this by looking for a closed lock
                icon at the bottom of your web browser, or looking for “https”
                at the beginning of the address of the web page.
              </p>
              <p>
                While we use encryption to protect sensitive information
                transmitted online, we also protect your information offline.
                Only employees who need the information to perform a specific
                job (for example, billing or customer service) are granted
                access to personally identifiable information. The
                computers/servers in which we store personally identifiable
                information are kept in a secure environment.
              </p>
              <p>
                <b>Updates</b>
              </p>
              <p>
                Our Privacy Policy may change from time to time, and all updates
                will be posted on this page. If you feel that we are not abiding
                by this privacy policy, you should contact us immediately via
                telephone at{" "}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="tel:626.584.0913"
                  style={{ color: "#f44336" }}
                >
                  626.584.0913
                </a>{" "}
                or via email at{" "}
                <a
                  rel="noopener noreferrer"
                  href="mailto: info@cardio.io"
                  target="_blank"
                  style={{ color: "#f44336" }}
                >
                  info@cardio.io
                </a>
                .
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                localStorage.removeItem("user-details");
                localStorage.removeItem("termAndCondtions");
                localStorage.removeItem("changePassword");
                localStorage.removeItem("user-custom-details");
                history.push("/auth/login");
              }}
              color="primary"
            >
              Dont Agree
            </Button>
            <Button
              onClick={() => {
                const userDetails = JSON.parse(
                  localStorage.getItem("user-details")
                );
                const userCustomDetails = JSON.parse(
                  localStorage.getItem("user-custom-details")
                );

                const finalObj = {
                  user: userDetails,
                  customData: userCustomDetails.map((val) => {
                    if (val.field.name === "T&C accepted") {
                      val.fieldData = "Yes";
                    }
                    return val;
                  }),
                };
                updateTermAndConditions(finalObj);
              }}
              color="primary"
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
        <PdfLoader status={userData.loading} />
      </div>
    </div>
  );
}
