export const isURL = (str) => {
  const pattern = new RegExp(`
  ${"^(https?:\\/\\/)?" /* protocol */}
  ${"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" /* domain name */}
  ${"((\\d{1,3}\\.){3}\\d{1,3}))" /* OR ip (v4) address */}
  ${"(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" /* port and path */}
  ${"(\\?[;&a-z\\d%_.~+=-]*)?" /* query string */}
  ${"(\\#[-a-z\\d_]*)?$" /* fragment locator */}
  ${"i"}`);
  // const pattern = new RegExp(`^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$`);
  return !!pattern.test(str);
};

export const isIEBrowser = () => {
  return !!document.documentMode;
};

export const createReducer = (schema, reducer) => (
  initialState = schema,
  action$
) => (reducer[action$.type] || (() => initialState))(initialState, action$);

export const buildRouteParams = (route, ...args) => {
  let i = -1;
  const regex = /(:\w+)/g;
  const final = route
    .split(regex)
    .map((chunk) => {
      if (regex.test(chunk)) {
        i += 1;
        return args[i];
      }
      return chunk;
    })
    .join("");
  return final;
};

export const dispatch = (type, payload) => ({ type, payload });

export const objToParams = (obj) =>
  Object.entries(obj)
    .map(
      ([key, value]) => `${key}=${encodeURIComponent(JSON.stringify(value))}`
    )
    .join("&");
