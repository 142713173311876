import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import GetAppIcon from "@material-ui/icons/GetApp";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import TableSortLabel from "@material-ui/core/TableSortLabel";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Loader from "components/Loader/Loader.js";
import { PAGINATION_DETAILS, PAGE_SIZES } from "../../common/constants";
import {
  FETCH_DEVICE_FIRMWARE_REQUEST,
  DELETE_DEVICE_FIRMWARE_REQUEST,
  DOWNLOAD_DEVICE_FIRMWARE_REQUEST
} from "../../redux/device/actions";
import { useComposeDispatcher } from "../../common/hooks";
import styles from "assets/jss/material-dashboard-pro-react/views/userStyle.js";

const useStyles = makeStyles(styles);

const columns = [
  {
    id: "displayVersion",
    label: "Display Version",
    minWidth: 130,
    maxWidth: 140,
    sort: true,
  },
  { id: "name", label: "Name", minWidth: 130, maxWidth: 130, sort: false },
  {
    id: "description",
    label: "Description",
    minWidth: 150,
    maxWidth: 150,
    sort: false,
  },
  {
    id: "createdOn",
    label: "Date Created",
    minWidth: 130,
    maxWidth: 130,
    type: 'date',
    sort: false,
  },
  {
    id: "action",
    label: "Action",
    minWidth: 170,
    maxWidth: 170,
    align: "right",
    sort: false,
    format: (value) => value.toFixed(2),
  },
];

export default function Firmware() {
  const history = useHistory();
  const { id } = useParams();

  const { device } = useSelector((state) => ({
    device: state.device,
  }));

  const [alert, setAlert] = React.useState(null);
  const [order, setOrder] = React.useState("DESC");
  const [orderBy, setOrderBy] = React.useState("createdOn");

  const [getDeviceFirmwareList] = useComposeDispatcher(
    FETCH_DEVICE_FIRMWARE_REQUEST
  );
  const [deleteFirmwareDetailsById] = useComposeDispatcher(
    DELETE_DEVICE_FIRMWARE_REQUEST
  );
  const [downloadFirmwareVersion] = useComposeDispatcher(
    DOWNLOAD_DEVICE_FIRMWARE_REQUEST
  );

  const classes = useStyles();

  const [page, setPage] = React.useState(PAGINATION_DETAILS.pageNumber);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    PAGINATION_DETAILS.pageSize
  );

  const params = new URLSearchParams(window.location.search),
    tableName = params.get('name');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getDeviceFirmwareList({
      deviceId: id,
      pageNumber: newPage,
      pageSize: rowsPerPage,
      sortBy: orderBy,
      sortOrder: order,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getDeviceFirmwareList({
      deviceId: id,
      pageNumber: page,
      pageSize: rowsPerPage,
      sortBy: orderBy,
      sortOrder: order,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === "ASC";
    setOrder(isAsc ? "DESC" : "ASC");
    setOrderBy(property);
    getDeviceFirmwareList({
      deviceId: id,
      pageNumber: 0,
      pageSize: rowsPerPage,
      sortBy: property,
      sortOrder: isAsc ? "DESC" : "ASC",
    });
    setPage(0);
  };

  const warningWithConfirmMessage = (company) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => {
          deleteFirmwareDetailsById({
            firmwareId: company.firmwareId,
            deviceId: id,
            pageNumber: page,
            pageSize: rowsPerPage,
            sortBy: orderBy,
            sortOrder: order,
          });
          hideAlert();
        }}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        You will not be able to recover <b>{company.displayVersion}</b> firmware
        data!
      </SweetAlert>
    );
  };

  const downloadFirmware = (row) => {
    downloadFirmwareVersion({
      firmwareId: row.firmwareId,
      fileName: row.fileName
    });
  }
  // const successDelete = () => {
  //   setAlert(
  //     <SweetAlert
  //       success
  //       style={{ display: "block", marginTop: "-100px" }}
  //       title="Deleted!"
  //       onConfirm={() => hideAlert()}
  //       onCancel={() => hideAlert()}
  //       confirmBtnCssClass={classes.button + " " + classes.success}
  //     >
  //       Your imaginary file has been deleted.
  //     </SweetAlert>
  //   );
  // };

  const hideAlert = () => {
    setAlert(null);
  };

  const onEditButtonClick = ({ firmwareId }) => {
    history.push(`/admin/edit-device-firmware/${id}/${firmwareId}`);
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="danger" icon>
            <CardIcon color="danger">
              <Person />
            </CardIcon>
            <span className="tabelName">{tableName}</span>
            <Box
              paddingTop={1}
              onClick={() => {
                history.push(`/admin/create-device-firmware/${id}`);
              }}
              style={{
                float: "right",
              }}
            >
              <Button color="danger">Create Firmware</Button>
            </Box>
          </CardHeader>
          <CardBody>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          maxWidth: column.maxWidth,
                          minWidth: column.minWidth,
                        }}
                      >
                        {column.sort ? (
                          <TableSortLabel
                            style={{
                              maxWidth: column.maxWidth,
                              minWidth: column.minWidth,
                            }}
                            active={orderBy === column.id}
                            direction={
                              orderBy === column.id
                                ? order.toLowerCase()
                                : "asc"
                            }
                            onClick={createSortHandler(column.id)}
                          >
                            <Typography>{column.label}</Typography>
                          </TableSortLabel>
                        ) : (
                          <Typography>{column.label}</Typography>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {device.firmwareList.length === 0 && (
                    <TableRow>
                      <TableCell align="center" colSpan="5">
                        No records found
                      </TableCell>
                    </TableRow>
                  )}
                  {device.firmwareList.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index + "" + row.userId}
                      >
                        {columns.map((column) => {
                          let value = row[column.id];
                          if (column.id === "address") {
                            let {
                              streetAddress1,
                              streetAddress2,
                              city,
                              state,
                              country,
                              zipcode,
                              primaryPhone,
                              secondaryPhone,
                            } = row["contactInfo"];
                            value = `
                            ${streetAddress1 ? streetAddress1 : ""}
                            ${streetAddress2 ? streetAddress2 : ""}
                            ${city ? city : ""}
                            ${state ? state : ""} 
                            ${country ? country : ""} 
                            ${zipcode ? zipcode : ""} 
                            ${primaryPhone ? primaryPhone : ""} 
                            ${secondaryPhone ? secondaryPhone : ""}
                          `;
                          }
                          if (column.type === "date") {
                            value = moment.utc(row[column.id]).local().format(
                              "DD MMM, YYYY HH:mm"
                            );
                          }
                          return (
                            <TableCell
                              key={column.id}
                              style={{ maxWidth: column.minWidth }}
                              align={column.align}
                            >
                              {column.id !== "action" &&
                              column.format &&
                              typeof value === "number"
                                ? column.format(value)
                                : value}
                              {column.id === "action" && (
                                <>
                                  <Button
                                    color="success"
                                    simple
                                    onClick={() => {
                                      onEditButtonClick(row);
                                    }}
                                    className={classes.actionButton}
                                  >
                                    <Edit />
                                  </Button>
                                  <Button
                                    color="danger"
                                    simple
                                    onClick={() => {
                                      warningWithConfirmMessage(row);
                                    }}
                                    className={classes.actionButton}
                                  >
                                    <Close />
                                  </Button>
                                  <Button
                                    color="info"
                                    simple
                                    onClick={() => {
                                      downloadFirmware(row);
                                    }}
                                    className={classes.actionButton}
                                  >
                                    <GetAppIcon />
                                  </Button>
                                </>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={PAGE_SIZES}
              component="div"
              count={device.firmwareTotalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <Loader status={device.loading || device.loadingFirmware} />
            {alert}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
