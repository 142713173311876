import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 111111,
    color: "#e53935",
  },
}));

export default function PdfLoader(props) {
  const { status } = props;
  const classes = useStyles();
  return (
    <div>
      <Backdrop className={classes.backdrop} open={status}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
PdfLoader.propTypes = {
  status: PropTypes.bool,
};
