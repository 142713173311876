import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import Loader from "components/Loader/Loader.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";

import formStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import {
  FETCH_FIRMWARE_DETAIL_REQUEST,
  CREATE_FIRMWARE_DETAIL_REQUEST,
  UPDATE_FIRMWARE_DETAIL_REQUEST,
} from "../../redux/device/actions";
import { useComposeDispatcher } from "../../common/hooks";

const styles = () => ({
  labelFocused: {
    color: "#E73F3B !important",
  },
  inputFocused: {
    "&:before": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "&:after": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "& input": {
      borderBottom: "#E73F3B !important",
    },
  },
  inputRoot: {
    color: "red",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
});

const InputBox = withStyles(styles)((props) => {
  const {
    disabled,
    labelText,
    helperText,
    classes,
    value,
    id,
    info,
    updateInfo,
    type,
    required,
  } = props;
  return (
    <TextField
      label={labelText}
      helperText={helperText}
      fullWidth
      disabled={disabled || false}
      required={required ? required : false}
      type={type ? type : "text"}
      margin="normal"
      color="primary"
      value={value}
      onChange={(e) => {
        let obj = {};
        obj[id] = e.target.value;
        if (type === "file") {
          obj["file"] = e.target.files[0];
        }
        updateInfo({ ...info, ...obj });
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          focused: classes.labelFocused,
        },
      }}
      InputProps={{
        classes: {
          root: classes.root,
          focused: classes.inputFocused,
        },
      }}
    />
  );
});

const useStyles = makeStyles(formStyle);

export default function CreateFirmware() {
  const history = useHistory();
  const { id, firmwareId } = useParams();
  const { device } = useSelector((state) => ({
    device: state.device,
  }));

  const [userInfo, setUserInfo] = React.useState({});
  const [alertMessage, setAlertMessage] = React.useState("");
  const [toastStatus, setToastStatus] = React.useState(false);
  const [refreshData, setRefreshData] = React.useState(Date.now());

  const [getFirmwareDetailsById] = useComposeDispatcher(
    FETCH_FIRMWARE_DETAIL_REQUEST
  );
  const [updateFirmwareDetailsById] = useComposeDispatcher(
    UPDATE_FIRMWARE_DETAIL_REQUEST
  );
  const [createFirmwareDetailsById] = useComposeDispatcher(
    CREATE_FIRMWARE_DETAIL_REQUEST
  );

  const classes = useStyles();

  useEffect(() => {
    firmwareId && getFirmwareDetailsById({ id, firmwareId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  let validateForm = () => {
    setToastStatus(false);
    if (
      !userInfo?.name ||
      !userInfo?.displayVersion ||
      !userInfo.fileName ||
      !userInfo.fileHash
    ) {
      setAlertMessage("Fields mark with asterisk are required");
      setToastStatus(true);
    } else {
      if (!firmwareId) {
        userInfo["deviceDataModelId"] = id;
        createFirmwareDetailsById({ userInfo });
      } else {
        updateFirmwareDetailsById({ userInfo });
      }
    }
  };

  if (
    firmwareId &&
    device.firmwareDetail?.firmwareId &&
    !userInfo?.firmwareId
  ) {
    setUserInfo(device.firmwareDetail);
    setRefreshData(Date.now());
  }

  if (device.success) {
    history.push(`/admin/device-firmware/${id}?name=${userInfo?.name}`);
  }

  return (
    <GridContainer key={refreshData}>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="danger" icon>
            <CardIcon color="danger">
              <Person />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              {firmwareId ? "Edit" : "Create"} Device Firmware
            </h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <InputBox
                  labelText="Name"
                  value={userInfo.name}
                  id="name"
                  required={true}
                  info={userInfo}
                  updateInfo={setUserInfo}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <InputBox
                  labelText="Description"
                  id="description"
                  value={userInfo.description}
                  info={userInfo}
                  updateInfo={setUserInfo}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <InputBox
                  labelText="Dislplay Version"
                  id="displayVersion"
                  required={true}
                  value={userInfo.displayVersion}
                  info={userInfo}
                  updateInfo={setUserInfo}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                {firmwareId ? (
                  <InputBox
                    labelText="Firmware File"
                    value={userInfo.fileName}
                    disabled={firmwareId ? true : false}
                    id="fileName"
                    required={true}
                    info={userInfo}
                    updateInfo={setUserInfo}
                  />
                ) : (
                  <InputBox
                    labelText="Firmware File"
                    value={userInfo.fileName}
                    disabled={firmwareId ? true : false}
                    id="fileName"
                    type="file"
                    required={true}
                    info={userInfo}
                    updateInfo={setUserInfo}
                  />
                )}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <InputBox
                  labelText="File Hash"
                  value={userInfo.fileHash}
                  helperText={
                    <p>
                      To generate file hash, you can use{" "}
                      <a
                        rel="noopener noreferrer"
                        href="https://docs.microsoft.com/en-us/powershell/module/microsoft.powershell.utility/get-filehash?view=powershell-6"
                        target="_blank"
                      >
                        this
                      </a>{" "}
                      tool for Windows or{" "}
                      <a
                        rel="noopener noreferrer"
                        href="https://ss64.com/osx/shasum.html"
                        target="_blank"
                      >
                        this
                      </a>{" "}
                      one for Mac OS
                    </p>
                  }
                  disabled={firmwareId ? true : false}
                  id="fileHash"
                  required={true}
                  info={userInfo}
                  updateInfo={setUserInfo}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Box textAlign="right">
          <Button onClick={() => validateForm()} color="danger">
            {firmwareId ? "Save" : "Submit"}
          </Button>
          <Button
            onClick={() => {
              history.push(`/admin/device-firmware/${id}?name=${userInfo?.name}`);
            }}
          >
            Cancel
          </Button>
        </Box>
      </GridItem>
      <Snackbar
        place="tr"
        color="danger"
        icon={AddAlert}
        message={alertMessage}
        open={toastStatus}
        closeNotification={() => setToastStatus(false)}
        close
      />
      <Loader status={device.loading} />
    </GridContainer>
  );
}
