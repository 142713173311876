import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import moment from "moment-timezone";

import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import AddAlert from "@material-ui/icons/AddAlert";
import Checkbox from "@material-ui/core/Checkbox";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Table from "@material-ui/core/Table";
import List from "@material-ui/icons/List";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";

// core components
import { PAGINATION_DETAILS, PAGE_SIZES } from "../../common/constants";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import Loader from "components/Loader/Loader.js";
import Card from "components/Card/Card.js";
import Filter from "./Filter.js";

import {
  FETCH_READ_ALERT_LOG_DATA_REQUEST,
  DELETE_ALERT_LOG_DATA_REQUEST,
  FETCH_ALERT_LOG_DATA_REQUEST,
  FETCH_FRIENDLY_NAME_REQUEST,
  FETCH_DEVICE_REQUEST,
} from "../../redux/device/actions";
import {
  FETCH_GUESTS_REQUEST
} from "../../redux/guestMap/actions";
import { FETCH_USER_LIST_REQUEST } from "../../redux/users/actions";
import { FETCH_COMPANY_REQUEST } from "../../redux/company/actions";
import { useComposeDispatcher } from "../../common/hooks";

import styles from "assets/jss/material-dashboard-pro-react/views/userStyle.js";
const useStyles = makeStyles(styles);

const columns = [
  {
    id: "residentName",
    label: "Resident Name",
    minWidth: 130,
    sort: false,
  },
  {
    id: "FriendlyNameA",
    label: "Friendly Name",
    minWidth: 130,
    sort: true,
  },
  {
    id: "DevidAlerts",
    label: "Dev Id",
    minWidth: 130,
    sort: true,
  },
  {
    id: "AlertType",
    label: "Alert Type",
    minWidth: 130,
    sort: true,
  },
  {
    id: "Atext",
    label: "Alert Text",
    minWidth: 130,
    sort: true,
  },
  {
    id: "TimestampA",
    label: "Alert Time",
    minWidth: 130,
    type: "date",
    sort: true,
  },
];

export default function AlertLogs() {
  const classes = useStyles();

  const timerToClearSomewhere = useRef(null);

  const userDetails = JSON.parse(localStorage.getItem("user-details"));
  const loggedUserId = userDetails !== null ? userDetails.userId : "";
  const loggedUserRole = userDetails !== null ? userDetails.roles[0].role : "";

  const { device, company, users, guestMapData } = useSelector((state) => ({
    device: state.device,
    company: state.company,
    users: state.users,
    guestMapData: state.guestMap,
  }));

  const [userInfo, setUserInfo] = useState({
    interval: "1 Hr",
    deviceDataModelId: "d36a4373-fbdc-44a3-8c96-4bb920041e40",
    macAddress: "all",
    intervalStartTime: new Date(),
    supplierId: "cd5dc314-6e25-48a8-9d47-9e574b8fe3a0",
  });

  const [displayContainer, setDisplayContainer] = useState(false);
  
  const [guestSensors, setGuestSensors] = useState([]);

  const [toastStatus, setToastStatus] = useState(false);

  const [order, setOrder] = useState("maxValueProvidedOn");

  const [orderBy, setOrderBy] = useState("DESC");

  const [filterData, setFilterData] = useState({});

  const [page, setPage] = useState(PAGINATION_DETAILS.pageNumber);

  const [selected, setSelected] = React.useState([]);

  const [selectedAll, setSelectedAll] = React.useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(PAGINATION_DETAILS.pageSize);

  const [getDeviceList] = useComposeDispatcher(FETCH_DEVICE_REQUEST);

  const [getCompanyList] = useComposeDispatcher(FETCH_COMPANY_REQUEST);

  const [getUserList] = useComposeDispatcher(FETCH_USER_LIST_REQUEST);

  const [checked, setChecked] = React.useState(false);

  const [fetchFriendlyNameData] = useComposeDispatcher(FETCH_FRIENDLY_NAME_REQUEST);

  const [deleteAlertLogData] = useComposeDispatcher(DELETE_ALERT_LOG_DATA_REQUEST);

  const [getAlertLogData] = useComposeDispatcher(FETCH_ALERT_LOG_DATA_REQUEST);

  const [getReadAlertLogData] = useComposeDispatcher(FETCH_READ_ALERT_LOG_DATA_REQUEST);

  const [getGuestsList] = useComposeDispatcher(FETCH_GUESTS_REQUEST);

  useEffect(() => {
    let friendlyNameReqObj = loggedUserRole !== "Guest" ? {userId: loggedUserId, deviceDataModelId: userInfo.deviceDataModelId} : {deviceDataModelId: userInfo.deviceDataModelId};
    fetchFriendlyNameData(friendlyNameReqObj);

    if (loggedUserRole === "Patient") {
      setUserInfo({ ...userInfo, userId: loggedUserId });
    } else if (loggedUserRole !== "Guest") {
      getCompanyList();
    }

    if (loggedUserRole === "Guest") {
      getGuestsList({"deviceDataModelId": "299992ad-6d4a-4020-8501-8a004d45fffc"});
    }

    getUserList({
      role: "Patient",
      pageNumber: 0,
      pageSize: 100,
      sortBy: "firstName",
      sortOrder: "ASC",
    });
    getDeviceList({ status: "Active" });
    getUserList({ role: "Patient" });

    return () => {
      clearInterval(timerToClearSomewhere.current);
    };
  }, []);

  const getDeviceData = () => {
    let dataObj = {
      deviceDataModelId: `${userInfo.deviceId}-as`,
      devicePropertyCodes: null,
      deviceCriteria: [{ key: "FriendlyNameA", operator: "IsSet", value: null},],
    };
    if (userInfo.macAddress !== "all") {
      dataObj.deviceCriteria = [
        { key: "FriendlyNameA", operator: "IsSet", value: null },
        { key: "DevidAlerts", operator: "Equal", value: userInfo.macAddress }
      ];
    }
    const userList = users.userList.users.map((val) => val.userId);
    if (loggedUserRole !== "Guest") {
      dataObj.ownerFilter = {
        users: userInfo.userId !== "all" ? [userInfo.userId] : userList,
      };
    }
    setFilterData(dataObj);
    setSelectedAll(false);
    setPage(0);
    getAlertLogData({
      payload: {
        pageNumber: 0,
        pageSize: rowsPerPage,
        sortBy: order,
        sortOrder: orderBy,
      },
      data: dataObj,
    });
    /* getReadAlertLogData({
      payload: {
        pageNumber: 0,
        pageSize: rowsPerPage,
        sortBy: order,
        sortOrder: orderBy,
      },
      data: {
        ...dataObj, deviceCriteria: [...dataObj.deviceCriteria, {
          key: "AlertFlag",
          operator: "Equal",
          value: 1
        }]
      },
    }); */
    setDisplayContainer(true);
    setChecked(false);
    setSelected([]);
  };

  const createSortHandler = (property) => () => {
    const isAsc = order === `data.${property}.value` && orderBy === "ASC";
    setOrderBy(isAsc ? "DESC" : "ASC");
    setOrder(`data.${property}.value`);
    getAlertLogData({
      payload: {
        pageNumber: 0,
        pageSize: rowsPerPage,
        sortBy: `data.${property}.value`,
        sortOrder: isAsc ? "DESC" : "ASC",
      },
      data: filterData,
    });
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setChecked(false);
    setSelected([]);
    getAlertLogData({
      payload: {
        pageNumber: newPage,
        pageSize: rowsPerPage,
        sortBy: order,
        sortOrder: orderBy,
      },
      data: filterData,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setChecked(false);
    setSelected([]);
    getAlertLogData({
      payload: {
        pageNumber: 0,
        pageSize: +event.target.value, 
        sortBy: order,
        sortBy: orderBy,
        sortOrder: order,
        sortOrder: orderBy
      },
      data: filterData,
    });
  };

  const onSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = device.errorLog.data.map((n) => n.deviceDataId);
      setSelected(newSelecteds);
      setChecked(event.target.checked);
      return;
    }
    setSelected([]);
    setChecked(false);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    newSelected.length === 0 && setChecked(false);
    setSelected(newSelected);
  };

  const isSelected = (deviceDataId) => selected.indexOf(deviceDataId) !== -1;

  /* In case Guest Update, Guest data also should update on Local */
  useEffect(() => {
    const tempGuestSensors = [];
    if (guestMapData.guestsList?.length) {
      const guestSensorsMapped = guestMapData?.guestsList[0]?.data?.Macaddreess?.value;
      if(guestSensorsMapped?.length) {
        if (guestSensorsMapped?.length > 1) {
          tempGuestSensors.push(device?.friendlyNameData[0]);
        }
        for (let j=0; j < device?.friendlyNameData?.length; j++) {
          if (guestSensorsMapped.indexOf(device?.friendlyNameData[j]?.macId) !== -1) {
            tempGuestSensors.push(device?.friendlyNameData[j]);
          }
        }
      }
      setGuestSensors(tempGuestSensors);
    }
    console.log(guestMapData.guestsList, tempGuestSensors, 'guestSensors');
  }, [guestMapData.guestsList, device?.friendlyNameData])

  return (
    <>
      <GridContainer>
        <Filter
          device={device}
          company={company}
          userInfo={userInfo}
          userList={users.userList}
          setUserInfo={setUserInfo}
          getUserList={getUserList}
          getDeviceData={getDeviceData}
          timerToClearSomewhere={timerToClearSomewhere}
          fetchFriendlyNameData={fetchFriendlyNameData}
          guestSensors={guestSensors}
        />
        {displayContainer && (
          <GridItem xs={12}>
            <Card>
              <CardHeader color="danger" icon>
                <CardIcon color="danger">
                  <List />
                </CardIcon>
                <p className={classes.rowCardIconTitle}>Unread Alerts: {device.totalUnreadAlert} Total Alerts: {device.errorLog.count}</p>
              </CardHeader>
              <CardBody>
                {selected.length > 0 && (
                  <Toolbar
                    style={{
                      color: "#fd0808",
                      fontSize: "16px",
                      backgroundColor: lighten("#fd0808", 0.85),
                    }}
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={10}
                        style={{
                          margin: "auto",
                        }}
                      >
                        <Typography
                          className={classes.title}
                          color="#fd0808"
                          variant="subtitle1"
                          component="div"
                        >
                          You have selected all{" "}
                          {selectedAll
                            ? device.errorLog.count
                            : selected.length}{" "}
                          records.{" "}
                          {device.errorLog.count > selected.length && (
                            <Link
                              color="#fd0808"
                              onClick={() => {
                                const currentStatus = selectedAll
                                  ? false
                                  : true;
                                setSelectedAll(currentStatus);
                              }}
                            >
                              {selectedAll ? `Unselect all` : `Select all`}{" "}
                              {device.errorLog.count} records
                            </Link>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          variant="outlined"
                          color="danger"
                          onClick={() => {
                            if (selectedAll) {
                              deleteAlertLogData({
                                deviceDataModelId:
                                  "d36a4373-fbdc-44a3-8c96-4bb920041e40-as",
                                userIds: [userInfo.userId],
                                filter: {
                                  payload: {
                                    pageNumber: page,
                                    pageSize: rowsPerPage,
                                    sortBy: "maxValueProvidedOn",
                                    sortOrder: "DESC",
                                  },
                                  data: filterData,
                                },
                              });
                            } else {
                              deleteAlertLogData({
                                deviceDataModelId:
                                  "d36a4373-fbdc-44a3-8c96-4bb920041e40-as",
                                deviceDataIds: selected,
                                filter: {
                                  payload: {
                                    pageNumber: page,
                                    pageSize: rowsPerPage,
                                    sortBy: "maxValueProvidedOn",
                                    sortOrder: "DESC",
                                  },
                                  data: filterData,
                                },
                              });
                            }
                            setSelected([]);
                          }}
                        >
                          Confirm Delete
                        </Button>
                      </Grid>
                    </Grid>
                  </Toolbar>
                )}
                <TableContainer className={classes.container}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            inputProps={{ "aria-label": "select all" }}
                            indeterminate={
                              selected.length > 0 &&
                              selected.length < device.errorLog.count
                            }
                            checked={checked}
                            onChange={onSelectAllClick}
                          />
                        </TableCell>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              maxWidth: column.minWidth,
                              minWidth: column.minWidth,
                            }}
                          >
                            {column.sort ? (
                              <TableSortLabel
                                style={{
                                  maxWidth: column.minWidth,
                                  minWidth: column.minWidth,
                                }}
                                active={orderBy === column.id}
                                direction={
                                  orderBy === column.id
                                    ? order.toLowerCase()
                                    : "asc"
                                }
                                onClick={createSortHandler(column.id)}
                              >
                                <Typography>{column.label}</Typography>
                              </TableSortLabel>
                            ) : (
                              <Typography>{column.label}</Typography>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {device.errorLog.count === 0 && (
                        <TableRow>
                          <TableCell align="center" colSpan="7">
                            No records found
                          </TableCell>
                        </TableRow>
                      )}
                      {device.errorLog.data.map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index + "" + row.userId}
                          >
                            {columns.map((column, cIndex) => {
                              let value = row[column.id];
                              if (column.type === "date") {
                                const matchedSensor = device?.friendlyNameData?.length && device?.friendlyNameData.find(ele => ele?.macId === row['DevidAlerts']);
                                value = moment.utc(row[column.id]).tz(matchedSensor?.timeZone || "Central Standard Time").format("MMM Do YY, h:mm:ss a");
                                {/* value = moment(row[column.id]).format(
                                  "DD MMM, YYYY hh:mm"
                                ); */}
                              }
                              let errorStyle = "#000000";
                              if (column.id === "errorType") {
                                if (value.toLowerCase().trim() === "w") {
                                  errorStyle = "orange";
                                  value = "Warning";
                                } else if (value.toLowerCase().trim() === "e") {
                                  errorStyle = "red";
                                  value = "Error";
                                } else if (value.toLowerCase().trim() === "l") {
                                  value = "Log";
                                }
                              }
                              if (column.id === "errorText") {
                                if (
                                  row["errorType"].toLowerCase().trim() === "w"
                                ) {
                                  errorStyle = "orange";
                                } else if (
                                  row["errorType"].toLowerCase().trim() === "e"
                                ) {
                                  errorStyle = "red";
                                }
                              }
                              return (
                                <>
                                  {cIndex === 0 && (
                                    <TableCell padding="checkbox">
                                      <Checkbox
                                        onChange={(event) =>
                                          handleClick(event, row.deviceDataId)
                                        }
                                        checked={isSelected(row.deviceDataId)}
                                        inputProps={{
                                          "aria-labelledby": labelId,
                                        }}
                                      />
                                    </TableCell>
                                  )}
                                  <TableCell
                                    style={{
                                      color: errorStyle,
                                    }}
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.id !== "action" &&
                                      column.format &&
                                      typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                </>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={PAGE_SIZES}
                  component="div"
                  count={device.errorLog.count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                {alert}
              </CardBody>
            </Card>
          </GridItem>
        )}
      </GridContainer>
      <Loader status={device.loading || device.loadingData} />
      <Snackbar
        place="tr"
        color="danger"
        icon={AddAlert}
        message={''}
        open={toastStatus}
        closeNotification={() => setToastStatus(false)}
        close
      />
    </>
  );
}
