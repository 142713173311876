import * as service from "../common/service";
import { API_BASE_URL } from "../common/constants";

const loginApi = `${API_BASE_URL}/auth/login`;
const refreshToken = `${API_BASE_URL}/auth/refresh-token?deviceDataModelId=d36a4373-fbdc-44a3-8c96-4bb920041e40`;
const userApi = `${API_BASE_URL}/user/user`;
const userCustomApi = `${API_BASE_URL}/user/user-custom`;
const practiceApi = `${API_BASE_URL}/user/practice-custom`;
const getCustomApi = `${API_BASE_URL}/user/custom-field`;
const passwordApi = `${API_BASE_URL}/user/password`;
const updatePasswordApi = `${API_BASE_URL}/user/updatepassword`;
const updateResetPassword = `${API_BASE_URL}/user/password/reset`;
const inviteEmail = `${API_BASE_URL}/user/invite/`;
const registerUserApi = `${API_BASE_URL}/user/register`;
const resendCodeApi = `${API_BASE_URL}/user/register/send-code`;
const acceptTerms = `${API_BASE_URL}/user/tenant/termsagreement?acceptedTermsOfUseForTenant=true`;
const acceptTermsUser = `${API_BASE_URL}/user/termsagreement?acceptedTermsOfUse=true`;
const activateUserApi = `${API_BASE_URL}/user/activate`;

const usersService = {
  login: (data) => {
    delete data.email;
    return service.post(`${loginApi}`, data, "text");
  },
  getUserList: (payload) => {
    let filter = "";
    if (payload && Object.keys(payload).length > 0) {
      filter = Object.keys(payload).reduce(
        (acc, val, index) =>
          Object.keys(payload).length - 1 === index
            ? `${acc}${val}=${payload[val]}`
            : `${acc}${val}=${payload[val]}&`,
        "?"
      );
    }
    return service.get(`${userApi}${filter}`, {});
  },
  getUserDetailsById: (id) => {
    return service.get(`${userApi}/${id}`, {});
  },
  getUserCustomDataById: (id) => {
    return service.get(`${getCustomApi}/data/${id}`, {});
  },
  getUserCustomFieldById: (id) => {
    return service.get(`${getCustomApi}/data/${id}`, {});
  },
  createPractice: (payload) => {
    return service.postJson(`${practiceApi}`, {
      customData: null,
      practice: payload,
    });
  },
  createUser: (payload) => {
    return service.postJson(`${userApi}`, payload);
  },
  updateUser: (payload) => {
    return service.putJson(`${userCustomApi}`, payload);
  },
  deleteUser: (payload) => {
    return service.deleteJson(`${userApi}?userId=${payload.userId}`);
  },
  resetPassword: (payload) => {
    return service.postJson(
      `${passwordApi}/send-reset-code?email=${payload.emailAddress}`,
      {}
    );
  },
  updateUserPassword: (payload) => {
    return service.putJson(updatePasswordApi, payload);
  },
  resetPasswordUpdate: payload => {
    return service.postJson(updateResetPassword, payload);
  },
  getInviteEmail: payload => {
    return service.get3Version(inviteEmail + payload);
  },
  registerUser: payload => {
    return service.postRegisterForm(registerUserApi, payload);
  }, 
  activateUser: payload => {
    return service.postVersionJson(activateUserApi, payload, 'text', 3);
  },
  resendCode: payload => {
    return service.postMultipart(resendCodeApi, payload);
  },
  acceptTerms: payload => {
    return service.putJson(acceptTerms, payload);
  },
  acceptTermsUser: payload => {
    return service.putJson(acceptTermsUser, payload);
  },
  refreshToken: () => {
    return service.refreshToken(refreshToken);
  }
};

export default usersService;
