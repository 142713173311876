// Options Actions
export const FETCH_OBSERVE_LIST_REQUEST = "FETCH_OBSERVE_LIST_REQUEST";
export const FETCH_OBSERVE_LIST_SUCCESS = "FETCH_OBSERVE_LIST_SUCCESS";
export const FETCH_OBSERVE_LIST_ERROR = "FETCH_OBSERVE_LIST_ERROR";

export const FETCH_INVITES_REQUEST = "FETCH_INVITES_REQUEST";
export const FETCH_INVITES_SUCCESS = "FETCH_INVITES_SUCCESS";
export const FETCH_INVITES_ERROR = "FETCH_INVITES_ERROR";

export const ACTION_INVITES_REQUEST = "ACTION_INVITES_REQUEST";
export const ACTION_INVITES_SUCCESS = "ACTION_INVITES_SUCCESS";
export const ACTION_INVITES_ERROR = "ACTION_INVITES_ERROR";