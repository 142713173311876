import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import SweetAlert from "react-bootstrap-sweetalert";
import Datetime from "react-datetime";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Autocomplete from '@material-ui/lab/Autocomplete';
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import AddAlert from "@material-ui/icons/AddAlert";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Loader from "components/Loader/Loader.js";

import formStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import {
  GET_DEVICE_DETAIL_REQUEST,
  ADD_DEVICE_REQUEST,
  UPDATE_DEVICE_REQUEST,
  DELETE_PROPERTY_SET_REQUEST,
  DELETE_PROPERTY_REQUEST,
  CREATE_DEVICE_DATA_REQUEST
} from "../../redux/device/actions";
import {
  FETCH_PATIENT_DEVICE_REQUEST
} from "../../redux/patientDevice/actions";
import { useComposeDispatcher } from "../../common/hooks";
import { THRESHOLD_DEFAULT_VALUES, PARAMS_DEFAULT_VALUES } from "../../common/constants";

const tenantId = "ATS-DEV";

const styles = () => ({
  labelFocused: {
    color: "#E73F3B !important",
  },
  inputFocused: {
    "&:before": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "&:after": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "& input": {
      borderBottom: "#E73F3B !important",
    },
  },
  inputRoot: {
    color: "red",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
});

const InputBox = withStyles(styles)((props) => {
  const {
    labelText,
    helperText,
    classes,
    value,
    id,
    info,
    updateInfo,
    type,
    required,
  } = props;
  return (
    <TextField
      label={labelText}
      helperText={helperText}
      fullWidth
      required={required ? required : false}
      type={type ? type : "text"}
      margin="normal"
      color="primary"
      value={value}
      onChange={(e) => {
        let obj = {};
        obj[id] = e.target.value;
        updateInfo({ ...info, ...obj });
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          focused: classes.labelFocused,
        },
      }}
      InputProps={{
        classes: {
          root: classes.root,
          focused: classes.inputFocused,
        },
      }}
    />
  );
});

const SelectBox = withStyles(styles)((props) => {
  const {
    onChange,
    labelText,
    classes,
    value,
    disabled,
    options,
    required,
  } = props;
  return (
    <TextField
      select
      fullWidth
      label={labelText}
      value={value}
      disabled={disabled}
      required={required ? required : false}
      margin="normal"
      color="primary"
      onChange={onChange}
      InputLabelProps={{
        shrink: true,
        classes: {
          focused: classes.labelFocused,
        },
      }}
      InputProps={{
        classes: {
          root: classes.root,
          focused: classes.inputFocused,
        },
      }}
    >
      {options}
    </TextField>
  );
});

const columns = [
  { id: "name", label: "Property Name", minWidth: 110, sort: false },
  {
    id: "description",
    label: "Property Description",
    minWidth: 130,
    sort: false,
  },
  { id: "propertyCode", label: "Property Code", minWidth: 130, sort: false },
  { id: "dataType", label: "Data Type", minWidth: 130, sort: false },
  {
    id: "action",
    label: "Action",
    minWidth: 170,
    align: "center",
    sort: false,
    format: (value) => value.toFixed(2),
  },
];

const useStyles = makeStyles(formStyle);

export default function AddUser() {
  const history = useHistory();
  const { id } = useParams();
  const { device, patientDevice } = useSelector((state) => ({
    device: state.device,
    patientDevice: state.patientDevice
  }));
  const userRole = loggedUserRole;
  const userDetails = JSON.parse(localStorage.getItem("user-details"));
  const loggedUserRole = userDetails !== null ? userDetails.roles[0].role : "";
  const [refreshSection, setRefreshSection] = React.useState(false);
  const [alert, setAlert] = React.useState(null);

  const [userInfo, setUserInfo] = React.useState({});
  const [propertyInfo, setPropertyInfo] = React.useState({});

  const [alertMessage, setAlertMessage] = React.useState("");
  const [toastStatus, setToastStatus] = React.useState(false);
  const [refreshData, setRefreshData] = React.useState(Date.now());
  const [refreshPropertyData, setRefreshPropertyData] = React.useState(
    Date.now()
  );
  const [refreshDeviceData, setRefreshDeviceData] = React.useState(
    Date.now()
  );
  const [getDeviceDetailById] = useComposeDispatcher(GET_DEVICE_DETAIL_REQUEST);
  const [addDevice] = useComposeDispatcher(ADD_DEVICE_REQUEST);

  const [updateDevice] = useComposeDispatcher(UPDATE_DEVICE_REQUEST);

  const [deleteProperty] = useComposeDispatcher(DELETE_PROPERTY_REQUEST);

  const [deletePropertySet] = useComposeDispatcher(DELETE_PROPERTY_SET_REQUEST);

  const [getPatientDeviceList] = useComposeDispatcher(FETCH_PATIENT_DEVICE_REQUEST);

  const [createDeviceData] = useComposeDispatcher(CREATE_DEVICE_DATA_REQUEST);

  const classes = useStyles();

  useEffect(() => {
    if (id) {
      getDeviceDetailById(id);
      setRefreshSection(true);
    }
    setUserInfo({});
    setRefreshData(false);
    getPatientDeviceList({
      sortBy: 'user.firstName',
      sortOrder: 'ASC'
    });
  }, []);

  if (id && device.deviceDetails.deviceId && !userInfo?.deviceId) {
    if (
      device.deviceDetails.maxDataAgeDays &&
      device.deviceDetails.maxDataAgeDays > 0
    ) {
      device.deviceDetails.customDays = device.deviceDetails.maxDataAgeDays;
      device.deviceDetails.maxDataAgeDays = "custom";
    }
    setUserInfo(device.deviceDetails);
    setRefreshData(Date.now());
    setRefreshSection(true);
    setRefreshPropertyData(Date.now());
    setRefreshSection(false);
  }

  useEffect(() => {
    if (device.success && device.deviceDetails.deviceId) {
      history.push(`/admin/edit-device/${device.deviceDetails.deviceId}`);
    }
  }, [device.success, device.deviceDetails.deviceId])

  useEffect(() => {
    if (device.success && !refreshSection) {
      setRefreshSection(true);
      history.push(`/admin/edit-device/${device.deviceDetails.deviceId}`);
    }
  }, [device.success, refreshSection]);

  let validateForm = () => {
    setToastStatus(false);
    if (
      !userInfo?.name ||
      !userInfo?.status ||
      !userInfo?.sequentialFirmwareUpgrade
    ) {
      setAlertMessage("Fields mark with asterisk are required");
      setToastStatus(true);
    } else {
      if (id) {
        updateDevice({
          deviceId: id,
          description: userInfo.description,
          maxDataAgeDays:
            userInfo.maxDataAgeDays === "custom"
              ? userInfo.customDays
              : userInfo.maxDataAgeDays,
          name: userInfo.name,
          sequentialFirmwareUpgrade: userInfo.sequentialFirmwareUpgrade,
          status: userInfo.status,
          tenantId,
        });
      } else {
        addDevice({
          description: userInfo.description,
          maxDataAgeDays:
            userInfo.maxDataAgeDays === "custom"
              ? userInfo.customDays
              : userInfo.maxDataAgeDays,
          name: userInfo.name,
          sequentialFirmwareUpgrade: userInfo.sequentialFirmwareUpgrade,
          status: userInfo.status,
          tenantId,
        });
      }
      setRefreshSection(true);
    }
  };

  const warningWithConfirmMessage = ({ onConfirm, title, linkedForm }) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => onConfirm()}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        You will not be able to recover <b>{title}</b> {linkedForm} data!
      </SweetAlert>
    );
  };

  return (
    <Box key={refreshData}>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="danger" icon>
              <CardIcon color="danger">
                <Person />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{id ? userInfo?.name : "Device Information"}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {!id && <GridItem xs={12} sm={12} md={4}>
                  <InputBox
                    labelText="Device name"
                    value={userInfo.name}
                    id="name"
                    required={true}
                    info={userInfo}
                    updateInfo={setUserInfo}
                  />
                </GridItem>
                }
                <GridItem xs={12} sm={12} md={4}>
                  <InputBox
                    labelText="Device Description"
                    id="description"
                    value={userInfo.description}
                    info={userInfo}
                    updateInfo={setUserInfo}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <SelectBox
                    id="status"
                    labelText="Status"
                    required={true}
                    value={userInfo?.status}
                    onChange={(e) => {
                      let obj = {};
                      obj["status"] = e.target.value;
                      setUserInfo({ ...userInfo, ...obj });
                    }}
                    options={["Active", "Inactive"].map((val) => (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                        key={val}
                        value={val}
                      >
                        {val}
                      </MenuItem>
                    ))}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <SelectBox
                    id="maxDataAgeDays"
                    labelText="Delete Device Data After (Days)"
                    value={userInfo?.maxDataAgeDays}
                    onChange={(e) => {
                      let obj = {};
                      obj["maxDataAgeDays"] = e.target.value;
                      setUserInfo({ ...userInfo, ...obj });
                    }}
                    options={[
                      { key: 0, value: "Default" },
                      { key: -1, value: "Never" },
                      { key: "custom", value: "Custom" },
                    ].map((val) => (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                        key={val.key}
                        value={val.key}
                      >
                        {val.value}
                      </MenuItem>
                    ))}
                  />
                </GridItem>
                {userInfo?.maxDataAgeDays === "custom" && (
                  <GridItem xs={12} sm={12} md={4}>
                    <InputBox
                      labelText="Days"
                      id="customDays"
                      value={userInfo.customDays}
                      info={userInfo}
                      updateInfo={setUserInfo}
                    />
                  </GridItem>
                )}
                <GridItem xs={12} sm={12} md={4}>
                  <SelectBox
                    id="sequentialFirmwareUpgrade"
                    labelText="Allow Sequential Firmware Upgrade"
                    required={true}
                    value={userInfo?.sequentialFirmwareUpgrade}
                    onChange={(e) => {
                      let obj = {};
                      obj["sequentialFirmwareUpgrade"] = e.target.value;
                      setUserInfo({ ...userInfo, ...obj });
                    }}
                    options={[
                      { key: "true", value: "YES" },
                      { key: "false", value: "NO" },
                    ].map((val) => (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                        key={val.key}
                        value={val.key}
                      >
                        {val.value}
                      </MenuItem>
                    ))}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          {userRole !== "PracticeUser" && (
            <Box marginBottom="20px">
              <Button
                onClick={() => {
                  validateForm();
                }}
                color="danger"
              >
                {id ? "Save" : "Submit"}
              </Button>
              <Button
                onClick={() => {
                  history.push(`/admin/device`);
                }}
              >
                Cancel
              </Button>
            </Box>
          )}
        </GridItem>
        <Snackbar
          place="tr"
          color="danger"
          icon={AddAlert}
          message={alertMessage}
          open={toastStatus}
          closeNotification={() => setToastStatus(false)}
          close
        />
        <Loader status={device.loading} />
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Box
            bgcolor="#ffffff"
            display="flex"
            padding={1}
            borderRadius="5px"
            marginBottom={2}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Box display="flex">
                  <Box flexGrow={1} marginTop={2}>
                    <Typography>Device Property Sets/Properties</Typography>
                    <p style={{ fontSize: "10px" }}>
                      Note: Property set is mandatory before creating property.
                    </p>
                  </Box>
                  <Button
                    disabled={id ? false : true}
                    color="danger"
                    size="sm"
                    onClick={() => {
                      history.push(`/admin/device-property/create/${id}`);
                    }}
                    className={classes.actionButton}
                  >
                    Create Property
                  </Button>
                  <Button
                    disabled={id ? false : true}
                    color="danger"
                    size="sm"
                    onClick={() => {
                      history.push(`/admin/device-property-set/create/${id}`);
                    }}
                    className={classes.actionButton}
                  >
                    Create Property Set
                  </Button>
                </Box>
              </GridItem>
            </GridContainer>
          </Box>
        </GridItem>
        <GridItem xs={12} key={refreshPropertyData}>
          {device.deviceDetails.properties?.map((val) => {
            return (
              <Accordion key={val.name}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-label="Expand"
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    width="100%"
                  >
                    <Typography>
                      {val.name}
                      {", "}
                      {val.collectionMethod === "CollectedFromDevice"
                        ? `Collected From Device`
                        : val.collectionMethod}
                    </Typography>
                  </Box>
                  <Box
                    flexShrink={1}
                    color="#4caf50"
                    onClick={(event) => {
                      event.stopPropagation();
                      history.push(
                        `/admin/device-property-set/edit/${id}/${val.devicePropertySetId}`
                      );
                    }}
                    onFocus={(event) => event.stopPropagation()}
                  >
                    <Edit />
                  </Box>
                  <Box
                    flexShrink={0}
                    color="#f44336"
                    onClick={(event) => {
                      event.stopPropagation();
                      warningWithConfirmMessage({
                        onConfirm: () => {
                          deletePropertySet({
                            devicePropertySetId: val.devicePropertySetId,
                            deviceId: id,
                          });
                          setAlert(null);
                        },
                        title: val.name,
                        linkedForm: "property set",
                      });
                    }}
                    onFocus={(event) => event.stopPropagation()}
                  >
                    <Close />
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => {
                            if (
                              userRole === "PracticeUser" &&
                              column.id === "action"
                            ) {
                              return false;
                            }
                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                  maxWidth: column.minWidth,
                                  minWidth: column.minWidth,
                                }}
                              >
                                <Typography>{column.label}</Typography>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {val.deviceProperties.length > 0 &&
                          val.deviceProperties.map((row) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.userId}
                              >
                                {columns.map((column) => {
                                  let value = row[column.id];
                                  if (column.type === "date") {
                                    value = moment(row[column.id]).format(
                                      "DD MMM, YYYY hh:mm"
                                    );
                                  }
                                  return (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                    >
                                      {column.id !== "action" &&
                                        column.id !== "role" &&
                                        column.format &&
                                        typeof value === "number"
                                        ? column.format(value)
                                        : value}
                                      {column.id === "action" && (
                                        <>
                                          <Button
                                            color="success"
                                            size="sm"
                                            onClick={() => {
                                              history.push(
                                                `/admin/device-property/edit/${id}/${row.devicePropertyId}`
                                              );
                                            }}
                                            className={classes.actionButton}
                                          >
                                            <Edit />
                                          </Button>
                                          <Button
                                            color="danger"
                                            size="sm"
                                            onClick={() => {
                                              warningWithConfirmMessage({
                                                onConfirm: () => {
                                                  deleteProperty({
                                                    devicePropertyId:
                                                      row.devicePropertyId,
                                                    deviceId: id,
                                                  });
                                                  setAlert(null);
                                                },
                                                title: row.name,
                                                linkedForm: "property",
                                              });
                                            }}
                                            className={classes.actionButton}
                                          >
                                            <Close />
                                          </Button>
                                        </>
                                      )}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })}
                        {val.deviceProperties.length === 0 && (
                          <TableRow>
                            <TableCell align="center" colSpan="5">
                              No records found
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </GridItem>
        {
          id &&
          <GridItem xs={12}>
            <Box
              bgcolor="#ffffff"
              display="flex"
              marginTop={2}
              padding={1}
              borderRadius="5px"
            >
              <GridContainer key={refreshDeviceData}>
                <GridItem xs={12} sm={12} md={12}>
                  <Box display="flex">
                    <Box flexGrow={1} marginTop={2}>
                      <Typography>Update Device Data</Typography>
                    </Box>
                  </Box>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <SelectBox
                    labelText="Select property set to edit"
                    required={true}
                    value={propertyInfo?.propertySet}
                    onChange={(e) => {
                      let obj = {};
                      const selectedObj = device.deviceDetails.properties?.find(val => (
                        val.devicePropertySetId === e.target.value
                      ));
                      if (selectedObj.name.toLocaleLowerCase() === 'thresholds') {
                        obj = { ...obj, ...THRESHOLD_DEFAULT_VALUES }
                      }
                      if (selectedObj.name.toLocaleLowerCase() === 'params') {
                        obj = { ...obj, ...PARAMS_DEFAULT_VALUES }
                      }
                      obj["propertySet"] = e.target.value;
                      setPropertyInfo(obj);
                      setRefreshDeviceData(Date.now());
                    }}
                    options={
                      device.deviceDetails.properties?.filter(val => (
                        val.collectionMethod === 'Specified'
                      )).map((val) => (
                        <MenuItem
                          classes={{ root: classes.selectMenuItem }}
                          key={val.devicePropertySetId}
                          value={val.devicePropertySetId}
                        >
                          {val.name}
                        </MenuItem>
                      ))}
                  />
                </GridItem>
                {
                  device.deviceDetails.properties?.find(val => (
                    val.devicePropertySetId === propertyInfo?.propertySet
                  ))?.deviceProperties.map(val => {
                    return (
                      <GridItem xs={12} sm={12} md={3} key={val.devicePropertySetId}>
                        {
                          (
                            val.propertyCode === 'Devid' ||
                            val.propertyCode === 'DevidThresholds' ||
                            val.propertyCode === 'DevidParams'
                          ) &&
                          <Box pt={2}>
                            <Autocomplete
                              options={patientDevice.patientDeviceList}
                              classes={{
                                option: classes.option,
                              }}
                              value={propertyInfo[val.propertyCode]}
                              autoHighlight
                              getOptionLabel={(option) => option.user.fullName}
                              onChange={(e, newValue) => {
                                let obj = {};
                                obj[val.propertyCode] = newValue.value;
                                setPropertyInfo({ ...propertyInfo, ...obj });
                              }}
                              renderOption={(option) => (
                                <React.Fragment>
                                  {option.user.fullName} ({option.value})
                                </React.Fragment>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={val.name}
                                  required={val.required}
                                  InputLabelProps={{
                                    shrink: true,
                                    classes: {
                                      focused: classes.labelFocused,
                                    },
                                  }}
                                />
                              )}
                            />
                          </Box>
                        }
                        {
                          (
                            val.dataType === "Text" &&
                            val.propertyCode !== 'Devid' &&
                            val.propertyCode !== 'DevidThresholds' &&
                            val.propertyCode !== 'DevidParams'
                          ) &&
                          <InputBox
                            labelText={val.name}
                            value={propertyInfo[val.propertyCode]}
                            id={val.propertyCode}
                            required={val.required}
                            info={propertyInfo}
                            updateInfo={setPropertyInfo}
                          />
                        }
                        {
                          val.dataType === "Number" &&
                          <InputBox
                            labelText={val.name}
                            value={propertyInfo[val.propertyCode]}
                            id={val.propertyCode}
                            required={val.required}
                            type="number"
                            info={propertyInfo}
                            updateInfo={setPropertyInfo}
                          />
                        }
                        {
                          val.dataType === "Boolean" &&
                          <SelectBox
                            labelText={val.name}
                            required={val.required}
                            value={propertyInfo[val.propertyCode]}
                            onChange={(e) => {
                              let obj = {};
                              obj[val.propertyCode] = e.target.value;
                              setPropertyInfo({ ...propertyInfo, ...obj });
                            }}
                            options={
                              [
                                { key: true, title: "Yes" },
                                { key: false, title: "No" },
                              ].map((val) => (
                                <MenuItem
                                  classes={{ root: classes.selectMenuItem }}
                                  value={val.key}
                                >
                                  {val.title}
                                </MenuItem>
                              ))}
                          />
                        }
                        {
                          val.dataType === "Enumeration" &&
                          <SelectBox
                            labelText={val.name}
                            required={val.required}
                            value={propertyInfo[val.propertyCode]}
                            onChange={(e) => {
                              let obj = {};
                              obj[val.propertyCode] = e.target.value;
                              setPropertyInfo({ ...propertyInfo, ...obj });
                            }}
                            options={
                              val?.additionalData.map((val, index) => (
                                <MenuItem
                                  classes={{ root: classes.selectMenuItem }}
                                  key={index}
                                  value={val}
                                >
                                  {val}
                                </MenuItem>
                              ))}
                          />
                        }
                        {
                          val.dataType === "DateAndTime" &&
                          <>
                            <InputLabel className={classes.datePickerLabel}>
                              {val.name}
                            </InputLabel>
                            <FormControl fullWidth>
                              <Datetime
                                className={classes.datePicker}
                                value={userInfo?.dateOfBirth}
                                onChange={(val) => {
                                  let obj = {};
                                  obj[val.propertyCode] = moment(val).format("YYYY-MM-DD");
                                  setPropertyInfo({ ...propertyInfo, ...obj });
                                }}
                                closeOnSelect={true}
                              />
                            </FormControl>
                          </>
                        }
                      </GridItem>
                    )
                  })
                }
                <GridItem xs={12}>
                  <Button
                    onClick={() => {
                      if (!propertyInfo?.propertySet) {
                        setAlertMessage("Property set is required");
                        setToastStatus(true);
                      } else {
                        const selectedProperties = device.deviceDetails.properties?.find(val => (
                          val.devicePropertySetId === propertyInfo?.propertySet
                        ))?.deviceProperties;
                        const emptyFields = selectedProperties.filter(val => {
                          if (val?.dataType !== 'Boolean') {
                            if (val.required === true && !propertyInfo[val?.propertyCode]) {
                              return val
                            }
                          } else {
                            if (val.required === true && propertyInfo[val?.propertyCode] === undefined) {
                              return val
                            }
                          }
                        })
                        if (emptyFields.length > 0) {
                          setAlertMessage("Fields mark with asterisk are required");
                          setToastStatus(true);
                        } else {
                          const devicePropertySetId = propertyInfo.propertySet;
                          delete propertyInfo.propertySet;
                          let finalObj = {
                            devicePropertySetId,
                            deviceId: id,
                            data: propertyInfo
                          }
                          createDeviceData(finalObj);
                        }
                      }
                    }}
                    color="danger"
                  >
                    Update Data
                  </Button>
                </GridItem>
              </GridContainer>
            </Box>
          </GridItem>
        }
      </GridContainer>
      {alert}
    </Box>
  );
}
