import { ofType } from "redux-observable";
import { mergeMap, map, catchError } from "rxjs/operators";

import { dispatch } from "../../common/helpers";
import { customFieldService } from "../../services";

import {
  FETCH_CUSTOM_FIELD_REQUEST,
  FETCH_CUSTOM_FIELD_SUCCESS,
  FETCH_CUSTOM_FIELD_ERROR,
  FETCH_SUPPLIER_CUSTOM_FIELD_REQUEST,
  FETCH_SUPPLIER_CUSTOM_FIELD_SUCCESS,
  FETCH_SUPPLIER_CUSTOM_FIELD_ERROR
} from "./actions";

export const getCustomFieldList = (action$) => {
  return action$.pipe(
    ofType(FETCH_CUSTOM_FIELD_REQUEST),
    mergeMap(({ payload }) => {
      return customFieldService.getCustomFieldList(payload).pipe(
        map((response) => {
          return dispatch(FETCH_CUSTOM_FIELD_SUCCESS, JSON.parse(response));
        }),
        catchError((error) => {
          return dispatch(FETCH_CUSTOM_FIELD_ERROR, error);
        })
      );
    })
  );
};

export const getSupplierCustomField = (action$) => {
  return action$.pipe(
    ofType(FETCH_SUPPLIER_CUSTOM_FIELD_REQUEST),
    mergeMap(({ payload }) => {
      return customFieldService.getCustomFieldList(payload).pipe(
        map((response) => {
          return dispatch(FETCH_SUPPLIER_CUSTOM_FIELD_SUCCESS, JSON.parse(response));
        }),
        catchError((error) => {
          return dispatch(FETCH_SUPPLIER_CUSTOM_FIELD_ERROR, error);
        })
      );
    })
  );
};
