import * as service from "../common/service";
import { API_BASE_URL } from "../common/constants";

const companyApi = `${API_BASE_URL}/user/supplier`;
const CREATE_COMPANY = `${API_BASE_URL}/user/supplier-custom`;
const getCustomApi = `${API_BASE_URL}/user/custom-field`;

const companyService = {
  getCompanyList: (payload) => {
    let filter = "";
    if (payload && Object.keys(payload).length > 0) {
      filter = Object.keys(payload).reduce(
        (acc, val, index) =>
          Object.keys(payload).length - 1 === index
            ? `${acc}${val}=${payload[val]}`
            : `${acc}${val}=${payload[val]}&`,
        "?"
      );
    }
    return service.get(`${companyApi}${filter}`, {});
  },
  getCompanyDetailsById: (id) => {
    return service.get(`${companyApi}/${id}`, {});
  },
  createCompany: (payload) => {
    return service.postJson(`${CREATE_COMPANY}`, payload);
  },
  updateCompany: (payload) => {
    return service.putJson(`${CREATE_COMPANY}`, payload);
  },
  deleteCompany: (payload) => {
    return service.deleteJson(`${companyApi}?supplierId=${payload.supplierId}`);
  },
  getCompanyCustomDataById: (id) => {
    return service.get(`${getCustomApi}/data/${id}`, {});
  },
};

export default companyService;
