import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import Datetime from "react-datetime";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
// @material-ui/icons
import CancelIcon from "@material-ui/icons/Cancel";
import Person from "@material-ui/icons/Person";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import AccessibleIcon from "@material-ui/icons/Accessible";
import AddAlert from "@material-ui/icons/AddAlert";
import countryList from "react-select-country-list";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import Snackbar from "components/Snackbar/Snackbar.js";
import Loader from "components/Loader/Loader.js";

import formStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { FETCH_PRACTICE_REQUEST } from "../../redux/practice/actions";
import {
  FETCH_USER_LIST_REQUEST,
  PATIENT_DETAIL_REQUEST,
  UPDATE_DEVICE_USER_REQUEST,
  CREATE_DEVICE_USER_REQUEST,
  DELETE_RESIDENT_DEVICE_REQUEST,
  DELETE_RESIDENT_DEVICE_ONLY_REQUEST
} from "../../redux/users/actions";
import { FETCH_PATIENT_DEVICE_REQUEST } from "../../redux/patientDevice/actions";
import {
  FETCH_DEVICE_REQUEST,
  FETCH_DEVICE_PROPERTY_REQUEST,
  FETCH_DEVICE_PROPERTY_SET_REQUEST,
} from "../../redux/device/actions";
import { FETCH_COMPANY_REQUEST } from "../../redux/company/actions";
import { FETCH_CUSTOM_FIELD_REQUEST } from "../../redux/customFields/actions";
import { useComposeDispatcher } from "../../common/hooks";

import { CUSTOM_FIELD_DEFAULT_VALUE, GENDER } from "../../common/constants";
import timezones from "../../common/timezones.json";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const tenantId = "ATS-DEV";
const today = moment();
const disableFutureDt = (current) => current.isBefore(today);

const styles = () => ({
  labelFocused: {
    color: "#E73F3B !important",
  },
  inputFocused: {
    "&:before": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "&:after": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "& input": {
      borderBottom: "#E73F3B !important",
    },
  },
  inputRoot: {
    color: "red",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
  deativiatedSensor: {
    background: '#fce5e5'
  }
});

const InputBox = withStyles(styles)((props) => {
  const {
    labelText,
    helperText,
    classes,
    value,
    id,
    info,
    updateInfo,
    type,
    required,
  } = props;
  return (
    <TextField
      label={labelText}
      helperText={helperText}
      fullWidth
      required={required ? required : false}
      type={type ? type : "text"}
      margin="normal"
      color="primary"
      value={value}
      onChange={(e) => {
        let obj = {};
        obj[id] = e.target.value;
        updateInfo({ ...info, ...obj });
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          focused: classes.labelFocused,
        },
      }}
      InputProps={{
        classes: {
          root: classes.root,
          focused: classes.inputFocused,
        },
      }}
    />
  );
});

const DeviceInputBox = withStyles(styles)((props) => {
  const {
    labelText,
    helperText,
    classes,
    value,
    onChange,
    type,
    required,
    disabled = false
  } = props;
  return (
    <TextField
      label={labelText}
      disabled={disabled}
      helperText={helperText}
      fullWidth
      required={required ? required : false}
      type={type ? type : "text"}
      margin="normal"
      color="primary"
      value={value}
      onChange={onChange}
      InputLabelProps={{
        shrink: true,
        classes: {
          focused: classes.labelFocused,
        },
      }}
      InputProps={{
        classes: {
          root: classes.root,
          focused: classes.inputFocused,
        },
      }}
    />
  );
});

const SelectBox = withStyles(styles)((props) => {
  const {
    onChange,
    labelText,
    classes,
    value,
    disabled,
    options,
    required,
  } = props;
  return (
    <TextField
      select
      fullWidth
      label={labelText}
      value={value}
      disabled={disabled}
      required={required ? required : false}
      margin="normal"
      color="primary"
      onChange={onChange}
      InputLabelProps={{
        shrink: true,
        classes: {
          focused: classes.labelFocused,
        },
      }}
      InputProps={{
        classes: {
          root: classes.root,
          focused: classes.inputFocused,
        },
      }}
    >
      {options}
    </TextField>
  );
});

const useStyles = makeStyles(formStyle);

export default function AddUser() {
  const history = useHistory();
  const { id } = useParams();
  const { patientDevice, users, company, customFields, device } = useSelector(
    (state) => ({
      practice: state.practice,
      users: state.users,
      company: state.company,
      customFields: state.customFields,
      device: state.device,
      patientDevice: state.patientDevice,
    })
  );
  let user = users.userDetail.user;
  const userDetails = JSON.parse(localStorage.getItem("user-details"));
  const loggedUserRole = userDetails !== null ? userDetails.roles[0].role : "";
  const userRole = loggedUserRole;

  const [userInfo, setUserInfo] = React.useState({});
  const [alertMessage, setAlertMessage] = React.useState("");
  const [toastStatus, setToastStatus] = React.useState(false);
  const [refreshData, setRefreshData] = React.useState(Date.now());
  const [refreshSection, setRefreshSection] = React.useState(Date.now());
  const [getPatientDeviceList] = useComposeDispatcher(
    FETCH_PATIENT_DEVICE_REQUEST
  );
  const [userDeviceList, setUserDeviceList] = React.useState([]);

  const [alert, setAlert] = React.useState(null);
  const [getCompanyList] = useComposeDispatcher(FETCH_COMPANY_REQUEST);
  const [getUserList] = useComposeDispatcher(FETCH_USER_LIST_REQUEST);
  const [getPracticeList] = useComposeDispatcher(FETCH_PRACTICE_REQUEST);
  const [getPatientDetailsById] = useComposeDispatcher(PATIENT_DETAIL_REQUEST);
  const [getCustomFieldList] = useComposeDispatcher(FETCH_CUSTOM_FIELD_REQUEST);
  const [updateUserAndDevice] = useComposeDispatcher(
    UPDATE_DEVICE_USER_REQUEST
  );
  const [getDeviceList] = useComposeDispatcher(FETCH_DEVICE_REQUEST);
  const [getDevicePropertyList] = useComposeDispatcher(
    FETCH_DEVICE_PROPERTY_REQUEST
  );
  const [createUserAndDevice] = useComposeDispatcher(
    CREATE_DEVICE_USER_REQUEST
  );
  const [getDevicePropertySetList] = useComposeDispatcher(
    FETCH_DEVICE_PROPERTY_SET_REQUEST
  );
  const [deleteResidentDevice] = useComposeDispatcher(
    DELETE_RESIDENT_DEVICE_REQUEST
  );
  const [deleteResidentDeviceOnly] = useComposeDispatcher(
    DELETE_RESIDENT_DEVICE_ONLY_REQUEST
  );

  const classes = useStyles();

  useEffect(() => {
    getPracticeList();
    getCompanyList();
    getDeviceList();
    getCustomFieldList("UserProfile");
    getUserList({ role: "Patient" });
    id && getPatientDetailsById(id);
  }, []);

  if (id && user.firstName && !userInfo.company) {
    user.company = user.roles[0].supplier?.supplierId;
    user.value = user?.device.length > 0 ? user?.device[0]?.value : "";
    user.device =
      user?.device.length > 0 ? user?.device[0].device?.deviceId : "";
    user.linkDeviceData = user.linkedDevices.map((val) => ({
      value: val.value,
      deviceDataId: val.deviceDataId,
    }));
    if (user.linkedDevices.length > 0) {
      getPatientDeviceList({ emailAddressLike: user.emailAddress });
    }
    user.device && getDevicePropertyList(user.device);
    setUserInfo({ ...user, ...user.contactInfo });
    setUserDeviceList([...user.linkedDevices]);
    setRefreshData(Date.now());
  }

  if (customFields.list.length > 0 && Object.keys(userInfo).length === 0) {
    const updatedData = customFields.list.reduce((acc, val) => {
      const defaultValue = CUSTOM_FIELD_DEFAULT_VALUE.find(
        (data) => data.key === val.name
      );
      let obj = [];
      obj[val.fieldId] = defaultValue?.value;
      return { ...acc, ...obj };
    }, {});
    setUserInfo({ ...userInfo, ...updatedData });
  }

  let validateForm = () => {
    setToastStatus(false);
    const deviceArrayData = userDeviceList.filter(
      (val) => !val.value || !val.Devsn || !val.friendlyName || !val.location || !val.deviceId
    );
    const validateCustomData = customFields.list.filter(
      (val) => !userInfo[val.fieldId]
    );
    if (
      !userInfo?.firstName ||
      !userInfo?.lastName ||
      !userInfo?.password ||
      !userInfo?.confirmPassword ||
      !userInfo?.emailAddress ||
      // !userInfo?.password ||
      // !userInfo?.device ||
      !userInfo?.company ||
      // !userInfo?.value ||
      deviceArrayData.length > 0 ||
      validateCustomData.length > 1 ||
      (validateCustomData.length > 0 &&
        validateCustomData[0].name !== "Location")
    ) {
      setAlertMessage("Fields mark with asterisk are required");
      setToastStatus(true);
    } else {
      const emailReg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      const passwordReq = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      if (!emailReg.test(userInfo?.emailAddress)) {
        setAlertMessage("Please enter valid email");
        setToastStatus(true);
      } else if (!passwordReq.test(userInfo?.password)) {
        setAlertMessage("Please enter valid password");
        setToastStatus(true);
      } else if (userInfo?.password !== userInfo?.confirmPassword) {
        setAlertMessage("Entered Password is not matching");
        setToastStatus(true);
      } else {
        const customFieldData = customFields.list.map((val) => {
          return {
            fieldData: userInfo[val.fieldId],
            field: val,
          };
        });
        const contactInfo = {
          streetAddress1: userInfo.streetAddress1,
          streetAddress2: userInfo.streetAddress2,
          city: userInfo.city,
          state: userInfo.state,
          country: userInfo.country,
          zipcode: userInfo.zipcode,
          primaryPhone: userInfo.primaryPhone,
          secondaryPhone: userInfo.secondaryPhone,
        };

        const userObject = {
          password: userInfo.password,
          user: {
            firstName: userInfo.firstName,
            middleName: userInfo.middleName,
            lastName: userInfo.lastName,
            emailAddress: userInfo.emailAddress,
            password: userInfo.password,
            patientId: userInfo.patientId,
            gender: userInfo.gender,
            height: userInfo.height,
            weight: userInfo.weight,
            dateOfBirth: userInfo.dateOfBirth,
            contactInfo,
            roles: [
              { role: "Patient", supplier: { supplierId: userInfo.company } },
            ],
            tenant: { tenantId },
          },
          customData: customFieldData,
        };
        const selectedCompany = company.companyList.find(
          (val) => val.supplierId === userInfo.company
        );
        createUserAndDevice({
          userObject,
          userDeviceList,
          companyList: company.companyList,
        });
      }
    }
  };

  let validateUpdateForm = () => {
    setToastStatus(false);
    const deviceArrayData = userDeviceList.filter(
      (val) => !val.value || !val.Devsn || !val.friendlyName || !val.location || !val.deviceId
    );
    const validateCustomData = customFields.list.filter(
      (val) => {
        if (val.name.toLowerCase() !== 'plot_range_default' && val.name.toLowerCase() !== 'change password') {
          return !userInfo[val.fieldId];
        }
      }
    );
    if (
      !userInfo?.firstName ||
      !userInfo?.lastName ||
      !userInfo?.emailAddress ||
      // !userInfo?.device ||
      !userInfo?.company ||
      // !userInfo?.value ||
      deviceArrayData.length > 0 ||
      validateCustomData.length > 1 ||
      (validateCustomData.length > 0 &&
        validateCustomData[0].name !== "Location")
    ) {
      setAlertMessage("Fields mark with asterisk are required");
      setToastStatus(true);
    } else {
      const emailReg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      if (!emailReg.test(userInfo?.emailAddress)) {
        setAlertMessage("Please enter valid email");
        setToastStatus(true);
      } else {
        const customData = users.userDetail.custom;
        const customFieldData = customFields.list.reduce((acc, val) => {
          const linkedField = customData.find(
            (data) => data.field.fieldId === val.fieldId
          );
          if (linkedField?.fieldDataId) {
            return [
              ...acc,
              {
                fieldDataId: linkedField?.fieldDataId
                  ? linkedField.fieldDataId
                  : "",
                fieldData: userInfo[val.fieldId] ? userInfo[val.fieldId] : "",
                field: val,
              },
            ];
          } else {
            return [
              ...acc,
              {
                fieldData: userInfo[val.fieldId]
                  ? userInfo[val.fieldId]
                  : "blank",
                field: val,
              },
            ];
          }
        }, []);
        const contactInfo = {
          streetAddress1: userInfo.streetAddress1,
          streetAddress2: userInfo.streetAddress2,
          city: userInfo.city,
          state: userInfo.state,
          country: userInfo.country,
          zipcode: userInfo.zipcode,
          primaryPhone: userInfo.primaryPhone,
          secondaryPhone: userInfo.secondaryPhone,
        };

        const userObject = {
          user: {
            userId: userInfo.userId,
            firstName: userInfo.firstName,
            middleName: userInfo.middleName,
            lastName: userInfo.lastName,
            emailAddress: userInfo.emailAddress,
            patientId: userInfo.patientId,
            gender: userInfo.gender,
            height: userInfo.height,
            weight: userInfo.weight,
            dateOfBirth: userInfo.dateOfBirth,
            contactInfo,
            roles: userInfo?.roles,
            tenant: { tenantId },
          },
          customData: customFieldData,
        };

        const deviceInfo = patientDevice.patientDeviceList.map((data) => ({
          patientDeviceId: data.patientDeviceId,
          value: userInfo.value,
        }));

        updateUserAndDevice({
          userObject,
          deviceInfo,
          deviceId: userInfo.device,
          userDeviceList: userDeviceList.map((v) => {
            const linkedDevice = device.deviceList.find(
              (val) => val.deviceId === v.deviceId
            );
            v.deviceName = linkedDevice.name.split("-")[0];
            return v;
          }),
          companyList: company.companyList,
          patientDeviceList: patientDevice.patientDeviceList,
          linkedDevices: user.linkedDevices,
          userInfo: userInfo,
        });
      }
    }
  };

  if (users.success) {
    history.push(`/admin/resident`);
  }

  const warningWithConfirmMessage = (index, userInfo, previousData) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => {
          let deletedObj = previousData.splice(index, 1);
          if (deletedObj[0]?.deviceDataId !== undefined) {
            deleteResidentDeviceOnly({
              deviceList: device.deviceList,
              deletedObj,
              userInfo,
            });
          }
          setUserDeviceList(previousData);
          setRefreshSection(Date.now());
          hideAlert();
        }}
        onCancel={() => hideAlert()}
        // confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        // confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        // cte
        showConfirm={false}
        showCancel={false}
      >
        You will not be able to recover device data!
        <Box pt={1}><Button color="success" onClick={() => {
          let deletedObj = previousData.splice(index, 1);
          if (deletedObj[0]?.deviceDataId !== undefined) {
            deleteResidentDeviceOnly({
              deviceList: device.deviceList,
              deletedObj,
              userInfo,
            });
          }
          setUserDeviceList(previousData);
          setRefreshSection(Date.now());
          hideAlert();
        }}>Deactivate sensor but keep data</Button></Box>
        <Box pt={1}><Button color="success" onClick={() => {
          let deletedObj = previousData.splice(index, 1);
          if (deletedObj[0]?.deviceDataId !== undefined) {
            deleteResidentDevice({
              deviceList: device.deviceList,
              deletedObj,
              userInfo,
            });
          }
          setUserDeviceList(previousData);
          setRefreshSection(Date.now());
          hideAlert();
        }}>Deactivate sensor and delete data</Button></Box>
        <Box pt={1}><Button color="danger" onClick={() => hideAlert()}>Cancel</Button></Box>
      </SweetAlert>
    );
  };

  const linkCustomFieldWithUser = (companyId) => {
    let alertId = customFields.list.find((v) => v.name === "Enable Alerts");
    let linkedCompany = company.companyList.find(
      (val) => val.supplierId === companyId
    );
    let alertValue = [];
    if (linkedCompany.customData.length > 0) {
      let linkedValue = linkedCompany.customData.find(
        (v) => v.field.name === "enableAlert"
      );
      alertValue = linkedValue ? linkedValue.fieldData : [];
    }
    if (alertId) {
      userInfo[alertId.fieldId] = alertValue;
      userInfo["company"] = companyId;
      setUserInfo(userInfo);
      setRefreshSection(Date.now());
    }
  };

  const hideAlert = () => {
    setAlert(null);
  };

  return (
    <GridContainer key={refreshData}>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="danger" icon>
            <CardIcon color="danger">
              <Person />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>General Information</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <InputBox
                  labelText="First name"
                  value={userInfo.firstName}
                  id="firstName"
                  required={true}
                  info={userInfo}
                  updateInfo={setUserInfo}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <InputBox
                  labelText="Middle name"
                  id="middleName"
                  value={userInfo.middleName}
                  info={userInfo}
                  updateInfo={setUserInfo}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <InputBox
                  labelText="Last name"
                  id="lastName"
                  value={userInfo.lastName}
                  required={true}
                  info={userInfo}
                  updateInfo={setUserInfo}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <InputBox
                  labelText="Email"
                  id="emailAddress"
                  type="email"
                  value={userInfo.emailAddress}
                  required={true}
                  info={userInfo}
                  updateInfo={setUserInfo}
                />
              </GridItem>
              {!id && (
                <>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputBox
                      labelText="Password"
                      helperText="Please enter password which contain min 8 letter, at least a symbol, upper and lower case letters and a number"
                      id="password"
                      type="password"
                      value={userInfo.password}
                      required={true}
                      info={userInfo}
                      updateInfo={setUserInfo}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputBox
                      labelText="Confirm password"
                      id="confirmPassword"
                      type="password"
                      value={userInfo.confirmPassword}
                      required={true}
                      info={userInfo}
                      updateInfo={setUserInfo}
                    />
                  </GridItem>
                </>
              )}
              <GridItem xs={12} sm={12} md={4}>
                <SelectBox
                  id="company"
                  labelText="Company"
                  value={userInfo?.company}
                  info={userInfo}
                  required={true}
                  onChange={(e) => {
                    let obj = {};
                    obj["company"] = e.target.value;
                    setUserInfo({ ...userInfo, ...obj });
                    linkCustomFieldWithUser(e.target.value);
                  }}
                  updateInfo={setUserInfo}
                  options={company.companyList.map((val) => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      key={val.supplierId}
                      value={val.supplierId}
                    >
                      {val.name}
                    </MenuItem>
                  ))}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <Card>
          <CardHeader color="danger" icon>
            <CardIcon color="danger">
              <Person />
            </CardIcon>
            <Box className={classes.cardIconTitle}>
              <Box style={{ width: "300px" }}>
                <h4 className={classes.cardIconTitle}>Device Information</h4>
              </Box>
              <Button
                style={{ float: "right" }}
                color="primary"
                onClick={() => {
                  setUserDeviceList([
                    ...userDeviceList,
                    {
                      deviceId: "",
                      value: "",
                      Devsn: "",
                      friendlyName: "",
                      location: "",
                    },
                  ]);
                }}
              >
                Add Device
              </Button>
            </Box>
          </CardHeader>
          <CardBody key={refreshSection}>
            {userDeviceList.map((value, index) => {
              const deactivateSensor = value.active && value.active.toLowerCase() === 'no' ? '#fce5e5' : '#ffffff';
              return (
                <GridContainer style={{ background: deactivateSensor }}>
                  <GridItem xs={12} sm={12} md={2}>
                    <DeviceInputBox
                      labelText="DevID (Mac Add.)"
                      id="value"
                      value={value.value}
                      required={true}
                      disabled={value.active && value.active.toLowerCase() === 'no' ? true : false}
                      onChange={(e) => {
                        userDeviceList[index].value = e.target.value;
                        setUserDeviceList([...userDeviceList]);
                        // setRefreshSection(Date.now());
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <SelectBox
                      id="device"
                      disabled={value.active && value.active.toLowerCase() === 'no' ? true : false}
                      labelText="Device"
                      required={true}
                      value={userDeviceList[index].deviceId}
                      onChange={(e) => {
                        // getDevicePropertyList(e.target.value);
                        // getDevicePropertySetList(e.target.value);
                        userDeviceList[
                          index
                        ].deviceName = device.deviceList
                          .find((val) => val.deviceId === e.target.value)
                          ?.name.split("-")[0];
                        userDeviceList[index].deviceId = e.target.value;
                        setUserDeviceList([...userDeviceList]);
                        // setRefreshSection(Date.now());
                      }}
                      options={device.deviceList
                        .reduce((acc, val) => {
                          const currentValue = val.name.split("-")[0];
                          const linkedData = acc.find(
                            (v) => v.name.split("-")[0] === currentValue
                          );
                          if (
                            !linkedData &&
                            val.name.split("-")[1].toLowerCase() === "identity"
                          ) {
                            return [...acc, val];
                          }
                          return acc;
                        }, [])
                        .map((val) => (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                            key={val.deviceId}
                            value={val.deviceId}
                          >
                            {val.name.split("-")[0]}
                          </MenuItem>
                        ))}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <DeviceInputBox
                      labelText="Serial Number"
                      id="Devsn"
                      disabled={value.active && value.active.toLowerCase() === 'no' ? true : false}
                      value={userDeviceList[index].Devsn}
                      required={true}
                      info={userInfo}
                      onChange={(e) => {
                        userDeviceList[index].Devsn = e.target.value;
                        setUserDeviceList([...userDeviceList]);
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <DeviceInputBox
                      labelText="Friendly Name"
                      id="friendlyName"
                      value={userDeviceList[index].friendlyName}
                      required={true}
                      disabled={value.active && value.active.toLowerCase() === 'no' ? true : false}
                      info={userInfo}
                      onChange={(e) => {
                        userDeviceList[index].friendlyName = e.target.value;
                        setUserDeviceList([...userDeviceList]);
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <DeviceInputBox
                      labelText="Location"
                      id="location"
                      value={value.location}
                      required={true}
                      disabled={value.active && value.active.toLowerCase() === 'no' ? true : false}
                      info={userInfo}
                      onChange={(e) => {
                        userDeviceList[index].location = e.target.value;
                        setUserDeviceList([...userDeviceList]);
                      }}
                    />
                  </GridItem>
                  {value.active !== 'No' && <GridItem xs={12} sm={12} md={2}>
                    {userDeviceList.length > 0 && (
                      <Box p={1} pt={4}>
                        <CancelIcon
                          onClick={() => {
                            let previousData = userDeviceList;
                            warningWithConfirmMessage(
                              index,
                              userInfo,
                              previousData
                            );
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </Box>
                    )}
                  </GridItem>}
                </GridContainer>
              );
            })}
          </CardBody>
        </Card>
        <Card>
          <CardHeader color="danger" icon>
            <CardIcon color="danger">
              <AccessibleIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Personal Information</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <InputBox
                  labelText="PATIENT ID"
                  id="patientId"
                  value={userInfo?.patientId}
                  info={userInfo}
                  updateInfo={setUserInfo}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <InputLabel className={classes.datePickerLabel}>
                  DATE OF BIRTH
                </InputLabel>
                <FormControl fullWidth>
                  <Datetime
                    isValidDate={disableFutureDt}
                    className={classes.datePicker}
                    value={userInfo?.dateOfBirth}
                    onChange={(val) => {
                      setUserInfo({
                        ...userInfo,
                        dateOfBirth: moment(val).format("YYYY-MM-DD"),
                      });
                    }}
                    closeOnSelect={true}
                    timeFormat={false}
                  />
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <InputBox
                  labelText="HEIGHT (FEET)"
                  id="height"
                  type="number"
                  value={userInfo?.height}
                  info={userInfo}
                  updateInfo={setUserInfo}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <InputBox
                  labelText="WEIGHT (LB)"
                  id="weight"
                  type="number"
                  value={userInfo?.weight}
                  info={userInfo}
                  updateInfo={setUserInfo}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <SelectBox
                  id="gender"
                  labelText="Gender"
                  value={userInfo?.gender}
                  info={userInfo}
                  updateInfo={setUserInfo}
                  onChange={(e) => {
                    let obj = {};
                    obj["gender"] = e.target.value;
                    setUserInfo({ ...userInfo, ...obj });
                  }}
                  options={GENDER.map((val) => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      key={val.toUpperCase()}
                      value={val.toUpperCase()}
                    >
                      {val.toUpperCase()}
                    </MenuItem>
                  ))}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <Card>
          <CardHeader color="danger" icon>
            <CardIcon color="danger">
              <ContactPhoneIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Contact Information</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <InputBox
                  labelText="Street Address 1"
                  id="streetAddress1"
                  value={userInfo?.streetAddress1}
                  info={userInfo}
                  updateInfo={setUserInfo}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <InputBox
                  labelText="Street Address 2"
                  id="streetAddress2"
                  value={userInfo?.streetAddress2}
                  info={userInfo}
                  updateInfo={setUserInfo}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <InputBox
                  labelText="City"
                  id="city"
                  value={userInfo?.city}
                  info={userInfo}
                  updateInfo={setUserInfo}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <InputBox
                  labelText="State"
                  id="state"
                  value={userInfo?.state}
                  info={userInfo}
                  updateInfo={setUserInfo}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Box paddingTop={2}>
                  <Autocomplete
                    fullWidth
                    options={countryList().getData()}
                    className={classes.inputColor}
                    disableCloseOnSelect
                    color="primary"
                    getOptionLabel={(option) =>
                      option.label ? option.label : option
                    }
                    value={
                      userInfo?.country
                        ? countryList()
                          .getData()
                          .find((data) => data.value === userInfo?.country)
                        : null
                    }
                    onChange={(event, val) => {
                      const field = { ...userInfo };
                      if (val) {
                        field.country = val.value;
                      } else {
                        delete field.country;
                      }
                      setUserInfo(field);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country"
                        InputLabelProps={{
                          shrink: true,
                          classes: {
                            focused: classes.labelFocused,
                          },
                        }}
                        SelectProps={{
                          classes: {
                            focused: classes.labelFocused,
                          },
                        }}
                      />
                    )}
                  />
                </Box>
                {/* <InputBox
                  labelText="Country"
                  id="country"
                  value={userInfo?.country}
                  info={userInfo}
                  updateInfo={setUserInfo}
                /> */}
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <InputBox
                  labelText="Zipcode"
                  id="zipcode"
                  value={userInfo?.zipcode}
                  info={userInfo}
                  updateInfo={setUserInfo}
                />
              </GridItem>
            </GridContainer>
            <GridContainer style={{ marginTop: "25px" }}>
              <GridItem xs={12} sm={12} md={4}>
                <InputLabel
                  style={{
                    fontSize: "12px",
                    color: "rgba(0, 0, 0, 0.54)",
                  }}
                >
                  Primary Phone
                </InputLabel>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <PhoneInput
                    country={"au"}
                    containerStyle={{ zIndex: 4 }}
                    inputStyle={{
                      border: "0px",
                      borderBottom: "1px solid #0000008a",
                      borderRadius: "0px",
                    }}
                    buttonStyle={{
                      border: "0px",
                      background: "none",
                    }}
                    enableSearch={true}
                    placeholder="212345678"
                    value={userInfo?.primaryPhone}
                    onChange={(val) => {
                      setUserInfo({ ...userInfo, primaryPhone: val });
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={4} mt={2}>
                <InputLabel
                  style={{
                    fontSize: "12px",
                    color: "rgba(0, 0, 0, 0.54)",
                  }}
                >
                  Secondary Phone
                </InputLabel>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <PhoneInput
                    country={"au"}
                    containerStyle={{ zIndex: 4 }}
                    inputStyle={{
                      border: "0px",
                      borderBottom: "1px solid #0000008a",
                      borderRadius: "0px",
                    }}
                    buttonStyle={{
                      border: "0px",
                      background: "none",
                    }}
                    enableSearch={true}
                    placeholder="212345678"
                    value={userInfo?.secondaryPhone}
                    onChange={(val) => {
                      setUserInfo({ ...userInfo, secondaryPhone: val });
                    }}
                  />
                </FormControl>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <Card>
          <CardHeader zeroIndex={true} color="danger" icon>
            <CardIcon color="danger">
              <FilterNoneIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>User Profile</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              {customFields.list.map((field) => {
                let html;
                if (field.name.toLowerCase() !== 'plot_range_default' && field.name.toLowerCase() !== 'change password') {
                  if (field.name === "TimeZone") {
                    html = (
                      <GridItem xs={12} sm={12} md={4}>
                        <SelectBox
                          id={field.fieldId}
                          labelText={field.name}
                          required={true}
                          value={userInfo[field.fieldId]}
                          info={userInfo}
                          updateInfo={setUserInfo}
                          onChange={(e) => {
                            let obj = {};
                            obj[field.fieldId] = e.target.value;
                            setUserInfo({ ...userInfo, ...obj });
                          }}
                          options={timezones.map((val) => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                              }}
                              key={val}
                              value={val.value}
                            >
                              {`${val.text} (${val.abbr})`}
                            </MenuItem>
                          ))}
                        />
                      </GridItem>
                    );
                  } else if (field.type === "SingleSelect") {
                    html = (
                      <GridItem xs={12} sm={12} md={4}>
                        <SelectBox
                          id={field.fieldId}
                          labelText={field.name}
                          // disabled={field.name === "Guest Account" ? true : false}
                          required={true}
                          value={userInfo[field.fieldId]}
                          info={userInfo}
                          updateInfo={setUserInfo}
                          onChange={(e) => {
                            let obj = {};
                            obj[field.fieldId] = e.target.value;
                            setUserInfo({ ...userInfo, ...obj });
                          }}
                          options={field.optionValues.map((val) => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                              }}
                              key={val}
                              value={val}
                            >
                              {val}
                            </MenuItem>
                          ))}
                        />
                      </GridItem>
                    );
                  } else if (field.type === "MultiSelect") {
                    html = (
                      <GridItem xs={12} sm={12} md={4}>
                        <Box paddingTop={2}>
                          <Autocomplete
                            multiple
                            fullWidth
                            options={field.optionValues}
                            className={classes.inputColor}
                            disableCloseOnSelect
                            color="primary"
                            value={userInfo[field.fieldId]}
                            renderOption={(option, { selected }) => (
                              <React.Fragment>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option}
                              </React.Fragment>
                            )}
                            onChange={(event, val) => {
                              if (val.length === 0) {
                                delete userInfo[field.fieldId];
                              } else {
                                setUserInfo({
                                  ...userInfo,
                                  [field.fieldId]: val,
                                });
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={field.name}
                                required={true}
                                InputLabelProps={{
                                  shrink: true,
                                  classes: {
                                    focused: classes.labelFocused,
                                  },
                                }}
                                SelectProps={{
                                  classes: {
                                    focused: classes.labelFocused,
                                  },
                                }}
                              />
                            )}
                          />
                        </Box>
                      </GridItem>
                    );
                  } else if (field.type === "MultiLineText") {
                    html = (
                      <GridItem xs={12} sm={12} md={4}>
                        <InputLabel className={classes.datePickerLabel}>
                          {field.name}
                        </InputLabel>
                        <TextareaAutosize
                          value={userInfo[field.fieldId]}
                          rowsMax={5}
                          rowsMin={3}
                          id={field.fieldId}
                          style={{
                            width: "100%",
                            marginTop: "5px",
                          }}
                          onChange={(e) => {
                            setUserInfo({
                              ...userInfo,
                              [field.fieldId]: e.target.value,
                            });
                          }}
                        />
                      </GridItem>
                    );
                  } else {
                    html = (
                      <GridItem xs={12} sm={12} md={4}>
                        <InputBox
                          labelText={field.name}
                          id={field.fieldId}
                          required={true}
                          value={userInfo[field.fieldId]}
                          info={userInfo}
                          updateInfo={setUserInfo}
                        />
                      </GridItem>
                    );
                  }
                }
                return html;
              })}
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        {userRole !== "PracticeUser" && (
          <Box textAlign="right">
            <Button
              onClick={() => {
                id ? validateUpdateForm() : validateForm();
              }}
              color="danger"
            >
              {id ? "Save" : "Submit"}
            </Button>
            <Button
              onClick={() => {
                history.push(`/admin/resident`);
              }}
            >
              Cancel
            </Button>
          </Box>
        )}
      </GridItem>
      <Snackbar
        place="tr"
        color="danger"
        icon={AddAlert}
        message={alertMessage}
        open={toastStatus}
        closeNotification={() => setToastStatus(false)}
        close
      />
      {alert}
      <Loader
        status={device.loading || users.loading || users.userDetail.loading}
      />
    </GridContainer>
  );
}
