import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import SweetAlert from "react-bootstrap-sweetalert";
import TableSortLabel from "@material-ui/core/TableSortLabel";
// @material-ui/core components
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import AddAlert from "@material-ui/icons/AddAlert";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Loader from "components/Loader/Loader.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import timezones from "../../common/timezones.json";
import Filter from "./Filter.js";

import {
  FETCH_DEVICE_REQUEST,
  FETCH_DEVICE_SPECIFIED_REQUEST,
  GET_PROPERTY_DATA_REQUEST,
  UPDATE_DEVICE_DATA_REQUEST,
  UPDATE_DEFAULT_DATA_REQUEST,
  UPDATE_DEFAULT_THRESHOLD_REQUEST,
  DELETE_DEVICE_DATA_REQUEST,
  SYNC_FRIENDLY_NAME_REQUEST,
  FETCH_FRIENDLY_NAME_REQUEST,
  FETCH_DIAGNOSTIC_PANEL_REQUEST
} from "../../redux/device/actions";
import {
  FETCH_USER_LIST_REQUEST,
  FETCH_USER_CUSTOM_REQUEST,
  UPDATE_RESIDENT_USER_REQUEST,
} from "../../redux/users/actions";
import {
  FETCH_CUSTOM_FIELD_REQUEST,
  FETCH_SUPPLIER_CUSTOM_FIELD_REQUEST,
} from "../../redux/customFields/actions";
import {
  FETCH_COMPANY_REQUEST,
  UPDATE_DEVICE_COMPANY_REQUEST,
} from "../../redux/company/actions";
import { useComposeDispatcher } from "../../common/hooks";
import { DEFAULT_TIME, PAGE_SIZES } from "../../common/constants";
import buttonStyle from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles({
  ...buttonStyle,
  searchBox: {
    borderRadius: "5px",
    "& .MuiInputBase-fullWidth": {
      padding: "3px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "red !important",
    },
  },
  drawerRoot: {
    "& .MuiPaper-root": {
      width: "100%",
    },
  },
  labelFocused: {
    color: "#E73F3B !important",
  },
  inputFocused: {
    "&:before": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "&:after": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "& input": {
      borderBottom: "#E73F3B !important",
    },
  },
  inputRoot: {
    color: "red",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
  popover: {
    zIndex: 2,
  },
  fab: {
    margin: "0px",
    top: "auto",
    right: "20px",
    bottom: "20px",
    left: "auto",
    position: "fixed",
    zIndex: 3,
  },
});

const styles = () => ({
  labelFocused: {
    color: "#E73F3B !important",
  },
  inputFocused: {
    "&:before": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "&:after": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "& input": {
      borderBottom: "#E73F3B !important",
    },
  },
  inputRoot: {
    color: "red",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
});

const InputBox = withStyles(styles)((props) => {
  const {
    helperText,
    classes,
    value,
    type,
    required,
    onChange,
  } = props;
  return (
    <TextField
      // label={labelText}
      helperText={helperText}
      fullWidth
      required={required ? required : false}
      type={type ? type : "text"}
      margin="normal"
      color="primary"
      value={value}
      onChange={onChange}
      InputLabelProps={{
        shrink: true,
        classes: {
          focused: classes.labelFocused,
        },
      }}
      InputProps={{
        classes: {
          root: classes.root,
          focused: classes.inputFocused,
        },
      }}
    />
  );
});

const SelectBox = withStyles(styles)((props) => {
  const {
    onChange,
    classes,
    value,
    disabled,
    options,
    required,
  } = props;
  return (
    <TextField
      select
      fullWidth
      // label={labelText}
      value={value}
      disabled={disabled}
      required={required ? required : false}
      margin="normal"
      color="primary"
      onChange={onChange}
      InputLabelProps={{
        shrink: true,
        classes: {
          focused: classes.labelFocused,
        },
      }}
      InputProps={{
        classes: {
          root: classes.root,
          focused: classes.inputFocused,
        },
      }}
    >
      {options}
    </TextField>
  );
});

export default function DeviceTable() {
  const timerToClearSomewhere = useRef(null);
  const userDetails = JSON.parse(localStorage.getItem("user-details"));
  const loggedUserRole = userDetails !== null ? userDetails.roles[0].role : "";
  const classes = useStyles();
  const [userInfo, setUserInfo] = useState({
    supplierId: "cd5dc314-6e25-48a8-9d47-9e574b8fe3a0",
    deviceDataModelId: "",
    interval: "1 Hr"
  });
  const [paginationData, setPaginationData] = useState({});
  const [customPaginationData, setCustomPaginationData] = useState({
    role: "Patient",
    pageNumber: 0,
    pageSize: 5,
    sortBy: "firstName",
    sortOrder: "ASC",
  });
  const [
    customCompanyPaginationData,
    setCustomCompanyPaginationData,
  ] = useState({
    pageNumber: 0,
    pageSize: 5,
    sortBy: "name",
    sortOrder: "ASC",
  });
  const [
    customDiagnosticPaginationData,
    setCustomDiagnosticPaginationData,
  ] = useState({
    pageNumber: 0,
    pageSize: 5,
    sortBy: "name",
    sortOrder: "ASC",
  });
  const [alertMessage, setAlertMessage] = useState("");
  const [toastStatus, setToastStatus] = useState(false);
  const [editCompanyIndex, setEditCompanyIndex] = useState();
  const [editCompanyCustoms, setEditCompanyCustoms] = useState([]);
  const [tableCompany, setTableCompany] = useState("");

  const [editThresholdIndex, setEditThresholdIndex] = useState();
  const [editThreshold, setEditThreshold] = useState({});
  const [alert, setAlert] = useState(null);
  const [refreshSection, setRefreshSection] = useState(Date.now());
  const [editResidentIndex, setEditResidentIndex] = useState();
  const [editResidentData, setEditResidentData] = useState([]);
  const [editCategory, setEditCategory] = useState();

  const [order, setOrder] = useState("DESC");
  const [orderBy, setOrderBy] = useState("createdOn");
  const [orderTable, setOrderTable] = useState("");

  const { device, company, users, customFields } = useSelector((state) => ({
    device: state.device,
    company: state.company,
    users: state.users,
    customFields: state.customFields,
  }));

  const [getDeviceList] = useComposeDispatcher(FETCH_DEVICE_REQUEST);

  const [fetchSavedDiagnosticPanel] = useComposeDispatcher(FETCH_DIAGNOSTIC_PANEL_REQUEST);

  const [getCompanyList] = useComposeDispatcher(FETCH_COMPANY_REQUEST);

  const [getUserList] = useComposeDispatcher(FETCH_USER_LIST_REQUEST);

  const [getUserAndCustomList] = useComposeDispatcher(
    FETCH_USER_CUSTOM_REQUEST
  );

  const [getDeviceSpecifiedData] = useComposeDispatcher(
    FETCH_DEVICE_SPECIFIED_REQUEST
  );

  const [getPropertyData] = useComposeDispatcher(GET_PROPERTY_DATA_REQUEST);

  const [getCustomFieldList] = useComposeDispatcher(FETCH_CUSTOM_FIELD_REQUEST);

  const [getSupplierCustomField] = useComposeDispatcher(
    FETCH_SUPPLIER_CUSTOM_FIELD_REQUEST
  );

  const [updateDeviceCompany] = useComposeDispatcher(
    UPDATE_DEVICE_COMPANY_REQUEST
  );

  const [updateDeviceData] = useComposeDispatcher(UPDATE_DEVICE_DATA_REQUEST);

  const [updateDefaultData] = useComposeDispatcher(UPDATE_DEFAULT_DATA_REQUEST);

  const [updateDefaultValueInCompany] = useComposeDispatcher(
    UPDATE_DEFAULT_THRESHOLD_REQUEST
  );

  const [deleteDeviceData] = useComposeDispatcher(DELETE_DEVICE_DATA_REQUEST);

  const [updateResidentUser] = useComposeDispatcher(
    UPDATE_RESIDENT_USER_REQUEST
  );

  const [fetchFriendlyNameData] = useComposeDispatcher(
    FETCH_FRIENDLY_NAME_REQUEST
  );

  const [syncFriendlyName] = useComposeDispatcher(SYNC_FRIENDLY_NAME_REQUEST);

  useEffect(() => {
    getCompanyList({
      pageNumber: 0,
      pageSize: 5,
      sortBy: "name",
      sortOrder: "ASC",
    });
    fetchSavedDiagnosticPanel({
      pageNumber: 0,
      pageSize: 5,
      sortBy: "minValueProvidedOn",
      sortOrder: "ASC",
    })
    getDeviceList({ status: "Active" });
    getUserList({ role: "Patient" });
    getCustomFieldList("UserProfile");
    getSupplierCustomField("SupplierProfile");
  }, []);

  if (!userInfo?.deviceDataModelId && device.deviceList.length > 0) {
    let obj = {deviceDataModelId: device.deviceList[0].deviceId};
    setUserInfo({ ...userInfo, ...obj });
  }

  if (
    Object.keys(paginationData).length === 0 &&
    Object.keys(device?.deviceTableHeader).length > 0
  ) {
    const updatedPagination = Object.keys(device?.deviceTableHeader).reduce(
      (acc, val) => {
        let obj = {};
        obj[val] = { rowsPerPage: 5, page: 0 };
        return { ...acc, ...obj };
      },
      {}
    );
    setPaginationData(updatedPagination);
  }

  const handleChangeRowsPerPage = (
    type,
    count,
    propertyCodes,
    deviceDataModelId,
    page,
    devicePropertySetId
  ) => {
    let usersList = [];
    if (userInfo?.userId && userInfo?.userId !== "all") {
      usersList = [userInfo?.userId];
    } else if (userInfo?.supplierId && userInfo.supplierId !== "all") {
      usersList = users?.userList?.users.reduce((acc, val) => {
        if (userInfo?.supplierId === val?.roles[0].supplier?.supplierId) {
          return [...acc, val.userId];
        }
        return acc;
      }, []);
    }

    getPropertyData({
      payload: {
        pageNumber: page,
        pageSize: count,
        sortBy: "maxValueProvidedOn",
        sortOrder: "DESC",
      },
      data: {
        deviceDataModelId,
        devicePropertyCodes: propertyCodes,
        devicePropertySetId,
        deviceCriteria: userInfo.macAddress && userInfo.macAddress !== 'all' ? getDeviceCriteriaObj(type, userInfo.macAddress) : [],
        ownerFilter: {
          users: usersList,
        },
      },
      type,
    });
  };

  const getDeviceCriteriaObj = (type, macAddress) => {
    let deviceCriteria = [];
    switch (type) {
      case "pVITAL":
        deviceCriteria = [
          {
            key: "DevidPvital",
            operator: "Equal",
            value: macAddress,
            valueFrom: "",
            valueTo: "",
            isDateValue: false,
            dateTimeRangeValue: null,
          },
        ];
        break;
      case "IDENTITY":
        deviceCriteria = [
          {
            key: "Devid",
            operator: "Equal",
            value: macAddress,
            valueFrom: "",
            valueTo: "",
            isDateValue: false,
            dateTimeRangeValue: null,
          },
        ];
        break;
      case "sVITAL":
        deviceCriteria = [
          {
            key: "DevidSvital",
            operator: "Equal",
            value: macAddress,
            valueFrom: "",
            valueTo: "",
            isDateValue: false,
            dateTimeRangeValue: null,
          },
        ];
        break;
      case "PARAMS":
        deviceCriteria = [
          {
            key: "DevidParams",
            operator: "Equal",
            value: macAddress,
            valueFrom: "",
            valueTo: "",
            isDateValue: false,
            dateTimeRangeValue: null,
          },
        ];
        break;
      case "THRESHOLDS":
        deviceCriteria = [
          {
            key: "DevidThresholds",
            operator: "Equal",
            value: macAddress,
            valueFrom: "",
            valueTo: "",
            isDateValue: false,
            dateTimeRangeValue: null,
          },
        ];
        break;
      case "DevHealth":
        deviceCriteria = [
          {
            key: "DevidDevhealth",
            operator: "Equal",
            value: macAddress,
            valueFrom: "",
            valueTo: "",
            isDateValue: false,
            dateTimeRangeValue: null,
          },
        ];
        break;
      case "ALERTS":
        deviceCriteria = [
          {
            key: "DevidAlerts",
            operator: "Equal",
            value: macAddress,
            valueFrom: "",
            valueTo: "",
            isDateValue: false,
            dateTimeRangeValue: null,
          },
        ];
        break;
    }
    return deviceCriteria;
  }

  const filterUserData = () => {
    setPaginationData({});
    // setEditThreshold({});
    const deviceName = device.deviceList
      .find((val) => val.deviceId === userInfo.deviceDataModelId)
      ?.name.split("-")[0];
    if (!userInfo?.supplierId && !userInfo?.userId) {
      getDeviceSpecifiedData({
        deviceDataModelId: userInfo.deviceDataModelId,
        deviceName,
        users: [],
      });
      getUserAndCustomList(customPaginationData);
    }
    if (userInfo?.supplierId) {
      if (!userInfo?.userId || userInfo.userId === "all") {
        let selectUser = users?.userList?.users.reduce((acc, val) => {
          if (userInfo?.supplierId === val?.roles[0].supplier?.supplierId) {
            return [...acc, val.userId];
          }
          return acc;
        }, []);
        if (selectUser.length > 0) {
          getDeviceSpecifiedData({
            deviceDataModelId: userInfo.deviceDataModelId,
            deviceName,
            users: selectUser,
          });
          if (userInfo?.supplierId === "all") {
            getUserAndCustomList(customPaginationData);
          } else {
            getUserAndCustomList({
              ...customPaginationData,
              supplierId: userInfo?.supplierId,
            });
          }
        } else {
          const companyDetails = company.companyList.find(
            (com) => com.supplierId === userInfo?.supplierId
          );
          setToastStatus(true);
          setAlertMessage(
            `No user found under company - ${companyDetails.name}`
          );
        }
      } else if (userInfo.userId) {
        const userEmailObj = users?.userList?.users.find(
          (val) => val.userId === userInfo.userId
        );
        getUserAndCustomList({
          ...customPaginationData,
          emailAddress: userEmailObj.emailAddress,
        });
        getDeviceSpecifiedData({
          deviceName,
          deviceDataModelId: userInfo.deviceDataModelId,
          macAddress: userInfo.macAddress || "",
          users: [userInfo.userId],
        });
      }
    }
    if (!userInfo?.supplierId && userInfo.userId) {
      if (userInfo.userId === "all") {
        getDeviceSpecifiedData({
          deviceDataModelId: userInfo.deviceDataModelId,
          deviceName,
          users: [],
        });
      } else {
        const userEmailObj = users?.userList?.users.find(
          (val) => val.userId === userInfo.userId
        );
        getUserAndCustomList({
          ...customPaginationData,
          emailAddress: userEmailObj.emailAddress,
        });
        getDeviceSpecifiedData({
          deviceDataModelId: userInfo.deviceDataModelId,
          deviceName,
          users: [userInfo.userId],
        });
      }
    }
  };

  const warningWithConfirmMessage = (selectedCompany) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => {
          updateDefaultValueInCompany(selectedCompany);
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Confirm"
        cancelBtnText="Cancel"
        showCancel
      >
        Are you sure, You want to overwrite{" "}
        <b>{selectedCompany.paginationObj.type}</b> value of all{" "}
        <b>Resident User</b> in <b>{selectedCompany.name}</b> company
      </SweetAlert>
    );
  };

  const companyTable = () => {
    return (
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography>Company Name</Typography>
              </TableCell>
              {customFields?.companyCustomList.map((field) => {
                if ((loggedUserRole === "SupplierAdmin" && field.name !== "GainSelect" &&
                    field.name !== "BpfSelect") || loggedUserRole === "TenantAdmin") {
                  return (
                    <TableCell key={field.fieldId}>
                      <Typography>{field.name}</Typography>
                    </TableCell>
                  );
                }
              })}
              <TableCell align="center">
                <Typography>Actions</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody key={refreshSection}>
            {company?.totalElements === 0 && (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={customFields?.list.length + 1}
                >
                  No records found
                </TableCell>
              </TableRow>
            )}
            {company?.companyList.map((data, index) => {
              if (data?.supplierId) {
                return (
                  <TableRow key={data.supplierId}>
                    <TableCell>{data.name}</TableCell>
                    {customFields?.companyCustomList.map((field) => {
                      const cData = data?.customData.find(
                        (cd) => cd.field.fieldId === field.fieldId
                      );
                      if (
                        (loggedUserRole === "SupplierAdmin" &&
                          field.name !== "GainSelect" &&
                          field.name !== "BpfSelect") ||
                        loggedUserRole === "TenantAdmin"
                      ) {
                        return (
                          <TableCell key={field.fieldId}>
                            <Typography>
                              {editCompanyIndex === index ? (
                                field.type === "MultiSelect" ? (
                                  <Autocomplete
                                    multiple
                                    fullWidth
                                    style={{ width: "200px" }}
                                    options={field.optionValues}
                                    className={classes.inputColor}
                                    disableCloseOnSelect
                                    color="primary"
                                    renderOption={(option, { selected }) => (
                                      <React.Fragment>
                                        <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          style={{ marginRight: 8 }}
                                          checked={selected}
                                        />
                                        {option}
                                      </React.Fragment>
                                    )}
                                    onChange={(event, val) => {
                                      let validateData = editCompanyCustoms?.find(
                                        (cData) =>
                                          cData.field.fieldId === field.fieldId
                                      );
                                      if (validateData) {
                                        let updatedData = editCompanyCustoms?.map(
                                          (cData) => {
                                            if (
                                              cData.field.fieldId ===
                                              field.fieldId
                                            ) {
                                              let newObj = {
                                                ...cData,
                                              };
                                              newObj.fieldData = val;
                                              return newObj;
                                            }
                                            return cData;
                                          }
                                        );
                                        setEditCompanyCustoms(updatedData);
                                      } else {
                                        let obj = {
                                          fieldData: val,
                                          field,
                                        };
                                        setEditCompanyCustoms([
                                          ...editCompanyCustoms,
                                          obj,
                                        ]);
                                      }
                                    }}
                                    value={
                                      editCompanyIndex === index
                                        ? editCompanyCustoms?.find(
                                          (cData) =>
                                            cData.field.fieldId ===
                                            field.fieldId
                                        )?.fieldData
                                        : cData?.fieldData
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        required={true}
                                        InputLabelProps={{
                                          shrink: true,
                                          classes: {
                                            focused: classes.labelFocused,
                                          },
                                        }}
                                        SelectProps={{
                                          classes: {
                                            focused: classes.labelFocused,
                                          },
                                        }}
                                      />
                                    )}
                                  />
                                ) : (
                                  <TextField
                                    className={classes.searchBox}
                                    style={{
                                      width: "100%",
                                    }}
                                    color="secondary"
                                    // label="Device"
                                    onChange={(val) => {
                                      let validateData = editCompanyCustoms?.find(
                                        (cData) =>
                                          cData.field.fieldId === field.fieldId
                                      );
                                      if (validateData) {
                                        let updatedData = editCompanyCustoms?.map(
                                          (cData) => {
                                            if (
                                              cData.field.fieldId ===
                                              field.fieldId
                                            ) {
                                              let newObj = {
                                                ...cData,
                                              };
                                              newObj.fieldData =
                                                val.target.value;
                                              return newObj;
                                            }
                                            return cData;
                                          }
                                        );
                                        setEditCompanyCustoms(updatedData);
                                      } else {
                                        let obj = {
                                          fieldData: val.target.value,
                                          field,
                                        };
                                        setEditCompanyCustoms([
                                          ...editCompanyCustoms,
                                          obj,
                                        ]);
                                      }
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                      classes: {
                                        focused: classes.labelFocused,
                                      },
                                    }}
                                    InputProps={{
                                      classes: {
                                        root: classes.root,
                                        focused: classes.inputFocused,
                                      },
                                    }}
                                    value={
                                      editCompanyIndex === index
                                        ? editCompanyCustoms?.find(
                                          (cData) =>
                                            cData.field.fieldId ===
                                            field.fieldId
                                        )?.fieldData
                                        : cData?.fieldData
                                    }
                                  />
                                )
                              ) : typeof cData?.fieldData === "object" ? (
                                cData?.fieldData?.toString()
                              ) : (
                                cData?.fieldData || "-"
                              )}
                            </Typography>
                          </TableCell>
                        );
                      }
                    })}
                    <TableCell style={{ textAlign: "center" }}>
                      {editCompanyIndex === index ? (
                        <>
                          <Button
                            color="success"
                            simple
                            style={{ padding: "0px" }}
                            onClick={() => {
                              setRefreshSection(Date.now());
                              let getCompany = company?.companyList.find(
                                (val, cIndex) => cIndex === editCompanyIndex
                              );
                              let obj = {
                                companyObject: {
                                  customData: editCompanyCustoms,
                                  supplier: getCompany,
                                },
                                pagination: {
                                  pageNumber:
                                    customCompanyPaginationData.pageNumber,
                                  pageSize:
                                    customCompanyPaginationData.pageSize,
                                  sortBy: "name",
                                  sortOrder: "ASC",
                                },
                              };
                              updateDeviceCompany(obj);
                              setEditCompanyIndex();
                              setEditCompanyCustoms([]);
                              // setEditCompanyIndex(index);
                            }}
                          >
                            Update
                          </Button>
                          <Button
                            color="danger"
                            simple
                            style={{ padding: "0px" }}
                            onClick={() => {
                              setEditCompanyIndex();
                              setEditCompanyCustoms([]);
                            }}
                          >
                            Cancel
                          </Button>
                        </>
                      ) : (
                        <Button
                          color="success"
                          simple
                          onClick={() => {
                            let getCompany = company?.companyList.find(
                              (val, cIndex) => cIndex === index
                            );
                            setEditCompanyIndex(index);
                            setEditCompanyCustoms(getCompany.customData);
                            setRefreshSection(Date.now());
                          }}
                        >
                          Edit
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              }
            })}
          </TableBody>
        </Table>
        <TablePagination
          style={{
            float: "left",
            paddingLeft: "0px",
          }}
          rowsPerPageOptions={PAGE_SIZES}
          component="div"
          count={company?.totalElements}
          rowsPerPage={customCompanyPaginationData?.pageSize}
          page={customCompanyPaginationData?.pageNumber}
          onChangePage={(e, page) => {
            let previousData = customCompanyPaginationData;
            previousData.pageNumber = page;
            setCustomCompanyPaginationData(previousData);
            getCompanyList(previousData);
          }}
          onChangeRowsPerPage={(e) => {
            let previousData = customCompanyPaginationData;
            previousData.pageSize = e.target.value;
            setCustomCompanyPaginationData(previousData);
            getCompanyList(previousData);
          }}
        />
      </TableContainer>
    );
  };

  const diagnosticTable = () => {
    return (
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography>User</Typography>
              </TableCell>
              <TableCell>
                <Typography>User Name 1</Typography>
              </TableCell>
              <TableCell>
                <Typography>Friendly Name 1</Typography>
              </TableCell>
              <TableCell>
                <Typography>User Name 2</Typography>
              </TableCell>
              <TableCell>
                <Typography>Friendly Name 2</Typography>
              </TableCell>
              <TableCell>
                <Typography>User Name 3</Typography>
              </TableCell>
              <TableCell>
                <Typography>Friendly Name 3</Typography>
              </TableCell>
              <TableCell>
                <Typography>Graph Property 1</Typography>
              </TableCell>
              <TableCell>
                <Typography>Graph Property 2</Typography>
              </TableCell>
              <TableCell>
                <Typography>Interval</Typography>
              </TableCell>
              <TableCell>
                <Typography>Start Time</Typography>
              </TableCell>
              <TableCell>
                <Typography>Modified At (UTC)</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody key={refreshSection}>
            {device?.totalDiagnosticElements === 0 && (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={customFields?.list.length + 1}
                >
                  No records found
                </TableCell>
              </TableRow>
            )}
            {device?.diagnosticList.map(( val, index) => {
              return (<TableRow key={`key-${index}`}>
                <TableCell>{val.data.Createdbyusername.value}</TableCell>
                <TableCell>{val.data.Username1.value}</TableCell>
                <TableCell>{val.data.Friendlyname1.value}</TableCell>
                <TableCell>{val.data.Username2.value}</TableCell>
                <TableCell>{val.data.Friendlyname2.value}</TableCell>
                <TableCell>{val.data.Username3.value}</TableCell>
                <TableCell>{val.data.Friendlyname3.value}</TableCell>
                <TableCell>{val.data.Graph1.value}</TableCell>
                <TableCell>{val.data.Graph2.value}</TableCell>
                <TableCell>{val.data.Interval.value}</TableCell>
                <TableCell>{moment(val.data.Starttime.value).format("MM/DD/YYYY h:mm a")}</TableCell>
                <TableCell>{moment(val.maxValueProvidedOn).format("MM/DD/YYYY h:mm a")}</TableCell>
              </TableRow>);
            })}
          </TableBody>
        </Table>
        <TablePagination
          style={{
            float: "left",
            paddingLeft: "0px",
          }}
          rowsPerPageOptions={PAGE_SIZES}
          component="div"
          count={device?.totalDiagnosticElements}
          rowsPerPage={customDiagnosticPaginationData?.pageSize}
          page={customDiagnosticPaginationData?.pageNumber}
          onChangePage={(e, page) => {
            let previousData = customDiagnosticPaginationData;
            previousData.pageNumber = page;
            setCustomDiagnosticPaginationData(previousData);
            fetchSavedDiagnosticPanel(previousData);
          }}
          onChangeRowsPerPage={(e) => {
            let previousData = customDiagnosticPaginationData;
            previousData.pageSize = e.target.value;
            setCustomDiagnosticPaginationData(previousData);
            fetchSavedDiagnosticPanel(previousData);
          }}
        />
      </TableContainer>
    );
  };

  const tableRowSection = (editThresholdIndex, val, data, index) => {
    return device?.deviceTableHeader[val]?.devicePropertyCodes.map(
      (code, fIndex) => {
        let fieldDataType = device.deviceTableHeader[val].dataTypes[fIndex];
        let currentUser = users.userDeviceList.users.find(
          (val) => val.userId === data.owner.userId
        );
        let selectedOffset = 0;
        let dayLightSaving = 0;
        if (currentUser && currentUser.customData) {
          // let selectedTimeZone = currentUser.customData.find(
          //   (val) => val.field.name === "TimeZone"
          // );
          let selectedTimeZone = data?.data?.Timezone ? data?.data?.Timezone.value : DEFAULT_TIME;

          let userTimeZone = timezones.find(
            (val) => val.value === selectedTimeZone
          );
          let dayLight = currentUser.customData.find(
            (val) => val.field.name === "DayLight_Savings"
          );
          dayLightSaving = dayLight && dayLight?.value === "Yes" ? 1 : 0;
          selectedOffset = userTimeZone.offset || 0;
        }
        return editThresholdIndex === index &&
          editCategory === val &&
          fieldDataType === "Boolean" ? (
          <TableCell key={code}>
            <SelectBox
              value={data?.data[code]?.value}
              onChange={(val) => {
                let obj = {
                  ...editThreshold,
                };
                if (obj.data[code] && obj.data[code].value) {
                  obj.data[code].value = val.target.value;
                } else {
                  obj.data[code] = {
                    value: val.target.value,
                  };
                }
                setEditThreshold(obj);
              }}
              options={["Yes", "No"].map((val) => (
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                  key={val}
                  value={val}
                >
                  {val}
                </MenuItem>
              ))}
            />
          </TableCell>
        ) : editThresholdIndex === index &&
          editCategory === val &&
          code !== "DevidThresholds" &&
          code !== "Devid" &&
          code !== "FriendlyNamePa" &&
          code !== "FriendlyNameT" &&
          code !== "DevidParams" &&
          code !== "ThresholdLastupdate" &&
          code !== "FindMySensor" &&
          code !== "Offline" &&
          code !== "NightModeStart" &&
          code !== "NightModeEnd" &&
          code !== "NightMode" &&
          code !== "TimestampP" &&
          code !== "TimestampI" &&
          code !== "XMode" &&
          code !== "DeveloperMode" &&
          code !== "ParamsLastupdate" ? (
          <TableCell key={code}>
            <TextField
              className={classes.searchBox}
              style={{
                width: "100%",
              }}
              color="secondary"
              // label="Device"
              onChange={(val) => {
                let obj = {
                  ...editThreshold,
                };
                if (obj.data[code] && obj.data[code].value) {
                  obj.data[code].value = val.target.value;
                } else {
                  obj.data[code] = {
                    value: val.target.value,
                  };
                }
                setEditThreshold(obj);
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  focused: classes.labelFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.root,
                  focused: classes.inputFocused,
                },
              }}
              value={editThreshold?.data[code]?.value}
            />
          </TableCell>
        ) : editThresholdIndex === index &&
          editCategory === val &&
          (code === "NightModeStart" || code === "NightModeEnd") ? (
          <TableCell key={code}>
            <TextField
              type="time"
              value={editThreshold?.data[code]?.value}
              className={classes.searchBox}
              onChange={(val) => {
                let obj = {
                  ...editThreshold,
                };
                if (obj.data[code] && obj.data[code].value) {
                  obj.data[code].value = val.target.value;
                } else {
                  obj.data[code] = {
                    value: val.target.value,
                  };
                }
                setEditThreshold(obj);
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  focused: classes.labelFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.root,
                  focused: classes.inputFocused,
                },
              }}
            />
          </TableCell>
        ) : (
          <TableCell key={code}>
            <Typography>
              {data?.data[code] && fieldDataType === "DateAndTime"
                ? moment
                  .utc(data?.data[code].value)
                  .subtract(dayLightSaving, "h")
                  .utcOffset(selectedOffset)
                  .format("MMM Do YY, h:mm:ss a")
                : data?.data[code]
                  ? data?.data[code].value
                  : ""}
            </Typography>
          </TableCell>
        );
      }
    );
  };

  const createSortHandler = (
    property,
    table,
    code,
    deviceDataModelId,
    devicePropertyCodes,
    devicePropertySetId
  ) => () => {
    const isAsc = orderBy === property && order === "ASC";
    setOrder(isAsc ? "DESC" : "ASC");
    setOrderBy(property);
    setOrderTable(table);
    let usersList = [];
    if (userInfo?.userId && userInfo?.userId !== "all") {
      usersList = [userInfo?.userId];
    } else if (userInfo?.supplierId && userInfo.supplierId !== "all") {
      usersList = users?.userList?.users.reduce((acc, val) => {
        if (userInfo?.supplierId === val?.roles[0].supplier?.supplierId) {
          return [...acc, val.userId];
        }
        return acc;
      }, []);
    }
    getPropertyData({
      payload: {
        pageNumber: 0,
        pageSize: 5,
        sortBy: `data.${code}.value`,
        sortOrder: isAsc ? "DESC" : "ASC",
      },
      data: {
        deviceDataModelId,
        devicePropertyCodes: devicePropertyCodes,
        devicePropertySetId,
        ownerFilter: {
          users: usersList,
        },
      },
      type: table,
    });
  };

  return (
    <>
      <GridContainer>
        <Filter
          device={device}
          company={company}
          userInfo={userInfo}
          userList={users.userList}
          setUserInfo={setUserInfo}
          getUserList={getUserList}
          getDeviceData={filterUserData}
          timerToClearSomewhere={timerToClearSomewhere}
          fetchFriendlyNameData={fetchFriendlyNameData}
        />
        {Object.keys(device?.deviceTable).map((val) => {
          if (
            (loggedUserRole === "SupplierAdmin" && val !== "PARAMS") ||
            loggedUserRole === "TenantAdmin"
          ) {
            return (
              <GridItem xs={12}>
                <Box paddingBottom={2}>
                  <Accordion key={val}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-label="Expand"
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Box width="100%">
                        <Typography>{val}</Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        {(val === "THRESHOLDS" || val === "PARAMS") && (
                          <Grid
                            container
                            style={{ paddingLeft: "8px" }}
                            // direction="row-reverse"
                            justify="flex-start"
                          // alignItems="center"
                          >
                            <Grid item xs={4} spacing={2}>
                              <TextField
                                className={classes.searchBox}
                                style={{
                                  width: "100%",
                                }}
                                select
                                color="secondary"
                                label="Set default value to all resident in company"
                                onChange={(cval) => {
                                  setTableCompany(cval.target.value);
                                }}
                                value={tableCompany}
                                InputLabelProps={{
                                  shrink: true,
                                  classes: {
                                    // focused: classes.labelFocused,
                                  },
                                }}
                                InputProps={{
                                  classes: {
                                    // root: classes.root,
                                    // focused: classes.inputFocused,
                                  },
                                }}
                              >
                                {company.companyList.map((option) => {
                                  return (
                                    <MenuItem
                                      key={option.supplierId}
                                      value={option.supplierId}
                                    >
                                      {option.name}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              spacing={1}
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <Button
                                onClick={() => {
                                  if (tableCompany) {
                                    let selectedCompany = company?.companyList.find(
                                      (data) => data.supplierId === tableCompany
                                    );
                                    selectedCompany.paginationObj = {
                                      payload: {
                                        pageNumber: 0,
                                        pageSize: 5,
                                        sortBy: "maxValueProvidedOn",
                                        sortOrder: "DESC",
                                      },
                                      data: {
                                        deviceDataModelId: userInfo?.deviceDataModelId,
                                        devicePropertyCodes:
                                          device?.deviceTableHeader[val]
                                            .devicePropertyCodes,
                                        devicePropertySetId:
                                          device?.deviceTable[val]
                                            .devicePropertySetId,
                                      },
                                      type: val,
                                    };
                                    warningWithConfirmMessage(selectedCompany);
                                    setTableCompany("");
                                  }
                                }}
                                color="danger"
                              >
                                Apply
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    <Typography>Resident Name</Typography>
                                  </TableCell>
                                  {device?.deviceTableHeader[
                                    val
                                  ]?.devicePropertyCodes?.map((code, index) => {
                                    let title = code;
                                    if (code === "NightModeStart") {
                                      title = `${code} (UTC)`;
                                    } else if (code === "NightModeEnd") {
                                      title = `${code} (UTC)`;
                                    }
                                    const deviceDataModelId =
                                      device?.deviceTableHeader[val].deviceDataModelId;
                                    const devicePropertyCodes =
                                      device?.deviceTableHeader[val]
                                        .devicePropertyCodes;
                                    const devicePropertySetId =
                                      device?.deviceTableHeader[val]
                                        .devicePropertySetId;
                                    return (
                                      <TableCell key={code}>
                                        <TableSortLabel
                                          active={
                                            orderBy === index &&
                                            orderTable === val
                                          }
                                          direction={
                                            orderBy === index
                                              ? order.toLowerCase()
                                              : "asc"
                                          }
                                          onClick={createSortHandler(
                                            index,
                                            val,
                                            code,
                                            deviceDataModelId,
                                            devicePropertyCodes,
                                            devicePropertySetId
                                          )}
                                        >
                                          <Typography>{title}</Typography>
                                        </TableSortLabel>
                                      </TableCell>
                                    );
                                  })}
                                  {(val === "THRESHOLDS" ||
                                    val === "PARAMS" ||
                                    val === "IDENTITY") && (
                                      <TableCell align="center">
                                        <Typography>Actions</Typography>
                                      </TableCell>
                                    )}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {device?.deviceTable[val].totalElements ===
                                  0 && (
                                    <TableRow>
                                      <TableCell
                                        align="center"
                                        colSpan={
                                          device?.deviceTableHeader[val]
                                            .devicePropertyCodes?.length + 1
                                        }
                                      >
                                        No records found
                                      </TableCell>
                                    </TableRow>
                                  )}
                                {device?.deviceTable[val].data.map(
                                  (data, index) => {
                                    if (data?.owner) {
                                      return (
                                        <TableRow key={data.deviceDataId}>
                                          <TableCell key="resident">
                                            {data.owner.fullName}{" "}
                                          </TableCell>
                                          {tableRowSection(
                                            editThresholdIndex,
                                            val,
                                            data,
                                            index
                                          )}
                                          {(val === "THRESHOLDS" ||
                                            val === "PARAMS" ||
                                            val === "IDENTITY") && (
                                              <TableCell
                                                key="threshold"
                                                style={{ textAlign: "center" }}
                                              >
                                                {editThresholdIndex === index &&
                                                  editCategory === val ? (
                                                  <>
                                                    <Button
                                                      style={{
                                                        padding: "0px",
                                                        textAlign: "center",
                                                      }}
                                                      color="success"
                                                      simple
                                                      onClick={() => {
                                                        let reqObj = {
                                                          devicePropertySetId:
                                                            device?.deviceTable[
                                                              val
                                                            ].devicePropertySetId,
                                                          deviceDataModelId:
                                                            device?.deviceTable[val].data[index].deviceDataModelId,
                                                          deviceDataId:
                                                            data.deviceDataId,
                                                          data: Object.keys(
                                                            editThreshold.data
                                                          ).reduce((acc, val) => {
                                                            let obj = {};
                                                            obj[val] =
                                                              editThreshold.data[
                                                                val
                                                              ].value;
                                                            return {
                                                              ...acc,
                                                              ...obj,
                                                            };
                                                          }, {}),
                                                        };
                                                        let paginationObj = {
                                                          payload: {
                                                            pageNumber:
                                                              paginationData[val]
                                                                ?.page,
                                                            pageSize:
                                                              paginationData[val]
                                                                ?.rowsPerPage,
                                                            sortBy:
                                                              "maxValueProvidedOn",
                                                            sortOrder: "DESC",
                                                          },
                                                          data: {
                                                            deviceCriteria: userInfo.macAddress && userInfo.macAddress !== 'all' ? getDeviceCriteriaObj('IDENTITY', userInfo.macAddress) : [],
                                                            deviceDataModelId: device?.deviceTable[val].data[index].deviceDataModelId,
                                                            devicePropertyCodes: device?.deviceTableHeader[val].devicePropertyCodes,
                                                            devicePropertySetId: device?.deviceTable[val].devicePropertySetId,
                                                          },
                                                          type: val,
                                                        };
                                                        if (
                                                          userInfo?.userId &&
                                                          userInfo?.userId !==
                                                          "all"
                                                        ) {
                                                          paginationObj.data.ownerFilter = {
                                                            users: [
                                                              userInfo?.userId,
                                                            ],
                                                          };
                                                        } else if (
                                                          userInfo?.supplierId &&
                                                          userInfo.supplierId !==
                                                          "all"
                                                        ) {
                                                          paginationObj.data.ownerFilter = {
                                                            users: users?.userList?.users.reduce(
                                                              (acc, val) => {
                                                                if (
                                                                  userInfo?.supplierId ===
                                                                  val?.roles[0]
                                                                    .supplier
                                                                    ?.supplierId
                                                                ) {
                                                                  return [
                                                                    ...acc,
                                                                    val.userId,
                                                                  ];
                                                                }
                                                                return acc;
                                                              },
                                                              []
                                                            ),
                                                          };
                                                        }
                                                        if (
                                                          val === "THRESHOLDS"
                                                        ) {
                                                          reqObj.data.TimestampT = moment()
                                                            .utc()
                                                            .format(
                                                              "YYYY-MM-DDTHH:mm:ss[Z]"
                                                            );
                                                        } else if (
                                                          val === "PARAMS"
                                                        ) {
                                                          reqObj.data.TimestampP = moment()
                                                            .utc()
                                                            .format(
                                                              "YYYY-MM-DDTHH:mm:ss[Z]"
                                                            );
                                                        } else if (
                                                          val === "IDENTITY"
                                                        ) {
                                                          reqObj.data.TimestampI = moment()
                                                            .utc()
                                                            .format(
                                                              "YYYY-MM-DDTHH:mm:ss[Z]"
                                                            );
                                                        }
                                                        updateDeviceData({
                                                          reqObj,
                                                          paginationObj,
                                                        });
                                                        if (val === "IDENTITY") {
                                                          let pObj = {
                                                            deviceDataModelId:
                                                              device?.deviceTable[
                                                                val
                                                              ].data[index]
                                                                .deviceDataModelId,
                                                            devicePropertyCodes:
                                                              device
                                                                .deviceTableHeader
                                                                .PARAMS
                                                                .devicePropertyCodes,
                                                            deviceCriteria: userInfo.macAddress && userInfo.macAddress !== 'all' ? getDeviceCriteriaObj('PARAMS', userInfo.macAddress) : [],
                                                            ownerFilter: {
                                                              users: [
                                                                data.owner.userId,
                                                              ],
                                                            },
                                                          };
                                                          syncFriendlyName({
                                                            reqObj,
                                                            device,
                                                            userInfo,
                                                            data,
                                                            userObj: pObj,
                                                            type: "PARAMS",
                                                            paginationData,
                                                          });
                                                          let tObj = {
                                                            deviceDataModelId: device?.deviceTable[val].data[index].deviceDataModelId,
                                                            devicePropertyCodes: device.deviceTableHeader.THRESHOLDS.devicePropertyCodes,
                                                            deviceCriteria: userInfo.macAddress && userInfo.macAddress !== 'all' ? getDeviceCriteriaObj('THRESHOLDS', userInfo.macAddress) : [],
                                                            ownerFilter: {users: [data.owner.userId]},
                                                          };
                                                          syncFriendlyName({
                                                            reqObj,
                                                            device,
                                                            userInfo,
                                                            data,
                                                            userObj: tObj,
                                                            type: "THRESHOLDS",
                                                            paginationData,
                                                          });
                                                        }
                                                        setEditCategory();
                                                        setEditThreshold({});
                                                        setEditThresholdIndex();
                                                      }}
                                                    >
                                                      Update
                                                    </Button>
                                                    <Button
                                                      color="danger"
                                                      simple
                                                      style={{
                                                        padding: "0px",
                                                        textAlign: "center",
                                                      }}
                                                      onClick={() => {
                                                        setEditCategory();
                                                        setEditThreshold({});
                                                        setEditThresholdIndex();
                                                      }}
                                                    >
                                                      Cancel
                                                    </Button>
                                                  </>
                                                ) : (
                                                  <Grid container>
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      <Button
                                                        color="success"
                                                        style={{ padding: "0px" }}
                                                        simple
                                                        onClick={() => {
                                                          const currentDevice = device?.deviceTable[
                                                            val
                                                          ].data.find(
                                                            (val, cIndex) => {
                                                              return (
                                                                cIndex === index
                                                              );
                                                            }
                                                          );
                                                           
                                                          setEditCategory(val);
                                                          setEditThreshold(
                                                            currentDevice
                                                          );
                                                          setEditThresholdIndex(
                                                            index
                                                          );
                                                        }}
                                                      >
                                                        Edit
                                                      </Button>
                                                    </Grid>
                                                    {val !== "IDENTITY" && (
                                                      <Grid
                                                        item
                                                        xs={12}
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        <Button
                                                          color="info"
                                                          simple
                                                          style={{
                                                            padding: "0px",
                                                          }}
                                                          onClick={() => {
                                                            let obj = {
                                                              user: device?.deviceTable[
                                                                val
                                                              ].data.find(
                                                                (val, cIndex) => {
                                                                  return (
                                                                    cIndex ===
                                                                    index
                                                                  );
                                                                }
                                                              ),
                                                              codes:
                                                                device
                                                                  ?.deviceTableHeader[
                                                                  val
                                                                ]
                                                                  .devicePropertyCodes,
                                                              companyList:
                                                                company?.companyList,
                                                              devicePropertySetId:
                                                                device
                                                                  ?.deviceTable[
                                                                  val
                                                                ]
                                                                  .devicePropertySetId,
                                                              deviceDataId:
                                                                data.deviceDataId,
                                                              deviceDataModelId:
                                                                userInfo?.deviceDataModelId,
                                                              paginationObj: {
                                                                payload: {
                                                                  pageNumber:
                                                                    paginationData[
                                                                      val
                                                                    ]?.page,
                                                                  pageSize:
                                                                    paginationData[
                                                                      val
                                                                    ]
                                                                      ?.rowsPerPage,
                                                                  sortBy:
                                                                    "maxValueProvidedOn",
                                                                  sortOrder:
                                                                    "DESC",
                                                                },
                                                                data: {
                                                                  deviceDataModelId:
                                                                    userInfo?.deviceDataModelId,
                                                                  devicePropertyCodes:
                                                                    device
                                                                      ?.deviceTableHeader[
                                                                      val
                                                                    ]
                                                                      .devicePropertyCodes,
                                                                  devicePropertySetId:
                                                                    device
                                                                      ?.deviceTable[
                                                                      val
                                                                    ]
                                                                      .devicePropertySetId,
                                                                },
                                                                type: val,
                                                              },
                                                            };
                                                            if (
                                                              userInfo?.userId &&
                                                              userInfo?.userId !==
                                                              "all"
                                                            ) {
                                                              obj.paginationObj.data.ownerFilter = {
                                                                users: [
                                                                  userInfo?.userId,
                                                                ],
                                                              };
                                                            } else if (
                                                              userInfo?.supplierId &&
                                                              userInfo.supplierId !==
                                                              "all"
                                                            ) {
                                                              obj.paginationObj.data.ownerFilter = {
                                                                users: users?.userList?.users.reduce(
                                                                  (acc, val) => {
                                                                    if (
                                                                      userInfo?.supplierId ===
                                                                      val
                                                                        ?.roles[0]
                                                                        .supplier
                                                                        ?.supplierId
                                                                    ) {
                                                                      return [
                                                                        ...acc,
                                                                        val.userId,
                                                                      ];
                                                                    }
                                                                    return acc;
                                                                  },
                                                                  []
                                                                ),
                                                              };
                                                            }
                                                            updateDefaultData(
                                                              obj
                                                            );
                                                          }}
                                                        >
                                                          Set Default
                                                        </Button>
                                                      </Grid>
                                                    )}
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      <Button
                                                        color="danger"
                                                        simple
                                                        style={{
                                                          padding: "0px",
                                                          textAlign: "center",
                                                        }}
                                                        onClick={() => {
                                                          let paginationObj = {
                                                            payload: {
                                                              pageNumber:
                                                                paginationData[
                                                                  val
                                                                ]?.page,
                                                              pageSize:
                                                                paginationData[
                                                                  val
                                                                ]?.rowsPerPage,
                                                              sortBy:
                                                                "maxValueProvidedOn",
                                                              sortOrder: "DESC",
                                                            },
                                                            data: {
                                                              deviceCriteria: userInfo.macAddress && userInfo.macAddress !== 'all' ? getDeviceCriteriaObj(val, userInfo.macAddress) : [],
                                                              deviceId:
                                                                data?.deviceId,
                                                              devicePropertyCodes:
                                                                device
                                                                  ?.deviceTableHeader[
                                                                  val
                                                                ]
                                                                  .devicePropertyCodes,
                                                              devicePropertySetId:
                                                                device
                                                                  ?.deviceTable[
                                                                  val
                                                                ]
                                                                  .devicePropertySetId,
                                                            },
                                                            type: val,
                                                          };
                                                          if (
                                                            userInfo?.userId &&
                                                            userInfo?.userId !==
                                                            "all"
                                                          ) {
                                                            paginationObj.data.ownerFilter = {
                                                              users: [
                                                                userInfo?.userId,
                                                              ],
                                                            };
                                                          } else if (
                                                            userInfo?.supplierId &&
                                                            userInfo.supplierId !==
                                                            "all"
                                                          ) {
                                                            paginationObj.data.ownerFilter = {
                                                              users: users?.userList?.users.reduce(
                                                                (acc, val) => {
                                                                  if (
                                                                    userInfo?.supplierId ===
                                                                    val?.roles[0]
                                                                      .supplier
                                                                      ?.supplierId
                                                                  ) {
                                                                    return [
                                                                      ...acc,
                                                                      val.userId,
                                                                    ];
                                                                  }
                                                                  return acc;
                                                                },
                                                                []
                                                              ),
                                                            };
                                                          }
                                                           
                                                          deleteDeviceData({
                                                            deviceDataId: data.deviceDataId,
                                                            deviceDataModelId: data?.deviceDataModelId,
                                                            paginationObj,
                                                            type: val,
                                                          });
                                                        }}
                                                      >
                                                        Delete
                                                      </Button>
                                                    </Grid>
                                                  </Grid>
                                                )}
                                              </TableCell>
                                            )}
                                        </TableRow>
                                      );
                                    }
                                  }
                                )}
                              </TableBody>
                            </Table>
                            <TablePagination
                              style={{
                                float: "left",
                                paddingLeft: "0px",
                              }}
                              rowsPerPageOptions={PAGE_SIZES}
                              component="div"
                              count={device?.deviceTable[val]?.totalElements}
                              rowsPerPage={paginationData[val]?.rowsPerPage}
                              page={paginationData[val]?.page}
                              onChangePage={(e, page) => {
                                let previousData = paginationData;
                                previousData[val].page = page;
                                setPaginationData(previousData);
                                const deviceDataModelId =
                                  device?.deviceTableHeader[val].deviceDataModelId;
                                const devicePropertyCodes =
                                  device?.deviceTableHeader[val]
                                    .devicePropertyCodes;
                                const devicePropertySetId =
                                  device?.deviceTableHeader[val]
                                    .devicePropertySetId;
                                handleChangeRowsPerPage(
                                  val,
                                  previousData[val].rowsPerPage,
                                  devicePropertyCodes,
                                  deviceDataModelId,
                                  page,
                                  devicePropertySetId
                                );
                              }}
                              onChangeRowsPerPage={(e) => {
                                let previousData = paginationData;
                                previousData[val].rowsPerPage = e.target.value;
                                setPaginationData(previousData);
                                const deviceDataModelId =
                                  device?.deviceTableHeader[val].deviceDataModelId;
                                const devicePropertyCodes =
                                  device?.deviceTableHeader[val]
                                    .devicePropertyCodes;
                                const pageNo = previousData[val].page;
                                const devicePropertySetId =
                                  device?.deviceTableHeader[val]
                                    .devicePropertySetId;
                                handleChangeRowsPerPage(
                                  val,
                                  e.target.value,
                                  devicePropertyCodes,
                                  deviceDataModelId,
                                  pageNo,
                                  devicePropertySetId
                                );
                              }}
                            />
                          </TableContainer>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </GridItem>
            );
          }
        })}
        {Object.keys(device?.deviceTable).length > 0 && (
          <GridItem xs={12}>
            <Box paddingBottom={2}>
              <Accordion key="customTable">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-label="Expand"
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box width="100%">
                    <Typography>User Profile</Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Typography>Resident Name</Typography>
                          </TableCell>
                          {customFields?.list.map((field) => {
                            let width = "100px";
                            if (
                              field.name === "Enable Alerts" ||
                              field.name === "Enable Reports" ||
                              field.name === "Location-OLD"
                            ) {
                              width = "200px";
                            } else if (field.name === "TimeZone") {
                              width = "250px";
                            } else if (field.name === "Sensors") {
                              width = "350px";
                            }
                            return (
                              <TableCell
                                key={field.fieldId}
                                style={{ minWidth: width }}
                              >
                                <Typography>{field.name}</Typography>
                              </TableCell>
                            );
                          })}
                          <TableCell>
                            <Typography>Actions</Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {users.userDeviceList.totalElements === 0 && (
                          <TableRow>
                            <TableCell
                              align="center"
                              colSpan={customFields?.list.length + 1}
                            >
                              No records found
                            </TableCell>
                          </TableRow>
                        )}
                        {users.userDeviceList.users.map((data, index) => {
                          if (data?.userId) {
                            return (
                              <TableRow key={index}>
                                <TableCell>{data.fullName}</TableCell>
                                {customFields?.list.map((field) => {
                                  const cData = data?.customData.find(
                                    (cd) => cd.field.fieldId === field.fieldId
                                  );
                                  let cValue = cData?.fieldData;
                                  if (typeof cData?.fieldData === "object") {
                                    cValue = cData?.fieldData.toString();
                                  }
                                  if (editResidentIndex === index) {
                                    if (
                                      field.name === "T&C accepted" ||
                                      field.name ===
                                      "Privacy Policy Accepted" ||
                                      field.name === "APP_view" ||
                                      field.name === "Guest Account"
                                    ) {
                                      return (
                                        <TableCell key={`date-index-${index}`}>
                                          {cValue}
                                        </TableCell>
                                      );
                                    } else if (field.type === "DateTime") {
                                      return (
                                        <TableCell key={`date-index-${index}`}>
                                          {cValue
                                            ? moment()
                                              .utc(cValue)
                                              .utcOffset(0)
                                              .format("MMM Do YY, h:mm:ss a")
                                            : cValue}
                                        </TableCell>
                                      );
                                    } else {
                                      const editData = editResidentData.find(
                                        (dVal) =>
                                          dVal.field.fieldId === field.fieldId
                                      );
                                      return (
                                        <TableCell>
                                          {field.name === "TimeZone" && (
                                            <SelectBox
                                              id={field.fieldId}
                                              labelText={field.name}
                                              required={true}
                                              value={editData?.fieldData}
                                              onChange={(e) => {
                                                if (
                                                  editResidentData.find(
                                                    (aVal) =>
                                                      aVal.field.fieldId ===
                                                      field.fieldId
                                                  )
                                                ) {
                                                  let updatedCustomData = editResidentData.map(
                                                    (aVal) => {
                                                      if (
                                                        aVal.field.fieldId ===
                                                        field.fieldId
                                                      ) {
                                                        aVal.fieldData =
                                                          e.target.value;
                                                        return aVal;
                                                      }
                                                      return aVal;
                                                    }
                                                  );
                                                  setEditResidentData(
                                                    updatedCustomData
                                                  );
                                                } else {
                                                  let obj = {
                                                    fieldData: e.target.value,
                                                    field,
                                                  };
                                                  setEditResidentData([
                                                    ...editResidentData,
                                                    obj,
                                                  ]);
                                                }
                                              }}
                                              options={timezones.map((val) => (
                                                <MenuItem
                                                  classes={{
                                                    root:
                                                      classes.selectMenuItem,
                                                  }}
                                                  key={val}
                                                  value={val.value}
                                                >
                                                  {`${val.text} (${val.abbr})`}
                                                </MenuItem>
                                              ))}
                                            />
                                          )}
                                          {field.type === "SingleSelect" && (
                                            <SelectBox
                                              id={field.fieldId}
                                              labelText={field.name}
                                              disabled={
                                                field.name === "Guest Account"
                                                  ? true
                                                  : false
                                              }
                                              required={true}
                                              value={editData?.fieldData}
                                              onChange={(e) => {
                                                if (
                                                  editResidentData.find(
                                                    (aVal) =>
                                                      aVal.field.fieldId ===
                                                      field.fieldId
                                                  )
                                                ) {
                                                  let updatedCustomData = editResidentData.map(
                                                    (aVal) => {
                                                      if (
                                                        aVal.field.fieldId ===
                                                        field.fieldId
                                                      ) {
                                                        aVal.fieldData =
                                                          e.target.value;
                                                        return aVal;
                                                      }
                                                      return aVal;
                                                    }
                                                  );
                                                  setEditResidentData(
                                                    updatedCustomData
                                                  );
                                                } else {
                                                  let obj = {
                                                    fieldData: e.target.value,
                                                    field,
                                                  };
                                                  setEditResidentData([
                                                    ...editResidentData,
                                                    obj,
                                                  ]);
                                                }
                                              }}
                                              options={field.optionValues.map(
                                                (val) => (
                                                  <MenuItem
                                                    classes={{
                                                      root:
                                                        classes.selectMenuItem,
                                                    }}
                                                    key={val}
                                                    value={val}
                                                  >
                                                    {val}
                                                  </MenuItem>
                                                )
                                              )}
                                            />
                                          )}
                                          {field.type === "MultiSelect" && (
                                            <Autocomplete
                                              multiple
                                              fullWidth
                                              options={field.optionValues}
                                              className={classes.inputColor}
                                              disableCloseOnSelect
                                              color="primary"
                                              value={editData?.fieldData}
                                              renderOption={(
                                                option,
                                                { selected }
                                              ) => (
                                                <React.Fragment>
                                                  <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                  />
                                                  {option}
                                                </React.Fragment>
                                              )}
                                              onChange={(event, val) => {
                                                if (
                                                  editResidentData.find(
                                                    (aVal) =>
                                                      aVal.field.fieldId ===
                                                      field.fieldId
                                                  )
                                                ) {
                                                  let updatedCustomData = editResidentData.map(
                                                    (aVal) => {
                                                      if (
                                                        aVal.field.fieldId ===
                                                        field.fieldId
                                                      ) {
                                                        aVal.fieldData =
                                                          val.length === 0
                                                            ? []
                                                            : val;
                                                        return aVal;
                                                      }
                                                      return aVal;
                                                    }
                                                  );
                                                  setEditResidentData(
                                                    updatedCustomData
                                                  );
                                                } else {
                                                  let obj = {
                                                    fieldData:
                                                      val.length === 0
                                                        ? []
                                                        : val,
                                                    field,
                                                  };
                                                  setEditResidentData([
                                                    ...editResidentData,
                                                    obj,
                                                  ]);
                                                }
                                              }}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  required={true}
                                                  InputLabelProps={{
                                                    shrink: true,
                                                    classes: {
                                                      focused:
                                                        classes.labelFocused,
                                                    },
                                                  }}
                                                  SelectProps={{
                                                    classes: {
                                                      focused:
                                                        classes.labelFocused,
                                                    },
                                                  }}
                                                />
                                              )}
                                            />
                                          )}
                                          {field.type === "MultiLineText" && (
                                            <TextareaAutosize
                                              value={editData?.fieldData}
                                              rowsMax={5}
                                              rowsMin={3}
                                              style={{
                                                width: "100%",
                                              }}
                                              onChange={(e) => {
                                                if (
                                                  editResidentData.find(
                                                    (aVal) =>
                                                      aVal.field.fieldId ===
                                                      field.fieldId
                                                  )
                                                ) {
                                                  let updatedCustomData = editResidentData.map(
                                                    (aVal) => {
                                                      if (
                                                        aVal.field.fieldId ===
                                                        field.fieldId
                                                      ) {
                                                        aVal.fieldData =
                                                          e.target.value;
                                                        return aVal;
                                                      }
                                                      return aVal;
                                                    }
                                                  );
                                                  setEditResidentData(
                                                    updatedCustomData
                                                  );
                                                } else {
                                                  let obj = {
                                                    fieldData: e.target.value,
                                                    field,
                                                  };
                                                  setEditResidentData([
                                                    ...editResidentData,
                                                    obj,
                                                  ]);
                                                }
                                              }}
                                            />
                                          )}
                                          {field.type === "SingleLineText" &&
                                            field.name !== "TimeZone" && (
                                              <InputBox
                                                value={editData?.fieldData}
                                                onChange={(e) => {
                                                  if (
                                                    editResidentData.find(
                                                      (aVal) =>
                                                        aVal.field.fieldId ===
                                                        field.fieldId
                                                    )
                                                  ) {
                                                    let updatedCustomData = editResidentData.map(
                                                      (aVal) => {
                                                        if (
                                                          aVal.field.fieldId ===
                                                          field.fieldId
                                                        ) {
                                                          aVal.fieldData =
                                                            e.target.value;
                                                          return aVal;
                                                        }
                                                        return aVal;
                                                      }
                                                    );
                                                    setEditResidentData(
                                                      updatedCustomData
                                                    );
                                                  } else {
                                                    let obj = {
                                                      fieldData: e.target.value,
                                                      field,
                                                    };
                                                    setEditResidentData([
                                                      ...editResidentData,
                                                      obj,
                                                    ]);
                                                  }
                                                }}
                                              />
                                            )}
                                        </TableCell>
                                      );
                                    }
                                  } else {
                                    return (
                                      <TableCell>
                                        <Typography>
                                          {field.type === "DateTime" && cValue
                                            ? moment()
                                              .utc(cValue)
                                              .utcOffset(0)
                                              .format("MMM Do YY, h:mm:ss a")
                                            : cValue}
                                        </Typography>
                                      </TableCell>
                                    );
                                  }
                                })}
                                <TableCell>
                                  <Button
                                    style={{
                                      padding: "0px",
                                      textAlign: "center",
                                    }}
                                    color="success"
                                    simple
                                    onClick={() => {
                                      if (editResidentIndex === index) {
                                        let linkedTimeField = editResidentData.find(
                                          (val) =>
                                            val.field.name === "TimeStamp-UP"
                                        );
                                        let updatedData = [];
                                        if (!linkedTimeField) {
                                          let linkedTimeEntity = customFields.list.find(
                                            (val) => val.name === "TimeStamp-UP"
                                          );
                                          updatedData = [
                                            ...editResidentData,
                                            {
                                              fieldData: moment()
                                                .utc()
                                                .format("YYYY-MM-DDTHH:mm:ss"),
                                              field: linkedTimeEntity,
                                            },
                                          ];
                                        } else {
                                          updatedData = editResidentData.map(
                                            (val) => {
                                              if (
                                                val.field.name ===
                                                "TimeStamp-UP"
                                              ) {
                                                val.fieldData = moment()
                                                  .utc()
                                                  .format(
                                                    "YYYY-MM-DDTHH:mm:ss"
                                                  );
                                              }
                                              return val;
                                            }
                                          );
                                        }
                                        let userObj = {
                                          user: data,
                                          customData: updatedData,
                                        };
                                        let paginationObj = customPaginationData;
                                        if (userInfo.userId) {
                                          const userEmailObj = users?.userList?.users.find(
                                            (val) =>
                                              val.userId === userInfo.userId
                                          );
                                          paginationObj.emailAddress =
                                            userEmailObj.emailAddress;
                                        }
                                        updateResidentUser({
                                          userObj,
                                          paginationObj,
                                        });
                                        setEditResidentIndex();
                                        setEditResidentData([]);
                                      } else {
                                        setEditResidentIndex(index);
                                        setEditResidentData(data.customData);
                                      }
                                    }}
                                  >
                                    {editResidentIndex === index
                                      ? "Update"
                                      : "Edit"}
                                  </Button>
                                  {editResidentIndex === index && (
                                    <Button
                                      style={{
                                        padding: "0px",
                                        textAlign: "center",
                                      }}
                                      color="danger"
                                      simple
                                      onClick={() => {
                                        setEditResidentIndex();
                                        setEditResidentData([]);
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          }
                        })}
                      </TableBody>
                    </Table>
                    <TablePagination
                      style={{
                        float: "left",
                        paddingLeft: "0px",
                      }}
                      rowsPerPageOptions={PAGE_SIZES}
                      component="div"
                      count={users.userDeviceList.totalElements}
                      rowsPerPage={customPaginationData?.pageSize}
                      page={customPaginationData?.pageNumber}
                      onChangePage={(e, page) => {
                        let previousData = customPaginationData;
                        previousData.pageNumber = page;
                        setCustomPaginationData(previousData);
                        getUserAndCustomList(previousData);
                      }}
                      onChangeRowsPerPage={(e) => {
                        let previousData = customPaginationData;
                        previousData.pageSize = e.target.value;
                        setCustomPaginationData(previousData);
                        getUserAndCustomList(previousData);
                      }}
                    />
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            </Box>
          </GridItem>
        )}
        <GridItem xs={12}>
          <Box paddingBottom={2}>
            <Accordion key="customTable">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box width="100%">
                  <Typography>Diagnostic Data</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>{diagnosticTable()}</AccordionDetails>
            </Accordion>
          </Box>
        </GridItem>
        <GridItem xs={12}>
          <Box paddingBottom={2}>
            <Accordion key="customTable">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box width="100%">
                  <Typography>Company Default Value</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>{companyTable()}</AccordionDetails>
            </Accordion>
          </Box>
        </GridItem>
      </GridContainer>
      <Loader status={device.loading || users.loading || company.loading} />
      <Snackbar
        place="tr"
        color="danger"
        icon={AddAlert}
        message={alertMessage}
        open={toastStatus}
        closeNotification={() => setToastStatus(false)}
        close
      />
      {alert}
    </>
  );
}
