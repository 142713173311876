import { createStore, applyMiddleware, compose } from "redux";
import { createEpicMiddleware } from "redux-observable";

import epics from "./epics";
import reducers from "./reducers";

const epicMiddleware = createEpicMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = () => {
  const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(epicMiddleware))
  );
  epicMiddleware.run(epics);
  return store;
};

export default configureStore;
