import { useDispatch } from "react-redux";
import { useReducer, useCallback } from "react";

export const useCallbackDispatch = (type) => {
  const dispatch = useDispatch();
  return useCallback((payload) => dispatch({ type, payload }), [
    dispatch,
    type,
  ]);
};

export const useComposeDispatcher = (...args) => {
  const dispatch = useDispatch();
  return args.map((type) => (payload) => dispatch({ type, payload }));
};

export const useDefaultReducer = (initialState) => {
  const [state, _dispatch] = useReducer(
    (_state, { type, payload }) => ({ ..._state, [type]: payload }),
    initialState
  );
  const dispatch = (type) => (payload) => _dispatch({ type, payload });

  return [state, dispatch, _dispatch];
};
