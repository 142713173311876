import React from "react";
import moment from "moment-timezone";

import FavoriteBorderSharpIcon from '@material-ui/icons/FavoriteBorderSharp';
import FavoriteSharpIcon from '@material-ui/icons/FavoriteSharp';
import SettingsIcon from "@material-ui/icons/Settings";
import WifiOffIcon from '@material-ui/icons/WifiOff';
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

// core components
import { DEFAULT_TIMEZONE, DEFAULT_TIMEOFFSET } from "../../common/constants";
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import timezones from "../../common/timezones.json";
import Setting from "./Setting";

import heart_rate from "assets/img/heart_rate-2-512.png";
import stress from "assets/img/stress.png";
import motion from "assets/img/motion.png";
import lungs from "assets/img/resp.png";

export default function Devices({
  updateSensorControlTableRequest,
  updateSensorControlRequest,
  sensorControlRequest,
  setShowSensorControl,
  setDayLightSaving,
  showSensorControl,
  setUserMacAddress,
  startGraphLoader,
  setReadingParams,
  setFriendlyName,
  setUserTimeZone,
  setAlertMessage,
  setRealTimeType,
  selectedDevice,
  loggedUserRole,
  resetGraphData,
  intervalChange,
  userMacAddress,
  setToastStatus,
  onIndexUpdate,
  friendlyName,
  userTimeZone,
  setUserInfo,
  lastFilter,
  setSensor,
  setDrawer,
  setOffset,
  userData,
  userInfo,
  setAbbr,
  device,
  sensor,
  offset,
  abbr
}) {

  return (
    <>
      {device.graphData.map((val, i) => {
        const readingParam = ["Hr", "Rr", "Stress", "Motion", "DevidPvital","Occupied"];
        const userDayLightSaving = val.dayLightSaving || "No";

        let newData = {
          Hr: { value: "--" },
          Rr: { value: "--" },
          Stress: { value: "--" },
          Motion: { value: "--" },
          FriendlyName: { value: "--" },
          Location: { value: "--" },
          active: { value: "No" },
        };
        var isOccupied = false
        if (
          selectedDevice.length === 0 ||
          selectedDevice.find((data) => data === val.deviceName)
        ) {
          let gridLength = 1;
          if (val.linkedGraphData) {
            gridLength = Object.keys(val.linkedGraphData).length || 1;
          }
          let currentUtcFormatTime = moment().utc().format("YYYY-MM-DDTHH:mm:ss");
          let currentUtcTime = moment(currentUtcFormatTime);
         

          return (
            <GridItem xs={gridLength >= 3 ? 12 : gridLength * 2} key={"deivce" + i}>
              <Box bgcolor="#ffffff" padding={1} borderRadius="5px" marginBottom={2}>
                <Typography noWrap={true} style={{ paddingTop: "2%", textAlign: "center" }}>
                  <b>{val?.fullName}</b>
                </Typography>
                &nbsp;&nbsp;
                <Grid container spacing={1}>
                  {val.linkedGraphData &&
                    Object.keys(val.linkedGraphData).map((linkedData, index) => {
                        if (val?.linkedGraphData[linkedData]?.data?.length) {
                          const currentReading = val.linkedGraphData[linkedData].data[0];
                          const heartLastTime = moment(currentReading.data.Hr.valueProvidedOn);
                          const respiratoryLastTime = moment(currentReading.data.Rr.valueProvidedOn);
                          const stressLastTime = moment(currentReading.data.Stress.valueProvidedOn);
                          const motionLastTime = moment(currentReading.data.Motion.valueProvidedOn);
                          isOccupied = currentReading.data?.Occupied?.value === 1 ? true : false
                          
                          newData = {
                            Hr: {
                              value:
                                currentUtcTime.diff(heartLastTime, "seconds") <
                                60 ? Math.round(val.linkedGraphData[linkedData].data[0].data.Hr.value) : "--",
                            },
                            Rr: {
                              value:
                                currentUtcTime.diff(respiratoryLastTime, "seconds") < 
                                60 ? Math.round(val.linkedGraphData[linkedData].data[0].data.Rr.value) : "--",
                            },
                            Stress: {
                              value:
                                currentUtcTime.diff(stressLastTime, "seconds") <
                                60 ? Math.round(val.linkedGraphData[linkedData].data[0].data.Stress.value): "--",
                            },
                            Motion: {
                              value: currentUtcTime.diff(motionLastTime, "seconds") <
                                60 ? Math.round(val.linkedGraphData[linkedData].data[0].data.Motion.value) : "--",
                            },
                            FriendlyName: {value: val.linkedGraphData[linkedData].FriendlyName},
                            Location: {value: val.linkedGraphData[linkedData].Location},
                            active: {value: val.linkedGraphData[linkedData].active},
                          };
                        } else {
                          newData = {
                            Hr: { value: "--" },
                            Rr: { value: "--" },
                            Stress: { value: "--" },
                            Motion: { value: "--" },
                            FriendlyName: { value: "--" },
                            Location: { value: "--" },
                            active: { value: "No" },
                          };
                        }
                        newData.FriendlyName.value = val.linkedGraphData[linkedData].FriendlyName || "--";
                        newData.Location.value = val.linkedGraphData[linkedData].Location || "--";
                        newData.active.value = val.linkedGraphData[linkedData].active || "No";
                        let rightLineCss = "none";
                        let leftLineCss = "none";
                        if ( index === Object.keys(val.linkedGraphData).length - 1) {
                          rightLineCss = "1px solid #e0e0e0";
                          rightLineCss = "none";
                        }

                        let mainSectionDesign = {
                          backgroundColor: "#eeeeee",
                          padding: "10px",
                          borderRadius: "10px",
                        };

                        if (Object.keys(val.linkedGraphData).length === 1) {
                          rightLineCss = "none";
                          leftLineCss = "none";
                          mainSectionDesign = {};
                        }

                        if (newData.active.value === "No") {
                          mainSectionDesign.backgroundColor = "#ebd7d7";
                          mainSectionDesign.borderRadius = "10px";
                          mainSectionDesign.paddingTop = "10px";
                          mainSectionDesign.paddingBottom = "10px";
                        }
                        let fwVersion = "";
                        let hwVersion = "";
                        let wifiRss = "";
                        let occupied = 0;
                        let apneaperminute = "--";
                        
                        if (val.linkedGraphData[linkedData].Hwvers) {
                          hwVersion = val.linkedGraphData[linkedData].Hwvers;
                        }
                        if (val.linkedGraphData[linkedData].WifiRss) {
                          let tempWifiRss = val.linkedGraphData[linkedData].WifiRss;
                          if(val.linkedGraphData[linkedData].data[0] !== undefined){
                            //if (val.linkedGraphData[linkedData].data[0].data.RssHrMv)
                            let rssRrMv = Math.round(val.linkedGraphData[linkedData].data[0].data.RssRrMv.value) 
                            wifiRss = tempWifiRss + "/" + rssRrMv
                          }else{
                            wifiRss = tempWifiRss
                          } 
                           
                        }
                        if (val.linkedGraphData[linkedData].Fwvers) {
                          fwVersion = val.linkedGraphData[linkedData].Fwvers;
                        }
                        if (val.linkedGraphData[linkedData].occupied) {
                          occupied = val.linkedGraphData[linkedData].occupied;
                        }
                        apneaperminute =
                          val.linkedGraphData[linkedData].apneaperminute;

                        const userTimeZone =
                          val.linkedGraphData[linkedData].timezone;
                        const selectedZone =
                          timezones.find(
                            (time) => time.value === userTimeZone
                          ) || {};
                        selectedZone.offset =
                          selectedZone.offset || DEFAULT_TIMEOFFSET;
                        selectedZone.abbr =
                          selectedZone.abbr || DEFAULT_TIMEZONE;
                        if (!selectedZone.utc) {
                          selectedZone.utc = timezones.find(
                            (time) => time.abbr === DEFAULT_TIMEZONE
                          ).utc;
                        }
                        return (
                          <Grid item xs={gridLength === 1 ? 12 : gridLength === 3 ? 2 : gridLength >= 4 ? 2 : 6} key={"sensor" + index}>
                            <Box style={mainSectionDesign}>
                              <div style={{display: "flex", alignItems: "center", justifyContent: "space-evenly"}}>
                                <div style={{textAlign: "center",fontWeight: 600,fontSize: "12px"}}>
                                  <span>{newData.FriendlyName.value || "--"}</span>
                                  <p>{newData.Location.value || "--"}</p>
                                </div>
                                {
                                  Object.keys(val.linkedGraphData).length > 1 && userInfo.userId !== 'all' &&
                                  <div>
                                    <select value={index+1} onChange={e => onIndexUpdate(e.target.value, val, linkedData)}>
                                      {Object.keys(val.linkedGraphData).map((linkedData, i) => {
                                        return <option value={i+1}>{i+1}</option>
                                      })}
                                    </select>
                                  </div>
                                }
                                <div className={val?.linkedGraphData[linkedData]?.data[0]?.data?.Offline?.value.toLowerCase() ==="no" ?  isOccupied ? "heartBeatAnimation" : '' : ""}>
                                  {val?.linkedGraphData[linkedData]?.data[0]?.data?.Offline?.value.toLowerCase() ==="no" ? isOccupied ? <FavoriteSharpIcon style={{ color: '#ed193b' }} /> : <FavoriteBorderSharpIcon /> : <WifiOffIcon />}
                                </div>
                              </div>
                              <Box style={{borderLeft: leftLineCss,borderRight: rightLineCss}}>
                                {readingParam.map((val) => {
                                  if (val === "Hr") {
                                    return (
                                      <Box
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="center"
                                      >
                                        <Box
                                          width="33%"
                                          justifyContent="center"
                                        >
                                          <Avatar
                                            src={heart_rate}
                                            style={{
                                              verticalAlign: "middle",
                                              margin: "auto",
                                            }}
                                          />
                                        </Box>
                                        <Box
                                          width="33%"
                                          paddingTop="10px"
                                          textAlign="center"
                                          fontWeight="500"
                                          fontSize="10px"
                                          justifyContent="center"
                                        >
                                          Heart
                                        </Box>
                                        <Box
                                          width="33%"
                                          style={{
                                            verticalAlign: "middle",
                                            margin: "auto",
                                            textAlign: "center",
                                          }}
                                        >
                                          <Typography
                                            style={{
                                              fontSize: "22px",
                                              fontWeight: "700",
                                            }}
                                          >
                                            {newData[val].value}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    );
                                  } else if (val === "Rr") {
                                    return (
                                      <Box
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="center"
                                      >
                                        <Box
                                          width="33%"
                                          justifyContent="center"
                                        >
                                          <Avatar
                                            src={lungs}
                                            style={{
                                              verticalAlign: "middle",
                                              margin: "auto",
                                            }}
                                          />
                                        </Box>
                                        <Box
                                          width="33%"
                                          paddingTop="10px"
                                          textAlign="center"
                                          fontWeight="500"
                                          fontSize="10px"
                                          justifyContent="center"
                                        >
                                          Breath
                                        </Box>
                                        <Box
                                          width="33%"
                                          style={{
                                            verticalAlign: "middle",
                                            margin: "auto",
                                            textAlign: "center",
                                          }}
                                        >
                                          <Typography
                                            style={{
                                              fontSize: "22px",
                                              fontWeight: "700",
                                            }}
                                          >
                                            {newData[val].value}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    );
                                  } else if (val === "Stress") {
                                    return (
                                      <Box
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="center"
                                      >
                                        <Box
                                          width="33%"
                                          justifyContent="center"
                                        >
                                          <Avatar
                                            src={stress}
                                            style={{
                                              verticalAlign: "middle",
                                              margin: "auto",
                                            }}
                                          />
                                        </Box>
                                        <Box
                                          width="33%"
                                          paddingTop="10px"
                                          textAlign="center"
                                          fontWeight="500"
                                          fontSize="10px"
                                          justifyContent="center"
                                        >
                                          Stress
                                        </Box>
                                        <Box
                                          width="33%"
                                          style={{
                                            verticalAlign: "middle",
                                            margin: "auto",
                                            textAlign: "center",
                                          }}
                                        >
                                          <Typography
                                            style={{
                                              fontSize: "22px",
                                              fontWeight: "700",
                                            }}
                                          >
                                            {newData[val]
                                              ? newData[val].value
                                              : "--"}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    );
                                  } else if (val === "Motion") {
                                    return (
                                      <Box
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="center"
                                      >
                                        <Box
                                          width="33%"
                                          justifyContent="center"
                                        >
                                          <Avatar
                                            src={motion}
                                            style={{
                                              verticalAlign: "middle",
                                              margin: "auto",
                                            }}
                                          />
                                        </Box>
                                        <Box
                                          width="33%"
                                          paddingTop="10px"
                                          textAlign="center"
                                          fontWeight="500"
                                          fontSize="10px"
                                          justifyContent="center"
                                        >
                                          Motion
                                        </Box>
                                        <Box
                                          width="33%"
                                          style={{
                                            verticalAlign: "middle",
                                            margin: "auto",
                                            textAlign: "center",
                                          }}
                                        >
                                          <Typography
                                            style={{
                                              fontSize: "22px",
                                              fontWeight: "700",
                                            }}
                                          >
                                            {newData[val]
                                              ? newData[val].value
                                              : "--"}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    );
                                  }
                                })}
                              </Box>
                              <Box
                                style={{cursor: "pointer"}}
                                marginTop="2%"
                                textAlign="center"
                                justifyContent="center"
                              >
                                <Button
                                  size="sm"
                                  color="danger"
                                  onClick={() => {
                                    const HRValues = [],
                                      RRValues = [],
                                      Timings = [];
                                    if (val?.linkedGraphData[linkedData]?.data?.length) {
                                      val.linkedGraphData[linkedData].data.forEach((val) => {
                                        Timings.push(val.data.Hr.valueProvidedOn);
                                        HRValues.push([new Date(val.data.Hr.valueProvidedOn), val.data.Hr.value]);
                                        RRValues.push([new Date(val.data.Hr.valueProvidedOn), val.data.Rr.value]);
                                      });
                                    } else {
                                      const currentTime = moment().utc().format("YYYY-MM-DDTHH:mm:ss");
                                      HRValues.push([new Date(currentTime), 0]);
                                      RRValues.push([new Date(currentTime), 0]);
                                    }
                                    const graphIntervalObj = val.customFieldData.find(
                                      (val) => val.field.name === "Plot_range_default"
                                    );
                                    let obj = { interval: "30 mins", devID: linkedData,};
                                    if (graphIntervalObj?.fieldData) {
                                      obj = {interval: "30 mins", devID: linkedData};
                                    }
                                    setDrawer(true);
                                    setRealTimeType(true);
                                    setSensor(val);
                                    setReadingParams(readingParam);
                                    setUserInfo({ ...userInfo, ...obj });
                                    const lastFilterData = lastFilter;
                                    lastFilterData.devHealth.ownerFilter.users = [
                                      val.userId,
                                    ];
                                    lastFilterData.pVittal.ownerFilter.users = [
                                      val.userId,
                                    ];
                                    setTimeout(() => {
                                      intervalChange(
                                        selectedZone.utc[0],
                                        "",
                                        obj.interval,
                                        linkedData,
                                        val.userId,
                                        selectedZone.offset,
                                        userDayLightSaving === "Yes" ? 1 : 0
                                      );
                                    }, 100);
                                    // callReadingByUser(linkedData, val.userId);
                                    startGraphLoader();
                                    setUserTimeZone(selectedZone.utc[0]);
                                    setOffset(selectedZone.offset);
                                    setAbbr(selectedZone.abbr);
                                    setDayLightSaving(userDayLightSaving === "Yes" ? 1 : 0);
                                    setFriendlyName(val.linkedGraphData[linkedData].FriendlyName);
                                  }}
                                >
                                  History
                                </Button>
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="center"
                              >
                                <Box
                                  textAlign="center"
                                  style={{ fontSize: "11px" }}
                                >
                                  <b>Active: {newData.active.value || "--"}</b>
                                </Box>
                                <Box textAlign="center"></Box>
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="center"
                              >
                                <Box
                                  textAlign="center"
                                  style={{ fontSize: "11px" }}
                                >
                                  <b>Fwvers: {fwVersion || "--"}</b>
                                </Box>
                                <Box textAlign="center"></Box>
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="center"
                              >
                                <Box
                                  textAlign="center"
                                  style={{ fontSize: "11px" }}
                                >
                                  <b>Hwvers: {hwVersion || "--"}</b>
                                </Box>
                                <Box textAlign="center"></Box>
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="center"
                              >
                                <Box
                                  textAlign="center"
                                  style={{ fontSize: "11px" }}
                                >
                                  <b>WifiRss: {wifiRss || "--"}</b>
                                </Box>
                                <Box textAlign="center"></Box>
                              </Box>
                              <Box textAlign="center">
                                <p style={{ fontSize: "11px" }}>
                                  <b>Last updated on:</b>
                                </p>
                                {val.linkedGraphData[linkedData] &&
                                val.linkedGraphData[linkedData].data &&
                                val.linkedGraphData[linkedData].data.length >
                                  0 &&
                                val.linkedGraphData[linkedData].data[0] &&
                                val.linkedGraphData[linkedData].data[0]
                                  .minValueProvidedOn ? (
                                  <p style={{ fontSize: "11px" }}>
                                    {moment
                                      .utc(
                                        val.linkedGraphData[linkedData].data[0]
                                          ?.minValueProvidedOn
                                      )
                                      .tz(selectedZone.utc[0])
                                      .format("MMM Do YY, h:mm:ss a")}{" "}
                                    ({selectedZone.abbr})
                                  </p>
                                ) : (
                                  <p style={{ fontSize: "11px" }}>--</p>
                                )}
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="center"
                              >
                                {val?.linkedGraphData[linkedData]?.data?.length ? (
                                  val?.linkedGraphData[linkedData]?.data[0]?.data?.Offline?.value.toLowerCase() ==="no" ? (
                                    <Box textAlign="center">
                                      <div
                                        style={{
                                          width: "10px",
                                          height: "10px",
                                          background: "green",
                                          borderRadius: "50%",
                                          display: "inline-block",
                                        }}
                                      ></div>{" "}
                                      <b>Online</b>
                                    </Box>
                                  ) : (
                                    <Box textAlign="center">
                                      <div
                                        style={{
                                          width: "10px",
                                          height: "10px",
                                          background: "red",
                                          borderRadius: "50%",
                                          display: "inline-block",
                                        }}
                                      ></div>{" "}
                                      <b>Offline</b>
                                    </Box>
                                  )
                                ) : (
                                  <Box textAlign="center">
                                    <div
                                      style={{
                                        width: "10px",
                                        height: "10px",
                                        background: "red",
                                        borderRadius: "50%",
                                        display: "inline-block",
                                      }}
                                    ></div>{" "}
                                    <b>Offline</b>
                                  </Box>
                                )}
                              </Box>
                              <Box>
                                {
                                  <Box textAlign="center" style={{ fontSize: "11px" }}>
                                  <div
                                    style={{
                                      width: "10px",
                                      height: "10px",
                                      background: `${isOccupied ? "green" : "red"}`,
                                      borderRadius: "50%",
                                      display: "inline-block",
                                    }}
                                  ></div>{" "}
                                  <b>{isOccupied ? "Occupied" : "Unoccupied"}</b>
                                </Box>
                                }
                                {loggedUserRole !== "Patient" && (
                                  <Box
                                    textAlign="center"
                                    style={{ fontSize: "11px" }}
                                  >
                                    <b>Apnea: {apneaperminute || 0}</b>
                                  </Box>
                                )}
                                <Box
                                  textAlign="center"
                                  style={{
                                    fontSize: "11px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    let identityId =
                                      "d36a4373-fbdc-44a3-8c96-4bb920041e40-id";
                                    let paramId =
                                      "d36a4373-fbdc-44a3-8c96-4bb920041e40-pa";
                                    let thresholdId =
                                      "d36a4373-fbdc-44a3-8c96-4bb920041e40-th";
                                    let finalObj = [
                                      {
                                        ownerFilter: {
                                          users: [val.userId],
                                        },
                                        deviceDataModelId: identityId,
                                        deviceCriteria: [
                                          {
                                            key: "Devid",
                                            operator: "Equal",
                                            value: linkedData,
                                          },
                                        ],
                                      },
                                      {
                                        ownerFilter: {
                                          users: [val.userId],
                                        },
                                        deviceDataModelId: paramId,
                                        deviceCriteria: [
                                          {
                                            key: "DevidParams",
                                            operator: "Equal",
                                            value: linkedData,
                                          },
                                        ],
                                      },
                                      {
                                        ownerFilter: {
                                          users: [val.userId],
                                        },
                                        deviceDataModelId: thresholdId,
                                        deviceCriteria: [
                                          {
                                            key: "DevidThresholds",
                                            operator: "Equal",
                                            value: linkedData,
                                          },
                                        ],
                                      },
                                    ];
                                    setUserTimeZone(selectedZone.utc[0]);
                                    setOffset(selectedZone.offset);
                                    setAbbr(selectedZone.abbr);
                                    setShowSensorControl(true);
                                    sensorControlRequest(finalObj);
                                    setUserMacAddress(linkedData);
                                    setFriendlyName(
                                      val.linkedGraphData[linkedData]
                                        .FriendlyName
                                    );
                                    setSensor(val);
                                  }}
                                >
                                  <SettingsIcon
                                    style={{
                                      verticalAlign: "middle",
                                      margin: "auto",
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        );
                      }
                    )}
                  {(!val.linkedGraphData || Object.keys(val.linkedGraphData).length === 0) && (
                    <Grid item xs={12}>
                      <Box
                        style={{
                          backgroundColor: "#ebd7d7",
                          borderRadius: "10px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        <Typography
                          noWrap={true}
                          style={{
                            textAlign: "center",
                            fontWeight: 600,
                            fontSize: "12px",
                          }}
                        >
                          --
                        </Typography>
                        <Typography
                          noWrap={true}
                          style={{
                            textAlign: "center",
                            fontWeight: 600,
                            fontSize: "12px",
                          }}
                        >
                          --
                        </Typography>
                        {readingParam.map((val) => {
                          if (val === "Hr") {
                            return (
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="center"
                              >
                                <Box width="33%" justifyContent="center">
                                  <Avatar
                                    src={heart_rate}
                                    style={{
                                      verticalAlign: "middle",
                                      margin: "auto",
                                    }}
                                  />
                                </Box>
                                <Box
                                  width="33%"
                                  paddingTop="10px"
                                  textAlign="center"
                                  fontWeight="500"
                                  fontSize="10px"
                                  justifyContent="center"
                                >
                                  Heart
                                </Box>
                                <Box
                                  width="33%"
                                  style={{
                                    verticalAlign: "middle",
                                    margin: "auto",
                                    textAlign: "center",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontSize: "22px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    {newData[val].value}
                                  </Typography>
                                </Box>
                              </Box>
                            );
                          } else if (val === "Rr") {
                            return (
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="center"
                              >
                                <Box width="33%" justifyContent="center">
                                  <Avatar
                                    src={lungs}
                                    style={{
                                      verticalAlign: "middle",
                                      margin: "auto",
                                    }}
                                  />
                                </Box>
                                <Box
                                  width="33%"
                                  paddingTop="10px"
                                  textAlign="center"
                                  fontWeight="500"
                                  fontSize="10px"
                                  justifyContent="center"
                                >
                                  Breath
                                </Box>
                                <Box
                                  width="33%"
                                  style={{
                                    verticalAlign: "middle",
                                    margin: "auto",
                                    textAlign: "center",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontSize: "22px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    {newData[val].value}
                                  </Typography>
                                </Box>
                              </Box>
                            );
                          } else if (val === "Stress") {
                            return (
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="center"
                              >
                                <Box width="33%" justifyContent="center">
                                  <Avatar
                                    src={stress}
                                    style={{
                                      verticalAlign: "middle",
                                      margin: "auto",
                                    }}
                                  />
                                </Box>
                                <Box
                                  width="33%"
                                  paddingTop="10px"
                                  textAlign="center"
                                  fontWeight="500"
                                  fontSize="10px"
                                  justifyContent="center"
                                >
                                  Stress
                                </Box>
                                <Box
                                  width="33%"
                                  style={{
                                    verticalAlign: "middle",
                                    margin: "auto",
                                    textAlign: "center",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontSize: "22px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    {newData[val] ? newData[val].value : "--"}
                                  </Typography>
                                </Box>
                              </Box>
                            );
                          } else if (val === "Motion") {
                            return (
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="center"
                              >
                                <Box width="33%" justifyContent="center">
                                  <Avatar
                                    src={motion}
                                    style={{
                                      verticalAlign: "middle",
                                      margin: "auto",
                                    }}
                                  />
                                </Box>
                                <Box
                                  width="33%"
                                  paddingTop="10px"
                                  textAlign="center"
                                  fontWeight="500"
                                  fontSize="10px"
                                  justifyContent="center"
                                >
                                  Motion
                                </Box>
                                <Box
                                  width="33%"
                                  style={{
                                    verticalAlign: "middle",
                                    margin: "auto",
                                    textAlign: "center",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontSize: "22px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    {newData[val] ? newData[val].value : "--"}
                                  </Typography>
                                </Box>
                              </Box>
                            );
                          }
                        })}
                        <Box
                          style={{
                            cursor: "pointer",
                          }}
                          marginTop="2%"
                          textAlign="center"
                          justifyContent="center"
                        >
                          <Button
                            size="sm"
                            color="danger"
                            onClick={() => {
                              const HRValues = [],
                                RRValues = [],
                                Timings = [];
                              if (val.graphData && val.graphData.length > 0) {
                                val.graphData.forEach((val) => {
                                  Timings.push(val.data.Hr.valueProvidedOn);
                                  HRValues.push([
                                    new Date(val.data.Hr.valueProvidedOn),
                                    val.data.Hr.value,
                                  ]);
                                  RRValues.push([
                                    new Date(val.data.Hr.valueProvidedOn),
                                    val.data.Rr.value,
                                  ]);
                                });
                              } else {
                                const currentTime = moment().utc().format("YYYY-MM-DDTHH:mm:ss");
                                HRValues.push([new Date(currentTime), 0]);
                                RRValues.push([new Date(currentTime), 0]);
                              }
                              const graphIntervalObj = val.customFieldData.find(
                                (val) => val.field.name === "Plot_range_default"
                              );
                              let obj = {interval: "1 Hr"};
                              if (graphIntervalObj && graphIntervalObj.fieldData) {
                                obj = {interval: graphIntervalObj.fieldData};
                              }
                              setDrawer(true);
                              setRealTimeType(true);
                              setSensor(val);
                              setReadingParams(readingParam);
                              setUserInfo({ ...userInfo, ...obj });
                              setTimeout(() => {
                                resetGraphData();
                              }, 9000);
                              const newelectedZone =
                                timezones.find(
                                  (time) => time.abbr === DEFAULT_TIMEZONE
                                ) || {};
                              setUserTimeZone(newelectedZone.utc[0]);
                              setOffset(newelectedZone.offset);
                              setAbbr(newelectedZone.abbr);
                              setDayLightSaving(
                                userDayLightSaving === "Yes" ? 1 : 0
                              );
                            }}
                          >
                            History
                          </Button>
                        </Box>
                        <Box textAlign="center">
                          <p style={{ fontSize: "11px" }}>
                            <b>Last updated on:</b>
                          </p>
                          {val.graphData && val.graphData.length > 0 ? (
                            <p style={{ fontSize: "11px" }}>
                              {/* {moment
                                  .utc(val.graphData[0].maxValueProvidedOn)
                                  .tz(selectedZone.utc[0])
                                  .format("MMM Do YY, h:mm:ss a")}{" "}
                                ({selectedZone.abbr}) */}
                            </p>
                          ) : (
                            <p style={{ fontSize: "11px" }}>--</p>
                          )}
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="center"
                        >
                          <Box textAlign="center">
                            <div
                              style={{
                                width: "10px",
                                height: "10px",
                                background: "red",
                                borderRadius: "50%",
                                display: "inline-block",
                              }}
                            ></div>{" "}
                            <b>Offline</b>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  )}
                </Grid>
                <p className="deviceDisclaimer">To change the sequence of icons in the display, adjust the number in the icon from the left to right</p>
              </Box>
            </GridItem>
          );
        }
        return true;
      })}
      {showSensorControl && (
        <Setting
          showSensorControl={showSensorControl}
          sensor={sensor}
          setShowSensorControl={setShowSensorControl}
          friendlyName={friendlyName}
          setFriendlyName={setFriendlyName}
          sensorControlRequest={sensorControlRequest}
          userData={userData}
          updateSensorControlRequest={updateSensorControlRequest}
          updateSensorControlTableRequest={updateSensorControlTableRequest}
          setUserTimeZone={setUserTimeZone}
          setOffset={setOffset}
          setAbbr={setAbbr}
          userTimeZone={userTimeZone}
          offset={offset}
          abbr={abbr}
          userMacAddress={userMacAddress}
          setUserMacAddress={setUserMacAddress}
          device={device}
          loggedUserRole={loggedUserRole}
          setAlertMessage={setAlertMessage}
          setToastStatus={setToastStatus}
        />
      )}
    </>
  );
}
