import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";

const extendedFormsStyle = {
  ...customCheckboxRadioSwitch,
  ...customSelectStyle,
  ...buttonStyle,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#AAAAAA",
    fontSize: "14px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    display: "inline-flex",
  },
  mrAuto: {
    marginRight: "auto",
  },
  mlAuto: {
    marginLeft: "auto",
  },
  datePickerLabel: {
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.54)",
    paddingTop: "16px",
  },
  labelFocused: {
    color: "#E73F3B !important",
  },
  inputColor: {
    "& .MuiInput-underline & :before": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "& .MuiInput-underline & :after": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "& input": {
      borderBottom: "#E73F3B !important",
    },
  },
};

export default extendedFormsStyle;
