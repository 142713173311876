import React, { useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";

// @material-ui/icons
import Person from "@material-ui/icons/Person";

// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const history = useHistory();
  const [userName, setUserName] = React.useState("");
  const [userRole, setUserRole] = React.useState("");
  const [userCompany, setUserCompany] = React.useState("");

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("user-details"));
    let role = userDetails.roles[0].role;
    let company = "";
    if (userDetails.roles[0].role === "TenantAdmin") {
      role = "Manufacturer";
    } else if (userDetails.roles[0].role === "SupplierUser") {
      role = "Manager";
      company = userDetails.roles[0].supplier?.name;
    } else if (userDetails.roles[0].role === "SupplierAdmin") {
      role = "Admin";
      company = userDetails.roles[0].supplier?.name;
    } else if (userDetails.roles[0].role === "PracticeUser") {
      role = "Caregiver";
      company = userDetails.roles[0].supplier?.name;
    } else if (userDetails.roles[0].role === "Patient") {
      role = "Resident";
      company = userDetails.roles[0].supplier?.name;
    }
    setUserName(userDetails.fullName);
    setUserCompany(company);
    setUserRole(role);
  }, []);

  const [openProfile, setOpenProfile] = React.useState(null);
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  const classes = useStyles();
  const { rtlActive } = props;
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive,
  });
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive,
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true,
  });
  return (
    <Box display="flex" flexDirection="row-reverse" p={1} m={1}>
      <div className={wrapper}>
        <div className={managerClasses}>
          <Button
            color="transparent"
            aria-label="Person"
            justIcon
            aria-owns={openProfile ? "profile-menu-list" : null}
            aria-haspopup="true"
            onClick={handleClickProfile}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : "",
            }}
          >
            <Person
              className={
                classes.headerLinksSvg +
                " " +
                (rtlActive
                  ? classes.links + " " + classes.linksRTL
                  : classes.links)
              }
            />
            <Hidden mdUp implementation="css">
              <span onClick={handleClickProfile} className={classes.linkText}>
                {rtlActive ? "الملف الشخصي" : "Profile"}
              </span>
            </Hidden>
          </Button>
          <Popper
            open={Boolean(openProfile)}
            anchorEl={openProfile}
            transition
            disablePortal
            placement="bottom"
            className={classNames({
              [classes.popperClose]: !openProfile,
              [classes.popperResponsive]: true,
              [classes.popperNav]: true,
            })}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                id="profile-menu-list"
                style={{ transformOrigin: "0 0 0" }}
              >
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={handleCloseProfile}>
                    <MenuList role="menu">
                      <MenuItem
                        onClick={() => {
                          const data = JSON.parse(
                            localStorage.getItem("user-details")
                          );
                          const loggedUserRole = data !== null ? data.roles[0].role : "";
                          if(loggedUserRole === 'TenantAdmin') {
                            history.push(`/admin/edit-manufacturer/${data.userId}`);
                          } else {
                            history.push(`/admin/edit-user/${data.userId}`);
                          }
                        }}
                        className={dropdownItem}
                      >
                        {rtlActive ? "الملف الشخصي" : "Profile"}
                      </MenuItem>
                      <Divider light />
                      <MenuItem
                        onClick={() => {
                          localStorage.removeItem("user-details");
                          localStorage.removeItem("termAndCondtions");
                          localStorage.removeItem("changePassword");
                          localStorage.removeItem("user-custom-details");
                          history.push("/auth/login");
                        }}
                        className={dropdownItem}
                      >
                        {rtlActive ? "الخروج" : "Log out"}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
      <div className={managerClasses}>
        <p
          className={classes.linkText}
          style={{
            fontWeight: "400",
            textTransform: "capitalize",
          }}
        >
          {userName}{" "}
        </p>
        <p
          style={{
            fontWeight: "400",
            textTransform: "capitalize",
            textAlign: "center",
          }}
        >
          {userRole}
        </p>
      </div>
      {userCompany && (
        <Box width="500px" padding={2} className={managerClasses}>
          {userCompany}
        </Box>
      )}
    </Box>
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool,
};
