import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

// @material-ui/icons
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";

import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";

import { 
    FETCH_DEVICE_PROPSETID_REQUEST, 
    ADD_GUESTMAP_REQUEST
} from "../../redux/guestMap/actions";
import { 
    FETCH_INVITES_REQUEST,
    ACTION_INVITES_REQUEST
} from "../../redux/observe/actions";
import { useComposeDispatcher } from "../../common/hooks";
import { REFRESH_TOKEN_REQUEST } from "../../redux/users/actions";

export default function Invites() {
    const { guestMapData, observe, users } = useSelector((state) => ({
        guestMapData: state.guestMap,
        observe: state.observe,
        users: state.users,
    }));

    const userDetails = JSON.parse(localStorage.getItem("user-details")),
    loggedUserId = userDetails !== null ? userDetails.userId : "",
    loggedUserRole = userDetails !== null ? userDetails.roles[0].role : "";

    const [selectedInvite, setSelectedInvite] = useState({});
    
    const [getDevicePropSetId] = useComposeDispatcher(FETCH_DEVICE_PROPSETID_REQUEST);
    const [getRefreshToken] = useComposeDispatcher(REFRESH_TOKEN_REQUEST);
    const [getInvitesList] = useComposeDispatcher(FETCH_INVITES_REQUEST);
    const [actionInvite] = useComposeDispatcher(ACTION_INVITES_REQUEST);
    const [addGuestMap] = useComposeDispatcher(ADD_GUESTMAP_REQUEST);

    useEffect(() => {
        getInvitesList({
            pageNumber:0,
            pageSize:20,
            sortBy:'createdOn',
            sortOrder:'DESC'
        });
        if(loggedUserRole === "Guest") {
            getRefreshToken();
        }
    }, []);

    useEffect(() => {
        if(observe?.inviteUpdated) {
            getInvitesList({
                pageNumber:0,
                pageSize:20,
                sortBy:'createdOn',
                sortOrder:'DESC'
            });

            let payload = {
                "deviceDataModelId": "299992ad-6d4a-4020-8501-8a004d45fffc",
                "ownerId": selectedInvite?.user?.userId,
                "data": {
                    "Macaddress": [],
                    "Timestampgm": moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
                    "Guestid": loggedUserId,
                    "Userid": selectedInvite?.user?.userId,
                    "Guestname": userDetails?.fullName
                },
                "devicePropertySetId": guestMapData?.devicePropSetId || "04ab8cdc-27bf-4129-9a02-33bc58e62a81"
            };
            console.log(payload, 'On Update of Invite');
            addGuestMap(payload);
        }
    }, [observe?.inviteUpdated]);

    const onAction = (obj, flag) => {
        const payload = {...obj, requestStatus: flag === 'accept' ? "ACCEPTED" : "REJECTED", refreshToken: users?.refreshToken};
        setSelectedInvite(payload);
        actionInvite(payload);

        if (flag === 'accept') {
            getDevicePropSetId("299992ad-6d4a-4020-8501-8a004d45fffc");
        }
    }

    return(
        <>
            <Card>
                <CardBody>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell key="Name" align="left">Name</TableCell>
                                    <TableCell key="Valid" align="center">Valid Untill</TableCell>
                                    <TableCell key="Status" align="center">Status</TableCell>
                                    <TableCell key="CreatedAt" align="center">Created At</TableCell>
                                    <TableCell key="CreatedBy" align="center">Created By</TableCell>
                                    <TableCell key="Action" align="center">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {observe?.invitesList?.length > 0 &&
                                observe.invitesList.map((column, i) => (
                                    <TableRow key={i}>
                                        <TableCell key="fullName" align="left">{column?.user?.fullName}</TableCell>
                                        <TableCell key="validUntill" align="center">{column?.validUntil ? moment(column?.validUntil).format("DD MMM, YYYY hh:mm") : '-'}</TableCell>
                                        <TableCell key="reqStatus" align="center">
                                            <span className={column?.requestStatus == "ACCEPTED" ? "dot green" : column?.requestStatus == "REJECTED" ? "dot red" : "dot yellow"} />
                                            {column?.requestStatus}
                                        </TableCell>
                                        <TableCell key="createdOn" align="center">{column?.createdOn ? moment(column?.createdOn).format("DD MMM, YYYY hh:mm") : '-'}</TableCell>
                                        <TableCell key="CreatedUser" align="center">{column?.createdByUser?.fullName}</TableCell>
                                        <TableCell key="Action" align="center">
                                            {column?.requestStatus == "PENDING" ?
                                                <>
                                                    <Button color="danger" onClick={() => onAction(column, 'accept')}>Accept</Button>
                                                    <Button onClick={() => onAction(column, 'cancel')}>Cancel</Button>
                                                </> : 'N/A'
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardBody>
            </Card>
        </>
    )
}