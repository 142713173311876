import moment from "moment";
import momentTz from "moment-timezone";
import {
  FETCH_DEVICE_REQUEST,
  FETCH_DEVICE_SUCCESS,
  FETCH_DEVICE_ERROR,
  FETCH_DEVICE_PROPERTY_REQUEST,
  FETCH_DEVICE_PROPERTY_SUCCESS,
  FETCH_DEVICE_PROPERTY_ERROR,
  GET_DEVICE_DETAIL_REQUEST,
  GET_DEVICE_DETAIL_SUCCESS,
  GET_DEVICE_DETAIL_ERROR,
  FETCH_DEVICE_PROPERTY_SET_REQUEST,
  FETCH_DEVICE_PROPERTY_SET_SUCCESS,
  FETCH_DEVICE_PROPERTY_SET_ERROR,
  ADD_DEVICE_REQUEST,
  ADD_DEVICE_SUCCESS,
  ADD_DEVICE_ERROR,
  DELETE_DEVICE_REQUEST,
  DELETE_DEVICE_SUCCESS,
  DELETE_DEVICE_ERROR,
  GET_DEVICE_DETAIL_PROPERTY_ERROR,
  UPDATE_DEVICE_REQUEST,
  UPDATE_DEVICE_SUCCESS,
  UPDATE_DEVICE_ERROR,
  CREATE_PROPERTY_SET_REQUEST,
  CREATE_PROPERTY_SET_SUCCESS,
  CREATE_PROPERTY_SET_ERROR,
  DELETE_PROPERTY_SET_REQUEST,
  DELETE_PROPERTY_SET_ERROR,
  DELETE_PROPERTY_SET_SUCCESS,
  RESET_SUCCESS,
  FETCH_PROPERTY_SET_DETAIL_REQUEST,
  FETCH_PROPERTY_SET_DETAIL_SUCCESS,
  FETCH_PROPERTY_SET_DETAIL_ERROR,
  UPDATE_PROPERTY_SET_REQUEST,
  UPDATE_PROPERTY_SET_SUCCESS,
  UPDATE_PROPERTY_SET_ERROR,
  GET_DEVICE_PROPERTY_REQUEST,
  GET_DEVICE_PROPERTY_SUCCESS,
  GET_DEVICE_PROPERTY_ERROR,
  UPDATE_DEVICE_PROPERTY_REQUEST,
  UPDATE_DEVICE_PROPERTY_SUCCESS,
  UPDATE_DEVICE_PROPERTY_ERROR,
  CREATE_DEVICE_PROPERTY_REQUEST,
  CREATE_DEVICE_PROPERTY_SUCCESS,
  CREATE_DEVICE_PROPERTY_ERROR,
  DELETE_PROPERTY_REQUEST,
  DELETE_PROPERTY_ERROR,
  FETCH_DEVICE_FIRMWARE_REQUEST,
  FETCH_DEVICE_FIRMWARE_SUCCESS,
  FETCH_DEVICE_FIRMWARE_ERROR,
  FETCH_FIRMWARE_DETAIL_REQUEST,
  FETCH_FIRMWARE_DETAIL_SUCCESS,
  FETCH_FIRMWARE_DETAIL_ERROR,
  UPDATE_FIRMWARE_DETAIL_REQUEST,
  UPDATE_FIRMWARE_DETAIL_SUCCESS,
  UPDATE_FIRMWARE_DETAIL_ERROR,
  CREATE_FIRMWARE_DETAIL_REQUEST,
  CREATE_FIRMWARE_DETAIL_SUCCESS,
  CREATE_FIRMWARE_DETAIL_ERROR,
  FETCH_DEVICE_DATA_REQUEST,
  FETCH_DEVICE_DATA_SUCCESS,
  FETCH_DEVICE_DATA_ERROR,
  DUPLICATE_DEVICE_REQUEST,
  DUPLICATE_DEVICE_SUCCESS,
  DUPLICATE_DEVICE_ERROR,
  FETCH_NEW_DATA_REQUEST,
  FETCH_NEW_DATA_SUCCESS,
  FETCH_NEW_DATA_ERROR,
  FETCH_NEW_USER_DATA_REQUEST,
  FETCH_NEW_USER_DATA_SUCCESS,
  FETCH_NEW_USER_DATA_ERROR,
  RESET_GRAPH_SUCCESS,
  FETCH_DEVICE_SPECIFIED_REQUEST,
  FETCH_DEVICE_SPECIFIED_SUCCESS,
  FETCH_DEVICE_SPECIFIED_ERROR,
  GET_PROPERTY_DATA_REQUEST,
  GET_PROPERTY_DATA_SUCCESS,
  CREATE_DEVICE_DATA_REQUEST,
  CREATE_DEVICE_DATA_SUCCESS,
  CREATE_DEVICE_DATA_ERROR,
  UPDATE_DEVICE_DATA_REQUEST,
  UPDATE_DEVICE_DATA_ERROR,
  UPDATE_DEFAULT_DATA_REQUEST,
  UPDATE_DEFAULT_DATA_ERROR,
  UPDATE_DEFAULT_THRESHOLD_REQUEST,
  UPDATE_DEFAULT_THRESHOLD_ERROR,
  DELETE_DEVICE_DATA_REQUEST,
  DELETE_DEVICE_DATA_ERROR,
  SYNC_FRIENDLY_NAME_REQUEST,
  SYNC_FRIENDLY_NAME_ERROR,
  FETCH_HW_SW_VERSION_REQUEST,
  FETCH_HW_SW_VERSION_SUCCESS,
  FETCH_HW_SW_VERSION_ERROR,
  START_GRAPH_SUCCESS,
  FETCH_CSV_DATA_REQUEST,
  FETCH_CSV_DATA_SUCCESS,
  FETCH_CSV_DATA_ERROR,
  FETCH_FRIENDLY_NAME_REQUEST,
  FETCH_FRIENDLY_NAME_SUCCESS,
  FETCH_FRIENDLY_NAME_ERROR,
  FETCH_NEW_USER_MIN_DATA_REQUEST,
  FETCH_NEW_USER_MIN_DATA_SUCCESS,
  FETCH_NEW_USER_MIN_DATA_ERROR,
  FETCH_ERROR_LOG_DATA_REQUEST,
  FETCH_ERROR_LOG_DATA_SUCCESS,
  FETCH_ERROR_LOG_DATA_ERROR,
  DOWNLOAD_DEVICE_FIRMWARE_REQUEST,
  DOWNLOAD_DEVICE_FIRMWARE_SUCCESS,
  DOWNLOAD_DEVICE_FIRMWARE_ERROR,
  FETCH_ALERT_LOG_DATA_REQUEST,
  FETCH_ALERT_LOG_DATA_SUCCESS,
  FETCH_ALERT_LOG_DATA_ERROR,
  FETCH_READ_ALERT_LOG_DATA_REQUEST,
  FETCH_READ_ALERT_LOG_DATA_SUCCESS,
  FETCH_READ_ALERT_LOG_DATA_ERROR,
  FETCH_NEW_DEV_DATA_SUCCESS,
  FETCH_SVITTAL_DATA_SUCCESS,
  FETCH_PARAMS_DATA_SUCCESS,
  FETCH_NEW_SV_DATA_SUCCESS,
  FETCH_SENSOR_CONTROL_REQUEST,
  FETCH_SENSOR_CONTROL_SUCCESS,
  FETCH_SENSOR_CONTROL_ERROR,
  UPDATE_SENSOR_CONTROL_REQUEST,
  UPDATE_SENSOR_CONTROL_ERROR,
  UPDATE_SENSOR_CONTROL_SUCCESS,
  UPDATE_SENSOR_CONTROL_TABLE_REQUEST,
  UPDATE_SENSOR_CONTROL_TABLE_SUCCESS,
  UPDATE_SENSOR_CONTROL_TABLE_ERROR,
  FETCH_DIAGNOSTIC_PANEL_DATA_SUCCESS,
  FETCH_DIAGNOSTIC_PANEL_DATA_ERROR,
  FETCH_DIAGNOSTIC_PANEL_DATA_REQUEST,
  RESET_DIAGNOSTIC_PANEL_DATA_REQUEST,
  SAVE_DIAGNOSTIC_PANEL_DATA_SUCCESS,
  SAVE_DIAGNOSTIC_PANEL_DATA_ERROR,
  SAVE_DIAGNOSTIC_PANEL_DATA_REQUEST,
  FETCH_SAVED_DIAGNOSTIC_PANEL_DATA_REQUEST,
  FETCH_SAVED_DIAGNOSTIC_PANEL_DATA_SUCCESS,
  FETCH_SAVED_DIAGNOSTIC_PANEL_DATA_ERROR,
  FETCH_DIAGNOSTIC_PANEL_REQUEST,
  FETCH_DIAGNOSTIC_PANEL_SUCCESS,
  FETCH_DIAGNOSTIC_PANEL_ERROR,
  FETCH_USER_ORDER_DATA_REQUEST,
  FETCH_USER_ORDER_DATA_REQUEST_SUCCESS,
  FETCH_USER_ORDER_DATA_REQUEST_ERROR,
  FETCH_DEVICE_DETAILS_REQUEST,
  FETCH_DEVICE_DETAILS_ERROR,
  FETCH_DEVICE_DETAILS_SUCCESS,
  SAVE_USER_ORDER_REQUEST,
  SAVE_USER_ORDER_ERROR,
  SAVE_USER_ORDER_SUCCESS,
} from "./actions";
import { REDUCERS, DEFAULT_UTC_TIME } from "../../common/constants";
import { createReducer } from "../../common/helpers";
import timezones from "../../common/timezones.json";

var d = new Date();

const schema = {
  loading: false,
  loadingCsv: false,
  loadingData: false,
  loadingFirmware: false,
  error: false,
  totalElements: 0,
  deviceList: [],
  deviceDetails: {},
  propertySetDetails: {},
  propertyDetails: {},
  property: [],
  propertySet: [],
  firmwareList: [],
  firmwareDetail: {},
  graphData: [],
  graphDataApi: false,
  graphApiCount: 0,
  graphReqApiCount: 0,
  firmwareTotalElements: 0,
  success: false,
  refresh: false,
  loadingGraph: false,
  userSensorPosSaved: false,
  graphArray: {
    hrValue: [
      [{ type: "date", label: "Day" }, "Heart Rate"],
      [new Date(d.getUTCDate()), 0],
    ],
    rrValue: [
      [{ type: "date", label: "Day" }, "Respiratory Rate"],
      [new Date(d.getUTCDate()), 0],
    ],
    stressValue: [
      [{ type: "date", label: "Day" }, "Stress Rate"],
      [new Date(d.getUTCDate()), 0],
    ],
    motionValue: [
      [{ type: "date", label: "Day" }, "Stress Rate"],
      [new Date(d.getUTCDate()), 0],
    ],
    rssHrMvValue: [
      [{ type: "date", label: "Day" }, "Rss Hr Mv Rate"],
      [new Date(d.getUTCDate()), 0],
    ],
    rssRrMvValue: [
      [{ type: "date", label: "Day" }, "Rss Rr Mv Stress Rate"],
      [new Date(d.getUTCDate()), 0],
    ],
  },
  backDateData: false,
  selectedInteval: "",
  swVersion: "",
  hwVersion: "",
  deviceTable: {},
  deviceTableHeader: {},
  friendlyNameData: [],
  friendlyNameUser1Data: [],
  friendlyNameUser2Data: [],
  friendlyNameUser3Data: [],
  errorLog: {
    count: 0,
    data: [],
  },
  rangeStartDateTime: "",
  rangeEndDateTime: "",
  sensorControl: {},
  updateSensorStatus: false,
  diagnosticPanel1: [
    ["Time", "First User", "Second User", "Third User"],
    [new Date(), 0, 0, 0],
  ],
  diagnosticPanel2: [
    ["Time", "First User", "Second User", "Third User"],
    [new Date(), 0, 0, 0],
  ],
  diagnosticFilter: {},
  diagnosticList: [],
  totalDiagnosticElements: 0,
  totalUnreadAlert: 0,
  userOrderData: [],
  deviceDataById: {}
};

const reducer = {
  [FETCH_SENSOR_CONTROL_REQUEST]: (state) => {
    return {
      ...state,
      ...REDUCERS.LOADING,
      sensorControl: {},
    };
  },
  [FETCH_SENSOR_CONTROL_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      sensorControl: payload,
    };
  },
  [FETCH_SENSOR_CONTROL_ERROR]: (state) => {
    return {
      ...state,
      ...REDUCERS.ERROR,
      sensorControl: {},
    };
  },
  [UPDATE_SENSOR_CONTROL_REQUEST]: (state) => {
    return {
      ...state,
      updateSensorStatus: false,
    };
  },
  [UPDATE_SENSOR_CONTROL_SUCCESS]: (state) => {
    return {
      ...state,
      updateSensorStatus: true,
      sensorControl: {},
    };
  },
  [UPDATE_SENSOR_CONTROL_ERROR]: (state) => {
    return {
      ...state,
      updateSensorStatus: false,
    };
  },
  [UPDATE_SENSOR_CONTROL_TABLE_REQUEST]: (state) => {
    return {
      ...state,
      updateSensorStatus: false,
    };
  },
  [UPDATE_SENSOR_CONTROL_TABLE_SUCCESS]: (state) => {
    return {
      ...state,
      updateSensorStatus: true,
      sensorControl: {},
    };
  },
  [UPDATE_SENSOR_CONTROL_TABLE_ERROR]: (state) => {
    return {
      ...state,
      updateSensorStatus: false,
    };
  },
  [FETCH_ALERT_LOG_DATA_REQUEST]: (state) => {
    return {
      ...state,
      loading: true,
      errorLog: {
        count: 0,
        data: [],
      },
    };
  },
  [FETCH_ALERT_LOG_DATA_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      loading: false,
      errorLog: {
        count: payload.totalElements,
        data: payload.content.reduce((acc, val) => {
          let obj = {
            residentName: val.owner.fullName,
            DevidAlerts: val.data.DevidAlerts ? val.data.DevidAlerts.value : "",
            FriendlyNameA: val.data.FriendlyNameA
              ? val.data.FriendlyNameA.value
              : "",
            AlertFlag: val.data.AlertFlag ? val.data.AlertFlag.value : "",
            AlertType: val.data.AlertType ? val.data.AlertType.value : "",
            Atext: val.data.Atext ? val.data.Atext.value : "",
            TimestampA: val.data.TimestampA
              ? val.data.TimestampA.valueProvidedOn
              : "",
            deviceDataId: val.deviceDataId,
          };
          return [...acc, obj];
        }, []),
      },
    };
  },
  [FETCH_ALERT_LOG_DATA_ERROR]: (state) => {
    return {
      ...state,
      loading: false,
    };
  },
  [FETCH_READ_ALERT_LOG_DATA_REQUEST]: (state) => {
    return {
      ...state,
      loading: true,
      totalUnreadAlert: 0,
    };
  },
  [FETCH_READ_ALERT_LOG_DATA_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      loading: false,
      totalUnreadAlert: payload,
    };
  },
  [FETCH_READ_ALERT_LOG_DATA_ERROR]: (state) => {
    return {
      ...state,
      loading: false,
      totalUnreadAlert: 0,
    };
  },
  [FETCH_ERROR_LOG_DATA_REQUEST]: (state) => {
    return {
      ...state,
      loading: true,
      errorLog: {
        count: 0,
        data: [],
      },
    };
  },
  [FETCH_ERROR_LOG_DATA_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      loading: false,
      errorLog: {
        count: payload.totalElements,
        data: payload.content.reduce((acc, val) => {
          let timeProvided = val.data.TimestampD.valueProvidedOn;
          if (
            val.data.TimestampD && 
            val.data.TimestampD.value && 
            val.data.DevidDevhealth && 
            val.data.DevidDevhealth.value && 
            payload.macIdObj[val.data.DevidDevhealth.value]
          ) {
            let userTimeZone = timezones.find(
              (time) => time.value === payload.macIdObj[val.data.DevidDevhealth.value]
            )
            console.log('timezone', userTimeZone, payload.macIdObj[val.data.DevidDevhealth.value])
            if (userTimeZone && userTimeZone.utc && userTimeZone.utc.length > 0) {
              timeProvided = momentTz
                .utc(val.data.TimestampD.valueProvidedOn)
                .tz(userTimeZone.utc[0])
                .format("MM/DD/YYYY hh:mm a")
            }
          }
          let obj = {
            residentName: val.owner.fullName,
            DevidDevhealth: val.data.DevidDevhealth.value,
            FriendlyNameD: val.data.FriendlyNameD.value,
            ErrorType: val.data.ErrorType ? val.data.ErrorType.value : "",
            ErrorLog: val.data.ErrorLog ? val.data.ErrorLog.value : "",
            Fwvers: val.data.Fwvers ? val.data.Fwvers.value : "",
            TimestampD: val.data.TimestampD 
              ? timeProvided
              : "",
            deviceDataId: val.deviceDataId,
          };
          return [...acc, obj];
        }, []),
      },
    };
  },
  [FETCH_ERROR_LOG_DATA_ERROR]: (state) => {
    return {
      ...state,
      loading: false,
    };
  },
  [START_GRAPH_SUCCESS]: (state) => {
    return {
      ...state,
      loadingGraph: true,
    };
  },
  [FETCH_FRIENDLY_NAME_REQUEST]: (state) => {
    return {
      ...state,
      loading: true,
      friendlyNameData: [],
    };
  },
  [FETCH_DEVICE_REQUEST]: (state) => {
    return {
      ...state,
      ...REDUCERS.LOADING,
      totalElements: 0,
      deviceList: [],
      deviceDetails: {},
      propertySetDetails: {},
      propertyDetails: {},
      property: [],
      propertySet: [],
      firmwareList: [],
      firmwareDetail: {},
      graphData: [],
      graphDataApi: false,
      graphApiCount: 0,
      graphReqApiCount: 0,
      deviceTable: {},
      deviceTableHeader: {},
      firmwareTotalElements: 0,
      success: false,
    };
  },
  [FETCH_DEVICE_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      totalElements: payload.totalElements,
      deviceList: payload.content,
    };
  },
  [FETCH_DEVICE_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [FETCH_DEVICE_PROPERTY_REQUEST]: (state) => {
    return {
      ...state,
      ...REDUCERS.LOADING,
    };
  },
  [FETCH_DEVICE_PROPERTY_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      property: payload,
    };
  },
  [FETCH_DEVICE_PROPERTY_ERROR]: (state) => {
    return {
      ...state,
      ...REDUCERS.ERROR,
    };
  },
  [GET_DEVICE_DETAIL_REQUEST]: (state) => {
    return {
      ...state,
      ...REDUCERS.LOADING,
      totalElements: 0,
      deviceList: [],
      deviceDetails: {},
      propertySetDetails: {},
      propertyDetails: {},
      property: [],
      propertySet: [],
      firmwareList: [],
      firmwareDetail: {},
      firmwareTotalElements: 0,
      success: false,
    };
  },
  [GET_DEVICE_DETAIL_SUCCESS]: (state, { payload }) => {
    payload.device.properties = payload.property.content.map((val) => {
      val.deviceProperties = val.deviceProperties.sort(
        (a, b) => a.displayOrder - b.displayOrder
      );
      let zeroIndexData = val.deviceProperties.filter(
        (a) => a.displayOrder === 0
      );
      let greaterThenZeroIndexData = val.deviceProperties.filter(
        (a) => a.displayOrder > 0
      );
      val.deviceProperties = [...greaterThenZeroIndexData, ...zeroIndexData];
      return val;
    });
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      deviceDetails: payload.device,
      success: false,
    };
  },
  [GET_DEVICE_DETAIL_PROPERTY_ERROR]: (state, { payload }) => {
    payload.device.properties = [];
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      deviceDetails: payload.device,
    };
  },
  [GET_DEVICE_DETAIL_ERROR]: (state) => {
    return {
      ...state,
      ...REDUCERS.ERROR,
    };
  },
  [FETCH_DEVICE_PROPERTY_SET_REQUEST]: (state) => {
    return {
      ...state,
      ...REDUCERS.LOADING,
    };
  },
  [FETCH_DEVICE_PROPERTY_SET_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      propertySet: payload.content,
    };
  },
  [FETCH_DEVICE_PROPERTY_SET_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [ADD_DEVICE_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [ADD_DEVICE_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [ADD_DEVICE_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      deviceDetails: payload,
      success: true,
    };
  },
  [DELETE_DEVICE_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [DELETE_DEVICE_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [DELETE_DEVICE_SUCCESS]: (state) => {
    return {
      ...state,
      ...REDUCERS.SUCCESS,
    };
  },
  [UPDATE_DEVICE_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [UPDATE_DEVICE_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [UPDATE_DEVICE_SUCCESS]: (state) => {
    return {
      ...state,
      ...REDUCERS.SUCCESS,
    };
  },
  [CREATE_PROPERTY_SET_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [CREATE_PROPERTY_SET_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [CREATE_PROPERTY_SET_SUCCESS]: (state) => ({
    ...state,
    ...REDUCERS.SUCCESS,
    success: true,
  }),
  [DELETE_PROPERTY_SET_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [DELETE_PROPERTY_SET_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [DELETE_PROPERTY_SET_SUCCESS]: (state) => ({
    ...state,
    ...REDUCERS.SUCCESS,
    success: true,
  }),
  [RESET_SUCCESS]: (state) => {
    return {
      ...state,
      refresh: false,
      success: false,
      diagnosticPanel1: [
        ["Time", "First User", "Second User", "Third User"],
        [new Date(), 0, 0, 0],
      ],
      diagnosticPanel2: [
        ["Time", "First User", "Second User", "Third User"],
        [new Date(), 0, 0, 0],
      ],
    };
  },
  [FETCH_PROPERTY_SET_DETAIL_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [FETCH_PROPERTY_SET_DETAIL_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [FETCH_PROPERTY_SET_DETAIL_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      propertySetDetails: payload,
    };
  },
  [UPDATE_PROPERTY_SET_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [UPDATE_PROPERTY_SET_SUCCESS]: (state) => ({
    ...state,
    ...REDUCERS.SUCCESS,
    success: true,
  }),
  [UPDATE_PROPERTY_SET_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [GET_DEVICE_PROPERTY_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [GET_DEVICE_PROPERTY_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      propertyDetails: payload,
    };
  },
  [GET_DEVICE_PROPERTY_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [UPDATE_DEVICE_PROPERTY_SUCCESS]: (state) => ({
    ...state,
    ...REDUCERS.SUCCESS,
    success: true,
  }),
  [UPDATE_DEVICE_PROPERTY_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [UPDATE_DEVICE_PROPERTY_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [CREATE_DEVICE_PROPERTY_SUCCESS]: (state) => ({
    ...state,
    ...REDUCERS.SUCCESS,
    success: true,
  }),
  [CREATE_DEVICE_PROPERTY_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [CREATE_DEVICE_PROPERTY_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [DELETE_PROPERTY_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [DELETE_PROPERTY_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [FETCH_DEVICE_FIRMWARE_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
    success: false,
  }),
  [FETCH_DEVICE_FIRMWARE_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
    firmwareList: [],
    firmwareDetail: {},
    firmwareTotalElements: 0,
  }),
  [FETCH_DEVICE_FIRMWARE_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      firmwareList: payload.content,
      firmwareTotalElements: payload.totalElements,
      firmwareDetail: {},
    };
  },
  [FETCH_FIRMWARE_DETAIL_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
    success: false,
  }),
  [FETCH_FIRMWARE_DETAIL_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
    success: false,
  }),
  [FETCH_FIRMWARE_DETAIL_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      firmwareDetail: payload,
    };
  },
  [UPDATE_FIRMWARE_DETAIL_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [UPDATE_FIRMWARE_DETAIL_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [UPDATE_FIRMWARE_DETAIL_SUCCESS]: (state) => ({
    ...state,
    ...REDUCERS.SUCCESS,
    success: true,
  }),
  [CREATE_FIRMWARE_DETAIL_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [CREATE_FIRMWARE_DETAIL_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [CREATE_FIRMWARE_DETAIL_SUCCESS]: (state) => ({
    ...state,
    ...REDUCERS.SUCCESS,
    success: true,
  }),
  [FETCH_DEVICE_DATA_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
    loadingData: true,
    graphData: [],
    deviceTable: {},
    deviceTableHeader: {},
    updateSensorStatus: false,
  }),
  [FETCH_DEVICE_DATA_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
    loadingData: false,
    graphData: [],
  }),
  [FETCH_DEVICE_DATA_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      graphData: payload,
      // loadingData: false,
      updateSensorStatus: false,
    };
  },
  [DUPLICATE_DEVICE_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [DUPLICATE_DEVICE_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [DUPLICATE_DEVICE_SUCCESS]: (state) => ({
    ...state,
    ...REDUCERS.SUCCESS,
    success: true,
  }),
  [FETCH_NEW_DATA_REQUEST]: (state) => {
    var count = 0;
    if (state.graphReqApiCount > 8) {
      count = 0 + 1;
    } else {
      count = state.graphReqApiCount + 1;
    }
    return {
      ...state,
      graphDataApi: false,
      graphApiCount: state.graphApiCount,
      graphReqApiCount: count,
    };
  },
  [FETCH_NEW_DATA_SUCCESS]: (state, { payload }) => {
    const finalData = state.graphData.map((val) => {
      val.linkedGraphData = Object.keys(val.linkedGraphData).reduce(
        (acc, vl) => {
          let newObj = {};
          newObj[vl] = { ...val.linkedGraphData[vl] };
          if (payload[vl] && payload[vl][0].ownerId === val.userId) {
            const newData = payload[vl];
            newObj[vl].data = [...newData, ...val.linkedGraphData[vl].data];
            return {
              ...acc,
              ...newObj,
            };
          }
          return { ...acc, ...newObj };
        },
        {}
      );
      return val;
    });
    var count = 0;
    if (state.graphApiCount > 8) {
      count = 0 + 1;
    } else {
      count = state.graphApiCount + 1;
    }
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      graphData: finalData,
      graphDataApi: true,
      graphApiCount: count,
    };
  },
  [FETCH_NEW_DATA_ERROR]: (state) => {
    return { ...state, graphDataApi: false };
  },
  [FETCH_NEW_USER_DATA_REQUEST]: (state) => {
    let hrValue = [new Date(), 0];
    let rrValue = [new Date(), 0];
    let stressValue = [new Date(), 0];
    let motionValue = [new Date(), 0];
    let rssHrMvValue = [new Date(), 0];
    let rssRrMvValue = [new Date(), 0];
    return {
      ...state,
      loadingGraph: true,
      backDateData: false,
      rangeStartDateTime: "",
      rangeEndDateTime: "",
      graphApiCount: 0,
      graphReqApiCount: 0,
      graphArray: {
        hrValue: [[{ type: "date", label: "Day" }, "Heart Rate"], hrValue],
        rrValue: [
          [{ type: "date", label: "Day" }, "Respiratory Rate"],
          rrValue,
        ],
        stressValue: [
          [{ type: "date", label: "Day" }, "Stress Rate"],
          stressValue,
        ],
        motionValue: [
          [{ type: "date", label: "Day" }, "Motion Rate"],
          motionValue,
        ],
        rssHrMvValue: [
          [{ type: "date", label: "Day" }, "Rss Hr Mv Rate"],
          rssHrMvValue,
        ],
        rssRrMvValue: [
          [{ type: "date", label: "Day" }, "Rss Rr Mv Stress Rate"],
          rssRrMvValue,
        ],
        sdnnValue: [
          [{ type: "date", label: "Day" }, "Sdnn Rate"],
          [new Date(d.getUTCDate()), 0],
        ],
        rmssdValue: [
          [{ type: "date", label: "Day" }, "Rmssd Rate"],
          [new Date(d.getUTCDate()), 0],
        ],
        hrTdValue: [
          [{ type: "date", label: "Day" }, "Hr Td Rate"],
          [new Date(d.getUTCDate()), 0],
        ],
        hrFdValue: [
          [{ type: "date", label: "Day" }, "Hr Fd Rate"],
          [new Date(d.getUTCDate()), 0],
        ],
        hrSelValue: [
          [{ type: "date", label: "Day" }, "Hr Sel Rate"],
          [new Date(d.getUTCDate()), 0],
        ],
        hrEstValue: [
          [{ type: "date", label: "Day" }, "Hr Est Rate"],
          [new Date(d.getUTCDate()), 0],
        ],
        winFdValue: [
          [{ type: "date", label: "Day" }, "Win Fd Rate"],
          [new Date(d.getUTCDate()), 0],
        ],
        winSelValue: [
          [{ type: "date", label: "Day" }, "Win Sel Rate"],
          [new Date(d.getUTCDate()), 0],
        ],
        winEstValue: [
          [{ type: "date", label: "Day" }, "Win Sel Rate"],
          [new Date(d.getUTCDate()), 0],
        ],
      },
    };
  },
  [FETCH_NEW_USER_MIN_DATA_REQUEST]: (state) => {
    let hrValue = [new Date(), 0];
    let rrValue = [new Date(), 0];
    let stressValue = [new Date(), 0];
    let motionValue = [new Date(), 0];
    return {
      ...state,
      loadingGraph: true,
      graphApiCount: 0,
      graphReqApiCount: 0,
      graphArray: {
        hrValue: [[{ type: "date", label: "Day" }, "Heart Rate"], hrValue],
        rrValue: [
          [{ type: "date", label: "Day" }, "Respiratory Rate"],
          rrValue,
        ],
        stressValue: [
          [{ type: "date", label: "Day" }, "Stress Rate"],
          stressValue,
        ],
        motionValue: [
          [{ type: "date", label: "Day" }, "Motion Rate"],
          motionValue,
        ],
      },
    };
  },
  [FETCH_NEW_USER_DATA_SUCCESS]: (state, { payload }) => {
    let graphArray = state.graphArray;
    let hrValue = [],
      rrValue = [],
      stressValue = [],
      motionValue = [];
    if (payload.updated || state.selectedInteval === payload.interval) {
      payload.data.forEach((val) => {
        const newTime = momentTz
          .utc(val.view.groupDate)
          .tz(payload.currentUserTimeZone)
          .format("MM/DD/YYYY h:mm:ss a");
        if (payload.propertyCode === "Hr") {
          hrValue = [...hrValue, [new Date(newTime), val.view.value]];
        }
        if (payload.propertyCode === "Rr") {
          rrValue = [...rrValue, [new Date(newTime), val.view.value]];
        }
        if (payload.propertyCode === "Motion") {
          motionValue = [...motionValue, [new Date(newTime), val.view.value]];
        }
        if (payload.propertyCode === "Stress") {
          stressValue = [...stressValue, [new Date(newTime), val.view.value]];
        }
      });
      if (payload.propertyCode === "Hr") {
        graphArray.hrValue = [
          [{ type: "date", label: "Day" }, "Heart Rate"],
          ...hrValue.reverse(),
        ];
      }

      if (payload.propertyCode === "Rr") {
        graphArray.rrValue = [
          [{ type: "date", label: "Day" }, "Respiratory Rate"],
          ...rrValue.reverse(),
        ];
      }

      if (payload.propertyCode === "Stress") {
        graphArray.stressValue = [
          [{ type: "date", label: "Day" }, "Stress Rate"],
          ...stressValue.reverse(),
        ];
      }

      if (payload.propertyCode === "Motion") {
        graphArray.motionValue = [
          [{ type: "date", label: "Day" }, "Motion Rate"],
          ...motionValue.reverse(),
        ];
      }
    }
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      loadingGraph: false,
      selectedInteval: payload.interval,
      graphArray: graphArray,
      backDateData: payload.backDateData,
      rangeStartDateTime: payload.rangeStartDateTime,
      rangeEndDateTime: payload.rangeEndDateTime,
    };
  },
  [FETCH_NEW_USER_MIN_DATA_SUCCESS]: (state, { payload }) => {
    let hrValue = [],
      rrValue = [],
      stressValue = [],
      motionValue = [];

    payload.data.forEach((val) => {
      const test = momentTz
        .utc(val.data.Hr.valueProvidedOn)
        .tz(payload.currentUserTimeZone)
        .format("MM/DD/YYYY h:mm:ss a");
      hrValue = [...hrValue, [new Date(test), val.data.Hr.value]];
      rrValue = [...rrValue, [new Date(test), val.data.Rr.value]];
      if (val.data.Stress) {
        stressValue = [...stressValue, [new Date(test), val.data.Stress.value]];
      }
      if (val.data.Motion) {
        motionValue = [...motionValue, [new Date(test), val.data.Motion.value]];
      }
    });
    if (stressValue.length === 0) {
      stressValue = [[new Date(d.getUTCDate()), 0]];
    }
    if (motionValue.length === 0) {
      motionValue = [[new Date(d.getUTCDate()), 0]];
    }

    return {
      ...state,
      ...REDUCERS.SUCCESS,
      loadingGraph: false,
      selectedInteval: payload.interval,
      graphArray: {
        hrValue: [
          [{ type: "date", label: "Day" }, "Heart Rate"],
          ...hrValue.reverse(),
        ],
        rrValue: [
          [{ type: "date", label: "Day" }, "Respiratory Rate"],
          ...rrValue.reverse(),
        ],
        stressValue: [
          [{ type: "date", label: "Day" }, "Stress Rate"],
          ...stressValue.reverse(),
        ],
        motionValue: [
          [{ type: "date", label: "Day" }, "Motion Rate"],
          ...motionValue.reverse(),
        ],
      },
    };
  },
  [FETCH_NEW_USER_DATA_ERROR]: (state) => {
    return {
      ...state,
      loadingGraph: false,
    };
  },
  [FETCH_NEW_USER_MIN_DATA_ERROR]: (state) => {
    return {
      ...state,
      loadingGraph: false,
    };
  },
  [RESET_GRAPH_SUCCESS]: (state) => {
    return {
      ...state,
      graphArray: {
        hrValue: [
          [{ type: "date", label: "Day" }, "Heart Rate"],
          [new Date(d.getUTCDate()), 0],
        ],
        rrValue: [
          [{ type: "date", label: "Day" }, "Respiratory Rate"],
          [new Date(d.getUTCDate()), 0],
        ],
        stressValue: [
          [{ type: "date", label: "Day" }, "Stress Rate"],
          [new Date(d.getUTCDate()), 0],
        ],
        motionValue: [
          [{ type: "date", label: "Day" }, "Motion Rate"],
          [new Date(d.getUTCDate()), 0],
        ],
        rssHrMvValue: [
          [{ type: "date", label: "Day" }, "Rss Hr Mv Rate"],
          [new Date(d.getUTCDate()), 0],
        ],
        rssRrMvValue: [
          [{ type: "date", label: "Day" }, "Rss Rr Mv Stress Rate"],
          [new Date(d.getUTCDate()), 0],
        ],
        sdnnValue: [
          [{ type: "date", label: "Day" }, "Sdnn Rate"],
          [new Date(d.getUTCDate()), 0],
        ],
        rmssdValue: [
          [{ type: "date", label: "Day" }, "Rmssd Rate"],
          [new Date(d.getUTCDate()), 0],
        ],
        hrTdValue: [
          [{ type: "date", label: "Day" }, "Hr Td Rate"],
          [new Date(d.getUTCDate()), 0],
        ],
        hrFdValue: [
          [{ type: "date", label: "Day" }, "Hr Fd Rate"],
          [new Date(d.getUTCDate()), 0],
        ],
        hrSelValue: [
          [{ type: "date", label: "Day" }, "Hr Sel Rate"],
          [new Date(d.getUTCDate()), 0],
        ],
        hrEstValue: [
          [{ type: "date", label: "Day" }, "Hr Est Rate"],
          [new Date(d.getUTCDate()), 0],
        ],
        winFdValue: [
          [{ type: "date", label: "Day" }, "Win Fd Rate"],
          [new Date(d.getUTCDate()), 0],
        ],
        winSelValue: [
          [{ type: "date", label: "Day" }, "Win Sel Rate"],
          [new Date(d.getUTCDate()), 0],
        ],
        winEstValue: [
          [{ type: "date", label: "Day" }, "Win Est Rate"],
          [new Date(d.getUTCDate()), 0],
        ],
      },
      backDateData: false,
    };
  },
  [FETCH_DEVICE_SPECIFIED_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
    deviceTableHeader: {},
    deviceTable: {},
  }),
  [FETCH_DEVICE_SPECIFIED_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      deviceTableHeader: payload,
    };
  },
  [FETCH_DEVICE_SPECIFIED_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
    deviceTableHeader: {},
    deviceTable: {},
  }),
  [GET_PROPERTY_DATA_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [GET_PROPERTY_DATA_SUCCESS]: (state, { payload }) => {
    let newData = { ...state.deviceTable, ...payload };
    let sortKeys = Object.keys(newData).sort(function (a, b) {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      deviceTable: sortKeys.reduce((acc, val) => {
        let obj = {};
        obj[val] = newData[val];
        return { ...acc, ...obj };
      }, {}),
    };
  },
  [CREATE_DEVICE_DATA_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [CREATE_DEVICE_DATA_SUCCESS]: (state) => ({
    ...state,
    ...REDUCERS.SUCCESS,
  }),
  [CREATE_DEVICE_DATA_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [UPDATE_DEVICE_DATA_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.SUCCESS,
  }),
  [UPDATE_DEVICE_DATA_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [UPDATE_DEFAULT_DATA_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [UPDATE_DEFAULT_DATA_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [UPDATE_DEFAULT_THRESHOLD_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [UPDATE_DEFAULT_THRESHOLD_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [DELETE_DEVICE_DATA_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [DELETE_DEVICE_DATA_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [SYNC_FRIENDLY_NAME_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [SYNC_FRIENDLY_NAME_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [FETCH_HW_SW_VERSION_REQUEST]: (state) => {
    return {
      ...state,
      swVersion: "",
      hwVersion: "",
    };
  },
  [FETCH_HW_SW_VERSION_ERROR]: (state) => {
    return {
      ...state,
      swVersion: "",
      hwVersion: "",
    };
  },
  [FETCH_HW_SW_VERSION_SUCCESS]: (state, { payload }) => {
    let fwV = "";
    let hwV = "";
    if (payload.data.length > 0) {
      fwV = payload.data[0].data?.Fwvers?.value || "";
      hwV = payload.data[0].data?.Hwvers?.value || "";
    }
    return {
      ...state,
      swVersion: fwV,
      hwVersion: hwV,
    };
  },
  [FETCH_CSV_DATA_REQUEST]: (state) => {
    return {
      ...state,
      loadingCsv: true,
    };
  },
  [FETCH_CSV_DATA_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      loadingCsv: false,
    };
  },
  [FETCH_CSV_DATA_ERROR]: (state) => {
    return {
      ...state,
      loadingCsv: false,
    };
  },
  [FETCH_FRIENDLY_NAME_SUCCESS]: (state, { payload }) => {
    let friendlyNameObj = payload.user
      ? [...payload.friendlyNameData]
      : [
        {
          macId: "all",
          friendlyName: "Select All",
        },
        ...payload.friendlyNameData.sort((a, b) =>
          a.friendlyName > b.friendlyName ? 1 : -1
        ),
      ];
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      loading: false,
      friendlyNameUser1Data:
        payload.user === "user1"
          ? friendlyNameObj.sort((a, b) =>
            a.friendlyName > b.friendlyName ? 1 : -1
          )
          : state.friendlyNameUser1Data,
      friendlyNameUser2Data:
        payload.user === "user2"
          ? friendlyNameObj.sort((a, b) =>
            a.friendlyName > b.friendlyName ? 1 : -1
          )
          : state.friendlyNameUser2Data,
      friendlyNameUser3Data:
        payload.user === "user3"
          ? friendlyNameObj.sort((a, b) =>
            a.friendlyName > b.friendlyName ? 1 : -1
          )
          : state.friendlyNameUser3Data,
      friendlyNameData: friendlyNameObj,
    };
  },
  [FETCH_FRIENDLY_NAME_ERROR]: (state) => {
    return {
      ...state,
      ...REDUCERS.ERROR,
      loading: false,
      friendlyNameData: []
    };
  },
  [DOWNLOAD_DEVICE_FIRMWARE_REQUEST]: (state) => {
    return {
      ...state,
      loadingFirmware: true,
    };
  },
  [DOWNLOAD_DEVICE_FIRMWARE_SUCCESS]: (state) => {
    return {
      ...state,
      loadingFirmware: false,
    };
  },
  [DOWNLOAD_DEVICE_FIRMWARE_ERROR]: (state) => {
    return {
      ...state,
      loadingFirmware: false,
    };
  },
  [FETCH_NEW_DEV_DATA_SUCCESS]: (state, { payload }) => {
    const graphData = state.graphData.map((val) => {
      Object.keys(val.linkedGraphData).forEach((macId) => {
        let checked = payload.content.find(
          (dd) =>
            dd.data.DevidDevhealth.value === macId && dd.ownerId === val.userId
        );
        if (checked) {
          val.linkedGraphData[macId].Fwvers = checked?.data["Last-Fwvers"]
            ? checked?.data["Last-Fwvers"].value
            : val.linkedGraphData[macId].Fwvers;
          val.linkedGraphData[macId].Hwvers = checked?.data["Last-Hwvers"]
            ? checked?.data["Last-Hwvers"].value
            : val.linkedGraphData[macId].Hwvers;
        }
      });
      return val;
    });
    return {
      ...state,
      graphData,
    };
  },
  [FETCH_NEW_SV_DATA_SUCCESS]: (state, { payload }) => {
    const graphData = state.graphData.map((val) => {
      const updatedUser = payload.content.find((v) => v.userId === val.userId);
      const macId = updatedUser?.data.DevidSvital.value;
      if (macId) {
        val.linkedGraphData[macId] = {
          ...val.linkedGraphData[macId],
          apneaperminute: updatedUser?.data?.Apneaperminute.value || val?.linkedGraphData[macId]?.Apneaperminute,
          occupied: updatedUser?.data?.Occupied.value || val.linkedGraphData[macId].Occupied,
        };
      }
      return val;
    });
    return {
      ...state,
      graphData,
    };
  },
  [FETCH_SVITTAL_DATA_SUCCESS]: (state, { payload }) => {
    const graphData = state.graphData.map((val) => {
      const updatedUser = payload.content.find((v) => v.userId === val.userId);
      const macId = updatedUser?.data.DevidSvital.value;
      if (macId) {
        val.linkedGraphData[macId] = {
          ...val.linkedGraphData[macId],
          Occupied: updatedUser?.data?.Occupied
            ? updatedUser?.data?.Occupied?.value
            : val.linkedGraphData[macId].Occupied,
          Apneaperminute: updatedUser?.data?.Apneaperminute
            ? updatedUser?.data?.Apneaperminute?.value
            : val.linkedGraphData[macId].Apneaperminute,
        };
      }
      return val;
    });
    return {
      ...state,
      graphData,
    };
  },
  [FETCH_PARAMS_DATA_SUCCESS]: (state, { payload }) => {
    const graphData = state.graphData.map((val) => {
      const updatedUser = payload.content.find((v) => v.userId === val.userId);
      const macId = updatedUser?.data.DevidParams.value;
      if (macId) {
        val.linkedGraphData[macId] = {
          ...val.linkedGraphData[macId],
          Csv1sec: updatedUser?.data?.Csv1sec
            ? updatedUser?.data?.Csv1sec?.value
            : "No",
        };
      }
      return val;
    });
    return {
      ...state,
      graphData,
    };
  },
  [FETCH_DIAGNOSTIC_PANEL_DATA_REQUEST]: (state) => {
    return {
      ...state,
      loadingData: true,
      diagnosticPanel1: [
        ["Time", "First User", "Second User", "Third User"],
        [new Date(), 0, 0, 0],
      ],
      diagnosticPanel2: [
        ["Time", "First User", "Second User", "Third User"],
        [new Date(), 0, 0, 0],
      ],
    };
  },
  [FETCH_DIAGNOSTIC_PANEL_DATA_ERROR]: (state) => {
    return {
      ...state,
      loadingData: false,
      diagnosticPanel1: [
        ["Time", "First User", "Second User", "Third User"],
        [new Date(), 0, 0, 0],
      ],
      diagnosticPanel2: [
        ["Time", "First User", "Second User", "Third User"],
        [new Date(), 0, 0, 0],
      ],
    };
  },
  [FETCH_DIAGNOSTIC_PANEL_DATA_SUCCESS]: (state, { payload }) => {
    const firstUser = state.friendlyNameUser1Data.find(
      (val) => val.macId === payload.firstUserId.value
    );
    const secondUser = state.friendlyNameUser2Data.find(
      (val) => val.macId === payload.secondUserId.value
    );
    const thirdUser = state.friendlyNameUser3Data.find(
      (val) => val.macId === payload.thirdUserId.value
    );
    payload.firstUserFirstData =
      payload.firstUserFirstData.length === 0
        ? payload.secondUserFirstData.map((val) => ({
          ...val,
          view: { ...val.view, value: 0 },
        }))
        : payload.firstUserFirstData;
    let finalObj = [];
    payload.firstUserFirstData.forEach((val) => {
      const oneSecAdded = moment(val.view.groupDate).add("1", "second").unix();
      const twoSecAdded = moment(val.view.groupDate).add("2", "second").unix();
      const newTime = momentTz
        .utc(val.view.groupDate)
        .tz(payload.currentUserTimeZone)
        .format("MM/DD/YYYY h:mm:ss a");
      const secondObj = payload.secondUserFirstData.find(
        (sVal) =>
          sVal.view.groupDate === val.view.groupDate ||
          oneSecAdded === moment(sVal.view.groupDate).unix() ||
          twoSecAdded === moment(sVal.view.groupDate).unix()
      );
      const thirdObj = payload.thirdUserFirstData.find(
        (sVal) =>
          sVal.view.groupDate === val.view.groupDate ||
          oneSecAdded === moment(sVal.view.groupDate).unix() ||
          twoSecAdded === moment(sVal.view.groupDate).unix()
      );
      if (
        payload.secondUser &&
        payload.thirdUser &&
        (secondObj?.view?.value || secondObj?.view?.value === 0) &&
        (thirdObj?.view?.value || thirdObj?.view?.value === 0)
      ) {
        finalObj = [
          ...finalObj,
          [
            new Date(newTime),
            val?.view?.value || 0,
            secondObj?.view?.value || 0,
            thirdObj?.view?.value || 0,
          ],
        ];
      } else if (
        payload.secondUser &&
        !payload.thirdUser &&
        (secondObj?.view?.value || secondObj?.view?.value === 0)
      ) {
        finalObj = [
          ...finalObj,
          [
            new Date(newTime),
            val?.view?.value || 0,
            secondObj?.view?.value || 0,
          ],
        ];
      } else if (
        !payload.secondUser &&
        payload.thirdUser &&
        (thirdObj?.view?.value || thirdObj?.view?.value === 0)
      ) {
        finalObj = [
          ...finalObj,
          [
            new Date(newTime),
            val?.view?.value || 0,
            thirdObj?.view?.value || 0,
          ],
        ];
      }
    });
    payload.firstUserSecondData =
      payload.firstUserSecondData.length === 0
        ? payload.secondUserSecondData.map((val) => ({
          ...val,
          view: { ...val.view, value: 0 },
        }))
        : payload.firstUserSecondData;
    let finalSecondObj = [];
    payload.firstUserSecondData.forEach((val) => {
      const oneSecAdded = moment(val.view.groupDate).add("1", "second").unix();
      const twoSecAdded = moment(val.view.groupDate).add("2", "second").unix();
      const newTime = momentTz
        .utc(val.view.groupDate)
        .tz(payload.currentUserTimeZone)
        .format("MM/DD/YYYY h:mm:ss a");
      const secondObj = payload.secondUserSecondData.find(
        (sVal) =>
          sVal.view.groupDate === val.view.groupDate ||
          oneSecAdded === moment(sVal.view.groupDate).unix() ||
          twoSecAdded === moment(sVal.view.groupDate).unix()
      );
      const thirdObj = payload.thirdUserSecondData.find(
        (sVal) =>
          sVal.view.groupDate === val.view.groupDate ||
          oneSecAdded === moment(sVal.view.groupDate).unix() ||
          twoSecAdded === moment(sVal.view.groupDate).unix()
      );
      if (
        payload.secondUser &&
        payload.thirdUser &&
        (secondObj?.view?.value || secondObj?.view?.value === 0) &&
        (thirdObj?.view?.value || thirdObj?.view?.value === 0)
      ) {
        finalSecondObj = [
          ...finalSecondObj,
          [
            new Date(newTime),
            val?.view?.value || 0,
            secondObj?.view?.value || 0,
            thirdObj?.view?.value || 0,
          ],
        ];
      } else if (
        payload.secondUser &&
        !payload.thirdUser &&
        (secondObj?.view?.value || secondObj?.view?.value === 0)
      ) {
        finalSecondObj = [
          ...finalSecondObj,
          [
            new Date(newTime),
            val?.view?.value || 0,
            secondObj?.view?.value || 0,
          ],
        ];
      } else if (
        !payload.secondUser &&
        payload.thirdUser &&
        (thirdObj?.view?.value || thirdObj?.view?.value === 0)
      ) {
        finalSecondObj = [
          ...finalSecondObj,
          [
            new Date(newTime),
            val?.view?.value || 0,
            thirdObj?.view?.value || 0,
          ],
        ];
      }
    });

    let headings = ["Time", firstUser.friendlyName];
    if (payload.secondUser && payload.thirdUser) {
      headings = [
        "Time",
        firstUser.friendlyName,
        secondUser.friendlyName,
        thirdUser.friendlyName,
      ];
    } else if (payload.secondUser && !payload.thirdUser) {
      headings = ["Time", firstUser.friendlyName, secondUser.friendlyName];
    } else if (!payload.secondUser && payload.thirdUser) {
      headings = ["Time", firstUser.friendlyName, thirdUser.friendlyName];
    }
    return {
      ...state,
      loadingData: false,
      diagnosticPanel1: [headings, ...finalObj],
      diagnosticPanel2: [headings, ...finalSecondObj],
    };
  },
  [RESET_DIAGNOSTIC_PANEL_DATA_REQUEST]: (state) => {
    return {
      ...state,
      loadingData: false,
      diagnosticPanel1: [
        ["Time", "First User", "Second User", "Third User"],
        [new Date(), 0, 0, 0],
      ],
      diagnosticPanel2: [
        ["Time", "First User", "Second User", "Third User"],
        [new Date(), 0, 0, 0],
      ],
    };
  },
  [SAVE_DIAGNOSTIC_PANEL_DATA_REQUEST]: (state) => {
    return {
      ...state,
      loadingData: true,
    };
  },
  [SAVE_DIAGNOSTIC_PANEL_DATA_ERROR]: (state) => {
    return {
      ...state,
      loadingData: false,
    };
  },
  [SAVE_DIAGNOSTIC_PANEL_DATA_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      loadingData: false,
    };
  },
  [FETCH_SAVED_DIAGNOSTIC_PANEL_DATA_REQUEST]: (state) => {
    return {
      ...state,
      loadingData: true,
      diagnosticFilter: {},
    };
  },
  [FETCH_SAVED_DIAGNOSTIC_PANEL_DATA_ERROR]: (state) => {
    return {
      ...state,
      loadingData: false,
      diagnosticFilter: {},
    };
  },
  [FETCH_SAVED_DIAGNOSTIC_PANEL_DATA_SUCCESS]: (state, { payload }) => {
    let intervalStartTime = momentTz
      .tz(DEFAULT_UTC_TIME)
      .subtract("1", "h")
      .format("MM/DD/YYYY h:mm a");
    if (payload[0].data?.Interval?.value) {
      const intervalType = payload[0].data?.Interval?.value.split(" ")[1]
        ? payload[0].data?.Interval?.value.split(" ")[1].toLowerCase()
        : payload[0].data?.Interval?.value.split(" ")[0].toLowerCase();

      if (intervalType === "hr") {
        intervalStartTime = momentTz
          .tz(DEFAULT_UTC_TIME)
          .subtract(payload[0].data?.Interval?.value.split(" ")[0], "h")
          .format("MM/DD/YYYY h:mm a");
      } else if (intervalType === "min" || intervalType === "mins") {
        intervalStartTime = momentTz
          .tz(DEFAULT_UTC_TIME)
          .subtract(payload[0].data?.Interval?.value.split(" ")[0], "m")
          .format("MM/DD/YYYY h:mm a");
      } else if (intervalType === "daily") {
        intervalStartTime = momentTz
          .tz(DEFAULT_UTC_TIME)
          .subtract(24, "h")
          .format("MM/DD/YYYY h:mm a");
      } else if (intervalType === "weekly") {
        intervalStartTime = momentTz
          .tz(DEFAULT_UTC_TIME)
          .subtract(7, "day")
          .format("MM/DD/YYYY h:mm a");
      } else if (intervalType === "monthly") {
        intervalStartTime = momentTz
          .tz(DEFAULT_UTC_TIME)
          .subtract(30, "day")
          .format("MM/DD/YYYY h:mm a");
      }
    }
    return {
      ...state,
      loadingData: false,
      diagnosticFilter: {
        deviceDataId: payload[0].deviceDataId || "",
        userId1: payload[0].data?.User1?.value || "",
        userId2: payload[0].data?.User2?.value || "",
        userId3: payload[0].data?.User3?.value || "",
        macAddress1: payload[0].data?.Fname1?.value || "",
        macAddress2: payload[0].data?.Fname2?.value || "",
        macAddress3: payload[0].data?.Fname3?.value || "",
        interval: payload[0].data?.Interval?.value || "",
        graphProperty1: payload[0].data?.Graph1?.value || "",
        graphProperty2: payload[0].data?.Graph2?.value || "",
        timezone: payload[0].data?.Timezone?.value || "",
        intervalStartTime,
        // intervalStartTime: payload[0].data?.Starttime?.value
        //   ? moment(payload[0].data?.Starttime?.value).format(
        //       "MM/DD/YYYY h:mm a"
        //     )
        //   : "",
        loggedUserId: payload[0].data?.Createdbyuser?.value || "",
        timeformat: payload[0].data?.Timeformat?.value || "",
      },
    };
  },
  [FETCH_DIAGNOSTIC_PANEL_REQUEST]: (state) => {
    return {
      ...state,
      loadingData: true,
      diagnosticList: [],
    };
  },
  [FETCH_DIAGNOSTIC_PANEL_ERROR]: (state) => {
    return {
      ...state,
      loadingData: false,
      diagnosticList: [],
    };
  },
  [FETCH_DIAGNOSTIC_PANEL_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      loadingData: false,
      diagnosticList: payload.content,
      totalDiagnosticElements: payload.totalElements,
    };
  },
  [FETCH_USER_ORDER_DATA_REQUEST]: (state) => ({
    ...state,
    loadingData: true,
    userOrderData: []
  }),
  [FETCH_USER_ORDER_DATA_REQUEST_SUCCESS]: (state, { payload }) => ({
    ...state,
    loadingData: false,
    userOrderData: payload.content
  }),
  [FETCH_USER_ORDER_DATA_REQUEST_ERROR]: (state) => ({
    ...state,
    loadingData: false,
    userOrderData: []
  }),
  [FETCH_DEVICE_DETAILS_REQUEST]: (state) => ({
    ...state,
    loadingData: true,
    deviceDataById: {}
  }),
  [FETCH_DEVICE_DETAILS_SUCCESS]: (state, { payload }) => ({
    ...state,
    loadingData: false,
    deviceDataById: payload.content && payload?.content[0]
  }),
  [FETCH_DEVICE_DETAILS_ERROR]: (state) => ({
    ...state,
    loadingData: false,
    deviceDataById: {}
  }),
  [SAVE_USER_ORDER_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
    userSensorPosSaved: false
  }),
  [SAVE_USER_ORDER_SUCCESS]: (state) => ({
    ...state,
    ...REDUCERS.SUCCESS,
    userSensorPosSaved: true
  }),
  [SAVE_USER_ORDER_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
    userSensorPosSaved: false
  }),
};

export default createReducer(schema, reducer);
