import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { Box } from '@material-ui/core';
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import Snackbar from "components/Snackbar/Snackbar.js";
import GridContainer from "components/Grid/GridContainer.js";
import Loader from "components/Loader/Loader.js";
import { Chart } from "react-google-charts";
import Filter from "./Filter.js";
import {
  FETCH_DEVICE_REQUEST,
  FETCH_FRIENDLY_NAME_REQUEST,
} from "../../redux/device/actions";
import { FETCH_USER_LIST_REQUEST } from "../../redux/users/actions";
import {
  FETCH_SAVED_DIAGNOSTIC_PANEL_DATA_REQUEST, RESET_REQUEST, FETCH_DIAGNOSTIC_PANEL_DATA_REQUEST, RESET_DIAGNOSTIC_PANEL_DATA_REQUEST, SAVE_DIAGNOSTIC_PANEL_DATA_REQUEST
} from "../../redux/device/actions";
import { useComposeDispatcher } from "../../common/hooks";
import { DEFAULT_TIME, DEFAULT_UTC_TIME, DEFAULT_TIMEZONE } from '../../common/constants';

const defaultObj = {
  interval: "1 Hr",
  deviceId: "d36a4373-fbdc-44a3-8c96-4bb920041e40",
  intervalStartTime: moment.tz(DEFAULT_UTC_TIME).subtract('1', 'h').format("MM/DD/YYYY h:mm a"),
  supplierId: "cd5dc314-6e25-48a8-9d47-9e574b8fe3a0",
  graphProperty1: 'Hr',
  graphProperty2: 'Rr',
  timeformat: 'mins'
};

export default function DiagnosticPanel() {
  const [defaultKey, setDefaultKey] = useState(Date.now());

  const { device, users } = useSelector((state) => ({
    device: state.device,
    users: state.users,
  }));

  const userDetails = JSON.parse(localStorage.getItem("user-details"));

  const loggedUserId = userDetails !== null ? userDetails.userId : "";

  const [userInfo, setUserInfo] = useState(defaultObj);

  const [alertMessage, setAlertMessage] = useState("");

  const [userAbbr, setUserAbbr] = useState(DEFAULT_TIMEZONE);

  const [userTime, setUserTime] = useState(DEFAULT_TIME);

  const [userUtcTime, setUserUtcTime] = useState(DEFAULT_UTC_TIME);

  const [toastStatus, setToastStatus] = useState(false);

  const [getDeviceList] = useComposeDispatcher(FETCH_DEVICE_REQUEST);

  const [getUserList] = useComposeDispatcher(FETCH_USER_LIST_REQUEST);

  const [resetDeviceData] = useComposeDispatcher(RESET_REQUEST);

  const [resetGraphData] = useComposeDispatcher(RESET_DIAGNOSTIC_PANEL_DATA_REQUEST);

  const [saveGraphData] = useComposeDispatcher(SAVE_DIAGNOSTIC_PANEL_DATA_REQUEST);

  const [fetchSavedDiagnosticPanelData] = useComposeDispatcher(FETCH_SAVED_DIAGNOSTIC_PANEL_DATA_REQUEST);

  const [fetchFriendlyNameData] = useComposeDispatcher(
    FETCH_FRIENDLY_NAME_REQUEST
  );

  const [fetchDiagnosticPanelData] = useComposeDispatcher(
    FETCH_DIAGNOSTIC_PANEL_DATA_REQUEST
  );

  useEffect(() => {
    resetDeviceData();
    getUserList({
      role: "Patient",
      pageNumber: 0,
      pageSize: 100,
      sortBy: "firstName",
      sortOrder: "ASC",
    });
    getDeviceList({ status: "Active" });
    fetchSavedDiagnosticPanelData(loggedUserId);
  }, []);

  useEffect(() => {
    if (device.diagnosticFilter.userId1) {
      fetchFriendlyNameData({
        userId: device.diagnosticFilter.userId1,
        deviceDataModelId: userInfo.deviceId,
        user: 'user1'
      });
    }
    if (device.diagnosticFilter.userId2) {
      fetchFriendlyNameData({
        userId: device.diagnosticFilter.userId2,
        deviceDataModelId: userInfo.deviceId,
        user: 'user2'
      });
    }
    if (device.diagnosticFilter.userId3) {
      fetchFriendlyNameData({
        userId: device.diagnosticFilter.userId3,
        deviceDataModelId: userInfo.deviceId,
        user: 'user3'
      });
    }
    // delete device.diagnosticFilter.intervalStartTime;
    //  
    setUserInfo({ ...userInfo, ...device.diagnosticFilter });
    setDefaultKey(Date.now())
  }, [device.diagnosticFilter]);

  useEffect(() => {
    setDefaultKey(Date.now())
  }, [device.friendlyNameUser1Data]);

  const getDeviceData = () => {
    if (
      userInfo.interval &&
      userInfo.intervalStartTime &&
      userInfo.graphProperty1 &&
      userInfo.graphProperty2 &&
      userInfo.userId1 &&
      userInfo.macAddress1
    ) {
      if (!userInfo.userId2 && !userInfo.macAddress2 && !userInfo.userId3 && !userInfo.macAddress3) {
        fetchDataForSignal();
      } else if ((!userInfo.userId2 || !userInfo.macAddress2) && (!userInfo.userId3 || !userInfo.macAddress3)) {
        setAlertMessage("All fields are mandatory");
        setToastStatus(true);
      } else {
        fetchDataForSignal();
      }
    } else {
      setAlertMessage("All fields are mandatory");
      setToastStatus(true);
    }
  }

  const fetchDataForSignal = () => {
    var date = moment(userInfo.intervalStartTime, "MM/DD/YYYY h:mm a", true).isValid();
    if (date) {
      const selectedTime = moment(userInfo.intervalStartTime).format("YYYY-MM-DDTHH:mm:ss");
      var newStartTime = moment.tz(selectedTime, userUtcTime).utc().format("YYYY-MM-DDTHH:mm:ss");
      var modifiedTime = moment.tz(selectedTime, userUtcTime).utc().format("YYYY-MM-DDTHH:mm:ss");
      const intervalArray = userInfo.interval.split(" ");
       
      if (intervalArray[0] === "Weekly") {
        modifiedTime = moment.utc(newStartTime).add(7, "day").format("YYYY-MM-DDTHH:mm:ss");
      } else if (intervalArray[0] === "Monthly") {
        modifiedTime = moment.utc(newStartTime).add(30, "day").format("YYYY-MM-DDTHH:mm:ss");
      } else if (intervalArray[0] === "Daily") {
        modifiedTime = moment.utc(newStartTime).add(24, "h").format("YYYY-MM-DDTHH:mm:ss");
      } else if (intervalArray[1] === "Hr") {
        modifiedTime = moment.utc(newStartTime).add(intervalArray[0], "h").format("YYYY-MM-DDTHH:mm:ss");
      } else if (intervalArray[1] === "secs") {
        modifiedTime = moment.utc(newStartTime).add(intervalArray[0], "seconds").format("YYYY-MM-DDTHH:mm:ss");
      } else {
        modifiedTime = moment.utc(newStartTime).add(intervalArray[0], "minutes").format("YYYY-MM-DDTHH:mm:ss");
      }
      let selectedDevice1 = userInfo.deviceId;
      let selectedDevice2 = userInfo.deviceId;
      let filter1Criteria = 'DevidPvital';
      let filter2Criteria = 'DevidPvital';
      let filter1Criteria1 = 'Timestamp';
      let filter2Criteria2 = 'Timestamp';
      let filter1 = {
        sortBy: 'data.Timestamp.value',
        sortOrder: 'DESC'
      };
      let filter2 = {
        sortBy: 'data.Timestamp.value',
        sortOrder: 'DESC'
      };
      if (userInfo.graphProperty1 === 'Sdnn' || userInfo.graphProperty1 === 'Rmssd' || userInfo.graphProperty1 === 'Occupied') {
        selectedDevice1 = 'd36a4373-fbdc-44a3-8c96-4bb920041e40-sv';
        filter1 = {
          sortBy: 'data.TimestampS.value',
          sortOrder: 'DESC'
        }
        filter1Criteria = 'DevidSvital';
        filter1Criteria1 = 'TimestampSv'
      }
      if (userInfo.graphProperty2 === 'Sdnn' || userInfo.graphProperty2 === 'Rmssd' || userInfo.graphProperty2 === 'Occupied') {
        selectedDevice2 = 'd36a4373-fbdc-44a3-8c96-4bb920041e40-sv';
        filter2 = {
          sortBy: 'data.TimestampS.value',
          sortOrder: 'DESC'
        }
        filter2Criteria = 'DevidSvital';
        filter2Criteria2 = 'TimestampSv'
      }
      const firstUserFirstRequest = {
        deviceDataModelId: selectedDevice1,
        dataPropertyCode: userInfo.graphProperty1,
        options: {
          aggregationType: null,
          groupingPropertyCode: filter1Criteria1,
          groupType: userInfo.timeformat === 'sec' ? "Second" : "Minute",
          defaultSelect: "Last"
        },
        deviceCriteria: [
          {
            key: filter1Criteria,
            operator: "Equal",
            value: userInfo.macAddress1,
            valueFrom: null,
            valueTo: null
          },
          {
            key: filter1Criteria1,
            operator: "Between",
            valueTo: modifiedTime,
            valueFrom: newStartTime
          }
        ],
        ownerFilter: {
          users: [userInfo.userId1]
        },
        userUtcTime
      };

      const firstUserSecondRequest = {
        deviceDataModelId: selectedDevice2,
        dataPropertyCode: userInfo.graphProperty2,
        options: {
          aggregationType: null,
          groupingPropertyCode: filter2Criteria2,
          groupType: userInfo.timeformat === 'sec' ? "Second" : "Minute",
          defaultSelect: "Last"
        },
        deviceCriteria: [
          {
            key: filter2Criteria,
            operator: "Equal",
            value: userInfo.macAddress1,
            valueFrom: null,
            valueTo: null
          },
          {
            key: filter2Criteria2,
            operator: "Between",
            valueTo: modifiedTime,
            valueFrom: newStartTime
          }
        ],
        ownerFilter: {
          users: [userInfo.userId1]
        },
      };

      let secondUserFirstRequest = {};
      let secondUserSecondRequest = {};
      if (userInfo.macAddress2) {
        secondUserFirstRequest = {
          deviceDataModelId: selectedDevice1,
          dataPropertyCode: userInfo.graphProperty1,
          options: {
            aggregationType: null,
            groupingPropertyCode: filter1Criteria1,
            groupType: userInfo.timeformat === 'sec' ? "Second" : "Minute",
            defaultSelect: "Last"
          },
          deviceCriteria: [
            {
              key: filter1Criteria,
              operator: "Equal",
              value: userInfo.macAddress2,
              valueFrom: null,
              valueTo: null
            },
            {
              key: filter1Criteria1,
              operator: "Between",
              valueTo: modifiedTime,
              valueFrom: newStartTime
            }
          ],
          ownerFilter: {
            users: [userInfo.userId2]
          },
        };
        secondUserSecondRequest = {
          deviceDataModelId: selectedDevice2,
          dataPropertyCode: userInfo.graphProperty2,
          options: {
            aggregationType: null,
            groupingPropertyCode: filter2Criteria2,
            groupType: userInfo.timeformat === 'sec' ? "Second" : "Minute",
            defaultSelect: "Last"
          },
          deviceCriteria: [
            {
              key: filter2Criteria,
              operator: "Equal",
              value: userInfo.macAddress2,
              valueFrom: null,
              valueTo: null
            },
            {
              key: filter2Criteria2,
              operator: "Between",
              valueTo: modifiedTime,
              valueFrom: newStartTime
            }
          ],
          ownerFilter: {
            users: [userInfo.userId2]
          },
        };
      }

      let thirdUserFirstRequest = {};
      let thirdUserSecondRequest = {};
      if (userInfo.macAddress3) {
        thirdUserFirstRequest = {
          deviceDataModelId: selectedDevice1,
          dataPropertyCode: userInfo.graphProperty1,
          options: {
            aggregationType: null,
            groupingPropertyCode: filter1Criteria1,
            groupType: userInfo.timeformat === 'sec' ? "Second" : "Minute",
            defaultSelect: "Last"
          },
          deviceCriteria: [
            {
              key: filter1Criteria,
              operator: "Equal",
              value: userInfo.macAddress3,
              valueFrom: null,
              valueTo: null
            },
            {
              key: filter1Criteria1,
              operator: "Between",
              valueTo: modifiedTime,
              valueFrom: newStartTime
            }
          ],
          ownerFilter: {
            users: [userInfo.userId3]
          }
        };
        thirdUserSecondRequest = {
          deviceDataModelId: selectedDevice2,
          dataPropertyCode: userInfo.graphProperty2,
          options: {
            aggregationType: null,
            groupingPropertyCode: filter2Criteria2,
            groupType: userInfo.timeformat === 'sec' ? "Second" : "Minute",
            defaultSelect: "Last"
          },
          deviceCriteria: [
            {
              key: filter2Criteria,
              operator: "Equal",
              value: userInfo.macAddress3,
              valueFrom: null,
              valueTo: null
            },
            {
              key: filter2Criteria2,
              operator: "Between",
              valueTo: modifiedTime,
              valueFrom: newStartTime
            }
          ],
          ownerFilter: {
            users: [userInfo.userId3]
          }
        };
      }
      fetchDiagnosticPanelData({ firstUserFirstRequest, secondUserFirstRequest, thirdUserFirstRequest, firstUserSecondRequest, secondUserSecondRequest, thirdUserSecondRequest, filter1, filter2 });
    } else {
      setAlertMessage('Please enter Start Time in MM/DD/YYYY h:mm a format (Example: 04/27/2022 1:14 pm)')
      setToastStatus(true);
    }
  }

  const resetGraphDeviceData = () => {
    resetGraphData();
    setUserInfo(defaultObj);
  }

  return (
    <>
      <GridContainer>
        <Filter
          device={device}
          userInfo={userInfo}
          userList={users.userList}
          setUserInfo={setUserInfo}
          getUserList={getUserList}
          getDeviceData={getDeviceData}
          defaultKey={defaultKey}
          setDefaultKey={setDefaultKey}
          fetchFriendlyNameData={fetchFriendlyNameData}
          userAbbr={userAbbr}
          userTime={userTime}
          setUserAbbr={setUserAbbr}
          setUserTime={setUserTime}
          userUtcTime={userUtcTime}
          setUserUtcTime={setUserUtcTime}
          resetGraphDeviceData={resetGraphDeviceData}
          saveGraphData={saveGraphData}
          loggedUserId={loggedUserId}
          loggedUserName={userDetails.fullName}
        />
      </GridContainer>
      {device?.diagnosticPanel1?.length > 2 && <Box p={2} bgcolor="#1281FB" pt={1}>
        <Chart
          height={300}
          chartType="LineChart"
          loader={
            <div style={{ color: "#ffffff", textAlign: "center" }}>
              Loading Chart
            </div>
          }
          data={device.diagnosticPanel1}
          options={{
            title: userInfo.graphProperty1 && `Signal - ${userInfo.graphProperty1}`,
            // curveType: "function",
            legend: { position: "right" },
            width: "100%",
          }}
          legendToggle={false}
          legend_toggle={true}
        />
      </Box>}
      {
        device.diagnosticPanel2?.length > 2 && <Box p={2} bgcolor="#1281FB" pt={1}>
          <Chart
            height={300}
            chartType="LineChart"
            loader={
              <div style={{ color: "#ffffff", textAlign: "center" }}>
                Loading Chart
              </div>
            }
            data={device.diagnosticPanel2}
            options={{
              title: userInfo.graphProperty2 && `Signal - ${userInfo.graphProperty2}`,
              // curveType: "function",
              legend: { position: "right" },
              width: "100%",
            }}
            legendToggle={false}
            legend_toggle={true}
          />
        </Box>
      }
      <Loader status={device.loading || device.loadingData} />
      <Snackbar
        place="tr"
        color="danger"
        icon={AddAlert}
        message={alertMessage}
        open={toastStatus}
        closeNotification={() => setToastStatus(false)}
        close
      />
    </>
  );
}
