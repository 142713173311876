import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import FavoriteBorderSharpIcon from '@material-ui/icons/FavoriteBorderSharp';
import FavoriteSharpIcon from '@material-ui/icons/FavoriteSharp';
import ViewHeadlineIcon from "@material-ui/icons/ViewHeadline";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import WifiOffIcon from '@material-ui/icons/WifiOff';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import React, { useState, useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Popover from "@material-ui/core/Popover";
import Tooltip from "@material-ui/core/Tooltip";
import Drawer from "@material-ui/core/Drawer";
import { Chart } from "react-google-charts";
import Box from "@material-ui/core/Box";
import Datetime from "react-datetime";
import moment from "moment-timezone";

// core components
import VerticalProgress from "../../components/VerticalProgress";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import { UTC_TIME_ZONE } from "../../common/constants";
import GridItem from "components/Grid/GridItem.js";

const logo = require("assets/img/logo-black.png");
const today = moment();
const disableFutureDt = (current) => current.isBefore(today);

const useStyles = makeStyles({
  readingSection: {
    padding: "16px !important",
    fontSize: "17px",
  },
  noPadding: {
    padding: "0 !important",
  },
  paddingZeroMarginRight: {
    padding: "0 !important",
    marginRight: "16px",
  },
  fullHeight: {
    height: "100% !important",
    padding: "0 !important",
    marginRight: "16px",
  },
  headingSize: {
    fontSize: "22px",
    lineHeight: "24px",
  },
  subHeadingSize: {
    fontSize: "22px",
    lineHeight: "24px",
    paddingTop: "3px",
  },
  subHeading: {
    fontSize: "20px",
    lineHeight: "22px",
  },
  subHeadingTitleSize: {
    fontSize: "35px !important",
    lineHeight: "36px !important",
  },
  readingSize: {
    fontSize: "58px",
    lineHeight: "60px",
  },
  searchBox: {
    borderRadius: "5px",
    "& .MuiInputBase-fullWidth": {
      padding: "3px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "red !important",
    },
  },
  drawerRoot: {
    "& .MuiPaper-root": {
      width: "100%",
    },
  },
  labelFocused: {
    color: "#E73F3B !important",
  },
  inputFocused: {
    "&:before": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "&:after": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "& input": {
      borderBottom: "#E73F3B !important",
    },
  },
  inputRoot: {
    color: "red",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
  popover: {
    zIndex: 2,
  },
  fab: {
    margin: "0",
    top: "auto",
    right: "20px",
    bottom: "20px",
    left: "auto",
    position: "fixed",
    zIndex: 3,
  },
  datePicker: {
    "& input": {
      fontSize: "15px !important",
      color: "#212121 !important",
    },
  },
});

const realTimeIntervals = ["1 min", "2 mins", "5 mins", "10 mins", "30 mins", "1 Hr", "2 Hr", "4 Hr", "6 Hr", "8 Hr"];
const nonRealTimeIntervals = ["1 min", "2 mins", "5 mins", "10 mins", "30 mins", "1 Hr", "2 Hr", "4 Hr", "6 Hr", "8 Hr", "10 Hr", "12 Hr", "18 Hr", "24 Hr", "Daily", "Weekly", "Monthly"];
let timeIntervals = realTimeIntervals;

export default function GraphDetail({
  successNotificationRequest,
  handleStartDateChange,
  selectedReadingParams,
  setDayLightSaving,
  selectedStartDate,
  fetchButtonTapped,
  setReadingParams,
  svittalDrawerRef,
  setDisablePdfBtn,
  onIntervalChange,
  apiResponseCount,
  setRealTimeType,
  apiRequestCount,
  setUserTimeZone,
  setFriendlyName,
  resetGraphData,
  dayLightSaving,
  loggedUserRole,
  disablePdfBtn,
  realTimeType,
  setDevDataId,
  userTimeZone,
  friendlyName,
  setUserInfo,
  setDrawer,
  drawerRef,
  setOffset,
  exportPdf,
  startTime,
  exportCSV,
  userInfo,
  endTime,
  setAbbr,
  drawer,
  offset,
  device,
  sensor,
  abbr
}) {
  const classes = useStyles();
  const [endTimeCDT, setEndTimeCDT] = useState(new Date());

  const currentDevice = device.graphData.find((val) => val.userId === sensor.userId);
  let offlineStatus = "Yes";
  let fwVersion = "--";
  let hwVersion = "--";
  let activeStatus = "No";
  let occupied = 0;
  let apneaperminute = "--";
  let csv1sec = "No";
  let locationValue = "--";

  const currentSensor = currentDevice?.linkedGraphData[userInfo.devID];
  if (currentSensor) {
    hwVersion = currentSensor.Hwvers || "--";
    fwVersion = currentSensor.Fwvers || "--";
    apneaperminute = currentSensor?.Apneaperminute;
    occupied = currentSensor?.Occupied;
    csv1sec = currentSensor?.Csv1sec || "No";
    locationValue = currentSensor?.Location || "--";
  }

  if (currentSensor?.data?.length > 0 && currentSensor?.data[0].data) {
    activeStatus = currentSensor?.active;
    offlineStatus = currentSensor.data[0].data?.Offline?.value || "--";
    occupied = currentSensor.data[0].data?.Occupied?.value || 0;
  }

  apneaperminute = apneaperminute === undefined ? "--" : apneaperminute;
  let startAndEndTime = `${moment
    .utc(startTime)
    .tz(userTimeZone)
    .format("MMMM Do YYYY, h:mm:ss a")} (${abbr}) - ${moment
      .utc(endTime)
      .tz(userTimeZone)
      .format("MMMM Do YYYY, h:mm:ss a")} (${abbr})`;

  let currentUtcFormatTime = moment().utc().format("YYYY-MM-DDTHH:mm:ss");
  let currentUtcTime = moment(currentUtcFormatTime);
  let latestHrReading = "-";
  let latestRrReading = "-";
  let latestRssMvReading = 0;
  let latestStressReading = "-";
  let latestMotionReading = 0;

  /* console.log('Device', device); */
  let minHrReading = "-";
  let maxHrReading = "-";
  let avgHrReading = "-";
  const hrData = [...device.graphArray.hrValue];
  if (hrData.length > 2) {
    const sortedHrData = hrData.sort((a, b) => a[1] - b[1]);
    sortedHrData.shift();
    minHrReading = Math.round(sortedHrData[0][1]);
    sortedHrData.reverse();
    maxHrReading = Math.round(sortedHrData[0][1]);
    avgHrReading = Math.round((sortedHrData.reduce((acc, val) => acc + val[1], 0) / sortedHrData.length).toFixed(1));
  }

  let minRrReading = "-";
  let maxRrReading = "-";
  let avgRrReading = "-";
  const rrData = [...device.graphArray.rrValue];
  if (rrData.length > 2) {
    const sortedRrData = rrData.sort((a, b) => a[1] - b[1]);
    sortedRrData.shift();
    minRrReading = Math.round(sortedRrData[0][1]);
    sortedRrData.reverse();
    maxRrReading = Math.round(sortedRrData[0][1]);
    avgRrReading = Math.round(
      (
        sortedRrData.reduce((acc, val) => acc + val[1], 0) / sortedRrData.length
      ).toFixed(1)
    );
  }

  let minMotionReading = "-";
  let maxMotionReading = "-";
  let avgMotionReading = "-";
  const motionData = [...device.graphArray.motionValue];
  if (motionData.length > 2) {
    const sortedMotionData = motionData.sort((a, b) => a[1] - b[1]);
    sortedMotionData.shift();
    minMotionReading = Math.round(sortedMotionData[0][1]);
    sortedMotionData.reverse();
    maxMotionReading = sortedMotionData[0][1];
    avgMotionReading = Math.round(
      (
        sortedMotionData.reduce((acc, val) => acc + val[1], 0) /
        sortedMotionData.length
      ).toFixed(1)
    );
  }

  let minStressReading = "-";
  let maxStressReading = "-";
  let avgStressReading = "-";
  const stressData = [...device.graphArray.stressValue];
  if (stressData.length > 2) {
    const sortedStressData = stressData.sort((a, b) => a[1] - b[1]);
    sortedStressData.shift();
    minStressReading = Math.round(sortedStressData[0][1]);
    sortedStressData.reverse();
    maxStressReading = Math.round(sortedStressData[0][1]);
    avgStressReading = Math.round(
      (
        sortedStressData.reduce((acc, val) => acc + val[1], 0) /
        sortedStressData.length
      ).toFixed(1)
    );
  }

  const onChangeRealTimeType = (flag) => {
    let obj = { interval: '30 mins' };
    onIntervalChange("30 mins");
    if (flag) {
      let currentTime = moment.utc().tz(userTimeZone).format("MM/DD/YYYY h:mm a")
      let str = moment(currentTime).subtract(30, "minutes").format("MM/DD/YYYY h:mm a")
      setEndTimeCDT(currentTime)
      handleStartDateChange(str)
      fetchButtonTapped()
    }
    setUserInfo({ ...userInfo, ...obj });
    setRealTimeType(flag);
    timeIntervals = flag ? realTimeIntervals : nonRealTimeIntervals
  }

  useEffect(() => {
    const timeFormat = userInfo.interval.split(' ');
    if (drawer && timeFormat[1]) {
      let intervalFormat = timeFormat[1] === 'mins' ? 'minutes' : 'hours';
      const returned_endate = moment(selectedStartDate).add(parseInt(timeFormat[0]), intervalFormat).format("MM/DD/YYYY h:mm a");
      setEndTimeCDT(returned_endate);
    }
  }, [selectedStartDate, userInfo.interval]);

  /*let tempTimeout = '';
  const updateTimeEveryMin = () => {
    if (realTimeType) {
      tempTimeout = setTimeout(() => {
        handleStartDateChange(oldTime => {
          const updatedTimeStamp = +new Date(oldTime) + 60000; 
          const updatedStartTime = moment(updatedTimeStamp).format("MM/DD/YYYY h:mm a");
          return updatedStartTime
        });
      }, 60000);
    }
    return () => {
      clearTimeout(tempTimeout);
    };
  } 

  useEffect(() => {
    if(drawer) {
     // updateTimeEveryMin();
    }
  }, [selectedStartDate]); */

  return (
    <Drawer
      className={classes.drawerRoot}
      anchor="right"
      open={drawer}
      onClose={() => {
        setDrawer(false);
        setRealTimeType(true);
        setReadingParams(null);
        resetGraphData();
        setDevDataId();
        setOffset(0);
        setAbbr("GMT");
        setDayLightSaving(0);
        setFriendlyName();
        clearInterval(drawerRef.current);
        clearInterval(svittalDrawerRef.current);
      }}
    >
      <Box id={`capture`} display="flex" flexDirection="column">
        <Box pt={2} pl={2} pr={2} display="flex">
          <GridContainer>
            <GridItem xs={5}>
              <Box display="flex" flexDirection="row" width="100%">
                <Box display="flex" flexDirection="row">
                  <Typography noWrap={true} style={{ verticalAlign: "middle", margin: "auto" }}>
                    <u>{sensor.fullName}</u>
                    {friendlyName && <b> : {friendlyName}</b>}
                    {locationValue && <span className="font-it"> - {locationValue}</span>}
                  </Typography>
                </Box>
                {selectedReadingParams?.map((val) => {
                  if (
                    currentDevice?.linkedGraphData[userInfo.devID] &&
                    currentDevice?.linkedGraphData[userInfo.devID].data.length >
                    0
                  ) {
                    const firstEntry = currentDevice.linkedGraphData[userInfo.devID].data[0];
                    const heartLastTime = moment(firstEntry.data.Hr.valueProvidedOn);
                    const respiratoryLastTime = moment(firstEntry.data.Rr.valueProvidedOn);
                    const stressLastTime = moment(firstEntry.data.Stress.valueProvidedOn);
                    const motionLastTime = moment(firstEntry.data.Motion.valueProvidedOn);
                    const rssMvLastTime = moment(firstEntry.data.RssRrMv.valueProvidedOn);

                    if (currentUtcTime.diff(heartLastTime, "seconds") < 60) {
                      latestHrReading = Math.round(firstEntry.data.Hr.value);
                    }
                    if (currentUtcTime.diff(rssMvLastTime, "seconds") < 60) {
                      latestRssMvReading = Math.round(firstEntry.data.RssRrMv.value);
                    }
                    if (currentUtcTime.diff(respiratoryLastTime, "seconds") < 60) {
                      latestRrReading = Math.round(firstEntry.data.Rr.value);
                    }
                    if (currentUtcTime.diff(stressLastTime, "seconds") < 60) {
                      latestStressReading = firstEntry.data.Stress ? Math.round(firstEntry.data.Stress.value) : "-";
                    }
                    if (currentUtcTime.diff(motionLastTime, "seconds") < 60) {
                      latestMotionReading = firstEntry.data.Motion ? Math.round(firstEntry.data.Motion.value) : "-";
                    }
                  }
                  return <></>;
                })}
              </Box>
            </GridItem>
            <GridItem xs={2}>
              <Box justifyContent="center">
                <img
                  src={logo}
                  width="100px"
                  alt={"Cardio Logo"}
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              </Box>
            </GridItem>
            <GridItem xs={5}>
              <Box display="flex" flexDirection="row-reverse">
                <Box
                  pl={1}
                  pt={2}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setDrawer(false);
                    setReadingParams(null);
                    clearInterval(drawerRef.current);
                    clearInterval(svittalDrawerRef.current);
                    resetGraphData();
                    setUserTimeZone(UTC_TIME_ZONE);
                    let obj = { startTime: false };
                    setUserInfo({ ...userInfo, ...obj });
                    setRealTimeType(true);
                  }}
                >
                  <Tooltip title="Close">
                    <CloseIcon />
                  </Tooltip>
                </Box>
                {/* <Divider orientation="vertical" flexItem /> */}
                <Box pl={1} pr={1} pt={2}>
                  <PopupState variant="popover" popupId="demo-popup-popover">
                    {(popupState) => (
                      <div>
                        <Tooltip title="Filter">
                          <ViewHeadlineIcon
                            style={{ cursor: "pointer", color: "#b5322f" }}
                            {...bindTrigger(popupState)}
                          />
                        </Tooltip>
                        <Popover
                          {...bindPopover(popupState)}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          <Box p={2} width="230px">
                            {device.graphArray.hrValue.length > 2 && (
                              <Box textAlign="center">
                                <Box>
                                  <Button
                                    color="danger"
                                    onClick={() => {
                                      device.fwVersion = fwVersion;
                                      device.hwVersion = hwVersion;
                                      exportCSV(device);
                                      popupState.close();
                                    }}
                                  >
                                    Export to CSV
                                  </Button>
                                </Box>
                                {loggedUserRole !== "Patient" &&
                                  (userInfo.interval === "30 mins" ||
                                    userInfo.interval === "1 Hr" ||
                                    userInfo.interval === "2 Hr" ||
                                    userInfo.interval === "4 Hr" ||
                                    userInfo.interval === "6 Hr" ||
                                    userInfo.interval === "8 Hr" ||
                                    userInfo.interval === "10 Hr" ||
                                    userInfo.interval === "12 Hr") && (
                                    <Button
                                      color="danger"
                                      onClick={() => {
                                        device.fwVersion = fwVersion;
                                        device.hwVersion = hwVersion;
                                        exportCSV(device, "Second");
                                        popupState.close();
                                      }}
                                    >
                                      Export to CSV (SECS)
                                    </Button>
                                  )}
                                {loggedUserRole === "Patient" &&
                                  csv1sec === "Yes" && (
                                    <Button
                                      color="danger"
                                      onClick={() => {
                                        device.fwVersion = fwVersion;
                                        device.hwVersion = hwVersion;
                                        exportCSV(device, "Second");
                                        popupState.close();
                                      }}
                                    >
                                      Export to CSV (SECS)
                                    </Button>
                                  )}
                                <Box>
                                  <Button
                                    disabled={disablePdfBtn}
                                    color="danger"
                                    onClick={() => {
                                      setDisablePdfBtn(true);
                                      successNotificationRequest({
                                        message:
                                          "Export to PDF is processing, it will take few seconds.",
                                      });
                                      setTimeout(() => {
                                        exportPdf(
                                          "pdf",
                                          `${moment
                                            .utc(startTime)
                                            .subtract(dayLightSaving, "h")
                                            .utcOffset(offset)
                                            .format(
                                              "MMMM Do YYYY, h:mm:ss a"
                                            )}-${sensor.fullName
                                          }-${friendlyName}`
                                        );
                                      }, 10000);
                                      popupState.close();
                                    }}
                                  >
                                    Export to PDF
                                  </Button>
                                </Box>
                              </Box>
                            )}
                            {device.graphArray.hrValue.length < 3 && (
                              <Box>
                                <p
                                  style={{
                                    fontSize: "14px",
                                    padding: "0",
                                    margin: "0",
                                  }}
                                >
                                  No data to download
                                </p>
                              </Box>
                            )}
                          </Box>
                        </Popover>
                      </div>
                    )}
                  </PopupState>
                </Box>
                {/* <Divider orientation="vertical" flexItem /> */}
                <Box pr={1} pt={2}>
                  <Typography
                    style={{
                      verticalAlign: "middle",
                      margin: "auto",
                    }}
                  >
                    <b>
                      {sensor.roles &&
                        sensor.roles.length > 0 &&
                        sensor.roles[0]?.supplier?.name}
                    </b>
                  </Typography>
                </Box>
                <Box pr={2}>
                  <div className="rTimeBox">
                    <span 
                      onClick={() => {
                        onChangeRealTimeType(true);
                      }} 
                      className={realTimeType ? "rTime active" : "rTime"
                    }>Real Time</span>
                    <span 
                      onClick={() => { 
                        onChangeRealTimeType(false);
                      }} 
                      className={!realTimeType ? "rTime active" : "rTime"}
                    >Non Real Time</span>
                  </div>
                </Box>
              </Box>
            </GridItem>
          </GridContainer>
        </Box>
        <Box pt={0} pl={2} pr={2} display="flex">
          <GridContainer>
            <GridItem xs={3}>
              <p
                style={{
                  fontSize: "13px",
                  padding: "0",
                  margin: "0",
                }}
              >
                Firmware version: <b>{fwVersion}</b> {"  "} Hardware version:{" "}
                <b>{hwVersion}</b> Active: <b>{activeStatus}</b> {"  "} Sent:{" "}
                <b>{apiRequestCount}</b> {"  "} Received:{" "}
                <b>{apiResponseCount}</b>

              </p>
              {/* <p style={{fontSize: "13px", padding: "0", margin: "0",}}>
                Note: If the readings are zero, it's possible that the resident
                is not in the room or the sensors has connection issues.
              </p> */}
            </GridItem>
            <GridItem xs={4} className={classes.readingSection}>
              <GridContainer direction="row" justifyContent="center" alignItems="center">
                <GridItem className="flexCenter">
                  <div className={offlineStatus.toLowerCase() === "no" ? occupied ? "heartBeatAnimation" : '' : ""}>
                    {offlineStatus.toLowerCase() === "no" ? occupied ? <FavoriteSharpIcon style={{ color: '#ed193b' }} /> : <FavoriteBorderSharpIcon /> : <WifiOffIcon />}
                  </div>
                  <div className={offlineStatus.toLowerCase() === "no" ? "dot green" : "dot red"}></div>{" "}
                  <b>{offlineStatus.toLowerCase() === "no" ? 'Online' : 'Offline'}</b>
                </GridItem>
                <GridItem>
                  <Box>
                    <div className={occupied ? "dot green" : "dot red"}></div>{" "}
                    <b>{occupied ? 'Occupied' : 'Unoccupied'}</b>
                  </Box>
                </GridItem>
                {loggedUserRole !== "Patient" && (
                  <GridItem>
                    <Box>
                      <div><b>Apnea: {apneaperminute}</b></div>
                    </Box>
                  </GridItem>
                )}
              </GridContainer>
            </GridItem>
            <GridItem xs={5}>
              <Box display="flex" flexDirection="row-reverse">
                <Box pr={2}>
                  <Button
                    color="danger"
                    onClick={() => { fetchButtonTapped() }}
                  >
                    FETCH
                  </Button>
                </Box>
                <Box pr={2}>
                  <InputLabel
                    style={{ fontSize: "12px" }}
                    className={classes.datePickerLabel}
                  >
                    End Time ({abbr})
                  </InputLabel>
                  <FormControl fullWidth>
                    <Datetime
                      dateFormat="MM/DD/YYYY h:mm a"
                      isValidDate={disableFutureDt}
                      className={classes.datePicker}
                      value={endTimeCDT}
                      inputProps={{
                        disabled: true
                      }}
                    />
                  </FormControl>
                </Box>
                <Box pr={2}>
                  <InputLabel
                    style={{ fontSize: "12px" }}
                    className={classes.datePickerLabel}
                  >
                    Start Time ({abbr})
                  </InputLabel>
                  <FormControl fullWidth>
                    <Datetime
                      // dateFormat="MM/DD/YYYY h:mm a"
                      isValidDate={disableFutureDt}
                      className={classes.datePicker}
                      value={selectedStartDate}
                      inputProps={{ disabled: realTimeType }}
                      onChange={(val) => {
                        let obj = { intervalStartTime: val, startTime: true };
                        setUserInfo({ ...userInfo, ...obj });
                        handleStartDateChange(val);
                      }}
                    />
                  </FormControl>
                </Box>
                <Box pr={2}>
                  <TextField
                    className={classes.searchBox}
                    select
                    style={{ width: "100px" }}
                    label="Interval"
                    color="secondary"
                    value={userInfo.interval}
                    onChange={(val) => {
                      let obj = {
                        interval: val.target.value,
                      };
                      onIntervalChange(val.target.value);
                      setUserInfo({ ...userInfo, ...obj });
                    }}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        focused: classes.labelFocused,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.root,
                        focused: classes.inputFocused,
                      },
                    }}
                  >
                    {timeIntervals.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Box>
            </GridItem>
          </GridContainer>
        </Box>
        <Box>
          <Box p={2} bgcolor="#b5322f" pt={1}>
            <Typography
              style={{
                color: "#ffffff",
                paddingBottom: "8px",
                fontSize: "18px",
              }}
              align="center"
            >
              Heart Rate Chart
            </Typography>
            <GridContainer>
              <GridItem xs={realTimeType ? 8 : 12}>
                <Chart
                  height={300}
                  chartType="LineChart"
                  loader={
                    <div style={{ color: "#ffffff", textAlign: "center" }}>Loading Chart</div>
                  }
                  data={device.graphArray.hrValue}
                  options={{
                    title: startAndEndTime,
                    legend: "none",
                    width: "100%",
                    explorer: { axis: "horizontal" },
                    colors: ["#b5322f", "#b5322f"],
                  }}
                  legendToggle
                />
              </GridItem>
              {
                realTimeType &&
                <>
                  <GridItem
                    className={classes.noPadding}
                    xs={3}
                    container
                    justifyContent="space-between"
                  >
                    <GridItem
                      xs={12}
                      className={classes.paddingZeroMarginRight}
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      style={{
                        marginRight: "0",
                      }}
                    >
                      <Box
                        p={2}
                        bgcolor="#ffffff"
                        className={classes.paddingZeroMarginRight}
                      >
                        <GridItem xs={12} className={classes.noPadding}>
                          <Box
                            textAlign="center"
                            color="#b5322f"
                            pt={2}
                            className={classes.headingSize}
                          >
                            <b>Heart Rate (BPM)</b>
                          </Box>
                          <Box
                            textAlign="center"
                            color="#b5322f"
                            pt={1}
                            className={classes.readingSize}
                          >
                            <b>{latestHrReading}</b>
                          </Box>
                          <Box
                            textAlign="center"
                            bgcolor="#b5322fcc"
                            color="#ffffff"
                            p={2}
                            mt={1}
                          >
                            <GridItem
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              zeroMinWidth={true}
                            >
                              <GridItem xs={4} className={classes.subHeading}>
                                <b>
                                  Avg <br />
                                  <span className={classes.headingSize}>
                                    {avgHrReading}
                                  </span>
                                </b>
                              </GridItem>
                              <GridItem xs={4} className={classes.subHeading}>
                                <b>
                                  Min <br />
                                  <span className={classes.headingSize}>
                                    {minHrReading}
                                  </span>
                                </b>
                              </GridItem>
                              <GridItem xs={4} className={classes.subHeading}>
                                <b>
                                  Max <br />
                                  <span className={classes.headingSize}>
                                    {maxHrReading}
                                  </span>
                                </b>
                              </GridItem>
                            </GridItem>
                          </Box>
                          <Box textAlign="center" color="#83C9D3" p={1}>
                            <GridItem
                              container
                              direction="row"
                              alignItems="center"
                              className={classes.noPadding}
                              justifyContent="space-evenly"
                            >
                              <GridItem
                                xs={12}
                                className={classes.noPadding}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  verticalAlign: "middle",
                                  alignItems: "center",
                                }}
                              >
                                <b className={classes.subHeadingSize}>Stress/HRV</b>
                                <span className={classes.subHeadingTitleSize}>
                                  <b>
                                    &nbsp;&nbsp;&nbsp;&nbsp;{latestStressReading}
                                  </b>
                                </span>
                              </GridItem>
                            </GridItem>
                          </Box>
                        </GridItem>
                      </Box>
                    </GridItem>
                  </GridItem>
                  <GridItem
                    className={classes.noPadding}
                    xs={1}
                    container
                    justifyContent="space-between"
                  >
                    <GridItem
                      xs={12}
                      className={classes.paddingZeroMarginRight}
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="flex-start"
                    >
                      <Box textAlign="center">
                        <Typography
                          style={{
                            color: "white",
                            fontSize: "18px",
                            textAlign: "left"
                          }}
                          align="center"
                        >
                          {latestMotionReading}
                        </Typography>
                        <VerticalProgress progress={latestMotionReading * 10} />
                        <div
                          style={{
                            color: "#eaa745",
                            fontSize: "16px",
                          }}
                        >
                          <strong>Motion</strong>
                        </div>
                      </Box>
                    </GridItem>
                  </GridItem>
                </>
              }
            </GridContainer>
          </Box>
          <Box p={2} bgcolor="#1281FB" pt={1}>
            <Typography
              style={{
                color: "#ffffff",
                paddingBottom: "8px",
                fontSize: "18px",
              }}
              align="center"
            >
              Breath Chart
            </Typography>
            <GridContainer>
              <GridItem xs={realTimeType ? 8 : 12}>
                <Chart
                  height={300}
                  chartType="LineChart"
                  loader={
                    <div style={{ color: "#ffffff", textAlign: "center" }}>
                      Loading Chart
                    </div>
                  }
                  data={device.graphArray.rrValue}
                  options={{
                    title: startAndEndTime,
                    intervals: { style: "sticks" },
                    legend: "none",
                    width: "100%",
                    explorer: { axis: "horizontal" },
                    colors: ["#1281FB", "#1281FB"],
                  }}
                  legendToggle={false}
                />
              </GridItem>
              {
                realTimeType &&
                <>
                  <GridItem
                    className={classes.noPadding}
                    xs={3}
                    container
                    justifyContent="space-between"
                  >
                    <GridItem
                      xs={12}
                      className={classes.paddingZeroMarginRight}
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box
                        p={2}
                        bgcolor="#ffffff"
                        className={classes.paddingZeroMarginRight}
                      >
                        <GridItem xs={12} className={classes.noPadding}>
                          <Box
                            textAlign="center"
                            color="#1281FB"
                            pt={2}
                            className={classes.headingSize}
                          >
                            <b>Breath Rate (bpm)</b>
                          </Box>
                          <Box
                            textAlign="center"
                            color="#1281FB"
                            pt={1}
                            className={classes.readingSize}
                          >
                            <b>{latestRrReading}</b>
                          </Box>
                          <Box
                            textAlign="center"
                            bgcolor="#1281fbbf"
                            color="#ffffff"
                            p={2}
                            mt={1}
                          >
                            <GridItem
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              zeroMinWidth={true}
                            >
                              <GridItem xs={4} className={classes.subHeading}>
                                <b>
                                  Avg <br />
                                  <span className={classes.headingSize}>
                                    {avgRrReading}
                                  </span>
                                </b>
                              </GridItem>
                              <GridItem xs={4} className={classes.subHeading}>
                                <b>
                                  Min <br />
                                  <span className={classes.headingSize}>
                                    {minRrReading}
                                  </span>
                                </b>
                              </GridItem>
                              <GridItem xs={4} className={classes.subHeading}>
                                <b>
                                  Max <br />
                                  <span className={classes.headingSize}>
                                    {maxRrReading}
                                  </span>
                                </b>
                              </GridItem>
                            </GridItem>
                          </Box>
                          <Box textAlign="center" color="#EAA745" p={1}>
                            <GridItem
                              container
                              direction="row"
                              alignItems="center"
                              justifyContent="space-evenly"
                              className={classes.noPadding}
                            >
                              <GridItem
                                xs={12}
                                className={classes.noPadding}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  verticalAlign: "middle",
                                  alignItems: "center",
                                }}
                              >
                                <b className={classes.subHeadingSize}>Motion</b>
                                <span className={classes.subHeadingTitleSize}>
                                  <b>
                                    &nbsp;&nbsp;&nbsp;&nbsp;{latestMotionReading}
                                  </b>
                                </span>
                              </GridItem>
                            </GridItem>
                          </Box>
                        </GridItem>
                      </Box>
                    </GridItem>
                  </GridItem>
                  <GridItem
                    className={classes.noPadding}
                    xs={1}
                    container
                    justifyContent="space-between"
                  >
                    <GridItem
                      xs={12}
                      className={classes.paddingZeroMarginRight}
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="flex-start"
                    >
                      <Box textAlign="center">
                        <Typography
                          style={{
                            color: "white",
                            fontSize: "18px",
                            textAlign: "left"
                          }}
                          align="center"
                        >
                          {latestRssMvReading}
                        </Typography>
                        <VerticalProgress progress={latestRssMvReading < 101 ? latestRssMvReading : 100} min={0} max={100} />
                        <div
                          style={{
                            color: "#eaa745",
                            fontSize: "16px",
                          }}
                        >
                          <strong>Rss RR</strong>
                        </div>
                      </Box>
                    </GridItem>
                  </GridItem>
                </>
              }
            </GridContainer>
          </Box>
          <Box p={2} bgcolor="#EAA745" pt={1}>
            <Typography
              style={{
                color: "#ffffff",
                paddingBottom: "8px",
                fontSize: "18px",
              }}
              align="center"
            >
              Motion Chart
            </Typography>
            <GridContainer>
              <GridItem xs={realTimeType ? 8 : 12}>
                <Chart
                  height={300}
                  chartType="LineChart"
                  loader={
                    <div style={{ color: "#ffffff", textAlign: "center" }}>
                      Loading Chart
                    </div>
                  }
                  data={device.graphArray.motionValue}
                  options={{
                    title: startAndEndTime,
                    intervals: { style: "sticks" },
                    legend: "none",
                    width: "100%",
                    explorer: { axis: "horizontal" },
                    colors: ["#EAA745", "#EAA745"],
                  }}
                  legendToggle={false}
                />
              </GridItem>
              {
                realTimeType &&
                <>
                  <GridItem xs={3} className={classes.noPadding} container>
                    <GridItem
                      xs={12}
                      className={classes.paddingZeroMarginRight}
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box
                        p={2}
                        bgcolor="#ffffff"
                        className={classes.paddingZeroMarginRight}
                      >
                        <GridItem xs={12} className={classes.noPadding}>
                          <Box
                            textAlign="center"
                            color="#EAA745"
                            pt={2}
                            className={classes.headingSize}
                          >
                            <b>Motion</b>
                          </Box>
                          <Box
                            textAlign="center"
                            color="#EAA745"
                            pt={1}
                            className={classes.readingSize}
                          >
                            <b>{latestMotionReading}</b>
                          </Box>
                          <Box
                            textAlign="center"
                            bgcolor="#EAA745c9"
                            color="#ffffff"
                            p={2}
                            mt={2}
                          >
                            <GridItem
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              zeroMinWidth={true}
                            >
                              <GridItem xs={4} className={classes.subHeading}>
                                <b>
                                  Avg <br />
                                  <span className={classes.headingSize}>
                                    {avgMotionReading}
                                  </span>
                                </b>
                              </GridItem>
                              <GridItem xs={4} className={classes.subHeading}>
                                <b>
                                  Min <br />
                                  <span className={classes.headingSize}>
                                    {minMotionReading}
                                  </span>
                                </b>
                              </GridItem>
                              <GridItem xs={4} className={classes.subHeading}>
                                <b>
                                  Max <br />
                                  <span className={classes.headingSize}>
                                    {maxMotionReading}
                                  </span>
                                </b>
                              </GridItem>
                            </GridItem>
                          </Box>
                        </GridItem>
                      </Box>
                    </GridItem>
                  </GridItem>
                </>
              }
            </GridContainer>
          </Box>
          <Box p={2} bgcolor="#83C9D3" pt={1}>
            <Typography
              style={{
                color: "#ffffff",
                paddingBottom: "8px",
                fontSize: "18px",
              }}
              align="center"
            >
              Stress Chart
            </Typography>
            <GridContainer>
              <GridItem xs={realTimeType ? 8 : 12}>
                <Chart
                  height={300}
                  chartType="LineChart"
                  loader={
                    <div style={{ color: "#ffffff", textAlign: "center" }}>
                      Loading Chart
                    </div>
                  }
                  data={device.graphArray.stressValue}
                  options={{
                    title: startAndEndTime,
                    intervals: { style: "sticks" },
                    legend: "none",
                    width: "100%",
                    explorer: { axis: "horizontal" },
                    colors: ["#83C9D3", "#83C9D3"],
                  }}
                  legendToggle={false}
                />
              </GridItem>
              {
                realTimeType &&
                <>
                  <GridItem
                    className={classes.noPadding}
                    xs={3}
                    container
                    justifyContent="space-between"
                  >
                    <GridItem
                      xs={12}
                      className={classes.paddingZeroMarginRight}
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box
                        p={2}
                        bgcolor="#ffffff"
                        className={classes.paddingZeroMarginRight}
                      >
                        <GridItem xs={12} className={classes.noPadding}>
                          <Box
                            textAlign="center"
                            color="#83C9D3"
                            pt={2}
                            className={classes.headingSize}
                          >
                            <b>Stress/HRV</b>
                          </Box>
                          <Box
                            textAlign="center"
                            color="#83C9D3"
                            pt={1}
                            className={classes.readingSize}
                          >
                            <b>{latestStressReading}</b>
                          </Box>
                          <Box
                            textAlign="center"
                            bgcolor="#83c9d3c9"
                            color="#ffffff"
                            p={2}
                            mt={2}
                          >
                            <GridItem
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              zeroMinWidth={true}
                            >
                              <GridItem xs={4} className={classes.subHeading}>
                                <b>
                                  Avg <br />
                                  <span className={classes.headingSize}>
                                    {avgStressReading}
                                  </span>
                                </b>
                              </GridItem>
                              <GridItem xs={4} className={classes.subHeading}>
                                <b>
                                  Min <br />
                                  <span className={classes.headingSize}>
                                    {minStressReading}
                                  </span>
                                </b>
                              </GridItem>
                              <GridItem xs={4} className={classes.subHeading}>
                                <b>
                                  Max <br />
                                  <span className={classes.headingSize}>
                                    {maxStressReading}
                                  </span>
                                </b>
                              </GridItem>
                            </GridItem>
                          </Box>
                        </GridItem>
                      </Box>
                    </GridItem>
                  </GridItem>
                </>
              }
            </GridContainer>
          </Box>

        </Box>
      </Box>
    </Drawer>
  );
}
