import * as service from "../common/service";
import { API_BASE_URL } from "../common/constants";

const userApi = `${API_BASE_URL}/observer/observers`;
const invitesListApi = `${API_BASE_URL}/observer/observees`;
const actionInviteApi = `${API_BASE_URL}/observer`;

const observeService = {
  getObserveList: (payload) => {
    return service.get(`${userApi}${observeService.filterConfig(payload)}`, {});
  },
  getInvitesList: (payload) => {
    return service.get(`${invitesListApi}${observeService.filterConfig(payload)}`, {});
  },
  actionInvite: (payload) => {
    return service.putJson(`${actionInviteApi}`, payload);
  },
  filterConfig: (payload) => {
    let filter = "";
    if (payload && Object.keys(payload).length > 0) {
      filter = Object.keys(payload).reduce(
        (acc, val, index) =>
          Object.keys(payload).length - 1 === index
            ? `${acc}${val}=${payload[val]}`
            : `${acc}${val}=${payload[val]}&`,
        "?"
      );
    }
    return filter;
  }
};

export default observeService;
