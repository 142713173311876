import { createBrowserHistory } from "history";
import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";

const useStyles = makeStyles({
  searchBox: {
    borderRadius: "5px",
    "& .MuiInputBase-fullWidth": {
      padding: "3px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "red !important",
    },
  },
  drawerRoot: {
    "& .MuiPaper-root": {
      width: "100%",
    },
  },
  labelFocused: {
    color: "#E73F3B !important",
  },
  inputFocused: {
    "&:before": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "&:after": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "& input": {
      borderBottom: "#E73F3B !important",
    },
  },
  inputRoot: {
    color: "red",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
  popover: {
    zIndex: 2,
  },
  fab: {
    margin: "0px",
    top: "auto",
    right: "20px",
    bottom: "20px",
    left: "auto",
    position: "fixed",
    zIndex: 3,
  },
});

export default function Filter({
  fetchFriendlyNameData,
  getDeviceData,
  guestSensors,
  getUserList,
  setUserInfo,
  userInfo,
  userList,
  company,
  device,
}) {
  const classes = useStyles();

  const userDetails = JSON.parse(localStorage.getItem("user-details"));
  const loggedUserRole = userDetails !== null ? userDetails.roles[0].role : "";
  let userData = [...userList.users];

  const history = createBrowserHistory(),
  currPath = history.location.pathname;

  if (!userInfo.supplierId && company.companyList.length > 0) {
    let obj = {
      ...userInfo,
      supplierId:
        company.companyList.length > 0 ? company.companyList[0].supplierId : "",
    };
    setUserInfo({ ...userInfo, ...obj });
  }

  if (!userInfo.deviceId && device.deviceList.length > 0) {
    let obj = {
      ...userInfo,
      deviceId:
        device.deviceList.length > 0 ? device.deviceList[0].deviceId : "",
    };
    setUserInfo({ ...userInfo, ...obj });
  }

  if (company.companyList.length > 0 && userData.length > 0) {
    userData = userData.filter((val) =>
      val.roles.length > 0
        ? val.roles[0].supplier?.supplierId === userInfo.supplierId
        : ""
    );
  }

  if (!userInfo.userId) {
    // userData.splice(0, 0, { userId: "all", emailAddress: "all", fullName: "Select All" });
    let obj = {
      ...userInfo,
      userId: "all",
    };
    setUserInfo({ ...userInfo, ...obj });
  }

  return (
    <GridItem xs={12}>
      <Box
        bgcolor="#ffffff"
        display="flex"
        padding={2}
        borderRadius="5px"
        marginBottom={2}
      >
        <GridContainer>
          {loggedUserRole === "TenantAdmin" && (
            <>
              <GridItem xs={3}>
                <TextField
                  className={classes.searchBox}
                  style={{
                    width: "100%",
                  }}
                  select
                  color="secondary"
                  label="Device"
                  onChange={(val) => {
                    let obj = {
                      deviceId: val.target.value,
                    };
                    setUserInfo({ ...userInfo, ...obj });
                  }}
                  InputLabelProps={{
                    shrink: true,
                    classes: {
                      focused: classes.labelFocused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.root,
                      focused: classes.inputFocused,
                    },
                  }}
                  value={
                    !userInfo?.deviceId
                      ? device.deviceList.length > 0
                        ? device.deviceList.reduce((acc, val) => {
                            const currentValue = val.name.split("-")[0];
                            const linkedData = acc.find(
                              (v) => v.name.split("-")[0] === currentValue
                            );
                            if (
                              !linkedData ||
                              val.name.split("-")[1].toLowerCase() === "pvittal"
                            ) {
                              return [...acc, val];
                            }
                            return acc;
                          }, [])[0].deviceId
                        : ""
                      : userInfo?.deviceId
                  }
                >
                  {device.deviceList
                    .reduce((acc, val) => {
                      const currentValue = val.name.split("-")[0];
                      const linkedData = acc.find(
                        (v) => v.name.split("-")[0] === currentValue
                      );
                      if (
                        !linkedData ||
                        val.name.split("-")[1].toLowerCase() === "pvittal"
                      ) {
                        return [...acc, val];
                      }
                      return acc;
                    }, [])
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((option) => (
                      <MenuItem key={option.deviceId} value={option.deviceId}>
                        {option.name.split("-")[0]}
                      </MenuItem>
                    ))}
                </TextField>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <TextField
                  className={classes.searchBox}
                  style={{
                    width: "100%",
                  }}
                  select
                  color="secondary"
                  label="Company"
                  onChange={(val) => {
                    let obj = {
                      supplierId: val.target.value,
                    };
                    setUserInfo({ ...userInfo, ...obj });
                    getUserList({
                      role: "Patient",
                      supplierId: val.target.value,
                    });
                  }}
                  InputLabelProps={{
                    shrink: true,
                    classes: {
                      focused: classes.labelFocused,
                    },
                  }}
                  value={
                    !userInfo?.supplierId
                      ? company.companyList.length > 0
                        ? company.companyList[0].supplierId
                        : ""
                      : userInfo?.supplierId
                  }
                  InputProps={{
                    classes: {
                      root: classes.root,
                      focused: classes.inputFocused,
                    },
                  }}
                >
                  {company.companyList
                    .map((option) => (
                      <MenuItem
                        key={option.supplierId}
                        value={option.supplierId}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                </TextField>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <TextField
                  className={classes.searchBox}
                  style={{
                    width: "100%",
                  }}
                  select
                  color="secondary"
                  label="User"
                  onChange={(val) => {
                    let obj = {userId: val.target.value, macAddress: "all"};
                    if (val.target.value !== "all") {
                      fetchFriendlyNameData({
                        userId: val.target.value,
                        deviceDataModelId: userInfo.deviceId,
                      });
                    }
                    setUserInfo({ ...userInfo, ...obj });
                  }}
                  InputLabelProps={{
                    shrink: true,
                    classes: {
                      focused: classes.labelFocused,
                    },
                  }}
                  value={
                    !userInfo?.userId
                      ? userData.length > 0
                        ? userData[0].emailAddress
                        : ""
                      : userInfo?.userId
                  }
                  InputProps={{
                    classes: {
                      root: classes.root,
                      focused: classes.inputFocused,
                    },
                  }}
                >
                  {[
                    {
                      userId: "all",
                      emailAddress: "all",
                      fullName: "Select All",
                    },
                    ...userData.sort((a, b) =>
                      a.firstName > b.firstName ? 1 : -1
                    ),
                  ].map((option) => (
                    <MenuItem key={option.userId} value={option.userId}>
                      {option.fullName}
                    </MenuItem>
                  ))}
                </TextField>
              </GridItem>
            </>
          )}
          {userInfo?.userId && userInfo?.userId !== "all" && device.friendlyNameData.length > 1 && !currPath.includes('/admin/guest-map') && (
            <GridItem xs={12} sm={12} md={3}>
              <TextField
                className={classes.searchBox}
                style={{width: "100%",}}
                select
                color="secondary"
                label="Friendly Name"
                onChange={(val) => {
                  let obj = {macAddress: val.target.value,};
                  setUserInfo({ ...userInfo, ...obj });
                }}
                InputLabelProps={{shrink: true, classes: {focused: classes.labelFocused}}}
                value={userInfo?.macAddress}
                InputProps={{ classes: { root: classes.root, focused: classes.inputFocused}}}
              >
                {device.friendlyNameData.map(
                  (option) =>
                    option.friendlyName && (
                      <MenuItem key={option.macId} value={option.macId}>
                        {option.friendlyName}
                      </MenuItem>
                    )
                )}
              </TextField>
            </GridItem>
          )}

          {loggedUserRole === "Guest" && guestSensors.length > 1 && (
            <GridItem xs={12} sm={12} md={3}>
              <TextField
                className={classes.searchBox}
                style={{width: "100%",}}
                select
                color="secondary"
                label="Friendly Name"
                onChange={(val) => {
                  let obj = {macAddress: val.target.value};
                  setUserInfo({ ...userInfo, ...obj });
                }}
                InputLabelProps={{ shrink: true, classes: {focused: classes.labelFocused}}}
                value={userInfo?.macAddress}
                InputProps={{classes: {root: classes.root, focused: classes.inputFocused}}}
              >
                {guestSensors.map((option) => option.friendlyName && (
                  <MenuItem key={option.macId} value={option.macId}>
                    {option.friendlyName}
                  </MenuItem>
                ))}
              </TextField>
            </GridItem>
          )}
          <GridItem xs={3}>
            <Button onClick={() => {getDeviceData();}} color="danger">
              Fetch Data
            </Button>
          </GridItem>
        </GridContainer>
      </Box>
    </GridItem>
  );
}
