import { ofType } from "redux-observable";
import { mergeMap, map, catchError } from "rxjs/operators";
import { forkJoin, of } from "rxjs";
import moment from "moment";
import momentTz from "moment-timezone";
import { dispatch } from "../../common/helpers";
import { UTC_TIME_ZONE, DEFAULT_TIME } from "../../common/constants";
import {
  deviceService,
  patientDeviceService,
  usersService,
} from "../../services";

import {
  FETCH_DEVICE_REQUEST,
  FETCH_DEVICE_SUCCESS,
  FETCH_DEVICE_ERROR,
  FETCH_DEVICE_PROPERTY_REQUEST,
  FETCH_DEVICE_PROPERTY_SUCCESS,
  FETCH_DEVICE_PROPERTY_ERROR,
  GET_DEVICE_DETAIL_REQUEST,
  GET_DEVICE_DETAIL_SUCCESS,
  GET_DEVICE_DETAIL_ERROR,
  GET_DEVICE_DETAIL_PROPERTY_ERROR,
  FETCH_DEVICE_PROPERTY_SET_REQUEST,
  FETCH_DEVICE_PROPERTY_SET_SUCCESS,
  FETCH_DEVICE_PROPERTY_SET_ERROR,
  ADD_DEVICE_REQUEST,
  ADD_DEVICE_SUCCESS,
  ADD_DEVICE_ERROR,
  DELETE_DEVICE_REQUEST,
  DELETE_DEVICE_ERROR,
  UPDATE_DEVICE_REQUEST,
  UPDATE_DEVICE_SUCCESS,
  UPDATE_DEVICE_ERROR,
  CREATE_PROPERTY_SET_REQUEST,
  CREATE_PROPERTY_SET_SUCCESS,
  CREATE_PROPERTY_SET_ERROR,
  DELETE_PROPERTY_SET_REQUEST,
  DELETE_PROPERTY_SET_ERROR,
  RESET_REQUEST,
  RESET_SUCCESS,
  FETCH_PROPERTY_SET_DETAIL_REQUEST,
  FETCH_PROPERTY_SET_DETAIL_SUCCESS,
  FETCH_PROPERTY_SET_DETAIL_ERROR,
  UPDATE_PROPERTY_SET_REQUEST,
  UPDATE_PROPERTY_SET_SUCCESS,
  UPDATE_PROPERTY_SET_ERROR,
  GET_DEVICE_PROPERTY_REQUEST,
  GET_DEVICE_PROPERTY_SUCCESS,
  GET_DEVICE_PROPERTY_ERROR,
  UPDATE_DEVICE_PROPERTY_REQUEST,
  UPDATE_DEVICE_PROPERTY_SUCCESS,
  UPDATE_DEVICE_PROPERTY_ERROR,
  CREATE_DEVICE_PROPERTY_REQUEST,
  CREATE_DEVICE_PROPERTY_SUCCESS,
  CREATE_DEVICE_PROPERTY_ERROR,
  DELETE_PROPERTY_REQUEST,
  DELETE_PROPERTY_ERROR,
  FETCH_DEVICE_FIRMWARE_REQUEST,
  FETCH_DEVICE_FIRMWARE_SUCCESS,
  FETCH_DEVICE_FIRMWARE_ERROR,
  FETCH_FIRMWARE_DETAIL_REQUEST,
  FETCH_FIRMWARE_DETAIL_SUCCESS,
  FETCH_FIRMWARE_DETAIL_ERROR,
  UPDATE_FIRMWARE_DETAIL_REQUEST,
  UPDATE_FIRMWARE_DETAIL_SUCCESS,
  UPDATE_FIRMWARE_DETAIL_ERROR,
  CREATE_FIRMWARE_DETAIL_REQUEST,
  CREATE_FIRMWARE_DETAIL_SUCCESS,
  CREATE_FIRMWARE_DETAIL_ERROR,
  DELETE_DEVICE_FIRMWARE_REQUEST,
  DELETE_DEVICE_FIRMWARE_ERROR,
  FETCH_DEVICE_DATA_REQUEST,
  FETCH_DEVICE_DATA_SUCCESS,
  FETCH_DEVICE_DATA_ERROR,
  DUPLICATE_DEVICE_REQUEST,
  DUPLICATE_DEVICE_SUCCESS,
  DUPLICATE_DEVICE_ERROR,
  FETCH_NEW_DATA_REQUEST,
  FETCH_NEW_DATA_SUCCESS,
  FETCH_NEW_DATA_ERROR,
  FETCH_NEW_DEV_DATA_REQUEST,
  FETCH_NEW_DEV_DATA_SUCCESS,
  FETCH_NEW_DEV_DATA_ERROR,
  FETCH_UPDATE_USER_DATA_REQUEST,
  FETCH_NEW_USER_DATA_REQUEST,
  FETCH_NEW_USER_DATA_SUCCESS,
  FETCH_NEW_USER_DATA_ERROR,
  RESET_GRAPH_REQUEST,
  RESET_GRAPH_SUCCESS,
  FETCH_DEVICE_SPECIFIED_REQUEST,
  FETCH_DEVICE_SPECIFIED_SUCCESS,
  FETCH_DEVICE_SPECIFIED_ERROR,
  GET_PROPERTY_DATA_REQUEST,
  GET_PROPERTY_DATA_SUCCESS,
  CREATE_DEVICE_DATA_REQUEST,
  CREATE_DEVICE_DATA_SUCCESS,
  CREATE_DEVICE_DATA_ERROR,
  UPDATE_DEVICE_DATA_REQUEST,
  UPDATE_DEVICE_DATA_ERROR,
  UPDATE_DEFAULT_DATA_REQUEST,
  UPDATE_DEFAULT_DATA_ERROR,
  UPDATE_DEFAULT_THRESHOLD_REQUEST,
  UPDATE_DEFAULT_THRESHOLD_ERROR,
  DELETE_DEVICE_DATA_REQUEST,
  DELETE_DEVICE_DATA_ERROR,
  SYNC_FRIENDLY_NAME_REQUEST,
  SYNC_FRIENDLY_NAME_ERROR,
  FETCH_HW_SW_VERSION_REQUEST,
  FETCH_HW_SW_VERSION_SUCCESS,
  FETCH_HW_SW_VERSION_ERROR,
  START_GRAPH_LOADER,
  START_GRAPH_SUCCESS,
  FETCH_CSV_DATA_REQUEST,
  FETCH_CSV_DATA_SUCCESS,
  FETCH_CSV_DATA_ERROR,
  FETCH_FRIENDLY_NAME_REQUEST,
  FETCH_FRIENDLY_NAME_SUCCESS,
  FETCH_FRIENDLY_NAME_ERROR,
  FETCH_NEW_USER_MIN_DATA_REQUEST,
  FETCH_NEW_USER_MIN_DATA_SUCCESS,
  FETCH_NEW_USER_MIN_DATA_ERROR,
  FETCH_NEW_USER_MIN_DATA_UPDATE_REQUEST,
  FETCH_ERROR_LOG_DATA_REQUEST,
  FETCH_ERROR_LOG_DATA_SUCCESS,
  FETCH_ERROR_LOG_DATA_ERROR,
  DELETE_ERROR_LOG_DATA_REQUEST,
  DELETE_ERROR_LOG_DATA_ERROR,
  DOWNLOAD_DEVICE_FIRMWARE_REQUEST,
  DOWNLOAD_DEVICE_FIRMWARE_SUCCESS,
  DOWNLOAD_DEVICE_FIRMWARE_ERROR,
  FETCH_ALERT_LOG_DATA_REQUEST,
  FETCH_ALERT_LOG_DATA_SUCCESS,
  FETCH_ALERT_LOG_DATA_ERROR,
  FETCH_READ_ALERT_LOG_DATA_REQUEST,
  FETCH_READ_ALERT_LOG_DATA_SUCCESS,
  FETCH_READ_ALERT_LOG_DATA_ERROR,
  DELETE_ALERT_LOG_DATA_REQUEST,
  DELETE_ALERT_LOG_DATA_ERROR,
  FETCH_SVITTAL_DATA_REQUEST,
  FETCH_SVITTAL_DATA_SUCCESS,
  FETCH_SVITTAL_DATA_ERROR,
  FETCH_MIN_CSV_DATA_REQUEST,
  FETCH_MIN_CSV_DATA_SUCCESS,
  FETCH_MIN_CSV_DATA_ERROR,
  FETCH_NEW_SV_DATA_REQUEST,
  FETCH_NEW_SV_DATA_SUCCESS,
  FETCH_NEW_SV_DATA_ERROR,
  FETCH_SENSOR_CONTROL_REQUEST,
  FETCH_SENSOR_CONTROL_ERROR,
  FETCH_SENSOR_CONTROL_SUCCESS,
  UPDATE_SENSOR_CONTROL_REQUEST,
  UPDATE_SENSOR_CONTROL_ERROR,
  UPDATE_SENSOR_CONTROL_SUCCESS,
  UPDATE_SENSOR_CONTROL_TABLE_REQUEST,
  UPDATE_SENSOR_CONTROL_TABLE_SUCCESS,
  UPDATE_SENSOR_CONTROL_TABLE_ERROR,
  FETCH_PARAMS_DATA_REQUEST,
  FETCH_PARAMS_DATA_SUCCESS,
  FETCH_PARAMS_DATA_ERROR,
  FETCH_DIAGNOSTIC_PANEL_DATA_REQUEST,
  FETCH_DIAGNOSTIC_PANEL_DATA_SUCCESS,
  FETCH_DIAGNOSTIC_PANEL_DATA_ERROR,
  SAVE_DIAGNOSTIC_PANEL_DATA_REQUEST,
  SAVE_DIAGNOSTIC_PANEL_DATA_SUCCESS,
  SAVE_DIAGNOSTIC_PANEL_DATA_ERROR,
  FETCH_SAVED_DIAGNOSTIC_PANEL_DATA_REQUEST,
  FETCH_SAVED_DIAGNOSTIC_PANEL_DATA_SUCCESS,
  FETCH_SAVED_DIAGNOSTIC_PANEL_DATA_ERROR,
  FETCH_DIAGNOSTIC_PANEL_REQUEST,
  FETCH_DIAGNOSTIC_PANEL_SUCCESS,
  FETCH_DIAGNOSTIC_PANEL_ERROR,
  FETCH_USER_ORDER_DATA_REQUEST,
  FETCH_USER_ORDER_DATA_REQUEST_SUCCESS,
  FETCH_USER_ORDER_DATA_REQUEST_ERROR,
  FETCH_DEVICE_DETAILS_REQUEST,
  FETCH_DEVICE_DETAILS_ERROR,
  FETCH_DEVICE_DETAILS_SUCCESS,
  SAVE_USER_ORDER_REQUEST,
  SAVE_USER_ORDER_ERROR,
  SAVE_USER_ORDER_SUCCESS,
} from "./actions";

import {
  SUCCESS_NOTIFICATION_REQUEST,
  ERROR_NOTIFICATION_REQUEST,
} from "../notifications/actions";

export const startGraphLoader = (action$) =>
  action$.pipe(
    ofType(START_GRAPH_LOADER),
    map(() => dispatch(START_GRAPH_SUCCESS))
  );

export const getDeviceList = (action$) => {
  return action$.pipe(
    ofType(FETCH_DEVICE_REQUEST),
    mergeMap(({ payload }) => {
      return deviceService.getDeviceList(payload).pipe(
        map((response) => {
          return dispatch(FETCH_DEVICE_SUCCESS, JSON.parse(response));
        }),
        catchError((error) => {
          return dispatch(FETCH_DEVICE_ERROR, error);
        })
      );
    })
  );
};

export const getDevicePropertyList = (action$) => {
  return action$.pipe(
    ofType(FETCH_DEVICE_PROPERTY_REQUEST),
    mergeMap(({ payload }) => {
      return deviceService.getDevicePropertyList(payload).pipe(
        map((response) => {
          return dispatch(FETCH_DEVICE_PROPERTY_SUCCESS, JSON.parse(response));
        }),
        catchError((error) => {
          return [
            dispatch(FETCH_DEVICE_PROPERTY_ERROR, error),
            dispatch(ERROR_NOTIFICATION_REQUEST, {
              response: {
                message: `Please select another device, property are not linked with this device.`,
              },
            }),
          ];
        })
      );
    })
  );
};

export const getDevicePropertySetList = (action$) => {
  return action$.pipe(
    ofType(FETCH_DEVICE_PROPERTY_SET_REQUEST),
    mergeMap(({ payload }) => {
      return deviceService.getDevicePropertySetList(payload).pipe(
        map((response) => {
          return dispatch(
            FETCH_DEVICE_PROPERTY_SET_SUCCESS,
            JSON.parse(response)
          );
        }),
        catchError((error) => {
          return [dispatch(FETCH_DEVICE_PROPERTY_SET_ERROR, error)];
        })
      );
    })
  );
};

export const getDeviceDetailById = (action$) => {
  return action$.pipe(
    ofType(GET_DEVICE_DETAIL_REQUEST),
    mergeMap(({ payload }) => {
      return deviceService.getDeviceDetailById(payload).pipe(
        mergeMap((deviceResponse) => {
          return deviceService.getDevicePropertyDetails(payload).pipe(
            mergeMap((response) => {
              let obj = {
                device: JSON.parse(deviceResponse),
                property: JSON.parse(response),
              };
              return [dispatch(GET_DEVICE_DETAIL_SUCCESS, obj)];
            }),
            catchError(() => {
              let obj = {
                device: JSON.parse(deviceResponse),
              };
              return [dispatch(GET_DEVICE_DETAIL_PROPERTY_ERROR, obj)];
            })
          );
        }),
        catchError((error) => {
          return [dispatch(GET_DEVICE_DETAIL_ERROR, error)];
        })
      );
    })
  );
};

export const addDevice = (action$) => {
  return action$.pipe(
    ofType(ADD_DEVICE_REQUEST),
    mergeMap(({ payload }) => {
      return deviceService.addDevice(payload).pipe(
        mergeMap((response) => {
          return [
            dispatch(ADD_DEVICE_SUCCESS, JSON.parse(response.response)),
            dispatch(SUCCESS_NOTIFICATION_REQUEST, {
              message: "Device added successfully",
            }),
          ];
        }),
        catchError((error) => {
          return [
            dispatch(ADD_DEVICE_ERROR, error),
            dispatch(ERROR_NOTIFICATION_REQUEST, {
              response: {
                message: error.response,
              },
            }),
          ];
        })
      );
    })
  );
};

export const deleteDevice = (action$) => {
  return action$.pipe(
    ofType(DELETE_DEVICE_REQUEST),
    mergeMap(({ payload }) =>
      deviceService.deleteDevice(payload).pipe(
        map(() => {
          delete payload.deviceId;
          return dispatch(FETCH_DEVICE_REQUEST, payload);
        }),
        catchError((error) => [
          dispatch(DELETE_DEVICE_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, {
            response: {
              message: error.response,
            },
          }),
        ])
      )
    )
  );
};

export const updateDevice = (action$) => {
  return action$.pipe(
    ofType(UPDATE_DEVICE_REQUEST),
    mergeMap(({ payload }) => {
      return deviceService.updateDevice(payload).pipe(
        mergeMap(() => {
          return [dispatch(UPDATE_DEVICE_SUCCESS)];
        }),
        catchError((error) => {
          return [
            dispatch(UPDATE_DEVICE_ERROR, error),
            dispatch(ERROR_NOTIFICATION_REQUEST, {
              response: {
                message: error.response,
              },
            }),
          ];
        })
      );
    })
  );
};

export const createPropertySet = (action$) => {
  return action$.pipe(
    ofType(CREATE_PROPERTY_SET_REQUEST),
    mergeMap(({ payload }) => {
      return deviceService.addPropertySet(payload).pipe(
        mergeMap(() => {
          return [dispatch(CREATE_PROPERTY_SET_SUCCESS)];
        }),
        catchError((error) => {
          return [
            dispatch(CREATE_PROPERTY_SET_ERROR, error),
            dispatch(ERROR_NOTIFICATION_REQUEST, {
              response: {
                message: error.response,
              },
            }),
          ];
        })
      );
    })
  );
};

export const deletePropertySet = (action$) => {
  return action$.pipe(
    ofType(DELETE_PROPERTY_SET_REQUEST),
    mergeMap(({ payload }) =>
      deviceService.deletePropertySet(payload).pipe(
        map(() => {
          delete payload.devicePropertySetId;
          return dispatch(GET_DEVICE_DETAIL_REQUEST, payload.deviceId);
        }),
        catchError((error) => [
          dispatch(DELETE_PROPERTY_SET_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, {
            response: {
              message: error.response,
            },
          }),
        ])
      )
    )
  );
};

export const resetDeviceData = (action$) =>
  action$.pipe(
    ofType(RESET_REQUEST),
    mergeMap(() => {
      return [dispatch(RESET_SUCCESS)];
    })
  );

export const getPropertySetDetailsById = (action$) => {
  return action$.pipe(
    ofType(FETCH_PROPERTY_SET_DETAIL_REQUEST),
    mergeMap(({ payload }) =>
      deviceService.getPropertySetDetailsById(payload).pipe(
        mergeMap((deviceResponse) => {
          return [
            dispatch(
              FETCH_PROPERTY_SET_DETAIL_SUCCESS,
              JSON.parse(deviceResponse)
            ),
          ];
        }),
        catchError((error) => {
          return [
            dispatch(FETCH_PROPERTY_SET_DETAIL_ERROR, error),
            dispatch(ERROR_NOTIFICATION_REQUEST, {
              response: {
                message: error.response,
              },
            }),
          ];
        })
      )
    )
  );
};

export const updatePropertySet = (action$) => {
  return action$.pipe(
    ofType(UPDATE_PROPERTY_SET_REQUEST),
    mergeMap(({ payload }) => {
      return deviceService.updatePropertySet(payload).pipe(
        mergeMap(() => {
          return [dispatch(UPDATE_PROPERTY_SET_SUCCESS)];
        }),
        catchError((error) => {
          return [
            dispatch(UPDATE_PROPERTY_SET_ERROR, error),
            dispatch(ERROR_NOTIFICATION_REQUEST, {
              response: {
                message: error.response,
              },
            }),
          ];
        })
      );
    })
  );
};

export const getPropertyDetailsById = (action$) => {
  return action$.pipe(
    ofType(GET_DEVICE_PROPERTY_REQUEST),
    mergeMap(({ payload }) =>
      deviceService.getPropertyDetailsById(payload).pipe(
        mergeMap((deviceResponse) => {
          return [
            dispatch(GET_DEVICE_PROPERTY_SUCCESS, JSON.parse(deviceResponse)),
          ];
        }),
        catchError((error) => {
          return [
            dispatch(GET_DEVICE_PROPERTY_ERROR, error),
            dispatch(ERROR_NOTIFICATION_REQUEST, {
              response: {
                message: error.response,
              },
            }),
          ];
        })
      )
    )
  );
};

export const updateProperty = (action$) => {
  return action$.pipe(
    ofType(UPDATE_DEVICE_PROPERTY_REQUEST),
    mergeMap(({ payload }) => {
      return deviceService.updateProperty(payload).pipe(
        mergeMap(() => {
          return [dispatch(UPDATE_DEVICE_PROPERTY_SUCCESS)];
        }),
        catchError((error) => {
          return [
            dispatch(UPDATE_DEVICE_PROPERTY_ERROR, error),
            dispatch(ERROR_NOTIFICATION_REQUEST, {
              response: {
                message: error.response,
              },
            }),
          ];
        })
      );
    })
  );
};

export const createProperty = (action$) => {
  return action$.pipe(
    ofType(CREATE_DEVICE_PROPERTY_REQUEST),
    mergeMap(({ payload }) => {
      return deviceService.createProperty(payload).pipe(
        mergeMap(() => {
          return [dispatch(CREATE_DEVICE_PROPERTY_SUCCESS)];
        }),
        catchError((error) => {
          return [
            dispatch(CREATE_DEVICE_PROPERTY_ERROR, error),
            dispatch(ERROR_NOTIFICATION_REQUEST, {
              response: {
                message: error.response,
              },
            }),
          ];
        })
      );
    })
  );
};

export const deleteProperty = (action$) => {
  return action$.pipe(
    ofType(DELETE_PROPERTY_REQUEST),
    mergeMap(({ payload }) =>
      deviceService.deleteProperty(payload).pipe(
        map(() => {
          delete payload.devicePropertySetId;
          return dispatch(GET_DEVICE_DETAIL_REQUEST, payload.deviceId);
        }),
        catchError((error) => [
          dispatch(DELETE_PROPERTY_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, {
            response: {
              message: error.response,
            },
          }),
        ])
      )
    )
  );
};

export const getDeviceFirmwareList = (action$) => {
  return action$.pipe(
    ofType(FETCH_DEVICE_FIRMWARE_REQUEST),
    mergeMap(({ payload }) =>
      deviceService.getDeviceFirmwareList(payload).pipe(
        mergeMap((response) => {
          return [
            dispatch(FETCH_DEVICE_FIRMWARE_SUCCESS, JSON.parse(response)),
          ];
        }),
        catchError((error) => [
          dispatch(FETCH_DEVICE_FIRMWARE_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, {
            response: {
              message: error.response,
            },
          }),
        ])
      )
    )
  );
};

export const getFirmwareDetailsById = (action$) => {
  return action$.pipe(
    ofType(FETCH_FIRMWARE_DETAIL_REQUEST),
    mergeMap(({ payload }) =>
      deviceService.getFirmwareDetailsById(payload).pipe(
        mergeMap((response) => {
          return [
            dispatch(FETCH_FIRMWARE_DETAIL_SUCCESS, JSON.parse(response)),
          ];
        }),
        catchError((error) => [
          dispatch(FETCH_FIRMWARE_DETAIL_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, {
            response: {
              message: error.response,
            },
          }),
        ])
      )
    )
  );
};

export const updateFirmwareDetailsById = (action$) => {
  return action$.pipe(
    ofType(UPDATE_FIRMWARE_DETAIL_REQUEST),
    mergeMap(({ payload }) =>
      deviceService.updateFirmwareDetailsById(payload).pipe(
        mergeMap(() => [dispatch(UPDATE_FIRMWARE_DETAIL_SUCCESS)]),
        catchError((error) => [
          dispatch(UPDATE_FIRMWARE_DETAIL_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, {
            response: {
              message: error.response,
            },
          }),
        ])
      )
    )
  );
};

export const createFirmwareDetailsById = (action$) => {
  return action$.pipe(
    ofType(CREATE_FIRMWARE_DETAIL_REQUEST),
    mergeMap(({ payload }) =>
      deviceService.createFirmwareDetailsById(payload).pipe(
        mergeMap(() => [dispatch(CREATE_FIRMWARE_DETAIL_SUCCESS)]),
        catchError((error) => [
          dispatch(CREATE_FIRMWARE_DETAIL_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, {
            response: {
              message: error.response,
            },
          }),
        ])
      )
    )
  );
};

export const deleteFirmwareDetailsById = (action$) => {
  return action$.pipe(
    ofType(DELETE_DEVICE_FIRMWARE_REQUEST),
    mergeMap(({ payload }) =>
      deviceService.deleteFirmwareDetailsById(payload).pipe(
        mergeMap(() => {
          delete payload.firmwareId;
          return [dispatch(FETCH_DEVICE_FIRMWARE_REQUEST, payload)];
        }),
        catchError((error) => [
          dispatch(DELETE_DEVICE_FIRMWARE_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, {
            response: {
              message: error.response,
            },
          }),
        ])
      )
    )
  );
};

export const getUserProfileData = (action$) => {
  return action$.pipe(
    ofType(FETCH_USER_ORDER_DATA_REQUEST),
    mergeMap(({ payload }) =>
      deviceService.getUserProfileData(payload).pipe(
        mergeMap((data) => {
          const response = JSON.parse(data.response);
          return [dispatch(FETCH_USER_ORDER_DATA_REQUEST_SUCCESS, response)];
        }),
        catchError((error) => [
          dispatch(FETCH_USER_ORDER_DATA_REQUEST_ERROR, error)
        ])
      )
    )
  );
}

export const getDeviceDetailsById = (action$) => {
  return action$.pipe(
    ofType(FETCH_DEVICE_DETAILS_REQUEST),
    mergeMap(({ payload }) =>
      deviceService.getDevicePropertyDetails(payload).pipe(
        mergeMap((response) => {
          return [dispatch(FETCH_DEVICE_DETAILS_SUCCESS, JSON.parse(response))];
        }),
        catchError((error) => [
          dispatch(FETCH_DEVICE_DETAILS_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, {
            response: {
              message: error.response,
            },
          }),
        ])
      )
    )
  );
}

export const saveUserDeviceOrder = (action$) => {
  return action$.pipe(
    ofType(SAVE_USER_ORDER_REQUEST),
    mergeMap(({ payload }) => {
      return deviceService.createDeviceData(payload).pipe(
        mergeMap(() => {
          return [dispatch(SAVE_USER_ORDER_SUCCESS)];
        }),
        catchError((err) => {
          return [dispatch(SAVE_USER_ORDER_ERROR, err)];
        })
      );
    })
  );
};

export const getDeviceDataList = (action$) => {
  return action$.pipe(
    ofType(FETCH_DEVICE_DATA_REQUEST),
    mergeMap(({ payload }) => {
      return deviceService.fetchUserDeviceDataByTime(payload.devHealth).pipe(
        mergeMap((devData) => {
          let parsedData = JSON.parse(devData.response);
          return deviceService
            .getDeviceDataFromTimeStampList(payload.pVittal)
            .pipe(
              mergeMap((data) => {
                return deviceService
                  .fetchUserDeviceDataSVittalByTime(payload.sVittal)
                  .pipe(
                    mergeMap((sVittalData) => {
                      let svParsedData = JSON.parse(sVittalData.response);
                      return pVittalDataMapping(
                        data,
                        payload,
                        parsedData.content,
                        svParsedData.content
                      );
                    }),
                    catchError((error) => {
                      return pVittalDataMapping(data, payload, []);
                    })
                  );
              }),
              catchError((error) => {
                if (payload?.pVittal?.ownerFilter?.users?.length) {
                  return getUserDetailsForDashboard(
                    [],
                    {},
                    payload?.pVittal,
                    parsedData?.content
                  );
                } else {
                  return [
                    dispatch(FETCH_DEVICE_DATA_ERROR, error),
                    dispatch(ERROR_NOTIFICATION_REQUEST, {
                      response: {
                        message: error.response,
                      },
                    }),
                  ];
                }
              })
            );
        }),
        catchError((error) => {
          if (payload?.pVittal?.ownerFilter?.users?.length) {
            return getUserDetailsForDashboard([], {}, payload.pVittal, []);
          } else {
            return [
              dispatch(FETCH_DEVICE_DATA_ERROR, error),
              dispatch(ERROR_NOTIFICATION_REQUEST, { response: {message: error.response} }),
            ];
          }
        })
      );
    })
  );
};

let pVittalDataMapping = (data, payload, dhData, svData) => {
  const devicePayload = JSON.parse(data.response);
  const deviceData = devicePayload.content.reduce((acc, val) => {
    if (val.data.DevidPvital && val.data.DevidPvital.value) {
      if (acc[val.data.DevidPvital.value]) {
        acc[val.data.DevidPvital.value] = [
          ...acc[val.data.DevidPvital.value],
          val,
        ];
        return acc;
      } else {
        let obj = {};
        obj[val.data.DevidPvital.value] = [val];
        return { ...acc, ...obj };
      }
    }
    return acc;
  }, {});
  const linkedUsers = Object.keys(deviceData);
  if (linkedUsers.length > 0) {
    return getUserDetailsForDashboard(
      linkedUsers,
      deviceData,
      payload.pVittal,
      dhData,
      svData
    );
  } else {
    return [
      dispatch(FETCH_DEVICE_DATA_ERROR, {}),
      dispatch(ERROR_NOTIFICATION_REQUEST, {
        response: {
          message: "No data found for selected device.",
        },
      }),
    ];
  }
};

let getUserDetailsForDashboard = (
  linkedUsers,
  deviceData,
  payload,
  dhData,
  svData
) => {
  return deviceService.getDeviceList({ nameLike: payload.deviceName }).pipe(
    mergeMap((list) => {
      let deviceList = JSON.parse(list).content;
      let identityGuid = JSON.parse(list).content.find((val) => {
        if (val.name.split("-")[1].toLowerCase() === "identity") {
          return val;
        }
      });
      return usersService
        .getUserList({
          role: "Patient",
          supplierId: payload.supplierId,
          sortBy: "firstName",
          sortOrder: "ASC",
        })
        .pipe(
          mergeMap((resdientList) => {
            let residentList = JSON.parse(resdientList).content;
            residentList =
              payload.ownerFilter.users.length === 1
                ? residentList.filter(
                    (val) => val.userId === payload.ownerFilter.users[0]
                  )
                : residentList;
            return updatedDeviceDataFunction(
              residentList,
              deviceList,
              identityGuid,
              linkedUsers,
              deviceData,
              payload,
              dhData,
              svData
            );
          }),
          catchError((err) => {
            if (payload.ownerFilter.users.length > 0) {
              return usersService
                .getUserDetailsById(payload.ownerFilter.users[0])
                .pipe(
                  mergeMap((val) => {
                    const residentList = [JSON.parse(val)];
                    return updatedDeviceDataFunction(
                      residentList,
                      deviceList,
                      identityGuid,
                      linkedUsers,
                      deviceData,
                      payload,
                      dhData
                    );
                  }),
                  catchError((err) => {
                    return [
                      dispatch(FETCH_DEVICE_DATA_ERROR, err),
                      dispatch(ERROR_NOTIFICATION_REQUEST, {
                        response: {
                          message: err.response,
                        },
                      }),
                    ];
                  })
                );
            } else {
              return [
                dispatch(FETCH_DEVICE_DATA_ERROR, err),
                dispatch(ERROR_NOTIFICATION_REQUEST, {
                  response: {
                    message: err.response,
                  },
                }),
              ];
            }
          })
        );
    }),
    catchError((err) => {
      return [
        dispatch(FETCH_DEVICE_DATA_ERROR, err),
        dispatch(ERROR_NOTIFICATION_REQUEST, {
          response: {
            message: err.response,
          },
        }),
      ];
    })
  );
};

let updatedDeviceDataFunction = (
  residentList,
  deviceList,
  identityGuid,
  linkedUsers,
  deviceData,
  payload,
  dhData,
  svData
) => {
  const userCustomData = residentList.reduce((acc, val) => {
    return [...acc, usersService.getUserCustomDataById(val.userId)];
  }, []);
  const userDetails = JSON.parse(localStorage.getItem("user-details"));
  const loggedUserRole = userDetails !== null ? userDetails.roles[0].role : "";

  const userDeviceData = residentList.reduce((acc, val) => {
    return [
      ...acc,
      deviceService
        .fetchTableDeviceData({
          payload: { sortBy: "maxValueProvidedOn", sortOrder: "DESC" },
          data: {
            deviceDataModelId: `${payload.deviceDataModelId}-id`,
            devicePropertyCodes: [ "Devid", "FriendlyName", "Location", "Active", "Timezone", "NotificationType"],
            ownerFilter: loggedUserRole !== "Guest" ? { users: [val.userId] } : null,
          },
        })
        .pipe(catchError((error) => of(error))),
    ];
  }, []);
  return forkJoin(userCustomData).pipe(
    mergeMap((result) => {
      return forkJoin(userDeviceData).pipe(
        mergeMap((deviceResponse) => {
          const udpatedResidentData = residentList.map((val, index) => {
            val.deviceIds = [];
            val.linkedGraphData = {};
            if (!deviceResponse[index].message) {
              val.deviceIds = JSON.parse(
                deviceResponse[index].response
              ).content.reduce((acc, data) => {
                const findData = acc.find(
                  (val) => val === data.data.Devid.value
                );
                if (!findData) {
                  let obj = {};
                  const linkedDevUser =
                    dhData &&
                    dhData.find(
                      (v) =>
                        v.ownerId === val.userId &&
                        v.data.DevidDevhealth.value === data.data.Devid.value
                    );
                  const linkedSvUser =
                    svData &&
                    svData.find(
                      (v) =>
                        v.userId === val.userId &&
                        v.data.DevidSvital.value === data.data.Devid.value
                    );
                  var occupied = 0;
                  var apneaperminute = "--";
                  var Fwvers = "--";
                  var Hwvers = "--";
                  var WifiRss = "--";
                  let RouterMac1 = "",
                    RouterMac2 = "";
                  if (
                    linkedSvUser &&
                    linkedSvUser.data &&
                    linkedSvUser.data.DevidSvital.value ===
                      data.data.Devid.value
                  ) {
                    apneaperminute = linkedSvUser?.data?.Apneaperminute?.value;
                    occupied = linkedSvUser?.data?.Occupied?.value || 0;
                  }
                  if (
                    linkedDevUser &&
                    linkedDevUser.data &&
                    linkedDevUser.data.DevidDevhealth.value ===
                      data.data.Devid.value
                  ) {
                    Fwvers = linkedDevUser.data["Last-Fwvers"].value;
                    Hwvers = linkedDevUser.data["Last-Hwvers"].value;
                    WifiRss = linkedDevUser.data["Last-WifiRss"].value;
                    RouterMac1 = linkedDevUser.data["Last-RouterMac1"].value;
                    RouterMac2 = linkedDevUser.data["Last-RouterMac2"].value;
                  }
                  let friendlyName = data.data.FriendlyName.value || "";
                  let location = data.data.Location.value || "";
                  let timezone =
                    data?.data?.Timezone?.value || "Central Standard Time";
                  let active =
                    data.data.Active && data.data.Active.value
                      ? data.data.Active.value
                      : false;
                  obj[data.data.Devid.value] = {
                    FriendlyName: friendlyName,
                    Location: location,
                    active: active,
                    timezone,
                    Fwvers,
                    Hwvers,
                    WifiRss,
                    RouterMac1,
                    RouterMac2,
                    occupied,
                    apneaperminute,
                    data: [],
                  };
                  if (
                    data.data.Devid.value &&
                    deviceData[data.data.Devid.value] &&
                    deviceData[data.data.Devid.value][0].data &&
                    deviceData[data.data.Devid.value][0].userId === data.userId
                  ) {
                    obj[data.data.Devid.value] = {
                      data: deviceData[data.data.Devid.value] || [],
                      FriendlyName: friendlyName,
                      Location: location,
                      active: active,
                      timezone,
                      Fwvers,
                      RouterMac1,
                      RouterMac2,
                      Hwvers,
                      WifiRss,
                      occupied,
                      apneaperminute,
                    };
                    val.linkedGraphData = {
                      ...val.linkedGraphData,
                      ...obj,
                    };
                    return [...acc, data.data];
                  }
                  val.linkedGraphData = {
                    ...val.linkedGraphData,
                    ...obj,
                  };
                  return [...acc, data.data];
                }
                return acc;
              }, []);
            }
            val.customFieldData = JSON.parse(result[index]);
            const selectedtimeZone = val.customFieldData.find(
              (custom) => custom.field.name === "TimeZone"
            );
            const selectedDayLightSaving = val.customFieldData.find(
              (custom) => custom.field.name === "DayLight_Savings"
            );
            val.dayLightSaving = selectedDayLightSaving
              ? selectedDayLightSaving.fieldData
              : "No";
            val.timeZone = selectedtimeZone
              ? selectedtimeZone.fieldData
              : "GMT";
            return val;
          });
          const finalUpdatedResidentData = udpatedResidentData.sort((a, b) => {
            return (
              Object.keys(b.linkedGraphData).length -
              Object.keys(a.linkedGraphData).length
            );
          });
          return linkIdentityWithUser(finalUpdatedResidentData, payload);
        }),
        catchError((err) => {
           
          return [
            dispatch(FETCH_DEVICE_DATA_ERROR, err),
            dispatch(ERROR_NOTIFICATION_REQUEST, {
              response: {
                message: err.response,
              },
            }),
          ];
        })
      );
    })
  );
};

let linkIdentityWithUser = (finalUpdatedResidentData, payload) => {
  if (payload.macId && finalUpdatedResidentData.length > 0) {
    finalUpdatedResidentData[0].linkedGraphData = Object.keys(
      finalUpdatedResidentData[0].linkedGraphData
    ).reduce((acc, val) => {
      if (val === payload.macId) {
        acc[val] = finalUpdatedResidentData[0].linkedGraphData[val];
        return acc;
      } else {
        return acc;
      }
    }, {});
  }
  return [dispatch(FETCH_DEVICE_DATA_SUCCESS, finalUpdatedResidentData)];
};

export const duplicateDevice = (action$) => {
  return action$.pipe(
    ofType(DUPLICATE_DEVICE_REQUEST),
    mergeMap(({ payload }) =>
      deviceService.getDeviceDetailById(payload.deviceId).pipe(
        mergeMap((deviceData) => {
          return deviceService.getDevicePropertyDetails(payload.deviceId).pipe(
            mergeMap((propertyData) => {
              return groupAllDeviceData(
                JSON.parse(deviceData),
                JSON.parse(propertyData).content,
                payload
              );
            }),
            catchError(() => {
              return groupAllDeviceData(JSON.parse(deviceData), [], payload);
            })
          );
        }),
        catchError((error) => {
          return [
            dispatch(DUPLICATE_DEVICE_ERROR, error),
            dispatch(ERROR_NOTIFICATION_REQUEST, {
              response: {
                message: error.response,
              },
            }),
          ];
        })
      )
    )
  );
};

let groupAllDeviceData = (deviceData, propertyData, payload) => {
  const duplicateDeviceObj = {
    description: deviceData.description,
    maxDataAgeDays: deviceData.maxDataAgeDays,
    name: payload.newDeviceName,
    sequentialFirmwareUpgrade: deviceData.sequentialFirmwareUpgrade,
    status: deviceData.status,
    tenantId: deviceData.tenantId,
  };
  return deviceService.addDevice(duplicateDeviceObj).pipe(
    mergeMap((response) => {
      if (propertyData.length === 0) {
        return [
          dispatch(DUPLICATE_DEVICE_SUCCESS),
          dispatch(FETCH_DEVICE_REQUEST, payload.currentPage),
        ];
      } else {
        const newDeviceDetails = JSON.parse(response.response);
        const newDevicePropertySets = propertyData.reduce((acc, val) => {
          return [
            ...acc,
            deviceService.addPropertySet({
              propertyInfo: {
                collectionMethod: val?.collectionMethod,
                description: val?.description,
                deviceId: newDeviceDetails.deviceId,
                deviceProperties: [],
                name: val?.name,
                rolesAllowedToSpecify: val?.rolesAllowedToSpecify,
              },
            }),
          ];
        }, []);
        return forkJoin(newDevicePropertySets).pipe(
          mergeMap((result) => {
            const newPropertyData = propertyData.reduce((acc, val, index) => {
              const propertyData = val.deviceProperties.map((data) => {
                data.devicePropertySet = [
                  {
                    devicePropertySetId: JSON.parse(result[index].response)
                      .devicePropertySetId,
                  },
                ];
                data.deviceId = newDeviceDetails.deviceId;
                delete data.devicePropertyId;
                return deviceService.createProperty(data);
              });
              return [...acc, ...propertyData];
            }, []);
            return forkJoin(newPropertyData).pipe(
              mergeMap(() => {
                return [
                  dispatch(DUPLICATE_DEVICE_SUCCESS),
                  dispatch(FETCH_DEVICE_REQUEST, payload.currentPage),
                ];
              }),
              catchError((error) => {
                return [
                  dispatch(DUPLICATE_DEVICE_ERROR, error),
                  dispatch(ERROR_NOTIFICATION_REQUEST, {
                    response: {
                      message:
                        "Device created successfully, but error occured while linking property.",
                    },
                  }),
                ];
              })
            );
          }),
          catchError((error) => {
            return [
              dispatch(DUPLICATE_DEVICE_ERROR, error),
              dispatch(ERROR_NOTIFICATION_REQUEST, {
                response: {
                  message:
                    "Device created successfully, but error occured while linking property sets.",
                },
              }),
            ];
          })
        );
      }
    }),
    catchError((error) => {
      return [
        dispatch(DUPLICATE_DEVICE_ERROR, error),
        dispatch(ERROR_NOTIFICATION_REQUEST, {
          response: {
            message: error.response,
          },
        }),
      ];
    })
  );
};

export const newDataRequest = (action$) => {
  return action$.pipe(
    ofType(FETCH_NEW_DATA_REQUEST),
    mergeMap(({ payload }) =>
      deviceService.getDeviceDataFromTimeStampList(payload.pVittal).pipe(
        mergeMap((data) => {
          const devicePayload = JSON.parse(data.response);
          return pVittalRefreshDataMapping(devicePayload, {
            content: [],
          });
        }),
        catchError((error) => {
          return [dispatch(FETCH_NEW_DATA_ERROR, error)];
        })
      )
    )
  );
};

export const newDevDataRequest = (action$) => {
  return action$.pipe(
    ofType(FETCH_NEW_DEV_DATA_REQUEST),
    mergeMap(({ payload }) =>
      deviceService.fetchUserDeviceDataByTime(payload.devHealth).pipe(
        mergeMap((data) => {
          const parsedData = JSON.parse(data.response);
          return [dispatch(FETCH_NEW_DEV_DATA_SUCCESS, parsedData)];
        }),
        catchError((error) => {
          return [dispatch(FETCH_NEW_DEV_DATA_ERROR, error)];
        })
      )
    )
  );
};

export const newSvDataRequest = (action$) => {
  return action$.pipe(
    ofType(FETCH_NEW_SV_DATA_REQUEST),
    mergeMap(({ payload }) =>
      deviceService.fetchUserDeviceDataSVittalByTime(payload).pipe(
        mergeMap((data) => {
          const parsedData = JSON.parse(data.response);
          return [dispatch(FETCH_NEW_SV_DATA_SUCCESS, parsedData)];
        }),
        catchError((error) => {
          return [dispatch(FETCH_NEW_SV_DATA_ERROR, error)];
        })
      )
    )
  );
};

let pVittalRefreshDataMapping = (devicePayload, devHealthData) => {
  const deviceData = devicePayload.content.reduce((acc, val) => {
    if (val.data.DevidPvital && val.data.DevidPvital.value) {
      if (acc[val.data.DevidPvital.value]) {
        acc[val.data.DevidPvital.value] = [
          ...acc[val.data.DevidPvital.value],
          val,
        ];
        return acc;
      } else {
        let obj = {};
        obj[val.data.DevidPvital.value] = [val];
        if (devHealthData && devHealthData.content.length > 0) {
          let linkedDhData = devHealthData.content.find(
            (value) =>
              value.data.DevidDevhealth.value === val.data.DevidPvital.value &&
              val.userId === value.userId
          );
          obj[val.data.DevidPvital.value].Fwvers =
            linkedDhData?.data?.Fwvers.value || "--";
          obj[val.data.DevidPvital.value].Hwvers =
            linkedDhData?.data?.Hwvers.value || "--";
        }
        return { ...acc, ...obj };
      }
    }
    return acc;
  }, {});
  return [dispatch(FETCH_NEW_DATA_SUCCESS, deviceData)];
};

export const fetchUserDeviceData = (action$) => {
  return action$.pipe(
    ofType(FETCH_NEW_USER_DATA_REQUEST),
    mergeMap(({ payload }) => {
      let offset = payload.offset;
      let dayLightSaving = payload.dayLightSaving;
      let interval = payload.interval;
      let deviceData = payload.deviceData;
      let backDateData = payload.backDateData || false;
      delete payload.backDateData;
      delete payload.offset;
      delete payload.dayLightSaving;
      return deviceService.fetchUserDeviceAggregateData(payload).pipe(
        mergeMap((data) => {
          return [
            dispatch(FETCH_NEW_USER_DATA_SUCCESS, {
              data: JSON.parse(data.response).content,
              sData: [],
              propertyCode: payload.dataPropertyCode,
              userId: payload.ownerFilter.users[0],
              offset: offset,
              abbr: payload.abbr,
              dayLightSaving: dayLightSaving,
              currentUserTimeZone: payload.currentUserTimeZone,
              interval,
              updated: true,
              backDateData: backDateData,
              rangeStartDateTime: payload.deviceData.rangeStartDateTime,
              rangeEndDateTime: payload.deviceData.rangeEndDateTime,
            }),
          ];
        }),
        catchError((error) => {
          let errorMessage = "Error: Galen cloud not responding";
          if (error.status === 404) {
            errorMessage = "Error: No data in the plot range selected";
          }
          payload.deviceCriteria = payload.deviceCriteria.filter(
            (val) => val.key !== "Timestamp"
          );
          if (payload.dataPropertyCode === "Hr") {
            payload.offset = offset;
            payload.dayLightSaving = dayLightSaving;
            payload.interval = interval;
            payload.deviceData = deviceData;
            let identityObj = {
              ...payload,
              deviceDataModelId: "d36a4373-fbdc-44a3-8c96-4bb920041e40-id",
            };
            identityObj.deviceCriteria = [
              {
                key: "TimeOfLastData",
                operator: "IsSet",
                value: null,
                valueFrom: null,
                valueTo: null,
                isDateValue: false,
                dateTimeRangeValue: {},
              },
              {
                key: "Devid",
                operator: "Equal",
                value: payload.deviceCriteria[0].value,
                valueFrom: null,
                valueTo: null,
                isDateValue: false,
                dateTimeRangeValue: {},
              },
            ];
            delete identityObj.buckets;
            delete identityObj.currentUserTimeZone;
            delete identityObj.currentUserTimeZone;
            delete identityObj.deviceData;
            payload.identityObj = identityObj;
            return fetchDeviceFirstRecord(payload);
          } else {
            return [
              dispatch(ERROR_NOTIFICATION_REQUEST, {
                response: {
                  message: errorMessage,
                  align: "tc",
                },
              }),
            ];
          }
        })
      );
    })
  );
};

const fetchDeviceFirstRecord = (payload) => {
  return deviceService.fetchUserFirstPageData(payload.identityObj).pipe(
    mergeMap((idata) => {
      let lastTime = JSON.parse(idata.response).content[0];
      return setNewDateAndTime(payload, lastTime.maxValueProvidedOn);
    }),
    catchError(() => {
      payload.deviceData.deviceDataModelId =
        "d36a4373-fbdc-44a3-8c96-4bb920041e40";
      payload.deviceData.deviceCriteria = payload.deviceCriteria;
      return deviceService.fetchUserFirstPageData(payload.deviceData).pipe(
        mergeMap((data) => {
          let lastTime = JSON.parse(data.response).content[0];
          return setNewDateAndTime(payload, lastTime.maxValueProvidedOn);
        }),
        catchError((error) => {
          let errorMessage = "Error: Galen cloud not responding";
          if (error.status === 404) {
            errorMessage = "Error: No data in the plot range selected";
          }
          return [
            dispatch(FETCH_NEW_USER_DATA_ERROR, error),
            dispatch(ERROR_NOTIFICATION_REQUEST, {
              response: {
                message: errorMessage,
                align: "tc",
              },
            }),
          ];
        })
      );
    })
  );
};

const setNewDateAndTime = (payload, lastTime) => {
  let m = moment.tz(lastTime, UTC_TIME_ZONE);
  let rangeStartDateTime = "";
  let rangeEndDateTime = m.format("YYYY-MM-DDTHH:mm:ss");
  const intervalArray = payload.interval.split(" ");
  if (payload.interval === "Weekly") {
    m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    rangeStartDateTime = m
      .subtract(7, "day")
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss");
  } else if (payload.interval === "Monthly") {
    m.set({ date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
    rangeStartDateTime = m
      .subtract(30, "day")
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss");
  } else if (intervalArray[1] === "Hr") {
    rangeStartDateTime = m
      .subtract(intervalArray[0], "h")
      .format("YYYY-MM-DDTHH:mm:ss");
  } else if (intervalArray[1] === "secs") {
    rangeStartDateTime = m
      .subtract(intervalArray[0], "seconds")
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss");
  } else {
    rangeStartDateTime = m
      .subtract(intervalArray[0], "minutes")
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss");
  }
  payload.deviceData.rangeStartDateTime = rangeStartDateTime;
  payload.deviceData.rangeEndDateTime = rangeEndDateTime;
  payload.deviceCriteria = [
    ...payload.deviceCriteria,
    {
      key: "Timestamp",
      operator: "GreaterThanOrEqual",
      value: rangeStartDateTime,
      valueFrom: null,
      valueTo: null,
      isDateValue: false,
      dateTimeRangeValue: null,
    },
    {
      key: "Timestamp",
      operator: "LessThanOrEqual",
      value: rangeEndDateTime,
      valueFrom: null,
      valueTo: null,
      isDateValue: false,
      dateTimeRangeValue: null,
    },
  ];
  payload.backDateData = true;
  return [
    dispatch(FETCH_NEW_USER_DATA_REQUEST, payload),
    dispatch(FETCH_NEW_USER_DATA_REQUEST, {
      ...payload,
      dataPropertyCode: "Rr",
    }),
    dispatch(FETCH_NEW_USER_DATA_REQUEST, {
      ...payload,
      dataPropertyCode: "Stress",
    }),
    dispatch(FETCH_NEW_USER_DATA_REQUEST, {
      ...payload,
      dataPropertyCode: "Motion",
    }),
  ];
};

export const fetchUserMinDeviceData = (action$) => {
  return action$.pipe(
    ofType(FETCH_NEW_USER_MIN_DATA_REQUEST),
    mergeMap(({ payload }) =>
      deviceService.fetchUserDeviceData(payload).pipe(
        mergeMap((data) => {
          return [
            dispatch(FETCH_NEW_USER_MIN_DATA_SUCCESS, {
              data: JSON.parse(data.response).content.filter(
                (val) => val.data.DevidPvital.value === payload.devId
              ),
              userId: payload.ownerFilter.users[0],
              offset: payload.offset,
              abbr: payload.abbr,
              dayLightSaving: payload.dayLightSaving,
              currentUserTimeZone: payload.currentUserTimeZone,
            }),
          ];
        }),
        catchError((error) => [
          dispatch(FETCH_NEW_USER_MIN_DATA_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, {
            response: {
              message: `Error while fetching the data.`,
            },
          }),
        ])
      )
    )
  );
};

export const fetchUserMinDeviceUpdateData = (action$) => {
  return action$.pipe(
    ofType(FETCH_NEW_USER_MIN_DATA_UPDATE_REQUEST),
    mergeMap(({ payload }) =>
      deviceService.fetchUserDeviceData(payload).pipe(
        mergeMap((data) => {
          return [
            dispatch(FETCH_NEW_USER_MIN_DATA_SUCCESS, {
              data: JSON.parse(data.response).content.filter(
                (val) => val.data.DevidPvital.value === payload.devId
              ),
              userId: payload.ownerFilter.users[0],
              offset: payload.offset,
              abbr: payload.abbr,
              dayLightSaving: payload.dayLightSaving,
              currentUserTimeZone: payload.currentUserTimeZone,
            }),
          ];
        }),
        catchError((error) => [
          dispatch(FETCH_NEW_USER_MIN_DATA_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, {
            response: {
              message: `Error while fetching the data.`,
            },
          }),
        ])
      )
    )
  );
};

export const fetchUserDeviceUpdateData = (action$) => {
  return action$.pipe(
    ofType(FETCH_UPDATE_USER_DATA_REQUEST),
    mergeMap(({ payload }) => {
      let offset = payload.offset;
      let dayLightSaving = payload.dayLightSaving;
      let interval = payload.interval;
      delete payload.offset;
      delete payload.dayLightSaving;
      delete payload.interval;
      return deviceService.fetchUserDeviceAggregateData(payload).pipe(
        mergeMap((data) => {
          return [
            dispatch(FETCH_NEW_USER_DATA_SUCCESS, {
              data: JSON.parse(data.response).content,
              propertyCode: payload.dataPropertyCode,
              sData: [],
              userId: payload.ownerFilter.users[0],
              offset: offset,
              abbr: payload.abbr,
              interval: interval,
              dayLightSaving: dayLightSaving,
              currentUserTimeZone: payload.currentUserTimeZone,
              updated: false,
            }),
          ];
        }),
        catchError((error) => [dispatch(FETCH_NEW_USER_DATA_ERROR, error)])
      );
    })
  );
};

export const resetGraphData = (action$) => {
  return action$.pipe(
    ofType(RESET_GRAPH_REQUEST),
    mergeMap(() => [dispatch(RESET_GRAPH_SUCCESS)])
  );
};

export const getDeviceSpecifiedData = (action$) => {
  return action$.pipe(
    ofType(FETCH_DEVICE_SPECIFIED_REQUEST),
    mergeMap(({ payload }) => {
      return deviceService.getDeviceList({ nameLike: payload.deviceName }).pipe(
        mergeMap((list) => {
          const linkedDevice = JSON.parse(list).content;
          const propertyDeviceReq = linkedDevice.map((val) =>
            deviceService.getDevicePropertySetList(val.deviceId)
          );
          return forkJoin(propertyDeviceReq).pipe(
            mergeMap((response) => {
              const devData = response.reduce((acc, value) => {
                const val = JSON.parse(value).content[0];
                let dataTypes = [];
                const devicePropertyCodes = val.deviceProperties
                  .sort((a, b) => a.displayOrder - b.displayOrder)
                  .reduce((acc, data) => {
                    dataTypes = [...dataTypes, data.dataType];
                    return [...acc, data.propertyCode];
                  }, []);
                let obj = {};
                obj[val.name] = {
                  deviceDataModelId: val.deviceDataModelId,
                  devicePropertyCodes,
                  dataTypes,
                  devicePropertySetId: val.devicePropertySetId,
                };
                if (payload.macAddress && payload.macAddress !== "all") {
                  switch (val.name) {
                    case "pVITAL":
                      obj[val.name].deviceCriteria = [
                        {
                          key: "DevidPvital",
                          operator: "Equal",
                          value: payload.macAddress,
                          valueFrom: "",
                          valueTo: "",
                          isDateValue: false,
                          dateTimeRangeValue: null,
                        },
                      ];
                      break;
                    case "IDENTITY":
                      obj[val.name].deviceCriteria = [
                        {
                          key: "Devid",
                          operator: "Equal",
                          value: payload.macAddress,
                          valueFrom: "",
                          valueTo: "",
                          isDateValue: false,
                          dateTimeRangeValue: null,
                        },
                      ];
                      break;
                    case "sVITAL":
                      obj[val.name].deviceCriteria = [
                        {
                          key: "DevidSvital",
                          operator: "Equal",
                          value: payload.macAddress,
                          valueFrom: "",
                          valueTo: "",
                          isDateValue: false,
                          dateTimeRangeValue: null,
                        },
                      ];
                      break;
                    case "PARAMS":
                      obj[val.name].deviceCriteria = [
                        {
                          key: "DevidParams",
                          operator: "Equal",
                          value: payload.macAddress,
                          valueFrom: "",
                          valueTo: "",
                          isDateValue: false,
                          dateTimeRangeValue: null,
                        },
                      ];
                      break;
                    case "THRESHOLDS":
                      obj[val.name].deviceCriteria = [
                        {
                          key: "DevidThresholds",
                          operator: "Equal",
                          value: payload.macAddress,
                          valueFrom: "",
                          valueTo: "",
                          isDateValue: false,
                          dateTimeRangeValue: null,
                        },
                      ];
                      break;
                    case "DevHealth":
                      obj[val.name].deviceCriteria = [
                        {
                          key: "DevidDevhealth",
                          operator: "Equal",
                          value: payload.macAddress,
                          valueFrom: "",
                          valueTo: "",
                          isDateValue: false,
                          dateTimeRangeValue: null,
                        },
                      ];
                      break;
                    case "ALERTS":
                      obj[val.name].deviceCriteria = [
                        {
                          key: "DevidAlerts",
                          operator: "Equal",
                          value: payload.macAddress,
                          valueFrom: "",
                          valueTo: "",
                          isDateValue: false,
                          dateTimeRangeValue: null,
                        },
                      ];
                      break;
                  }
                }
                return { ...acc, ...obj };
              }, {});
              return Object.keys(devData).reduce(
                (acc, val) => {
                  if (val !== "pVITAL" && val !== "sVITAL") {
                    return [
                      ...acc,
                      dispatch(GET_PROPERTY_DATA_REQUEST, {
                        payload: {
                          pageNumber: 0,
                          pageSize: 5,
                          sortBy: "maxValueProvidedOn",
                          sortOrder: "DESC",
                        },
                        data: {
                          ...devData[val],
                          ownerFilter: { users: payload.users },
                        },
                        type: val,
                      }),
                    ];
                  }
                  return acc;
                },
                [dispatch(FETCH_DEVICE_SPECIFIED_SUCCESS, devData)]
              );
            }),
            catchError((error) => {
              return [dispatch(FETCH_DEVICE_SPECIFIED_ERROR, error)];
            })
          );
        }),
        catchError((error) => {
          return [dispatch(FETCH_DEVICE_SPECIFIED_ERROR, error)];
        })
      );
    })
  );
};

export const getPropertyData = (action$) => {
  return action$.pipe(
    ofType(GET_PROPERTY_DATA_REQUEST),
    mergeMap(({ payload }) => {
      return deviceService.fetchTableDeviceData(payload).pipe(
        mergeMap((response) => {
          let data = JSON.parse(response.response).content;
          let obj = {};
          obj[payload.type] = {
            data,
            totalElements: JSON.parse(response.response).totalElements,
            devicePropertySetId: payload.data.devicePropertySetId,
          };
          return [dispatch(GET_PROPERTY_DATA_SUCCESS, obj)];
        }),
        catchError(() => {
          let obj = {};
          obj[payload.type] = {
            data: [],
            totalElements: 0,
          };
          return [dispatch(GET_PROPERTY_DATA_SUCCESS, obj)];
        })
      );
    })
  );
};

export const createDeviceData = (action$) => {
  return action$.pipe(
    ofType(CREATE_DEVICE_DATA_REQUEST),
    mergeMap(({ payload }) => {
      return deviceService.createDeviceData(payload).pipe(
        mergeMap(() => {
          return [dispatch(CREATE_DEVICE_DATA_SUCCESS)];
        }),
        catchError((err) => {
          return [dispatch(CREATE_DEVICE_DATA_ERROR, err)];
        })
      );
    })
  );
};

export const updateDeviceData = (action$) => {
  return action$.pipe(
    ofType(UPDATE_DEVICE_DATA_REQUEST),
    mergeMap(({ payload }) => {
      return deviceService.createDeviceData(payload.reqObj).pipe(
        mergeMap(() => {
          return [dispatch(GET_PROPERTY_DATA_REQUEST, payload.paginationObj)];
        }),
        catchError((err) => {
          return [dispatch(UPDATE_DEVICE_DATA_ERROR, err)];
        })
      );
    })
  );
};

export const updateDefaultData = (action$) => {
  return action$.pipe(
    ofType(UPDATE_DEFAULT_DATA_REQUEST),
    mergeMap(({ payload }) => {
      return usersService.getUserDetailsById(payload.user.ownerId).pipe(
        mergeMap((response) => {
          const userCompany = JSON.parse(response).roles[0].supplier;
          const companyInfo = payload.companyList.find(
            (val) => val.supplierId === userCompany.supplierId
          );
          let data = {
            DevidThresholds:
              payload.paginationObj.type === "PARAMS"
                ? payload.user.data.DevidParams.value
                : payload.user.data.DevidThresholds.value,
          };
          const macType =
            payload.paginationObj.type === "PARAMS"
              ? "DevidParams"
              : "DevidThresholds";
          const codes = payload.codes.filter((val) => val !== macType);
          data = codes.reduce((acc, val) => {
            const field = companyInfo.customData.find(
              (cVal) => cVal.field.name === val
            );
            let obj = {};
            obj[val] = field?.fieldData;
            return { ...acc, ...obj };
          }, data);
          if (payload.paginationObj.type === "PARAMS") {
            data.ParamsLastupdate = moment()
              .utc()
              .format("YYYY-MM-DDTHH:mm:ss");
          } else {
            data.ThresholdLastupdate = moment()
              .utc()
              .format("YYYY-MM-DDTHH:mm:ss");
          }
          let reqObj = {
            devicePropertySetId: payload.devicePropertySetId,
            deviceDataModelId: payload.deviceDataModelId,
            deviceDataId: payload.deviceDataId,
            data,
          };
          return [
            dispatch(UPDATE_DEVICE_DATA_REQUEST, {
              paginationObj: payload.paginationObj,
              reqObj: reqObj,
            }),
          ];
        }),
        catchError((err) => {
          return [dispatch(UPDATE_DEFAULT_DATA_ERROR, err)];
        })
      );
    })
  );
};

export const updateDefaultValueInCompany = (action$) => {
  return action$.pipe(
    ofType(UPDATE_DEFAULT_THRESHOLD_REQUEST),
    mergeMap(({ payload }) => {
      return usersService
        .getUserList({
          role: "Patient",
          supplierId: payload.supplierId,
          sortBy: "firstName",
          sortOrder: "ASC",
        })
        .pipe(
          mergeMap((response) => {
            return patientDeviceService
              .getPatientDeviceList({
                sortBy: "createdOn",
                sortOrder: "DESC",
              })
              .pipe(
                mergeMap((presponse) => {
                  let thresholdObj = {
                    payload: {
                      sortBy: "maxValueProvidedOn",
                      sortOrder: "DESC",
                    },
                    data: {
                      deviceId: payload.paginationObj.data.deviceId,
                      devicePropertyCodes:
                        payload.paginationObj.data.devicePropertyCodes,
                    },
                  };
                  return deviceService.fetchTableDeviceData(thresholdObj).pipe(
                    mergeMap((thresholdResponse) => {
                      const thresholdData = JSON.parse(
                        thresholdResponse.response
                      ).content;
                      const userList = JSON.parse(response).content;
                      const patientDevice = JSON.parse(presponse).content;
                      const macType =
                        payload.paginationObj.type === "PARAMS"
                          ? "DevidParams"
                          : "DevidThresholds";
                      const codes = payload.paginationObj.data.devicePropertyCodes.filter(
                        (val) => val !== macType
                      );
                      const defaultValues = codes.reduce((acc, val) => {
                        let defaultValue = payload.customData.find(
                          (v) => v.field.name === val
                        );
                        let obj = {};
                        obj[val] = defaultValue?.fieldData;
                        return { ...acc, ...obj };
                      }, {});

                      const updatedUserList = userList.reduce((acc, val) => {
                        let macId = patientDevice.find(
                          (pVal) => pVal.user.userId === val.userId
                        );
                        if (macId?.value) {
                          const currentThreshold = thresholdData.find(
                            (data) => data.user?.userId === val.userId
                          );
                          let newObj = {};
                          newObj[macType] = macId.value;
                          return [
                            ...acc,
                            deviceService.createDeviceData({
                              devicePropertySetId:
                                payload.paginationObj.data.devicePropertySetId,
                              deviceId: payload.paginationObj.data.deviceId,
                              deviceDataId: currentThreshold?.deviceDataId,
                              data: { ...defaultValues, ...newObj },
                            }),
                          ];
                        } else {
                          return acc;
                        }
                      }, []);
                      return forkJoin(updatedUserList).pipe(
                        mergeMap((updateResponse) => {
                          return [
                            dispatch(
                              GET_PROPERTY_DATA_REQUEST,
                              payload.paginationObj
                            ),
                          ];
                        }),
                        catchError((err) => {
                          return [
                            dispatch(UPDATE_DEFAULT_THRESHOLD_ERROR, err),
                          ];
                        })
                      );
                    }),
                    catchError((err) => {
                      return [dispatch(UPDATE_DEFAULT_THRESHOLD_ERROR, err)];
                    })
                  );
                }),
                catchError((err) => {
                  return [dispatch(UPDATE_DEFAULT_THRESHOLD_ERROR, err)];
                })
              );
          }),
          catchError((err) => {
            return [dispatch(UPDATE_DEFAULT_THRESHOLD_ERROR, err)];
          })
        );
    })
  );
};

export const deleteDeviceData = (action$) => {
  return action$.pipe(
    ofType(DELETE_DEVICE_DATA_REQUEST),
    mergeMap(({ payload }) => {
      return deviceService.deleteDeviceData(payload).pipe(
        mergeMap(() => {
          return [dispatch(GET_PROPERTY_DATA_REQUEST, payload.paginationObj)];
        }),
        catchError((err) => {
          return [dispatch(DELETE_DEVICE_DATA_ERROR, err)];
        })
      );
    })
  );
};

export const syncFriendlyName = (action$) => {
  return action$.pipe(
    ofType(SYNC_FRIENDLY_NAME_REQUEST),
    mergeMap(({ payload }) => {
      return deviceService.fetchUserDeviceData(payload.userObj).pipe(
        mergeMap((res) => {
          const devId = payload.reqObj.data.Devid;
          const searchedData = JSON.parse(res.response).content.find((val) => {
            const currentId =
              payload.type === "PARAMS"
                ? val.data.DevidParams.value
                : val.data.DevidThresholds.value;
            if (currentId === devId) {
              return val;
            }
          });
          let data = Object.keys(searchedData.data).reduce((acc, val) => {
            let obj = {};
            obj[val] = searchedData.data[val].value;
            return { ...acc, ...obj };
          }, {});
          if (payload.type === "THRESHOLDS") {
            data = {
              ...data,
              ...{ FriendlyNameT: payload.reqObj.data.FriendlyName },
            };
          } else if (payload.type === "PARAMS") {
            data = {
              ...data,
              ...{ FriendlyNamePa: payload.reqObj.data.FriendlyName },
            };
          }
          let reqObj = {
            devicePropertySetId:
              payload.device?.deviceTable[payload.type].devicePropertySetId,
            deviceId: payload.userInfo?.deviceId,
            deviceDataId: searchedData.deviceDataId,
            data,
          };
          let paginationObj = {
            payload: {
              pageNumber: payload.paginationData[payload.type]?.page,
              pageSize: payload.paginationData[payload.type]?.rowsPerPage,
              sortBy: "maxValueProvidedOn",
              sortOrder: "DESC",
            },
            data: {
              deviceId: payload.userInfo?.deviceId,
              devicePropertyCodes:
                payload.device?.deviceTableHeader[payload.type]
                  .devicePropertyCodes,
              devicePropertySetId:
                payload.device?.deviceTable[payload.type].devicePropertySetId,
            },
            ownerFilter: {
              users: [payload.userInfo.userId],
            },
            type: payload.type,
          };
          return [
            dispatch(UPDATE_DEVICE_DATA_REQUEST, { reqObj, paginationObj }),
          ];
        }),
        catchError((err) => {
          return [dispatch(SYNC_FRIENDLY_NAME_ERROR, err)];
        })
      );
      // return deviceService.deleteDeviceData(payload).pipe(
      //   mergeMap(() => {
      //     return [dispatch(SYNC_FRIENDLY_NAME_SUCCESS, payload.paginationObj)];
      //   }),
      //   catchError((err) => {
      //     return [dispatch(SYNC_FRIENDLY_NAME_ERROR, err)];
      //   })
      // );
    })
  );
};

export const fetchDeviceSwVersion = (action$) => {
  return action$.pipe(
    ofType(FETCH_HW_SW_VERSION_REQUEST),
    mergeMap(({ payload }) => {
      return deviceService.fetchUserDeviceDataWithLimit(payload).pipe(
        mergeMap((response) => {
          return [
            dispatch(FETCH_HW_SW_VERSION_SUCCESS, {
              data: JSON.parse(response.response).content,
            }),
          ];
        }),
        catchError((err) => {
          return [dispatch(FETCH_HW_SW_VERSION_ERROR, err)];
        })
      );
    })
  );
};

export const fetchCsvData = (action$) => {
  return action$.pipe(
    ofType(FETCH_CSV_DATA_REQUEST),
    mergeMap(({ payload }) => {
      let sVittal = payload.sVittal;
      let offset = payload.offset;
      let dayLightSaving = payload.dayLightSaving;
      let friendlyName = payload.friendlyName;
      let swVersion = payload.swVersion;
      let hwVersion = payload.hwVersion;
      let userRole = payload.userRole;
      let groupBy = payload.groupBy;
      let groupBySv = payload.groupBySv;
      // delete payload.offset;
      // delete payload.dayLightSaving;
      // delete payload.friendlyName;
      delete payload.sVittal;
      // delete payload.swVersion;
      // delete payload.hwVersion;
      delete payload.fullName;
      delete payload.userRole;

      const xyz = (sData, devicePayload) => {
        const deviceSPayload = sData?.response && JSON.parse(sData?.response)?.content || [];
        let csvResponse = manageCsvData(
          devicePayload,
          deviceSPayload,
          dayLightSaving,
          offset,
          friendlyName,
          swVersion,
          hwVersion,
          userRole,
          payload.currentUserTimeZone,
          groupBy,
          groupBySv
        );
        let hrFile = `${friendlyName}-${moment
          .utc(payload.rangeStartDateTime)
          .subtract(dayLightSaving, "h")
          .utcOffset(offset)
          .format("MMDDYYYY HH:mm")}.csv`;
        let sFile = `${friendlyName}-${moment
          .utc(payload.rangeStartDateTime)
          .subtract(dayLightSaving, "h")
          .utcOffset(offset)
          .format("MMDDYYYY HH:mm")}-hrv.csv`;
        if (swVersion) {
          hrFile = `${friendlyName}-${moment
            .utc(payload.rangeStartDateTime)
            .subtract(dayLightSaving, "h")
            .utcOffset(offset)
            .format("MMDDYYYY HH:mm")}(v${swVersion}).csv`;
          sFile = `${friendlyName}-${moment
            .utc(payload.rangeStartDateTime)
            .subtract(dayLightSaving, "h")
            .utcOffset(offset)
            .format("MMDDYYYY HH:mm")}(v${swVersion})-hrv.csv`;
        }
        if (payload.interval === "1 min") {
          hrFile = `${friendlyName}-${moment
            .utc(payload.rangeStartDateTime)
            .subtract(dayLightSaving, "h")
            .utcOffset(offset)
            .format("MMDDYYYY HH:mm")}(v${swVersion})_60.csv`;
          sFile = `${friendlyName}-${moment
            .utc(payload.rangeStartDateTime)
            .subtract(dayLightSaving, "h")
            .utcOffset(offset)
            .format("MMDDYYYY HH:mm")}(v${swVersion})_60-hrv.csv`;
        }
        downloadBlob(
          arrayToCsv(csvResponse.pData),
          hrFile,
          "text/csv;charset=utf-8;"
        );
        downloadBlob(
          arrayToCsv(csvResponse.sData),
          sFile,
          "text/csv;charset=utf-8;"
        );
      }

      return deviceService.fetchUserDeviceDataByTime(payload).pipe(
        mergeMap((data) => {
          const devicePayload = JSON.parse(data.response).content;
          return deviceService.fetchUserDeviceDataByTime(sVittal).pipe(
            mergeMap((sData) => {
              xyz(sData, devicePayload);
              return [dispatch(FETCH_CSV_DATA_SUCCESS)];
            }),
            catchError((err) => {
              xyz([], devicePayload);
              return [
                dispatch(FETCH_CSV_DATA_ERROR, err),
                dispatch(ERROR_NOTIFICATION_REQUEST, {
                  response: {
                    message: `Error while fetching sVittal data.`,
                  },
                }),
              ];
            })
          );
        }),
        catchError((err) => {
          return [
            dispatch(FETCH_CSV_DATA_ERROR, err),
            dispatch(ERROR_NOTIFICATION_REQUEST, {
              response: {
                message: `Error while fetching pVittal data.`,
              },
            }),
          ];
        })
      );
    })
  );
};

export const fetchMinCsvData = (action$) => {
  return action$.pipe(
    ofType(FETCH_MIN_CSV_DATA_REQUEST),
    mergeMap(({ payload }) => {
      payload.groupingPropertyOptions[0].groupType = "Minute";
      payload.group = false;
      payload.groupBy = "Minute-Timestamp";
      payload.groupBySv = "Minute-TimestampSv";
      let offset = payload.offset;
      let dayLightSaving = payload.dayLightSaving;
      let friendlyName = payload.friendlyName;
      let swVersion = payload.swVersion;
      let hwVersion = payload.hwVersion;
      let userRole = payload.userRole;
      let groupBy = payload.groupBy;
      let groupBySv = payload.groupBySv;
      delete payload.offset;
      delete payload.dayLightSaving;
      // delete payload.friendlyName;
      delete payload.sVittal;
      delete payload.swVersion;
      delete payload.hwVersion;
      delete payload.fullName;
      delete payload.userRole;
      return deviceService.fetchUserDeviceDataByTime(payload).pipe(
        mergeMap((data) => {
          const devicePayload = JSON.parse(data.response).content;
          let csvResponse = manageMinCsvData(
            devicePayload,
            dayLightSaving,
            offset,
            friendlyName,
            swVersion,
            hwVersion,
            userRole,
            payload.currentUserTimeZone,
            groupBy,
            groupBySv
          );
          let hrFile = `${friendlyName}-${moment
            .utc(payload.rangeStartDateTime)
            .subtract(dayLightSaving, "h")
            .utcOffset(offset)
            .format("MMDDYYYY HH:mm")}_60.csv`;
          if (swVersion) {
            hrFile = `${friendlyName}-${moment
              .utc(payload.rangeStartDateTime)
              .subtract(dayLightSaving, "h")
              .utcOffset(offset)
              .format("MMDDYYYY HH:mm")}(v${swVersion})_60.csv`;
          }
          downloadBlob(
            arrayToCsv(csvResponse.pData),
            hrFile,
            "text/csv;charset=utf-8;"
          );
          return [dispatch(FETCH_MIN_CSV_DATA_SUCCESS)];
        }),
        catchError((err) => {
          return [
            dispatch(FETCH_MIN_CSV_DATA_ERROR, err),
            dispatch(ERROR_NOTIFICATION_REQUEST, {
              response: {
                message: `Error while fetching pVittal data.`,
              },
            }),
          ];
        })
      );
    })
  );
};

let manageCsvData = (
  pVittal,
  sVittal,
  dayLightSaving,
  offset,
  friendlyName,
  swVersion,
  hwVersion,
  userRole,
  currentUserTimeZone,
  groupBy,
  groupBySv
) => {
  try{
    let pHData = [
      [
        "Date",
        "Time",
        "Date-Time",
        "Hr",
        "Rr",
        "Stress",
        "Motion",
        "RssHrMv",
        "RssRrMv",
        "HrTd",
        "HrFd",
        "HrSel",
        "HrEst",
        "WinFd",
        "WinSel",
        "WinEst",
        "WinTd",
        "Friendly Name",
        "HWver",
        "FWver",
      ],
    ];
    if (userRole === "Patient") {
      pHData = [
        [
          "Date",
          "Time",
          "Date-Time",
          "Hr",
          "Rr",
          "Stress",
          "Motion",
          "Friendly Name",
          "HWver",
          "FWver",
        ],
      ];
    }
    let sHData = [
      [
        "Date",
        "Time",
        "Date-Time",
        "Sdnn",
        "Rmssd",
        "Occupied",
        "ApneaEvents",
        "Friendly Name",
        "HWver",
        "FWver",
      ],
    ];
    if (userRole === "Patient") {
      sHData = [
        [
          "Date",
          "Time",
          "Date-Time",
          "Sdnn",
          "Rmssd",
          "Occupied",
          "Friendly Name",
          "HWver",
          "FWver",
        ],
      ];
    }
    let pData = [];
    let sData = [];
  
    pVittal.reverse().forEach((val, index) => {
      let newTimeYear = momentTz
        .utc(val.data[groupBy].value)
        .tz(currentUserTimeZone)
        .format("YYYY");
      let newTimeMonth = momentTz
        .utc(val.data[groupBy].value)
        .tz(currentUserTimeZone)
        .format("M");
      let newTimeDay = momentTz
        .utc(val.data[groupBy].value)
        .tz(currentUserTimeZone)
        .format("D");
      let newTimeHour = momentTz
        .utc(val.data[groupBy].value)
        .tz(currentUserTimeZone)
        .format("H");
      let newTimeMin = momentTz
        .utc(val.data[groupBy].value)
        .tz(currentUserTimeZone)
        .format("m");
      let newTimeSec = momentTz
        .utc(val.data[groupBy].value)
        .tz(currentUserTimeZone)
        .format("s");
      let newTime = new Date(
        newTimeYear,
        newTimeMonth - 1,
        newTimeDay,
        newTimeHour,
        newTimeMin,
        newTimeSec
      );
      if (userRole === "Patient") {
        pData = [
          ...pData,
          [
            moment(newTime).format("MM-DD-YYYY"),
            moment(newTime).format("HH:mm:ss"),
            moment(newTime).format("MM/DD/YY HH:mm:ss"),
            val.data["Average-Hr"]
              ? val.data["Average-Hr"].value?.toFixed(1)
              : "-",
            val.data["Average-Rr"]
              ? val.data["Average-Rr"].value?.toFixed(1)
              : "-",
            val.data["Average-Stress"]
              ? val.data["Average-Stress"].value?.toFixed(1)
              : "-",
            val.data["Average-Motion"]
              ? val.data["Average-Motion"].value?.toFixed(1)
              : "-",
            friendlyName,
            // hwVersion || "-",
            // swVersion || "-",
          ],
        ];
      } else {
        if (index === 0) {
          pData = [
            ...pData,
            [
              moment(newTime).format("MM-DD-YYYY"),
              moment(newTime).format("HH:mm:ss"),
              moment(newTime).format("MM/DD/YY HH:mm:ss"),
              val.data["Average-Hr"]
                ? val.data["Average-Hr"].value?.toFixed(1)
                : "-",
              val.data["Average-Rr"]
                ? val.data["Average-Rr"].value?.toFixed(1)
                : "-",
              val.data["Average-Stress"]
                ? val.data["Average-Stress"].value?.toFixed(1)
                : "-",
              val.data["Average-Motion"]
                ? val.data["Average-Motion"].value?.toFixed(1)
                : "-",
              val.data["Average-RssHrMv"]
                ? val.data["Average-RssHrMv"].value?.toFixed(1)
                : "-",
              val.data["Average-RssRrMv"]
                ? val.data["Average-RssRrMv"].value?.toFixed(1)
                : "-",
              val.data["Average-HrTd"]
                ? val.data["Average-HrTd"].value?.toFixed(1)
                : "-",
              val.data["Average-HrFd"]
                ? val.data["Average-HrFd"].value?.toFixed(1)
                : "-",
              val.data["Average-HrSel"]
                ? val.data["Average-HrSel"].value?.toFixed(1)
                : "-",
              val.data["Average-HrEst"]
                ? val.data["Average-HrEst"].value?.toFixed(1)
                : "-",
              val.data["Average-WinFd"]
                ? val.data["Average-WinFd"].value?.toFixed(1)
                : "-",
              val.data["Average-WinSel"]
                ? val.data["Average-WinSel"].value?.toFixed(1)
                : "-",
              val.data["Average-WinEst"]
                ? val.data["Average-WinEst"].value?.toFixed(1)
                : "-",
              val.data["Average-WinTd"]
                ? val.data["Average-WinTd"].value?.toFixed(1)
                : "-",
              friendlyName,
              // hwVersion || "-",
              // swVersion || "-",
            ],
          ];
        } else {
          pData = [
            ...pData,
            [
              moment(newTime).format("MM-DD-YYYY"),
              moment(newTime).format("HH:mm:ss"),
              moment(newTime).format("MM/DD/YY HH:mm:ss"),
              val.data["Average-Hr"]
                ? val.data["Average-Hr"].value?.toFixed(1)
                : "-",
              val.data["Average-Rr"]
                ? val.data["Average-Rr"].value?.toFixed(1)
                : "-",
              val.data["Average-Stress"]
                ? val.data["Average-Stress"].value?.toFixed(1)
                : "-",
              val.data["Average-Motion"]
                ? val.data["Average-Motion"].value?.toFixed(1)
                : "-",
              val.data["Average-RssHrMv"]
                ? val.data["Average-RssHrMv"].value?.toFixed(1)
                : "-",
              val.data["Average-RssRrMv"]
                ? val.data["Average-RssRrMv"].value?.toFixed(1)
                : "-",
              val.data["Average-HrTd"]
                ? val.data["Average-HrTd"].value?.toFixed(1)
                : "-",
              val.data["Average-HrFd"]
                ? val.data["Average-HrFd"].value?.toFixed(1)
                : "-",
              val.data["Average-HrSel"]
                ? val.data["Average-HrSel"].value?.toFixed(1)
                : "-",
              val.data["Average-HrEst"]
                ? val.data["Average-HrEst"].value?.toFixed(1)
                : "-",
              val.data["Average-WinFd"]
                ? val.data["Average-WinFd"].value?.toFixed(1)
                : "-",
              val.data["Average-WinSel"]
                ? val.data["Average-WinSel"].value?.toFixed(1)
                : "-",
              val.data["Average-WinEst"]
                ? val.data["Average-WinEst"].value?.toFixed(1)
                : "-",
              val.data["Average-WinTd"]
                ? val.data["Average-WinTd"].value?.toFixed(1)
                : "-",
              friendlyName,
            ],
          ];
        }
      }
    });
    sVittal.reverse().forEach((val, index) => {
      let newTimeYear = momentTz
        .utc(val.data["Minute-TimestampSv"].value)
        .tz(currentUserTimeZone)
        .format("YYYY");
      let newTimeMonth = momentTz
        .utc(val.data["Minute-TimestampSv"].value)
        .tz(currentUserTimeZone)
        .format("M");
      let newTimeDay = momentTz
        .utc(val.data["Minute-TimestampSv"].value)
        .tz(currentUserTimeZone)
        .format("D");
      let newTimeHour = momentTz
        .utc(val.data["Minute-TimestampSv"].value)
        .tz(currentUserTimeZone)
        .format("H");
      let newTimeMin = momentTz
        .utc(val.data["Minute-TimestampSv"].value)
        .tz(currentUserTimeZone)
        .format("m");
      let newTimeSec = momentTz
        .utc(val.data["Minute-TimestampSv"].value)
        .tz(currentUserTimeZone)
        .format("s");
      let newTime = new Date(
        newTimeYear,
        newTimeMonth - 1,
        newTimeDay,
        newTimeHour,
        newTimeMin,
        newTimeSec
      );
      if (userRole === "Patient") {
        sData = [
          ...sData,
          [
            moment(newTime).format("MM-DD-YYYY"),
            moment(newTime).format("HH:mm:ss"),
            moment(newTime).format("MM/DD/YY HH:mm:ss"),
            val.data["Average-Sdnn"]
              ? val.data["Average-Sdnn"].value?.toFixed(1)
              : "-",
            val.data["Average-Rmssd"]
              ? val.data["Average-Rmssd"].value?.toFixed(1)
              : "-",
            val.data["Average-Occupied"]
              ? val.data["Average-Occupied"].value || 0
              : 0,
            friendlyName,
            // hwVersion || "-",
            // swVersion || "-",
          ],
        ];
      } else {
        sData = [
          ...sData,
          [
            moment(newTime).format("MM-DD-YYYY"),
            moment(newTime).format("HH:mm:ss"),
            moment(newTime).format("MM/DD/YY HH:mm:ss"),
            val.data["Average-Sdnn"]
              ? val.data["Average-Sdnn"].value?.toFixed(1)
              : "-",
            val.data["Average-Rmssd"]
              ? val.data["Average-Rmssd"].value?.toFixed(1)
              : "-",
            val.data["Average-Occupied"]
              ? val.data["Average-Occupied"].value || 0
              : 0,
            val.data["Average-Apneaperminute"]
              ? val.data["Average-Apneaperminute"].value
              : "-",
            friendlyName,
            // hwVersion || "-",
            // swVersion || "-",
          ],
        ];
      }
    });
    if(pData && pData?.length) {
      pData = [...pHData, ...pData.sort((a, b) => new Date(a[2]) - new Date(b[2]))];
      pData[1] = [...pData[1], hwVersion, swVersion];
    } else {
      pData = [...pHData];
    }
    if(sData && sData?.length) {
      sData = [...sHData, ...sData.sort((a, b) => new Date(a[2]) - new Date(b[2]))];
      sData[1] = [...sData[1], hwVersion, swVersion];
    } else {
      sData = [...sHData];
    }
    return {
      pData,
      sData,
    };
  } catch(error) {
    console.log(error);
  }
};

let manageMinCsvData = (
  pVittal,
  dayLightSaving,
  offset,
  friendlyName,
  swVersion,
  hwVersion,
  userRole,
  currentUserTimeZone,
  groupBy,
  groupBySv
) => {
  let pHData = [
    [
      "Date",
      "Time",
      "Date-Time",
      "Hr",
      "Rr",
      "Stress",
      "Motion",
      "RssHrMv",
      "RssRrMv",
      "HrTd",
      "HrFd",
      "HrSel",
      "HrEst",
      "WinFd",
      "WinSel",
      "WinEst",
      "WinTd",
      "Friendly Name",
      "HWver",
      "FWver",
    ],
  ];
  if (userRole === "Patient") {
    pHData = [
      [
        "Date",
        "Time",
        "Date-Time",
        "Hr",
        "Rr",
        "Stress",
        "Motion",
        "Friendly Name",
        "HWver",
        "FWver",
      ],
    ];
  }
  let pData = [];
  pVittal.reverse().forEach((val, index) => {
    let newTimeYear = momentTz
      .utc(val.data[groupBy].value)
      .tz(currentUserTimeZone)
      .format("YYYY");
    let newTimeMonth = momentTz
      .utc(val.data[groupBy].value)
      .tz(currentUserTimeZone)
      .format("M");
    let newTimeDay = momentTz
      .utc(val.data[groupBy].value)
      .tz(currentUserTimeZone)
      .format("D");
    let newTimeHour = momentTz
      .utc(val.data[groupBy].value)
      .tz(currentUserTimeZone)
      .format("H");
    let newTimeMin = momentTz
      .utc(val.data[groupBy].value)
      .tz(currentUserTimeZone)
      .format("m");
    let newTimeSec = momentTz
      .utc(val.data[groupBy].value)
      .tz(currentUserTimeZone)
      .format("s");
    let newTime = new Date(
      newTimeYear,
      newTimeMonth - 1,
      newTimeDay,
      newTimeHour,
      newTimeMin,
      newTimeSec
    );
    if (userRole === "Patient") {
      pData = [
        ...pData,
        [
          moment(newTime).format("MM-DD-YYYY"),
          moment(newTime).format("HH:mm:ss"),
          moment(newTime).format("MM/DD/YY HH:mm:ss"),
          val.data["Average-Hr"]
            ? val.data["Average-Hr"].value?.toFixed(1)
            : "-",
          val.data["Average-Rr"]
            ? val.data["Average-Rr"].value?.toFixed(1)
            : "-",
          val.data["Average-Stress"]
            ? val.data["Average-Stress"].value?.toFixed(1)
            : "-",
          val.data["Average-Motion"]
            ? val.data["Average-Motion"].value?.toFixed(1)
            : "-",
          friendlyName,
          // hwVersion || "-",
          // swVersion || "-",
        ],
      ];
    } else {
      if (index === 0) {
        pData = [
          ...pData,
          [
            moment(newTime).format("MM-DD-YYYY"),
            moment(newTime).format("HH:mm:ss"),
            moment(newTime).format("MM/DD/YY HH:mm:ss"),
            val.data["Average-Hr"]
              ? val.data["Average-Hr"].value?.toFixed(1)
              : "-",
            val.data["Average-Rr"]
              ? val.data["Average-Rr"].value?.toFixed(1)
              : "-",
            val.data["Average-Stress"]
              ? val.data["Average-Stress"].value?.toFixed(1)
              : "-",
            val.data["Average-Motion"]
              ? val.data["Average-Motion"].value?.toFixed(1)
              : "-",
            val.data["Average-RssHrMv"]
              ? val.data["Average-RssHrMv"].value?.toFixed(1)
              : "-",
            val.data["Average-RssRrMv"]
              ? val.data["Average-RssRrMv"].value?.toFixed(1)
              : "-",
            val.data["Average-HrTd"]
              ? val.data["Average-HrTd"].value?.toFixed(1)
              : "-",
            val.data["Average-HrFd"]
              ? val.data["Average-HrFd"].value?.toFixed(1)
              : "-",
            val.data["Average-HrSel"]
              ? val.data["Average-HrSel"].value?.toFixed(1)
              : "-",
            val.data["Average-HrEst"]
              ? val.data["Average-HrEst"].value?.toFixed(1)
              : "-",
            val.data["Average-WinFd"]
              ? val.data["Average-WinFd"].value?.toFixed(1)
              : "-",
            val.data["Average-WinSel"]
              ? val.data["Average-WinSel"].value?.toFixed(1)
              : "-",
            val.data["Average-WinEst"]
              ? val.data["Average-WinEst"].value?.toFixed(1)
              : "-",
            val.data["Average-WinTd"]
              ? val.data["Average-WinTd"].value?.toFixed(1)
              : "-",
            friendlyName,
            // hwVersion || "-",
            // swVersion || "-",
          ],
        ];
      } else {
        pData = [
          ...pData,
          [
            moment(newTime).format("MM-DD-YYYY"),
            moment(newTime).format("HH:mm:ss"),
            moment(newTime).format("MM/DD/YY HH:mm:ss"),
            val.data["Average-Hr"]
              ? val.data["Average-Hr"].value?.toFixed(1)
              : "-",
            val.data["Average-Rr"]
              ? val.data["Average-Rr"].value?.toFixed(1)
              : "-",
            val.data["Average-Stress"]
              ? val.data["Average-Stress"].value?.toFixed(1)
              : "-",
            val.data["Average-Motion"]
              ? val.data["Average-Motion"].value?.toFixed(1)
              : "-",
            val.data["Average-RssHrMv"]
              ? val.data["Average-RssHrMv"].value?.toFixed(1)
              : "-",
            val.data["Average-RssRrMv"]
              ? val.data["Average-RssRrMv"].value?.toFixed(1)
              : "-",
            val.data["Average-HrTd"]
              ? val.data["Average-HrTd"].value?.toFixed(1)
              : "-",
            val.data["Average-HrFd"]
              ? val.data["Average-HrFd"].value?.toFixed(1)
              : "-",
            val.data["Average-HrSel"]
              ? val.data["Average-HrSel"].value?.toFixed(1)
              : "-",
            val.data["Average-HrEst"]
              ? val.data["Average-HrEst"].value?.toFixed(1)
              : "-",
            val.data["Average-WinFd"]
              ? val.data["Average-WinFd"].value?.toFixed(1)
              : "-",
            val.data["Average-WinSel"]
              ? val.data["Average-WinSel"].value?.toFixed(1)
              : "-",
            val.data["Average-WinEst"]
              ? val.data["Average-WinEst"].value?.toFixed(1)
              : "-",
            val.data["Average-WinTd"]
              ? val.data["Average-WinTd"].value?.toFixed(1)
              : "-",
            friendlyName,
          ],
        ];
      }
    }
  });
  pData = [...pHData, ...pData.sort((a, b) => new Date(a[2]) - new Date(b[2]))];
  pData[1] = [...pData[1], hwVersion, swVersion];
  return {
    pData,
  };
};

let arrayToCsv = (data) => {
  return data
    .map((row) =>
      row
        .map(String)
        .map((v) => v.replaceAll('"', '""'))
        .map((v) => `"${v}"`)
        .join(",")
    )
    .join("\r\n");
};

let downloadBlob = (content, filename, contentType, fileSize) => {
  // Create a blob
  var blob = new Blob([content], { type: contentType });
  var url = URL.createObjectURL(blob);

  // Create a link to download it
  var pom = document.createElement("a");
  pom.href = url;
  pom.setAttribute("download", filename);
  pom.click();
};

export const fetchFriendlyNameData = (action$) => {
  return action$.pipe( 
    ofType(FETCH_FRIENDLY_NAME_REQUEST),
    mergeMap(({ payload }) => {
      let data = {
        payload: {sortBy: "maxValueProvidedOn", sortOrder: "DESC"},
        data: { deviceDataModelId: `${payload.deviceDataModelId}-id`, devicePropertyCodes: ["Devid", "FriendlyName", "Timezone"]}
      };
      if (payload?.userId) {
        data.data.ownerFilter = {users: [payload.userId]}
      }

      return deviceService.fetchTableDeviceData(data).pipe(
        mergeMap((identityData) => {
          let identityResponse = JSON.parse(identityData.response).content;
          let friendlyNameData = identityResponse.reduce((acc, val) => {
            return [
              ...acc, {
                macId: val.data.Devid.value,
                friendlyName: val.data.FriendlyName.value,
                timeZone: val.data?.Timezone?.value || DEFAULT_TIME,
              },
            ];
          }, []);
          let finalObj = {
            user: payload.user || "",
            friendlyNameData,
          };

          return [dispatch(FETCH_FRIENDLY_NAME_SUCCESS, finalObj)];
        }),
        catchError(() => {
          return [
            dispatch(FETCH_FRIENDLY_NAME_ERROR),
            dispatch(ERROR_NOTIFICATION_REQUEST, {response: { message: "Friendly Name not found." }}),
          ];
        })
      );
    })
  );
};

export const getErrorLogData = (action$) => {
  return action$.pipe(
    ofType(FETCH_ERROR_LOG_DATA_REQUEST),
    mergeMap(({ payload }) => {
      const newIdentityDataFrame = payload?.identityDataFrame && [...payload.identityDataFrame] || [];
      delete payload.identityDataFrame;
      return deviceService.fetchTableDeviceData(payload).pipe(
        mergeMap((response) => {
          let identityCalls = []
          if (newIdentityDataFrame?.length > 0){
            identityCalls = newIdentityDataFrame?.reduce((acc, val) => {
              return [
                ...acc,
                deviceService
                  .fetchUserFirstPageData(val)
                  .pipe(catchError((error) => of(error))),
              ];
            }, []);
            return forkJoin(identityCalls).pipe(
              mergeMap((result) => {
                let macIdObj = [];
                let deviceData = JSON.parse(response.response);
                deviceData.macIdObj = [];
                if (result.length > 0) {
                  result.forEach(val => {
                    const parsedData = JSON.parse(val.response);
                    if (
                      parsedData.content.length > 0 && 
                      parsedData.content[0].data &&
                      parsedData.content[0].data.Timezone && 
                      parsedData.content[0].data.Timezone.value &&
                      parsedData.content[0].data.Devid && 
                      parsedData.content[0].data.Devid.value
                    ) {
                      macIdObj[parsedData.content[0].data.Devid.value] = parsedData.content[0].data.Timezone.value;
                    }
                  });
                  deviceData.macIdObj = macIdObj
                  return [dispatch(FETCH_ERROR_LOG_DATA_SUCCESS, deviceData)];
                } else {
                  return [dispatch(FETCH_ERROR_LOG_DATA_SUCCESS, deviceData)];
                }      
              }),
              catchError((error) => {
                return [
                  dispatch(FETCH_ERROR_LOG_DATA_ERROR, error),
                  dispatch(ERROR_NOTIFICATION_REQUEST, {
                    response: {
                      message: `Error while fetching logs`,
                    },
                  }),
                ];
              })
            );
          } else {
            let deviceData = JSON.parse(response.response);
            deviceData.macIdObj = [];
            return [dispatch(FETCH_ERROR_LOG_DATA_SUCCESS, deviceData)];
          }
        }),
        catchError((error) => {
          return [
            dispatch(FETCH_ERROR_LOG_DATA_ERROR, error),
            dispatch(ERROR_NOTIFICATION_REQUEST, {
              response: {
                message: `Error while fetching logs`,
              },
            }),
          ];
        })
      );
    })
  );
};

export const deleteErrorLogData = (action$) => {
  return action$.pipe(
    ofType(DELETE_ERROR_LOG_DATA_REQUEST),
    mergeMap(({ payload }) => {
      const filter = payload.filter;
      return deviceService.deleteMultipleDeviceData(payload).pipe(
        mergeMap(() => {
          return [
            dispatch(FETCH_ERROR_LOG_DATA_REQUEST, filter),
            dispatch(SUCCESS_NOTIFICATION_REQUEST, {
              message: `Error logs deleted successfully`,
            }),
          ];
        }),
        catchError((error) => {
          return [
            dispatch(DELETE_ERROR_LOG_DATA_ERROR, error),
            dispatch(ERROR_NOTIFICATION_REQUEST, {
              response: {
                message: `Error while deleting error logs`,
              },
            }),
          ];
        })
      );
    })
  );
};

export const downloadFirmwareVersion = (action$) => {
  return action$.pipe(
    ofType(DOWNLOAD_DEVICE_FIRMWARE_REQUEST),
    mergeMap(({ payload }) =>
      deviceService.downloadFirmwareVersion(payload.firmwareId).pipe(
        map((response) => {
          const responseType = response.xhr.getResponseHeader('content-type');
          const responseLength = response.xhr.getResponseHeader('content-type');
          downloadBlob(response.response, payload.fileName, responseType, responseLength);
          return dispatch(DOWNLOAD_DEVICE_FIRMWARE_SUCCESS, payload.deviceId);
        }),
        catchError((error) => [
          dispatch(DOWNLOAD_DEVICE_FIRMWARE_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, {
            response: {
              message: error.response,
            },
          }),
        ])
      )
    )
  );
};

export const getAlertLogData = (action$) => {
  return action$.pipe(
    ofType(FETCH_ALERT_LOG_DATA_REQUEST),
    mergeMap(({ payload }) => {
      return deviceService.fetchTableDeviceData(payload).pipe(
        map((response) => {
          let deviceData = JSON.parse(response.response);
          return dispatch(FETCH_ALERT_LOG_DATA_SUCCESS, deviceData);
        }),
        catchError((error) => {
          return [
            dispatch(FETCH_ALERT_LOG_DATA_ERROR, error),
            dispatch(ERROR_NOTIFICATION_REQUEST, {
              response: {
                message: `Error while fetching logs`,
              },
            }),
          ];
        })
      );
    })
  );
};

export const getReadAlertLogData = (action$) => {
  return action$.pipe(
    ofType(FETCH_READ_ALERT_LOG_DATA_REQUEST),
    mergeMap(({ payload }) => {
      return deviceService.fetchTableDeviceData(payload).pipe(
        map((response) => {
          let deviceData = JSON.parse(response.response);
          return dispatch(
            FETCH_READ_ALERT_LOG_DATA_SUCCESS,
            deviceData.totalElements
          );
        }),
        catchError((error) => {
          return [
            dispatch(FETCH_READ_ALERT_LOG_DATA_ERROR, error),
            dispatch(ERROR_NOTIFICATION_REQUEST, {
              response: {
                message: `Error while fetching logs`,
              },
            }),
          ];
        })
      );
    })
  );
};

export const deleteAlertLogData = (action$) => {
  return action$.pipe(
    ofType(DELETE_ALERT_LOG_DATA_REQUEST),
    mergeMap(({ payload }) => {
      const filter = payload.filter;
      return deviceService.deleteMultipleAlertDeviceData(payload).pipe(
        mergeMap(() => {
          return [
            dispatch(FETCH_ALERT_LOG_DATA_REQUEST, filter),
            dispatch(SUCCESS_NOTIFICATION_REQUEST, {
              message: `Alert logs deleted successfully`,
            }),
          ];
        }),
        catchError((error) => {
          return [
            dispatch(DELETE_ALERT_LOG_DATA_ERROR, error),
            dispatch(ERROR_NOTIFICATION_REQUEST, {
              response: {
                message: `Error while deleting alert logs`,
              },
            }),
          ];
        })
      );
    })
  );
};

export const svittalDataRequest = (action$) => {
  return action$.pipe(
    ofType(FETCH_SVITTAL_DATA_REQUEST),
    mergeMap(({ payload }) => {
      return deviceService.fetchUserDeviceDataSVittalByTime(payload).pipe(
        mergeMap((data) => {
          const parsedData = JSON.parse(data.response);
          return [dispatch(FETCH_SVITTAL_DATA_SUCCESS, parsedData)];
        }),
        catchError((error) => {
          return [dispatch(FETCH_SVITTAL_DATA_ERROR, error)];
        })
      );
    })
  );
};

export const paramsDataRequest = (action$) => {
  return action$.pipe(
    ofType(FETCH_PARAMS_DATA_REQUEST),
    mergeMap(({ payload }) => {
      return deviceService.fetchUserDeviceDataParamsByTime(payload).pipe(
        mergeMap((data) => {
          const parsedData = JSON.parse(data.response);
          return [dispatch(FETCH_PARAMS_DATA_SUCCESS, parsedData)];
        }),
        catchError((error) => {
          return [dispatch(FETCH_PARAMS_DATA_ERROR, error)];
        })
      );
    })
  );
};

export const sensorControlRequest = (action$) => {
  return action$.pipe(
    ofType(FETCH_SENSOR_CONTROL_REQUEST),
    mergeMap(({ payload }) => {
      const finalPayload = payload.reduce((acc, val) => {
        return [
          ...acc,
          deviceService
            .fetchUserDeviceAggregateFirstData(val)
            .pipe(catchError((error) => of(error))),
        ];
      }, []);
      const finalPropertyPayload = payload.reduce((acc, val) => {
        return [
          ...acc,
          deviceService
            .getDevicePropertyDetails(val.deviceDataModelId)
            .pipe(catchError((error) => of(error))),
        ];
      }, []);
      return forkJoin([...finalPayload, ...finalPropertyPayload]).pipe(
        mergeMap((data) => {
          let identityData =
            data[0].status === 404 ? [] : JSON.parse(data[0].response).content;
          let paramsData =
            data[1].status === 404 ? [] : JSON.parse(data[1].response).content;
          let thresholdData =
            data[2].status === 404 ? [] : JSON.parse(data[2].response).content;
          return [
            dispatch(FETCH_SENSOR_CONTROL_SUCCESS, {
              identityData,
              paramsData,
              thresholdData,
              identityPropertySet: JSON.parse(data[3]).content,
              paramsPropertySet: JSON.parse(data[4]).content,
              thresholdPropertySet: JSON.parse(data[5]).content,
            }),
          ];
        }),
        catchError((error) => {
           
          return [dispatch(FETCH_SENSOR_CONTROL_ERROR, error)];
        })
      );
    })
  );
};

export const updateSensorControlRequest = (action$) => {
  return action$.pipe(
    ofType(UPDATE_SENSOR_CONTROL_REQUEST),
    mergeMap(({ payload }) => {
      const thresholdData = payload[2].data;
      let error = false;
      let errorMessage = "Threshold value not in range.";
      if (
        (!!thresholdData.AlertMinhr || !!thresholdData.AlertMaxhr) &&
        (!(
          Number(thresholdData.AlertMinhr) >= 30 &&
          Number(thresholdData.AlertMinhr) <= 150
        ) ||
          !(
            Number(thresholdData.AlertMaxhr) >= 30 &&
            Number(thresholdData.AlertMaxhr) <= 150
          ))
      ) {
        error = true;
      }
      if (
        (!!thresholdData.AlertMinrr || !!thresholdData.AlertMaxrr) &&
        (!(
          Number(thresholdData.AlertMinrr) >= 8 &&
          Number(thresholdData.AlertMinrr) <= 60
        ) ||
          !(
            Number(thresholdData.AlertMaxrr) >= 8 &&
            Number(thresholdData.AlertMaxrr) <= 60
          ))
      ) {
        error = true;
      }
      if (
        (!!thresholdData.AlertMinst || !!thresholdData.AlertMinst) &&
        (!(
          Number(thresholdData.AlertMinst) >= 0 &&
          Number(thresholdData.AlertMinst) <= 10
        ) ||
          !(
            Number(thresholdData.AlertMaxst) >= 0 &&
            Number(thresholdData.AlertMaxst) <= 10
          ))
      ) {
        error = true;
      }
      if (
        (!!thresholdData.AlertMinmt || !!thresholdData.AlertMaxmt) &&
        (!(
          Number(thresholdData.AlertMinmt) >= 0 &&
          Number(thresholdData.AlertMinmt) <= 10
        ) ||
          !(
            Number(thresholdData.AlertMaxmt) >= 0 &&
            Number(thresholdData.AlertMaxmt) <= 10
          ))
      ) {
        error = true;
      }
      if (error) {
        return [
          dispatch(ERROR_NOTIFICATION_REQUEST, {
            response: {
              message: errorMessage,
            },
          }),
        ];
      } else {
        const finalPayload = payload.reduce((acc, val) => {
          return [...acc, deviceService.updateDeviceData(val)];
        }, []);
        return forkJoin(finalPayload).pipe(
          mergeMap(() => {
            return [dispatch(UPDATE_SENSOR_CONTROL_SUCCESS)];
          }),
          catchError((error) => {
            return [dispatch(UPDATE_SENSOR_CONTROL_ERROR, error)];
          })
        );
      }
    })
  );
};

export const updateSensorControlTableRequest = (action$) => {
  return action$.pipe(
    ofType(UPDATE_SENSOR_CONTROL_TABLE_REQUEST),
    mergeMap(({ payload }) => {
      return deviceService.updateDeviceData(payload).pipe(
        mergeMap(() => {
          return [dispatch(UPDATE_SENSOR_CONTROL_TABLE_SUCCESS)];
        }),
        catchError((error) => {
          return [dispatch(UPDATE_SENSOR_CONTROL_TABLE_ERROR, error)];
        })
      );
    })
  );
};

export const fetchDiagnosticPanelData = (action$) => {
  return action$.pipe(
    ofType(FETCH_DIAGNOSTIC_PANEL_DATA_REQUEST),
    mergeMap(({ payload }) => {
      return forkJoin([
        deviceService
          .fetchUserDeviceAggregateData(
            payload.firstUserFirstRequest,
            payload.filter1
          )
          .pipe(catchError((error) => of(error))),
        deviceService
          .fetchUserDeviceAggregateData(
            payload.secondUserFirstRequest,
            payload.filter1
          )
          .pipe(catchError((error) => of(error))),
        deviceService
          .fetchUserDeviceAggregateData(
            payload.thirdUserFirstRequest,
            payload.filter1
          )
          .pipe(catchError((error) => of(error))),
        deviceService
          .fetchUserDeviceAggregateData(
            payload.firstUserSecondRequest,
            payload.filter2
          )
          .pipe(catchError((error) => of(error))),
        deviceService
          .fetchUserDeviceAggregateData(
            payload.secondUserSecondRequest,
            payload.filter2
          )
          .pipe(catchError((error) => of(error))),
        deviceService
          .fetchUserDeviceAggregateData(
            payload.thirdUserSecondRequest,
            payload.filter2
          )
          .pipe(catchError((error) => of(error))),
      ]).pipe(
        mergeMap((data) => {
          if (data.filter((val) => val.name === "AjaxError").length === 6) {
            return [
              dispatch(FETCH_DIAGNOSTIC_PANEL_DATA_ERROR),
              dispatch(ERROR_NOTIFICATION_REQUEST, {
                response: {
                  message: "Error: No data in the plot range selected",
                  align: "tc",
                },
              }),
            ];
          } else {
            let devId1 = "DevidPvital";
            if (payload.filter1.sortBy === "data.TimestampS.value") {
              devId1 = "DevidSvital";
            }
            let devId2 = "DevidPvital";
            if (payload.filter2.sortBy === "data.TimestampS.value") {
              devId2 = "DevidSvital";
            }
            return [
              dispatch(FETCH_DIAGNOSTIC_PANEL_DATA_SUCCESS, {
                firstUserFirstData:
                  data[0].status === 200
                    ? JSON.parse(data[0].response).content
                    : [],
                secondUserFirstData:
                  data[1].status === 200
                    ? JSON.parse(data[1].response).content
                    : [],
                thirdUserFirstData:
                  data[2].status === 200
                    ? JSON.parse(data[2].response).content
                    : [],
                firstUserSecondData:
                  data[3].status === 200
                    ? JSON.parse(data[3].response).content
                    : [],
                secondUserSecondData:
                  data[4].status === 200
                    ? JSON.parse(data[4].response).content
                    : [],
                thirdUserSecondData:
                  data[5].status === 200
                    ? JSON.parse(data[5].response).content
                    : [],

                firstUserId: payload.firstUserFirstRequest.deviceCriteria.find(
                  (val) => val.key === devId1
                ),
                secondUserId:
                  Object.keys(payload.secondUserFirstRequest).length > 0
                    ? payload.secondUserFirstRequest.deviceCriteria.find(
                        (val) => val.key === devId1
                      )
                    : [],
                thirdUserId:
                  Object.keys(payload.thirdUserFirstRequest).length > 0
                    ? payload.thirdUserFirstRequest.deviceCriteria.find(
                        (val) => val.key === devId1
                      )
                    : [],

                firstUserId2: payload.firstUserSecondRequest.deviceCriteria.find(
                  (val) => val.key === devId2
                ),
                secondUserId2:
                  Object.keys(payload.secondUserSecondRequest).length > 0
                    ? payload.secondUserSecondRequest.deviceCriteria.find(
                        (val) => val.key === devId2
                      )
                    : [],
                thirdUserId2:
                  Object.keys(payload.thirdUserSecondRequest).length > 0
                    ? payload.thirdUserSecondRequest.deviceCriteria.find(
                        (val) => val.key === devId2
                      )
                    : [],

                secondUser:
                  Object.keys(payload.secondUserFirstRequest).length > 0
                    ? true
                    : false,
                thirdUser:
                  Object.keys(payload.thirdUserFirstRequest).length > 0
                    ? true
                    : false,
                currentUserTimeZone: payload.firstUserFirstRequest.userUtcTime,
              }),
            ];
          }
        }),
        catchError((error) => {
           
          let errorMessage = "Error: Galen cloud not responding";
          if (error.status === 404) {
            errorMessage = "Error: No data in the plot range selected";
          }
          return [
            dispatch(FETCH_DIAGNOSTIC_PANEL_DATA_ERROR),
            dispatch(ERROR_NOTIFICATION_REQUEST, {
              response: {
                message: errorMessage,
                align: "tc",
              },
            }),
          ];
        })
      );
    })
  );
};

export const saveDiagnosticPanelData = (action$) => {
  return action$.pipe(
    ofType(SAVE_DIAGNOSTIC_PANEL_DATA_REQUEST),
    mergeMap(({ payload }) => {
      return deviceService
        .getDeviceList({
          nameLike: "Diagnostic",
          pageNumber: 0,
          pageSize: 20,
        })
        .pipe(
          mergeMap((data) => {
            const parsedDeviceData = JSON.parse(data);
            if (parsedDeviceData && parsedDeviceData.content.length > 0) {
              const deviceInfo = parsedDeviceData.content[0];
              return deviceService
                .getDevicePropertySetList(deviceInfo.deviceId)
                .pipe(
                  mergeMap((dataProperty) => {
                    const parsedDeviceDataProperty = JSON.parse(dataProperty);
                    let finalObj = {
                      devicePropertySetId:
                        parsedDeviceDataProperty.content[0].devicePropertySetId,
                      deviceId: parsedDeviceDataProperty.content[0].deviceId,
                      deviceDataId: payload.deviceDataId || "",
                      data: {
                        User1: payload.userId1,
                        User2: payload.userId2,
                        User3: payload.userId3,
                        Username1: payload.userName1,
                        Username2: payload.userName2,
                        Username3: payload.userName3,
                        Friendlyname1: payload.friendlyName1,
                        Friendlyname2: payload.friendlyName2,
                        Friendlyname3: payload.friendlyName3,
                        Fname1: payload.macAddress1,
                        Fname2: payload.macAddress2,
                        Fname3: payload.macAddress3,
                        Interval: payload.interval,
                        Graph1: payload.graphProperty1,
                        Graph2: payload.graphProperty2,
                        Timezone: payload.timezone,
                        Starttime: payload.intervalStartTime,
                        Createdbyuser: payload.loggedUserId,
                        Createdbyusername: payload.createdByUserName,
                        Timeformat: payload.timeformat,
                      },
                    };
                    return deviceService.createDeviceData(finalObj).pipe(
                      mergeMap(() => {
                        return [dispatch(SAVE_DIAGNOSTIC_PANEL_DATA_SUCCESS)];
                      })
                    );
                  })
                );
            } else {
              return [
                dispatch(SAVE_DIAGNOSTIC_PANEL_DATA_ERROR),
                dispatch(ERROR_NOTIFICATION_REQUEST, {
                  response: {
                    message: "Error while saving filter data.",
                    align: "tc",
                  },
                }),
              ];
            }
          }),
          catchError((error) => {
            let errorMessage = "Error: Galen cloud not responding";
            if (error.status === 404) {
              errorMessage = "Error: No data in the plot range selected";
            }
            return [
              dispatch(SAVE_DIAGNOSTIC_PANEL_DATA_ERROR),
              dispatch(ERROR_NOTIFICATION_REQUEST, {
                response: {
                  message: errorMessage,
                  align: "tc",
                },
              }),
            ];
          })
        );
    })
  );
};

export const fetchSavedDiagnosticPanelData = (action$) => {
  return action$.pipe(
    ofType(FETCH_SAVED_DIAGNOSTIC_PANEL_DATA_REQUEST),
    mergeMap(({ payload }) => {
      return deviceService
        .getDeviceList({
          nameLike: "Diagnostic",
          pageNumber: 0,
          pageSize: 20,
        })
        .pipe(
          mergeMap((data) => {
            const parsedDeviceData = JSON.parse(data);
            if (parsedDeviceData && parsedDeviceData.content.length > 0) {
              const deviceInfo = parsedDeviceData.content[0];
              return deviceService
                .getDevicePropertySetList(deviceInfo.deviceId)
                .pipe(
                  mergeMap((dataProperty) => {
                    const parsedDeviceDataProperty = JSON.parse(dataProperty);
                    let finalObj = {
                      deviceDataModelId:
                        parsedDeviceDataProperty.content[0].deviceDataModelId,
                      deviceCriteriaGroup: {
                        isOr: false,
                        groupOwner: null,
                        groupElements: [
                          {
                            key: "Createdbyuser",
                            operator: "Equal",
                            value: payload,
                            valueFrom: null,
                            valueTo: null,
                            isDateValue: false,
                            dateTimeRangeValue: null,
                            criteriaNumber: 1,
                          },
                        ],
                      },
                    };
                    return deviceService
                      .fetchSavedDiagnosticPanelData(finalObj)
                      .pipe(
                        mergeMap((savedResponse) => {
                          const data = JSON.parse(savedResponse.response);
                          return [
                            dispatch(
                              FETCH_SAVED_DIAGNOSTIC_PANEL_DATA_SUCCESS,
                              data.content
                            ),
                          ];
                        })
                      );
                  })
                );
            } else {
              return [dispatch(FETCH_SAVED_DIAGNOSTIC_PANEL_DATA_ERROR),];
            }
          }),
          catchError((error) => {
            return [dispatch(FETCH_SAVED_DIAGNOSTIC_PANEL_DATA_ERROR),];
          })
        );
    })
  );
};

export const fetchSavedDiagnosticPanel = (action$) => {
  return action$.pipe(
    ofType(FETCH_DIAGNOSTIC_PANEL_REQUEST),
    mergeMap(({ payload }) => {
      return deviceService
        .getDeviceList({
          nameLike: "Diagnostic",
          pageNumber: 0,
          pageSize: 20,
        })
        .pipe(
          mergeMap((data) => {
            const parsedDeviceData = JSON.parse(data);
            if (parsedDeviceData && parsedDeviceData.content.length > 0) {
              const deviceInfo = parsedDeviceData.content[0];
              return deviceService
                .getDevicePropertySetList(deviceInfo.deviceId)
                .pipe(
                  mergeMap((dataProperty) => {
                    const parsedDeviceDataProperty = JSON.parse(dataProperty);
                    let finalObj = {
                      deviceDataModelId:
                        parsedDeviceDataProperty.content[0].deviceDataModelId,
                      deviceCriteriaGroup: {
                        isOr: false,
                        groupOwner: null,
                        groupElements: [],
                      },
                    };
                    return deviceService
                      .fetchSavedDiagnosticPanelData(finalObj)
                      .pipe(
                        mergeMap((savedResponse) => {
                          const data = JSON.parse(savedResponse.response);
                          return [
                            dispatch(FETCH_DIAGNOSTIC_PANEL_SUCCESS, data),
                          ];
                        })
                      );
                  })
                );
            } else {
              return [
                dispatch(FETCH_DIAGNOSTIC_PANEL_ERROR),
              ];
            }
          }),
          catchError((error) => {
            return [dispatch(FETCH_DIAGNOSTIC_PANEL_ERROR),];
          })
        );
    })
  );
};
