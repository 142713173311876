import { ofType } from "redux-observable";
import { forkJoin, of } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";

import { dispatch } from "../../common/helpers";
import { PAGINATION_DETAILS } from "../../common/constants";
import { usersService, practiceService, companyService } from "../../services";
import {
  FETCH_DASHBOARD_REQUEST,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_ERROR,
} from "./actions";

export const getDashboardData = (action$) =>
  action$.pipe(
    ofType(FETCH_DASHBOARD_REQUEST),
    mergeMap(() =>
      forkJoin(
        [
          usersService.getUserList(PAGINATION_DETAILS),
          practiceService.getPracticeList(PAGINATION_DETAILS),
          companyService.getCompanyList(PAGINATION_DETAILS),
        ].map((el) => el.pipe(catchError(() => of({ data: [] }))))
      )
    ),
    map(([users, practice, company]) => {
      return dispatch(FETCH_DASHBOARD_SUCCESS, {
        users: JSON.parse(users).totalElements,
        practice: JSON.parse(practice).totalElements,
        company: JSON.parse(company).totalElements,
      });
    }),
    catchError((err) => [dispatch(FETCH_DASHBOARD_ERROR, err)])
  );
