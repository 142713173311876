import { mergeMap, map, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";

import { dispatch } from "../../common/helpers";
import { guestMapService } from "../../services";

import {
    FETCH_PATIENT_SENSOR_REQUEST,
    FETCH_PATIENT_SENSOR_SUCCESS,
    FETCH_PATIENT_SENSOR_ERROR,
    FETCH_GUESTS_REQUEST,
    FETCH_GUESTS_SUCCESS,
    FETCH_GUESTS_ERROR,
    FETCH_DEVICE_PROPSETID_REQUEST,
    FETCH_DEVICE_PROPSETID_SUCCESS,
    UPDATE_GUESTMAPPING_REQUEST,
    UPDATE_GUESTMAPPING_SUCCESS,
    UPDATE_GUESTMAPPING_ERROR,
    SEND_INVITE_REQUEST,
    SEND_INVITE_SUCCESS,
    SEND_INVITE_ERROR,
    ADD_GUESTMAP_REQUEST,
    ADD_GUESTMAP_SUCCESS,
    ADD_GUESTMAP_ERROR
} from "./actions";

import {
  ERROR_NOTIFICATION_REQUEST,
} from "../notifications/actions";

export const getPatientSensorList = (action$) => {
  return action$.pipe(
    ofType(FETCH_PATIENT_SENSOR_REQUEST),
    mergeMap(({ payload }) => {
      return guestMapService.getPatientSensorList(payload).pipe(
        map((response) => {
          return dispatch(FETCH_PATIENT_SENSOR_SUCCESS, JSON.parse(response.response));
        }),
        catchError((error) => {
          return dispatch(FETCH_PATIENT_SENSOR_ERROR, error);
        })
      );
    })
  );
};

export const getGuestsList = (action$) => {
  return action$.pipe(
    ofType(FETCH_GUESTS_REQUEST),
    mergeMap(({ payload }) => {
      return guestMapService.getGuestsList(payload).pipe(
        mergeMap((response) => {
          return [dispatch(FETCH_GUESTS_SUCCESS, JSON.parse(response.response))];
        }),
        catchError(() => {
          return [dispatch(FETCH_GUESTS_ERROR)];
        })
      );
    })
  );
};

export const getDevicePropSetId = (action$) => {
  return action$.pipe(
    ofType(FETCH_DEVICE_PROPSETID_REQUEST),
    mergeMap(({ payload }) => {
      return guestMapService.getDevicePropSetId(payload).pipe(
        map((res) => {
          let devicePropSetID = "";
          const response = JSON.parse(res);
          if (response && response?.length) {
            devicePropSetID = response[0]?.devicePropertySet[0]?.devicePropertySetId;
          }
          return dispatch(FETCH_DEVICE_PROPSETID_SUCCESS, devicePropSetID);
        }),
        catchError((error) => {
          console.log(error, 'Error in getDevicePropSetId');
        })
      );
    })
  );
};


export const updateGuest = (action$) => {
  return action$.pipe(
    ofType(UPDATE_GUESTMAPPING_REQUEST),
    mergeMap(({ payload }) => {
      return guestMapService.updateGuest(payload).pipe(
        map((res) => {
          return dispatch(UPDATE_GUESTMAPPING_SUCCESS);
        }),
        catchError((error) => {
          return dispatch(UPDATE_GUESTMAPPING_ERROR, error);
        })
      );
    })
  );
};

export const onInviteGuest = (action$) => {
  return action$.pipe(
    ofType(SEND_INVITE_REQUEST),
    mergeMap(({ payload }) => {
      return guestMapService.onInviteGuest(payload).pipe(
        map((res) => {
          return dispatch(SEND_INVITE_SUCCESS, res);
        }),
        catchError((error) => {
          console.log(error, 'Error in Invite Guest');
          let msg = "",
            isNewAccount = false;
          if (error.status == 412) {
            msg = "Observer already exists";
          } else if (error.status == 404) {
            msg = "User not found in create guest invite";
            isNewAccount = true;
          } else {
            msg = "Some error, please try again";
          }
          return [
            dispatch(SEND_INVITE_ERROR, isNewAccount),
            dispatch(ERROR_NOTIFICATION_REQUEST, {response: {message: msg}}),
          ]
        })
      );
    })
  );
};

export const addGuestMap = (action$) => {
  return action$.pipe(
    ofType(ADD_GUESTMAP_REQUEST),
    mergeMap(({ payload }) => {
      return guestMapService.updateGuest(payload).pipe(
        mergeMap((res) => {
          return [dispatch(ADD_GUESTMAP_SUCCESS, res)];
        }),
        catchError((error) => {
          return [dispatch(ADD_GUESTMAP_ERROR, error)];
        })
      );
    })
  );
};
