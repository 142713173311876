import { ofType } from "redux-observable";
import { mergeMap, map, catchError } from "rxjs/operators";

import { dispatch } from "../../common/helpers";
import { patientDeviceService } from "../../services";

import {
  FETCH_PATIENT_DEVICE_REQUEST,
  FETCH_PATIENT_DEVICE_SUCCESS,
  FETCH_PATIENT_DEVICE_ERROR,
} from "./actions";

export const getPatientDeviceList = (action$) => {
  return action$.pipe(
    ofType(FETCH_PATIENT_DEVICE_REQUEST),
    mergeMap(({ payload }) => {
      return patientDeviceService.getPatientDeviceList(payload).pipe(
        map((response) => {
          return dispatch(FETCH_PATIENT_DEVICE_SUCCESS, JSON.parse(response));
        }),
        catchError((error) => {
          return dispatch(FETCH_PATIENT_DEVICE_ERROR, error);
        })
      );
    })
  );
};
