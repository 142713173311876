import {
  FETCH_LOGIN_REQUEST,
  FETCH_LOGIN_SUCCESS,
  FETCH_LOGIN_ERROR,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  FETCH_USER_LIST_REQUEST,
  FETCH_USER_LIST_SUCCESS,
  FETCH_USER_LIST_ERROR,
  USER_DETAIL_REQUEST,
  USER_DETAIL_SUCCESS,
  CREATE_USER_SUCCESS,
  CREATE_PRACTICE_USER_REQUEST,
  CREATE_PRACTICE_USER_SUCCESS,
  CREATE_PRACTICE_USER_ERROR,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  DELETE_USER_PRACTICE_REQUEST,
  DELETE_USER_PRACTICE_SUCCESS,
  DELETE_USER_PRACTICE_ERROR,
  CREATE_DEVICE_USER_REQUEST,
  CREATE_DEVICE_USER_SUCCESS,
  CREATE_DEVICE_USER_ERROR,
  PATIENT_DETAIL_REQUEST,
  PATIENT_DETAIL_SUCCESS,
  FETCH_CAREGIVER_LIST_ERROR,
  FETCH_CAREGIVER_LIST_SUCCESS,
  FETCH_CAREGIVER_LIST_REQUEST,
  UPDATE_ROLE_REQUEST,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_ERROR,
  UPDATE_GUEST_REQUEST,
  UPDATE_GUEST_SUCCESS,
  UPDATE_GUEST_ERROR,
  UPDATE_DEVICE_USER_REQUEST,
  UPDATE_DEVICE_USER_SUCCESS,
  UPDATE_DEVICE_USER_ERROR,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  UPDATE_PRACTICE_USER_REQUEST,
  UPDATE_PRACTICE_USER_SUCCESS,
  UPDATE_PRACTICE_USER_ERROR,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  UPDATE_TERM_AND_CONDITION_REQUEST,
  UPDATE_TERM_AND_CONDITION_SUCCESS,
  UPDATE_TERM_AND_CONDITION_ERROR,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  FETCH_USER_CUSTOM_REQUEST,
  FETCH_USER_CUSTOM_SUCCESS,
  FETCH_USER_CUSTOM_ERROR,
  BACK_TO_OTP_SUCCESS,
  RESET_PASSWORD_UPDATE_REQUEST,
  RESET_PASSWORD_UPDATE_SUCCESS,
  RESET_PASSWORD_UPDATE_ERROR,
  UPDATE_RESIDENT_USER_REQUEST,
  UPDATE_RESIDENT_USER_ERROR,
  DELETE_RESIDENT_DEVICE_REQUEST,
  DELETE_RESIDENT_DEVICE_SUCCESS,
  DELETE_RESIDENT_DEVICE_ERROR,
  DELETE_RESIDENT_DEVICE_ONLY_REQUEST,
  DELETE_RESIDENT_DEVICE_ONLY_SUCCESS,
  DELETE_RESIDENT_DEVICE_ONLY_ERROR,
  GET_INVITE_EMAIL_REQUEST,
  GET_INVITE_EMAIL_SUCCESS,
  GET_INVITE_EMAIL_ERROR,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  ACTIVATE_USER_REQUEST,
  ACTIVATE_USER_SUCCESS,
  ACTIVATE_USER_ERROR,
  RESEND_OTP_REQUEST,
  RESEND_OTP_SUCCESS,
  RESEND_OTP_ERROR,
  ACCEPT_TERMS_REQUEST,
  ACCEPT_TERMS_SUCCESS,
  ACCEPT_TERMS_ERROR,
  ACCEPT_TERMS_USER_REQUEST,
  ACCEPT_TERMS_USER_SUCCESS,
  ACCEPT_TERMS_USER_ERROR,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_ERROR,
} from "./actions";
import { REDUCERS } from "../../common/constants";
import { createReducer } from "../../common/helpers";

const schema = {
  loading: false,
  caregiverLoader: false,
  error: false,
  user: {},
  userList: {
    loading: false,
    error: false,
    totalPages: 0,
    totalElements: 0,
    users: [],
  },
  userDeviceList: {
    totalPages: 0,
    totalElements: 0,
    users: [],
  },
  userDetail: {
    loading: false,
    error: false,
    user: {},
    custom: [],
  },
  success: false,
  caregiveList: [],
  guestList: [],
  sentResetPassword: false,
  resetPasswordStatus: false,
  inviteData: {},
  userRegisteredObj: {
    userRegistered: false,
    msg: ''
  },
  userActivate: false,
  codeResent: false,
  acceptTermsUser: false,
  acceptTerms: false
};

const reducer = {
  // Program Options
  [FETCH_LOGIN_REQUEST]: (state) => {
    return {
      ...state,
      ...REDUCERS.LOADING,
      sentResetPassword: false,
      resetPasswordStatus: false
    };
  },
  [FETCH_LOGIN_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      user: payload.response,
      sentResetPassword: false,
      resetPasswordStatus: false
    };
  },
  [FETCH_LOGIN_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
    resetPasswordStatus: false
  }),
  [RESET_PASSWORD_REQUEST]: (state) => {
    return {
      ...state,
      ...REDUCERS.LOADING,
      sentResetPassword: false,
      resetPasswordStatus: false
    };
  },
  [RESET_PASSWORD_SUCCESS]: (state) => ({
    ...state,
    ...REDUCERS.SUCCESS,
    sentResetPassword: true,
    resetPasswordStatus: false
  }),
  [RESET_PASSWORD_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
    sentResetPassword: false,
    resetPasswordStatus: false
  }),
  [RESET_PASSWORD_UPDATE_REQUEST]: (state) => {
    return {
      ...state,
      ...REDUCERS.LOADING,
      resetPasswordStatus: false
    };
  },
  [RESET_PASSWORD_UPDATE_SUCCESS]: (state) => ({
    ...state,
    ...REDUCERS.SUCCESS,
    sentResetPassword: false,
    resetPasswordStatus: true
  }),
  [RESET_PASSWORD_UPDATE_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
    resetPasswordStatus: false
  }),
  [BACK_TO_OTP_SUCCESS]: (state) => ({
    ...state,
    sentResetPassword: false
  }),
  [FETCH_USER_LIST_REQUEST]: (state) => {
    return {
      ...state,
      success: false,
      userList: {
        ...state.userList,
        ...REDUCERS.LOADING,
      },
      userDetail: {
        loading: false,
        error: false,
        user: {},
        custom: [],
      },
    };
  },
  [FETCH_USER_LIST_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      userList: {
        ...state.userList,
        ...REDUCERS.SUCCESS,
        totalPages: payload.totalPages,
        totalElements: payload.totalElements,
        users: payload.content,
      },
    };
  },
  [FETCH_USER_LIST_ERROR]: (state) => {
    return {
      ...state,
      userList: {
        ...state.userList,
        ...REDUCERS.ERROR,
        totalPages: 0,
        totalElements: 0,
        users: [],
      },
    };
  },
  [USER_DETAIL_REQUEST]: (state) => {
    return {
      ...state,
      success: false,
      userDetail: {
        ...state.userDetail,
        ...REDUCERS.LOADING,
      },
    };
  },
  [USER_DETAIL_SUCCESS]: (state, { payload }) => {
    const customUserFieldData = payload.customObject.reduce((acc, val) => {
      let obj = {};
      obj[val.field.fieldId] = val.fieldData;
      return { ...acc, ...obj };
    }, {});
    return {
      ...state,
      userDetail: {
        ...state.userDetail,
        ...REDUCERS.SUCCESS,
        user: {
          ...payload.userObject,
          role: payload.userObject.roles[0].role,
          practice: payload.practiceObject,
          patient: payload.patient,
          ...payload.userObject.contactInfo,
          ...customUserFieldData,
        },
        custom: payload.customObject,
      },
    };
  },
  [PATIENT_DETAIL_REQUEST]: (state) => {
    return {
      ...state,
      success: false,
      userDetail: {
        ...state.userDetail,
        ...REDUCERS.LOADING,
      },
    };
  },
  [PATIENT_DETAIL_SUCCESS]: (state, { payload }) => {
    const customUserFieldData = payload.customObject.reduce((acc, val) => {
      let obj = {};
      obj[val.field.fieldId] = val.fieldData;
      return { ...acc, ...obj };
    }, {});
    return {
      ...state,
      userDetail: {
        ...state.userDetail,
        ...REDUCERS.SUCCESS,
        user: {
          ...payload.userObject,
          role: payload.userObject.roles[0].role,
          practice: payload.practiceObject,
          device: payload.deviceObject,
          linkedDevices: payload.linkedDevices,
          patient: payload.patient,
          ...payload.userObject.contactInfo,
          ...customUserFieldData,
        },
        custom: payload.customObject,
      },
    };
  },
  [CREATE_USER_SUCCESS]: (state) => {
    return {
      ...state,
      success: true,
    };
  },
  [CREATE_PRACTICE_USER_REQUEST]: (state) => {
    return {
      ...state,
      ...REDUCERS.LOADING,
    };
  },
  [CREATE_PRACTICE_USER_SUCCESS]: (state) => {
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      success: true,
    };
  },
  [CREATE_PRACTICE_USER_ERROR]: (state) => {
    return {
      ...state,
      ...REDUCERS.ERROR,
    };
  },
  [DELETE_USER_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [DELETE_USER_SUCCESS]: (state) => ({
    ...state,
    ...REDUCERS.SUCCESS,
  }),
  [DELETE_USER_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [DELETE_USER_PRACTICE_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [DELETE_USER_PRACTICE_SUCCESS]: (state) => ({
    ...state,
    ...REDUCERS.SUCCESS,
  }),
  [DELETE_USER_PRACTICE_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [CREATE_DEVICE_USER_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [CREATE_DEVICE_USER_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [CREATE_DEVICE_USER_SUCCESS]: (state) => {
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      success: true,
    };
  },
  [FETCH_CAREGIVER_LIST_ERROR]: (state) => ({
    ...state,
    caregiverLoader: false,
  }),
  [FETCH_CAREGIVER_LIST_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      caregiverLoader: false,
      caregiveList: payload.filter((val) => {
        const isGuest = val.customData.find(
          (data) =>
            data.field.name === "Guest Account" && data.fieldData !== "Yes"
        );
        return isGuest ? val : false;
      }),
      guestList: payload.filter((val) => {
        const isGuest = val.customData.find(
          (data) =>
            data.field.name === "Guest Account" && data.fieldData === "Yes"
        );
        return isGuest ? val : false;
      }),
    };
  },
  [FETCH_CAREGIVER_LIST_REQUEST]: (state) => ({
    ...state,
    caregiverLoader: true,
    caregiveList: [],
    guestList: [],
  }),
  [UPDATE_ROLE_REQUEST]: (state) => ({
    ...state,
    caregiverLoader: true,
  }),
  [UPDATE_ROLE_SUCCESS]: (state) => ({
    ...state,
    caregiverLoader: false,
  }),
  [UPDATE_ROLE_ERROR]: (state) => ({
    ...state,
    caregiverLoader: false,
  }),
  [UPDATE_GUEST_REQUEST]: (state) => ({
    ...state,
    caregiverLoader: true,
  }),
  [UPDATE_GUEST_SUCCESS]: (state) => ({
    ...state,
    caregiverLoader: false,
  }),
  [UPDATE_GUEST_ERROR]: (state) => ({
    ...state,
    caregiverLoader: false,
  }),
  [UPDATE_DEVICE_USER_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [UPDATE_DEVICE_USER_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [UPDATE_DEVICE_USER_SUCCESS]: (state) => {
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      success: true,
    };
  },
  [UPDATE_USER_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.SUCCESS,
  }),
  [UPDATE_USER_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [UPDATE_USER_SUCCESS]: (state) => ({
    ...state,
    ...REDUCERS.SUCCESS,
    success: true,
  }),
  [UPDATE_PRACTICE_USER_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [UPDATE_PRACTICE_USER_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [UPDATE_PRACTICE_USER_SUCCESS]: (state) => ({
    ...state,
    ...REDUCERS.SUCCESS,
    success: true,
  }),
  [CHANGE_PASSWORD_REQUEST]: (state) => ({
    ...state,
    userList: {
      ...state.userList,
      ...REDUCERS.LOADING,
    },
  }),
  [CHANGE_PASSWORD_ERROR]: (state) => ({
    ...state,
    userList: {
      ...state.userList,
      ...REDUCERS.SUCCESS,
    },
  }),
  [CHANGE_PASSWORD_SUCCESS]: (state) => ({
    ...state,
    userList: {
      ...state.userList,
      ...REDUCERS.SUCCESS,
    },
  }),
  [UPDATE_TERM_AND_CONDITION_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [UPDATE_TERM_AND_CONDITION_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [UPDATE_TERM_AND_CONDITION_SUCCESS]: (state) => ({
    ...state,
    ...REDUCERS.SUCCESS,
    success: true,
  }),
  [UPDATE_PASSWORD_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [UPDATE_PASSWORD_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [UPDATE_PASSWORD_SUCCESS]: (state) => ({
    ...state,
    ...REDUCERS.SUCCESS,
    success: true,
  }),
  [FETCH_USER_CUSTOM_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
    userDeviceList: {
      totalPages: 0,
      totalElements: 0,
      users: [],
    }
  }),
  [FETCH_USER_CUSTOM_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
    userDeviceList: {
      totalPages: 0,
      totalElements: 0,
      users: [],
    }
  }),
  [FETCH_USER_CUSTOM_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      userDeviceList: {
        ...state.userDeviceList,
        totalPages: payload.totalPages,
        totalElements: payload.totalElements,
        users: payload.content,
      },
    };
  },
  [UPDATE_RESIDENT_USER_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [UPDATE_RESIDENT_USER_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [DELETE_RESIDENT_DEVICE_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [DELETE_RESIDENT_DEVICE_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [DELETE_RESIDENT_DEVICE_SUCCESS]: (state) => ({
    ...state,
    ...REDUCERS.SUCCESS,
  }),
  [DELETE_RESIDENT_DEVICE_ONLY_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [DELETE_RESIDENT_DEVICE_ONLY_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
  [DELETE_RESIDENT_DEVICE_ONLY_SUCCESS]: (state) => ({
    ...state,
    ...REDUCERS.SUCCESS,
  }),
  [GET_INVITE_EMAIL_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
  }),
  [GET_INVITE_EMAIL_SUCCESS]: (state, {payload}) => ({
    ...state,
    ...REDUCERS.ERROR,
    inviteData: payload,
  }),
  [GET_INVITE_EMAIL_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.SUCCESS,
  }),
  [REGISTER_USER_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
    userRegisteredObj: {
      userRegistered: false,
      msg: ''
    }
  }),
  [REGISTER_USER_SUCCESS]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
    userRegisteredObj: {
      userRegistered: true,
      msg: ''
    }
  }),
  [REGISTER_USER_ERROR]: (state, {payload}) => ({
      ...state,
      ...REDUCERS.SUCCESS,
      userRegisteredObj: {
        userRegistered: false,
        msg: payload
      }
  }),
  [ACTIVATE_USER_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
    userActivate: false
  }),
  [ACTIVATE_USER_SUCCESS]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
    userActivate: true
  }),
  [ACTIVATE_USER_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.SUCCESS,
    userActivate: false
  }),
  [RESEND_OTP_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
    codeResent: false
  }),
  [RESEND_OTP_SUCCESS]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
    codeResent: true
  }),
  [RESEND_OTP_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.SUCCESS,
    codeResent: false
  }),
  [ACCEPT_TERMS_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
    acceptTerms: false
  }),
  [ACCEPT_TERMS_SUCCESS]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
    acceptTerms: true
  }),
  [ACCEPT_TERMS_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.SUCCESS,
    acceptTerms: false
  }),
  [ACCEPT_TERMS_USER_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
    acceptTermsUser: false
  }),
  [ACCEPT_TERMS_USER_SUCCESS]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
    acceptTermsUser: true
  }),
  [ACCEPT_TERMS_USER_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.SUCCESS,
    acceptTermsUser: false
  }),
  [REFRESH_TOKEN_REQUEST]: (state) => ({
    ...state,
    ...REDUCERS.LOADING,
    refreshToken: ""
  }),
  [REFRESH_TOKEN_SUCCESS]: (state, {payload}) => ({
    ...state,
    ...REDUCERS.ERROR,
    refreshToken: payload
  }),
  [REFRESH_TOKEN_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.SUCCESS,
    refreshToken: ""
  }),
};
  
export default createReducer(schema, reducer);
