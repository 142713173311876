import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import React, {useEffect} from "react";
import { useHistory } from "react-router";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AddAlert from "@material-ui/icons/AddAlert";

import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import LoginPage from "views/Pages/LoginPage.js";
import pricing from "assets/img/bg-pricing.jpeg";
import register from "assets/img/register.jpeg";
import error from "assets/img/clint-mckoy.jpg";
import Signup from 'views/Pages/Signup.js';
import login from "assets/img/login.jpeg";
import lock from "assets/img/lock.jpeg";

import { RESET_NOTIFICATION_DATA } from "../redux/notifications/actions";
import { useComposeDispatcher } from "../common/hooks";

const useStyles = makeStyles(styles);

export default function Pages() {
  const { success, message, error: alertError } = useSelector(({ notifications }) => notifications);
  const [resetNotificationData] = useComposeDispatcher(RESET_NOTIFICATION_DATA);

  const history = useHistory();
  const wrapper = React.createRef();
  const classes = useStyles();

  useEffect(() => {
    if (localStorage.getItem("user-details")) {
      history.push("/admin/dashboard");
    }
    document.body.style.overflow = "unset";
  }, []);

  const getBgImage = () => {
    if (window.location.pathname.indexOf("/auth/signup") !== -1) {
      return register;
    } else if (window.location.pathname.indexOf("/auth/login") !== -1) {
      return login;
    } else if (window.location.pathname.indexOf("/auth/pricing-page") !== -1) {
      return pricing;
    } else if (window.location.pathname.indexOf("/auth/lock-screen-page") !== -1) {
      return lock;
    } else if (window.location.pathname.indexOf("/auth/error-page") !== -1) {
      return error;
    }
  };

  return (
    <div>
      <div className={classes.wrapper} ref={wrapper}>
        <div className={classes.fullPage} style={{ backgroundImage: "url(" + getBgImage() + ")" }}>
          <Switch>
            <Route path="/auth/login" component={LoginPage} />
            <Route exact path="/auth/signup" component={Signup} />
            <Redirect from="/auth" to="/auth/login" />
          </Switch>
        </div>
      </div>
      <Snackbar
        place="tr"
        color={success ? "success" : "danger"}
        icon={AddAlert}
        message={message}
        open={success || alertError}
        closeNotification={() => resetNotificationData()}
        close
      />
    </div>
  );
}
