import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import countryList from "react-select-country-list";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "react-phone-input-2/lib/style.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import Loader from "components/Loader/Loader.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";

import formStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import {
  CREATE_COMPANY_REQUEST,
  COMPANY_DETAIL_REQUEST,
  UPDATE_COMPANY_REQUEST,
} from "../../redux/company/actions";
import { useComposeDispatcher } from "../../common/hooks";

const tenantId = "ATS-DEV";

const styles = () => ({
  labelFocused: {
    color: "#E73F3B !important",
  },
  inputFocused: {
    "&:before": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "&:after": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "& input": {
      borderBottom: "#E73F3B !important",
    },
  },
  inputRoot: {
    color: "red",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
});

const InputBox = withStyles(styles)((props) => {
  const {
    labelText,
    helperText,
    classes,
    value,
    id,
    info,
    updateInfo,
    type,
    required,
  } = props;
  return (
    <TextField
      label={labelText}
      helperText={helperText}
      fullWidth
      required={required ? required : false}
      type={type ? type : "text"}
      margin="normal"
      color="primary"
      value={value}
      onChange={(e) => {
        let obj = {};
        obj[id] = e.target.value;
        updateInfo({ ...info, ...obj });
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          focused: classes.labelFocused,
        },
      }}
      InputProps={{
        classes: {
          root: classes.root,
          focused: classes.inputFocused,
        },
      }}
    />
  );
});

const SelectBox = withStyles(styles)((props) => {
  const {
    disabled,
    labelText,
    classes,
    value,
    id,
    info,
    updateInfo,
    options,
    required,
    customField,
  } = props;
  return (
    <TextField
      select
      fullWidth
      disabled={disabled || false}
      label={labelText}
      value={value}
      required={required ? required : false}
      margin="normal"
      color="primary"
      onChange={(e) => {
        if (id === "role") {
          const guestAccountDetail = customField.find(
            (val) => val.name === "Guest Account"
          );
          let obj = {};
          obj[id] = e.target.value;
          obj[guestAccountDetail.fieldId] =
            e.target.value === "Guest" ? "Yes" : "No";
          updateInfo({ ...info, ...obj });
        } else {
          let obj = {};
          obj[id] = e.target.value;
          updateInfo({ ...info, ...obj });
        }
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          focused: classes.labelFocused,
        },
      }}
      InputProps={{
        classes: {
          root: classes.root,
          focused: classes.inputFocused,
        },
      }}
    >
      {options}
    </TextField>
  );
});

const useStyles = makeStyles(formStyle);

export default function AddUser() {
  const history = useHistory();
  const { id } = useParams();
  const { company } = useSelector((state) => ({
    company: state.company,
  }));
  const [userInfo, setUserInfo] = React.useState({});
  const [alertMessage, setAlertMessage] = React.useState("");
  const [toastStatus, setToastStatus] = React.useState(false);
  const [refreshData, setRefreshData] = React.useState(Date.now());

  const [createCompany] = useComposeDispatcher(CREATE_COMPANY_REQUEST);
  const [updateCompany] = useComposeDispatcher(UPDATE_COMPANY_REQUEST);
  const [getCompanyDetailsById] = useComposeDispatcher(COMPANY_DETAIL_REQUEST);

  const classes = useStyles();

  useEffect(() => {
    id && getCompanyDetailsById(id);
  }, [id]);

  if (id && company.companyDetail.data.name && !userInfo.name) {
    setUserInfo(company.companyDetail.data);
    setRefreshData(Date.now());
  }

  let validateForm = () => {
    setToastStatus(false);
    if (
      !userInfo?.name ||
      !userInfo?.emailAddress ||
      !userInfo?.streetAddress1 ||
      !userInfo?.city ||
      !userInfo?.state ||
      !userInfo?.country ||
      !userInfo?.zipcode ||
      !userInfo?.primaryPhone
    ) {
      setAlertMessage("Fields mark with asterisk are required");
      setToastStatus(true);
    } else {
      const emailReg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      if (!emailReg.test(userInfo?.emailAddress)) {
        setAlertMessage("Please enter valid email");
        setToastStatus(true);
      } else {
        const contactInfo = {
          streetAddress1: userInfo.streetAddress1,
          streetAddress2: userInfo.streetAddress2,
          city: userInfo.city,
          state: userInfo.state,
          country: userInfo.country,
          zipcode: userInfo.zipcode,
          primaryPhone: userInfo.primaryPhone,
          secondaryPhone: userInfo.secondaryPhone,
        };

        const companyObject = {
          customData: null,
          supplier: {
            name: userInfo.name,
            emailAddress: userInfo.emailAddress,
            contactInfo,
            tenant: { tenantId },
          },
        };
        if (!id) {
          createCompany({ companyObject });
        } else {
          companyObject.supplier.supplierId = userInfo.supplierId;
          companyObject.supplier.status = userInfo.status;
          companyObject.customData = company.companyDetail.data.customData;
          updateCompany({ companyObject });
        }
      }
    }
  };
  if (company.success) {
    history.push(`/admin/company`);
  }

  return (
    <GridContainer key={refreshData}>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="danger" icon>
            <CardIcon color="danger">
              <Person />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>General Information</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <InputBox
                  labelText="Name"
                  value={userInfo.name}
                  id="name"
                  required={true}
                  info={userInfo}
                  updateInfo={setUserInfo}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <InputBox
                  labelText="Email"
                  id="emailAddress"
                  type="email"
                  value={userInfo.emailAddress}
                  required={true}
                  info={userInfo}
                  updateInfo={setUserInfo}
                />
              </GridItem>
              {id && (
                <GridItem xs={12} sm={12} md={4}>
                  <SelectBox
                    id="status"
                    labelText="Status"
                    required={true}
                    value={userInfo?.status}
                    info={userInfo}
                    updateInfo={setUserInfo}
                    options={["Active", "Inactive"].map((val) => (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                        key={val}
                        value={val}
                      >
                        {val}
                      </MenuItem>
                    ))}
                  />
                </GridItem>
              )}
            </GridContainer>
          </CardBody>
        </Card>
        <Card>
          <CardHeader color="danger" icon>
            <CardIcon color="danger">
              <ContactPhoneIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Contact Information</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <InputBox
                  required={true}
                  labelText="Street Address 1"
                  id="streetAddress1"
                  value={userInfo?.streetAddress1}
                  info={userInfo}
                  updateInfo={setUserInfo}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <InputBox
                  labelText="Street Address 2"
                  id="streetAddress2"
                  value={userInfo?.streetAddress2}
                  info={userInfo}
                  updateInfo={setUserInfo}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <InputBox
                  required={true}
                  labelText="City"
                  id="city"
                  value={userInfo?.city}
                  info={userInfo}
                  updateInfo={setUserInfo}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <InputBox
                  required={true}
                  labelText="State"
                  id="state"
                  value={userInfo?.state}
                  info={userInfo}
                  updateInfo={setUserInfo}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Box paddingTop={2}>
                  <Autocomplete
                    fullWidth
                    options={countryList().getData()}
                    className={classes.inputColor}
                    disableCloseOnSelect
                    color="primary"
                    getOptionLabel={(option) =>
                      option.label ? option.label : option
                    }
                    value={
                      userInfo?.country
                        ? countryList()
                            .getData()
                            .find((data) => data.value === userInfo?.country)
                        : null
                    }
                    onChange={(event, val) => {
                      const field = { ...userInfo };
                      if (val) {
                        field.country = val.value;
                      } else {
                        delete field.country;
                      }
                      setUserInfo(field);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country"
                        required={true}
                        InputLabelProps={{
                          shrink: true,
                          classes: {
                            focused: classes.labelFocused,
                          },
                        }}
                        SelectProps={{
                          classes: {
                            focused: classes.labelFocused,
                          },
                        }}
                      />
                    )}
                  />
                </Box>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <InputBox
                  required={true}
                  labelText="Zipcode"
                  id="zipcode"
                  value={userInfo?.zipcode}
                  info={userInfo}
                  updateInfo={setUserInfo}
                />
              </GridItem>
            </GridContainer>
            <GridContainer style={{ marginTop: "25px" }}>
              <GridItem xs={12} sm={12} md={4}>
                <InputLabel
                  style={{
                    fontSize: "12px",
                    color: "rgba(0, 0, 0, 0.54)",
                  }}
                >
                  Primary Phone *
                </InputLabel>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <PhoneInput
                    country={"au"}
                    containerStyle={{ zIndex: 4 }}
                    inputStyle={{
                      border: "0px",
                      borderBottom: "1px solid #d2d2d2",
                      borderRadius: "0px",
                    }}
                    buttonStyle={{
                      border: "0px",
                      background: "none",
                    }}
                    enableSearch={true}
                    placeholder="212345678"
                    value={userInfo?.primaryPhone}
                    onChange={(val) => {
                      setUserInfo({ ...userInfo, primaryPhone: val });
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={4} mt={2}>
                <InputLabel
                  style={{
                    fontSize: "12px",
                    color: "rgba(0, 0, 0, 0.54)",
                  }}
                >
                  Secondary Phone
                </InputLabel>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <PhoneInput
                    country={"au"}
                    containerStyle={{ zIndex: 4 }}
                    inputStyle={{
                      border: "0px",
                      borderBottom: "1px solid #d2d2d2",
                      borderRadius: "0px",
                    }}
                    buttonStyle={{
                      border: "0px",
                      background: "none",
                    }}
                    enableSearch={true}
                    placeholder="212345678"
                    value={userInfo?.secondaryPhone}
                    onChange={(val) => {
                      setUserInfo({ ...userInfo, secondaryPhone: val });
                    }}
                  />
                </FormControl>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Box textAlign="right">
          <Button onClick={() => validateForm()} color="danger">
            {id ? "Save" : "Submit"}
          </Button>
          <Button
            onClick={() => {
              history.push(`/admin/company`);
            }}
          >
            Cancel
          </Button>
        </Box>
      </GridItem>
      <Snackbar
        place="tr"
        color="danger"
        icon={AddAlert}
        message={alertMessage}
        open={toastStatus}
        closeNotification={() => setToastStatus(false)}
        close
      />
      <Loader status={company.loading || company.companyDetail.loading} />
    </GridContainer>
  );
}
