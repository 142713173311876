import { ofType } from "redux-observable";
import { forkJoin, of } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";
import moment from "moment";

import { dispatch } from "../../common/helpers";
import {
  usersService,
  practiceService,
  deviceService,
  customFieldService,
} from "../../services";
import { MESSAGES } from "../../common/constants";

import {
  FETCH_LOGIN_REQUEST,
  FETCH_LOGIN_SUCCESS,
  FETCH_LOGIN_ERROR,
  FETCH_USER_LIST_REQUEST,
  FETCH_USER_LIST_SUCCESS,
  FETCH_USER_LIST_ERROR,
  USER_DETAIL_REQUEST,
  USER_DETAIL_SUCCESS,
  USER_DETAIL_ERROR,
  CREATE_PRACTICE_USER_REQUEST,
  CREATE_PRACTICE_USER_SUCCESS,
  CREATE_PRACTICE_USER_ERROR,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  START_PRACTICE_REQUEST,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  DELETE_USER_PRACTICE_REQUEST,
  DELETE_USER_PRACTICE_SUCCESS,
  DELETE_USER_PRACTICE_ERROR,
  CREATE_DEVICE_USER_REQUEST,
  CREATE_DEVICE_USER_SUCCESS,
  CREATE_DEVICE_USER_ERROR,
  PATIENT_DETAIL_REQUEST,
  PATIENT_DETAIL_SUCCESS,
  PATIENT_DETAIL_ERROR,
  DELETE_USER_DEVICE_REQUEST,
  DELETE_USER_DEVICE_SUCCESS,
  DELETE_USER_DEVICE_ERROR,
  FETCH_CAREGIVER_LIST_ERROR,
  FETCH_CAREGIVER_LIST_SUCCESS,
  FETCH_CAREGIVER_LIST_REQUEST,
  UPDATE_ROLE_REQUEST,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_ERROR,
  UPDATE_GUEST_REQUEST,
  UPDATE_GUEST_SUCCESS,
  UPDATE_GUEST_ERROR,
  UPDATE_DEVICE_USER_REQUEST,
  UPDATE_DEVICE_USER_ERROR,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  UPDATE_PRACTICE_USER_REQUEST,
  UPDATE_PRACTICE_USER_SUCCESS,
  UPDATE_PRACTICE_USER_ERROR,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  UPDATE_TERM_AND_CONDITION_REQUEST,
  UPDATE_TERM_AND_CONDITION_SUCCESS,
  UPDATE_TERM_AND_CONDITION_ERROR,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  FETCH_USER_CUSTOM_REQUEST,
  FETCH_USER_CUSTOM_SUCCESS,
  FETCH_USER_CUSTOM_ERROR,
  RESET_PASSWORD_UPDATE_REQUEST,
  RESET_PASSWORD_UPDATE_SUCCESS,
  RESET_PASSWORD_UPDATE_ERROR,
  BACK_TO_OTP_REQUEST,
  BACK_TO_OTP_SUCCESS,
  UPDATE_RESIDENT_USER_REQUEST,
  UPDATE_RESIDENT_USER_ERROR,
  DELETE_RESIDENT_DEVICE_REQUEST,
  DELETE_RESIDENT_DEVICE_SUCCESS,
  DELETE_RESIDENT_DEVICE_ERROR,
  DELETE_RESIDENT_DEVICE_ONLY_REQUEST,
  DELETE_RESIDENT_DEVICE_ONLY_SUCCESS,
  DELETE_RESIDENT_DEVICE_ONLY_ERROR,
  GET_INVITE_EMAIL_REQUEST,
  GET_INVITE_EMAIL_SUCCESS,
  GET_INVITE_EMAIL_ERROR,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  ACTIVATE_USER_REQUEST,
  ACTIVATE_USER_SUCCESS,
  ACTIVATE_USER_ERROR,
  RESEND_OTP_REQUEST,
  RESEND_OTP_SUCCESS,
  RESEND_OTP_ERROR,
  ACCEPT_TERMS_REQUEST,
  ACCEPT_TERMS_SUCCESS,
  ACCEPT_TERMS_ERROR,
  ACCEPT_TERMS_USER_REQUEST,
  ACCEPT_TERMS_USER_SUCCESS,
  ACCEPT_TERMS_USER_ERROR,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_ERROR,
} from "./actions";

import {
  SUCCESS_NOTIFICATION_REQUEST,
  ERROR_NOTIFICATION_REQUEST,
} from "../notifications/actions";

export const getCaregiverList = (action$) => {
  return action$.pipe(
    ofType(FETCH_CAREGIVER_LIST_REQUEST),
    mergeMap(({ payload }) => {
      let obj = {
        role: "PracticeUser",
      };
      if (payload) {
        obj["supplierId"] = payload;
      }
      return usersService.getUserList(obj).pipe(
        mergeMap((response) => {
          const usersCustomReq = JSON.parse(response).content.reduce(
            (acc, val) => {
              return [...acc, usersService.getUserCustomDataById(val.userId)];
            },
            []
          );
          return forkJoin(usersCustomReq).pipe(
            mergeMap((result) => {
              const finalData = JSON.parse(response).content.reduce(
                (acc, val, index) => {
                  val.customData = JSON.parse(result[index]);
                  return [...acc, val];
                },
                []
              );
              return [dispatch(FETCH_CAREGIVER_LIST_SUCCESS, finalData)];
            }),
            catchError((error) => [dispatch(FETCH_CAREGIVER_LIST_ERROR, error)])
          );
        }),
        catchError((error) => [dispatch(FETCH_CAREGIVER_LIST_ERROR, error)])
      );
    })
  );
};

export const updateUserPassword = (action$) => {
  return action$.pipe(
    ofType(CHANGE_PASSWORD_REQUEST),
    mergeMap(({ payload }) => {
      return usersService.updateUserPassword(payload).pipe(
        mergeMap((data) => {
          return [
            dispatch(CHANGE_PASSWORD_SUCCESS, data),
            dispatch(SUCCESS_NOTIFICATION_REQUEST, {
              message: "Password updated successfully",
            }),
          ];
        }),
        catchError((err) => {
          return [
            dispatch(CHANGE_PASSWORD_ERROR, err),
            dispatch(ERROR_NOTIFICATION_REQUEST, {
              response: {
                message: err.response,
              },
            }),
          ];
        })
      );
    })
  );
};

export const login = (action$) => {
  return action$.pipe(
    ofType(FETCH_LOGIN_REQUEST),
    mergeMap(({ payload }) => {
      return usersService.login(payload).pipe(
        mergeMap((data) => {
          data.response = JSON.parse(data.response);
          data.response.token = data.xhr
            .getAllResponseHeaders()
            .split(":")[1]
            .replace("Bearer", "")
            .replace("cache-control", "")
            .trim();
          localStorage.setItem("user-details", JSON.stringify(data.response));
          return usersService.getUserCustomDataById(data.response.userId).pipe(
            mergeMap((customData) => {
              data.response.customData = JSON.parse(customData);
              localStorage.setItem("user-custom-details", JSON.stringify(data.response.customData));
              return customFieldService.getCustomFieldList("UserProfile").pipe(
                mergeMap((customFields) => {
                  data.response.customFields = JSON.parse(customFields).content;

                  const termAndCondtions = data.response.customData?.find((val) => {
                      return val.field.name === "T&C accepted";
                  });

                  const changePassword = data.response.customData?.find((val) => {
                    return val.field.name === "Change Password";
                  });

                  localStorage.setItem("termAndCondtions",JSON.stringify(termAndCondtions));
                  localStorage.setItem("changePassword",JSON.stringify(changePassword));

                  return [
                    dispatch(FETCH_LOGIN_SUCCESS, data),
                    dispatch(SUCCESS_NOTIFICATION_REQUEST, {
                      message: MESSAGES.LOGIN_SUCCESSFUL,
                    }),
                  ];
                })
              );
            }),
            catchError((err) => {
              return [
                dispatch(FETCH_LOGIN_ERROR, err),
                dispatch(ERROR_NOTIFICATION_REQUEST, {
                  response: {
                    message: err.response,
                  },
                }),
              ];
            })
          );
        }),
        catchError((err) => {
          return [
            dispatch(FETCH_LOGIN_ERROR, err),
            dispatch(ERROR_NOTIFICATION_REQUEST, {
              response: {
                message: err.response,
              },
            }),
          ];
        })
      );
    })
  );
};

export const resetPassword = (action$) => {
  return action$.pipe(
    ofType(RESET_PASSWORD_REQUEST),
    mergeMap(({ payload }) => {
      return usersService.resetPassword(payload).pipe(
        mergeMap((data) => {
          return [
            dispatch(RESET_PASSWORD_SUCCESS, data),
            dispatch(SUCCESS_NOTIFICATION_REQUEST, {
              message: MESSAGES.RESET_MAIL_SUCCESSFUL,
            }),
          ];
        }),
        catchError((err) => [
          dispatch(RESET_PASSWORD_ERROR, err),
          dispatch(ERROR_NOTIFICATION_REQUEST, {
            response: {
              message: err.response,
            },
          }),
        ])
      );
    })
  );
};

export const resetPasswordUpdate = (action$) => {
  return action$.pipe(
    ofType(RESET_PASSWORD_UPDATE_REQUEST),
    mergeMap(({ payload }) => {
      delete payload.password;
      return usersService.resetPasswordUpdate(payload).pipe(
        mergeMap((data) => {
          return [
            dispatch(RESET_PASSWORD_UPDATE_SUCCESS, data),
            dispatch(SUCCESS_NOTIFICATION_REQUEST, {
              message: MESSAGES.PASSWORD_UPDATED_SUCCESSFUL,
            }),
          ];
        }),
        catchError((err) => [
          dispatch(RESET_PASSWORD_UPDATE_ERROR, err),
          dispatch(ERROR_NOTIFICATION_REQUEST, {
            response: {
              message: err.response,
            },
          }),
        ])
      );
    })
  );
};

export const backToOTP = (action$) => {
  return action$.pipe(
    ofType(BACK_TO_OTP_REQUEST),
    mergeMap(() => {
      return [dispatch(BACK_TO_OTP_SUCCESS)];
    })
  );
};

export const getUserList = (action$) => {
  return action$.pipe(
    ofType(FETCH_USER_LIST_REQUEST),
    mergeMap(({ payload }) => {
      return usersService.getUserList(payload).pipe(
        map((response) => {
          return dispatch(FETCH_USER_LIST_SUCCESS, JSON.parse(response));
        }),
        catchError((error) => [dispatch(FETCH_USER_LIST_ERROR, error)])
      );
    })
  );
};

export const getUserDetailsById = (action$) => {
  return action$.pipe(
    ofType(USER_DETAIL_REQUEST),
    mergeMap(({ payload }) => {
      return usersService.getUserDetailsById(payload).pipe(
        mergeMap((response) => {
          return usersService.getUserCustomDataById(payload).pipe(
            mergeMap((customResponse) => {
              return practiceService
                .getPracticeList({
                  name: JSON.parse(response).fullName,
                })
                .pipe(
                  mergeMap((practiceResponse) => {
                    return getLinkedPatient(
                      response,
                      practiceResponse,
                      customResponse
                    );
                  }),
                  catchError(() => {
                    return getLinkedPatient(response, null, customResponse);
                  })
                );
            })
          );
        }),
        catchError((error) => {
          return dispatch(USER_DETAIL_ERROR, error);
        })
      );
    })
  );
};

export const getPatientDetailsById = (action$) => {
  return action$.pipe(
    ofType(PATIENT_DETAIL_REQUEST),
    mergeMap(({ payload }) => {
      return usersService.getUserDetailsById(payload).pipe(
        mergeMap((response) => {
          return usersService.getUserCustomDataById(payload).pipe(
            mergeMap((customResponse) => {
              return deviceService
                .getPracticeDeviceList({
                  patientNameLike: `${JSON.parse(response).firstName} ${JSON.parse(response).lastName
                    }`,
                  emailAddressLike: JSON.parse(response).emailAddress,
                })
                .pipe(
                  mergeMap((deviceResponse) => {
                    const identityIds = JSON.parse(deviceResponse).content.find(
                      (v) => {
                        if (
                          v.device.name.split("-")[1].toLowerCase() ===
                          "identity"
                        ) {
                          return v;
                        }
                      }
                    );
                    const paramsDT = JSON.parse(deviceResponse).content.find(
                      (v) => {
                        if (
                          v.device.name.split("-")[1].toLowerCase() === "params"
                        ) {
                          return v;
                        }
                      }
                    );
                    const devHealth = JSON.parse(deviceResponse).content.find(
                      (v) => {
                        if (
                          v.device.name.split("-")[1].toLowerCase() === "devhealth"
                        ) {
                          return v;
                        }
                      }
                    );
                    const thresholdsDT = JSON.parse(
                      deviceResponse
                    ).content.find((v) => {
                      if (
                        v.device.name.split("-")[1].toLowerCase() ===
                        "thresholds"
                      ) {
                        return v;
                      }
                    });
                    let idData = {
                      payload: {
                        sortBy: "maxValueProvidedOn",
                        sortOrder: "DESC",
                      },
                      data: {
                        deviceId: identityIds?.device?.deviceId,
                        devicePropertyCode: [
                          "Devid",
                          "FriendlyName",
                          "Location",
                        ],
                        ownerFilter: {
                          users: [payload],
                        },
                      },
                    };

                    return deviceService.fetchTableDeviceData(idData).pipe(
                      mergeMap((identityData) => {
                        let identityResponse = JSON.parse(identityData.response)
                          .content;
                        const deviceIdentityData = identityResponse.reduce(
                          (acc, val) => {
                            return [
                              ...acc,
                              deviceService
                                .fetchUserFirstPageData({
                                  deviceId: identityIds?.device?.deviceId,
                                  ownerFilter: {
                                    users: [payload],
                                  },
                                  deviceCriteria: [
                                    {
                                      key: "Devid",
                                      operator: "Equal",
                                      value: val.data.Devid.value,
                                      valueFrom: null,
                                      valueTo: null,
                                      isDateValue: false,
                                      dateTimeRangeValue: null,
                                    },
                                  ],
                                  devicePropertyCode: [
                                    "Devid",
                                    "FriendlyName",
                                    "Location",
                                  ],
                                })
                                .pipe(catchError((error) => of(error))),
                            ];
                          },
                          []
                        );
                        return forkJoin(deviceIdentityData).pipe(
                          mergeMap((res) => {
                            const idtyData = res
                              .filter((va) => va.status === 200)
                              .map((va) => JSON.parse(va.response).content[0]);
                            const paramsSet = idtyData.map((val) => {
                              return deviceService.fetchUserFirstPageData({
                                deviceId: paramsDT.device.deviceId,
                                deviceCriteria: [
                                  {
                                    key: "DevidParams",
                                    operator: "Equal",
                                    value: val.data.Devid.value,
                                    valueFrom: null,
                                    valueTo: null,
                                    isDateValue: false,
                                    dateTimeRangeValue: null,
                                  },
                                ],
                                devicePropertyCode: [
                                  "DevidParams",
                                  "FriendlyNamePa",
                                ],
                                ownerFilter: {
                                  users: [val.userId],
                                },
                              });
                            });
                            const thresholdsSet = idtyData.map((val) => {
                              return deviceService.fetchUserFirstPageData({
                                deviceId: thresholdsDT.device.deviceId,
                                deviceCriteria: [
                                  {
                                    key: "DevidThresholds",
                                    operator: "Equal",
                                    value: val.data.Devid.value,
                                    valueFrom: null,
                                    valueTo: null,
                                    isDateValue: false,
                                    dateTimeRangeValue: null,
                                  },
                                ],
                                devicePropertyCode: [
                                  "DevidThresholds",
                                  "FriendlyNameT",
                                ],
                                ownerFilter: {
                                  users: [val.userId],
                                },
                              });
                            });
                            const devHealthsSet = idtyData.map((val) => {
                              return deviceService.fetchUserFirstPageData({
                                deviceId: devHealth.device.deviceId,
                                deviceCriteria: [
                                  {
                                    key: "DevidDevhealth",
                                    operator: "Equal",
                                    value: val.data.Devid.value,
                                    valueFrom: null,
                                    valueTo: null,
                                    isDateValue: false,
                                    dateTimeRangeValue: null,
                                  },
                                ],
                                devicePropertyCode: [
                                  "DevidDevhealth",
                                  "FriendlyNameD",
                                ],
                                ownerFilter: {
                                  users: [val.userId],
                                },
                              }).pipe(catchError((error) => of(error)));
                            });
                            return forkJoin(paramsSet).pipe(
                              mergeMap((rsss) => {
                                const paramsData = rsss
                                  .filter((va) => va.status === 200)
                                  .map(
                                    (va) => JSON.parse(va.response).content[0]
                                  );
                                return forkJoin(thresholdsSet).pipe(
                                  mergeMap((tsss) => {
                                    const thresholdsData = tsss
                                      .filter((va) => va.status === 200)
                                      .map(
                                        (va) =>
                                          JSON.parse(va.response).content[0]
                                      );
                                    return forkJoin(devHealthsSet).pipe(
                                      mergeMap((devFinalData) => {
                                        const devHealthData = devFinalData
                                          .filter((va) => va.status === 200)
                                          .map(
                                            (va) =>
                                              JSON.parse(va.response).content[0]
                                          );
                                        let fOjt = {
                                          customObject: JSON.parse(customResponse),
                                          practiceObject: {},
                                          patient: [],
                                          deviceObject: JSON.parse(deviceResponse)
                                            .content,
                                          userObject: JSON.parse(response),
                                          linkedDevices: res
                                            .filter((va) => va.status === 200)
                                            .reduce((acc, data, index) => {
                                              if (
                                                JSON.parse(data.response) &&
                                                JSON.parse(data.response).content.length > 0
                                              ) {
                                                let obj = {
                                                  paramsDataId:
                                                    paramsData[index].deviceDataId,
                                                  thresholdsDataId:
                                                    thresholdsData[index]
                                                      .deviceDataId,
                                                  deviceDataId: JSON.parse(
                                                    data.response
                                                  ).content[0].deviceDataId,
                                                  devHealthDataId: '',
                                                  deviceId: JSON.parse(data.response)
                                                    .content[0].deviceId,
                                                  value:
                                                    JSON.parse(data.response)
                                                      .content[0].data?.Devid
                                                      ?.value || "",
                                                  Devsn:
                                                    JSON.parse(data.response)
                                                      .content[0].data?.Devsn
                                                      ?.value || "",
                                                  friendlyName:
                                                    JSON.parse(data.response)
                                                      .content[0].data?.FriendlyName
                                                      ?.value || "",
                                                  location:
                                                    JSON.parse(data.response)
                                                      .content[0].data?.Location
                                                      ?.value || "",
                                                  active: JSON.parse(data.response)
                                                    .content[0].data?.Active
                                                    ?.value || "",
                                                };
                                                if (devHealthData[index] && devHealthData[index].deviceDataId) {
                                                  obj.devHealthDataId = devHealthData[index].deviceDataId
                                                }
                                                return [...acc, obj];
                                              }
                                              return acc;
                                            }, []),
                                        };
                                        return [
                                          dispatch(PATIENT_DETAIL_SUCCESS, fOjt),
                                        ];
                                      }),
                                      catchError((err) => {
                                        return [
                                          dispatch(PATIENT_DETAIL_SUCCESS, {
                                            customObject: JSON.parse(
                                              customResponse
                                            ),
                                            practiceObject: {},
                                            patient: [],
                                            deviceObject: [],
                                            userObject: JSON.parse(response),
                                            linkedDevices: res
                                              .filter((va) => va.status === 200)
                                              .map((data, index) => {
                                                return {
                                                  paramsDataId:
                                                    paramsData[index].deviceDataId,
                                                  thresholdsDataId: thresholdsData[index].deviceDataId,
                                                  devHealthDataId: '',
                                                  deviceDataId: JSON.parse(
                                                    data.response
                                                  ).content[0].deviceDataId,
                                                  deviceId: JSON.parse(
                                                    data.response
                                                  ).content[0].deviceId,
                                                  value:
                                                    JSON.parse(data.response)
                                                      .content[0].data?.Devid
                                                      ?.value || "",
                                                  Devsn:
                                                    JSON.parse(data.response)
                                                      .content[0].data?.Devsn
                                                      ?.value || "",
                                                  friendlyName:
                                                    JSON.parse(data.response)
                                                      .content[0].data?.FriendlyName
                                                      ?.value || "",
                                                  location:
                                                    JSON.parse(data.response)
                                                      .content[0].data?.Location
                                                      ?.value || "",
                                                };
                                              }),
                                          }),
                                        ];
                                      })
                                    );
                                  }),
                                  catchError((err) => {
                                    return [
                                      dispatch(PATIENT_DETAIL_SUCCESS, {
                                        customObject: JSON.parse(
                                          customResponse
                                        ),
                                        practiceObject: {},
                                        patient: [],
                                        deviceObject: [],
                                        userObject: JSON.parse(response),
                                        linkedDevices: res
                                          .filter((va) => va.status === 200)
                                          .map((data, index) => {
                                            return {
                                              paramsDataId:
                                                paramsData[index].deviceDataId,
                                              thresholdsDataId: "",
                                              devHealthDataId: '',
                                              deviceDataId: JSON.parse(
                                                data.response
                                              ).content[0].deviceDataId,
                                              deviceId: JSON.parse(
                                                data.response
                                              ).content[0].deviceId,
                                              value:
                                                JSON.parse(data.response)
                                                  .content[0].data?.Devid
                                                  ?.value || "",
                                              Devsn:
                                                JSON.parse(data.response)
                                                  .content[0].data?.Devsn
                                                  ?.value || "",
                                              friendlyName:
                                                JSON.parse(data.response)
                                                  .content[0].data?.FriendlyName
                                                  ?.value || "",
                                              location:
                                                JSON.parse(data.response)
                                                  .content[0].data?.Location
                                                  ?.value || "",
                                            };
                                          }),
                                      }),
                                    ];
                                  })
                                );
                              }),
                              catchError((err) => {
                                 
                                return [
                                  dispatch(PATIENT_DETAIL_SUCCESS, {
                                    customObject: JSON.parse(customResponse),
                                    practiceObject: {},
                                    patient: [],
                                    deviceObject: [],
                                    userObject: JSON.parse(response),
                                    linkedDevices: res
                                      .filter((va) => va.status === 200)
                                      .map((data, index) => {
                                        return {
                                          paramsDataId: "",
                                          thresholdsDataId: "",
                                          devHealthDataId: '',
                                          deviceDataId: JSON.parse(
                                            data.response
                                          ).content[0].deviceDataId,
                                          deviceId: JSON.parse(data.response)
                                            .content[0].deviceId,
                                          value:
                                            JSON.parse(data.response).content[0]
                                              .data?.Devid?.value || "",
                                          Devsn:
                                            JSON.parse(data.response).content[0]
                                              .data?.Devsn?.value || "",
                                          friendlyName:
                                            JSON.parse(data.response).content[0]
                                              .data?.FriendlyName?.value || "",
                                          location:
                                            JSON.parse(data.response).content[0]
                                              .data?.Location?.value || "",
                                        };
                                      }),
                                  }),
                                ];
                              })
                            );
                          }),
                          catchError((err) => {
                            return [
                              dispatch(PATIENT_DETAIL_SUCCESS, {
                                customObject: JSON.parse(customResponse),
                                practiceObject: {},
                                patient: [],
                                deviceObject: [],
                                userObject: JSON.parse(response),
                                linkedDevices: [],
                              }),
                            ];
                          })
                        );
                      }),
                      catchError((err) => {
                         
                        return [
                          dispatch(PATIENT_DETAIL_SUCCESS, {
                            customObject: JSON.parse(customResponse),
                            practiceObject: {},
                            patient: [],
                            deviceObject: [],
                            userObject: JSON.parse(response),
                            linkedDevices: [],
                          }),
                        ];
                      })
                    );
                  }),
                  catchError((err) => {
                     
                    return [
                      dispatch(PATIENT_DETAIL_SUCCESS, {
                        customObject: JSON.parse(customResponse),
                        practiceObject: {},
                        patient: [],
                        deviceObject: [],
                        userObject: JSON.parse(response),
                        linkedDevices: [],
                      }),
                    ];
                  })
                );
            })
          );
        }),
        catchError((error) => {
           
          return [dispatch(PATIENT_DETAIL_ERROR, error)];
        })
      );
    })
  );
};

const getLinkedPatient = (response, practiceResponse, customResponse) => {
  const patientData = JSON.parse(response).roles.reduce((acc, val) => {
    return [
      ...acc,
      usersService.getUserList({
        role: "Patient",
        supplierId: val.supplier?.supplierId,
      }),
    ];
  }, []);
  return forkJoin(patientData).pipe(
    mergeMap((patientReponse) => {
      const linkedPatient = patientReponse.reduce((acc, val) => {
        const data = JSON.parse(val).content;
        return [...acc, ...data];
      }, []);
      return [
        dispatch(USER_DETAIL_SUCCESS, {
          userObject: JSON.parse(response),
          practiceObject: practiceResponse
            ? JSON.parse(practiceResponse).content[0]
            : [],
          patient: linkedPatient,
          customObject: JSON.parse(customResponse),
        }),
      ];
    }),
    catchError(() => {
      return [
        dispatch(USER_DETAIL_SUCCESS, {
          userObject: JSON.parse(response),
          practiceObject: practiceResponse
            ? JSON.parse(practiceResponse).content[0]
            : [],
          customObject: JSON.parse(customResponse),
          patient: [],
        }),
      ];
    })
  );
};

export const createPracticeAndUser = (action$) => {
  return action$.pipe(
    ofType(CREATE_PRACTICE_USER_REQUEST),
    mergeMap(({ payload }) => {
      return practiceService
        .getPracticeList({
          name: payload.praticeObject.name,
        })
        .pipe(
          mergeMap(() => {
            return [
              dispatch(CREATE_PRACTICE_USER_ERROR),
              dispatch(ERROR_NOTIFICATION_REQUEST, {
                response: {
                  message: "User with same name already exist.",
                },
              }),
            ];
          }),
          catchError(() => {
            return usersService
              .getUserList({
                emailAddress: payload.userObject.user.emailAddress,
              })
              .pipe(
                mergeMap(() => {
                  return [
                    dispatch(CREATE_PRACTICE_USER_ERROR),
                    dispatch(ERROR_NOTIFICATION_REQUEST, {
                      response: {
                        message: "User with same email already exist.",
                      },
                    }),
                  ];
                }),
                catchError(() => createPractice(payload))
              );
          })
        );
    })
  );
};

const createPractice = (payload) => {
  return usersService.createPractice(payload.praticeObject).pipe(
    mergeMap(() => getPracticeData(payload)),
    catchError((err) => {
      return [
        dispatch(ERROR_NOTIFICATION_REQUEST, {
          response: {
            message: err.response,
          },
        }),
      ];
    })
  );
};

const getPracticeData = (payload) => {
  return practiceService
    .getPracticeList({ name: payload.praticeObject.name })
    .pipe(
      mergeMap((practiceResponse) => {
        const practiceData = JSON.parse(practiceResponse);
        payload.userObject.user.roles[0] = {
          ...payload.userObject.user.roles[0],
          practice: { practiceId: practiceData.content[0].practiceId },
        };
        return usersService.createUser(payload.userObject).pipe(
          mergeMap(() => {
            return [dispatch(CREATE_PRACTICE_USER_SUCCESS)];
          }),
          catchError((err) => {
            return [
              dispatch(ERROR_NOTIFICATION_REQUEST, {
                response: {
                  message: err.response,
                },
              }),
            ];
          })
        );
      })
    );
};

export const startLinkingPractice = (action$) => {
  return action$.pipe(
    ofType(START_PRACTICE_REQUEST),
    mergeMap(({ payload }) => {
      return usersService
        .getUserCustomFieldById(payload.selectedPatient.userId)
        .pipe(
          mergeMap((userDetailResponse) => {
            let newRole = {
              role: "Patient",
              supplier: { supplierId: payload.payload.supplierId },
              practice: payload.practiceData.content[0],
            };
            payload.selectedPatient.roles = [
              ...payload.selectedPatient.roles,
              newRole,
            ];
            payload.selectedPatient.roles = payload.selectedPatient.roles.reduce(
              (acc, val) => {
                const exist = acc.find((data) => {
                  return (
                    data?.role === val?.role &&
                    val?.supplier?.supplierId === data?.supplier?.supplierId &&
                    val?.practice?.practiceId === data?.practice?.practiceId
                  );
                });
                return exist ? acc : [...acc, val];
              },
              []
            );

            let userObj = {
              customData: JSON.parse(userDetailResponse),
              user: payload.selectedPatient,
            };
            return usersService
              .updateUser(userObj)
              .pipe(map(() => dispatch(CREATE_PRACTICE_USER_SUCCESS)));
          })
        );
    })
  );
};

export const createUser = (action$) => {
  return action$.pipe(
    ofType(CREATE_USER_REQUEST),
    mergeMap(({ payload }) =>
      usersService.createUser(payload.userObject).pipe(
        map(() => dispatch(CREATE_USER_SUCCESS)),
        catchError((error) => [
          dispatch(CREATE_USER_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, {
            response: {
              message: error.response,
            },
          }),
        ])
      )
    )
  );
};

export const deleteUser = (action$) => {
  return action$.pipe(
    ofType(DELETE_USER_REQUEST),
    mergeMap(({ payload }) =>
      usersService.deleteUser(payload).pipe(
        mergeMap(() => {
          delete payload.userId;
          return [
            dispatch(DELETE_USER_SUCCESS),
            dispatch(FETCH_USER_LIST_REQUEST, payload),
          ];
        }),
        catchError((error) => [
          dispatch(DELETE_USER_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, {
            response: {
              message: error.response,
            },
          }),
        ])
      )
    )
  );
};

export const deleteUserAndPractice = (action$) => {
  return action$.pipe(
    ofType(DELETE_USER_PRACTICE_REQUEST),
    mergeMap(({ payload }) =>
      usersService.deleteUser(payload).pipe(
        mergeMap(() => {
          return deleteLinkedPractice(payload);
        }),
        catchError((error) => [
          dispatch(DELETE_USER_PRACTICE_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, {
            response: {
              message: error.response,
            },
          }),
        ])
      )
    )
  );
};

const deleteLinkedPractice = (payload) => {
  return practiceService.getPracticeList({ name: payload.fullName }).pipe(
    mergeMap((practiceResponse) => {
      const practiceData = JSON.parse(practiceResponse).content[0];
      return practiceService.deletePractice(practiceData).pipe(
        mergeMap(() => {
          delete payload.userId;
          delete payload.fullName;
          return [
            dispatch(FETCH_USER_LIST_REQUEST, payload),
            dispatch(DELETE_USER_PRACTICE_SUCCESS),
          ];
        }),
        catchError((error) => [dispatch(DELETE_USER_PRACTICE_ERROR, error)])
      );
    }),
    catchError(() => {
      delete payload.userId;
      delete payload.fullName;
      return [
        dispatch(FETCH_USER_LIST_REQUEST, payload),
        dispatch(DELETE_USER_PRACTICE_SUCCESS),
      ];
    })
  );
};

export const createUserAndDevice = (action$) => {
  return action$.pipe(
    ofType(CREATE_DEVICE_USER_REQUEST),
    mergeMap(({ payload }) =>
      usersService
        .getUserList({
          emailAddress: payload.userObject.user.emailAddress,
        })
        .pipe(
          mergeMap(() => {
            return [
              dispatch(CREATE_DEVICE_USER_ERROR),
              dispatch(ERROR_NOTIFICATION_REQUEST, {
                response: {
                  message: "User with same email already exist",
                },
              }),
            ];
          }),
          catchError(() => {
            // return linkDeviceWithPatient({
            //   userId: '92398552-9b6c-4e5e-a35e-ba37e0cab737'
            // }, payload);
            return usersService.createUser(payload.userObject).pipe(
              mergeMap(() => {
                return usersService
                  .getUserList({
                    emailAddress: payload.userObject.user.emailAddress,
                  })
                  .pipe(
                    mergeMap((response) => {
                      const userData = JSON.parse(response).content[0];
                      if (payload.userDeviceList.length > 0) {
                        return linkDeviceWithPatient(userData, payload);
                      } else {
                        return [dispatch(CREATE_DEVICE_USER_SUCCESS)];
                      }
                    })
                  );
              }),
              catchError((err) => {
                return [
                  dispatch(CREATE_DEVICE_USER_ERROR),
                  dispatch(ERROR_NOTIFICATION_REQUEST, {
                    response: {
                      message: err.response,
                    },
                  }),
                ];
              })
            );
          })
        )
    )
  );
};

const linkDeviceWithPatient = (userData, payload) => {
  let getDeviceList = payload.userDeviceList.reduce((acc, device) => {
    return [
      ...acc,
      deviceService.getDeviceList({ nameLike: device.deviceName }),
    ];
  }, []);
  return forkJoin(getDeviceList).pipe(
    mergeMap((linkedDeviceData) => {
      var linkedDevicePropertyRequest = linkedDeviceData.reduce((a, v) => {
        return [
          ...a,
          ...JSON.parse(v).content.map((val) => {
            return deviceService
              .getDevicePropertyList(val.deviceId)
              .pipe(catchError((error) => of(error)));
          }),
        ];
      }, []);
      return forkJoin(linkedDevicePropertyRequest).pipe(
        mergeMap((linkedPropertyData) => {
          const updatedPropertyList = linkedDeviceData.map((v, i) => {
            const deviceListData = JSON.parse(v);
            payload.userDeviceList[i].propertyList = linkedPropertyData.splice(
              0,
              deviceListData.totalElements
            );
            return payload.userDeviceList[i];
          });
          const propertyCodes = updatedPropertyList.reduce((a, v, i) => {
            const filteredData = v.propertyList.reduce((acc, val) => {
              const paramValue = JSON.parse(val).find(
                (vval) => vval.dataType === "Text" && vval.indexProperty
              );
              if (paramValue) {
                return [...acc, deviceService
                  .createDevice({
                    deviceId: paramValue.deviceId,
                    propertyCode: paramValue.propertyCode,
                    value: payload.userDeviceList[i].value,
                    userId: userData.userId,
                  })
                  .pipe(catchError((error) => of(error)))];
              }
              return acc;
            }, []);
            return [...a, ...filteredData];
          }, []);

          return forkJoin(propertyCodes).pipe(
            mergeMap(() => {
              let propertySetArray = [];
              const mappedPropertyList = linkedDeviceData.map((val) => {
                const linkedData = JSON.parse(val).content.filter((v) => {
                  if (
                    v.name.split("-")[1].toLowerCase() === "identity" ||
                    v.name.split("-")[1].toLowerCase() === "thresholds" ||
                    v.name.split("-")[1].toLowerCase() === "params"
                  ) {
                    propertySetArray = [
                      ...propertySetArray,
                      deviceService
                        .getDevicePropertySetList(v.deviceId)
                        .pipe(catchError((error) => of(error))),
                    ];
                    return v;
                  }
                });
                return linkedData;
              });
              return forkJoin(propertySetArray).pipe(
                mergeMap((valProp) => {
                  const setData = updatedPropertyList.reduce(
                    (ac, dt, index) => {
                      const selectedCompany = payload.companyList.find(
                        (val) =>
                          val.supplierId ===
                          payload.userObject.user.roles[0].supplier.supplierId
                      );

                      const newArr = valProp.splice(
                        0,
                        mappedPropertyList[0].length
                      );
                      const paramObj = newArr.find(
                        (v) => JSON.parse(v).content[0].name === "PARAMS"
                      );
                      const identityObj = newArr.find(
                        (v) => JSON.parse(v).content[0].name === "IDENTITY"
                      );
                      const thresholdObj = newArr.find(
                        (v) => JSON.parse(v).content[0].name === "THRESHOLDS"
                      );

                      const paramsData = JSON.parse(paramObj).content.find(
                        (val) => val.name === "PARAMS"
                      );

                      const thresholdsData = JSON.parse(
                        thresholdObj
                      ).content.find((val) => val.name === "THRESHOLDS");

                      const identityData = JSON.parse(identityObj).content.find(
                        (val) => val.name === "IDENTITY"
                      );
                      const thresholdsDefaultValue = thresholdsData.deviceProperties.reduce(
                        (acc, val) => {
                          if (val.propertyCode === "DevidThresholds") {
                            let obj = {};
                            obj[val.propertyCode] =
                              payload.userDeviceList[index].value;
                            return { ...acc, ...obj };
                          } else if (val.propertyCode === "FriendlyNameT") {
                            let obj = {};
                            obj[val.propertyCode] =
                              payload.userDeviceList[index].friendlyName;
                            return { ...acc, ...obj };
                          } else if (
                            val.propertyCode === "ThresholdLastupdate"
                          ) {
                            let obj = {};
                            obj[val.propertyCode] = moment()
                              .utc()
                              .format("YYYY-MM-DDTHH:mm:ss");
                            return { ...acc, ...obj };
                          } else {
                            const selectedValue = selectedCompany.customData.find(
                              (sVal) => sVal.field.name === val.propertyCode
                            );
                            if (selectedValue) {
                              let obj = {};
                              // obj[val.propertyCode] = selectedValue.fieldData;
                              obj[val.propertyCode] = 0;
                              return { ...acc, ...obj };
                            } else {
                              return acc;
                            }
                          }
                        },
                        {}
                      );
                      const paramsDefaultValue = paramsData.deviceProperties.reduce(
                        (acc, val) => {
                          if (val.propertyCode === "DevidParams") {
                            let obj = {};
                            obj[val.propertyCode] =
                              payload.userDeviceList[index].value;
                            return { ...acc, ...obj };
                          } else if (val.propertyCode === "FriendlyNamePa") {
                            let obj = {};
                            obj[val.propertyCode] =
                              payload.userDeviceList[index].friendlyName;
                            return { ...acc, ...obj };
                          } else if (val.propertyCode === "ParamsLastupdate") {
                            let obj = {};
                            obj[val.propertyCode] = moment()
                              .utc()
                              .format("YYYY-MM-DDTHH:mm:ss");
                            return { ...acc, ...obj };
                          } else {
                            const selectedValue = selectedCompany.customData.find(
                              (sVal) => sVal.field.name === val.propertyCode
                            );
                            if (selectedValue) {
                              let obj = {};
                              obj[val.propertyCode] = selectedValue.fieldData;
                              return { ...acc, ...obj };
                            } else {
                              return acc;
                            }
                          }
                        },
                        {}
                      );
                      const identityDefaultValue = identityData.deviceProperties.reduce(
                        (acc, val) => {
                          if (val.propertyCode === "Devid") {
                            let obj = {};
                            obj[val.propertyCode] =
                              payload.userDeviceList[index].value;
                            return { ...acc, ...obj };
                          } else if (val.propertyCode === "FriendlyName") {
                            let obj = {};
                            obj[val.propertyCode] =
                              payload.userDeviceList[index].friendlyName;
                            return { ...acc, ...obj };
                          } else if (val.propertyCode === "Devsn") {
                            let obj = {};
                            obj[val.propertyCode] =
                              payload.userDeviceList[index].Devsn;
                            return { ...acc, ...obj };
                          } else if (val.propertyCode === "Model") {
                            let obj = {};
                            obj[val.propertyCode] =
                              payload.userDeviceList[index].deviceName;
                            return { ...acc, ...obj };
                          } else if (val.propertyCode === "Location") {
                            let obj = {};
                            obj[val.propertyCode] =
                              payload.userDeviceList[index].location;
                            return { ...acc, ...obj };
                          } else {
                            const selectedValue = selectedCompany.customData.find(
                              (sVal) => sVal.field.name === val.propertyCode
                            );
                            if (selectedValue) {
                              let obj = {};
                              obj[val.propertyCode] = selectedValue.fieldData;
                              return { ...acc, ...obj };
                            } else {
                              return acc;
                            }
                          }
                        },
                        {}
                      );
                      identityDefaultValue.Active = 'Yes';
                      identityDefaultValue.Timezone = 'Central Standard Time';
                      return [
                        ...ac,
                        deviceService.createDeviceData({
                          data: paramsDefaultValue,
                          deviceId: JSON.parse(paramObj).content[0].deviceId,
                          devicePropertySetId: paramsData.devicePropertySetId,
                        }),
                        deviceService.createDeviceData({
                          data: thresholdsDefaultValue,
                          deviceId: JSON.parse(thresholdObj).content[0]
                            .deviceId,
                          devicePropertySetId:
                            thresholdsData.devicePropertySetId,
                        }),
                        deviceService.createDeviceData({
                          data: identityDefaultValue,
                          deviceId: JSON.parse(identityObj).content[0].deviceId,
                          devicePropertySetId: identityData.devicePropertySetId,
                        }),
                      ];
                    },
                    []
                  );
                  return forkJoin(setData).pipe(
                    mergeMap(() => {
                      return [dispatch(CREATE_DEVICE_USER_SUCCESS)];
                    }),
                    catchError((err) => {
                       
                      return [
                        dispatch(CREATE_DEVICE_USER_ERROR),
                        dispatch(ERROR_NOTIFICATION_REQUEST, {
                          response: {
                            message: err.response,
                          },
                        }),
                      ];
                    })
                  );
                })
              );
            }),
            catchError((err) => {
               
              return [
                dispatch(CREATE_DEVICE_USER_ERROR),
                dispatch(ERROR_NOTIFICATION_REQUEST, {
                  response: {
                    message: err.response,
                  },
                }),
              ];
            })
          );
        })
      );
    }),
    catchError((err) => {
       
      return [
        dispatch(CREATE_DEVICE_USER_ERROR),
        dispatch(ERROR_NOTIFICATION_REQUEST, {
          response: {
            message: err.response,
          },
        }),
      ];
    })
  );
};

export const deleteUserAndDevice = (action$) => {
  return action$.pipe(
    ofType(DELETE_USER_DEVICE_REQUEST),
    mergeMap(({ payload }) =>
      usersService.deleteUser(payload).pipe(
        mergeMap(() => {
          delete payload.userId;
          return [
            dispatch(DELETE_USER_DEVICE_SUCCESS),
            dispatch(FETCH_USER_LIST_REQUEST, payload),
          ];
        }),
        catchError((error) => [
          dispatch(DELETE_USER_DEVICE_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, {
            response: {
              message: error.response,
            },
          }),
        ])
      )
    )
  );
};

export const updateRole = (action$) => {
  return action$.pipe(
    ofType(UPDATE_ROLE_REQUEST),
    mergeMap(({ payload }) => {
      return usersService.getUserCustomDataById(payload.user.userId).pipe(
        mergeMap((response) => {
          let userObj = {
            customData: JSON.parse(response),
            user: payload.user,
          };
          return usersService.updateUser(userObj).pipe(
            mergeMap(() => {
              return [
                dispatch(FETCH_USER_LIST_REQUEST, payload.currentPage),
                dispatch(UPDATE_ROLE_SUCCESS),
              ];
            }),
            catchError((error) => [
              dispatch(UPDATE_ROLE_ERROR, error),
              dispatch(ERROR_NOTIFICATION_REQUEST, {
                response: {
                  message: error.response,
                },
              }),
              dispatch(FETCH_USER_LIST_REQUEST, payload.currentPage),
            ])
          );
        }),
        catchError((error) => [dispatch(UPDATE_ROLE_ERROR, error)])
      );
    })
  );
};

export const updateGuestRole = (action$) => {
  return action$.pipe(
    ofType(UPDATE_GUEST_REQUEST),
    mergeMap(({ payload }) => {
      let findUserObj = {
        supplierId: payload.guest.roles[0].supplier?.supplierId,
        practiceId: payload.guest.roles[0].practice?.practiceId,
        role: "Patient",
      };
      return usersService.getUserList(findUserObj).pipe(
        mergeMap((findRes) => {
          const userList = JSON.parse(findRes).content;
          const validateData = userList.filter((val) => {
            const linkedField = val.roles.find(
              (role) =>
                role.role === "Patient" &&
                role.supplier?.supplierId ===
                payload.guest.roles[0].supplier?.supplierId &&
                role.practice?.practiceId ===
                payload.guest.roles[0].practice?.practiceId
            );
            if (linkedField) {
              return val;
            }
            return false;
          });
          if (validateData.length === 0) {
            return usersService.getUserCustomDataById(payload.user.userId).pipe(
              mergeMap((response) => {
                let userObj = {
                  customData: JSON.parse(response),
                  user: payload.user,
                };
                return usersService.updateUser(userObj).pipe(
                  mergeMap(() => {
                    return [
                      dispatch(FETCH_USER_LIST_REQUEST, payload.currentPage),
                      dispatch(UPDATE_GUEST_SUCCESS),
                    ];
                  }),
                  catchError((error) => {
                    return [
                      dispatch(UPDATE_GUEST_ERROR, error),
                      dispatch(ERROR_NOTIFICATION_REQUEST, {
                        response: {
                          message: error.response,
                        },
                      }),
                      dispatch(FETCH_USER_LIST_REQUEST, payload.currentPage),
                    ];
                  })
                );
              }),
              catchError((error) => {
                return [
                  dispatch(UPDATE_GUEST_ERROR, error),
                  dispatch(ERROR_NOTIFICATION_REQUEST, {
                    response: {
                      message: error.response,
                    },
                  }),
                  dispatch(FETCH_USER_LIST_REQUEST, payload.currentPage),
                ];
              })
            );
          } else {
            const message = `${payload.guest.fullName} is already linked with ${validateData[0].fullName}`;
            return [
              dispatch(UPDATE_GUEST_ERROR),
              dispatch(ERROR_NOTIFICATION_REQUEST, {
                response: {
                  message,
                },
              }),
              dispatch(FETCH_USER_LIST_REQUEST, payload.currentPage),
            ];
          }
        }),
        catchError(() => {
          return usersService.getUserCustomDataById(payload.user.userId).pipe(
            mergeMap((response) => {
              let userObj = {
                customData: JSON.parse(response),
                user: payload.user,
              };
              return usersService.updateUser(userObj).pipe(
                mergeMap(() => {
                  return [
                    dispatch(FETCH_USER_LIST_REQUEST, payload.currentPage),
                    dispatch(UPDATE_GUEST_SUCCESS),
                  ];
                }),
                catchError((error) => {
                  return [
                    dispatch(UPDATE_GUEST_ERROR, error),
                    dispatch(ERROR_NOTIFICATION_REQUEST, {
                      response: {
                        message: error.response,
                      },
                    }),
                    dispatch(FETCH_USER_LIST_REQUEST, payload.currentPage),
                  ];
                })
              );
            }),
            catchError((error) => {
              return [
                dispatch(UPDATE_GUEST_ERROR, error),
                dispatch(ERROR_NOTIFICATION_REQUEST, {
                  response: {
                    message: error.response,
                  },
                }),
                dispatch(FETCH_USER_LIST_REQUEST, payload.currentPage),
              ];
            })
          );
        })
      );
    })
  );
};

export const updateUserAndDevice = (action$) => {
  return action$.pipe(
    ofType(UPDATE_DEVICE_USER_REQUEST),
    mergeMap(({ payload }) =>
      usersService
        .getUserList({
          emailAddress: payload.userObject.user.emailAddress,
        })
        .pipe(
          mergeMap((response) => {
            const dbData = JSON.parse(response).content[0];
            if (dbData.emailAddress === payload.userObject.user.emailAddress) {
              return updateUserData(payload);
            } else {
              return [
                dispatch(UPDATE_DEVICE_USER_ERROR),
                dispatch(ERROR_NOTIFICATION_REQUEST, {
                  response: {
                    message: "User with same email already exist",
                  },
                }),
              ];
            }
          }),
          catchError(() => {
            return updateUserData(payload);
          })
        )
    )
  );
};

let updateUserData = (payload) => {
  return usersService.updateUser(payload.userObject).pipe(
    mergeMap(() => {
      return usersService
        .getUserList({
          emailAddress: payload.userObject.user.emailAddress,
        })
        .pipe(
          mergeMap(() => {
            if (payload.userDeviceList.length > 0) {
              return updateLinkDeviceWithPatient(payload);
            } else {
              return [dispatch(CREATE_DEVICE_USER_SUCCESS)];
            }
          })
        );
    }),
    catchError((err) => {
      return [
        dispatch(UPDATE_DEVICE_USER_ERROR),
        dispatch(ERROR_NOTIFICATION_REQUEST, {
          response: {
            message: err.response,
          },
        }),
      ];
    })
  );
};

const updateLinkDeviceWithPatient = (payload) => {
  let getDeviceList = payload.userDeviceList.reduce((acc, device) => {
    return [
      ...acc,
      deviceService.getDeviceList({ nameLike: device.deviceName }),
    ];
  }, []);
  return forkJoin(getDeviceList).pipe(
    mergeMap((linkedDeviceData) => {
      var linkedDevicePropertyRequest = linkedDeviceData.reduce((a, v) => {
        return [
          ...a,
          ...JSON.parse(v).content.map((val) => {
            return deviceService
              .getDevicePropertyList(val.deviceId)
              .pipe(catchError((error) => of(error)));
          }),
        ];
      }, []);
      return forkJoin(linkedDevicePropertyRequest).pipe(
        mergeMap((linkedPropertyData) => {
          const updatedPropertyList = linkedDeviceData.map((v, i) => {
            const deviceListData = JSON.parse(v);
            payload.userDeviceList[i].propertyList = linkedPropertyData.splice(
              0,
              deviceListData.totalElements
            );
            return payload.userDeviceList[i];
          });
          const mergedPropertyData = updatedPropertyList.reduce((a, v, i) => {
            if (payload.userDeviceList[i].deviceDataId) {
              const updateData = payload.patientDeviceList.reduce(
                (acc, data) => {
                  const previousDevId = payload.userInfo.linkDeviceData.find(
                    (val) =>
                      val.deviceDataId ===
                      payload.userDeviceList[i].deviceDataId
                  ).value;
                  if (data.value === previousDevId) {
                    return [
                      ...acc,
                      deviceService
                        .updatePropertyDevice({
                          patientDeviceId: data.patientDeviceId,
                          value: payload.userDeviceList[i].value,
                        })
                        .pipe(catchError((error) => of(error))),
                    ];
                  }
                  return acc;
                },
                []
              );
              return [...a, ...updateData];
            } else {
              const filteredData = v.propertyList.reduce((acc, val) => {
                const paramValue = JSON.parse(val).find(
                  (vval) => vval.dataType === "Text" && vval.indexProperty
                );
                if (paramValue) {
                  return [...acc, deviceService
                    .createDevice({
                      deviceId: paramValue.deviceId,
                      propertyCode: paramValue.propertyCode,
                      value: payload.userDeviceList[i].value,
                      userId: payload.userInfo.userId,
                    })
                    .pipe(catchError((error) => of(error)))];
                }
                return acc;
              }, []);
              return [...a, ...filteredData];
            }
          }, []);
          return forkJoin(mergedPropertyData).pipe(
            mergeMap(() => {
              let propertySetArray = [];
              const mappedPropertyList = linkedDeviceData.map((val) => {
                const linkedData = JSON.parse(val).content.filter((v) => {
                  if (
                    v.name.split("-")[1].toLowerCase() === "identity" ||
                    v.name.split("-")[1].toLowerCase() === "thresholds" ||
                    v.name.split("-")[1].toLowerCase() === "params"
                  ) {
                    propertySetArray = [
                      ...propertySetArray,
                      deviceService
                        .getDevicePropertySetList(v.deviceId)
                        .pipe(catchError((error) => of(error))),
                    ];
                    return v;
                  }
                });
                return linkedData;
              });
              return forkJoin(propertySetArray).pipe(
                mergeMap((valProp) => {
                  const setData = updatedPropertyList.reduce(
                    (ac, dt, index) => {
                      const selectedCompany = payload.companyList.find(
                        (val) =>
                          val.supplierId ===
                          payload.userObject.user.roles[0].supplier.supplierId
                      );

                      const newArr = valProp.splice(
                        0,
                        mappedPropertyList[0].length
                      );
                      const paramObj = newArr.find(
                        (v) => JSON.parse(v).content[0].name === "PARAMS"
                      );
                      const identityObj = newArr.find(
                        (v) => JSON.parse(v).content[0].name === "IDENTITY"
                      );
                      const thresholdObj = newArr.find(
                        (v) => JSON.parse(v).content[0].name === "THRESHOLDS"
                      );

                      const paramsData = JSON.parse(paramObj).content.find(
                        (val) => val.name === "PARAMS"
                      );

                      const thresholdsData = JSON.parse(
                        thresholdObj
                      ).content.find((val) => val.name === "THRESHOLDS");

                      const identityData = JSON.parse(identityObj).content.find(
                        (val) => val.name === "IDENTITY"
                      );

                      const thresholdsDefaultValue = thresholdsData.deviceProperties.reduce(
                        (acc, val) => {
                          if (val.propertyCode === "DevidThresholds") {
                            let obj = {};
                            obj[val.propertyCode] =
                              payload.userDeviceList[index].value;
                            return { ...acc, ...obj };
                          } else if (val.propertyCode === "FriendlyNameT") {
                            let obj = {};
                            obj[val.propertyCode] =
                              payload.userDeviceList[index].friendlyName;
                            return { ...acc, ...obj };
                          } else if (
                            val.propertyCode === "ThresholdLastupdate"
                          ) {
                            let obj = {};
                            obj[val.propertyCode] = moment()
                              .utc()
                              .format("YYYY-MM-DDTHH:mm:ss");
                            return { ...acc, ...obj };
                          } else {
                            const selectedValue = selectedCompany.customData.find(
                              (sVal) => sVal.field.name === val.propertyCode
                            );
                            if (selectedValue) {
                              let obj = {};
                              // obj[val.propertyCode] = selectedValue.fieldData;
                              obj[val.propertyCode] = 0;
                              return { ...acc, ...obj };
                            } else {
                              return acc;
                            }
                          }
                        },
                        {}
                      );

                      const paramsDefaultValue = paramsData.deviceProperties.reduce(
                        (acc, val) => {
                          if (val.propertyCode === "DevidParams") {
                            let obj = {};
                            obj[val.propertyCode] =
                              payload.userDeviceList[index].value;
                            return { ...acc, ...obj };
                          } else if (val.propertyCode === "FriendlyNamePa") {
                            let obj = {};
                            obj[val.propertyCode] =
                              payload.userDeviceList[index].friendlyName;
                            return { ...acc, ...obj };
                          } else if (val.propertyCode === "ParamsLastupdate") {
                            let obj = {};
                            obj[val.propertyCode] = moment()
                              .utc()
                              .format("YYYY-MM-DDTHH:mm:ss");
                            return { ...acc, ...obj };
                          } else {
                            const selectedValue = selectedCompany.customData.find(
                              (sVal) => sVal.field.name === val.propertyCode
                            );
                            if (selectedValue) {
                              let obj = {};
                              obj[val.propertyCode] = selectedValue.fieldData;
                              return { ...acc, ...obj };
                            } else {
                              return acc;
                            }
                          }
                        },
                        {}
                      );

                      const identityDefaultValue = identityData.deviceProperties.reduce(
                        (acc, val) => {
                          if (val.propertyCode === "Devid") {
                            let obj = {};
                            obj[val.propertyCode] =
                              payload.userDeviceList[index].value;
                            return { ...acc, ...obj };
                          } else if (val.propertyCode === "Devsn") {
                            let obj = {};
                            obj[val.propertyCode] =
                              payload.userDeviceList[index].Devsn;
                            return { ...acc, ...obj };
                          } else if (val.propertyCode === "Model") {
                            let obj = {};
                            obj[val.propertyCode] =
                              payload.userDeviceList[index].deviceName;
                            return { ...acc, ...obj };
                          } else if (val.propertyCode === "FriendlyName") {
                            let obj = {};
                            obj[val.propertyCode] =
                              payload.userDeviceList[index].friendlyName;
                            return { ...acc, ...obj };
                          } else if (val.propertyCode === "Location") {
                            let obj = {};
                            obj[val.propertyCode] =
                              payload.userDeviceList[index].location;
                            return { ...acc, ...obj };
                          } else {
                            const selectedValue = selectedCompany.customData.find(
                              (sVal) => sVal.field.name === val.propertyCode
                            );
                            if (selectedValue) {
                              let obj = {};
                              obj[val.propertyCode] = selectedValue.fieldData;
                              return { ...acc, ...obj };
                            } else {
                              return acc;
                            }
                          }
                        },
                        {}
                      );
                      if (!payload.userDeviceList[index].deviceDataId) {
                        identityDefaultValue.Active = 'Yes';
                        identityDefaultValue.Timezone = 'Central Standard Time';
                      }
                      return [
                        ...ac,
                        payload.userDeviceList[index].paramsDataId
                          ? deviceService.createDeviceData({
                            data: paramsDefaultValue,
                            deviceId: JSON.parse(paramObj).content[0]
                              .deviceId,
                            devicePropertySetId:
                              paramsData.devicePropertySetId,
                            deviceDataId:
                              payload.userDeviceList[index].paramsDataId,
                          })
                          : deviceService.createDeviceData({
                            data: paramsDefaultValue,
                            deviceId: JSON.parse(paramObj).content[0]
                              .deviceId,
                            devicePropertySetId:
                              paramsData.devicePropertySetId,
                          }),
                        payload.userDeviceList[index].thresholdsDataId
                          ? deviceService.createDeviceData({
                            data: thresholdsDefaultValue,
                            deviceId: JSON.parse(thresholdObj).content[0]
                              .deviceId,
                            devicePropertySetId:
                              thresholdsData.devicePropertySetId,
                            deviceDataId:
                              payload.userDeviceList[index].thresholdsDataId,
                          })
                          : deviceService.createDeviceData({
                            data: thresholdsDefaultValue,
                            deviceId: JSON.parse(thresholdObj).content[0]
                              .deviceId,
                            devicePropertySetId:
                              thresholdsData.devicePropertySetId,
                          }),
                        deviceService.createDeviceData({
                          data: identityDefaultValue,
                          deviceId: JSON.parse(identityObj).content[0].deviceId,
                          devicePropertySetId: identityData.devicePropertySetId,
                          deviceDataId:
                            payload.userDeviceList[index].deviceDataId,
                        }),
                      ];
                    },
                    []
                  );
                  return forkJoin(setData).pipe(
                    mergeMap(() => {
                      return [dispatch(CREATE_DEVICE_USER_SUCCESS)];
                    }),
                    catchError((err) => {
                       
                      return [
                        dispatch(CREATE_DEVICE_USER_ERROR),
                        dispatch(ERROR_NOTIFICATION_REQUEST, {
                          response: {
                            message: err.response,
                          },
                        }),
                      ];
                    })
                  );
                })
              );
            })
          );
        })
      );
    }),
    catchError((err) => {
       
      return [
        dispatch(UPDATE_DEVICE_USER_ERROR),
        dispatch(ERROR_NOTIFICATION_REQUEST, {
          response: {
            message: err.response,
          },
        }),
      ];
    })
  );
};

export const updateUser = (action$) => {
  return action$.pipe(
    ofType(UPDATE_USER_REQUEST),
    mergeMap(({ payload }) =>
      usersService.updateUser(payload.userObject).pipe(
        map(() => dispatch(UPDATE_USER_SUCCESS)),
        catchError((error) => [
          dispatch(UPDATE_USER_ERROR, error),
          dispatch(ERROR_NOTIFICATION_REQUEST, {
            response: {
              message: error.response,
            },
          }),
        ])
      )
    )
  );
};

export const updatePracticeAndUser = (action$) => {
  return action$.pipe(
    ofType(UPDATE_PRACTICE_USER_REQUEST),
    mergeMap(({ payload }) => {
      return practiceService
        .getPracticeList({
          name: payload.praticeObject.name,
        })
        .pipe(
          mergeMap((response) => {
            const dbData = JSON.parse(response).content[0];
            if (dbData.name === payload.praticeObject.name) {
              return validateUserExist(payload);
            } else {
              return [
                dispatch(UPDATE_PRACTICE_USER_ERROR),
                dispatch(ERROR_NOTIFICATION_REQUEST, {
                  response: {
                    message: "User with same name already exist.",
                  },
                }),
              ];
            }
          }),
          catchError(() => {
            return validateUserExist(payload);
          })
        );
    })
  );
};

let validateUserExist = (payload) => {
  return usersService
    .getUserList({
      emailAddress: payload.userObject.user.emailAddress,
    })
    .pipe(
      mergeMap((response) => {
        const dbData = JSON.parse(response).content[0];
        if (dbData.emailAddress === payload.userObject.user.emailAddress) {
          return updatePracticeUserData(payload);
        } else {
          return [
            dispatch(UPDATE_PRACTICE_USER_ERROR),
            dispatch(ERROR_NOTIFICATION_REQUEST, {
              response: {
                message: "User with same email already exist.",
              },
            }),
          ];
        }
      }),
      catchError(() => {
        return updatePracticeUserData(payload);
      })
    );
};

let updatePracticeUserData = (payload) => {
  return practiceService
    .updatePractice({
      customData: null,
      practice: payload.praticeObject,
    })
    .pipe(
      mergeMap(() => {
        return usersService.updateUser(payload.userObject).pipe(
          map(() => dispatch(UPDATE_PRACTICE_USER_SUCCESS)),
          catchError((error) => [
            dispatch(UPDATE_PRACTICE_USER_ERROR, error),
            dispatch(ERROR_NOTIFICATION_REQUEST, {
              response: {
                message: error.response,
              },
            }),
          ])
        );
      }),
      catchError((error) => [
        dispatch(UPDATE_PRACTICE_USER_ERROR, error),
        dispatch(ERROR_NOTIFICATION_REQUEST, {
          response: {
            message: error.response,
          },
        }),
      ])
    );
};

export const updateTermAndConditions = (action$) => {
  return action$.pipe(
    ofType(UPDATE_TERM_AND_CONDITION_REQUEST),
    mergeMap(({ payload }) => {
      return usersService.updateUser(payload).pipe(
        mergeMap(() => {
          return usersService.getUserCustomDataById(payload.user.userId).pipe(
            mergeMap((customData) => {
              const parsedCustomData = JSON.parse(customData);
              localStorage.setItem(
                "user-custom-details",
                JSON.stringify(parsedCustomData)
              );
              const termAndCondtions = parsedCustomData?.find((val) => {
                return val.field.name === "T&C accepted";
              });
              localStorage.setItem(
                "termAndCondtions",
                JSON.stringify(termAndCondtions)
              );
              return [dispatch(UPDATE_TERM_AND_CONDITION_SUCCESS)];
            })
          );
        }),
        catchError(() => {
          return [dispatch(UPDATE_TERM_AND_CONDITION_ERROR)];
        })
      );
    })
  );
};

export const changeUserPassword = (action$) => {
  return action$.pipe(
    ofType(UPDATE_PASSWORD_REQUEST),
    mergeMap(({ payload }) => {
      return usersService.updateUserPassword(payload.password).pipe(
        mergeMap(() => {
          return usersService.updateUser(payload.user).pipe(
            mergeMap(() => {
              localStorage.removeItem("user-details");
              localStorage.removeItem("termAndCondtions");
              localStorage.removeItem("changePassword");
              localStorage.removeItem("user-custom-details");
              return [
                dispatch(UPDATE_PASSWORD_SUCCESS),
                dispatch(SUCCESS_NOTIFICATION_REQUEST, {
                  message: "Password updated successfully",
                }),
              ];
            })
          );
        }),
        catchError((err) => {
          return [
            dispatch(UPDATE_PASSWORD_ERROR),
            dispatch(ERROR_NOTIFICATION_REQUEST, {
              response: {
                message: err.response,
              },
            }),
          ];
        })
      );
    })
  );
};

export const getUserAndCustomList = (action$) => {
  return action$.pipe(
    ofType(FETCH_USER_CUSTOM_REQUEST),
    mergeMap(({ payload }) => {
      return usersService.getUserList(payload).pipe(
        mergeMap((response) => {
          const customDataFunc = JSON.parse(response).content.reduce(
            (acc, val) => {
              return [...acc, usersService.getUserCustomDataById(val.userId)];
            },
            []
          );
          return forkJoin(customDataFunc).pipe(
            mergeMap((customResponse) => {
              const userResponse = JSON.parse(response);
              const finalUserData = userResponse.content.map((val, index) => {
                val.customData = JSON.parse(customResponse[index]);
                return val;
              });
              return [
                dispatch(FETCH_USER_CUSTOM_SUCCESS, {
                  content: finalUserData,
                  totalElements: userResponse.totalElements,
                  totalPages: userResponse.totalPages,
                }),
              ];
            }),
            catchError((err) => {
              return [dispatch(FETCH_USER_CUSTOM_ERROR, err)];
            })
          );
        }),
        catchError((error) => {
          return [dispatch(FETCH_USER_CUSTOM_ERROR, error)];
        })
      );
    })
  );
};

export const updateResidentUser = (action$) => {
  return action$.pipe(
    ofType(UPDATE_RESIDENT_USER_REQUEST),
    mergeMap(({ payload }) => {
      return usersService.updateUser(payload.userObj).pipe(
        mergeMap(() => {
          return [dispatch(FETCH_USER_CUSTOM_REQUEST, payload.paginationObj)];
        }),
        catchError((err) => {
          return [dispatch(UPDATE_RESIDENT_USER_ERROR, err)];
        })
      );
    })
  );
};

export const deleteResidentDevice = (action$) => {
  return action$.pipe(
    ofType(DELETE_RESIDENT_DEVICE_REQUEST),
    mergeMap(({ payload }) => {
      return deviceService
        .getPracticeDeviceList({
          emailAddressLike: payload.userInfo.emailAddress,
        })
        .pipe(
          mergeMap((response) => {
            const deviceDetails = payload.deviceList.find(
              (val) => val.deviceId === payload.deletedObj[0].deviceId
            );
            const deviceName = deviceDetails.name.split("-")[0];
            return deviceService.getDeviceList({ nameLike: deviceName }).pipe(
              mergeMap((val) => {
                const paramObj = JSON.parse(val).content.find(
                  (val) => val.name.split("-")[1].toLowerCase() === "params"
                );
                const thresholdObj = JSON.parse(val).content.find(
                  (val) => val.name.split("-")[1].toLowerCase() === "thresholds"
                );
                const identityObj = JSON.parse(val).content.find(
                  (val) => val.name.split("-")[1].toLowerCase() === "identity"
                );
                const devHealthObj = JSON.parse(val).content.find(
                  (val) => val.name.split("-")[1].toLowerCase() === "devhealth"
                );
                let propertyList = JSON.parse(response).content.reduce(
                  (acc, device) => {
                    if (device.value === payload.deletedObj[0].value) {
                      return [
                        ...acc,
                        deviceService.deleteResidentDevice(
                          device.patientDeviceId
                        ),
                      ];
                    }
                    return acc;
                  },
                  []
                );
                let arrayObj = [
                  ...propertyList,
                  deviceService.deleteResidentDeviceData({
                    deviceId: identityObj.deviceId,
                    ids: {
                      deviceDataIds: [payload.deletedObj[0].deviceDataId],
                    },
                  }),
                  deviceService.deleteResidentDeviceData({
                    deviceId: thresholdObj.deviceId,
                    ids: {
                      deviceDataIds: [payload.deletedObj[0].thresholdsDataId],
                    },
                  }),
                  deviceService.deleteResidentDeviceData({
                    deviceId: paramObj.deviceId,
                    ids: {
                      deviceDataIds: [payload.deletedObj[0].paramsDataId],
                    },
                  })
                ];
                if (payload.deletedObj[0].devHealthDataId) {
                  arrayObj = [...arrayObj, deviceService.deleteResidentDeviceData({
                    deviceId: devHealthObj.deviceId,
                    ids: {
                      deviceDataIds: [payload.deletedObj[0].devHealthDataId],
                    },
                  })]
                }
                return forkJoin(arrayObj).pipe(
                  mergeMap(() => {
                    return [
                      dispatch(DELETE_RESIDENT_DEVICE_SUCCESS),
                      dispatch(PATIENT_DETAIL_REQUEST, payload.userInfo.userId),
                    ];
                    // return [
                    //   dispatch(DELETE_RESIDENT_DEVICE_SUCCESS)
                    // ]
                  }),
                  catchError((err) => {
                    return [
                      dispatch(DELETE_RESIDENT_DEVICE_ERROR),
                      dispatch(ERROR_NOTIFICATION_REQUEST, {
                        response: {
                          message: "Error while deleting device information",
                        },
                      }),
                    ];
                  })
                );
              })
            );
          }),
          catchError((err) => {
            return [
              dispatch(DELETE_RESIDENT_DEVICE_ERROR),
              dispatch(ERROR_NOTIFICATION_REQUEST, {
                response: {
                  message: "Error while deleting device information",
                },
              }),
            ];
          })
        );
    })
  );
};

export const deleteResidentDeviceOnly = (action$) => {
  return action$.pipe(
    ofType(DELETE_RESIDENT_DEVICE_ONLY_REQUEST),
    mergeMap(({ payload }) => {
      return deviceService
        .getPracticeDeviceList({
          emailAddressLike: payload.userInfo.emailAddress,
        })
        .pipe(
          mergeMap((response) => {
            let arrayObj = [
              deviceService.fetchUserDeviceDataFromTable(
                payload.deletedObj[0].deviceId,
                payload.deletedObj[0].deviceDataId
              ),
              deviceService.getDevicePropertySetList(payload.deletedObj[0].deviceId)
            ]
            return forkJoin(arrayObj).pipe(
              mergeMap((val) => {
                const identltyTableData = JSON.parse(val[0]);
                const propertySetTableData = JSON.parse(val[1]).content;
                let newDataObj = Object.keys(identltyTableData.data).reduce((acc, val) => {
                  let obj = {};
                  obj[val] = identltyTableData.data[val].value;
                  return { ...acc, ...obj };
                }, {});
                newDataObj.Active = 'No';
                let finalObj = {
                  deviceDataId: payload.deletedObj[0].deviceDataId,
                  deviceId: payload.deletedObj[0].deviceId,
                  devicePropertySetId: propertySetTableData[0].devicePropertySetId,
                  data: newDataObj
                }
                let propertyList = JSON.parse(response).content.reduce(
                  (acc, device) => {
                    if (device.value === payload.deletedObj[0].value) {
                      return [
                        ...acc,
                        deviceService.deleteResidentDevice(
                          device.patientDeviceId
                        ),
                      ];
                    }
                    return acc;
                  },
                  []
                );
                let arrayObj = [
                  ...propertyList,
                  deviceService.createDeviceData(finalObj),
                ];
                return forkJoin(arrayObj).pipe(
                  mergeMap(() => {
                    return [
                      dispatch(DELETE_RESIDENT_DEVICE_ONLY_SUCCESS),
                      dispatch(PATIENT_DETAIL_REQUEST, payload.userInfo.userId),
                    ];
                  }),
                  catchError((err) => {
                    return [
                      dispatch(DELETE_RESIDENT_DEVICE_ONLY_ERROR),
                      dispatch(ERROR_NOTIFICATION_REQUEST, {
                        response: {
                          message: "Error while deleting device information",
                        },
                      }),
                    ];
                  })
                );
              })
            );
          }),
          catchError((err) => {
            return [
              dispatch(DELETE_RESIDENT_DEVICE_ONLY_ERROR),
              dispatch(ERROR_NOTIFICATION_REQUEST, {
                response: {
                  message: "Error while deleting device information",
                },
              }),
            ];
          })
        );
    })
  );
};

export const getInviteEmail = (action$) => {
  return action$.pipe(
    ofType(GET_INVITE_EMAIL_REQUEST),
    mergeMap(({ payload }) => {
      return usersService.getInviteEmail(payload).pipe(
        map((res) => {
          return dispatch(GET_INVITE_EMAIL_SUCCESS, JSON.parse(res));
        }),
        catchError((error) => {
          return dispatch(GET_INVITE_EMAIL_ERROR, error);
        })
      );
    })
  );
};

export const registerUser = (action$) => {
  return action$.pipe(
    ofType(REGISTER_USER_REQUEST),
    mergeMap(({ payload }) => {
      return usersService.registerUser(payload).pipe(
        mergeMap((res) => {
          return [dispatch(REGISTER_USER_SUCCESS)];
        }),
        catchError((error) => {
          console.log(error);
          let msg = "";
          if (error.status == 412) {
            msg = "User email address already exists for this tenant";
          } else {
            msg = "Some error, please try again";
          }
          return [dispatch(REGISTER_USER_ERROR, msg)];
        })
      );
    })
  );
};

export const activateUser = (action$) => {
  return action$.pipe(
    ofType(ACTIVATE_USER_REQUEST),
    mergeMap(({ payload }) => {
      return usersService.activateUser(payload).pipe(
        map((res) => {
          let token = res.xhr
            .getAllResponseHeaders()
            .split(":")[1]
            .replace("Bearer", "")
            .replace("cache-control", "")
            .trim();
            const userDetails = {
              token: token,
              ...JSON.parse(res.response)
            }
          localStorage.setItem("user-details", JSON.stringify(userDetails));
          return dispatch(ACTIVATE_USER_SUCCESS);
        }),
        catchError((error) => {
          return dispatch(ACTIVATE_USER_ERROR, error);
        })
      );
    })
  );
};

export const resendCode = (action$) => {
  return action$.pipe(
    ofType(RESEND_OTP_REQUEST),
    mergeMap(({ payload }) => {
      return usersService.resendCode(payload).pipe(
        map((res) => {
          return dispatch(RESEND_OTP_SUCCESS);
        }),
        catchError((error) => {
          console.log(error,'Resnd otp errro');
          return dispatch(RESEND_OTP_ERROR, error);
        })
      );
    })
  );
};

export const acceptTerms = (action$) => {
  return action$.pipe(
    ofType(ACCEPT_TERMS_REQUEST),
    mergeMap(({ payload }) => {
      return usersService.acceptTerms(payload).pipe(
        map((res) => {
          return dispatch(ACCEPT_TERMS_SUCCESS);
        }),
        catchError((error) => {
          return dispatch(ACCEPT_TERMS_ERROR, error);
        })
      );
    })
  );
};

export const acceptTermsUser = (action$) => {
  return action$.pipe(
    ofType(ACCEPT_TERMS_USER_REQUEST),
    mergeMap(({ payload }) => {
      return usersService.acceptTermsUser(payload).pipe(
        map((res) => {
          return dispatch(ACCEPT_TERMS_USER_SUCCESS);
        }),
        catchError((error) => {
          return dispatch(ACCEPT_TERMS_USER_ERROR, error);
        })
      );
    })
  );
};

export const getRefreshToken = (action$) => {
  return action$.pipe(
    ofType(REFRESH_TOKEN_REQUEST),
    mergeMap(({ payload }) => {
      return usersService.refreshToken(payload).pipe(
        map((res) => {
          let token = res.xhr
            .getAllResponseHeaders()
            .split(":")[1]
            .replace("Bearer", "")
            .replace("cache-control", "")
            .trim();

          return dispatch(REFRESH_TOKEN_SUCCESS, token);
        }),
        catchError((error) => {
          return dispatch(REFRESH_TOKEN_ERROR, error);
        })
      );
    })
  );
};
