import React, { useEffect } from "react";
import { useSelector } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import Checkbox from "@material-ui/core/Checkbox";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Loader from "components/Loader/Loader.js";

import {
  FETCH_USER_LIST_REQUEST,
  FETCH_CAREGIVER_LIST_REQUEST,
  UPDATE_ROLE_REQUEST,
  UPDATE_GUEST_REQUEST,
} from "../../redux/users/actions";

import { FETCH_COMPANY_REQUEST } from "../../redux/company/actions";
import { useComposeDispatcher } from "../../common/hooks";
import { PAGINATION_DETAILS, PAGE_SIZES } from "../../common/constants";
import styles from "assets/jss/material-dashboard-pro-react/views/userStyle.js";

const useStyles = makeStyles(styles);

const filterstyles = () => ({
  labelFocused: {
    color: "#E73F3B !important",
  },
  inputFocused: {
    "&:before": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "&:after": {
      borderBottom: "2px solid #E73F3B !important",
    },
    "& input": {
      borderBottom: "#E73F3B !important",
    },
  },
  inputRoot: {
    color: "red",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
});

const SelectBox = withStyles(filterstyles)((props) => {
  const { labelText, classes, value, onChange, options, required } = props;
  return (
    <TextField
      select
      fullWidth
      label={labelText}
      value={value}
      required={required ? required : false}
      margin="normal"
      color="primary"
      onChange={onChange}
      InputLabelProps={{
        shrink: true,
        classes: {
          focused: classes.labelFocused,
        },
      }}
      InputProps={{
        classes: {
          root: classes.root,
          focused: classes.inputFocused,
        },
      }}
    >
      {options}
    </TextField>
  );
});

export default function ResidentMap() {
  const classes = useStyles();
  const { users: userData, company } = useSelector((state) => ({
    users: state.users,
    company: state.company,
  }));
  const userDetails = JSON.parse(localStorage.getItem("user-details"));
  const loggedUserRole = userDetails !== null ? userDetails.roles[0].role : "";

  const [getUserList] = useComposeDispatcher(FETCH_USER_LIST_REQUEST);
  const [getCaregiverList] = useComposeDispatcher(FETCH_CAREGIVER_LIST_REQUEST);
  const [updateRole] = useComposeDispatcher(UPDATE_ROLE_REQUEST);
  const [updateGuestRole] = useComposeDispatcher(UPDATE_GUEST_REQUEST);
  const [getCompanyList] = useComposeDispatcher(FETCH_COMPANY_REQUEST);

  const [userRole, setUserRole] = React.useState("PracticeUser");
  const [userCompany, setUserCompany] = React.useState("");
  const [disableField, setDisableField] = React.useState(true);
  const [page, setPage] = React.useState(PAGINATION_DETAILS.pageNumber);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    PAGINATION_DETAILS.pageSize
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    let obj = {
      role: "Patient",
      pageNumber: newPage,
      pageSize: rowsPerPage,
      sortBy: "firstName",
      sortOrder: "ASC",
    };
    if (userCompany) {
      obj['supplierId'] = userCompany
    }
    getUserList(obj);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    let obj = {
      role: "Patient",
      pageNumber: 0,
      pageSize: +event.target.value,
      sortBy: "firstName",
      sortOrder: "ASC",
    }
    if (userCompany) {
      obj['supplierId'] = userCompany
    }
    getUserList(obj);
  };

  useEffect(() => {
    getCompanyList();
    getUserList({
      role: "Patient",
      pageNumber: page,
      pageSize: rowsPerPage,
      sortBy: "firstName",
      sortOrder: "ASC",
    });
    getCaregiverList();
  }, []);

  const caregiverTable = () => {
    return (
      <>
        <TableContainer className={classes.container}>
          <Table stickyColumn={0}>
            <TableHead>
              <TableRow>
                <TableCell
                  key="fullName"
                  style={{ maxWidth: 100 }}
                  align="center"
                >
                  <Typography>Resident Name</Typography>
                </TableCell>
                {userData.caregiveList.map((val) => (
                  <TableCell
                    key={val.userId}
                    style={{
                      minWidth: 150,
                      maxWidth: 150,
                      textAlign: "center",
                    }}
                  >
                    <Typography>{val.fullName}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(userData.userList.totalElements === 0 ||
                userData.caregiveList.length === 0) && (
                  <TableRow>
                    <TableCell align="center" colSpan="6">
                      No records found
                  </TableCell>
                  </TableRow>
                )}
              {userData.userList.totalElements > 0 &&
                userData.caregiveList.length > 0 &&
                userData.userList.users.map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.userId}
                    >
                      <TableCell
                        style={{
                          minWidth: 150,
                          maxWidth: 150,
                          textAlign: "center",
                        }}
                      >
                        {row.fullName}
                      </TableCell>
                      {userData.caregiveList.map((val) => {
                        return (
                          <TableCell
                            style={{
                              minWidth: 80,
                              maxWidth: 80,
                              textAlign: "center",
                            }}
                            key={val.userId}
                          >
                            <Checkbox
                              disabled={disableField}
                              checked={
                                !!row.roles.find(
                                  (value) =>
                                    value?.supplier?.supplierId ===
                                    val.roles[0]?.supplier?.supplierId &&
                                    value?.practice?.practiceId ===
                                    val.roles[0]?.practice?.practiceId
                                )
                              }
                              onChange={(event) => {
                                if (event.target.checked) {
                                  val.roles[0].role = "Patient";
                                  if (
                                    row.roles.length === 1 &&
                                    row.roles[0].role === "Patient" &&
                                    row.roles[0].supplier &&
                                    row.roles[0].practice === null
                                  ) {
                                    val.roles[0].defaultRole = true;
                                    row.roles[0] = val.roles[0];
                                  } else {
                                    row.roles = [...row.roles, val.roles[0]];
                                  }
                                } else {
                                  if (row.roles.length === 1) {
                                    row.roles[0].practice = null;
                                  } else {
                                    const removeIndex = row.roles.findIndex(
                                      (value) =>
                                        value?.supplier?.supplierId ===
                                        val.roles[0]?.supplier?.supplierId &&
                                        value?.practice?.practiceId ===
                                        val.roles[0]?.practice?.practiceId
                                    );
                                    row.roles.splice(removeIndex, 1);
                                  }
                                }
                                updateRole({
                                  user: row,
                                  currentPage: {
                                    role: "Patient",
                                    pageNumber: page,
                                    pageSize: rowsPerPage,
                                    sortBy: "firstName",
                                    sortOrder: "ASC",
                                    supplierId: userCompany
                                  },
                                });
                              }}
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={PAGE_SIZES}
          component="div"
          count={
            userData.caregiveList.length !== 0
              ? userData.userList.totalElements
              : 0
          }
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </>
    );
  };

  const guestTable = () => {
    return (
      <>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {userData.userList.totalElements > 0 && (
                  <TableCell
                    key="fullName"
                    style={{
                      minWidth: 100,
                      maxWidth: 100,
                    }}
                    stickyColumn
                    align="center"
                  >
                    <Typography>Resident Name</Typography>
                  </TableCell>
                )}
                {userData.guestList.map((val) => (
                  <TableCell
                    key={val.userId}
                    style={{
                      minWidth: 150,
                      maxWidth: 150,
                      textAlign: "center",
                    }}
                  >
                    {val.fullName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(userData.guestList.length === 0 ||
                userData.userList.totalElements === 0) && (
                  <TableRow>
                    <TableCell align="center" colSpan="6">
                      No guest user found
                  </TableCell>
                  </TableRow>
                )}
              {userData.guestList.length > 0 &&
                userData.userList.users.map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.userId}
                    >
                      <TableCell
                        style={{
                          minWidth: 150,
                          maxWidth: 150,
                          textAlign: "center",
                        }}
                      >
                        {row.fullName}
                      </TableCell>
                      {userData.guestList.map((val) => {
                        return (
                          <TableCell
                            style={{
                              minWidth: 80,
                              maxWidth: 80,
                              textAlign: "center",
                            }}
                            key={val.userId}
                          >
                            <Checkbox
                              disabled={disableField}
                              checked={
                                !!row.roles.find(
                                  (value) =>
                                    value?.supplier?.supplierId ===
                                    val.roles[0]?.supplier?.supplierId &&
                                    value?.practice?.practiceId ===
                                    val.roles[0]?.practice?.practiceId
                                )
                              }
                              onChange={(event) => {
                                if (event.target.checked) {
                                  val.roles[0].role = "Patient";
                                  if (
                                    row.roles.length === 1 &&
                                    row.roles[0].role === "Patient" &&
                                    row.roles[0].supplier &&
                                    row.roles[0].practice === null
                                  ) {
                                    val.roles[0].defaultRole = true;
                                    row.roles[0] = val.roles[0];
                                  } else {
                                    row.roles = [
                                      ...row.roles,
                                      {
                                        role: "Patient",
                                        supplier: val.roles[0].supplier,
                                        practice: val.roles[0].practice,
                                        defaultRole: false,
                                      },
                                    ];
                                  }
                                  updateGuestRole({
                                    user: row,
                                    currentPage: {
                                      role: "Patient",
                                      pageNumber: page,
                                      pageSize: rowsPerPage,
                                      sortBy: "firstName",
                                      sortOrder: "ASC",
                                      supplierId: userCompany
                                    },
                                    guest: val,
                                  });
                                } else {
                                  if (row.roles.length === 1) {
                                    row.roles[0].practice = null;
                                  } else {
                                    const removeIndex = row.roles.findIndex(
                                      (value) =>
                                        value?.supplier?.supplierId ===
                                        val.roles[0]?.supplier?.supplierId &&
                                        value?.practice?.practiceId ===
                                        val.roles[0]?.practice?.practiceId
                                    );
                                    row.roles.splice(removeIndex, 1);
                                  }
                                  updateRole({
                                    user: row,
                                    currentPage: {
                                      role: "Patient",
                                      pageNumber: page,
                                      pageSize: rowsPerPage,
                                      sortBy: "firstName",
                                      sortOrder: "ASC",
                                      supplierId: userCompany
                                    },
                                  });
                                }
                              }}
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={PAGE_SIZES}
          component="div"
          count={
            userData.guestList.length !== 0
              ? userData.userList.totalElements
              : 0
          }
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </>
    );
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box
          bgcolor="#ffffff"
          display="flex"
          padding={1}
          borderRadius="5px"
          marginBottom={2}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <b>Filter By</b>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <SelectBox
                id="role"
                labelText="Select Role"
                required={true}
                value={userRole}
                onChange={(val) => {
                  setUserRole(val.target.value);
                }}
                options={[
                  { key: "PracticeUser", value: "Caregiver" },
                  { key: "Guest", value: "Guest" },
                ].map((val) => (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                    key={val.key}
                    value={val.key}
                  >
                    {val.value}
                  </MenuItem>
                ))}
              />
            </GridItem>
            {loggedUserRole === "TenantAdmin" && (
              <GridItem xs={12} sm={12} md={4}>
                <SelectBox
                  id="company"
                  labelText="Company"
                  value={userCompany}
                  required={true}
                  onChange={(val) => {
                    setUserCompany(val.target.value);
                    let obj = {
                      role: "Patient",
                      supplierId: val.target.value,
                      pageNumber: 0,
                      pageSize: rowsPerPage,
                      sortBy: "firstName",
                      sortOrder: "ASC",
                    };
                    setPage(0);
                    getUserList(obj);
                    getCaregiverList(val.target.value);
                  }}
                  options={company.companyList.map((val) => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      key={val.supplierId}
                      value={val.supplierId}
                    >
                      {val.name}
                    </MenuItem>
                  ))}
                />
              </GridItem>
            )}
          </GridContainer>
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="danger" icon>
            <CardIcon color="danger">
              <Person />
            </CardIcon>
            {(loggedUserRole === "SupplierAdmin" ||
              loggedUserRole === "TenantAdmin") && (
                <Box className={classes.cardIconTitle} style={{ float: "right" }}>
                  <Button
                    disabled={!userCompany}
                    color="primary"
                    onClick={() => {
                      setDisableField(disableField ? false : true)
                    }}
                  >
                    {disableField ? 'Edit' : 'Lock'}
                  </Button>
                </Box>
              )}
          </CardHeader>
          <CardBody>
            {(loggedUserRole === "SupplierAdmin" ||
              loggedUserRole === "TenantAdmin") && (
                <p
                  style={{
                    fontSize: "10px",
                    float: "right",
                  }}
                >
                  Note: Company is mandatory, before enabling Edit Button
                </p>
              )}
            {userRole === "PracticeUser" && caregiverTable()}
            {userRole === "Guest" && guestTable()}
            {alert}
            <Loader
              status={userData.userList.loading || userData.caregiverLoader}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
