import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
import Email from "@material-ui/icons/Email";
import DialpadIcon from '@material-ui/icons/Dialpad'
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Loader from "components/Loader/Loader.js";

import loginLogo from "assets/img/login-logo.png";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import { useComposeDispatcher } from "../../common/hooks";
import {
  FETCH_LOGIN_REQUEST,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_UPDATE_REQUEST,
  BACK_TO_OTP_REQUEST
} from "../../redux/users/actions";

const useStyles = makeStyles(styles);

export default function LoginPage() {
  const history = useHistory();

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [loginInfo, setLoginInfo] = React.useState({});
  const [loginSection, setLoginSection] = React.useState(true);
  const [otpSection, setOtpSection] = React.useState(false);
  const [tr, setTR] = React.useState(false);
  const [trMsg, setTRMsg] = React.useState('');
  const userData = useSelector(({ users }) => users);
  const [login] = useComposeDispatcher(FETCH_LOGIN_REQUEST);
  const [resetPassword] = useComposeDispatcher(RESET_PASSWORD_REQUEST);
  const [resetPasswordUpdate] = useComposeDispatcher(RESET_PASSWORD_UPDATE_REQUEST);
  const [backToOTP] = useComposeDispatcher(BACK_TO_OTP_REQUEST);

  useEffect(() => {
    if (userData.user?.userId && localStorage.getItem("user-details")) {
      history.push("/admin/dashboard");
    }

    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  const validateResetPasswordUpdate = (data) => {
    const passwordReq = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (
      !data.emailAddress ||
      !data.confirmPassword ||
      !data.newPassword ||
      !data.otpCode
    ) {
      setTR(true);
      setTRMsg("All fields are mandatory.");
    } else {
      if (!passwordReq.test(data?.newPassword)) {
        setTRMsg("Please enter valid password");
        setTR(true);
      } else if (data?.confirmPassword !== data.newPassword) {
        setTRMsg("Entered Password is not matching");
        setTR(true);
      } else {
        resetPasswordUpdate(data);
      }
    }
  }

  if(userData.sentResetPassword && !otpSection) {
    setOtpSection(true);
  }

  if(userData.resetPasswordStatus && otpSection) {
    setOtpSection(false);
    setLoginInfo({});
    setLoginSection(true);
  }

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="danger"
              >
                <img
                  alt="logo"
                  src={loginLogo}
                  style={{ width: "120px", borderRadius: "5px" }}
                />
                {/* <h4 className={classes.cardTitle}>Log in</h4> */}
              </CardHeader>
              <CardBody>
                {
                  otpSection &&
                  <>
                    <CustomInput
                      labelText="OTP Code"
                      id="otpCode"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      data={loginInfo}
                      onChange={setLoginInfo}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <DialpadIcon className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomInput
                      labelText="New Password"
                      id="newPassword"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      data={loginInfo}
                      onChange={setLoginInfo}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                          </Icon>
                          </InputAdornment>
                        ),
                        type: "password",
                        autoComplete: "off",
                      }}
                    />
                    <CustomInput
                      labelText="Confirm Password"
                      id="confirmPassword"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      data={loginInfo}
                      helperText="Note: Please enter password which contain min 8 letter, at least a symbol, upper and lower case letters and a number"
                      onChange={setLoginInfo}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                          </Icon>
                          </InputAdornment>
                        ),
                        type: "password",
                        autoComplete: "off",
                      }}
                    />
                  </>
                }
                {
                  !otpSection &&
                  <CustomInput
                    labelText="Email"
                    id="emailAddress"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    data={loginInfo}
                    onChange={setLoginInfo}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                }
                {loginSection && (
                  <CustomInput
                    labelText="Password"
                    id="password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    data={loginInfo}
                    onChange={setLoginInfo}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ),
                      type: "password",
                      autoComplete: "off",
                    }}
                  />
                )}
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
              <GridContainer justifyContent="center">
                  {loginSection && (
                    <>
                      <GridItem xs={12} sm={12} md={12}>
                        <Button color="danger" block
                          onClick={() => {
                            if (
                              loginInfo &&
                              loginInfo.emailAddress &&
                              loginInfo.password
                            ) {
                              login(loginInfo);
                            } else {
                              setTR(true);
                              setTRMsg("All fields are mandatory.");
                            }
                          }}
                        >
                          Login
                        </Button>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <Button color="danger" block
                          onClick={() => setLoginSection(false)}
                        >
                          Reset Password
                        </Button>
                      </GridItem>
                    </>
                  )}
                  {otpSection && (
                    <>
                      <GridItem xs={12} sm={12} md={12}>
                        <Button
                          color="danger"
                          // simple
                          size="md"
                          block
                          onClick={() => {
                            if (loginInfo && loginInfo.emailAddress) {
                              validateResetPasswordUpdate(loginInfo);
                              // setOtpSection(false);
                            } else {
                              setTR(true);
                              setTRMsg("All fields are mandatory.");
                            }
                          }}
                        >
                          Continue
                      </Button>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <Button
                          color="danger"
                          // simple
                          size="md"
                          block
                          onClick={() => {
                            backToOTP();
                            setInterval(() => {
                              setOtpSection(false)
                            }, 1500)
                          }}
                        >
                          Back to reset
                      </Button>
                      </GridItem>
                    </>
                  )}
                  {!loginSection && !otpSection && (
                    <>
                      <GridItem xs={12} sm={12} md={12}>
                        <Button
                          color="danger"
                          // simple
                          size="md"
                          block
                          onClick={() => {
                            if (loginInfo && loginInfo.emailAddress) {
                              resetPassword(loginInfo);
                              // setOtpSection(true);
                            } else {
                              setTR(true);
                              setTRMsg("All fields are mandatory.");
                            }
                          }}
                        >
                          Reset Password
                        </Button>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <Button
                          color="danger"
                          // simple
                          size="md"
                          block
                          onClick={() => setLoginSection(true)}
                        >
                          Back to login
                        </Button>
                      </GridItem>
                    </>
                  )}
                </GridContainer>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
        <Snackbar
          place="tr"
          color="danger"
          icon={AddAlert}
          message={trMsg}
          open={tr}
          closeNotification={() => setTR(false)}
          close
        />
        <Loader status={userData.loading} />
      </GridContainer>
    </div>
  );
}
