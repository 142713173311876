import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment-timezone";

// @material-ui/icons
import TableContainer from "@material-ui/core/TableContainer";
import { makeStyles } from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import AddAlert from "@material-ui/icons/AddAlert";
import TableRow from "@material-ui/core/TableRow";
import Person from "@material-ui/icons/Person";
import Table from "@material-ui/core/Table";

// core components
import styles from "assets/jss/material-dashboard-pro-react/views/userStyle.js";
import GridContainer from "components/Grid/GridContainer.js";
import { useComposeDispatcher } from "../../common/hooks";
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import Filter from "./../MonitorDashboard/Filter";
import Checkbox from "@material-ui/core/Checkbox";
import Loader from "components/Loader/Loader.js";
import Card from "components/Card/Card.js";
import InviteGuest  from "./inviteGuest";

import {
  FETCH_DEVICE_PROPSETID_REQUEST,
  FETCH_PATIENT_SENSOR_REQUEST,
  UPDATE_GUESTMAPPING_REQUEST,
  FETCH_GUESTS_REQUEST,
} from "../../redux/guestMap/actions";

import { FETCH_FRIENDLY_NAME_REQUEST,  FETCH_DEVICE_REQUEST } from "../../redux/device/actions";
import { FETCH_USER_LIST_REQUEST } from "../../redux/users/actions";
import { FETCH_COMPANY_REQUEST } from "../../redux/company/actions";

const useStyles = makeStyles(styles);

export default function GuestMap() {
  const { guestMapData, company, device, users } = useSelector((state) => ({
    guestMapData: state.guestMap,
    company: state.company,
    device: state.device,
    users: state.users,
  }));

  const [userInfo, setUserInfo] = useState({
    interval: "1 Hr",
    deviceId: "d36a4373-fbdc-44a3-8c96-4bb920041e40",
    macAddress: "all",
    intervalStartTime: new Date(),
    supplierId: "cd5dc314-6e25-48a8-9d47-9e574b8fe3a0",
  });

  const userDetails = JSON.parse(localStorage.getItem("user-details")),
    loggedUserId = userDetails !== null ? userDetails.userId : "",
    loggedUserRole = userDetails !== null ? userDetails.roles[0].role : "";

  const [getPatientSensorList] = useComposeDispatcher(FETCH_PATIENT_SENSOR_REQUEST);
  const [getDevicePropSetId] = useComposeDispatcher(FETCH_DEVICE_PROPSETID_REQUEST);
  const [fetchFriendlyNameData] = useComposeDispatcher(FETCH_FRIENDLY_NAME_REQUEST);
  const [updateGuest] = useComposeDispatcher(UPDATE_GUESTMAPPING_REQUEST);
  const [getCompanyList] = useComposeDispatcher(FETCH_COMPANY_REQUEST);
  const [getUserList] = useComposeDispatcher(FETCH_USER_LIST_REQUEST);
  const [getDeviceList] = useComposeDispatcher(FETCH_DEVICE_REQUEST);
  const [getGuestsList] = useComposeDispatcher(FETCH_GUESTS_REQUEST);
  const classes = useStyles();

  const [showInviteDialog, setShowInviteDialog] = useState(false);
  const [toastStatus, setToastStatus] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isUpdatedData, setIsUpdatedData] = useState(false);

  useEffect(() => {
    if (loggedUserRole === "TenantAdmin") {
      setUserInfo({ ...userInfo, userId: loggedUserId });
      getDeviceList({status: "Active"});
      getCompanyList();
      getUserList({
        role: "Patient",
        pageNumber: 0,
        pageSize: 100,
        sortBy: "firstName",
        sortOrder: "ASC",
      });
    } else {
      getPatientSensorList({
        "deviceDataModelId": "d36a4373-fbdc-44a3-8c96-4bb920041e40-id",
        "devicePropertyCodes": ["FriendlyName", "Devid"],
        "ownerFilter": {"users": [loggedUserId]}
      });
      setIsUpdatedData(true);
    }

    getGuestsList({"deviceDataModelId": "299992ad-6d4a-4020-8501-8a004d45fffc"});
    getDevicePropSetId("299992ad-6d4a-4020-8501-8a004d45fffc");

    return () => {
      setIsUpdatedData(false);
    };
  }, []);

  const onGuestMap = (event) => {
    const val = event.target.value,
      isChecked = event.target.checked,
      currEle = val.split('@')[1].split('!')[0];

    let alreadyMapped = val.length && val.split('@').length && val.split('@')[0].split('/');
    
    if (isChecked) {
      alreadyMapped = [...alreadyMapped, currEle];
    } else if (alreadyMapped.indexOf(currEle) != -1) {
      alreadyMapped.splice(alreadyMapped.indexOf(currEle), 1);
    }
    const currDate = moment().utc().format("YYYY-MM-DDTHH:mm:ss");
    const payload = {
      "deviceDataModelId": "299992ad-6d4a-4020-8501-8a004d45fffc",
      "data": {"Macaddreess": alreadyMapped, "Timestampgm": currDate},
      "devicePropertySetId": guestMapData.devicePropSetId,
      "deviceDataId": val.split('!')[1]
    }

    /* console.log(payload, guestMapData); */
    updateGuest(payload);
  };

  /* In case Guest Update, Guest data also should update on Local */
  useEffect(() => {
    if (guestMapData.guestUpdated) {
      getGuestsList({"deviceDataModelId": "299992ad-6d4a-4020-8501-8a004d45fffc"});
    }
  }, [guestMapData.guestUpdated]);

  const getDeviceData = () => {
    /* console.log('Get device data called', userInfo); */
    if (userInfo?.userId) {
      getPatientSensorList({
        "deviceDataModelId": "d36a4373-fbdc-44a3-8c96-4bb920041e40-id",
        "devicePropertyCodes": ["FriendlyName", "Devid"],
        "ownerFilter": {"users": [userInfo?.userId]}
      });
      setIsUpdatedData(true);
    }
  }

  const onInviteGuest = () => {
    if (loggedUserRole === "TenantAdmin") {
      if (userInfo?.userId) {
        setShowInviteDialog(true)  
      } else {
        setAlertMessage("Please select user first to Invite");
        setToastStatus(true);
      }
    } else {
      setShowInviteDialog(true)
    }
  }

  return (
    <GridContainer>
      {loggedUserRole === "TenantAdmin" && 
        <Filter
            fetchFriendlyNameData={fetchFriendlyNameData}
            getDeviceData={getDeviceData}
            userList={users.userList}
            setUserInfo={setUserInfo}
            getUserList={getUserList}
            userInfo={userInfo}
            company={company}
            device={device}
          />
      }
      <GridItem xs={12}>
        <Card>
          <CardHeader color="danger" icon>
            <CardIcon color="danger"> <Person /></CardIcon>
            {isUpdatedData && <p className="tabelName">{guestMapData?.patientSensorsList[0]?.user?.fullName}</p>}
            <Button className="inviteBtnHdr" color="danger" onClick={onInviteGuest}>Invite Guest</Button>
          </CardHeader>
          <CardBody>
            {!guestMapData.loading && guestMapData?.patientSensorsList?.length > 0 && isUpdatedData && 
            <>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell key="root" align="left">Sensors</TableCell>
                      {guestMapData.patientSensorsList.map((column, i) => (
                        <TableCell key={i} align="left">{column?.data?.FriendlyName?.value}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {guestMapData.guestsList.map((column, i) => (
                      <TableRow key={i}>
                        <TableCell key={i} align="left">{column?.data?.Guestname?.value}</TableCell>
                        {guestMapData.patientSensorsList.map((ps, ind) => (
                          <TableCell key={ind} align="left">
                            <Checkbox
                              inputProps={{ "aria-label": "select all" }}
                              onChange={onGuestMap}
                              checked={column?.data?.Macaddreess?.value.indexOf(ps?.data?.Devid?.value) != -1 ? true : false}
                              value={column?.data?.Macaddreess?.value.join('/') + '@' + ps?.data?.Devid?.value + '!' + column?.deviceDataId}
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
            }
            {((!guestMapData?.patientSensorsList?.length && !guestMapData.loading) || !isUpdatedData) &&
             <>
              <div className="noGuestFound">
                  <p align="center">You do not have Guest invited. Please invite guest first</p>
                  <Button color="danger" onClick={onInviteGuest}>Invite Guest</Button>
              </div>
             </>
            }
            <Loader status={guestMapData.loading} />
          </CardBody>
        </Card>
      </GridItem>
      <InviteGuest
        showInviteDialog={showInviteDialog}
        setShowInviteDialog={setShowInviteDialog}
      />
      <Snackbar
        closeNotification={() => setToastStatus(false)}
        message={alertMessage}
        open={toastStatus}
        icon={AddAlert}
        color="danger"
        place="tr"
        close
      />
    </GridContainer>
  );
}
