import * as service from "../common/service";
import { API_BASE_URL } from "../common/constants";

const auditLogsApi = `${API_BASE_URL}/audit/logs`;

const auditLogService = {
  getAuditLogsList: ({ pageNumber, pageSize, sortBy, sortOrder, filter }) => {
    return service.postJson(
      `${auditLogsApi}?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
      {
        fromDateTime: filter.fromDateTime,
        toDateTime: filter.toDateTime,
        userRole: filter?.userRole,
      },
      "json"
    );
  },
};

export default auditLogService;
