import MonitorDashboard from "views/MonitorDashboard/MonitorDashboard.js";
import Manufacturer from "views/Manufacturer/Manufacturer.js";
import ResidentMap from "views/ResidentMap/ResidentMap.js";
import DeviceTable from "views/DeviceTable/DeviceTable.js";
import AuditLogs from "views/AuditLogs/AuditLogs.js";
import DiagnosticPanel from "views/DiagnosticPanel";
import Patient from "views/Patient/Patient.js";
import Company from "views/Company/Company.js";
import Devices from "views/Devices/Devices.js";
import Observe from "views/Observe/Observe.js";
import Users from "views/Users/Users.js";
import ErrorLogs from "views/ErrorLogs";
import AlertLogs from "views/AlertLogs";
import GuestMap from "views/GuestMap";
import Invites from "views/Invites";

// @material-ui/icons
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import PeopleIcon from "@material-ui/icons/People";
import DvrIcon from "@material-ui/icons/Dvr";
import AssessmentIcon from "@material-ui/icons/Assessment";
import BusinessIcon from "@material-ui/icons/Business";
import RouterIcon from "@material-ui/icons/Router";
import PersonIcon from "@material-ui/icons/Person";
import TocIcon from '@material-ui/icons/Toc';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import TimelineIcon from '@material-ui/icons/Timeline';
import LanIcon from '@material-ui/icons/People';

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Monitor Dashboard",
    roles: [
      "TenantAdmin",
      "SupplierAdmin",
      "SupplierUser",
      "PracticeUser",
      "Patient",
      "Guest"
    ],
    icon: DvrIcon,
    component: MonitorDashboard,
    layout: "/admin",
  },
  {
    path: "/table",
    name: "Device Data",
    roles: [
      "TenantAdmin",
      "SupplierAdmin",
    ],
    icon: TocIcon,
    component: DeviceTable,
    layout: "/admin",
  },
  {
    name: "Device Table",
    roles: ["TenantAdmin"],
    icon: RouterIcon,
    path: "/device",
    component: Devices,
    layout: "/admin",
  },
  {
    path: "/manufacturer",
    name: "Manufacturer",
    roles: ["TenantAdmin"],
    icon: PersonIcon,
    component: Manufacturer,
    layout: "/admin",
  },
  {
    path: "/user",
    name: "User",
    roles: ["TenantAdmin", "SupplierAdmin", "SupplierUser"],
    icon: PeopleIcon,
    component: Users,
    layout: "/admin",
  },
  {
    path: "/resident",
    name: "Resident",
    roles: ["TenantAdmin", "SupplierAdmin", "SupplierUser", "PracticeUser"],
    icon: PeopleIcon,
    component: Patient,
    layout: "/admin",
  },
  {
    path: "/company",
    name: "Company",
    roles: ["TenantAdmin"],
    icon: BusinessIcon,
    layout: "/admin",
    component: Company,
  },
  {
    path: "/map-resident",
    roles: ["TenantAdmin", "SupplierAdmin", "SupplierUser", "PracticeUser"],
    name: "Resident Map",
    icon: AccountTreeIcon,
    component: ResidentMap,
    layout: "/admin",
  },
  {
    path: "/audit-logs",
    name: "Audit Logs",
    roles: ["TenantAdmin"],
    icon: AssessmentIcon,
    component: AuditLogs,
    layout: "/admin",
  },
  {
    path: "/error-logs",
    name: "Error Logs",
    roles: ["TenantAdmin"],
    icon: AssessmentIcon,
    component: ErrorLogs,
    layout: "/admin",
  },
  {
    path: "/observe",
    name: "Users",
    roles: [
      "Patient",
      "Guest"
    ],
    icon: PeopleIcon,
    component: Observe,
    layout: "/admin",
  },
  {
    path: "/alert-logs",
    name: "Alert Logs",
    roles: ["TenantAdmin", "Patient",  "Guest"],
    icon: VerticalSplitIcon,
    component: AlertLogs,
    layout: "/admin",
  },
  {
    path: "/diagnostic-panel",
    name: "Diagnostic Panel",
    roles: ["TenantAdmin"],
    icon: TimelineIcon,
    component: DiagnosticPanel,
    layout: "/admin",
  },
  {
    path: "/guest-map",
    name: "Guest Map",
    roles: ["Patient", "TenantAdmin"],
    icon: DeveloperBoardIcon,
    component: GuestMap,
    layout: "/admin",
  },
  {
    path: "/invites",
    name: "Invites",
    roles: ["Guest"],
    icon: LanIcon,
    component: Invites,
    layout: "/admin",
  }
];
export default dashRoutes;
