// Options Actions
export const FETCH_DEVICE_REQUEST = "FETCH_DEVICE_REQUEST";
export const FETCH_DEVICE_SUCCESS = "FETCH_DEVICE_SUCCESS";
export const FETCH_DEVICE_ERROR = "FETCH_DEVICE_ERROR";

export const FETCH_DEVICE_PROPERTY_REQUEST = "FETCH_DEVICE_PROPERTY_REQUEST";
export const FETCH_DEVICE_PROPERTY_SUCCESS = "FETCH_DEVICE_PROPERTY_SUCCESS";
export const FETCH_DEVICE_PROPERTY_ERROR = "FETCH_DEVICE_PROPERTY_ERROR";

export const GET_DEVICE_DETAIL_REQUEST = "GET_DEVICE_DETAIL_REQUEST";
export const GET_DEVICE_DETAIL_SUCCESS = "GET_DEVICE_DETAIL_SUCCESS";
export const GET_DEVICE_DETAIL_ERROR = "GET_DEVICE_DETAIL_ERROR";

export const FETCH_DEVICE_PROPERTY_SET_REQUEST =
  "FETCH_DEVICE_PROPERTY_SET_REQUEST";
export const FETCH_DEVICE_PROPERTY_SET_SUCCESS =
  "FETCH_DEVICE_PROPERTY_SET_SUCCESS";
export const FETCH_DEVICE_PROPERTY_SET_ERROR =
  "FETCH_DEVICE_PROPERTY_SET_ERROR";

export const ADD_DEVICE_REQUEST = "ADD_DEVICE_REQUEST";
export const ADD_DEVICE_SUCCESS = "ADD_DEVICE_SUCCESS";
export const ADD_DEVICE_ERROR = "ADD_DEVICE_ERROR";

export const DELETE_DEVICE_REQUEST = "DELETE_DEVICE_REQUEST";
export const DELETE_DEVICE_SUCCESS = "DELETE_DEVICE_SUCCESS";
export const DELETE_DEVICE_ERROR = "DELETE_DEVICE_ERROR";

export const GET_DEVICE_DETAIL_PROPERTY_ERROR =
  "GET_DEVICE_DETAIL_PROPERTY_ERROR";

export const UPDATE_DEVICE_REQUEST = "UPDATE_DEVICE_REQUEST";
export const UPDATE_DEVICE_SUCCESS = "UPDATE_DEVICE_SUCCESS";
export const UPDATE_DEVICE_ERROR = "UPDATE_DEVICE_ERROR";

export const CREATE_PROPERTY_SET_REQUEST = "CREATE_PROPERTY_SET_REQUEST";
export const CREATE_PROPERTY_SET_SUCCESS = "CREATE_PROPERTY_SET_SUCCESS";
export const CREATE_PROPERTY_SET_ERROR = "CREATE_PROPERTY_SET_ERROR";

export const DELETE_PROPERTY_SET_REQUEST = "DELETE_PROPERTY_SET_REQUEST";
export const DELETE_PROPERTY_SET_SUCCESS = "DELETE_PROPERTY_SET_SUCCESS";
export const DELETE_PROPERTY_SET_ERROR = "DELETE_PROPERTY_SET_ERROR";

export const RESET_REQUEST = "RESET_REQUEST";
export const RESET_SUCCESS = "RESET_SUCCESS";

export const FETCH_PROPERTY_SET_DETAIL_REQUEST =
  "FETCH_PROPERTY_SET_DETAIL_REQUEST";
export const FETCH_PROPERTY_SET_DETAIL_SUCCESS =
  "FETCH_PROPERTY_SET_DETAIL_SUCCESS";
export const FETCH_PROPERTY_SET_DETAIL_ERROR =
  "FETCH_PROPERTY_SET_DETAIL_ERROR";

export const UPDATE_PROPERTY_SET_REQUEST = "UPDATE_PROPERTY_SET_REQUEST";
export const UPDATE_PROPERTY_SET_SUCCESS = "UPDATE_PROPERTY_SET_SUCCESS";
export const UPDATE_PROPERTY_SET_ERROR = "UPDATE_PROPERTY_SET_ERROR";

export const GET_DEVICE_PROPERTY_REQUEST = "GET_DEVICE_PROPERTY_REQUEST";
export const GET_DEVICE_PROPERTY_SUCCESS = "GET_DEVICE_PROPERTY_SUCCESS";
export const GET_DEVICE_PROPERTY_ERROR = "GET_DEVICE_PROPERTY_ERROR";

export const UPDATE_DEVICE_PROPERTY_REQUEST = "UPDATE_DEVICE_PROPERTY_REQUEST";
export const UPDATE_DEVICE_PROPERTY_SUCCESS = "UPDATE_DEVICE_PROPERTY_SUCCESS";
export const UPDATE_DEVICE_PROPERTY_ERROR = "UPDATE_DEVICE_PROPERTY_ERROR";

export const CREATE_DEVICE_PROPERTY_REQUEST = "CREATE_DEVICE_PROPERTY_REQUEST";
export const CREATE_DEVICE_PROPERTY_SUCCESS = "CREATE_DEVICE_PROPERTY_SUCCESS";
export const CREATE_DEVICE_PROPERTY_ERROR = "CREATE_DEVICE_PROPERTY_ERROR";

export const DELETE_PROPERTY_REQUEST = "DELETE_PROPERTY_REQUEST";
export const DELETE_PROPERTY_SUCCESS = "DELETE_PROPERTY_SUCCESS";
export const DELETE_PROPERTY_ERROR = "DELETE_PROPERTY_ERROR";

export const FETCH_DEVICE_FIRMWARE_REQUEST = "FETCH_DEVICE_FIRMWARE_REQUEST";
export const FETCH_DEVICE_FIRMWARE_SUCCESS = "FETCH_DEVICE_FIRMWARE_SUCCESS";
export const FETCH_DEVICE_FIRMWARE_ERROR = "FETCH_DEVICE_FIRMWARE_ERROR";

export const FETCH_FIRMWARE_DETAIL_REQUEST = "FETCH_FIRMWARE_DETAIL_REQUEST";
export const FETCH_FIRMWARE_DETAIL_SUCCESS = "FETCH_FIRMWARE_DETAIL_SUCCESS";
export const FETCH_FIRMWARE_DETAIL_ERROR = "FETCH_FIRMWARE_DETAIL_ERROR";

export const UPDATE_FIRMWARE_DETAIL_REQUEST = "UPDATE_FIRMWARE_DETAIL_REQUEST";
export const UPDATE_FIRMWARE_DETAIL_SUCCESS = "UPDATE_FIRMWARE_DETAIL_SUCCESS";
export const UPDATE_FIRMWARE_DETAIL_ERROR = "UPDATE_FIRMWARE_DETAIL_ERROR";

export const CREATE_FIRMWARE_DETAIL_REQUEST = "CREATE_FIRMWARE_DETAIL_REQUEST";
export const CREATE_FIRMWARE_DETAIL_SUCCESS = "CREATE_FIRMWARE_DETAIL_SUCCESS";
export const CREATE_FIRMWARE_DETAIL_ERROR = "CREATE_FIRMWARE_DETAIL_ERROR";

export const DELETE_DEVICE_FIRMWARE_REQUEST = "DELETE_DEVICE_FIRMWARE_REQUEST";
export const DELETE_DEVICE_FIRMWARE_SUCCESS = "DELETE_DEVICE_FIRMWARE_SUCCESS";
export const DELETE_DEVICE_FIRMWARE_ERROR = "DELETE_DEVICE_FIRMWARE_ERROR";

export const FETCH_DEVICE_DATA_REQUEST = "FETCH_DEVICE_DATA_REQUEST";
export const FETCH_DEVICE_DATA_SUCCESS = "FETCH_DEVICE_DATA_SUCCESS";
export const FETCH_DEVICE_DATA_ERROR = "FETCH_DEVICE_DATA_ERROR";

export const DUPLICATE_DEVICE_REQUEST = "DUPLICATE_DEVICE_REQUEST";
export const DUPLICATE_DEVICE_SUCCESS = "DUPLICATE_DEVICE_SUCCESS";
export const DUPLICATE_DEVICE_ERROR = "DUPLICATE_DEVICE_ERROR";

export const FETCH_NEW_DATA_REQUEST = "FETCH_NEW_DATA_REQUEST";
export const FETCH_NEW_DATA_SUCCESS = "FETCH_NEW_DATA_SUCCESS";
export const FETCH_NEW_DATA_ERROR = "FETCH_NEW_DATA_ERROR";

export const FETCH_UPDATE_USER_DATA_REQUEST = "FETCH_UPDATE_USER_DATA_REQUEST";
export const FETCH_NEW_USER_DATA_REQUEST = "FETCH_NEW_USER_DATA_REQUEST";
export const FETCH_NEW_USER_DATA_SUCCESS = "FETCH_NEW_USER_DATA_SUCCESS";
export const FETCH_NEW_USER_DATA_ERROR = "FETCH_NEW_USER_DATA_ERROR";

export const RESET_GRAPH_REQUEST = "RESET_GRAPH_REQUEST";
export const RESET_GRAPH_SUCCESS = "RESET_GRAPH_SUCCESS";
export const RESET_GRAPH_ERROR = "RESET_GRAPH_ERROR";

export const GET_SOMETHING_CANCELLED = 'GET_SOMETHING_CANCELLED';

export const FETCH_DEVICE_SPECIFIED_REQUEST = 'FETCH_DEVICE_SPECIFIED_REQUEST';
export const FETCH_DEVICE_SPECIFIED_SUCCESS = 'FETCH_DEVICE_SPECIFIED_SUCCESS';
export const FETCH_DEVICE_SPECIFIED_ERROR = 'FETCH_DEVICE_SPECIFIED_ERROR';

export const GET_PROPERTY_DATA_REQUEST = 'GET_PROPERTY_DATA_REQUEST';
export const GET_PROPERTY_DATA_SUCCESS = 'GET_PROPERTY_DATA_SUCCESS';
export const GET_PROPERTY_DATA_ERROR = 'GET_PROPERTY_DATA_ERROR';

export const CREATE_DEVICE_DATA_REQUEST = 'CREATE_DEVICE_DATA_REQUEST';
export const CREATE_DEVICE_DATA_SUCCESS = 'CREATE_DEVICE_DATA_SUCCESS';
export const CREATE_DEVICE_DATA_ERROR = 'CREATE_DEVICE_DATA_ERROR';

export const UPDATE_DEVICE_DATA_REQUEST = 'UPDATE_DEVICE_DATA_REQUEST';
export const UPDATE_DEVICE_DATA_SUCCESS = 'UPDATE_DEVICE_DATA_SUCCESS';
export const UPDATE_DEVICE_DATA_ERROR = 'UPDATE_DEVICE_DATA_ERROR';

export const UPDATE_DEFAULT_DATA_REQUEST = 'UPDATE_DEFAULT_DATA_REQUEST';
export const UPDATE_DEFAULT_DATA_SUCCESS = 'UPDATE_DEFAULT_DATA_SUCCESS';
export const UPDATE_DEFAULT_DATA_ERROR = 'UPDATE_DEFAULT_DATA_ERROR';

export const UPDATE_DEFAULT_THRESHOLD_REQUEST = 'UPDATE_DEFAULT_THRESHOLD_REQUEST';
export const UPDATE_DEFAULT_THRESHOLD_SUCCESS = 'UPDATE_DEFAULT_THRESHOLD_SUCCESS';
export const UPDATE_DEFAULT_THRESHOLD_ERROR = 'UPDATE_DEFAULT_THRESHOLD_ERROR';

export const DELETE_DEVICE_DATA_REQUEST = 'DELETE_DEVICE_DATA_REQUEST';
export const DELETE_DEVICE_DATA_SUCCESS = 'DELETE_DEVICE_DATA_SUCCESS';
export const DELETE_DEVICE_DATA_ERROR = 'DELETE_DEVICE_DATA_ERROR';

export const SYNC_FRIENDLY_NAME_REQUEST = 'SYNC_FRIENDLY_NAME_REQUEST';
export const SYNC_FRIENDLY_NAME_SUCCESS = 'SYNC_FRIENDLY_NAME_SUCCESS';
export const SYNC_FRIENDLY_NAME_ERROR = 'SYNC_FRIENDLY_NAME_ERROR';

export const FETCH_HW_SW_VERSION_REQUEST = 'FETCH_HW_SW_VERSION_REQUEST';
export const FETCH_HW_SW_VERSION_SUCCESS = 'FETCH_HW_SW_VERSION_SUCCESS';
export const FETCH_HW_SW_VERSION_ERROR = 'FETCH_HW_SW_VERSION_ERROR';

export const START_GRAPH_LOADER = 'START_GRAPH_LOADER';
export const START_GRAPH_SUCCESS = 'START_GRAPH_SUCCESS';

export const FETCH_CSV_DATA_REQUEST = 'FETCH_CSV_DATA_REQUEST';
export const FETCH_CSV_DATA_SUCCESS = 'FETCH_CSV_DATA_SUCCESS';
export const FETCH_CSV_DATA_ERROR = 'FETCH_CSV_DATA_ERROR';

export const FETCH_FRIENDLY_NAME_REQUEST = 'FETCH_FRIENDLY_NAME_REQUEST';
export const FETCH_FRIENDLY_NAME_SUCCESS = 'FETCH_FRIENDLY_NAME_SUCCESS';
export const FETCH_FRIENDLY_NAME_ERROR = 'FETCH_FRIENDLY_NAME_ERROR';

export const FETCH_NEW_USER_MIN_DATA_REQUEST = 'FETCH_NEW_USER_MIN_DATA_REQUEST';
export const FETCH_NEW_USER_MIN_DATA_SUCCESS = 'FETCH_NEW_USER_MIN_DATA_SUCCESS';
export const FETCH_NEW_USER_MIN_DATA_ERROR = 'FETCH_NEW_USER_MIN_DATA_ERROR';

export const FETCH_NEW_USER_MIN_DATA_UPDATE_REQUEST = 'FETCH_NEW_USER_MIN_DATA_UPDATE_REQUEST';
export const FETCH_NEW_USER_MIN_DATA_UPDATE_SUCCESS = 'FETCH_NEW_USER_MIN_DATA_UPDATE_SUCCESS';
export const FETCH_NEW_USER_MIN_DATA_UPDATE_ERROR = 'FETCH_NEW_USER_MIN_DATA_UPDATE_ERROR';

export const FETCH_ERROR_LOG_DATA_REQUEST = 'FETCH_ERROR_LOG_DATA_REQUEST';
export const FETCH_ERROR_LOG_DATA_SUCCESS = 'FETCH_ERROR_LOG_DATA_SUCCESS';
export const FETCH_ERROR_LOG_DATA_ERROR = 'FETCH_ERROR_LOG_DATA_ERROR';

export const DELETE_ERROR_LOG_DATA_REQUEST = 'DELETE_ERROR_LOG_DATA_REQUEST';
export const DELETE_ERROR_LOG_DATA_SUCCESS = 'DELETE_ERROR_LOG_DATA_SUCCESS';
export const DELETE_ERROR_LOG_DATA_ERROR = 'DELETE_ERROR_LOG_DATA_ERROR';

export const DOWNLOAD_DEVICE_FIRMWARE_REQUEST = "DOWNLOAD_DEVICE_FIRMWARE_REQUEST";
export const DOWNLOAD_DEVICE_FIRMWARE_SUCCESS = "DOWNLOAD_DEVICE_FIRMWARE_SUCCESS";
export const DOWNLOAD_DEVICE_FIRMWARE_ERROR = "DOWNLOAD_DEVICE_FIRMWARE_ERROR";

export const FETCH_ALERT_LOG_DATA_REQUEST = 'FETCH_ALERT_LOG_DATA_REQUEST';
export const FETCH_ALERT_LOG_DATA_SUCCESS = 'FETCH_ALERT_LOG_DATA_SUCCESS';
export const FETCH_ALERT_LOG_DATA_ERROR = 'FETCH_ALERT_LOG_DATA_ERROR';

export const DELETE_ALERT_LOG_DATA_REQUEST = 'DELETE_ALERT_LOG_DATA_REQUEST';
export const DELETE_ALERT_LOG_DATA_SUCCESS = 'DELETE_ALERT_LOG_DATA_SUCCESS';
export const DELETE_ALERT_LOG_DATA_ERROR = 'DELETE_ALERT_LOG_DATA_ERROR';

export const FETCH_NEW_DEV_DATA_REQUEST = 'FETCH_NEW_DEV_DATA_REQUEST';
export const FETCH_NEW_DEV_DATA_SUCCESS = 'FETCH_NEW_DEV_DATA_SUCCESS';
export const FETCH_NEW_DEV_DATA_ERROR = 'FETCH_NEW_DEV_DATA_ERROR';

export const FETCH_SVITTAL_DATA_REQUEST = 'FETCH_SVITTAL_DATA_REQUEST';
export const FETCH_SVITTAL_DATA_SUCCESS = 'FETCH_SVITTAL_DATA_SUCCESS';
export const FETCH_SVITTAL_DATA_ERROR = 'FETCH_SVITTAL_DATA_ERROR';

export const FETCH_MIN_CSV_DATA_REQUEST = 'FETCH_MIN_CSV_DATA_REQUEST';
export const FETCH_MIN_CSV_DATA_SUCCESS = 'FETCH_MIN_CSV_DATA_SUCCESS';
export const FETCH_MIN_CSV_DATA_ERROR = 'FETCH_MIN_CSV_DATA_ERROR';

export const FETCH_NEW_SV_DATA_REQUEST = 'FETCH_NEW_SV_DATA_REQUEST';
export const FETCH_NEW_SV_DATA_SUCCESS = 'FETCH_NEW_SV_DATA_SUCCESS';
export const FETCH_NEW_SV_DATA_ERROR = 'FETCH_NEW_SV_DATA_ERROR';

export const FETCH_SENSOR_CONTROL_REQUEST = 'FETCH_SENSOR_CONTROL_REQUEST';
export const FETCH_SENSOR_CONTROL_SUCCESS = 'FETCH_SENSOR_CONTROL_SUCCESS';
export const FETCH_SENSOR_CONTROL_ERROR = 'FETCH_SENSOR_CONTROL_ERROR';

export const UPDATE_SENSOR_CONTROL_REQUEST = 'UPDATE_SENSOR_CONTROL_REQUEST';
export const UPDATE_SENSOR_CONTROL_SUCCESS = 'UPDATE_SENSOR_CONTROL_SUCCESS';
export const UPDATE_SENSOR_CONTROL_ERROR = 'UPDATE_SENSOR_CONTROL_ERROR';

export const UPDATE_SENSOR_CONTROL_TABLE_REQUEST = 'UPDATE_SENSOR_CONTROL_TABLE_REQUEST';
export const UPDATE_SENSOR_CONTROL_TABLE_SUCCESS = 'UPDATE_SENSOR_CONTROL_TABLE_SUCCESS';
export const UPDATE_SENSOR_CONTROL_TABLE_ERROR = 'UPDATE_SENSOR_CONTROL_TABLE_ERROR';

export const FETCH_PARAMS_DATA_REQUEST = 'FETCH_PARAMS_DATA_REQUEST';
export const FETCH_PARAMS_DATA_SUCCESS = 'FETCH_PARAMS_DATA_SUCCESS';
export const FETCH_PARAMS_DATA_ERROR = 'FETCH_PARAMS_DATA_ERROR';

export const FETCH_DIAGNOSTIC_PANEL_DATA_REQUEST = 'FETCH_DIAGNOSTIC_PANEL_DATA_REQUEST';
export const FETCH_DIAGNOSTIC_PANEL_DATA_SUCCESS = 'FETCH_DIAGNOSTIC_PANEL_DATA_SUCCESS';
export const FETCH_DIAGNOSTIC_PANEL_DATA_ERROR = 'FETCH_DIAGNOSTIC_PANEL_DATA_ERROR';

export const RESET_DIAGNOSTIC_PANEL_DATA_REQUEST = 'RESET_DIAGNOSTIC_PANEL_DATA_REQUEST';
export const RESET_DIAGNOSTIC_PANEL_DATA_ERROR = 'RESET_DIAGNOSTIC_PANEL_DATA_ERROR';

export const SAVE_DIAGNOSTIC_PANEL_DATA_REQUEST = 'SAVE_DIAGNOSTIC_PANEL_DATA_REQUEST';
export const SAVE_DIAGNOSTIC_PANEL_DATA_SUCCESS = 'SAVE_DIAGNOSTIC_PANEL_DATA_SUCCESS';
export const SAVE_DIAGNOSTIC_PANEL_DATA_ERROR = 'SAVE_DIAGNOSTIC_PANEL_DATA_ERROR';

export const FETCH_SAVED_DIAGNOSTIC_PANEL_DATA_REQUEST = 'FETCH_SAVED_DIAGNOSTIC_PANEL_DATA_REQUEST';
export const FETCH_SAVED_DIAGNOSTIC_PANEL_DATA_SUCCESS = 'FETCH_SAVED_DIAGNOSTIC_PANEL_DATA_SUCCESS';
export const FETCH_SAVED_DIAGNOSTIC_PANEL_DATA_ERROR = 'FETCH_SAVED_DIAGNOSTIC_PANEL_DATA_ERROR';

export const FETCH_DIAGNOSTIC_PANEL_REQUEST = 'FETCH_DIAGNOSTIC_PANEL_REQUEST';
export const FETCH_DIAGNOSTIC_PANEL_SUCCESS = 'FETCH_DIAGNOSTIC_PANEL_SUCCESS';
export const FETCH_DIAGNOSTIC_PANEL_ERROR = 'FETCH_DIAGNOSTIC_PANEL_ERROR';

export const FETCH_READ_ALERT_LOG_DATA_REQUEST = 'FETCH_READ_ALERT_LOG_DATA_REQUEST';
export const FETCH_READ_ALERT_LOG_DATA_SUCCESS = 'FETCH_READ_ALERT_LOG_DATA_SUCCESS';
export const FETCH_READ_ALERT_LOG_DATA_ERROR = 'FETCH_READ_ALERT_LOG_DATA_ERROR';

export const FETCH_USER_ORDER_DATA_REQUEST = "FETCH_USER_ORDER_DATA_REQUEST";
export const FETCH_USER_ORDER_DATA_REQUEST_SUCCESS = "FETCH_USER_ORDER_DATA_REQUEST_SUCCESS";
export const FETCH_USER_ORDER_DATA_REQUEST_ERROR = "FETCH_USER_ORDER_DATA_REQUEST_ERROR";

export const FETCH_DEVICE_DETAILS_REQUEST = "FETCH_DEVICE_DETAILS_REQUEST";
export const FETCH_DEVICE_DETAILS_SUCCESS = "FETCH_DEVICE_DETAILS_SUCCESS";
export const FETCH_DEVICE_DETAILS_ERROR = "FETCH_DEVICE_DETAILS_ERROR";

export const SAVE_USER_ORDER_REQUEST = "SAVE_USER_ORDER_REQUEST";
export const SAVE_USER_ORDER_SUCCESS = "SAVE_USER_ORDER_SUCCESS";
export const SAVE_USER_ORDER_ERROR = "SAVE_USER_ORDER_ERROR";