import {
    FETCH_PATIENT_SENSOR_ERROR,
    FETCH_PATIENT_SENSOR_REQUEST,
    FETCH_PATIENT_SENSOR_SUCCESS,
    FETCH_GUESTS_REQUEST,
    FETCH_GUESTS_SUCCESS,
    FETCH_GUESTS_ERROR,
    FETCH_DEVICE_PROPSETID_SUCCESS,
    FETCH_DEVICE_PROPSETID_REQUEST,
    UPDATE_GUESTMAPPING_REQUEST,
    UPDATE_GUESTMAPPING_SUCCESS,
    UPDATE_GUESTMAPPING_ERROR,
    SEND_INVITE_REQUEST,
    SEND_INVITE_SUCCESS,
    SEND_INVITE_ERROR,
    ADD_GUESTMAP_REQUEST,
    ADD_GUESTMAP_SUCCESS,
    ADD_GUESTMAP_ERROR
} from "./actions";
import { REDUCERS } from "../../common/constants";
import { createReducer } from "../../common/helpers";
  
  const schema = {
    loading: false,
    error: false,
    patientSensorsList: [],
    guestsList: [],
    devicePropSetId: '',
    guestUpdated: false,
    inviteSent: false,
    isNewAccount: false
  };
  
  const reducer = {
    [FETCH_PATIENT_SENSOR_REQUEST]: (state) => {
      return {
        ...state,
        ...REDUCERS.LOADING,
      };
    },
    [FETCH_PATIENT_SENSOR_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        ...REDUCERS.SUCCESS,
        patientSensorsList: payload.content,
      };
    },
    [FETCH_PATIENT_SENSOR_ERROR]: (state) => ({
      ...state,
      ...REDUCERS.ERROR,
    }),
    [FETCH_GUESTS_REQUEST]: (state) => {
      return {
        ...state,
        ...REDUCERS.LOADING,
      };
    },
    [FETCH_GUESTS_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        ...REDUCERS.SUCCESS,
        guestsList: payload.content,
      };
    },
    [FETCH_GUESTS_ERROR]: (state) => ({
        ...state,
        ...REDUCERS.ERROR,
    }),
    [FETCH_DEVICE_PROPSETID_REQUEST]: (state) => {
      return {
        ...state,
        ...REDUCERS.LOADING,
      };
    },
    [FETCH_DEVICE_PROPSETID_SUCCESS]: (state, {payload}) => {
      return {
        ...state,
        ...REDUCERS.SUCCESS,
        devicePropSetId: payload
      };
    },
    [UPDATE_GUESTMAPPING_REQUEST]: (state) => {
      return {
        ...state,
        ...REDUCERS.LOADING,
        guestUpdated: false
      };
    },
    [UPDATE_GUESTMAPPING_SUCCESS]: (state) => {
      return {
        ...state,
        ...REDUCERS.SUCCESS,
        guestUpdated: true
      };
    },
    [UPDATE_GUESTMAPPING_ERROR]: (state) => ({
      ...state,
      ...REDUCERS.ERROR,
    }),
    [SEND_INVITE_REQUEST]: (state) => {
      return {
        ...state,
        ...REDUCERS.LOADING,
        loading: true,
        inviteSent: false,
        isNewAccount: false
      };
    },
    [SEND_INVITE_SUCCESS]: (state, payload) => {
      return {
        ...state,
        ...REDUCERS.SUCCESS,
        loading: false,
        inviteSent: true
      };
    },
    [SEND_INVITE_ERROR]: (state, payload) => ({
      ...state,
      ...REDUCERS.ERROR,
      loading: false,
      inviteSent: false,
      isNewAccount: payload
    }),
    [ADD_GUESTMAP_REQUEST]: (state) => {
      return {
        ...state,
        ...REDUCERS.LOADING
      };
    },
    [ADD_GUESTMAP_SUCCESS]: (state, payload) => {
      return {
        ...state,
        ...REDUCERS.SUCCESS
      };
    },
    [ADD_GUESTMAP_ERROR]: (state, {payload}) => ({
      ...state,
      ...REDUCERS.ERROR
    }),
  };
  
  export default createReducer(schema, reducer);
  