import {
  FETCH_AUDIT_LOGS_REQUEST,
  FETCH_AUDIT_LOGS_SUCCESS,
  FETCH_AUDIT_LOGS_ERROR,
} from "./actions";
import { REDUCERS } from "../../common/constants";
import { createReducer } from "../../common/helpers";

const schema = {
  loading: false,
  error: false,
  totalElements: 0,
  logList: [],
};

const reducer = {
  [FETCH_AUDIT_LOGS_REQUEST]: (state) => {
    return {
      ...state,
      ...REDUCERS.LOADING,
    };
  },
  [FETCH_AUDIT_LOGS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      ...REDUCERS.SUCCESS,
      totalElements: payload.totalElements,
      logList: payload.content,
    };
  },
  [FETCH_AUDIT_LOGS_ERROR]: (state) => ({
    ...state,
    ...REDUCERS.ERROR,
  }),
};

export default createReducer(schema, reducer);
