import * as service from "../common/service";
import { API_BASE_URL } from "../common/constants";
import moment from "moment";
const deviceApi = `${API_BASE_URL}/user/device`;
const devicePropertyAPI = `${API_BASE_URL}/user/deviceproperty`;
const createDevice = `${API_BASE_URL}/user/patientdevice`;
const devicePropertySetDetails = `${API_BASE_URL}/user/devicepropertyset`;
const deviceFirmware = `${API_BASE_URL}/user/firmware`;
const deviceAdvance = `${API_BASE_URL}/data/devicedata-advanced`;
const devicedata = `${API_BASE_URL}/data/devicedata`;
const deviceFirmwareDownload = `${API_BASE_URL}/user/firmware-media`;
const deviceCsvDownload = `${API_BASE_URL}/data/devicedata/aggregate-advanced`;

const deviceService = {
  getDeviceList: (payload) => {
    let filter = "";
    if (payload && Object.keys(payload).length > 0) {
      filter = Object.keys(payload).reduce(
        (acc, val, index) =>
          Object.keys(payload).length - 1 === index
            ? `${acc}${val}=${payload[val]}`
            : `${acc}${val}=${payload[val]}&`,
        "?"
      );
    }
    return service.get(`${deviceApi}${filter}`, {});
  },
  getDevicePropertyList: (payload) => {
    return service.get(`${devicePropertyAPI}?deviceDataModelId=${payload}`, {});
  },
  getDevicePropertySetList: (payload) => {
    return service.get(`${devicePropertySetDetails}?deviceDataModelId=${payload}`, {});
  },
  createDevice: (payload) => {
    const api = `${createDevice}?userId=${payload.userId}&deviceDataModelId=${payload.deviceId}&propertyCode=${payload.propertyCode}&value=${payload.value}`;
    return service.postJson(api);
  },
  /* updateDevice: (payload) => {
    const api = `${createDevice}?patientDeviceId=${payload.patientDeviceId}&value=${payload.value}`;
    return service.putJson(api);
  }, */
  deleteResidentDevice: (payload) => {
    const api = `${createDevice}?patientDeviceId=${payload}`;
    return service.deleteJson(api);
  },
  deleteResidentDeviceData: (payload) => {
    return service.postJson(
      `${API_BASE_URL}/data/${payload.deviceId}/devicedata/delete`,
      payload.ids
    );
  },
  getPracticeDeviceList: (payload) => {
    let filter = "";
    if (payload && Object.keys(payload).length > 0) {
      filter = Object.keys(payload).reduce(
        (acc, val, index) =>
          Object.keys(payload).length - 1 === index
            ? `${acc}${val}=${payload[val]}`
            : `${acc}${val}=${payload[val]}&`,
        "?"
      );
    }
    return service.get(`${createDevice}${filter}`, {});
  },
  getDeviceDetailById: (payload) => {
    return service.get(`${deviceApi}/${payload}`);
  },
  getDevicePropertyDetails: (payload) => {
    return service.get(`${devicePropertySetDetails}?deviceDataModelId=${payload}`);
  },
  addDevice: (payload) => {
    return service.postJson(deviceApi, payload);
  },
  deleteDevice: (payload) => {
    return service.deleteJson(`${deviceApi}?deviceDataModelId=${payload.deviceId}`);
  },
  updateDevice: (payload) => {
    return service.putJson(deviceApi, payload);
  },
  updatePropertyDevice: (payload) => {
    let filter = "";
    if (payload && Object.keys(payload).length > 0) {
      filter = Object.keys(payload).reduce(
        (acc, val, index) =>
          Object.keys(payload).length - 1 === index
            ? `${acc}${val}=${payload[val]}`
            : `${acc}${val}=${payload[val]}&`,
        "?"
      );
    }
    return service.putJson(`${createDevice}${filter}`);
  },
  addPropertySet: (payload) => {
    return service.postJson(
      `${devicePropertySetDetails}`,
      payload.propertyInfo
    );
  },
  deletePropertySet: (payload) => {
    return service.deleteJson(
      `${devicePropertySetDetails}?devicePropertySetId=${payload.devicePropertySetId}`
    );
  },
  getPropertySetDetailsById: (payload) => {
    return service.get(`${devicePropertySetDetails}/${payload.propertyId}`);
  },
  updatePropertySet: (payload) => {
    return service.putJson(`${devicePropertySetDetails}`, payload.propertyInfo);
  },
  getPropertyDetailsById: (payload) => {
    return service.get(`${devicePropertyAPI}/${payload}`);
  },
  updateProperty: (payload) => {
    return service.putJson(`${devicePropertyAPI}`, payload);
  },
  createProperty: (payload) => {
    return service.postJson(`${devicePropertyAPI}`, payload);
  },
  deleteProperty: (payload) => {
    return service.deleteJson(
      `${devicePropertyAPI}?devicePropertyId=${payload.devicePropertyId}`
    );
  },
  getDeviceFirmwareList: (payload) => {
    let filter = "";
    if (payload && Object.keys(payload).length > 0) {
      filter = Object.keys(payload).reduce(
        (acc, val, index) =>
          Object.keys(payload).length - 1 === index
            ? `${acc}${val}=${payload[val]}`
            : `${acc}${val}=${payload[val]}&`,
        "?"
      );
    }
    return service.get(`${deviceFirmware}${filter}`);
  },
  getFirmwareDetailsById: (payload) => {
    return service.get(`${deviceFirmware}/${payload.firmwareId}`);
  },
  updateFirmwareDetailsById: ({ userInfo }) => {
    return service.putJson(deviceFirmware, userInfo);
  },
  createFirmwareDetailsById: ({ userInfo }) => {
    const data = new FormData();
    data.append("deviceId", userInfo.deviceDataModelId);
    data.append("name", userInfo.name);
    data.append("displayVersion", userInfo.displayVersion);
    data.append("description", userInfo.description);
    data.append("fileHash", userInfo.fileHash);
    data.append("file", userInfo.file);
    return service.postMultipart(deviceFirmware, data);
  },
  deleteFirmwareDetailsById: (payload) => {
    return service.deleteJson(
      `${deviceFirmware}?firmwareId=${payload.firmwareId}`
    );
  },
  getDeviceDataList: (payload) => {
    return service.postJson(
      `${deviceAdvance}?sortBy=Timestamp&sortOrder=DESC`,
      payload
    );
  },
  getUserProfileData: (payload) => {
    return service.postVersionJson(
      `${deviceAdvance}/?pageSize=1&sortOrder=DESC`,
      payload
    );
  },
  getDeviceDataFromTimeStampList: (payload) => {
    return service.postJson(
      `${deviceAdvance}?sortBy=data.Timestamp.value&sortOrder=DESC`,
      payload
    );
  },
  getDeviceDataListFromDevTimeStamp: (payload) => {
    return service.postJson(
      `${deviceAdvance}?sortBy=data.TimestampD.value&sortOrder=DESC`,
      payload
    );
  },
  fetchUserDeviceData: (payload) => {
    return service.postJson(
      `${deviceAdvance}?sortBy=data.Timestamp.value&sortOrder=DESC`,
      payload
    );
  },
  fetchUserDeviceDataByTime: (payload) => {
    return service.postVersionJson(deviceCsvDownload, payload);
  },
  fetchUserDeviceDataSVittalByTime: (payload) => {
    return service.postJson(
      `${deviceAdvance}?sortBy=data.TimestampS.value&sortOrder=DESC`,
      payload
    );
  },
  fetchUserDeviceDataParamsByTime: (payload) => {
    return service.postJson(
      `${deviceAdvance}?sortBy=data.Timestamp.value&sortOrder=DESC`,
      payload
    );
  },
  fetchUserDeviceDataWithLimit: (payload) => {
    return service.postJson(
      `${deviceAdvance}?sortBy=maxValueProvidedOn&sortOrder=DESC&pageSize=1`,
      payload
    );
  },
  fetchUserDeviceAggregateData: (payload, filterData = '') => {
    let filter = "";
    if (filterData && Object.keys(filterData).length > 0) {
      filter = Object.keys(filterData).reduce(
        (acc, val, index) =>
          Object.keys(filterData).length - 1 === index
            ? `${acc}${val}=${filterData[val]}`
            : `${acc}${val}=${filterData[val]}&`,
        "?"
      );
    } else {
      filter = "?sortBy=data.Timestamp.value&sortOrder=DESC";
    }
    return service.postVersionJson(
      `${devicedata}/aggregate${filter}`,
      payload
    );
  },
  fetchUserDeviceAggregateFirstData: (payload) => {
    return service.postVersionJson(
      `${deviceAdvance}/owner?pageNumber=0&pageSize=2&sortBy=minValueProvidedOn&sortOrder=ASC`,
      payload
    );
  },
  fetchUserFirstPageData: (payload) => {
    return service.postJson(
      `${deviceAdvance}?sortBy=data.Timestamp.value&sortOrder=DESC&pageSize=5&pageNumber=0`,
      payload
    );
  },
  fetchTableDeviceData: ({ payload, data }) => {
    let filter = "";
    if (payload && Object.keys(payload).length > 0) {
      filter = Object.keys(payload).reduce(
        (acc, val, index) =>
          Object.keys(payload).length - 1 === index
            ? `${acc}${val}=${payload[val]}`
            : `${acc}${val}=${payload[val]}&`,
        "?"
      );
    }
    return service.postJson(`${deviceAdvance}/owner${filter}`, data);
  },
  createDeviceData: (payload) => {
    return service.postJson(devicedata, payload);
  },
  deleteDeviceData: (payload) => {
    return service.postJson(
      `${API_BASE_URL}/data/${payload.deviceDataModelId}/devicedata/delete`,
      {
        deviceDataIds: [payload.deviceDataId],
      }
    );
  },
  deleteMultipleDeviceData: (payload) => {
    let finalObj = {};
    finalObj.deviceCriteria = [
      {
        key: "ErrorLog",
        operator: "IsSet",
        value: null,
        valueFrom: null,
        valueTo: null,
        isDateValue: false,
      },
    ];
    if (payload.userIds) {
      finalObj.userIds = payload.userIds;
    } else {
      finalObj.deviceDataIds = payload.deviceDataIds;
    }
    return service.postJson(
      `${API_BASE_URL}/data/${payload.deviceDataModelId}/devicedata/delete`,
      finalObj
    );
  },
  deleteMultipleAlertDeviceData: (payload) => {
    let finalObj = {};
    if (payload.userIds) {
      finalObj.rangeEndDateTime = moment().utc().format("YYYY-MM-DDTHH:mm:ss");
      finalObj.rangeStartDateTime = moment().subtract(5, 'year').utc().format("YYYY-MM-DDTHH:mm:ss");
      finalObj.userIds = payload.userIds;
    } else {
      finalObj.deviceCriteria = [
        {
          key: "ErrorLog",
          operator: "IsSet",
          value: null,
          valueFrom: null,
          valueTo: null,
          isDateValue: false,
        },
      ];
      finalObj.deviceDataIds = payload.deviceDataIds;
    }
    return service.postJson(
      `${API_BASE_URL}/data/${payload.deviceDataModelId}/devicedata/delete`,
      finalObj
    );
  },
  downloadFirmwareVersion: (payload) => {
    return service.downloadFirmware(`${deviceFirmwareDownload}/${payload}`);
  },
  updateDeviceData: (payload) => {
    return service.postVersionJson(
      devicedata,
      payload
    );
  },
  fetchUserDeviceDataFromTable: (tableId, rowId) => {
    return service.get3Version(`${devicedata}/${tableId}/${rowId}`);
  },
  fetchDiagnosticeTableDetail: () => {
    'https://api.galencloud.com/user/device?nameLike=Diagnostic&pageNumber=0&pageSize=20&sortBy=name&sortOrder=ASC'
  },
  fetchSavedDiagnosticPanelData: (payload, filterData) => {
    let filter = "?pageNumber=0&pageSize=1&sortBy=minValueProvidedOn&sortOrder=DESC";
    if (filterData && Object.keys(filterData).length > 0) {
      filter = Object.keys(filterData).reduce(
        (acc, val, index) =>
          Object.keys(filterData).length - 1 === index
            ? `${acc}${val}=${filterData[val]}`
            : `${acc}${val}=${filterData[val]}&`,
        "?"
      );
    }
    return service.postVersionJson(
      `${deviceAdvance}/owner/custom-criteria${filter}`,
      payload
    );
  }
};

export default deviceService;
